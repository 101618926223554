import * as React from 'react';
import { LoginStepEnum } from '../../utils/loginStepEnum';

export interface ILoginButtonProps {
    loginStep: LoginStepEnum,
    loading: boolean
}

const loadingIconPath = "../images/loading.svg";
const loginIconPath = "../images/cs-assets/cs-login.svg";

export default class LoginButton extends React.Component<ILoginButtonProps> {
    public render() {
        const url = this.props.loginStep == LoginStepEnum.SCHEDULE_LOGIN_BY_PSWD ? "/forgot_password.cgi?type=1" : "https://forget.tigertext.com/";

        return (
            <div className="row padding-top padding-bottom">
                <div className="col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-3">
                    {
                        this.props.loginStep !== LoginStepEnum.LOGIN_CHECK &&
                        <div className="forgot-password pull-left">
                            <a href={url}>Forgot Password?</a>
                        </div>
                    }

                    <div className="login-button pull-right">
                        {
                            this.props.loginStep === LoginStepEnum.LOGIN_CHECK ?
                                <button className="btn btn-primary pull-right" type="submit" name="login" value="Next" disabled={this.props.loading}>
                                    Next&nbsp;<img src={this.props.loading ? loadingIconPath : loginIconPath} />
                                </button> : null
                        }
                        {
                            this.props.loginStep !== LoginStepEnum.LOGIN_CHECK ?
                                <button className="btn btn-primary pull-right" type="submit" name="login" value="Login" disabled={this.props.loading}>
                                    Login&nbsp;<img src={this.props.loading ? loadingIconPath : loginIconPath} />
                                </button> : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}
