import React, { useContext } from "react"
import { Row, Col } from "@app/components/Layout"
import { H4, H5 } from "@app/components/Typography"
import Icon from "@app/components/Icon"
import Button from "@app/components/Button"
import BlockSetsFilter from "./components/BlockSetsFilter"
import ProvidersFilter from "./components/ProvidersFilter"
import RotationsFilter from "./components/RotationsFilter"
import QuickAssignmentFilter from "./components/QuickAssignmentFilter"
import HighlightFilter from "./components/HighlightFilter"
import { useAccessPermission } from "@app/utils/hooks"
import cx from "classnames"
import css from "./AnnualScheduleHeader.module.scss"
import QuickAssignmentTemplateResults from "./components/QuickAssignmentTemplateResults"
import { QuickAssignmentContext } from "../../context/QuickAssignmentContext"

type Props = {
  activeKey: string
  annualBlockSchedule: PlainObjectType
}

const filters: PlainObjectType = {
  blocks: BlockSetsFilter,
  schedules: (props: any) => (
    <>
      <ProvidersFilter {...props} />
      <RotationsFilter {...props} />
    </>
  ),
}

export default ((props) => {
  const { annualBlockSchedule, activeKey, children } = props
  const FilterComp = filters[activeKey]

  const { canAccess } = useAccessPermission({ scope: "blockScheduling" })

  const { quickAssignmentTemplate } = useContext(QuickAssignmentContext)

  return (
    <Row className="align-items-start">
      <Col md="4" style={{ flex: "1" }}>
        <span className={css.headline}>{annualBlockSchedule?.name}</span>
        <H4>{annualBlockSchedule.display_name}</H4>
      </Col>
      <Col
        md="auto"
        className="d-flex justify-content-center"
        style={{ flexGrow: 1 }}
      >
        {children}
      </Col>
      <Col
        md="4"
        className="mt-1 d-flex justify-content-end"
        style={{ flex: "1" }}
      >
        <div className="d-flex flex-column align-items-end">
          {FilterComp && (
            <div className="d-flex align-items-center">
              <H5>Filter by</H5>
              <div className={cx("d-flex ml-3", css.filtersContainer)}>
                <FilterComp annualBlockScheduleId={annualBlockSchedule.id} />
              </div>
            </div>
          )}
          {activeKey == "schedules" && (
            <>
              <div className="d-flex flex-column align-items-center mt-2">
                <div className={cx("d-flex ml-3 mb-2", css.filtersContainer)}>
                  <H5>Quick Assignment</H5>
                  <QuickAssignmentFilter
                    annualBlockScheduleId={annualBlockSchedule.id}
                  />
                </div>
                {quickAssignmentTemplate && (
                  <div className={cx("d-flex ml-3", css.filtersContainer)}>
                    <H5>Templates Processed</H5>
                    <QuickAssignmentTemplateResults />
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center mt-2">
                <H5>Highlight</H5>
                <div className={cx("d-flex ml-3", css.filtersContainer)}>
                  <HighlightFilter
                    annualBlockScheduleId={annualBlockSchedule.id}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {canAccess("/") && (
          <div className="ml-3">
            <Button shape="circle" variant="info" to="/">
              <Icon name="gear" className={css.settingIcon} />
            </Button>
          </div>
        )}
      </Col>
    </Row>
  )
}) as React.FC<Props>
