import React from "react"
import css from "./VacationProviderElement.module.scss"
import cx from "classnames"
import { useSelector } from "@app/models"

interface VacationProviderElementProps {
  isCardDisabled: boolean
}

export const VacationProviderElement = ({
  isCardDisabled,
}: VacationProviderElementProps) => {
  const {
    rulesConfig: { display_job_times: showJobTimesInCalendar },
    isHighlightedChangesActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  return (
    <div
      className={cx(css.vacationWrapper, {
        [css.disabledCellJob]: isCardDisabled || isHighlightedChangesActive,
        [css.smallCards]: !showJobTimesInCalendar,
      })}
    >
      Vacation
    </div>
  )
}
