import { Provider } from "../containers/spa/WhiteboardCalendar/data"

export const getAdditionalAssignmentHighlighted = (
  providerId: number,
  highlightProviders: Provider[] | undefined
) => {
  return highlightProviders?.some(
    (highlightedProvider) => providerId === highlightedProvider.providerid
  )
}

export const isHighlightedChange = (
  isHighlightedChangesActive: boolean,
  dailyScheduleChanges: ScheduleChange[],
  assignment: AssignmentBaseType
): boolean => {
  if (!isHighlightedChangesActive || dailyScheduleChanges.length === 0) {
    return false
  }

  return dailyScheduleChanges.some((change) => {
    const matchesAssignment =
      change.eventid === assignment.eventid ||
      change.eventid === assignment.draft_eventid ||
      (change.jobid === assignment.job.jobid &&
        change.new_providerid === assignment.provider.providerid)

    const matchesDate = change.event_date === assignment.edate

    return matchesAssignment && matchesDate
  })
}
