import React from "react"
import { H4, Text } from "@app/components/Typography"
import Avatar from "@app/components/Avatar"
import Modal from "@app/components/Modal"
import Card from "@app/components/Card"
import Button from "@app/components/Button"
import api, { useRequest } from "@app/services/api"
import { formatUSNumber } from "@app/utils"
import { capitalize } from "lodash"
import ProtocolViewer from "./ProtocolViewer"
import css from "./ProviderModal.module.scss"

type Props = {
  providerId: number | null
  customCalendar: CustomCalendarType
  setSendSecureMessageProviderId: (providerId: number) => any
  onHide: (status: boolean) => any
}

export default (props: Props) => {
  const { providerId, customCalendar, setSendSecureMessageProviderId, onHide } =
    props

  const { data: provider } = useRequest<CustomCalendarProviderType>(
    providerId
      ? [api.getCustomCalendarProvider, customCalendar.key, providerId]
      : null
  )

  const handleModalHide = () => {
    onHide(false)
  }

  const handleSendMessageLinkClick = (providerId: number) => {
    handleModalHide()
    setSendSecureMessageProviderId(providerId)
  }

  return (
    <Modal
      title="Provider Details"
      titleAlign="left"
      className={css.providerModal}
      show={Boolean(providerId)}
      onHide={handleModalHide}
    >
      {!provider ? (
        <div>Loading...</div>
      ) : (
        <>
          <Card title="Provider" className={css.thinCard}>
            <div className="d-flex align-items-center">
              <Avatar size={32} src={provider.avatar} className="mr-3" />
              <H4>{provider.fullname}</H4>
            </div>
            {provider.can_send_secure_message && (
              <div className="text-center mt-3 mb-1">
                <Button
                  size="sm"
                  onClick={(event) => {
                    event.stopPropagation()
                    handleSendMessageLinkClick(provider.providerid)
                  }}
                >
                  Send Secure TigerConnect Message
                </Button>
              </div>
            )}
          </Card>
          <Card title="Protocol" className={css.thinCard}>
            <ProtocolViewer
              providerId={provider.providerid}
              protocol={provider.provider_protocol?.protocol}
              canSendMessage={provider.can_send_secure_message}
              onSendMessageLinkClick={handleSendMessageLinkClick}
            />
          </Card>
          <Card title="Contact Information" className={css.thinCard}>
            {Object.values(provider.phones).every((x) => x.length === 0) ? (
              <div>None</div>
            ) : (
              Object.entries(provider.phones).map(
                ([type, numbers]) =>
                  numbers.length && (
                    <div key={type} className={css.phoneContent}>
                      <Text bold>
                        {type.includes("phone")
                          ? capitalize(type)
                          : `${capitalize(type)} Phone`}
                        :
                      </Text>
                      {numbers.map((num) => (
                        <Text bold="medium" key={num} title={num}>
                          {formatUSNumber(num)}
                        </Text>
                      ))}
                    </div>
                  )
              )
            )}
          </Card>
        </>
      )}
    </Modal>
  )
}
