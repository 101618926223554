import React from "react"
import ToggleSwitch from "@app/components/Toggle/Toggle"
import css from "./HighlightUnderstaffedJobs.module.scss"
import { useDispatch, useSelector } from "@app/models"

export const HighlightUnderstaffedJobs = () => {
  const { isUnderstaffedJobsHighlightActive } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const dispatch = useDispatch()

  const toggleSwitch = () => {
    dispatch.calendarEvents.setUnderstaffedJobsHighlight(
      !isUnderstaffedJobsHighlightActive
    )
  }

  return (
    <div className={css.container}>
      <div className={css.headerContainer}>
        <h2 className={css.header}>Highlight Understaffed Jobs</h2>
        <div>
          <ToggleSwitch
            value={isUnderstaffedJobsHighlightActive}
            onToggle={toggleSwitch}
          />
        </div>
      </div>
      <div>
        <p className={css.description}>
          Enable this option to easily identify assignments not meeting daily
          minimum requirements.
        </p>
        <p className={css.description}>
          This feature is only available in the Jobs List view and will be
          disabled automatically if you switch to the Calendar or Provider List
          view.
        </p>
      </div>
    </div>
  )
}
