import { createModel } from "@rematch/core"
import { RootModel } from "."

type StateType = {
  shiftsMap: PlainObjectType
}

export default createModel<RootModel>()({
  state: {
    shiftsMap: {},
  } as StateType,
  reducers: {
    updateGroupShiftsMap(state, payload: PlainObjectType) {
      return {
        shiftsMap: {
          ...state.shiftsMap,
          ...payload,
        },
      }
    },
  },
})
