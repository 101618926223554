import { useCaniuseFeature } from "@app/utils/hooks"

// Job types
export const JobTypes = [
  { id: 1, name: "Call" },
  { id: 2, name: "Work" },
  { id: 3, name: "Resident" },
]

// Days of the week
export const JobDays = [
  { id: 1, name: "Sunday" },
  { id: 2, name: "Monday" },
  { id: 3, name: "Tuesday" },
  { id: 4, name: "Wednesday" },
  { id: 5, name: "Thursday" },
  { id: 6, name: "Friday" },
  { id: 7, name: "Saturday" },
]

type AcceptableJobTypeIdType =
  | number
  | string
  | undefined
  | { job_typeid: number }

export const detectJobType = (jobTypeId: AcceptableJobTypeIdType) => {
  if (jobTypeId == null) {
    return
  }

  const realJobTypeId =
    typeof jobTypeId === "number"
      ? jobTypeId
      : typeof jobTypeId === "string"
      ? parseInt(jobTypeId)
      : jobTypeId.job_typeid

  return JobTypes.find((x) => x.id === realJobTypeId)
}

export const checkIfResidentJob = (jobTypeId: AcceptableJobTypeIdType) =>
  detectJobType(jobTypeId)?.name === "Resident"

// User can see ResidentJob type only "block_scheduling" is enabled
// in any group of his/her clinic
export const useEnabledJobTypes = () => {
  const isBlockSchedulingEnabled = useCaniuseFeature("block_scheduling", {
    scope: "clinic",
  })

  return isBlockSchedulingEnabled
    ? JobTypes
    : JobTypes.filter((job) => job.name !== "Resident")
}
