import React, { useEffect } from "react"
import { formatInTimeZone } from "date-fns-tz"
import css from "./EditRequest.module.scss"

type Props = {
  req: any
}

export default ({ req }: Props) => {
  useEffect(() => {
    if (req.created_at) {
      req.created_at_fmt = formatInTimeZone(
        new Date(req.created_at),
        req.timezone,
        "eeee, MMMM do, yyyy, hh:mm:ss aaa"
      )
    }
    if (req.updated_at) {
      req.updated_at_fmt = formatInTimeZone(
        new Date(req.updated_at),
        req.timezone,
        "eeee, MMMM do, yyyy, hh:mm:ss aaa"
      )
    }
  }, [])

  return (
    <>
      <div className={css.formLabel}>Created</div>
      <div className={css.formValue}>
        {req.created_at_fmt ? req.created_at_fmt : "-"}
      </div>
      <div className={css.formLabel}>Updated</div>
      <div className={css.formValue}>
        {req.updated_at_fmt ? req.updated_at_fmt : "-"}
      </div>
    </>
  )
}
