import React, { useContext } from "react"
import { useSelector } from "@app/models"
import Icon from "@app/components/Icon"
import type { JobBlockType } from "@app/services/mdvShiftCalculation"
import Dropdown from "@app/components/Dropdown"
import cx from "classnames"
import css from "./ScheduleChange.module.scss"
import { OnViewNoteUserLoginContext } from "../../../context/OnViewNoteUserLoginContext"
import { Dialog } from "@app/components/Modal"

type Props = {
  className?: string
  jobBlock: JobBlockType
  onViewNotes: boolean
  setPickedSendSecureMessageSource: (source: { type: "job"; id: number }) => any
  setPickedJobForScheduleChange: (job: JobBlockType | null) => any
}

export default (props: Props) => {
  const {
    className,
    jobBlock,
    onViewNotes,
    setPickedSendSecureMessageSource,
    setPickedJobForScheduleChange,
  } = props

  const { job } = jobBlock
  const { setLoginModalShow } = useContext(OnViewNoteUserLoginContext)
  const { currentOnViewNoteUser } = useSelector((state) => state.users)
  const { customCalendar } = useSelector((state) => state.customCalendars)

  const handleScheduleChangeClick = () => {
    if (onViewNotes) {
      if (currentOnViewNoteUser?.on_view_note_user) {
        if (currentOnViewNoteUser?.allow_edit) {
          setPickedJobForScheduleChange(jobBlock)
        } else {
          Dialog.warn({
            message: "You are not authorized to make notes on this calendar",
          })
        }
      } else {
        setLoginModalShow(true)
      }
    } else {
      Dialog.warn({
        message:
          "On-View notes and schedule changes are not enabled on this merged daily view",
      })
    }
  }

  return (
    <div className={cx(className, css.trigger)}>
      <Dropdown
        overlay={
          <Dropdown.Menu>
            <div className={css.dropdown}>
              {customCalendar.allow_messaging_to_roles && (
                <div
                  className={
                    job.tigerconnect_roles_count > 0
                      ? cx(css.dropdownItem)
                      : cx("disabled", css.dropdownItem)
                  }
                  onClick={() => {
                    if (job.tigerconnect_roles_count === 0) return
                    setPickedSendSecureMessageSource({
                      type: "job",
                      id: jobBlock.job.jobid,
                    })
                  }}
                >
                  <span>Message Role</span>
                </div>
              )}
              <div
                className={css.dropdownItem}
                onClick={handleScheduleChangeClick}
              >
                <span>Create Schedule Change</span>
              </div>
            </div>
          </Dropdown.Menu>
        }
        drop="right"
      >
        <div className={css.trigger}>
          <Icon name="three-dots" />
        </div>
      </Dropdown>
    </div>
  )
}
