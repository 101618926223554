import React, { useMemo } from "react"
import css from "./VacationElement.module.scss"
import { useSelector } from "@app/models"
import cx from "classnames"

interface VacationElementProps {
  vacationProviders: string
  vacationTypeName: string
  vacationProviderId: number
}

export const VacationElement = ({
  vacationProviders,
  vacationTypeName,
  vacationProviderId,
}: VacationElementProps) => {
  const {
    highlightOptions: { highlightProviders },
    isHighlightedChangesActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const isVacationHighlighted = useMemo(
    () =>
      highlightProviders?.some(
        (highlightedProvider) =>
          vacationProviderId === highlightedProvider.providerid
      ),
    [highlightProviders]
  )

  return (
    <div
      className={cx(css.vacationWrapper, {
        [css.highlightedVacation]:
          providersAreHighlighted && isVacationHighlighted,
        [css.fadedVacation]:
          (providersAreHighlighted && !isVacationHighlighted) ||
          isHighlightedChangesActive,
      })}
    >{`${vacationProviders} - ${vacationTypeName}`}</div>
  )
}
