import React from "react"
import cx from "classnames"
import css from "./Icon.module.scss"

type Props = React.HTMLAttributes<HTMLElement> & {
  name: string
  className?: string
  hoverable?: boolean
  resetLineHeight?: boolean
  style?: React.CSSProperties
  color?: string
  onClick?: any
}

export default (props: Props) => {
  const { name, className, hoverable, resetLineHeight, style, color, ...rest } =
    props
  const combinedStyle = color ? { ...style, color } : style

  return (
    <i
      style={combinedStyle}
      className={cx(
        "ts-icon",
        `ts-icon-${name}`,
        resetLineHeight ? css.lineHeightInherit : css.icon,
        { [css.hoverable]: hoverable },
        className
      )}
      {...rest}
    />
  )
}
