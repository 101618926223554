import { sortBy, orderBy } from "lodash"
import { parseDate, formatDate } from "@app/utils"
import { set as setDate, areIntervalsOverlapping, isSameDay } from "date-fns"

export type JobRowType = {
  id: string
  pastFutureType: "past" | "current" | "future"
  job: CustomCalendarPureJobType & {
    starttime_text: string
    endtime_text: string
  }
  event: CustomCalendarEventType
  job_edate: string
  job_edate_tz: string
  shift: {
    current: {
      provider: ProviderBaseType
      start_at: Date
      end_at: Date
      start_date_text: string
      end_date_text: string
      starttime_text: string
      endtime_text: string
      is_start_date_current_date: boolean
      is_end_date_current_date: boolean
    }
    previous?: JobRowType["shift"]["current"]
    next?: JobRowType["shift"]["current"]
  }
}

export type JobBlockType = {
  id: string
  pastFutureType: "past" | "current" | "future"
  job: CustomCalendarPureJobType & {
    starttime_text: string
    endtime_text: string
  }
  job_edate: string
  shifts: JobRowType[]
}

type CustomCalendarJobType = CustomCalendarGroupDetailsType["jobs"][number]
export type CustomCalendarPureJobType = Omit<
  CustomCalendarJobType,
  "events" | "notes"
>

// A transitional type generated from jobs to build jobRows
type JobEventType = {
  job: CustomCalendarPureJobType
  event: CustomCalendarEventType
  notes: CustomCalendarJobType["notes"]
}

export const sortMappings = {
  priority: {
    name: "Priority",
    order: { asc: "Low on Top", desc: "High on Top" },
    attr: ({ job }: JobRowType) => job.priority,
  },
  starttime: {
    name: "Start Time",
    order: { asc: "Earliest to Latest", desc: "Latest to Earliest" },
    attr: ({ shift }: JobRowType) => shift.current.start_at,
  },
  endtime: {
    name: "End Time",
    order: { asc: "Earliest to Latest", desc: "Latest to Earliest" },
    attr: ({ shift }: JobRowType) => shift.current.end_at,
  },
  name: {
    name: "Job Name",
    order: { asc: "A to Z", desc: "Z to A" },
    attr: ({ job }: JobRowType) => job.name,
  },
}

export const processJobRows = (
  jobs: CustomCalendarGroupDetailsType["jobs"] | undefined,
  now: Date,
  date?: string | null
): JobRowType[] | undefined => {
  if (!jobs) return undefined

  const nowOfDate = getNowOfDate(now, date)
  const jobEvents: JobEventType[] = []

  jobs.forEach((job) => {
    const { events, notes, ...jobAttr } = job
    events.forEach((event) => {
      jobEvents.push({
        job: {
          ...jobAttr,
          starttime: event.job_starttime,
          endtime: event.job_endtime,
        },
        event,
        notes: job.notes,
      })
      event.additional_assignments.map((assignment) => {
        jobEvents.push({
          job: {
            ...jobAttr,
            starttime: event.job_starttime,
            endtime: event.job_endtime,
          },
          event: {
            ...event,
            providerid: assignment.providerid,
            provider: assignment.provider,
          },
          notes: [],
        })
      })
    })
  })

  const jobRows = jobEvents.map((jobEvent) =>
    calculateJobRow(jobEvent, now, nowOfDate, date)
  )

  return jobRows
}

export const filterJobRows = (
  jobRows: JobRowType[] | undefined,
  conditions: {
    hidePastFuture?: boolean
    searchKeyword?: string
    sorting?: { column: keyof typeof sortMappings; order: "desc" | "asc" }
    start_time_secondary_sort?: StarttimeSecondarySortType
    priority_secondary_sort?: PrioritySecondarySortType
  } = {}
) => {
  if (!jobRows) return undefined

  const {
    hidePastFuture,
    searchKeyword,
    sorting,
    start_time_secondary_sort,
    priority_secondary_sort,
  } = conditions
  let newJobRows = jobRows

  if (hidePastFuture) {
    newJobRows = newJobRows.filter((x) => x.pastFutureType === "current")
  }

  if (searchKeyword) {
    newJobRows = newJobRows.filter(({ job, shift }) => {
      const reg = new RegExp(searchKeyword, "i")
      const fields = [
        job.name,
        shift.current?.provider.fullname,
        shift.previous?.provider.fullname,
        shift.next?.provider.fullname,
      ]

      return fields.some((x) => x && reg.test(x))
    })
  }

  if (sorting) {
    if (sorting.column === "starttime") {
      if (start_time_secondary_sort === "name") {
        newJobRows = orderBy(
          newJobRows,
          [sortMappings[sorting.column].attr, sortMappings.name.attr],
          [sorting.order, "asc"]
        )
      } else {
        newJobRows = orderBy(
          newJobRows,
          [
            sortMappings[sorting.column].attr,
            sortMappings.priority.attr,
            sortMappings.name.attr,
          ],
          [sorting.order, "asc", "asc"]
        )
      }
    } else if (sorting.column === "priority") {
      if (priority_secondary_sort === "starttime_name") {
        newJobRows = orderBy(
          newJobRows,
          [
            sortMappings[sorting.column].attr,
            sortMappings.starttime.attr,
            sortMappings.name.attr,
          ],
          [sorting.order, "asc", "asc"]
        )
      } else {
        newJobRows = orderBy(
          newJobRows,
          [
            sortMappings[sorting.column].attr,
            sortMappings.name.attr,
            sortMappings.starttime.attr,
          ],
          [sorting.order, "asc", "asc"]
        )
      }
    } else if (sorting.column === "name") {
      newJobRows = orderBy(
        newJobRows,
        [sortMappings[sorting.column].attr, sortMappings.starttime.attr],
        [sorting.order, "asc"]
      )
    } else {
      newJobRows = orderBy(
        newJobRows,
        [sortMappings[sorting.column].attr, sortMappings.name.attr],
        [sorting.order, "asc"]
      )
    }
  }

  return newJobRows
}

export const mergeJobRows = (
  jobRows: JobRowType[] | undefined
): JobBlockType[] | undefined => {
  if (!jobRows) return undefined

  let mergedJobRows: JobBlockType[] = []

  jobRows.forEach((item) => {
    const { pastFutureType, job, job_edate } = item

    let existItem = mergedJobRows.find(
      (x) => x.job.jobid == job.jobid && x.job_edate == job_edate
    )

    if (existItem) {
      existItem.shifts.push(item)
      existItem.pastFutureType =
        existItem.pastFutureType == "current" ? "current" : pastFutureType
    } else {
      existItem = {
        job,
        pastFutureType,
        job_edate,
        id: `${job.jobid}_${job_edate}`,
        shifts: [item],
      }

      mergedJobRows.push(existItem)
    }
  })

  return mergedJobRows
}

// Helper methods

const parseStartAt = (item: { start_date: string; starttime: string }): Date =>
  parseDate(`${item["start_date"]} ${item["starttime"]}`, (f) => f.iso)
const parseEndAt = (item: { end_date: string; endtime: string }): Date =>
  parseDate(`${item["end_date"]} ${item["endtime"]}`, (f) => f.iso)

const formatDateMonlyOnly = (date: Date) =>
  formatDate(date, (f) => f.humanizedMonthOnly)

const formatTimeTo12h = (timeOrDate: string | Date) =>
  formatDate(
    timeOrDate instanceof Date
      ? timeOrDate
      : parseDate(timeOrDate, (f) => f.timeOnly),
    (f) => f.shortHourMinute12h
  )

const getNowOfDate = (now: Date, date?: string | null) => {
  if (!date) return now

  const parsedDate = parseDate(date)
  const year = parsedDate.getFullYear()
  const month = parsedDate.getMonth()
  const day = parsedDate.getDate()

  return setDate(now, { year, month, date: day })
}

const calculateJobRow = (
  jobEvent: JobEventType,
  now: Date,
  nowOfDate: Date,
  date?: string | null
): JobRowType => {
  const eventStartAt = parseStartAt(jobEvent.event)
  const eventEndAt = parseEndAt(jobEvent.event)
  const job_edate = jobEvent.event.job_edate
  const job_edate_tz = jobEvent.event.start_date

  const jobRowId = `job-row-${jobEvent.event.eventid}`
  const pastFutureType: "past" | "future" | "current" =
    eventEndAt < now ? "past" : eventStartAt >= now ? "future" : "current"

  const job = {
    ...jobEvent.job,
    starttime_text: formatTimeTo12h(jobEvent.job.starttime),
    endtime_text: formatTimeTo12h(jobEvent.job.endtime),
    tigerconnect_roles_count: jobEvent.job.tigerconnect_roles_count,
  }

  const shift = calculateJobShift(jobEvent, nowOfDate, now, date)

  return {
    id: jobRowId,
    event: jobEvent.event,
    pastFutureType,
    job,
    job_edate,
    job_edate_tz,
    shift,
  }
}

const calculateJobShift = (jobEvent: JobEventType, nowOfDate: Date, now: Date, date?: string | null) => {
  const eventStartAt = parseStartAt(jobEvent.event)
  const eventEndAt = parseEndAt(jobEvent.event)
  const eventAsNote = {
    ...jobEvent.event,
    noteid: 0, // fake noteid
    startAt: parseStartAt(jobEvent.event),
    endAt: parseEndAt(jobEvent.event),
  }

  const processedNotes = jobEvent.notes.map((note) => ({
    ...note,
    startAt: parseStartAt(note),
    endAt: parseEndAt(note),
  }))

  const validNotes = processedNotes.filter((note) =>
    note.startAt > note.endAt
      ? false
      : areIntervalsOverlapping(
        { start: eventStartAt, end: eventEndAt },
        { start: note.startAt, end: note.endAt }
      )
  )

  const formatShift = (note: {
    provider: ProviderBaseType
    startAt: Date
    endAt: Date
  }) => {
    return {
      provider: note.provider,
      start_at: note.startAt,
      end_at: note.endAt,
      start_date_text: formatDateMonlyOnly(note.startAt),
      end_date_text: formatDateMonlyOnly(note.endAt),
      starttime_text: formatTimeTo12h(note.startAt),
      endtime_text: formatTimeTo12h(note.endAt),
      is_start_date_current_date: isSameDay(nowOfDate, note.startAt),
      is_end_date_current_date: isSameDay(nowOfDate, note.endAt),
    }
  }

  if (!validNotes.length) {
    return {
      current: formatShift(eventAsNote),
      previous: undefined,
      next: undefined,
    }
  }

  let virtualNotes: any[] = sortBy(validNotes, "startAt")
  virtualNotes = virtualNotes.map((item, index) =>
    virtualNotes[index + 1] && item.endAt > virtualNotes[index + 1].startAt
      ? Object.assign({}, item, { endAt: virtualNotes[index + 1].startAt })
      : item
  )

  const firstNote = virtualNotes[0]
  if (firstNote && firstNote.startAt > eventStartAt) {
    virtualNotes = [
      {
        ...jobEvent.event,
        noteid: 0, // fake noteid
        startAt: eventStartAt,
        endAt: firstNote.startAt,
      },
      ...virtualNotes,
    ]
  }

  const lastNote = virtualNotes.slice(-1)[0]
  if (lastNote.endAt < eventEndAt) {
    const lastEndedNote = sortBy(validNotes, "endAt").slice(-1)[0]
    if (lastEndedNote.endAt < eventEndAt) {
      virtualNotes = [
        ...virtualNotes,
        {
          ...jobEvent.event,
          noteid: 0, // fake noteid
          startAt: lastNote.endAt,
          endAt: parseEndAt(jobEvent.event),
        },
      ]
    } else {
      virtualNotes = [
        ...virtualNotes,
        {
          ...lastEndedNote,
          noteid: 0, // fake noteid
          startAt: lastNote.endAt,
        },
      ]
    }
  }

  // notes has higher priority than event
  const currentShift =
    virtualNotes.find(
      (note) => note.startAt <= now && note.endAt > now
    ) || (
      virtualNotes[0].startAt > now
        ? virtualNotes[0]
        : virtualNotes.slice(-1)[0].endAt <= now
          ? virtualNotes.slice(-1)[0]
          : eventAsNote
    )

  // find the closest previous event/note
  const previousShift = sortBy(virtualNotes, "startAt").find(
    (note) => note.noteid !== currentShift.noteid && note.startAt < currentShift.startAt
  )

  // find the next previous event/note
  const nextShift = sortBy(virtualNotes, "endAt").find(
    (note) => note.noteid !== currentShift.noteid && note.endAt > currentShift.endAt
  )

  return {
    current: formatShift(currentShift),
    previous: previousShift && formatShift(previousShift),
    next: nextShift && formatShift(nextShift),
  }
}
