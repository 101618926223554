import React, { useReducer, useEffect } from "react"
import BsTabs from "react-bootstrap/Tabs"
import BsTab from "react-bootstrap/Tab"
import Nav from "react-bootstrap/Nav"
import { Route, Routes, useLocation } from "react-router-dom"
import NavLink from "@app/components/NavLink"
import cx from "classnames"
import css from "./Tabs.module.scss"

type TabProps = {
  title: string | JSX.Element
  eventKey: string
  content?: any
}

type Props = {
  activeKey?: string
  defaultActiveKey?: string
  tabs: TabProps[]
  variant?: string
  className?: string
  lazy?: boolean
  asRoute?: boolean
  onSelect?: (eventKey: string, event: any) => void
  customHeader?: React.FC<{ activeKey: string }>
}

export default (props: Props) => {
  const {
    activeKey,
    defaultActiveKey,
    tabs,
    onSelect,
    variant = "default",
    className,
    lazy,
    asRoute,
    customHeader: CustomHeader = ({ children }) => <>{children}</>,
  } = props
  const defaultKey = defaultActiveKey || tabs[0].eventKey
  const tabsId = `tabs-${Math.floor(Math.random() * 0xffff)}`

  // If you use `asRoute`,
  // then `lazy`, `activeKey` and `onSelect` props will be ignored
  if (asRoute) {
    const defaultTab = tabs.find((x) => x.eventKey == defaultKey)
    const { pathname: url } = useLocation()
    const currentActiveKey =
      tabs.map((x) => x.eventKey).find((x) => url.endsWith(`/${x}`)) ||
      defaultKey

    return (
      <>
        <CustomHeader activeKey={currentActiveKey}>
          <Nav variant="tabs" className={cx(css[`tabs-${variant}`], className)}>
            {tabs.map((tab) => (
              <NavLink
                key={tab.eventKey}
                to={tab.eventKey}
                isActive={(match, { pathname }) =>
                  !!match ||
                  (defaultKey === tab.eventKey && pathname.startsWith(url))
                }
                className="nav-item nav-link"
              >
                {tab.title}
              </NavLink>
            ))}
          </Nav>
        </CustomHeader>
        <div className="tab-content">
          <Routes>
            <Route path={""} element={defaultTab?.content} />
            {tabs.map((tab) => (
              <Route
                key={tab.eventKey}
                path={tab.eventKey}
                element={tab.content}
              />
            ))}
          </Routes>
        </div>
      </>
    )
  }

  const [loadedEventKey, addLoadedEventKey] = useReducer(
    (state: string[], key: string) =>
      state.includes(key) ? state : [...state, key],
    [defaultKey]
  )

  useEffect(() => {
    activeKey && handleSelect(activeKey)
  }, [activeKey])

  const handleSelect = (eventKey: any, event?: any) => {
    if (lazy) {
      addLoadedEventKey(eventKey)
    }

    onSelect?.(eventKey, event)
  }

  return (
    <BsTabs
      id={tabsId}
      activeKey={activeKey}
      defaultActiveKey={defaultKey}
      transition={false}
      className={cx(css[`tabs-${variant}`], className)}
      onSelect={handleSelect}
    >
      {tabs.map((tab: TabProps, idx: number) => (
        <BsTab key={idx} title={tab.title} eventKey={tab.eventKey}>
          {(!lazy || loadedEventKey.includes(tab.eventKey)) && tab.content}
        </BsTab>
      ))}
    </BsTabs>
  )
}
