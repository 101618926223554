import React from "react"
import css from "./AdditionalAssignments.module.scss"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

interface PropsType {
  providersAssigned: number
  toggleExpandedAdditionalAssignmentRow: boolean
  handleToggleAdditionalAssignments: () => void
}

export default ({
  providersAssigned,
  toggleExpandedAdditionalAssignmentRow,
  handleToggleAdditionalAssignments,
}: PropsType) => {
  return (
    <div
      onClick={handleToggleAdditionalAssignments}
      className={css.additionalAssignments}
    >
      <span>Multiple providers ({providersAssigned})</span>
      <ArrowForwardIosIcon
        className={css.arrowIcon}
        style={{
          transform: toggleExpandedAdditionalAssignmentRow
            ? "rotate(90deg)"
            : "none",
        }}
      />
    </div>
  )
}
