import React, { useRef } from "react"
import BsDropdown from "react-bootstrap/Dropdown"
import { randomID, isWindows, isIE } from "@app/utils"
import cx from "classnames"
import css from "./Dropdown.module.scss"

type Props = React.ComponentProps<typeof BsDropdown> & {
  variant?: string
  overlay?: React.ReactNode
  children: React.ReactNode | ((show: boolean) => React.ReactNode)
  menuProps?: React.ComponentProps<typeof BsDropdown.Menu> & {
    options: { id: string | number; name: string | number }[]
    onItemClick: (id: any) => any
  }
}

const DefaultToggle = React.forwardRef<HTMLDivElement, any>(
  ({ className, children, ...others }, ref) => {
    const isShow = others["aria-expanded"]

    return (
      <div ref={ref} {...others}>
        {typeof children === "function" ? children(isShow) : children}
      </div>
    )
  }
)

const Dropdown: React.FC<Props> & {
  Item: typeof BsDropdown.Item
  Menu: React.FC<
    React.ComponentProps<typeof BsDropdown.Menu> & {
      keepUniformWidth?: boolean
    }
  >
  Divider: typeof BsDropdown.Divider
} = (props) => {
  const {
    variant = "default",
    className,
    overlay,
    children,
    menuProps,
    ...rest
  } = props

  const toggleId = useRef(randomID({ prefix: "dropdown" }))

  const renderMenu = () => {
    if (menuProps) {
      const { options, onItemClick, ...menuRest } = menuProps
      return (
        <BsDropdown.Menu {...menuRest}>
          {options.map((item) => (
            <BsDropdown.Item key={item.id} onClick={() => onItemClick(item.id)}>
              {item.name}
            </BsDropdown.Item>
          ))}
        </BsDropdown.Menu>
      )
    } else {
      return React.Children.count(overlay) === 1 ? (
        overlay
      ) : (
        <BsDropdown.Menu children={overlay} />
      )
    }
  }

  return (
    <BsDropdown
      className={cx(className, `dropdown-${variant}`, css.dropdown)}
      {...rest}
    >
      <BsDropdown.Toggle
        as={DefaultToggle}
        id={toggleId.current}
        children={children}
      />
      {renderMenu()}
    </BsDropdown>
  )
}

Dropdown.Item = BsDropdown.Item
Dropdown.Divider = BsDropdown.Divider
Dropdown.Menu = (props) => {
  const { keepUniformWidth, popperConfig, ...rest } = props
  const modifiers = popperConfig?.modifiers || []

  const strategy = isIE ? "absolute" : "fixed" // IE always uses "absolute"
  const defaultModifiers = [
    {
      name: "computeStyles",
      options: {
        gpuAcceleration: !isWindows, // false if Windows
      },
    },
    {
      name: "sameWidth",
      enabled: true,
      phase: "beforeWrite" as any,
      requires: ["computeStyles"],
      fn({ state }: any) {
        isIE
          ? keepUniformWidth && (state.styles.popper.width = "100%")
          : (state.styles.popper.minWidth = state.rects.reference.width)
      },
      effect({ state }: any) {
        isIE
          ? keepUniformWidth && (state.elements.popper.style.width = "100%")
          : (state.elements.popper.style.minWidth =
              state.elements.reference.offsetWidth)
      },
    },
  ]

  return (
    <BsDropdown.Menu
      {...rest}
      popperConfig={{
        ...popperConfig,
        strategy,
        modifiers: [...defaultModifiers, ...modifiers],
      }}
    />
  )
}

export default Dropdown
