import React from "react"
import Table from "@app/components/Table"
import { H3 } from "@app/components/Typography"
import { Row, Col } from "@app/components/Layout"
import Button from "@app/components/Button"
import api, { useRequest } from "@app/services/api"
import { checkIfResidentJob } from "@app/services/jobHelper"
import cx from "classnames"
import css from "./LinkedRotations.module.scss"

type Props = {
  job: JobWithAllFieldsType
}

export default (props: Props) => {
  const { job } = props

  if (!checkIfResidentJob(job)) {
    return null
  }

  const { data: linkedRotations } = useRequest<JobLinkedRotationType[]>([
    api.getJobLinkedRotations,
    job.jobid,
  ])

  const buildJobPath = (path: string) => `/jobs/${job.jobid}/${path}`

  return (
    <div className="bootstrap4">
      <Row className="mt-3">
        <Col>
          <H3 bold="medium" variant="blue">
            Linked Rotations
          </H3>
        </Col>
        <Col sm="auto">
          <Button
            variant="outline-secondary"
            size="sm"
            to={buildJobPath(`linked_rotations/new`)}
          >
            Add
          </Button>
        </Col>
      </Row>

      <Table
        className={cx("mt-3", css.table)}
        loading={!linkedRotations}
        columns={[
          { title: "Group", dataKey: "group", render: (group) => group.name },
          {
            title: "Rotations",
            dataKey: "block_schedule_rotations",
            render: (val) =>
              val.map((x: BlockScheduleRotationType) => x.name).join(", "),
          },
          {
            title: "Staff Levels",
            dataKey: "staff_levels",
            forceWrap: true,
            render: (val) => val.map((x: StaffLevelType) => x.name).join(", "),
          },
          {
            dataKey: "id",
            position: "right",
            render: (id) => (
              <Button
                variant="outline-secondary"
                size="sm"
                to={buildJobPath(`linked_rotations/${id}/edit`)}
              >
                Edit
              </Button>
            ),
          },
        ]}
        data={linkedRotations}
      />
    </div>
  )
}
