import React, { useContext, useState } from "react"
import { useSelector } from "@app/models"
import VirtualList from "@app/components/VirtualList"
import Shift from "./components/Shift"
import { useFilter, useElementPosition } from "@app/utils/hooks"
import api, { useRequest } from "@app/services/api"
import ScheduleChangeModal from "./components/ScheduleChangeModal"
import { JobBlockType } from "@app/services/mdvShiftCalculation"
import { OnViewNoteUserLoginContext } from "../../context/OnViewNoteUserLoginContext"

import cx from "classnames"

export default () => {
  const { customCalendar } = useSelector((state) => state.customCalendars)
  const { currentOnViewNoteUser } = useSelector((state) => state.users)

  const { printView } = useContext(OnViewNoteUserLoginContext)

  const [pickedJobForScheduleChange, setPickedJobForScheduleChange] =
    useState<null | JobBlockType>(null)

  const [forceReload, setForceReload] = useState<number | null>(null)

  const {
    filterState: { values: selectedGroupIds },
  } = useFilter(["mdv", customCalendar.key, "departments"])

  const { elementRef: containerRef, heightToScreenBottom: containerHeight } =
    useElementPosition()

  const { data: groups = [] } = useRequest<CustomCalendarGroupType[]>(
    customCalendar.key
      ? [api.getCustomCalendarGroups, customCalendar.key]
      : null
  )

  const selectedGroups = groups.filter((x) =>
    selectedGroupIds.includes(x.groupid.toString())
  )

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      {currentOnViewNoteUser?.allow_edit && (
        <ScheduleChangeModal
          jobBlock={pickedJobForScheduleChange}
          customCalendar={customCalendar}
          onViewNoteUser={currentOnViewNoteUser.on_view_note_user!}
          onHide={() => setPickedJobForScheduleChange(null)}
          setForceReload={setForceReload}
        />
      )}
      <VirtualList
        className={cx("shifts-container", printView ? "printView" : "")}
        height={containerHeight}
        virtualThreshold={printView ? -1 : 0}
        overscan={150}
        data={selectedGroups}
        computeItemKey={(_, item) => item.groupid}
        components={{
          Footer: () => <div style={{ paddingBottom: "1rem" }}></div>,
        }}
        itemContent={(_index, item) => (
          <Shift
            group={item}
            customCalendar={customCalendar}
            setPickedJobForScheduleChange={setPickedJobForScheduleChange}
            forceReload={forceReload}
          />
        )}
      />
    </div>
  )
}
