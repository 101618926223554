import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { HashRouter, Routes, Route } from "react-router-dom"
import store, { useDispatch } from "@app/models"
import IndexPage from "./IndexPage"
import CreateOrUpdateJobPage from "./JobWizardPage/CreateOrUpdateJob"
import CreateOrUpdateLinkedRotationPage from "./LinkedRotationPage/CreateOrUpdateLinkedRotation"

const AppRoutes = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch.users.getCurrentUserInfo()
  }, [])

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/jobs/new" element={<CreateOrUpdateJobPage />} />
        <Route path="/jobs/:jobId/edit/*" element={<CreateOrUpdateJobPage />} />
        <Route
          path="/jobs/:jobId/linked_rotations/new"
          element={<CreateOrUpdateLinkedRotationPage />}
        />
        <Route
          path="/jobs/:jobId/linked_rotations/:id/edit"
          element={<CreateOrUpdateLinkedRotationPage />}
        />
      </Routes>
    </HashRouter>
  )
}

export default () => <Provider store={store} children={<AppRoutes />} />
