import React from "react"
import { useForm, Input, Select, FormItem } from "@app/components/Form"
import Button from "@app/components/Button"
import JobFormContainer from "../JobFormContainer"
import api, { useRequest, mutate } from "@app/services/api"
import { handleApiError } from "@app/utils"

type FormFieldsType = {
  day_types: {
    dayid: number
    day_typeid: number
  }[]
}

type Props = {
  job: JobWithAllFieldsType
  nextSection?: string
}

export default (props: Props) => {
  const { job, nextSection } = props
  const jobDays = job.day_types.sort((a, b) => a.dayid - b.dayid)
  const { data: dayTypes } = useRequest<DaytypeType[]>([api.getDayTypes])

  const { handleSubmit, control, setValue } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        day_types: yup.array().of(
          yup.object().shape({
            dayid: yup.number(),
            day_typeid: yup.number().required().label("Day Type"),
          })
        ),
      }),
  })

  const submitForm = (fields: FormFieldsType, handleNextStep: any) => {
    return api.updateJob(job.jobid, fields).then((newJob) => {
      mutate([api.getJob, newJob.jobid], newJob, false)
      handleNextStep()
    }, handleApiError)
  }

  const resetToDefaults = async () => {
    const groupDayTypes: JobDaytypeType[] = await api.getGroupDayTypes()
    const jobDayTypes: FormFieldsType["day_types"] = []

    jobDays.forEach(({ dayid }) => {
      const dayType = groupDayTypes.find((x) => x.dayid == dayid)

      if (dayType) {
        jobDayTypes.push({ dayid, day_typeid: dayType.day_typeid })
      }
    })

    await submitForm({ day_types: jobDayTypes }, () => {
      setValue("day_types", jobDayTypes)
    })
  }

  return (
    <JobFormContainer
      nextSection={nextSection}
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="d-flex justify-content-end mb-3">
        <Button size="sm" variant="primary" onClick={resetToDefaults}>
          Reset to defaults
        </Button>
      </div>
      {jobDays.map((dayType, index) => (
        <FormItem key={dayType.dayid} label={dayType.day_fullname}>
          <Input
            type="hidden"
            name={`day_types.${index}.dayid`}
            control={control}
            defaultValue={dayType.dayid}
          />
          <Select
            control={control}
            name={`day_types.${index}.day_typeid`}
            defaultValue={dayType.day_typeid}
            options={dayTypes || []}
            loading={!dayTypes}
            labelKey="type"
            valueKey="day_typeid"
          />
        </FormItem>
      ))}
    </JobFormContainer>
  )
}
