import React, { useState } from "react"
import Table, { ColumnType } from "@app/components/Table"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import { useControl, UseControlProps } from "@app/components/Form/useForm"
import { ErrorMessage, useWatch } from "@app/components/Form"
import { Dialog } from "@app/components/Modal"
import SearchRoleModal from "./SearchRoleModal"
import api, { useRequest } from "@app/services/api"
import { useCaniuseFeature } from "@app/utils/hooks"
import cx from "classnames"
import css from "./JobRolesField.module.scss"

type JobRoleType = JobType["tigerconnect_roles"][number]

export default (props: UseControlProps) => {
  const { value, onChange, control, error } = useControl(props, {
    nativeValue: [],
  })

  const [showRoleModal, setShowRoleModal] = useState(false)
  const [editingRole, setEditingRole] = useState<JobRoleType | null>(null)

  // Check if job multiple roles sync enabled
  const isMultipleRolesSyncEnabled = useCaniuseFeature(
    "tigerconnect_one_to_multiple_roles_sync"
  )

  // Get the value to check if tigerconnect integration enabled
  const tcIntegration = useWatch({ control, name: "tigerconnect_integration" })

  const { data: tigerconnectSettings } = useRequest<TigerconnectSettingType[]>([
    api.getTigerconnectSettings,
  ])

  const setRoles = (roles: JobRoleType[]) => {
    onChange?.(roles)
  }

  const handleRoleEdit = (role: JobRoleType | null) => {
    setEditingRole(role)
    setShowRoleModal(true)
  }

  const handleRoleAdd = () => {
    if (!isMultipleRolesSyncEnabled && value?.length) {
      return Dialog.warn({
        title: "Notice",
        message:
          "This group is currently configured to allow only one TigerConnect Role association for each Job. " +
          "Please contact your account manager or customer support to enable linking Jobs to additional TigerConnect Roles",
      })
    }

    handleRoleEdit(null)
  }

  const handleRoleRemove = (roleToken: string) => {
    Dialog.confirm({
      title: "Confirm",
      message: "Are you sure that you want to delete this role mapping?",
      ok() {
        const newRoles = value.filter(
          (item: JobRoleType) => item.entity_token !== roleToken
        )

        setRoles(newRoles)
      },
    })
  }

  const columns: ColumnType<JobRoleType>[] = [
    { title: "Label", dataKey: "tigerconnect_setting_label" },
    { title: "Org Token", dataKey: "tigerconnect_setting_organization_token" },
    {
      title: "Tag",
      dataKey: "entity_tag_name",
      render: (tagName: string, role) => (
        <span style={{ color: role.entity_tag_color }}>{tagName}</span>
      ),
    },
    { title: "Role Name", dataKey: "entity_name" },
    {
      dataKey: "entity_token",
      render: (entity_token: string, role) => (
        <div className={css.actions}>
          <Icon name="pencil" onClick={() => handleRoleEdit(role)} />
          <Icon
            name="close"
            className="ml-3"
            onClick={() => handleRoleRemove(entity_token)}
          />
        </div>
      ),
    },
  ]

  // Remove Label column when there is less than 1 tigerconnect setting
  if (!tigerconnectSettings || tigerconnectSettings.length <= 1) {
    columns.shift()
  }

  return (
    <>
      <Table
        striped={false}
        hover={false}
        columns={columns}
        data={value}
        rowKey="entity_token"
        className={cx(css.table, {
          [css.tableWithLineThrough]: !tcIntegration,
        })}
        emptyMessage="Please add at least one tigerconnect role."
      />
      <ErrorMessage message={error?.message} />
      <Button type="primary" size="sm" className="mt-4" onClick={handleRoleAdd}>
        Add a Role
      </Button>
      {showRoleModal && (
        <SearchRoleModal
          show={showRoleModal}
          onHide={() => setShowRoleModal(false)}
          editingRole={editingRole}
          tigerconnectSettings={tigerconnectSettings}
          roles={value}
          setRoles={setRoles}
        />
      )}
    </>
  )
}
