import React, { useState, useEffect } from "react"
import { Dropdown, FormControl } from "react-bootstrap"

interface FilterDropdownProps {
  selected?: string
  items: string[]
  getSelected: (selected: string) => void
}

const FilterDropdown: React.FC<any> = (
  props: FilterDropdownProps
): JSX.Element => {
  const [filter, setFilter] = useState("")
  const [items, setItems] = useState<any>([])
  const [selected, setSelected] = useState(
    props.selected || "No selection made"
  )

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  useEffect(() => {
    if (props.selected) {
      setSelected(props.selected)
    }
  }, [props.selected])

  useEffect(() => {
    if (filter) {
      const filteredItems = [...props.items].filter((item) =>
        new RegExp(`${filter.toLowerCase()}*`).test(item.toLowerCase())
      )
      setItems(filteredItems)
    } else {
      setItems(props.items)
    }
  }, [filter])

  const onSelect = (eventKey: any, e: any) => {
    setSelected(eventKey)
    props.getSelected(eventKey)
  }

  return (
    <div style={{ marginBottom: "15px" }}>
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle>{selected} &#x25bc;</Dropdown.Toggle>
        <Dropdown.Menu>
          <FormControl
            autoFocus
            placeholder="Type to filter"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
          />
          <ul style={{ listStyle: "none" }}>
            {items.map((item: any, index: number) => (
              <li key={index} style={{ marginRight: "10px" }}>
                <Dropdown.Item eventKey={item}>{item}</Dropdown.Item>
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default FilterDropdown
