//TIMEPICKETINPUT
import React, { useState, useEffect } from "react"
import { useMountedEffect } from "@app/utils/hooks"
import TimeKeeper from "react-timekeeper"
import Dropdown from "@app/components/Dropdown"
import Icon from "@app/components/Icon"
import { formatDate, parseDate, isIE } from "@app/utils"
import { DateFormatter } from "@app/utils/constants"
import cx from "classnames"
import css from "./TimePickerInput.module.scss"

type Props = {
  format?: string
  placeholder?: string
  timePickerProps?: React.ComponentProps<typeof TimeKeeper>
  onTimeChange?: (timeStr?: string, event?: React.SyntheticEvent) => any
  inputProps?: PlainObjectType
  defaultValue?: any
  disabled?: boolean
  value?: any
}

export default React.forwardRef<any, Props>((props, ref) => {
  const {
    format = DateFormatter.hourMinute12h,
    placeholder,
    timePickerProps = {},
    onTimeChange,
    inputProps = {},
    disabled,
    defaultValue,
    value,
  } = props

  const [innerValue, setInnerValue] = useState<string | undefined>()

  useEffect(() => {
    if (value == undefined && innerValue == undefined) {
      defaultValue && setInnerValue(defaultValue)
    } else {
      setInnerValue(value)
    }
  }, [value, defaultValue])

  useMountedEffect(() => {
    onTimeChange?.(innerValue)
  }, [innerValue])

  const formattedValue = !!innerValue
    ? formatDate(
        parseDate(innerValue, (f) => f.timeOnly),
        format
      )
    : undefined

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const value = event.target.value

    if (value.trim() === formattedValue || isValidTimeFormat(value.trim())) {
      try {
        const time = formatDate(parseDate(value, format), (f) => f.timeOnly)
        setInnerValue(time)
        onTimeChange?.(time, event)
      } catch (e) {}
    } else {
      event.target.value = formattedValue !== undefined ? formattedValue : ""
    }
  }

  const isValidTimeFormat = (time: string) => {
    const regExp = new RegExp(/^\d{2}:\d{2} (AM|PM)$/)
    return regExp.test(time)
  }

  const popperConfig = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-240, 4],
        },
      },
    ],
  }

  return (
    <div className={cx(css.timePickerInput, { [css.timePickerInIE]: isIE })}>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={formattedValue}
        onChange={onInputChange}
        disabled={disabled}
        {...inputProps}
      />
      <div className={cx(css.dropdownContainer, { "pe-none": disabled })}>
        <Dropdown
          variant="timePicker"
          overlay={
            <Dropdown.Menu popperConfig={popperConfig}>
              <TimeKeeper
                onChange={(time) => {
                  setInnerValue(`${("0" + time.formatted24).slice(-5)}:00`)
                }}
                time={formattedValue}
                {...timePickerProps}
              />
            </Dropdown.Menu>
          }
        >
          <Icon name="clock" className={css.clockIcon} />
        </Dropdown>
      </div>
    </div>
  )
})
