import React, { useState } from "react"
import { useSelector, useDispatch } from "@app/models"
import { Select } from "@app/components/Form"
import { H5 } from "@app/components/Typography"
import Button from "@app/components/Button"
import Modal from "@app/components/Modal"
import api, { useRequest } from "@app/services/api"

type Props = {
  annualBlockScheduleId: string
}

export default (props: Props) => {
  const { annualBlockScheduleId: id } = props

  const [internalHighlightRotation, setInternalHighlightRotation] = useState<
    string[]
  >([])

  const dispatch = useDispatch()

  const { highlightRotation, quickAssignmentOrHighlight } = useSelector(
    (state) => state.rotationOperations
  )

  const highlightShow = () => quickAssignmentOrHighlight == "Highlight"

  const onModalClose = () => {
    dispatch.rotationOperations.updateRotationOperations({
      quickAssignmentOrHighlight: undefined,
    })
  }

  const showModal = () => {
    dispatch.rotationOperations.updateRotationOperations({
      quickAssignmentOrHighlight: "Highlight",
    })
  }

  const updateHighlightRotation = () => {
    dispatch.rotationOperations.updateRotationOperations({
      highlightRotation: internalHighlightRotation,
      quickAssignmentRotation: undefined,
    })
  }

  const { data: annualRotations = [] } = useRequest<
    AnnualBlockScheduleRotationType[]
  >([api.getAnnualBlockScheduleRotations, id])

  const rotationLabel = () => {
    const rotation = annualRotations.find((item) =>
      highlightRotation.includes(item.id)
    )

    return rotation ? (
      <H5 ellipsis prefixDotColor={rotation.block_schedule_rotation.color}>
        {rotation.block_schedule_rotation.name}...
      </H5>
    ) : (
      "Rotations"
    )
  }

  return (
    <>
      <Button shape="pill" onClick={showModal} variant="light" size="xs">
        {rotationLabel()}
      </Button>
      <Modal
        title="Select the rotation to highlight"
        size="lg"
        show={highlightShow()}
        onHide={onModalClose}
        buttons={[
          {
            text: "Confirm",
            variant: "outline-success",
            onClick: updateHighlightRotation,
          },
        ]}
      >
        <Select
          inline
          multiple
          options={annualRotations}
          labelKey="block_schedule_rotation.name"
          value={highlightRotation}
          renderOption={(text, item) => (
            <H5 ellipsis prefixDotColor={item.block_schedule_rotation.color}>
              {text}
            </H5>
          )}
          onChange={(val) => setInternalHighlightRotation(val)}
        />
      </Modal>
    </>
  )
}
