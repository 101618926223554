import React from "react"
import { Select, useForm, FormItem } from "@app/components/Form"
import JobFormContainer from "../JobFormContainer"
import api, { useRequest, mutate } from "@app/services/api"
import { handleApiError } from "@app/utils"

type FormFieldsType = {
  provider_type_ids: number[]
}

type ProvidertypeType = {
  provider_typeid: number
  type: string
  abbrev: string
}

type Props = {
  job: JobWithAllFieldsType
  nextSection?: string
}

export default (props: Props) => {
  const { job, nextSection } = props
  const { data: providerTypes } = useRequest<ProvidertypeType[]>([
    api.getProviderTypes,
  ])

  const { control, handleSubmit } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        provider_type_ids: yup.array().ensure().of(yup.number()),
      }),
  })

  const submitForm = (fields: FormFieldsType, handleNextStep: any) => {
    return api.updateJob(job.jobid, fields).then((newJob) => {
      mutate([api.getJob, newJob.jobid], newJob)
      handleNextStep()
    }, handleApiError)
  }

  const providerTypesOptions = providerTypes?.map((providerType) => ({
    id: providerType.provider_typeid,
    name: `${providerType.type} (${providerType.abbrev})`,
  }))

  return (
    <JobFormContainer
      nextSection={nextSection}
      onSubmit={handleSubmit(submitForm)}
    >
      <FormItem
        label="Provider Types"
        name="provider_type_ids"
        control={control}
      >
        <Select
          multiple
          inline
          loading={!providerTypes}
          options={providerTypesOptions || []}
          defaultValue={job.provider_types.map((x) => x.provider_typeid)}
        />
      </FormItem>
    </JobFormContainer>
  )
}
