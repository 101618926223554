import React from "react"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import { JobsAndDatesType } from "./ConfirmDailyAssignments"
import { H4 } from "@app/components/Typography"
import { TemplateResultsType } from "../ApplyDailyAssignmentTemplate"
import { formatDate, parseDate } from "@app/utils"

type PropsType = {
  jobsList: JobsAndDatesType[]
  appliedTemplateResults: TemplateResultsType
  closeModal?: () => void
}

export default (props: PropsType) => {
  const { appliedTemplateResults, jobsList, closeModal } = props

  const columns = [
    {
      title: "Daily Assignments Created:",
      dataKey: "title",
    },
    {
      title: "",
      dataKey: "value",
    },
  ]

  const renderTime = (time: string) => {
    if (!time) {
      return
    }

    const date = parseDate(time, (f) => f.timeOnly)
    return formatDate(date, (f) => f.timeAmPm)
  }

  const getJobsAndQuantity = (validationType: string) => {
    const filteredJobs = jobsList.filter(
      (job: JobsAndDatesType) =>
        job.validation && job.validation.type === validationType
    )

    return (
      <div className="d-flex flex-row">
        <H4 className="mr-3">{filteredJobs.length}</H4>
        <div>
          {filteredJobs.map((job: JobsAndDatesType) => {
            return (
              <p>
                ({job.abbrev} {job.start_date} {renderTime(job.starttime)}-
                {renderTime(job.endtime)})
              </p>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Table
        columns={columns}
        data={[
          {
            title: "Assignments processed from template:",
            value: <H4>{jobsList && jobsList.length}</H4>,
          },
          {
            title: "Assignments successfully created:",
            value: (
              <H4>{appliedTemplateResults && appliedTemplateResults.length}</H4>
            ),
          },
          {
            title: "Assignments skipped due to max seats reached:",
            value: getJobsAndQuantity("max_day_assignment"),
          },
          {
            title: "Assignments skipped due to provider day off:",
            value: getJobsAndQuantity("day_off"),
          },
          {
            title: "Assignments skipped due to time and days unavailable:",
            value: getJobsAndQuantity("time_and_days"),
          },
        ]}
        hover={false}
        striped={false}
      />
      {closeModal && (
        <div className="mt-5 d-flex flex-row justify-content-end">
          <Button onClick={closeModal}>Ok</Button>
        </div>
      )}
    </div>
  )
}
