import {
  pick as lodashPick,
  isEmpty,
  isArray,
  isPlainObject,
  isMatchWith,
} from "lodash"

function isAllEmpty(a: any, b: any) {
  return [a, b].some((x) => x === true) ? false : isEmpty(a) && isEmpty(b)
}

// Check if 2 array have same contents
// [1, 2, 3] is equal [1, 3, 2]
function isArraySameContent<T>(arr1: T[], arr2: T[]) {
  return arr1.length == arr2.length && arr1.every((x) => arr2.includes(x))
}

function isObjectSameContent<T extends PlainObjectType>(
  object: T,
  source: T
): boolean {
  return isMatchWith(object, source, (a, b) => {
    if (isArray(a) && isArray(b)) {
      return isArraySameContent(a, b)
    } else if (isPlainObject(a) && isPlainObject(b)) {
      return isObjectSameContent(a, b)
    } else {
      return a == b || isAllEmpty(a, b)
    }
  })
}

export function isFormStateChanged(
  currentState: PlainObjectType,
  options: { baseState?: PlainObjectType; fields?: string[] } = {}
) {
  const fields = options.fields || Object.keys(currentState)
  const state = lodashPick(currentState, fields)

  if (options.baseState) {
    const baseState = lodashPick(options.baseState, fields)
    return !isObjectSameContent(state, baseState)
  } else {
    // Check values of Object does not all empty,
    // it should be changed if field value is not empty
    return Object.entries(state).some(([_, value]) =>
      isArray(value) ? Boolean(value.length) : value === 0 || Boolean(value)
    )
  }
}
