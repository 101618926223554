import { useContext } from "react"
import { ManualScheduleTableContext } from "../context/ManualScheduleJobTableContext"

export const useValidProviders = (jobId?: number) => {
  const {
    isResidentJob,
    residentJobsProviders,
    providers,
    currentSplitShiftJobId,
    edate,
  } = useContext(ManualScheduleTableContext)

  const isLoading = isResidentJob && !residentJobsProviders
  let validProviders = []

  if (isResidentJob) {
    const currentJobId = jobId || currentSplitShiftJobId
    const item = residentJobsProviders?.find((x) => x.jobid === currentJobId)

    validProviders = item?.providers || []
  } else {
    validProviders = providers.filter(
      (p) => !p.end_date || Date.parse(p.end_date) >= Date.parse(edate)
    )
  }

  return { validProviders, isLoading }
}
