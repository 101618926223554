import React, { useState } from "react"
import { FormItem, Input, useForm } from "@app/components/Form"
import Button from "@app/components/Button"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import css from "./SaveTemplate.module.scss"
import cx from "classnames"
import { H4 } from "@app/components/Typography"

type FormFieldsType = {
  template_name: string
  start_date: string
  end_date: string
}

export const SaveTemplate = () => {
  const [templateid, setTemplateid] = useState<string | undefined>()
  const [templateName, setTemplateName] = useState<string | undefined>()

  const { control, handleSubmit, reset } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        template_name: yup.string().required("Template name is required"),
        start_date: yup
          .date()
          .required("Start date is an invalid date")
          .max(yup.ref("end_date"), "Start date must be less than end date"),
        end_date: yup
          .date()
          .required("End date is an invalid date")
          .min(
            yup.ref("start_date"),
            "End date must be greater than start date"
          ),
      }),
  })

  const onSubmit = (formData: FormFieldsType) => {
    api.saveTemplate(formData).then((data) => {
      setTemplateid(data.templateid)
      setTemplateName(formData.template_name)
      reset({})
    }, handleApiError)
  }

  return (
    <div className="man-schedule-jobs-page popup-view">
      <div className={css.templateForm}>
        <H4 className={css.subHeader}>
          Use the form below to save a new template based on the current
          schedule
        </H4>
        <form id="template-form-container" onSubmit={handleSubmit(onSubmit)}>
          <FormItem label="Template Name">
            <Input name="template_name" control={control} />
          </FormItem>
          <FormItem label="Start Date">
            <Input type="date" name="start_date" control={control} />
          </FormItem>
          <FormItem label="End Date">
            <Input type="date" name="end_date" control={control} />
          </FormItem>

          <div className={cx("col-xs-3 col-xs-offset-4", css.submit)}>
            <Button type="submit" className="btn-primary">
              Save Template
            </Button>
          </div>
        </form>
      </div>
      <div className={css.links}>
        {templateid && (
          <>
            <p>Your new template {templateName} was successfully created.</p>
            <a
              href={`/calendar/template_schedule.cgi?templateid=${templateid}`}
              target="_blank"
            >
              Click here
            </a>{" "}
            to access the template repository if you would like to use your
            template to create new assignments.
          </>
        )}
      </div>
    </div>
  )
}
