import React, { useReducer } from "react"
import BlockSchedulingForm from "@app/containers/layouts/BlockSchedulingForm"
import Table from "@app/components/Table"
import { Input, ColorPicker, useForm } from "@app/components/Form"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import { Dialog } from "@app/components/Modal"
import { randomID, handleApiError } from "@app/utils"
import { RotationDefaultColor } from "@app/utils/constants"
import mapValues from "lodash/mapValues"
import produce from "immer"
import { useSelector } from "@app/models"
import api, { useRequest, mutate } from "@app/services/api"
import css from "./EditRotations.module.scss"

type FormFieldsType = Record<
  string,
  {
    name: string
    abbrev: string
    color: string
  }
>

const generateNewRotation = () => ({
  _isNewRecord: true,
  id: randomID({ prefix: "rotation" }),
})

export default () => {
  const [newRecords, addNewRecords] = useReducer(
    produce((state: ReturnType<typeof generateNewRotation>[]) => {
      state.push(generateNewRotation())
    }),
    [generateNewRotation()]
  )

  const [storedIds, addStoredIds] = useReducer(
    produce(
      (
        state: { editing: string[]; deleted: string[] },
        { type, payload }: { type: "editing" | "deleted"; payload: string }
      ) => {
        state[type].push(payload)
      }
    ),
    { editing: [], deleted: [] }
  )

  const { data: rotations } = useRequest([api.getBlockScheduleRotations])
  const { groupid } = useSelector((state) => state.users.currentUser)
  const { handleSubmit, control, getValues, setFocus } =
    useForm<FormFieldsType>({
      shouldUnregister: true,
      schema: (yup) =>
        yup.lazy((obj: any) =>
          yup.object(
            mapValues(obj, () =>
              yup.object({
                name: yup
                  .string()
                  .max(80)
                  .label("Name")
                  .required("Name must be entered for a Rotation")
                  .test(
                    "unique",
                    "Rotation must have a unique Name",
                    onCheckFieldUnique
                  ),
                abbrev: yup
                  .string()
                  .max(20)
                  .label("Abbreviation")
                  .required("Abbreviation must be entered for a Rotation")
                  .test(
                    "unique",
                    "Rotation must have a unique Abbreviation",
                    onCheckFieldUnique
                  ),
              })
            )
          )
        ),
    })

  // An Yup helper method to check if field is unique
  const onCheckFieldUnique = (value: any, context: any) => {
    const [recordId, fieldName] = context.path.split(".")
    const values: string[] = []

    tableDataSources.every((record) => {
      if (record.id == recordId) {
        return false
      }

      const data: PlainObjectType = getValues(record.id)
      const feildValue = (data || record)[fieldName]

      if (feildValue) {
        values.push(feildValue)
      }

      return true
    })

    // Validate with case insensitive
    return values.every((val) => val.toLowerCase() !== value?.toLowerCase())
  }

  const onSubmit = (fields: FormFieldsType, cb: any) => {
    const rotations = Object.entries(fields).reduce(
      (
        acc: Optional<BlockScheduleRotationType, "id" | "color">[],
        [key, value]
      ) => {
        const field = { ...value, groupid }
        // key start with "rotation" is temp ID for Rotation which created by `generateNewRotation`
        return [...acc, /^rotation/.test(key) ? field : { id: key, ...field }]
      },
      []
    )

    if (!rotations.length) {
      cb()
      return
    }

    return api.postBatchUpdateBlockScheduleRotations(rotations).then(() => {
      mutate([api.getBlockScheduleRotations])
      cb()
    }, handleApiError)
  }

  const onRemove = (item: any) => {
    const addIdToDeletedStore = () => {
      addStoredIds({ type: "deleted", payload: item.id })
    }

    Dialog.confirm({
      title: "Confirm",
      message: `Are you sure to remove this ${
        item._isNewRecord ? "row" : "record"
      }?`,
      ok() {
        item._isNewRecord
          ? addIdToDeletedStore()
          : api.deleteBlockScheduleRotation(item.id).then(() => {
              addIdToDeletedStore()
            }, handleApiError)
      },
    })
  }

  const onEdit = (item: any) => {
    addStoredIds({ type: "editing", payload: item.id })
    setTimeout(() => setFocus(`${item.id}.name`)) // set Name field focus
  }

  // If last row is filled both on name and abbrev,
  // a new line will be auto added
  const listenChange = () => {
    const lastRecord = newRecords[newRecords.length - 1]
    const data = lastRecord && getValues(lastRecord.id)

    if (!data) {
      return
    }

    if (data.name && data.abbrev) {
      addNewRecords()
    }
  }

  const isEditing = (item: any) =>
    item._isNewRecord || storedIds.editing.indexOf(item.id) > -1

  const renderAttribute = (
    val: string,
    item: any,
    context: { dataKey: string }
  ) =>
    isEditing(item) ? (
      <Input
        name={`${item.id}.${context.dataKey}`}
        control={control}
        defaultValue={val}
        onChange={listenChange}
      />
    ) : (
      val
    )

  const renderColor = (val: string, item: any) =>
    isEditing(item) ? (
      <ColorPicker
        defaultValue={item.color || RotationDefaultColor}
        name={`${item.id}.color`}
        control={control}
      />
    ) : (
      <ColorPicker.ColorField color={val} />
    )

  const renderAction = (_: any, item: any) =>
    isEditing(item) ? (
      <Button
        shape="circle"
        variant="outline-secondary"
        className={css.removeButton}
        onClick={() => onRemove(item)}
      >
        <Icon name="close" />
      </Button>
    ) : (
      <Button
        size="sm"
        variant="outline-secondary"
        onClick={() => onEdit(item)}
      >
        Edit
      </Button>
    )

  if (!rotations) {
    return null
  }

  const tableDataSources = [...rotations, ...newRecords].filter(
    (item) => storedIds.deleted.indexOf(item.id) < 0
  )

  return (
    <BlockSchedulingForm
      title="Rotations"
      onSubmit={handleSubmit(onSubmit)}
      formControl={control}
    >
      <Table
        data={tableDataSources}
        columns={[
          {
            dataKey: "name",
            title: "Name",
            render: (val, item) =>
              renderAttribute(val, item, { dataKey: "name" }),
          },
          {
            dataKey: "abbrev",
            title: "Abbreviation",
            render: (val, item) =>
              renderAttribute(val, item, { dataKey: "abbrev" }),
          },
          { dataKey: "color", title: "Color", render: renderColor },
          { dataKey: "action", position: "center", render: renderAction },
        ]}
      />
    </BlockSchedulingForm>
  )
}
