import React, { useContext } from "react"
import { ManualScheduleTableContext } from "../context/ManualScheduleJobTableContext"
import ManualScheduleJobRow from "./ManualScheduleJobRow/ManualScheduleJobRow"

export interface IManualScheduleJobRowsProps {
  host: string
  script: string
  view: string
  year: string
  month: string
  day: string
  week: string
  curProviderid: string
  curJobid: string
  wt: string
  tab: string
}
const ManualScheduleJobRows: React.FC<IManualScheduleJobRowsProps> = (
  props
) => {
  const { currentSplitShiftJobId, currentMultipleAssignmentsJobId, jobs } =
    useContext(ManualScheduleTableContext)
  return (
    <>
      {currentSplitShiftJobId === null &&
      currentMultipleAssignmentsJobId === null
        ? jobs.map((j) => {
            let flagHref = ""
            if ((j.splitShifts && j.splitShifts.length == 0) || !j.providerid)
              flagHref = `${props.host + props.script}?action=flag&view=${
                props.view
              }&year=${props.year}&month=${props.month}&day=${
                props.day
              }&jobid=${j.jobid}&week=${props.week}&wt=${
                props.wt
              }&cur_providerid=${props.curProviderid}&cur_jobid=${
                props.curJobid
              }`
            const jobHref = `${
              props.host + props.script
            }?action=schedule&view=${props.view}&year=${props.year}&month=${
              props.month
            }&day=${props.day}&jobid=${j.jobid}&week=${props.week}&wt=${
              props.wt
            }&cur_providerid=${props.curProviderid}&cur_jobid=${props.curJobid}`
            return (
              <ManualScheduleJobRow
                key={j.jobid}
                flagHref={flagHref}
                jobHref={jobHref}
                name={`${j.name}`}
                flag={`${j.flag}`}
                starttime={`${j.starttime}`}
                endtime={`${j.endtime}`}
                scheduled={`${j.scheduled}`}
                jobid={j.jobid}
                splitShifts={j.splitShifts}
                multiple_assignments={j.multiple_assignments}
                additionalAssignments={j.additionalAssignments}
              />
            )
          })
        : null}
    </>
  )
}

export default ManualScheduleJobRows
