import "core-js/stable"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "./utils/polyfills"
import "./utils/common"
import "./styles/icons/icons.font"
import "./styles/global.scss"
import "./styles/common/colors.css"

const environment = process.env.NODE_ENV || "production"

// Start API mocking server if possible
if (environment !== "production") {
  let mockApiServer: any | undefined

  try {
    mockApiServer = require("./mocks").mockApiServer
  } catch (e) {
    // Mocks module is not allowed to load
  }

  mockApiServer?.({ environment })
}

const ReactUJS = require("react_ujs")
const componentRequireContext = require.context(
  "./containers",
  true,
  /^((?!\.(test|d)).)*$/
)

// Load all components
Object.assign(window, { ReactUJS })
ReactUJS.useContext(componentRequireContext)
