import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Icon from "@app/components/Icon"
import cx from "classnames"
import css from "./Collapse.module.scss"

type Props = {
  title: React.ReactNode
  subTitle?: React.ReactNode
  action?: React.ReactNode
  previewContent?: React.ReactNode
  eventKey?: string
  defaultOpen?: boolean
  collapseIcon?: boolean
  className?: string
  children?: any
  disabled?: boolean
  onToggle?: (currentStatus: boolean) => any
}

export default function (props: Props) {
  const {
    title,
    subTitle,
    action,
    previewContent,
    eventKey = "0",
    defaultOpen = false,
    collapseIcon = true,
    onToggle,
    className,
    disabled,
    children,
  } = props
  const [open, setOpen] = useState<boolean>(defaultOpen)

  const handleToggle = (newStatus: boolean) => {
    if (disabled) {
      return
    }

    setOpen(newStatus)
    onToggle?.(newStatus)
  }

  return (
    <Accordion
      activeKey={open ? eventKey : "__none__"}
      className={cx(css.collapse, { [css.expanded]: open }, className)}
    >
      <Accordion.Toggle
        as="div"
        eventKey={eventKey}
        className="collapse-toggle"
      >
        <div
          className={cx("d-flex align-items-center", css.toggleRow, {
            [css.disabled]: disabled,
          })}
          onClick={() => handleToggle(!open)}
        >
          <span className={cx("flex-fill text-truncate", css.title)}>
            {title}
          </span>
          {collapseIcon && (
            <Icon name="chevron-right" className={css.arrowIcon} />
          )}
          {action && <div className={css.action}>{action}</div>}
        </div>
      </Accordion.Toggle>
      {subTitle}
      {children && (
        <Accordion.Collapse
          eventKey={eventKey}
          className={cx("collapse-content", css.content)}
        >
          <div>{children}</div>
        </Accordion.Collapse>
      )}
      {!open && previewContent && (
        <div className={css.previewContent}>{previewContent}</div>
      )}
    </Accordion>
  )
}
