import React from "react"
import { useDispatch, useSelector } from "@app/models"
import Modal from "@app/components/Modal"
import { FormItem, Input, Select, useForm } from "@app/components/Form"
import { TallyCountModes } from "@app/services/tallyCalculation"
import api, { useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"

type Props = {
  id: string
  show: boolean
  blockSet?: BlockSetType
  onHide: (status: boolean) => any
}

type FormFieldsType = {
  name: string
  block_layout_id: string
  count_blocks: number
  staff_level_ids: string[]
}

export default ((props) => {
  const { id, show, onHide, blockSet } = props
  const {
    control,
    handleModalSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        name: yup.string().required().label("Name"),
        block_layout_id: yup.string().required().label("Block Layout"),
        count_blocks: yup.number().required().label("Tally Count Mode"),
        staff_level_ids: yup
          .array()
          .ensure()
          .of(yup.string())
          .min(1)
          .label("Staff Levels"),
      }),
  })

  const dispatch = useDispatch()
  const { annualBlockSchedule, blockSets } = useSelector(
    (state) => state.blockSets
  )
  const { data: staffLevels = [] } = useRequest<StaffLevelType[]>([
    api.getGroupStaffLevels,
  ])

  const onSubmit = (fields: FormFieldsType, closeModal: Function) => {
    const handler = blockSet
      ? api.updateBlockSet(blockSet.id, fields)
      : api.createBlockSet({ ...fields, annual_block_schedule_id: id })

    return handler.then((res) => {
      dispatch.blockSets.getBlockSets({ id })
      closeModal()
    }, handleApiError)
  }

  const onModalClose = (status: boolean) => {
    onHide(status)
    resetForm()
  }

  const staffLevelsOptions = staffLevels.map((sl) => {
    const usedStaffLevelIds: string[] = []

    blockSets.forEach((bs) => {
      if (bs.id === blockSet?.id) return
      bs.staff_levels?.forEach(({ id }) => usedStaffLevelIds.push(id))
    })

    return {
      id: sl.id,
      name: sl.name,
      disabled: usedStaffLevelIds.indexOf(sl.id) >= 0,
    }
  })

  return (
    <Modal
      title={`${blockSet ? "Edit" : "Add"} Block Set`}
      show={show}
      onHide={onModalClose}
      size="lg"
      buttons={[
        { text: "Cancel", variant: "outline-primary" },
        {
          control,
          text: "Save",
          onClick: handleModalSubmit(onSubmit),
        },
      ]}
    >
      <div className="p-5">
        <FormItem
          required
          label="Name"
          name="name"
          control={control}
          defaultValue={blockSet?.name}
        >
          <Input />
        </FormItem>
        <FormItem
          required
          disabled={!!blockSet}
          label="Block Layout"
          name="block_layout_id"
          control={control}
          defaultValue={blockSet?.block_layout_id}
        >
          <Select options={annualBlockSchedule?.block_layouts} />
        </FormItem>
        <FormItem
          required
          label="Tally Count Mode"
          name="count_blocks"
          control={control}
          defaultValue={blockSet?.count_blocks}
        >
          <Select options={TallyCountModes} />
        </FormItem>
        <FormItem
          required
          label="Staff Levels"
          name="staff_level_ids"
          control={control}
          defaultValue={blockSet?.staff_levels?.map((sl) => sl.id)}
        >
          <Select multiple inline options={staffLevelsOptions} />
        </FormItem>
      </div>
    </Modal>
  )
}) as React.FC<Props>
