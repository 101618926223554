import React from "react"
import { useSelector } from "@app/models"
import { SharedCalendarViewRow } from "../../SharedCalendarView"
import BlockSetInfo from "./BlockSetInfo"
import Block from "./Block"

type Props = {
  blockSet: BlockSetType
}

export default (props: Props) => {
  const { blockSet } = props

  const {
    computedBlocksData,
    blockConfig: { height },
  } = useSelector((state) => state.blockSets)

  return (
    <SharedCalendarViewRow
      sideContent={<BlockSetInfo {...blockSet} blockHeight={height} />}
      mainContent={(computedBlocksData[blockSet.id] || []).map((block) => (
        <Block key={block.id} {...block} blockHeight={height} />
      ))}
    />
  )
}
