import React from "react"
import Modal, { Dialog } from "@app/components/Modal"
import { Input, FormItem, useForm } from "@app/components/Form"
import api, { useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"

type Props = {
  staffLevel?: StaffLevelType | PlainObjectType
  show: boolean
  onHide: any
}

type FormFieldsType = {
  name: string
}

export default (props: Props) => {
  const { show, onHide } = props
  const staffLevel = props.staffLevel?.id ? props.staffLevel : undefined

  const { data: staffLevels, mutate } = useRequest<StaffLevelType[]>([
    api.getStaffLevels,
  ])

  const {
    control,
    handleModalSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        name: yup.string().max(80).required().label("Name"),
      }),
  })

  const onModalClose = () => {
    resetForm()
    onHide()
  }

  const onSubmit = ({ name }: FormFieldsType, closeModal: Function) => {
    if (!staffLevels) {
      return
    }

    if (staffLevel) {
      const { id, name: oldName } = staffLevel

      // Do nothing if name not changed
      if (name === oldName) {
        closeModal()
        return
      }

      return api.updateStaffLevel(id, name).then((res) => {
        const index = staffLevels.findIndex((x) => x.id === id)
        index >= 0 && (staffLevels[index] = res)

        mutate(staffLevels, false)
        closeModal()
      }, handleApiError)
    } else {
      return api.createStaffLevel(name).then((res) => {
        mutate([...staffLevels, res])
        closeModal()
      }, handleApiError)
    }
  }

  const onDelete = (closeModal: Function) => {
    if (!staffLevels || !staffLevel) {
      return
    }

    Dialog.confirm({
      title: "Confirm",
      message: (
        <span>
          Are you sure to delete <b>{staffLevel.name}</b> ?
        </span>
      ),
      ok() {
        return api.deleteStaffLevel(staffLevel.id).then(() => {
          mutate(staffLevels.filter((sl) => sl.id !== staffLevel.id))
          closeModal()
        }, handleApiError)
      },
    })

    return false
  }

  const buttons: any[] = [
    { text: "Cancel", variant: "outline-primary" },
    {
      control,
      text: staffLevel ? "Save" : "Create",
      variant: "primary",
      onClick: handleModalSubmit(onSubmit),
    },
  ]

  // Add [Delete] button
  if (staffLevel) {
    buttons.unshift({
      text: "Delete",
      variant: "outline-danger",
      position: "left",
      onClick: onDelete,
    })
  }

  return (
    <Modal
      disableBodyScroll
      title={`${staffLevel ? "Edit" : "Add"} Custom Staff Level`}
      show={show}
      onHide={onModalClose}
      buttons={buttons}
    >
      <FormItem
        label="Name"
        name="name"
        control={control}
        defaultValue={staffLevel?.name}
      >
        <Input autoFocus />
      </FormItem>
    </Modal>
  )
}
