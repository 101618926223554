import React, { useContext, useEffect, useState } from "react"
import { useDispatch } from "@app/models"
import api, { mutate, useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"
import CreateOrUpdateDailyTemplate from "./components/CreateOrUpdateDailyTemplate"
import { Dialog } from "@app/components/Modal"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import { Text } from "@app/components/Typography"
import _ from "lodash"
import { QuickAssignmentContext } from "../../context/QuickAssignmentContext"

type Props = {
  id: string
}

type TemplateType = {
  id: string
  name: string
  rotations: AnnualBlockScheduleRotationType[]
  staff_levels: StaffLevelType[]
}

export default ({ id }: Props) => {
  const dispatch = useDispatch()
  const [view, setView] = useState<"list" | "individual">("list")
  const [annualBlockScheduleTemplate, setAnnualBlockScheduleTemplate] =
    useState<TemplateType>()

  const { data: templates } = useRequest<TemplateType[]>([api.getTemplates, id])

  const { setQuickAssignmentTemplate } = useContext(QuickAssignmentContext)

  useEffect(() => {
    dispatch.rotationOperations.updateRotationOperations({
      quickAssignmentRotation: undefined,
    }),
      setQuickAssignmentTemplate(undefined)
  }, [])

  function resetViews() {
    setView("list")
    setAnnualBlockScheduleTemplate(undefined)
  }

  const handleTemplateDelete = (template: TemplateType) => {
    Dialog.confirm({
      title: "Delete Template",
      message: (
        <p>
          Are you sure you want to delete this template? Action can not be
          undone.
        </p>
      ),
      buttons: {
        ok: {
          text: "Delete",
          variant: "danger",
          onClick() {
            return api.deleteTemplate(template.id).then((_res) => {
              mutate([api.getTemplates, id])
            }, handleApiError)
          },
        },
      },
    })
  }

  function renderList() {
    return (
      <>
        <div className="text-right mb-4">
          <Button size="sm" onClick={() => setView("individual")}>
            Add Template
          </Button>
        </div>
        <Table
          variant="frame"
          loading={!templates}
          data={templates?.sort((a, b) => a.name.localeCompare(b.name))}
          columns={[
            {
              dataKey: "name",
              title: "Template Name",
              sorter: true,
            },
            {
              dataKey: "rotations",
              title: "Rotations",
              render: (rotations: AnnualBlockScheduleRotationType[]) =>
                rotations?.map((rotation) => (
                  <Text
                    key={rotation?.id}
                    prefixDotColor={rotation?.block_schedule_rotation?.color}
                  >
                    {rotation?.block_schedule_rotation?.name}
                  </Text>
                )),
            },
            {
              dataKey: "staff_levels",
              title: "Staff Level(s)",
              render: (staff_levels) =>
                staff_levels.map((staffLevel: any) => (
                  <Text key={staffLevel?.id}>
                    {staffLevel?.name} ({staffLevel?.count})
                  </Text>
                )),
            },
            {
              dataKey: "action",
              position: "right",
              render: (_, template: any) => (
                <>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      setAnnualBlockScheduleTemplate(template)
                      setView("individual")
                    }}
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => handleTemplateDelete(template)}
                  >
                    Delete
                  </Button>
                </>
              ),
            },
          ]}
        />
      </>
    )
  }

  function renderIndividual() {
    return (
      <CreateOrUpdateDailyTemplate
        annualBlockScheduleId={id}
        annualBlockScheduleTemplate={annualBlockScheduleTemplate}
        back={resetViews}
      />
    )
  }

  return (
    <>
      {view === "list" && renderList()}
      {view === "individual" && renderIndividual()}
    </>
  )
}
