import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "@app/models"
import { H2, H3, H4, Text } from "@app/components/Typography"
import { Row, Col, Container } from "@app/components/Layout"
import { getTimezoneAbbr, formatDateInTimezone } from "@app/utils"
import cx from "classnames"
import css from "./MDV.module.scss"
import { useParams } from "react-router-dom"

type Props = {
  title?: string
  timezone?: string
  loading?: boolean
  onViewNotes: boolean
  showLoginModal: () => void
  children: React.ReactNode
  printRef: React.Ref<HTMLDivElement>
}

export default ((props) => {
  const {
    title,
    timezone,
    loading,
    onViewNotes,
    showLoginModal,
    children,
    printRef,
  } = props
  const [now, setNow] = useState(new Date())
  const { key } = useParams()

  const { currentOnViewNoteUser } = useSelector((state) => state.users)
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000)
    return () => clearInterval(interval)
  }, [timezone])

  const isOnViewNoteUserLogged = Boolean(
    currentOnViewNoteUser?.on_view_note_user
  )

  const handleLoginOrLogout = () => {
    if (isOnViewNoteUserLogged) {
      dispatch.users.onViewNoteUserLogout()
    } else {
      showLoginModal()
    }
  }

  const timezoneShortName = useMemo(
    () => timezone && getTimezoneAbbr(timezone),
    [timezone]
  )

  const currentTime = formatDateInTimezone(
    now,
    timezone || "US/Central",
    "MMMM do, yyyy hh:mm aa"
  )

  const openUserAdmin = () => {
    window.open(
      "/custom/merged/daily/on_view_note/user_editor.cgi",
      "onview_user_admin",
      "status=0,toolbar=0,width=800,height=600,scrollbars=auto,resizable=1"
    )
  }

  return (
    <div className={cx("bootstrap4", css.mdvContainer)} ref={printRef}>
      <Row noMargin className={css.header}>
        <Col md="3">
          <div className={css.logo}>
            <img src="../../../images/tc_physician_scheduling_mdv-logo.svg" />
          </div>
        </Col>
        <Col
          md="6"
          className="d-flex align-items-center justify-content-center"
        >
          <div className="text-center">
            <H2 id="mdv_title" bold>
              {title}
            </H2>
            {timezone && (
              <H4 id="mdv_time">
                Current Time: {currentTime}{" "}
                <span className="font-weight-bold">{timezoneShortName}</span>
              </H4>
            )}
            <H4 id="mdv_old_link">
              Switch to the{" "}
              <a
                href={`/custom/merged/daily/whiteboard.cgi?key=${key}&override=1`}
              >
                legacy view
              </a>
            </H4>
          </div>
        </Col>
        <Col md="3" className="d-flex align-items-center justify-content-end">
          {!loading && (
            <>
              {isOnViewNoteUserLogged && (
                <div className="text-right mr-3">
                  <H3>
                    {currentOnViewNoteUser?.on_view_note_user?.fullname}
                    {currentOnViewNoteUser?.allow_edit &&
                      currentOnViewNoteUser?.on_view_note_user?.user_admin && (
                        <>
                          &nbsp;-&nbsp;
                          <a href="javascript:void();" onClick={openUserAdmin}>
                            <span className={css.user_admin}>User Admin</span>
                          </a>
                          &nbsp;|
                        </>
                      )}
                  </H3>
                  {!currentOnViewNoteUser?.allow_edit && (
                    <Text variant="danger">
                      You are not authorized to make notes on this calendar
                    </Text>
                  )}
                </div>
              )}
              {onViewNotes && (
                <H3 variant="blue">
                  <span className={css.login} onClick={handleLoginOrLogout}>
                    {isOnViewNoteUserLogged ? "Logout" : "Login"}
                  </span>
                </H3>
              )}
            </>
          )}
        </Col>
      </Row>
      <div className="flex-fill">
        <Container size="xxxl">{children}</Container>
      </div>
    </div>
  )
}) as React.FC<Props>
