import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import { isIE } from "@app/utils"
import cx from "classnames"
import css from "./Modal.module.scss"
import { ButtonVariant } from "react-bootstrap/esm/types"

export type ButtonProps = Pick<
  React.ComponentProps<typeof Button>,
  "text" | "className"
> & {
  variant?: ButtonVariant | "outlined" | "contained"
  disabled?: boolean
  onClick?: (closeModal: Function) => any
  position?: "left" | "right"
  control?: any
}

// Must pass onHide() to control the show state of parent component
type Props = Omit<React.ComponentProps<typeof Modal>, "onHide"> & {
  onHide: (status?: any) => void
  noHeader?: boolean
  noFooter?: boolean
  title?: React.ReactNode
  closeButton?: boolean
  buttons?: ButtonProps[]
  disableBodyScroll?: boolean
  titleAlign?: "left" | "center" | "right"
  escKeyClose?: boolean
}

export type ModalControlProps = Pick<Props, "show" | "onHide">

// Create Modal Container
let modalContainer = document.querySelector(`div.${css.modalContainer}`)
if (!modalContainer) {
  modalContainer = document.createElement("div")
  modalContainer.classList.add("bootstrap4", css.modalContainer)
  document.body.appendChild(modalContainer)
}

export default (props: Props) => {
  const {
    title,
    onHide,
    className,
    noHeader,
    noFooter,
    closeButton = true,
    show = false,
    children,
    buttons = [],
    disableBodyScroll,
    backdrop = "static",
    titleAlign = "center",
    escKeyClose = true,
    ...rest
  } = props

  const [loadings, setLoadings] = useState<Record<number, boolean>>({})
  const hideModal = (event?: any) => {
    event?.stopPropagation()
    onHide(false)
  }

  const handleButtonClick = (
    onClick: ((cb: Function) => any) | undefined,
    buttonIndex: number
  ) => {
    const result = onClick?.(hideModal)

    if (result instanceof Promise) {
      // Disable the button until promise resolved
      setLoadings({ ...loadings, [buttonIndex]: true })
      result.finally(() => {
        setLoadings({ ...loadings, [buttonIndex]: false })
        hideModal()
      })
    } else if (result !== false) {
      hideModal()
    }
  }

  return (
    <Modal
      centered
      animation={false}
      show={show}
      container={modalContainer as any}
      className={cx(css.modal, className)}
      backdropClassName={css.backdrop}
      backdrop={backdrop}
      onEscapeKeyDown={() => escKeyClose && hideModal()}
      {...rest}
    >
      {!noHeader && (
        <Modal.Header>
          {title && (
            <Modal.Title className={`text-${titleAlign}`}>{title}</Modal.Title>
          )}
          {closeButton && (
            <Icon name="close" className="close" onClick={hideModal} />
          )}
        </Modal.Header>
      )}
      <Modal.Body
        style={{
          overflow: disableBodyScroll
            ? isIE
              ? "visible"
              : "unset"
            : undefined,
        }}
      >
        {children}
      </Modal.Body>
      {!noFooter && (
        <Modal.Footer>
          {buttons.map((buttonProps, buttonIndex) => {
            const {
              text,
              onClick,
              disabled,
              position,
              variant,
              className: btnClassName,
              ...restButtonProps
            } = buttonProps

            return (
              <Button
                key={text}
                disabled={disabled || loadings[buttonIndex]}
                onClick={() => handleButtonClick(onClick, buttonIndex)}
                className={cx(btnClassName, {
                  "pull-left": position === "left",
                  [css.outlined]: variant === "outlined",
                  [css.contained]: variant === "contained",
                })}
                variant={variant}
                {...restButtonProps}
              >
                {text}
              </Button>
            )
          })}
        </Modal.Footer>
      )}
    </Modal>
  )
}
