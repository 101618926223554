import { createModel } from "@rematch/core"
import { RootModel } from "."
import api from "@app/services/api"

type StateType = {
  currentUser: UserType
  currentOnViewNoteUser?: Pick<
    CustomCalendarType,
    "on_view_note_user" | "allow_edit"
  >
}

export default createModel<RootModel>()({
  state: {
    currentUser: {},
    currentOnViewNoteUser: {},
  } as StateType,
  reducers: {
    setCurrentUser(state, payload: UserType) {
      return { ...state, currentUser: payload }
    },
    setCurrentOnViewNoteUser(
      state,
      payload?: StateType["currentOnViewNoteUser"]
    ) {
      return { ...state, currentOnViewNoteUser: payload }
    },
  },
  effects: (dispatch) => ({
    async getCurrentUserInfo() {
      const userInfo = (await api.getCurrentUserInfo()) as any
      dispatch.users.setCurrentUser(userInfo)
    },
    async onViewNoteUserLogin(data: {
      username: string
      password: string
      calendar_key: string
    }) {
      const userInfo: StateType["currentOnViewNoteUser"] =
        await api.onViewNoteUserLogin(data)
      dispatch.users.setCurrentOnViewNoteUser(userInfo)
    },
    async onViewNoteUserLogout() {
      await api.onViewNoteUserLogout()
      dispatch.users.setCurrentOnViewNoteUser(undefined)
    },
  }),
})
