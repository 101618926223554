import React from "react"
import { useContext } from "react"
import Form from "react-bootstrap/Form"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import { IManualScheduleJobItem } from "../../data"
import { useValidProviders } from "../../hook/ManualScheduleJobProvidersHook"

export interface IManualScheduleJobRowProviderSelectProps {
  jobId: number
}

const ManualScheduleJobRowProviderSelect: React.FC<
  IManualScheduleJobRowProviderSelectProps
> = (props) => {
  const { jobs, edate, setJobs } = useContext(ManualScheduleTableContext)
  const { validProviders, isLoading } = useValidProviders(props.jobId)
  const getProviderId = (jobList: IManualScheduleJobItem[]) =>
    jobList.find((j) => props.jobId == j.jobid)?.providerid ?? undefined

  const handleProviderChange = (providerId: string) => {
    const index = jobs.findIndex((j) => j.jobid == props.jobId)
    const providerDisplayName = validProviders.find(
      (provider) => provider.providerid === parseInt(providerId)
    )?.display_name
    jobs[index] = {
      ...jobs[index],
      providerid: providerId ? parseInt(providerId) : null,
      scheduled_name: providerDisplayName || undefined,
    }
    setJobs([...jobs])
  }

  const ifRemoveAllSplitShift = (jobList: IManualScheduleJobItem[]) => {
    const job = jobList.find((j) => props.jobId == j.jobid)
    return !job?.providerid && job?.removeSplitShiftListFlag
  }

  const getRemovedSplitShiftValue = () => {
    let value = {
      jobid: props.jobId,
      edate: edate,
    }
    return JSON.stringify(value)
  }

  return (
    <div>
      <Form.Control
        as="select"
        name={`job${props.jobId}`}
        value={getProviderId(jobs)}
        onChange={(e) =>
          handleProviderChange((e.target as HTMLInputElement).value)
        }
        style={{ minWidth: 240 }}
      >
        <option value="">
          {isLoading ? "Loading..." : "Select a provider..."}
        </option>
        {validProviders.map((p) => (
          <option
            key={p.providerid}
            value={p.providerid}
          >{`${p.display_name} - ${p.name}`}</option>
        ))}
      </Form.Control>
      {ifRemoveAllSplitShift(jobs) ? (
        <input
          type="hidden"
          name={`spljob${props.jobId}`}
          value={getRemovedSplitShiftValue()}
        />
      ) : null}
    </div>
  )
}

export default ManualScheduleJobRowProviderSelect
