import React from "react"
import Modal from "@app/components/Modal"
import Table from "@app/components/Table"
import css from "./SyncFailuresEditModal.module.scss"
import api, { useRequest } from "@app/services/api"

type PropTypes = {
  onHide: () => void
  show: boolean
  syncErrorId: string
}

export default (props: PropTypes) => {
  const { onHide, show, syncErrorId } = props
  const { data: schedulerEmails } = useRequest([
    api.getSchedulerEmailDataFromSyncFailure,
    syncErrorId,
  ])

  const columnData = [
    {
      title: "Email",
      dataKey: "email",
    },
    {
      title: "Amount of Emails",
      dataKey: "emails_delivered",
    },
  ]
  return (
    <Modal
      className={css.SyncFailureModal}
      show={show}
      title="Email delivery details"
      onHide={onHide}
    >
      <Table data={schedulerEmails} columns={columnData} />
    </Modal>
  )
}
