import React, { useMemo } from "react"
import Modal from "@app/components/Modal"
import _ from "lodash"
import { Group } from "../../UserLookupEdit"
import { FormItem, Select, useForm } from "@app/components/Form"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"

type PropsType = {
  show: boolean
  onHide: () => void
  groupAssociations: Group[]
  userid: number
}

type FormFields = {
  clinicid: number
  permission: string
}

export default ({ show, onHide, groupAssociations, userid }: PropsType) => {
  const calculateClinicOptions = useMemo(() => {
    const uniqueGroupAssociations = _.uniqBy(groupAssociations, "clinicid")

    return uniqueGroupAssociations.map((group) => {
      return {
        id: group.clinicid,
        name: group.clinic_name,
      }
    })
  }, [groupAssociations])

  const { control, handleModalSubmit } = useForm<FormFields>({
    schema: (yup) =>
      yup.object().shape({
        clinicid: yup.number().required(),
        permission: yup.string().required(),
      }),
  })

  const addPermission = (data: FormFields) => {
    api.createClinicPermission({ userid, ...data }).then((res) => {
      onHide()
    }, handleApiError)
  }

  return (
    <Modal
      buttons={[
        { text: "Cancel", variant: "outline-primary" },
        {
          control,
          text: "Save",
          variant: "primary",
          onClick: handleModalSubmit(addPermission),
        },
      ]}
      show={show}
      onHide={onHide}
      title="Add Enterprise Permission"
    >
      <FormItem name="clinicid" control={control} label="Clinic">
        <Select options={calculateClinicOptions} placeholder="Select Clinic" />
      </FormItem>
      <FormItem name="permission" control={control} label="Permission">
        <Select
          options={[
            {
              id: "User Admin",
              name: "User Admin",
            },
            {
              id: "Group Admin",
              name: "Group Admin",
            },
            {
              id: "Reporting",
              name: "Reporting",
            },
          ]}
          placeholder="Select Permission"
        />
      </FormItem>
    </Modal>
  )
}
