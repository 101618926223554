import React from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useSelector } from "@app/models"
import { H4 } from "@app/components/Typography"
import { authPermission } from "@app/services/accessPermission"

type Props = {
  scope: string
}

export default (props: Props) => {
  const { currentUser } = useSelector((state) => state.users)
  const { pathname } = useLocation()

  if (!currentUser.userid) {
    return null
  }

  if (!authPermission(currentUser, pathname, props)) {
    // Redirect to TigerSchdule Home page if no permission
    window.location.href = "/menu.cgi"

    return (
      <div className="bootstrap4">
        <H4 bold className="p-5 text-center">
          <div>Oops, you don't have permission to visit this page.</div>
          <div>Redirecting...</div>
        </H4>
      </div>
    )
  }

  return <Outlet />
}
