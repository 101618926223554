import * as React from 'react';

export interface IErrorMessageProps {
    message?: string
}

export default class ErrorMessage extends React.Component<IErrorMessageProps> {

    public render() {
        return (
            <div>
                {
                    this.props.message ? (
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                                <div className="text-center error-msg center-block">
                                    <div className="login-error" dangerouslySetInnerHTML={{ __html: this.props.message }}></div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        );
    }
}
