import React, { useState, useMemo } from "react"
import { useSelector } from "@app/models"
import { H3, H5, Text } from "@app/components/Typography"
import { ListTooltip } from "@app/components/Tooltip"
import Modal from "@app/components/Modal"
import Table from "@app/components/Table"
import Icon from "@app/components/Icon"
import {
  DayProgressType,
  DayProgressStatusType,
  progressStatusMapping,
} from "@app/services/rotationProgress"
import { monthOfInterval, formatDate } from "@app/utils"
import cx from "classnames"
import css from "./RotationProgressBoard.module.scss"

const ProgressStatus = ({ status }: { status: DayProgressStatusType }) => {
  const { color, icon, name } = progressStatusMapping[status]

  return (
    <div style={{ color }} className="d-flex align-items-center">
      <Icon name={icon} className="mr-2" />
      <span>{name}</span>
    </div>
  )
}

type Props = {
  annualBlockScheduleRotation: AnnualBlockScheduleRotationType
  progressData?: DayProgressType[]
  blockHeight?: number
}

export default (props: Props) => {
  const {
    annualBlockScheduleRotation: annualRotation,
    progressData,
    blockHeight,
  } = props

  const [clickedProgressDate, setClickedProgressDate] = useState<string | null>(
    null
  )

  const {
    blockConfig: { daySizePixels, bufferDays },
  } = useSelector((state) => state.blockSets)

  const monthesInfo = useMemo(() => {
    if (!progressData) {
      return []
    }

    const startDate = progressData[0].date
    const endDate = progressData[progressData.length - 1].date

    return monthOfInterval(startDate, endDate)
  }, [progressData])

  const hasStaffRequirements = annualRotation.staff_requirements.length
  const handleTooptipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!hasStaffRequirements) return
    if (event.target instanceof HTMLDivElement) {
      const date = event.target.dataset.date
      date && setClickedProgressDate(date)
    }
  }

  const clickedProgress = progressData?.find(
    (x) => x.formattedDate === clickedProgressDate
  )

  const tableColumns = [
    {
      title: "Requirement",
      dataKey: "staff_levels",
      render: (staffLevels: StaffLevelType[]) =>
        staffLevels.map((x) => <div key={x.id}>{x.name}</div>),
    },
    {
      title: "Target",
      dataKey: "count",
    },
    {
      title: "Status",
      dataKey: "status",
      render: (status: DayProgressStatusType) => (
        <ProgressStatus status={status} />
      ),
    },
    {
      title: "Residents",
      dataKey: "providers",
      render: (providers: AnnualBlockScheduleProviderType["provider"][]) =>
        providers.map((p) => <div key={p.providerid}>{p.fullname}</div>),
    },
  ]

  return (
    <div
      className={css.progressRow}
      style={{ paddingLeft: bufferDays * daySizePixels, height: blockHeight }}
    >
      <div className={css.progressCard}>
        <div className={css.progressChart}>
          <div className="d-flex mb-2">
            {monthesInfo.map((mi, idx) => (
              <Text
                key={`${mi.month}`}
                bold="medium"
                className={cx("text-center", css.progressMonth)}
                style={{ width: daySizePixels * mi.restDays }}
              >
                {mi.restDays >= 6 && formatDate(mi.month, (f) => f.shortMonth)}
              </Text>
            ))}
          </div>
          <ListTooltip
            variant="progress"
            lists={progressData || []}
            onClick={handleTooptipClick}
            renderListItem={(item) => (
              <div
                key={item.formattedDate}
                data-date={item.formattedDate}
                className={cx(css.progressStatus, {
                  [css.disableClick]: !hasStaffRequirements,
                })}
                style={{
                  width: daySizePixels,
                  backgroundColor:
                    progressStatusMapping[item.status].progressColor ||
                    progressStatusMapping[item.status].color,
                }}
              ></div>
            )}
            renderTooltipContent={(item) => (
              <div key={item.formattedDate}>
                <Text bold>
                  {formatDate(item.date, (f) => f.longLocalizedWithoutYear)}
                </Text>
                <div className="d-flex justify-content-center">
                  <ProgressStatus status={item.status} />
                </div>
              </div>
            )}
          />
        </div>
        <Modal
          title={
            <div>
              <H3
                ellipsis
                prefixDotColor={annualRotation.block_schedule_rotation?.color}
              >
                {annualRotation.block_schedule_rotation?.name}
              </H3>
              <H5 variant="grey">
                {clickedProgress &&
                  formatDate(
                    clickedProgress.date,
                    (f) => f.longLocalizedWithoutYear
                  )}
              </H5>
            </div>
          }
          size="lg"
          show={clickedProgressDate != null}
          onHide={() => setClickedProgressDate(null)}
        >
          <Table
            variant="frame"
            striped={false}
            columns={tableColumns}
            data={clickedProgress?.staffRequirementStatistics}
          />
        </Modal>
      </div>
    </div>
  )
}
