import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { HashRouter, Routes, Route } from "react-router-dom"
import store, { useDispatch } from "@app/models"
import ScrollToTop from "@app/containers/common/ReactRouterScrollToTop"
import AccessPermissionGuard from "@app/containers/common/ReactRouterAccessPermissionGuard"
import IndexPage from "./IndexPage"
import EditStaffLevelsPage from "./StaffLevelsPage/EditStaffLevels"
import EditRotationsPage from "./RotationsPage/EditRotations"
import CreateOrUpdateAnnualSchedulePage from "./AnnualSchedulesPage/CreateOrUpdateAnnualSchedule"
import AnnualSchedulePage from "./AnnualSchedulesPage/AnnualSchedule"
import AnnualScheduleWhitebardPage from "./WhiteboardPage/Whiteboard"
import CreateOrUpdateDutyHourRulePage from "./DutyHourRulesPage/CreateOrUpdateDutyHourRule"

const AppRoutes = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch.users.getCurrentUserInfo()
  }, [])

  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<AccessPermissionGuard scope="blockScheduling" />}>
          <Route path="/" element={<IndexPage />} />
          <Route path="/staff_levels/edit" element={<EditStaffLevelsPage />} />
          <Route path="/rotations/edit" element={<EditRotationsPage />} />
          <Route
            path="/annual_schedules/new"
            element={<CreateOrUpdateAnnualSchedulePage />}
          />
          <Route
            path="/annual_schedules/:id/edit"
            element={<CreateOrUpdateAnnualSchedulePage />}
          />
          <Route
            path="/annual_schedules/:id/whiteboard"
            element={<AnnualScheduleWhitebardPage />}
          />
          <Route
            path="/annual_schedules/:id/*"
            element={<AnnualSchedulePage />}
          />
          <Route
            path="/duty_hour_rules/new"
            element={<CreateOrUpdateDutyHourRulePage />}
          />
          <Route
            path="/duty_hour_rules/:id/edit"
            element={<CreateOrUpdateDutyHourRulePage />}
          />
        </Route>
      </Routes>
    </HashRouter>
  )
}

export default () => <Provider store={store} children={<AppRoutes />} />
