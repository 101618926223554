import React from "react"
import { Text } from "@app/components/Typography"
import Table from "@app/components/Table"
import { formatDateRange } from "@app/utils"
import type { RequestType } from "./type"

type Props = {
  requests: RequestType[]
  tableProps?: React.ComponentProps<typeof Table>
  title?: React.ReactNode
  className?: string
}

export default (props: Props) => {
  const { requests, tableProps, title, className } = props

  return (
    <div className={className}>
      <Text bold variant="black" className="mt-1 mb-4">
        {title || "Provider Request Summary"}
      </Text>
      <Table
        variant="frame"
        striped={false}
        rowKey="requestid"
        columns={[
          {
            dataKey: "type_name",
            title: "Type",
            render: (type: string) => (
              <Text bold variant="black">
                {type}
              </Text>
            ),
          },
          {
            dataKey: "start_date",
            title: "Dates",
            render: (_, record) =>
              formatDateRange(
                { start: record.start_date, end: record.end_date },
                (f) => f.shortSlash
              ),
          },
          {
            dataKey: "status",
            title: "Status",
          },
        ]}
        data={requests}
        {...tableProps}
      />
    </div>
  )
}
