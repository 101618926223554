import React from "react"
import Modal from "@app/components/Modal"
import NotesHeader from "./NotesHeader"
import NotesManager from "./NotesManager"
import css from "./NotesModal.module.scss"

interface Props {
  isModalOpen: boolean
  onCloseModal: () => void
  date: string
  closeButton?: boolean
}

export const NotesModal = ({
  isModalOpen,
  onCloseModal,
  date,
  closeButton = false,
}: Props) => {
  return (
    <Modal
      className={css.modal}
      centered
      show={isModalOpen}
      title={
        <NotesHeader
          date={date}
          onModalClose={onCloseModal}
          closeButton={closeButton}
        />
      }
      closeButton={false}
      onHide={onCloseModal}
    >
      <NotesManager onCloseModal={onCloseModal} date={date} />
    </Modal>
  )
}
