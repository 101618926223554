import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "@app/models"
import { useSearchParams } from "react-router-dom"
import { H3, Text } from "@app/components/Typography"
import Modal from "@app/components/Modal"
import {
  useForm,
  useFormState,
  ErrorMessage,
  FormItem,
  Input,
  Select,
} from "@app/components/Form"
import Button from "@app/components/Button"
import api, { useRequest } from "@app/services/api"
import {
  formatDateInTimezone,
  handleApiError as originHandleApiError,
} from "@app/utils"
import { add } from "date-fns"
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz"
import _ from "lodash"

import cx from "classnames"
import css from "./ScheduleChangeModal.module.scss"
import { JobBlockType } from "@app/services/mdvShiftCalculation"

type Props = {
  jobBlock: JobBlockType | null
  customCalendar: CustomCalendarType
  onViewNoteUser: OnViewNoteUserType
  onHide: () => any
  setForceReload: (forceReload: number | null) => any
}

type DescProps = {
  noteObj: OnViewNoteType
}

type ScheduleChangeFormFields = {
  groupid: number
  jobid?: number
  contact: string
  providerid1?: number
  providerid2?: number
  date1: string
  date2: string
  time1: string
  time2: string
  pager_1: string
  pager_2: string
  pager_3: string
  home_1: string
  home_2: string
  home_3: string
  cell_1: string
  cell_2: string
  cell_3: string
  provider_contact_pager: string
  provider_contact_special: string
  notes: string
  notification_email_addresses: string
}

export default (props: Props) => {
  const { jobBlock, customCalendar, onViewNoteUser, onHide, setForceReload } =
    props

  const { job, job_edate } = jobBlock || { job: null, job_edate: null }
  const { jobid, groupid } = job || { jobid: null, groupid: null }
  const { shiftsMap } = useSelector((state) => state.groupShifts)
  const groupOptions = () =>
    Object.values(shiftsMap)
      .filter(
        (item: CustomCalendarGroupDetailsType | undefined) =>
          item?.shift_assigned
      )
      .map((item: CustomCalendarGroupDetailsType) => ({
        name: item.name,
        groupid: item.groupid,
      }))

  const [pickedJobId, setPickedJobId] = useState<number | null>(jobid)
  const [pickedGroupId, setPickedGroupId] = useState<number | null>(groupid)
  const [noteObj, setNoteObj] = useState<any>()
  const [showRefreshMDV, setShowRefreshMDV] = useState<boolean>(false)

  const [searchParams] = useSearchParams()
  const date =
    searchParams.get("date") ||
    formatInTimeZone(new Date(), customCalendar.timezone, "yyyy-MM-dd")

  const now = formatDateInTimezone(
    new Date(),
    customCalendar.timezone,
    "MMMM do, yyyy hh:mm aa"
  )

  useEffect(() => {
    setPickedJobId(job?.jobid || null)
    setPickedGroupId(job?.groupid || null)
  }, [job])

  const handleGroupChange = (value: any) => {
    setPickedGroupId(value)
    setPickedJobId(null)
    setValue("jobid", undefined, { shouldValidate: false })
    setValue("providerid1", undefined, { shouldValidate: false })
    setValue("providerid2", undefined, { shouldValidate: false })
  }

  const currentGroupDetails = shiftsMap[pickedGroupId || ""]

  const currentJobRow = () =>
    currentGroupDetails?.jobRows?.find(
      (jobRow: any) =>
        jobRow.job.jobid == pickedJobId &&
        jobRow.job_edate == (jobRow.job.jobid == jobid ? job_edate : date)
    )

  const getPickedJob = () => {
    const jobRow = currentJobRow()
    return jobRow
      ? Object.assign({}, jobRow.job, {
          job_edate: jobRow.job_edate,
          job_edate_tz: jobRow.job_edate_tz,
        })
      : currentGroupDetails?.jobs.find((job: any) => job.jobid == pickedJobId)
  }

  const startDate = () => getPickedJob()?.job_edate_tz || date
  const endDate = () => {
    return getPickedJob() && getPickedJob().starttime >= getPickedJob().endtime
      ? formatInTimeZone(
          add(new Date(startDate()), { days: 1 }),
          "UTC",
          "yyyy-MM-dd"
        )
      : date
  }

  const getInitialJobProvider = () => {
    const jobRow = currentJobRow()

    return jobRow?.shift?.current?.provider?.providerid
  }

  useEffect(() => {
    setValue("providerid2", getInitialJobProvider(), { shouldValidate: false })
    setValue("time1", getPickedJob()?.starttime, { shouldValidate: false })
    setValue("time2", getPickedJob()?.endtime, { shouldValidate: false })
    setValue("date1", startDate(), { shouldValidate: false })
    setValue("date2", endDate(), { shouldValidate: false })
  }, [pickedJobId])

  const isEmail = (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)

  const {
    control,
    reset: resetForm,
    getValues,
    handleSubmit,
    setValue,
  } = useForm<ScheduleChangeFormFields>({
    schema: (yup) =>
      yup.lazy(() => {
        const baseShape = {
          groupid: yup.number().required().label("Group"),
          jobid: yup.number().required().label("Job"),
          contact: yup.string().required().label("Contact"),
          providerid1: yup.number().required().label("This Provider"),
          providerid2: yup.number().required().label("for Provider"),
          date1: yup.string().required().label("Date Starting"),
          time1: yup.string().required().label("From Time"),
          date2: yup.string().required().label("To Date"),
          time2: yup
            .string()
            .required()
            .label("To Time")
            .test(
              "Invalid time range",
              "End Time must greater than Start Time",
              checkTimeRange
            ),
          provider_contact_1: yup.string().test(phoneValidate("pager")),
          provider_contact_2: yup.string().test(phoneValidate("home")),
          provider_contact_3: yup.string().test(phoneValidate("cell")),
          notification_email_addresses: yup
            .string()
            .transform((value) =>
              Array.from(new Set(value.split(","))).join(",")
            )
            .test(
              "emails",
              "Invalid email address",
              (value: any) =>
                _.isEmpty(value) || value.split(",").every(isEmail)
            ),
        }

        return yup.object().shape(baseShape)
      }),
  })

  const checkTimeRange = (_value: any): boolean =>
    getValues("date1") &&
    getValues("date1") &&
    getValues("date1") &&
    getValues("date1")
      ? `${getValues("date1")}T${getValues("time1")}` <
        `${getValues("date2")}T${getValues("time2")}`
      : true

  const concatPhone = (
    field: "pager" | "home" | "cell"
  ): string | undefined => {
    const phone = _.compact([
      getValues(`${field}_1`)?.trim(),
      getValues(`${field}_2`)?.trim(),
      getValues(`${field}_3`)?.trim(),
    ]).join("-")

    return phone.length > 0 ? phone : undefined
  }

  const phoneValidate = (field: "pager" | "home" | "cell") => {
    return {
      name: "phoneFormatValidator",
      exclusive: true,
      message:
        "Phone Number must be formatted as 555-5555 with an optional 3 character area code",
      test: (_value: any) => {
        const targetValue = concatPhone(field)

        if (!targetValue) return true

        return /^(\d{3}-)?\d{3}-\d{4}$/.test(targetValue)
      },
    }
  }

  const errors = useFormState({ control }).errors

  const validationMessageRef = useRef<HTMLDivElement>(null)

  if (
    [
      "groupid",
      "providerid1",
      "providerid2",
      "jobid",
      "date1",
      "time1",
      "date2",
      "time2",
    ].some((key) => key in errors) &&
    validationMessageRef.current
  ) {
    validationMessageRef.current.scrollIntoView({ block: "nearest" })
  }

  const handleNoteCreated = () => {
    setShowRefreshMDV(true)
  }

  const handleModalHide = () => {
    setNoteObj(undefined)
    setShowRefreshMDV(false)
    resetForm()
    onHide()
  }

  const handleApiError = (res: PlainObjectType) => {
    const { message } = res

    if (typeof message === "string") {
      originHandleApiError(res)
      return
    }

    const msgList = Array.from(message, (item) => `<li>${item}</li>`)

    if (validationMessageRef.current) {
      validationMessageRef.current.innerHTML = msgList.join("")
      validationMessageRef.current.scrollIntoView({ block: "nearest" })
    }

    setNoteObj(undefined)
  }

  const handleNotePreview = (fields: ScheduleChangeFormFields) => {
    const {
      date1,
      date2,
      time1,
      time2,
      pager_1,
      pager_2,
      pager_3,
      home_1,
      home_2,
      home_3,
      cell_1,
      cell_2,
      cell_3,
      ...formalData
    } = fields

    const start_time = `${date1}T${time1}`
    const end_time = `${date2}T${time2}`
    const provider_contact_1 = concatPhone("pager")
    const provider_contact_2 = concatPhone("home")
    const provider_contact_3 = concatPhone("cell")

    const noteData = Object.assign(formalData, {
      start_time,
      end_time,
      provider_contact_1,
      provider_contact_2,
      provider_contact_3,
    })

    return api
      .createScheduleChange(customCalendar.key, { ...noteData })
      .then(() => {
        if (validationMessageRef.current) {
          validationMessageRef.current.innerHTML = ""
        }
        setNoteObj(noteData)
      })
      .catch(handleApiError)
  }

  const handleNoteSubmit = () => {
    return api
      .createScheduleChange(customCalendar.key, {
        ...noteObj,
        action: "create",
      })
      .then(handleNoteCreated)
      .catch(handleApiError)
  }

  const { data: providers } = useRequest<ProviderBaseType[]>(
    pickedGroupId ? [api.getGroupProviders, pickedGroupId] : []
  )
  const providerDetails = (providerid: number) =>
    providers?.find((provider) => provider.providerid == providerid)
  const { data: _schedulers } = useRequest<SchedulerBaseType[]>(
    pickedGroupId ? [api.getGroupSchedulers, pickedGroupId] : [],
    {
      onSuccess: (value: any) => {
        setValue(
          "notification_email_addresses",
          _.compact(value?.map((item: any) => item.email_text) || [])
            .filter((email_text: any) => isEmail(email_text))
            .join(","),
          { shouldValidate: false }
        )
      },
    }
  )

  const ScheduleChangeDescription = (props: DescProps) => {
    const { noteObj } = props

    const fromDate = formatDateInTimezone(
      zonedTimeToUtc(noteObj.start_time, customCalendar.timezone),
      customCalendar.timezone,
      "PPPP p"
    )

    const toDate = formatDateInTimezone(
      zonedTimeToUtc(noteObj.end_time, customCalendar.timezone),
      customCalendar.timezone,
      "PPPP p"
    )

    return (
      <div className={cx(css.noteDesc, "my-2")}>
        <strong>{providerDetails(noteObj.providerid1)?.fullname}</strong>
        is covering <strong>{getPickedJob()?.name}</strong>
        for{" "}
        <strong>
          {noteObj.providerid2 &&
            providerDetails(noteObj.providerid2)?.fullname}
        </strong>
        from <strong>{fromDate}</strong>
        to <strong>{toDate}</strong>.
      </div>
    )
  }

  const formTitle = () =>
    showRefreshMDV
      ? "On-View Note Has Been Saved"
      : `${noteObj ? "Preview" : "Create"} Schedule Change`

  return (
    <Modal
      title={formTitle()}
      titleAlign="left"
      dialogClassName={css.scheduleChangeModal}
      show={Boolean(jobBlock)}
      onHide={handleModalHide}
    >
      <div
        ref={validationMessageRef}
        style={{ color: "red", marginBottom: "1em" }}
      ></div>
      <FormItem layout={[4, 8]} label="Schedule Change Date/Time:">
        <div className="d-flex align-items-center">
          <Text>{now}</Text>
        </div>
      </FormItem>
      <FormItem layout={[4, 8]} label="Entered By:">
        <div className="d-flex align-items-center">
          <Text>{onViewNoteUser.fullname}</Text>
        </div>
      </FormItem>
      {noteObj ? (
        <FormItem layout={[4, 8]} label="Schedule Change Contact:">
          <Text>{noteObj.contact}</Text>
        </FormItem>
      ) : (
        <FormItem
          required
          layout={[4, 8]}
          label="Schedule Change Contact:"
          control={control}
          name="contact"
        >
          <Input />
        </FormItem>
      )}
      {noteObj ? (
        <FormItem layout={[4, 8]} label="Group/Calendar/Dept:">
          <Text>{currentGroupDetails?.name}</Text>
        </FormItem>
      ) : (
        <FormItem
          required
          layout={[4, 8]}
          label="Group/Calendar/Dept:"
          control={control}
          name="groupid"
          defaultValue={pickedGroupId}
        >
          <Select
            loading={!Boolean(groupOptions())}
            options={groupOptions() || []}
            labelKey="name"
            valueKey="groupid"
            onChange={handleGroupChange}
          />
        </FormItem>
      )}
      <FormItem layout={[4, 8]} label="Note Type:">
        <div className="d-flex align-items-center">
          <Text>Schedule Change</Text>
        </div>
      </FormItem>
      {noteObj ? (
        <>
          <FormItem layout={[4, 8]} label="Job:">
            <Text>{getPickedJob()?.name}</Text>
          </FormItem>
          <ScheduleChangeDescription noteObj={noteObj} />
        </>
      ) : (
        <>
          <FormItem
            required
            layout={[4, 8]}
            label="This Provider:"
            control={control}
            name="providerid1"
          >
            <Select
              loading={!providers}
              options={(providers || []) as PlainObjectType[]}
              labelKey="fullname"
              valueKey="providerid"
            />
          </FormItem>
          <FormItem
            required
            layout={[4, 8]}
            label="is covering job:"
            control={control}
            name="jobid"
            defaultValue={pickedJobId}
          >
            <Select
              loading={!currentGroupDetails}
              options={currentGroupDetails?.jobs || []}
              labelKey="name"
              valueKey="jobid"
              onChange={(value) => setPickedJobId(value)}
            />
          </FormItem>
          <FormItem
            required
            layout={[4, 8]}
            label="for Provider:"
            control={control}
            name="providerid2"
          >
            <Select
              loading={!providers}
              options={(providers || []) as PlainObjectType[]}
              labelKey="fullname"
              valueKey="providerid"
              defaultValue={getInitialJobProvider()}
            />
          </FormItem>
          <FormItem
            required
            layout={[4, 4]}
            label="Date Starting:"
            control={control}
            name="date1"
            defaultValue={startDate()}
          >
            <Input type="date" />
          </FormItem>
          <FormItem
            required
            layout={[4, 4]}
            label="From Time:"
            control={control}
            name="time1"
            defaultValue={getPickedJob()?.starttime}
          >
            <Input type="time" />
          </FormItem>
          <FormItem
            required
            layout={[4, 4]}
            label="To Date:"
            control={control}
            name="date2"
            defaultValue={endDate()}
          >
            <Input type="date" />
          </FormItem>
          <FormItem
            required
            layout={[4, 4]}
            label="To Time:"
            control={control}
            name="time2"
            defaultValue={getPickedJob()?.endtime}
          >
            <Input type="time" />
          </FormItem>
        </>
      )}
      {(!noteObj ||
        noteObj.provider_contact_1 ||
        noteObj.provider_contact_2 ||
        noteObj.provider_contact_3 ||
        noteObj.provider_contact_pager ||
        noteObj.provider_contact_special) && (
        <>
          <H3 variant="blue" className="mt-5 mb-2">
            Contact Information:
          </H3>
        </>
      )}

      {noteObj ? (
        noteObj.provider_contact_1 && (
          <FormItem layout={[4, 8]} label="Pager:">
            <Text>{noteObj.provider_contact_1}</Text>
          </FormItem>
        )
      ) : (
        <FormItem layout={[4, 8]} label="Pager:">
          <ErrorMessage
            className={css.requirementAdd}
            control={control}
            name="provider_contact_1"
          >
            <div className="d-flex align-items-center">
              <Input
                name="pager_1"
                control={control}
                className={css.phoneField}
              />
              <span className="px-1">-</span>
              <Input
                name="pager_2"
                control={control}
                className={css.phoneField}
              />
              <span className="px-1">-</span>
              <Input
                name="pager_3"
                control={control}
                className={css.phoneField}
              />
            </div>
          </ErrorMessage>
        </FormItem>
      )}
      {noteObj ? (
        noteObj.provider_contact_pager && (
          <FormItem layout={[4, 4]} label="In-house Pager:">
            <Text>{noteObj.provider_contact_pager}</Text>
          </FormItem>
        )
      ) : (
        <FormItem
          layout={[4, 4]}
          label="In-house Pager:"
          control={control}
          name="provider_contact_pager"
        >
          <Input />
        </FormItem>
      )}
      {noteObj ? (
        noteObj.provider_contact_2 && (
          <FormItem layout={[4, 8]} label="Home:">
            <Text>{noteObj.provider_contact_2}</Text>
          </FormItem>
        )
      ) : (
        <FormItem layout={[4, 8]} label="Home:">
          <ErrorMessage
            className={css.requirementAdd}
            control={control}
            name="provider_contact_2"
          >
            <div className="d-flex align-items-center">
              <Input
                name="home_1"
                control={control}
                className={css.phoneField}
              />
              <span className="px-1">-</span>
              <Input
                name="home_2"
                control={control}
                className={css.phoneField}
              />
              <span className="px-1">-</span>
              <Input
                name="home_3"
                control={control}
                className={css.phoneField}
              />
            </div>
          </ErrorMessage>
        </FormItem>
      )}

      {noteObj ? (
        noteObj.provider_contact_3 && (
          <FormItem layout={[4, 8]} label="Cell:">
            <Text>{noteObj.provider_contact_3}</Text>
          </FormItem>
        )
      ) : (
        <FormItem layout={[4, 8]} label="Cell:">
          <ErrorMessage
            className={css.requirementAdd}
            control={control}
            name="provider_contact_3"
          >
            <div className="d-flex align-items-center">
              <Input
                name="cell_1"
                control={control}
                className={css.phoneField}
              />
              <span className="px-1">-</span>
              <Input
                name="cell_2"
                control={control}
                className={css.phoneField}
              />
              <span className="px-1">-</span>
              <Input
                name="cell_3"
                control={control}
                className={css.phoneField}
              />
            </div>
          </ErrorMessage>
        </FormItem>
      )}
      {noteObj ? (
        noteObj.provider_contact_special && (
          <FormItem layout={[4, 4]} label="Other:">
            <Text>{noteObj.provider_contact_special}</Text>
          </FormItem>
        )
      ) : (
        <FormItem
          layout={[4, 4]}
          label="Other:"
          control={control}
          name="provider_contact_special"
        >
          <Input />
        </FormItem>
      )}

      <FormItem
        layout={[4, 8]}
        label="Special Instruction:"
        control={control}
        name="notes"
        className="mt-5"
      >
        {noteObj ? (
          <Text>{noteObj.notes}</Text>
        ) : (
          <Input type="textarea" rows={3} />
        )}
      </FormItem>
      <FormItem layout={[4, 8]} label="Notification Email Addresses:">
        {noteObj ? (
          <Text>{noteObj.notification_email_addresses}</Text>
        ) : (
          <>
            <Input
              type="textarea"
              control={control}
              name="notification_email_addresses"
              rows={3}
            />
            <Text style={{ fontStyle: "italic" }}>
              (Separate multiple addresses with commas.)
            </Text>
          </>
        )}
      </FormItem>
      <div className="mt-5 text-right">
        {noteObj ? (
          showRefreshMDV ? (
            <Button
              size="sm"
              onClick={() => {
                setForceReload(pickedGroupId)
                handleModalHide()
              }}
            >
              Close window and refresh MDV
            </Button>
          ) : (
            <>
              <Button
                size="sm"
                className="mx-2"
                onClick={handleSubmit(handleNoteSubmit)}
              >
                Save
              </Button>
              <Button
                variant="info"
                size="sm"
                className="mx-2"
                onClick={() => setNoteObj(undefined)}
              >
                Change
              </Button>
              <Button
                variant="light"
                size="sm"
                className="mx-2"
                onClick={handleModalHide}
              >
                Cancel
              </Button>
            </>
          )
        ) : (
          <>
            <Button
              size="sm"
              className="mx-2"
              onClick={handleSubmit(handleNotePreview)}
            >
              Preview
            </Button>
            <Button
              variant="light"
              size="sm"
              className="mx-2"
              onClick={handleModalHide}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}
