import React from "react"
import css from "./Typography.module.scss"
import cx from "classnames"

type TextWithLineProps = {
  text?: string
  variant?: string
  bold?: string
  className?: string
}

export const TextWithLine = ((props) => {
  const { text, variant = "default", bold, className, children } = props
  return (
    <div
      className={cx(
        css["text-with-line"],
        css[`text-${variant}`],
        `font-weight-${bold ? "bold" : "normal"}`,
        className
      )}
    >
      {text || children}
    </div>
  )
}) as React.FC<TextWithLineProps>

type TextComponentProps = {
  bold?: boolean | "medium"
  id?: string
  variant?: string
  className?: string
  style?: React.CSSProperties
  title?: string
  as?: string
  onClick?: (event: React.SyntheticEvent) => any
  prefixDotColor?: string
  ellipsis?: boolean
  children?: any
}

const TextComponentBuilder = (name: string) => (props: TextComponentProps) => {
  const {
    bold,
    variant = "default",
    className,
    id,
    as = "div",
    prefixDotColor,
    ellipsis,
    children,
    ...others
  } = props

  let prefixContent: React.ReactNode | undefined

  if (prefixDotColor) {
    prefixContent = (
      <span
        className={cx(css[`text-prefix-dot`], "mr-2")}
        style={{ backgroundColor: prefixDotColor }}
      />
    )
  }

  const newClassName = cx(
    css[name],
    css[`text-${variant}`],
    typeof bold !== "boolean"
      ? css[`text-bold-${bold}`]
      : `font-weight-${bold ? "bold" : "normal"}`,
    { "text-truncate": ellipsis },
    className
  )

  return React.createElement(as, {
    className: newClassName,
    id,
    children: (
      <>
        {prefixContent}
        {children}
      </>
    ),
    ...others,
  })
}

export const H1 = TextComponentBuilder("h1")
export const H2 = TextComponentBuilder("h2")
export const H3 = TextComponentBuilder("h3")
export const H4 = TextComponentBuilder("h4")
export const H5 = TextComponentBuilder("h5")
export const H6 = TextComponentBuilder("h6")
export const Text = H6

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  Text,
  TextWithLine,
}
