import React from "react"
import css from "./DayOffProviderElement.module.scss"
import cx from "classnames"
import { useSelector } from "@app/models"

interface DayOffProviderElementProps {
  dayOffTypeName: string
  isCardDisabled: boolean
}

export const DayOffProviderElement = ({
  dayOffTypeName,
  isCardDisabled,
}: DayOffProviderElementProps) => {
  const {
    rulesConfig: { display_job_times: showJobTimesInCalendar },
    isHighlightedChangesActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  return (
    <div
      className={cx(css.dayoffWrapper, {
        [css.disabledCellJob]: isCardDisabled || isHighlightedChangesActive,
        [css.smallCards]: !showJobTimesInCalendar,
      })}
    >
      {dayOffTypeName}
    </div>
  )
}
