import React, { useState } from "react"
import Modal from "@app/components/Modal"
import css from "./AccountMergeModal.module.scss"
import { AdminUserLookupUser } from "../UserLookup"
import PrimaryAccountSelect from "./components/PrimaryAccountSelect"
import AccountMergeConfirmation from "./components/AccountMergeConfirmation"

type PropsType = {
  users: AdminUserLookupUser[]
  show: boolean
  toggleModal: () => void
  clearUsers: () => void
}

export default (props: PropsType) => {
  const [primaryUser, setPrimaryUser] = useState<AdminUserLookupUser>()
  const [otherUsers, setOtherUsers] = useState<AdminUserLookupUser[]>()

  const closeModal = () => {
    props.toggleModal()
    setPrimaryUser(undefined)
    setOtherUsers(undefined)
    props.clearUsers()
  }

  return (
    <Modal
      title="Account Merge"
      onHide={closeModal}
      closeButton={true}
      show={props.show}
      className={css.accountMergeModal}
    >
      {!(primaryUser && otherUsers) && (
        <PrimaryAccountSelect
          users={props.users}
          setPrimaryUser={setPrimaryUser}
          setOtherUsers={setOtherUsers}
          closeModal={closeModal}
        />
      )}
      {primaryUser && otherUsers && (
        <AccountMergeConfirmation
          primaryUser={primaryUser}
          otherUsers={otherUsers}
          closeModal={closeModal}
        />
      )}
    </Modal>
  )
}
