import React from "react"
import css from "../JobsTable.module.scss"
import { formatDateToStartEnd } from "@app/utils"

interface SplitShiftTimeProps {
  item: any
  rowIndex: number
  expandedSplitRows: Record<number, boolean>
  toggleExpandedSplitRow: (rowIndex: number) => void
}

const SplitShiftTime: React.FC<SplitShiftTimeProps> = ({
  item,
  rowIndex,
  expandedSplitRows,
  toggleExpandedSplitRow,
}) => {
  return (
    <div>
      {!expandedSplitRows[rowIndex] && (
        <div key={`split-${rowIndex}`} className={css.containerShiftMultiple}>
          {item.provider?.map((idx: number) => (
            <div key={idx}>{item.shifttime}</div>
          ))}
        </div>
      )}
      {expandedSplitRows[rowIndex] && (
        <div onClick={(e) => e.stopPropagation()} className={css.shiftTime}>
          {item.split_shift.map((split_shift: any, idx: number) => (
            <div className={css.shifttimeContainer} key={idx}>
              <div className={css.shiftMultiTime}>
                {formatDateToStartEnd(
                  split_shift.starttime.substring(0, 5),
                  split_shift.endtime.substring(0, 5)
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SplitShiftTime
