import React, { useEffect, useState } from "react"
import api, { useRequest } from "@app/services/api"
import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card/Card"
import Button from "@app/components/Button"
import { useForm, FormItem, Select, Input } from "@app/components/Form"
import { formatDate, handleApiError } from "@app/utils"
import { DateFormatter } from "@app/utils/constants"
import { H5 } from "@app/components/Typography"

type FormFieldsType = {
  calendar_id: string
  filter_type: "action" | "affected"
  start_date: Date
  end_date: Date
  rotations: string[]
  residents: number[]
}

type PropsType = {
  reportType: "rotations" | "daily_assignments" | "rotations_summary"
}

export default (props: PropsType) => {
  const { reportType } = props
  const [residents, setResidents] = useState<ProviderBaseType[]>([])
  const [rotations, setRotations] = useState<BlockScheduleRotationType[]>()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [selectedSchedule, setSelectedSchedule] =
    useState<AnnualBlockScheduleType>()
  const { data: currentUser } = useRequest([api.getCurrentUserInfo])
  const { data: blockSchedules } = useRequest([api.getAnnualBlockSchedules])

  const { control, handleSubmit, watch, setValue, clearErrors } =
    useForm<FormFieldsType>({
      schema: (yup) =>
        yup.lazy(() =>
          yup.object().shape({
            calendar_id: yup.string().required("Please select a calendar"),
            filter_type:
              reportType !== "rotations_summary"
                ? yup.string()
                : yup.string().notRequired(),
            start_date: yup.date().when("filter_type", {
              is: "action",
              then: yup
                .date()
                .required("Please enter a start date")
                .max(
                  yup.ref("end_date"),
                  "Start date must be less than end date"
                ),
              otherwise: yup
                .date()
                .required("Please enter a start date")
                .max(
                  yup.ref("end_date"),
                  "Start date must be less than end date"
                )
                .min(
                  selectedSchedule?.start_date,
                  "Start date must be equal to or after the start date of the selected calendar"
                ),
            }),
            end_date: yup.date().when("filter_type", {
              is: "action",
              then: yup
                .date()
                .min(
                  yup.ref("start_date"),
                  "End date must be greater than start date"
                ),
              otherwise: yup
                .date()
                .min(
                  yup.ref("start_date"),
                  "End date must be greater than start date"
                )
                .max(
                  selectedSchedule?.end_date,
                  "End date must be equal to or less than the end date of the selected calendar"
                ),
            }),
            rotations: yup
              .array()
              .min(1, "You must select at least one rotation"),
            residents: yup
              .array()
              .min(1, "You must select at least one resident"),
          })
        ),
    })

  const calendarId = watch("calendar_id")
  useEffect(() => {
    if (calendarId) {
      api.getAnnualBlockScheduleProviders(calendarId).then(
        (res) =>
          setResidents(
            res?.map(
              (resident: AnnualBlockScheduleProviderWithAllFieldsType) => {
                return {
                  ...resident.provider,
                  label: `${resident.provider.firstname} ${resident.provider.lastname} (${resident.provider.display_name})`,
                }
              }
            )
          ),
        handleApiError
      )

      api
        .getAnnualBlockScheduleRotations(calendarId)
        .then(
          (res) =>
            setRotations(
              res.map(
                (annualScheduleRotation: AnnualBlockScheduleRotationType) =>
                  annualScheduleRotation.block_schedule_rotation
              )
            ),
          handleApiError
        )
    }

    const blockSchedule = blockSchedules?.find(
      (blockSchedule: AnnualBlockScheduleType) =>
        blockSchedule.id === calendarId
    )
    setSelectedSchedule(blockSchedule)
    setValue("start_date", blockSchedule?.start_date)
    setValue("end_date", blockSchedule?.end_date)
  }, [calendarId])

  const submitForm = (fields: FormFieldsType) => {
    if (selectedSchedule?.groupid) {
      const blockSchedule = blockSchedules?.find(
        (blockSchedule: AnnualBlockScheduleType) =>
          blockSchedule.id === calendarId
      )

      if (!blockSchedule) {
        console.error("No block schedule found")
        return
      }

      setFormSubmitted(true)

      const csvFileName = `${formatDate(
        new Date(),
        DateFormatter.humanizedMonthOnly
      )}_${currentUser.clinic.name}_${blockSchedule.name}_${
        props.reportType === "daily_assignments"
          ? "Daily_Assignments"
          : props.reportType === "rotations_summary"
          ? "rotations_summary"
          : "Rotation_Assignments"
      }_report.csv`

      api
        .getBlockScheduleReport(
          reportType,
          selectedSchedule?.groupid,
          fields.filter_type,
          `${fields.start_date.toISOString().split("T")[0]}T00:00:00`,
          `${fields.end_date.toISOString().split("T")[0]}T23:59:59`,
          props.reportType === "rotations" ||
            props.reportType === "rotations_summary"
            ? fields.rotations
            : undefined,
          fields.residents || []
        )
        .then((res) => {
          setFormSubmitted(false)
          const newBlob = new Blob([res])
          const navigator: any = window.navigator

          if (navigator?.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(newBlob)
            return
          }

          const data = window.URL.createObjectURL(newBlob)
          const link = document.createElement("a")

          link.href = data
          link.target = "_blank"
          link.download = csvFileName
          document.body.appendChild(link)
          link.click()
          setTimeout(() => {
            document.body.removeChild(link)
            window.URL.revokeObjectURL(data)
          }, 0)
        })
        .catch((error) => {
          handleApiError(error)
          setFormSubmitted(false)
        })
    }
  }

  return (
    <BaseLayout>
      <Card
        title={
          reportType === "rotations"
            ? "Rotation Assignments Change Log Report"
            : reportType === "rotations_summary"
            ? "Rotation Assignments Summary"
            : "Daily Assignments Change Log Report"
        }
      >
        <FormItem
          required
          label="Select Calendar"
          name="calendar_id"
          control={control}
        >
          <Select
            options={blockSchedules?.map(
              (blockSchedule: AnnualBlockScheduleType) => {
                return {
                  ...blockSchedule,
                  label: `${blockSchedule.name} ${
                    blockSchedule.display_name
                  } (${formatDate(
                    blockSchedule.start_date,
                    (f) => f.shortSlash
                  )} - ${formatDate(
                    blockSchedule.end_date,
                    (f) => f.shortSlash
                  )})`,
                }
              }
            )}
            labelKey={"label"}
            valueKey={"id"}
          />
        </FormItem>
        {props.reportType !== "rotations_summary" && (
          <FormItem
            required
            label="Select date filter type"
            name="filter_type"
            control={control}
          >
            <Select
              options={[
                {
                  name: `Date of ${
                    reportType === "rotations" ? "rotation" : "daily"
                  } assignments (affected date)`,
                  id: "affected",
                },
                {
                  name: `Date of scheduling updates (action date)`,
                  id: "action",
                },
              ]}
              defaultValue={"affected"}
              clearable={false}
            />
          </FormItem>
        )}
        <FormItem
          required
          label="Start Date"
          name="start_date"
          control={control}
        >
          <Input
            type="date"
            onChange={(e) => {
              clearErrors("start_date")
              clearErrors("end_date")
            }}
          />
        </FormItem>

        <FormItem required label="End Date" name="end_date" control={control}>
          <Input
            type="date"
            onChange={(e) => {
              clearErrors("start_date")
              clearErrors("end_date")
            }}
          />
        </FormItem>

        {(props.reportType === "rotations" ||
          props.reportType === "rotations_summary") && (
          <FormItem
            required
            label="Rotations"
            name="rotations"
            control={control}
          >
            <Select
              inline
              multiple
              renderOption={(text, item) => (
                <H5 ellipsis prefixDotColor={item.color}>
                  {text}
                </H5>
              )}
              options={rotations || []}
              valueKey={"id"}
              value={rotations?.map((rotation) => rotation.id)}
            />
          </FormItem>
        )}
        <FormItem required label="Residents" name="residents" control={control}>
          <Select
            inline
            multiple
            options={residents}
            valueKey={"providerid"}
            labelKey={"label"}
            value={residents.map((resident) => resident.providerid)}
          />
        </FormItem>
        <div className="d-flex justify-content-center">
          <Button disabled={formSubmitted} onClick={handleSubmit(submitForm)}>
            {formSubmitted
              ? "Processing report..."
              : "Generate Report and Download CSV"}
          </Button>
        </div>
      </Card>
    </BaseLayout>
  )
}
