import React from "react"
import css from "./NotesList.module.scss"
import NoteItem from "../NoteItem"
import * as types from "../types"

interface Props {
  list: Note[]
  onUpdateNote: (id: number, updatedNote: types.NotesForm) => Promise<void>
  onDeleteNote: (id: number) => Promise<void>
}

export const NotesList = ({ list, onUpdateNote, onDeleteNote }: Props) => {
  return (
    <article className={css.wrapper}>
      <div className={css.titleContainer}>
        <h5 className={css.title}>Saved Notes ({list?.length})</h5>
      </div>

      {list.length > 0 ? (
        <ul className={css.listContainer}>
          {list.map((note) => (
            <NoteItem
              key={note.noteid}
              data={note}
              onUpdateNote={onUpdateNote}
              onDeleteNote={onDeleteNote}
            />
          ))}
        </ul>
      ) : (
        <p className={css.emptyMessage}>No notes available for this date</p>
      )}
    </article>
  )
}
