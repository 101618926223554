import { createModel } from "@rematch/core"
import { RootModel } from "."
import api from "@app/services/api"

type StateType = {
  customCalendar: CustomCalendarType
  customCalendarHidePastFuture?: boolean
  customCalendarFilterConditions: {
    hidePastFuture?: boolean
    searchKeyword?: string
    sorting: {
      column: AssignmentSortType
      order: "desc" | "asc"
    }
    start_time_secondary_sort: StarttimeSecondarySortType
    priority_secondary_sort: PrioritySecondarySortType
  }
}

export default createModel<RootModel>()({
  state: {
    customCalendar: {},
    customCalendarFilterConditions: {
      sorting: { column: "starttime", order: "asc" },
      start_time_secondary_sort: "priority_name",
      priority_secondary_sort: "name_starttime",
    },
  } as StateType,
  reducers: {
    setCustomCalendar(state, payload: CustomCalendarType) {
      return { ...state, customCalendar: payload }
    },
    updateCustomCalendarConditions(
      state,
      payload: Partial<StateType["customCalendarFilterConditions"]>
    ) {
      return {
        ...state,
        customCalendarFilterConditions: {
          ...state.customCalendarFilterConditions,
          ...payload,
        },
      }
    },
  },
  effects: (dispatch) => ({
    async getCustomCalendar(calendarKey) {
      const customCalendar: CustomCalendarType = await api.getCustomCalendar(
        calendarKey
      )

      dispatch.customCalendars.setCustomCalendar(customCalendar)
      dispatch.customCalendars.updateCustomCalendarConditions({
        hidePastFuture: customCalendar.default_hidden_past_future,
        sorting: {
          column: customCalendar.default_assignment_sort || "starttime",
          order: "asc",
        },
        start_time_secondary_sort:
          customCalendar.start_time_secondary_sort || "priority_name",
        priority_secondary_sort:
          customCalendar.priority_secondary_sort || "name_starttime",
      })

      dispatch.users.setCurrentOnViewNoteUser({
        on_view_note_user: customCalendar.on_view_note_user,
        allow_edit: customCalendar.allow_edit,
      })
    },
  }),
})
