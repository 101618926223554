import React, { useEffect, useState } from "react"
import Select from "@app/components/Form/Select"
import { TimezoneOptions } from "@app/utils/constants"
import { H4, H5 } from "@app/components/Typography"

type PropType = {
  timezone: string
}

export default (props: PropType) => {
  const [timezoneState, setTimezoneState] = useState(props.timezone)

  useEffect(() => {
    const form = document.getElementById(
      "group-settings-form"
    ) as HTMLFormElement
    const previousInput = document.getElementById(
      "timezone"
    ) as HTMLInputElement
    if (previousInput) {
      form.removeChild(previousInput)
    }

    const timezoneInput = document.createElement("input") as HTMLInputElement

    timezoneInput.name = "timezone"
    timezoneInput.id = "timezone"
    timezoneInput.value = timezoneState || ""
    timezoneInput.type = "hidden"
    form.appendChild(timezoneInput)
  }, [timezoneState])

  const timezoneSelectOptions = TimezoneOptions.map((timezone) => {
    return {
      id: timezone.zone,
      name: timezone.displayName,
    }
  })

  return (
    <div className="bootstrap4">
      <H4 bold variant="black">
        Timezone
      </H4>
      <div className="w-25 pt-3 d-flex flex-direction-row">
        <div className="pr-3">
          <Select
            value={timezoneState}
            options={timezoneSelectOptions}
            onChange={(timezone) => setTimezoneState(timezone)}
            clearable={false}
          />
        </div>
        <H5>Timezone</H5>
      </div>
    </div>
  )
}
