import { eachDayOfInterval } from "date-fns"
import { parseDate, formatDate } from "@app/utils"

// 0 - Understaffed
// 1 - Fully Staffed
// 2 - Overstaffed
export enum DayProgressStatusType {
  UNDERSTAFFED = 0,
  FULLYSTAFFED = 1,
  OVERSTAFFED = 2,
}

export const progressStatusMapping: Record<
  DayProgressStatusType,
  PlainObjectType & { progressColor?: string }
> = {
  [DayProgressStatusType.UNDERSTAFFED]: {
    name: "Understaffed",
    shortName: "Under",
    progressColor: "#cccccc",
    color: "#333333",
    icon: "arrow-down-circle",
  },
  [DayProgressStatusType.FULLYSTAFFED]: {
    name: "Fully Staffed",
    shortName: "Full",
    color: "#4580d8",
    icon: "check-circle",
  },
  [DayProgressStatusType.OVERSTAFFED]: {
    name: "Overstaffed",
    shortName: "Over",
    color: "#e40060",
    icon: "arrow-up-circle",
  },
}

type StaffRequirementType =
  AnnualBlockScheduleRotationType["staff_requirements"][number]

export type DayProgressType = {
  date: Date
  formattedDate: string
  status: DayProgressStatusType
  staffRequirementStatistics: (StaffRequirementType & {
    providerids: number[]
    providers: AnnualBlockScheduleProviderType["provider"][]
    status: DayProgressStatusType
  })[]
}

export type ApiDayProgressType = {
  date: string
  status: DayProgressStatusType
  statistics: {
    staff_requirement_id: string
    providerids: number[]
    status: DayProgressStatusType
  }[]
}

export function plimProgressData({
  apiProgressData,
  annualBlockScheduleRotation,
  annualBlockScheduleProviders,
}: {
  apiProgressData?: ApiDayProgressType[]
  annualBlockScheduleRotation: AnnualBlockScheduleRotationType
  annualBlockScheduleProviders: AnnualBlockScheduleProviderType[]
}): DayProgressType[] | undefined {
  if (!apiProgressData) {
    return
  }

  const { staff_requirements: staffRequirements } = annualBlockScheduleRotation

  if (!staffRequirements.length) {
    const start = parseDate(apiProgressData[0].date)
    const end = parseDate(apiProgressData[apiProgressData.length - 1].date)
    const days = eachDayOfInterval({ start, end })

    return days.map((day) => ({
      date: day,
      formattedDate: formatDate(day),
      status: DayProgressStatusType.FULLYSTAFFED,
      staffRequirementStatistics: [],
    }))
  }

  return apiProgressData.map((item) => {
    const { date, statistics } = item
    const staffRequirementStatistics = statistics.map((statItem) => {
      const staffRequirement = staffRequirements.find(
        (x) => x.id === statItem.staff_requirement_id
      ) as StaffRequirementType
      const providers = annualBlockScheduleProviders
        .filter((x) => statItem.providerids.includes(x.providerid))
        .map((x) => x.provider)

      return { ...statItem, ...staffRequirement, providers }
    })

    return {
      ...item,
      date: parseDate(date),
      formattedDate: date,
      staffRequirementStatistics,
    }
  })
}
