import React, { useState } from "react"
import Modal from "@app/components/Modal"
import { Checkbox, FormItem, Input, Radio, useForm } from "@app/components/Form"
import Form from "react-bootstrap/Form"
import { useDispatch, useSelector } from "@app/models"
import { handleApiError } from "@app/utils"
import api from "@app/services/api"

type Props = {
  showUnschedule: boolean
  onHide: React.Dispatch<React.SetStateAction<any>>
}

type FormFieldsType = {
  start_date: string
  end_date: string
  jobid: number
  providerid: number
  preserve_holidays: string
  published_events: boolean
  draft_events: boolean
  day_of_week: string[]
  all_days: boolean
}

export default (props: Props) => {
  const { showUnschedule, onHide } = props
  const [preview, setPreview] = useState(false)
  const [jobid, setJobid] = useState<number>(0)
  const [providerid, setProviderid] = useState<number>(0)

  const dispatch = useDispatch()
  const { jobs, providers } = useSelector((state) => state.groupData)

  const groupedJobs = jobs.reduce((r, a) => {
    r[a.job_typeid] = r[a.job_typeid] || []
    r[a.job_typeid].push(a)
    return r
  }, Object.create(null))

  const JobTypes = ["Call", "Work", "Resident"]

  const DayofWeek = [
    { name: "Sunday", id: "Sunday" },
    { name: "Monday", id: "Monday" },
    { name: "Tuesday", id: "Tuesday" },
    { name: "Wednesday", id: "Wednesday" },
    { name: "Thursday", id: "Thursday" },
    { name: "Friday", id: "Friday" },
    { name: "Saturday", id: "Saturday" },
  ]

  const { control, getValues, handleModalSubmit, trigger, reset, setValue } =
    useForm<FormFieldsType>({
      mode: "onChange",
      schema: (yup) =>
        yup.object().shape({
          start_date: yup
            .date()
            .required("Start date is an invalid date")
            .max(yup.ref("end_date"), "Start date must be less than end date"),
          end_date: yup
            .date()
            .required("End date is an invalid date")
            .min(
              yup.ref("start_date"),
              "End date must be greater than start date"
            ),
          preserve_holidays: yup.string().required(),
          day_of_week: yup
            .array()
            .required()
            .min(1, "Please select at least one day"),
          // jobid: yup.number().required(),
          // providerid: yup.number().required(),
        }),
    })

  const hideModal = () => {
    reset()
    setPreview(false)
    onHide(false)
  }

  const onSubmit = (formData: FormFieldsType) => {
    const data = {
      ...formData,
      jobid,
      providerid,
    }

    api.unschedule(data).then((res: any) => {
      reset()
      hideModal()
      dispatch.calendarEvents.getEvents()
      dispatch.calendarEvents.getDraftEvents()
    }, handleApiError)
  }

  const getProviderName = (providerid: number) => {
    const provider = providers.find((p) => p.providerid === providerid)
    return provider ? provider.display_name : "any provider"
  }

  const getJobName = (jobid: number) => {
    const job = jobs.find((j) => j.jobid === jobid)
    return job ? job.name : "any job"
  }

  const previewMessage = () => {
    const fields = getValues()
    const holidayTips =
      fields.preserve_holidays === "on" ? "that do not occur on a holiday" : ""
    const providerTips = getProviderName(providerid)
    const jobTips = getJobName(jobid)
    const startDate = fields.start_date
    const endDate = fields.end_date
    const draftModeTips = fields.draft_events
      ? "This includes Draft-Mode events."
      : ""
    const dowTips = fields.all_days
      ? "all days"
      : fields.day_of_week?.map((name) => `${name}s`).join(", ")

    return (
      <div className="text-center">
        <p>
          You are going to unschedule all assignments {holidayTips} for{" "}
          {providerTips} for {jobTips} on {dowTips} beginning on {startDate}{" "}
          ending on {endDate}. {draftModeTips}
        </p>
        <p>
          This will permanently delete all matching assignments, and this action
          cannot be undone. Are you sure you want to proceed?
        </p>
      </div>
    )
  }

  return (
    <Modal
      title="Unschedule Date Range"
      size="lg"
      show={showUnschedule}
      onHide={hideModal}
      buttons={[
        {
          text: preview ? "No" : "Cancel",
          variant: "outline-default",
          onClick: () => {
            if (preview) {
              setPreview(false)
              return false
            } else {
              hideModal()
              return true
            }
          },
        },
        {
          text: preview ? "Yes" : "Preview",
          variant: "outline-primary",
          onClick: (e) => {
            if (preview) {
              handleModalSubmit(onSubmit)(e)
            } else {
              trigger().then((valid) => valid && setPreview(true))
            }
            return false
          },
        },
      ]}
    >
      {preview ? (
        previewMessage()
      ) : (
        <>
          <FormItem control={control} label="Start Date" name="start_date">
            <Input
              type="date"
              onChange={() => trigger(["start_date", "end_date"])}
            />
          </FormItem>
          <FormItem control={control} label="End Date" name="end_date">
            <Input
              type="date"
              onChange={() => trigger(["start_date", "end_date"])}
            />
          </FormItem>
          <FormItem control={control} label="Jobs" name="jobid">
            <Form.Control
              as="select"
              onChange={(e) => {
                setJobid(Number(e.target.value))
              }}
            >
              <option key={0} value={0}>
                All Jobs
              </option>
              {Object.keys(groupedJobs).map((key) => {
                const label = JobTypes[Number(key) - 1]
                return (
                  <optgroup key={label} label={label}>
                    {groupedJobs[key].map((job: any) => (
                      <option key={job.jobid} value={job.jobid}>
                        {job.name}
                      </option>
                    ))}
                  </optgroup>
                )
              })}
            </Form.Control>
          </FormItem>
          <FormItem control={control} label="Providers" name="providerid">
            <Form.Control
              as="select"
              onChange={(e) => {
                setProviderid(Number(e.target.value))
              }}
            >
              <option key={0} value={0}>
                All Providers
              </option>
              {providers.map((provider) => (
                <option key={provider.providerid} value={provider.providerid}>
                  {provider.display_name}
                </option>
              ))}
            </Form.Control>
          </FormItem>
          <FormItem label="Holidays" className="text-nowrap">
            <Radio.Group
              name="preserve_holidays"
              control={control}
              defaultValue="on"
              options={[
                {
                  id: "on",
                  name: "Preserve Holidays",
                },
                {
                  id: "off",
                  name: "Do Not Preserve Holidays",
                },
              ]}
            />
          </FormItem>
          <FormItem control={control} label="Published" name="published_events">
            <Checkbox label="Include published events" />
          </FormItem>
          <FormItem control={control} label="Draft Mode" name="draft_events">
            <Checkbox label="Include draft mode events" />
          </FormItem>
          <FormItem label="Day of the Week" control={control} name="all_days">
            <Checkbox
              label="Select All"
              onChange={(v) => {
                v.target.checked
                  ? setValue(
                      "day_of_week",
                      DayofWeek.map((d) => d.id)
                    )
                  : setValue("day_of_week", [])
                trigger("day_of_week")
              }}
            />
          </FormItem>
          <FormItem label="">
            <Checkbox.Group
              name="day_of_week"
              control={control}
              options={DayofWeek}
              onChange={(v) =>
                v.length === 7
                  ? setValue("all_days", true)
                  : setValue("all_days", false)
              }
            />
          </FormItem>
        </>
      )}
    </Modal>
  )
}
