import React, { useState } from "react"
import css from "./Toggle.module.scss"

type ToggleSwitchProps = {
  value: boolean
  onToggle: (newState: boolean) => void
  disabled?: boolean
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  onToggle,
  value,
  disabled = false,
}) => {
  const [isActive, setIsActive] = useState(value)

  const toggleSwitch = () => {
    setIsActive(!isActive)
    onToggle(!isActive)
  }

  return (
    <label className={css.toggleSwitch}>
      <input
        disabled={disabled}
        type="checkbox"
        defaultChecked={isActive}
        onChange={toggleSwitch}
      />
      <span className={css.switch} />
    </label>
  )
}

export default ToggleSwitch
