import React, { useContext, useEffect, useRef, useState } from "react"
import { IProviderInfo, ISplitShiftItem } from "../../data"
import Form from "react-bootstrap/Form"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import ClearIcon from "@material-ui/icons/Clear"
import FieldErrorMessage from "../ManualScheduleFormValidation/FieldErrorMessage"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import { useValidProviders } from "../../hook/ManualScheduleJobProvidersHook"
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles } from "@material-ui/core"
import ErrorIcon from "@material-ui/icons/Error"
import SplitShiftConfigTimePicker from "./SplitShiftConfigTimePicker"

export interface ISplitShiftConfigBoxRowProps {
  rerender: number
  rowData: ISplitShiftItem | null
  handleOnChange: (
    propname: "starttime" | "endtime" | "tally_credit" | "providerid",
    v: any
  ) => Promise<void>
  handleClearRow: () => void
  handleRemoveDelFlag: () => Promise<void>
  providers: IProviderInfo[]
  index: number
  isActive: Boolean
}

const LightTooltip = withStyles({
  tooltip: {
    fontSize: 14,
  },
})(Tooltip)

const SplitShiftConfigBoxRow: React.FC<ISplitShiftConfigBoxRowProps> = (
  props
) => {
  const { edate, currentSplitShiftJobId } = useContext(
    ManualScheduleTableContext
  )

  const rowInitValue = {
    split_shiftid: undefined,
    providerid: undefined,
    starttime: undefined,
    endtime: undefined,
    tally_credit: "",
    is_new: 1,
    has_changed: 0,
    edate: edate,
    job_edate: edate,
    delete_flag: false,
  }

  const { validProviders } = useValidProviders()
  const [rowData, setRowData] = useState<ISplitShiftItem>(rowInitValue)
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    handleResetRow()
    if (props.rowData) {
      setRowData({
        ...rowInitValue,
        ...props.rowData,
      })
    } else {
      setRowData(rowInitValue)
    }
  }, [props.rowData])

  useEffect(() => {
    if (props.rowData) {
      setRowData({
        ...rowInitValue,
        ...props.rowData,
      })
    } else {
      setRowData(rowInitValue)
    }
  }, [props.rerender])

  const handleResetRow = () => {
    if (formRef && formRef.current) {
      formRef.current.reset()
    }
  }

  return (
    <>
      <form ref={formRef}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          style={{ padding: "16px 16px 0 16px" }}
        >
          <Grid item>
            <LightTooltip
              title="This split shift could not be unscheduled"
              arrow
            >
              <ErrorIcon
                style={{
                  color: "#ce4844",
                  visibility: rowData.delete_flag ? "visible" : "hidden",
                }}
                onClick={async () => await props.handleRemoveDelFlag()}
              ></ErrorIcon>
            </LightTooltip>
          </Grid>

          <Grid
            container
            item
            justifyContent="space-around"
            alignItems="center"
            wrap="nowrap"
            style={{ maxWidth: 260 }}
          >
            <SplitShiftConfigTimePicker
              name="starttime"
              rowData={rowData}
              handleOnChange={props.handleOnChange}
              disabled={!props.isActive}
            />
            <span> To </span>
            <SplitShiftConfigTimePicker
              name="endtime"
              rowData={rowData}
              handleOnChange={props.handleOnChange}
              disabled={!props.isActive}
            />
          </Grid>
          <div className="flex-1">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Form.Control
                  as="select"
                  placeholder="Provider"
                  disabled={!props.isActive}
                  onChange={(e) =>
                    props.handleOnChange(
                      "providerid",
                      (e.target as HTMLInputElement).value
                    )
                  }
                  value={rowData.providerid}
                  style={{ width: 240, backgroundColor: "#F0FBFF" }}
                >
                  <option value=""> Please select provider</option>
                  {validProviders.map((p) => {
                    return (
                      <option
                        key={p.providerid}
                        value={p.providerid}
                      >{`${p.display_name} - ${p.name}`}</option>
                    )
                  })}
                </Form.Control>
              </Grid>
              <Grid item>
                <Form.Control
                  as="select"
                  placeholder="Tally Credit"
                  disabled={!props.isActive}
                  onChange={(e: any) =>
                    props.handleOnChange(
                      "tally_credit",
                      parseFloat((e.target as HTMLInputElement).value)
                    )
                  }
                  value={rowData.tally_credit}
                  style={{ width: 100, backgroundColor: "#F0FBFF" }}
                >
                  <option value="" disabled hidden>
                    Tally
                  </option>
                  <option value="0">0</option>
                  <option value="0.25">0.25</option>
                  <option value="0.5">0.5</option>
                  <option value="0.75">0.75</option>
                  <option value="1">1</option>
                </Form.Control>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  onClick={() => props.handleClearRow()}
                  style={{
                    width: 22,
                    height: 22,
                    padding: 0,
                    backgroundColor: "#FFFFFF",
                    color: "#3080DF",
                    border: "1px solid #CDD9DE",
                    visibility: props.isActive ? "visible" : "hidden",
                  }}
                >
                  <ClearIcon fontSize="small" style={{ color: "#A2B0BC" }} />
                </IconButton>
              </Grid>
            </Grid>
            <FieldErrorMessage
              jobId={currentSplitShiftJobId}
              splitShift={rowData}
            />
          </div>
        </Grid>
      </form>
    </>
  )
}

export default SplitShiftConfigBoxRow
