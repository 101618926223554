import React from "react"
import css from "../CalendarListView.module.scss"
import cx from "classnames"

type PropsType = {
  compactListView: boolean
  isProviderView: boolean
  renderAssignmentForDate: (date: string, item: any) => void
  date: string
  item: any
}

const CalendarListViewCell = ({
  compactListView,
  isProviderView,
  renderAssignmentForDate,
  date,
  item,
}: PropsType) => {
  return (
    <td
      className={cx(css.listViewCellJobs, {
        [css.miniListViewCellJobs]: compactListView,
      })}
    >
      <div
        className={
          isProviderView
            ? css.assignmentProviderContainer
            : css.assignmentJobsContainer
        }
      >
        <>{renderAssignmentForDate(date, item)}</>
      </div>
    </td>
  )
}

export default CalendarListViewCell
