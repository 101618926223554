import React, { useEffect } from "react"
import { Checkbox } from "@app/components/Form"
import Avatar from "@app/components/Avatar"
import CheckIcon from "@material-ui/icons/Check"
import css from "./NotifyChangesView.module.scss"
import { DefaultCalendarColor } from "@app/utils/constants"
import { H5 } from "@app/components/Typography"
import { format, parseISO } from "date-fns"

type Provider = {
  jobid: number
  providerid: number
  provider_color?: string
  provider_type?: string
  display_name: string
  avatar?: string
  job?: string
}

type NotifyChangesViewProps = {
  notificationConfig: {
    isVisible: boolean
    notifyProviders: Provider[]
    notifyJobs: { jobid: string; job: string }[]
    showProviderOptions: boolean
    providersToNotify: {
      assignmentUpdates: boolean
      newAssignments: boolean
      all: boolean
    }
    selectedProviders: Provider[]
    notifyScheduler: boolean
    showNotifyEmail: boolean
    manualEmails: string[]
  }
  updateNotificationConfig: (
    key: keyof NotifyChangesViewProps["notificationConfig"],
    value: any
  ) => void
  date: string
}

const NoProvidersMessage: React.FC = () => (
  <div className={css.noProviders}>
    <ul>
      <li className={css.customOption}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar size={25} src={"/images/user.svg"} className="mr-3" />
          There are no provider updates for this option.
        </div>
      </li>
    </ul>
  </div>
)

const NotifyChangesView: React.FC<NotifyChangesViewProps> = ({
  notificationConfig,
  updateNotificationConfig,
  date,
}) => {
  const formattedDate = format(parseISO(date), "MM/dd/yyyy")

  useEffect(() => {
    updateNotificationConfig("providersToNotify", {
      ...notificationConfig.providersToNotify,
      assignmentUpdates:
        notificationConfig.notifyProviders.filter(
          (provider) => provider.provider_type !== "new-assignment"
        ).length > 0,
      newAssignments: false,
    })
  }, [notificationConfig.notifyProviders])

  const handleProviderSelection = (provider: Provider) => {
    const isSelected = notificationConfig.selectedProviders.some(
      (selectedProvider) =>
        selectedProvider.providerid === provider.providerid &&
        selectedProvider.jobid === provider.jobid
    )
    const updatedSelectedProviders = isSelected
      ? notificationConfig.selectedProviders.filter(
          (selectedProvider) =>
            selectedProvider.providerid !== provider.providerid ||
            selectedProvider.jobid !== provider.jobid
        )
      : [...notificationConfig.selectedProviders, provider]

    updateNotificationConfig("selectedProviders", updatedSelectedProviders)
    const newAssignmentSelected = updatedSelectedProviders.some(
      (p) => p.provider_type === "new-assignment"
    )
    const assignmentUpdateSelected = updatedSelectedProviders.some(
      (p) => p.provider_type !== "new-assignment"
    )

    updateNotificationConfig("providersToNotify", {
      ...notificationConfig.providersToNotify,
      newAssignments: newAssignmentSelected,
      assignmentUpdates: assignmentUpdateSelected,
    })
  }

  const handleAllProvidersChange = (e: { target: { checked: boolean } }) => {
    const isAllSelected = e.target.checked
    const filteredProviders = notificationConfig.notifyProviders.filter(
      (provider) => provider.provider_type !== "new-assignment"
    )
    const updatedProviders = isAllSelected ? [] : filteredProviders
    updateNotificationConfig("providersToNotify", {
      assignmentUpdates: !isAllSelected && filteredProviders.length > 0,
      newAssignments: false,
      all: isAllSelected,
    })
    updateNotificationConfig("selectedProviders", updatedProviders)
  }

  const handleProviderTypeChange = (
    e: { target: { checked: boolean } },
    providerType: string,
    configKey: "assignmentUpdates" | "newAssignments"
  ) => {
    const isSelected = e.target.checked
    const filteredProviders = notificationConfig.notifyProviders.filter(
      (provider) =>
        providerType === "new-assignment"
          ? provider.provider_type === "new-assignment"
          : provider.provider_type !== "new-assignment"
    )

    updateNotificationConfig("providersToNotify", {
      ...notificationConfig.providersToNotify,
      [configKey]: isSelected,
      all: false,
    })

    const updatedProviders = isSelected
      ? [
          ...notificationConfig.selectedProviders,
          ...filteredProviders.filter(
            (provider) =>
              !notificationConfig.selectedProviders.some(
                (selectedProvider) =>
                  selectedProvider.providerid === provider.providerid &&
                  selectedProvider.jobid === provider.jobid
              )
          ),
        ]
      : notificationConfig.selectedProviders.filter((provider) =>
          providerType === "new-assignment"
            ? provider.provider_type !== "new-assignment"
            : provider.provider_type === "new-assignment"
        )

    updateNotificationConfig("selectedProviders", updatedProviders)
  }

  return (
    <div className={css.notifyChangesContainer}>
      <div className={css.notifyMainContainer}>
        <div className={css.columnNotify}>
          <h2 className={css.titleNotifyModal}>Job(s)</h2>
          <ul>
            {notificationConfig.notifyJobs.map((job) => (
              <li key={job.jobid}>{job.job}</li>
            ))}
          </ul>
        </div>
        <div className={css.columnNotify}>
          <h2 className={css.titleNotifyModal}>Date</h2>
          <span>{formattedDate}</span>
        </div>
      </div>
      <div className={css.notifyProvidersContainer}>
        <Checkbox
          label="Notify Providers"
          name="notify_providers"
          onChange={(e) =>
            updateNotificationConfig("showProviderOptions", e.target.checked)
          }
          className={css.checkboxNotify}
          value={notificationConfig.showProviderOptions}
        />
        {notificationConfig.showProviderOptions && (
          <div className={css.selectProviderNotifyOptionContainer}>
            <Checkbox
              label="Notify providers of assignment updates"
              name="providers_to_notify_assignment_updates"
              value={
                notificationConfig.providersToNotify.assignmentUpdates &&
                !notificationConfig.providersToNotify.all
              }
              className={css.checkboxSecundaryNotify}
              onChange={(e) =>
                handleProviderTypeChange(
                  e,
                  "assignment-updates",
                  "assignmentUpdates"
                )
              }
            />
            <div className={css.customSelect}>
              <ul>
                {notificationConfig.notifyProviders
                  .filter(
                    (provider) => provider.provider_type !== "new-assignment"
                  )
                  .map((provider) => (
                    <li
                      key={`${provider.providerid}-${provider.jobid}`}
                      className={css.customOption}
                      onClick={() => handleProviderSelection(provider)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          size={25}
                          src={provider.avatar || "/images/user.svg"}
                          className="mr-3"
                        />
                        <div
                          className={css.providerCellCircle}
                          style={{
                            background:
                              provider.provider_color || DefaultCalendarColor,
                          }}
                        ></div>
                        <H5 ellipsis>{provider.display_name}</H5>
                      </div>
                      <div className={css.checkboxContainer}>
                        <div>({provider.job})</div>
                        <div
                          className={
                            notificationConfig.selectedProviders.some(
                              (p) =>
                                p.providerid === provider.providerid &&
                                p.jobid === provider.jobid
                            )
                              ? css.checked
                              : css.unchecked
                          }
                        >
                          {notificationConfig.selectedProviders.some(
                            (p) =>
                              p.providerid === provider.providerid &&
                              p.jobid === provider.jobid
                          ) ? (
                            <CheckIcon className={css.checkIcon} />
                          ) : (
                            <div className={css.uncheckedIcon}></div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                {notificationConfig.notifyProviders.filter(
                  (provider) => provider.provider_type !== "new-assignment"
                ).length === 0 && <NoProvidersMessage />}
              </ul>
            </div>
            <Checkbox
              label="Notify providers of new assignments"
              name="providers_to_notify_new_assignments"
              value={
                notificationConfig.providersToNotify.newAssignments &&
                !notificationConfig.providersToNotify.all
              }
              className={css.checkboxSecundaryNotify}
              onChange={(e) =>
                handleProviderTypeChange(e, "new-assignment", "newAssignments")
              }
            />
            <div className={css.customSelect}>
              <ul>
                {notificationConfig.notifyProviders
                  .filter(
                    (provider) => provider.provider_type === "new-assignment"
                  )
                  .map((provider) => (
                    <li
                      key={`${provider.providerid}-${provider.jobid}`}
                      className={css.customOption}
                      onClick={() => handleProviderSelection(provider)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          size={25}
                          src={provider.avatar || "/images/user.svg"}
                          className="mr-3"
                        />
                        <div
                          className={css.providerCellCircle}
                          style={{
                            background:
                              provider.provider_color || DefaultCalendarColor,
                          }}
                        ></div>
                        <H5 ellipsis>{provider.display_name}</H5>
                      </div>
                      <div className={css.checkboxContainer}>
                        <div>({provider.job})</div>
                        <div
                          className={
                            notificationConfig.selectedProviders.some(
                              (p) =>
                                p.providerid === provider.providerid &&
                                p.jobid === provider.jobid
                            )
                              ? css.checked
                              : css.unchecked
                          }
                        >
                          {notificationConfig.selectedProviders.some(
                            (p) =>
                              p.providerid === provider.providerid &&
                              p.jobid === provider.jobid
                          ) ? (
                            <CheckIcon className={css.checkIcon} />
                          ) : (
                            <div className={css.uncheckedIcon}></div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                {notificationConfig.notifyProviders.filter(
                  (provider) => provider.provider_type === "new-assignment"
                ).length === 0 && <NoProvidersMessage />}
              </ul>
            </div>
            <Checkbox
              label="Notify all providers in the group"
              name="providers_to_notify_all"
              onChange={handleAllProvidersChange}
              value={notificationConfig.providersToNotify.all}
              className={css.checkboxSecundaryNotify}
            />
          </div>
        )}
      </div>
      <div className={css.notifyProvidersContainer}>
        <Checkbox
          label="Notify All Schedulers"
          name="notify_schedulers"
          onChange={() =>
            updateNotificationConfig(
              "notifyScheduler",
              !notificationConfig.notifyScheduler
            )
          }
          className={css.checkboxNotify}
          value={notificationConfig.notifyScheduler}
        />
      </div>
      <div className={css.notifyProvidersContainer}>
        <Checkbox
          label="Notify By Email"
          name="notify_email"
          onChange={() =>
            updateNotificationConfig(
              "showNotifyEmail",
              !notificationConfig.showNotifyEmail
            )
          }
          className={css.checkboxNotify}
          value={notificationConfig.showNotifyEmail}
        />
        {notificationConfig.showNotifyEmail && (
          <div className={css.emailNotifyContainer}>
            <div className={css.emailNotifyInputContainer}>
              <label className={css.emailNotifyLabel}>
                Enter recipient emails. Separate multiple entries with a comma.
              </label>
              <input
                type="text"
                placeholder="Enter email address"
                onChange={(e) => {
                  const emailsArray = e.target.value
                    .split(",")
                    .map((email) => email.trim())
                  updateNotificationConfig("manualEmails", emailsArray)
                }}
                className={css.emailNotifyInput}
                value={notificationConfig.manualEmails.join(", ")}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotifyChangesView
