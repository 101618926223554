import { getTimeStamp } from "@app/containers/manual-schedule-job-table/service"
import { SplitShift } from "@app/containers/spa/WhiteboardCalendar/data"
import { parseDate } from "@app/utils"
import { addDays } from "date-fns"

interface ITimePair {
  start: number
  end: number
  providerid: number
}

export const validateSplitShifts = (
  splitShifts: SplitShift[],
  job: JobType,
  edate: Date
) => {
  if (splitShifts.length == 0) {
    return `You cannot save a empty form.`
  }
  if (splitShifts.length == 1) {
    return `You cannot save a split shift with only one entry.`
  }
  let res = false
  for (let index = 0; index < splitShifts.length; index++) {
    const s = splitShifts[index]
    if (!validateRow(s)) {
      return `Please enter all required information for Split Shift #${
        index + 1
      }`
    }
  }
  if (!validateTally(splitShifts)) {
    return "Tally total for all splits must equal 1.0"
  }
  const timeGapAndSameUserError = validateTimeGapAndSameUser(
    splitShifts,
    job,
    edate
  )
  if (timeGapAndSameUserError) {
    return timeGapAndSameUserError
  }
  return res
}

const validateRow = (splitShift: SplitShift): boolean => {
  return (
    !!splitShift.starttime &&
    !!splitShift.endtime &&
    !!splitShift.providerid &&
    typeof splitShift.tally_credit === "number"
  )
}

const validateTally = (splList: SplitShift[]) => {
  let sum = 0
  splList.forEach((s) =>
    s.tally_credit ? (sum += s.tally_credit as number) : null
  )
  return sum == 1
}

const validateTimeGapAndSameUser = (
  splList: SplitShift[],
  job: JobType,
  edate: Date
) => {
  let timePairs: ITimePair[] = []

  const currentDayid = edate.getDay()
  const jobDaytype = job.job_day_types?.find(
    (jdt: any) => jdt.dayid === currentDayid + 1
  )
  const jobStarttime = jobDaytype?.starttime || job.starttime
  const jobEndtime = jobDaytype?.endtime || job.endtime

  const getNextDayTimeStamp = (time?: string) =>
    time &&
    addDays(
      parseDate(time, (f) => f.timeOnly),
      1
    ).valueOf()
  const baseStart = getTimeStamp(jobStarttime)
  const baseEnd =
    getTimeStamp(jobEndtime) > baseStart
      ? getTimeStamp(jobEndtime)
      : getNextDayTimeStamp(jobEndtime)

  // Make SplitShift time pair list
  splList.forEach((s) => {
    let start =
      (s.starttime as string) >= jobStarttime
        ? getTimeStamp(s.starttime as string)
        : getNextDayTimeStamp(s.starttime)
    let end =
      (s.endtime as string) > jobStarttime
        ? getTimeStamp(s.endtime as string)
        : getNextDayTimeStamp(s.endtime)

    if (start && end)
      timePairs.push({ start, end, providerid: s.providerid as number })
  })

  // Sort TimePair List
  timePairs.sort((a, b) => a.start - b.start)

  // walk through
  let timePt = baseStart
  let providerPt = -1
  for (let i = 0; i < timePairs.length; i++) {
    if (
      timePt != timePairs[i].start ||
      timePairs[i].start >= timePairs[i].end
    ) {
      return "There cannot be any gaps or overlapping splits in the split start and end times"
    } else {
      if (providerPt == timePairs[i].providerid) {
        return "The same user cannot be assigned to two consecutive splits"
      } else {
        providerPt = timePairs[i].providerid
        timePt = timePairs[i].end
      }
    }
  }
  if (timePt != baseEnd) {
    return "There cannot be any gaps or overlapping splits in the split start and end times"
  } else {
    return false
  }
}
