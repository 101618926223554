import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "@app/models"
import { useParams } from "react-router-dom"
import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card"
import Tabs from "@app/components/Tabs"
import Loader from "@app/components/Loader"
import BlocksPage from "./components/Blocks"
import SchedulesPage from "./components/Schedules"
import TalliesPage from "./components/Tallies"
import AnnualScheduleHeader from "./components/AnnualScheduleHeader"
import DailyTemplatesPage from "./components/DailyTemplates"
import { QuickAssignmentContext } from "./context/QuickAssignmentContext"
import { hideNavigationMenus } from "@app/utils"
import { useAsyncQueue } from "@app/utils/hooks"

export default () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const annualBlockSchedule = useSelector((state) => {
    const obj = state.blockSets.annualBlockSchedule
    return obj.id === id ? obj : undefined
  })

  const [quickAssignmentTemplate, setQuickAssignmentTemplate] = useState<
    AnnualBlockScheduleTemplateType | undefined
  >()

  const [quickAssignmentPage, setQuickAssignmentPage] = useState("rotation")

  const {
    addAsyncFuncToQueue: addTemplateRequestToQueue,
    totalEnqueued: templatesQueued,
    results: templateResults,
    clearResults: clearQuickAssignTemplateData,
  } = useAsyncQueue()

  useEffect(() => {
    if (id) {
      dispatch.blockSets.getAnnualBlockSchedule({ id })
      hideNavigationMenus()
    }
  }, [id])

  if (!id || !annualBlockSchedule) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <div className="w-100 p-3 d-flex justify-content-center">
            <Loader animation="dots" />
          </div>
        </Card>
      </BaseLayout>
    )
  }

  const tabs = [
    { title: "Blocks", eventKey: "blocks", component: BlocksPage },
    { title: "Schedules", eventKey: "schedules", component: SchedulesPage },
    { title: "Tallies", eventKey: "tallies", component: TalliesPage },
    {
      title: "Daily Templates",
      eventKey: "daily_templates",
      component: DailyTemplatesPage,
    },
  ].map(({ component: Comp, ...tab }) => ({
    ...tab,
    content: <div className="mt-5" children={<Comp id={id} />} />,
  }))

  const defaultTab = annualBlockSchedule.has_block_sets ? tabs[1] : undefined

  return (
    <QuickAssignmentContext.Provider
      value={{
        quickAssignmentTemplate,
        setQuickAssignmentTemplate,
        quickAssignmentPage,
        setQuickAssignmentPage,
        addTemplateRequestToQueue,
        templateResults,
        templatesQueued,
        clearQuickAssignTemplateData,
      }}
    >
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <Tabs
            asRoute
            variant="underline"
            tabs={tabs}
            defaultActiveKey={defaultTab?.eventKey}
            customHeader={(props) => (
              <AnnualScheduleHeader
                {...props}
                annualBlockSchedule={annualBlockSchedule}
              />
            )}
          />
        </Card>
      </BaseLayout>
    </QuickAssignmentContext.Provider>
  )
}
