import React from "react"

import Modal from "@app/components/Modal"

import { formatDateInTimezone } from "@app/utils"
import { DateFormatter } from "@app/utils/constants"
import Button from "@app/components/Button"

type PropsType = {
  show: boolean
  onHide: () => void
  overlappingShiftData: {
    [roleName: string]: JobType[]
  }
}

export default ({ show, onHide, overlappingShiftData }: PropsType) => {
  return (
    <Modal
      onHide={onHide}
      show={show}
      noFooter={true}
      title="Overlapping shift error"
      titleAlign="center"
      closeButton={false}
    >
      <p>
        You are attempting to schedule jobs with overlapping times that are
        mapped to the same role in TigerConnect.
      </p>
      <p>
        In order to proceed with saving these assignments, please fix the
        following conflicts.
      </p>
      <ul>
        {Object.keys(overlappingShiftData).map((roleName) => (
          <li>
            {roleName} is being populated by the following conflicting jobs.
            <ul>
              {overlappingShiftData[roleName].map((job) => (
                <li>
                  {job.name}{" "}
                  {formatDateInTimezone(
                    new Date(job.starttime),
                    "UTC",
                    DateFormatter.shortHourMinute12h
                  )}{" "}
                  -{" "}
                  {formatDateInTimezone(
                    new Date(job.endtime),
                    "UTC",
                    DateFormatter.shortHourMinute12h
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <div className="d-flex justify-content-center">
        <Button onClick={onHide}>Close</Button>
      </div>
    </Modal>
  )
}
