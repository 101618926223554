import React from "react"
import css from "../JobsTable.module.scss"

interface MultipleProvidersShiftTimeProps {
  item: any
  rowIndex: number
  expandedRows: Record<number, boolean>
  toggleExpandedRow: (rowIndex: number) => void
}

const MultipleProvidersShiftTime: React.FC<MultipleProvidersShiftTimeProps> = ({
  item,
  rowIndex,
  expandedRows,
  toggleExpandedRow,
}) => {
  return (
    <div>
      {!expandedRows[rowIndex] && (
        <div
          key={`multiple-${rowIndex}`}
          className={css.containerShiftMultiple}
        >
          {item.provider.map((idx: number) => (
            <div key={idx}>{item.shifttime}</div>
          ))}
        </div>
      )}
      {expandedRows[rowIndex] && (
        <div onClick={(e) => e.stopPropagation()} className={css.shiftTime}>
          {item.additional_event_assignments.map(
            (assignment: any, idx: number) => (
              <div className={css.shifttimeContainer} key={idx}>
                <div className={css.shiftMultiTime}>
                  {assignment.job.starttime.substring(0, 5)} -{" "}
                  {assignment.job.endtime.substring(0, 5)},
                </div>
              </div>
            )
          )}
          <div className={css.shifttimeContainer} key={item.jobid}>
            <div className={css.shiftMultiTime}>{item.shifttime}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MultipleProvidersShiftTime
