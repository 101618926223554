import React, { useState } from "react"
import Dropdown from "@app/components/Dropdown"
import { H4, Text } from "@app/components/Typography"
import { Row, Col } from "@app/components/Layout"
import Icon from "@app/components/Icon"
import {
  DayProgressType,
  DayProgressStatusType,
  progressStatusMapping,
} from "@app/services/rotationProgress"
import CreateOrUpdateRotationForm from "./CreateOrUpdateRotationForm"
import DeleteRotationModal from "./DeleteRotationModal"
import css from "./RotationInfo.module.scss"

type Props = {
  annualBlockScheduleRotation: AnnualBlockScheduleRotationType
  annualBlockScheduleId: string
  progressData?: DayProgressType[]
  blockHeight?: number
}

const ProgressStatusCount = (props: {
  status: DayProgressStatusType
  count: number
}) => {
  const { status, count } = props
  const { color, icon, shortName } = progressStatusMapping[status]

  return (
    <Text style={{ color }} className="d-flex align-items-center">
      <Icon name={icon} className="mr-1" />
      <span className="mr-2">{shortName}</span>
      <span>{count}d</span>
    </Text>
  )
}

export default (props: Props) => {
  const {
    annualBlockScheduleId,
    annualBlockScheduleRotation: annualRotation,
    progressData,
    blockHeight,
  } = props

  const [rotationEditFormShow, setRotationEditFormShow] = useState(false)
  const [showDeleteRotationModal, setShowDeleteRotationModal] = useState(false)
  const progressStatusCountBy = [
    DayProgressStatusType.FULLYSTAFFED,
    DayProgressStatusType.OVERSTAFFED,
    DayProgressStatusType.UNDERSTAFFED,
  ].map((status: DayProgressStatusType) => {
    const count = (progressData || []).filter((x) => x.status === status).length
    return { status, count }
  })

  return (
    <div className={css.rotationInfo} style={{ height: blockHeight }}>
      <div>
        <H4
          bold
          ellipsis
          variant="black"
          prefixDotColor={annualRotation.block_schedule_rotation?.color}
          title={annualRotation.block_schedule_rotation?.name}
        >
          {annualRotation.block_schedule_rotation?.name}
        </H4>
        <Row noMargin className="d-flex mt-3">
          {progressStatusCountBy.map((props) => (
            <Col noPadding key={props.status}>
              <ProgressStatusCount {...props} />
            </Col>
          ))}
        </Row>
      </div>
      <CreateOrUpdateRotationForm
        annualBlockScheduleId={annualBlockScheduleId}
        annualBlockScheduleRotation={annualRotation}
        show={rotationEditFormShow}
        onHide={setRotationEditFormShow}
      />
      <DeleteRotationModal
        annualBlockScheduleId={annualBlockScheduleId}
        annualRotation={annualRotation}
        show={showDeleteRotationModal}
        onHide={setShowDeleteRotationModal}
      />
      <Dropdown
        className={css.moreActions}
        overlay={
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setRotationEditFormShow(true)}>
              Edit Rotation
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setShowDeleteRotationModal(true)}>
              Delete Rotation
            </Dropdown.Item>
          </Dropdown.Menu>
        }
      >
        <Icon hoverable name="three-dots" className={css.moreDropdownIcon} />
      </Dropdown>
    </div>
  )
}
