import React from "react"
import { Row, Col } from "@app/components/Layout"
import { Text } from "@app/components/Typography"
import { UseControlProps } from "./useForm"
import isArray from "lodash/isArray"
import cx from "classnames"
import css from "./FormItem.module.scss"

interface Props extends UseControlProps {
  label: string | React.ReactNode
  className?: string
  required?: boolean
  layout?: "horizontal" | "vertical" | [number, number]
}

const columnSizes = {
  horizontal: ["3", "9"],
  vertical: ["12", "12"],
}

export default ((props) => {
  const {
    label,
    className,
    required,
    layout = "horizontal",
    children,
    ...rest
  } = props

  const [labelColSize, controlColSize] =
    typeof layout === "string" ? columnSizes[layout] : layout

  return (
    <Row
      className={cx(css.formItem, css[`formItemLayout-${layout}`], className)}
    >
      <Col md={labelColSize} className={css.formItemLabelContainer}>
        <Text
          bold
          variant="black"
          className={cx(css.formItemLabel, {
            [css.formItemRequired]: required,
          })}
        >
          {label}
        </Text>
      </Col>
      <Col md={controlColSize}>
        {React.Children.count(children) === 1
          ? React.cloneElement(
              React.Children.toArray(children).find((child) =>
                isArray(child) ? isArray.length > 0 : child
              ) as React.ReactElement<any>,
              rest
            )
          : children}
      </Col>
    </Row>
  )
}) as React.FC<Props>
