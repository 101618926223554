import React, { useState } from "react"
import Dropdown from "@app/components/Dropdown"
import { H5 } from "@app/components/Typography"
import Icon from "@app/components/Icon"
import Button from "@app/components/Button"
import cx from "classnames"
import css from "./SharedFilter.module.scss"

type Props = {
  filterName: string
  filterEnabled: boolean
  filterValuesCount: number
  onFilterClean: () => any
}

export default ((props) => {
  const {
    filterName,
    filterEnabled,
    filterValuesCount,
    onFilterClean,
    children,
  } = props

  const [show, setShow] = useState(false)

  const onToggle = (
    isOpen: boolean,
    _: any,
    { source }: { source: string }
  ) => {
    if (["rootClose", "click"].includes(source)) {
      setShow(isOpen)
    }
  }

  const onReset = () => {
    setShow(false)
    onFilterClean()
  }

  return (
    <Dropdown
      show={show}
      onToggle={onToggle}
      overlay={
        <Dropdown.Menu className={css.filterOverlay}>
          <div
            className={cx(
              "d-flex align-items-center p-4",
              css.filterOverlayHeader
            )}
          >
            <div className="d-flex align-items-center flex-fill">
              <Icon
                name="funnel"
                className={cx("mr-2", css.filterFunnelIcon)}
              />
              <H5 bold="medium">Filter by {filterName}</H5>
            </div>
            {filterEnabled && (
              <Button
                size="sm"
                variant="primary"
                className="text-uppercase"
                onClick={onReset}
              >
                Reset
              </Button>
            )}
          </div>
          <div className="p-4">{children}</div>
        </Dropdown.Menu>
      }
    >
      <div
        className={cx(css.filterContainer, {
          [css.filterLightUp]: filterEnabled,
        })}
      >
        {filterName}
        {filterEnabled && ` (${filterValuesCount})`}
      </div>
    </Dropdown>
  )
}) as React.FC<Props>
