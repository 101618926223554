import React from "react"

export type FormErrorType = {
  jobid: number
  providerid: number
  messages: string[]
}

export const ManualScheduleFormErrorsContext = React.createContext<{
  errors: FormErrorType[]
  assignments: ManualScheduleJobAssignmentType[]
}>({ errors: [], assignments: [] })
