import { Col, Container, Row } from "@app/components/Layout"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import css from "./TabsLayout.module.scss"

export interface TabType {
  title: string
  eventKey: string
  Component: React.ComponentType
}

interface TabsLayoutProps {
  tabs: Array<TabType>
  justifyContent?: string
  selected?: string
}

const TabsLayout: React.FC<TabsLayoutProps> = (props): JSX.Element => {
  const [tabSelected, setTabSelected] = useState(props.tabs[0].eventKey)

  const getTabContent = (): React.Component<any, any> | JSX.Element => {
    const tab = props.tabs.find((tab) => tab.eventKey === tabSelected)

    if (tab) {
      return <tab.Component />
    } else {
      return <h1>Tab not found</h1>
    }
  }

  useEffect(() => {
    if (props.selected) {
      setTabSelected(props.selected)
    }
  }, [props.selected])

  useEffect(() => {
    getTabContent()
  }, [tabSelected])

  return (
    <Container className={css.tabsComponent}>
      <Row xs={2} md={4} lg={6}>
        <Col>
          <ul
            id="tabs"
            style={{
              justifyContent: `${props.justifyContent || "start"}`,
            }}
          >
            {props.tabs.map((tab: TabType, index: number) => (
              <li
                id={tab.eventKey === tabSelected ? "current" : ""}
                key={index}
              >
                <Link to={`/${tab.eventKey}`} replace>
                  {tab.title}
                </Link>
              </li>
            ))}
          </ul>
        </Col>
        <Col>
          <div id="content" style={{ minWidth: "90%" }}>
            {getTabContent()}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default TabsLayout
