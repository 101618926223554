import { fetch } from "./axios"
import useSWR from "swr"
export * from "swr"

const defaultFetcher = (key: any, ...params: any[]) => {
  if (typeof key === "string") {
    return fetch(key, ...params)
  } else if (Array.isArray(key)) {
    const [fn, ...fnArgs] = key
    return fn(...fnArgs)
  } else {
    return key(...params)
  }
}

function useRequest<D = any, E = any>(key: any, ...args: any[]) {
  let [fetcher, config] = args
  config = { errorRetryCount: 10, ...config }

  if (typeof fetcher === "object") {
    return useSWR<D, E>(key, { fetcher: defaultFetcher, ...fetcher, ...config })
  } else {
    return useSWR<D, E>(key, fetcher || defaultFetcher, config)
  }
}

export default useRequest
