import React from "react"
import type { ModalControlProps } from "@app/components/Modal"
import Modal from "@app/components/Modal"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import {
  FormItem,
  Select,
  Input,
  useForm,
  useFieldArray,
  ErrorMessage,
} from "@app/components/Form"
import { Text, H5 } from "@app/components/Typography"
import api, { useRequest, mutate } from "@app/services/api"
import { checkStaffLevelsUnique } from "@app/services/staffLevelsValidation"
import { mapPick, handleApiError } from "@app/utils"
import css from "./CreateOrUpdateTallyForm.module.scss"

type Props = ModalControlProps & {
  annualBlockScheduleId: string
  annualBlockScheduleTally?: AnnualBlockScheduleTallyType
}

type FormFieldsType = {
  name: string
  annual_block_schedule_rotation_ids: string[]
  tally_targets: {
    staff_level_id: string
    count: number
  }[]
}

const defaultTallyTarget = {
  staff_level_id: undefined,
  count: undefined,
}

export default (props: Props) => {
  const {
    show,
    onHide,
    annualBlockScheduleId,
    annualBlockScheduleTally: tally,
  } = props

  const { data: staffLevels } = useRequest([api.getGroupStaffLevels])
  const { data: allAnnualRotations } = useRequest<
    AnnualBlockScheduleRotationType[]
  >([api.getAnnualBlockScheduleRotations, annualBlockScheduleId])

  const {
    control,
    handleModalSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          name: yup.string().max(80).required().label("Tally Name"),
          annual_block_schedule_rotation_ids: yup
            .array()
            .ensure()
            .min(1, "You must add at least one Rotation")
            .of(yup.string()),
          tally_targets: yup
            .array()
            .ensure()
            .min(1, "You must add at least one Tally Target")
            .of(
              yup.object().shape({
                staff_level_id: yup
                  .string()
                  .required()
                  .test(
                    "unique",
                    "You cannot have the same Staff Level for two different Tally Targets",
                    checkStaffLevelsUnique
                  )
                  .label("Staff Level"),
                count: yup.number().integer().required().label("Count"),
              })
            ),
        })
      ),
  })

  const {
    fields: tallyTargetFields,
    append: appendTallyTarget,
    remove: removeTallyTarget,
  } = useFieldArray({ control, name: "tally_targets" })

  // Set default values for staff_requirements and tally_targets
  const onModalShow = () => {
    resetForm({
      name: tally?.name,
      annual_block_schedule_rotation_ids:
        tally?.annual_block_schedule_rotation_ids,
      tally_targets: tally
        ? mapPick(tally.tally_targets, ["staff_level_id", "count"])
        : [defaultTallyTarget],
    })
  }

  const onModalClose = (status: boolean) => {
    onHide(status)
    resetForm({})
  }

  const onSubmit = (fields: FormFieldsType, closeModal: Function) => {
    const requestHandler = tally
      ? api.updateAnnualBlockScheduleTally(tally.id, fields)
      : api.createAnnualBlockScheduleTally({
          ...fields,
          annual_block_schedule_id: annualBlockScheduleId,
        })

    return requestHandler.then((res) => {
      mutate([api.getAnnualBLockScheduleTallies, annualBlockScheduleId])
      closeModal()
    }, handleApiError)
  }

  return (
    <Modal
      title={`${tally ? "Edit" : "Add"} Tally`}
      show={show}
      onShow={onModalShow}
      onHide={onModalClose}
      size="lg"
      buttons={[
        { text: "Cancel", variant: "outline-primary" },
        {
          control,
          text: "Save",
          onClick: handleModalSubmit(onSubmit),
        },
      ]}
    >
      <FormItem required label="Tally Name" name="name" control={control}>
        <Input />
      </FormItem>
      <FormItem
        required
        label="Rotations"
        name="annual_block_schedule_rotation_ids"
        control={control}
      >
        <Select
          multiple
          inline
          loading={!allAnnualRotations}
          options={allAnnualRotations || []}
          labelKey="block_schedule_rotation"
          renderOption={(rotation) => (
            <H5 ellipsis prefixDotColor={rotation.color} title={rotation.name}>
              {rotation.name}
            </H5>
          )}
        />
      </FormItem>
      <FormItem label="Tally Targets">
        {tallyTargetFields.map((item, index) => (
          <div className={css.requirement} key={item.id}>
            <div className={css.requirementSelection}>
              <Select
                options={staffLevels}
                control={control}
                name={`tally_targets.${index}.staff_level_id`}
              />
            </div>
            <div className={css.requirementAmount}>
              <Input control={control} name={`tally_targets.${index}.count`} />
            </div>
            <div className={css.requirementRemove}>
              <Button
                shape="circle"
                variant="outline-secondary"
                className={css.removeIcon}
                onClick={() => removeTallyTarget(index)}
              >
                <Icon name="close" />
              </Button>
            </div>
          </div>
        ))}
        <ErrorMessage
          className={css.requirementAdd}
          control={control}
          name="tally_targets"
        >
          <Button
            shape="circle"
            variant="outline-secondary"
            className={css.addIcon}
            onClick={() => appendTallyTarget(defaultTallyTarget)}
          >
            <Icon name="plus" />
          </Button>
          <Text variant="grey" className="ml-3">
            Add tally targets
          </Text>
        </ErrorMessage>
      </FormItem>
    </Modal>
  )
}
