import { MultipleAssignmentJob } from "@app/containers/spa/WhiteboardCalendar/data"
import api from "@app/services/api"

type FormElement = {
  changed?: boolean
  type:
    | "event"
    | "multiple_assignments"
    | "unassigned"
    | "draft_event"
    | "draft_multiple_assignments"
    | "split_shift"
  data: any
  original_providerid?: number
}

export const checkProviderIdChanges = (formElements: FormElement[]) => {
  const changedElements: FormElement[] = []
  formElements.forEach((element) => {
    if (
      element.changed === true &&
      (element.type === "event" || element.type === "multiple_assignments")
    ) {
      if (Array.isArray(element.data)) {
        const filteredAssignments = element.data.filter(
          (assignment: any) =>
            assignment.original_providerid === undefined ||
            assignment.providerid !== assignment.original_providerid
        )
        if (filteredAssignments.length > 0) {
          changedElements.push({
            ...element,
            data: filteredAssignments,
          })
        }
      } else {
        if (
          element.data.original_providerid === undefined ||
          element.data.providerid !== element.data.original_providerid
        ) {
          changedElements.push(element)
        }
      }
    }
  })

  return changedElements
}

export const detectChangeToUnassigned = (data: FormElement[]) => {
  const changesToUnassigned: FormElement[] = []
  data.forEach((element) => {
    if (element.changed === true && element.type === "unassigned") {
      changesToUnassigned.push(element)
    }
  })

  return changesToUnassigned
}

export const enhanceChangedDataWithJobInfo = async (
  changedData: { [key: string]: FormElement[] },
  jobsData: MultipleAssignmentJob[]
) => {
  const result: { [key: string]: FormElement[] } = {}

  for (const key of Object.keys(changedData)) {
    const changes = await Promise.all(
      changedData[key].map(async (change) => {
        if (Array.isArray(change.data)) {
          const updatedData = await Promise.all(
            change.data.map(async (assignment) => {
              const matchingJobData = jobsData.find(
                (job) => job.jobid === assignment.jobid
              )
              const originalProvider =
                assignment.original_providerid !== undefined
                  ? await api.getProviderById(assignment.original_providerid)
                  : null
              const newProvider = await api.getProviderById(
                assignment.providerid
              )
              return {
                ...assignment,
                job: matchingJobData?.job || "Unknown Job",
                abbrev: matchingJobData?.abbrev || "Unknown Abbrev",
                ...(originalProvider && {
                  original_provider: {
                    avatar: originalProvider.avatar || "",
                    emailid: originalProvider.emailid,
                    display_name: originalProvider.display_name,
                    provider_color: originalProvider.provider_color,
                    providerid: originalProvider.providerid,
                  },
                }),
                new_provider: {
                  avatar: newProvider.avatar || "",
                  emailid: newProvider.emailid,
                  display_name: newProvider.display_name,
                  provider_color: newProvider.provider_color,
                  providerid: newProvider.providerid,
                },
              }
            })
          )

          return {
            ...change,
            data: updatedData,
          }
        } else {
          const matchingJobData = jobsData.find(
            (job) => job.jobid === change.data.jobid
          )
          const originalProvider =
            change.original_providerid !== undefined
              ? await api.getProviderById(change.original_providerid)
              : change.data.original_providerid !== undefined
              ? await api.getProviderById(change.data.original_providerid)
              : {}

          const newProvider =
            change.data.providerid !== undefined
              ? await api.getProviderById(change.data.providerid)
              : {}
          return {
            ...change,
            job: matchingJobData?.job || "Unknown Job",
            abbrev: matchingJobData?.abbrev || "Unknown Abbrev",
            original_provider: {
              avatar: originalProvider.avatar || "",
              emailid: originalProvider.emailid,
              display_name: originalProvider.display_name,
              provider_color: originalProvider.provider_color,
              providerid: originalProvider.providerid,
            },
            new_provider: {
              avatar: newProvider.avatar || "",
              emailid: newProvider.emailid,
              display_name: newProvider.display_name,
              provider_color: newProvider.provider_color,
              providerid: newProvider.providerid,
            },
          }
        }
      })
    )

    result[key] = changes
  }

  return result
}

export const extractProvidersFromChanges = (enhancedChangedData: any) => {
  const providers: any[] = []
  const providerIds = new Set<number>()

  enhancedChangedData.providerIdChanges.forEach((change: any) => {
    if (Array.isArray(change.data)) {
      change.data.forEach((assignment: any) => {
        if (assignment.original_provider) {
          providers.push({
            ...assignment.original_provider,
            provider_type: "original",
            jobid: assignment.jobid,
            job: assignment.job,
          })
          providerIds.add(assignment.original_provider.emailid)
        }
        if (
          assignment.new_provider &&
          assignment.original_providerid === undefined
        ) {
          providers.push({
            ...assignment.new_provider,
            provider_type: "new-assignment",
            jobid: assignment.jobid,
            job: assignment.job,
          })
          providerIds.add(assignment.new_provider.emailid)
        }
        if (
          assignment.new_provider &&
          assignment.original_providerid !== undefined
        ) {
          providers.push({
            ...assignment.new_provider,
            provider_type: "new",
            jobid: assignment.jobid,
            job: assignment.job,
          })
          providerIds.add(assignment.new_provider.emailid)
        }
      })
    } else if (change.data) {
      const assignment = change
      if (assignment.original_provider.providerid !== undefined) {
        providers.push({
          ...assignment.original_provider,
          provider_type: "original",
          jobid: assignment.data.jobid,
          job: assignment.job,
        })
        providerIds.add(assignment.original_provider.emailid)
      }
      if (
        assignment.new_provider &&
        assignment.original_provider.providerid !== undefined
      ) {
        providers.push({
          ...assignment.new_provider,
          provider_type: "new",
          jobid: assignment.data.jobid,
          job: assignment.job,
        })
        providerIds.add(assignment.new_provider.emailid)
      }
      if (
        assignment.new_provider &&
        assignment.original_provider.providerid === undefined
      ) {
        providers.push({
          ...assignment.new_provider,
          provider_type: "new-assignment",
          jobid: assignment.data.jobid,
          job: assignment.job,
        })
        providerIds.add(assignment.new_provider.emailid)
      }
    }
  })

  enhancedChangedData.unassignedChanges.forEach((change: any) => {
    if (change.original_provider) {
      providers.push({
        ...change.original_provider,
        provider_type: "original",
        jobid: change.data.jobid,
        job: change.job,
      })
      providerIds.add(change.original_provider.emailid)
    }
  })

  return providers
}

export const extractJobsFromChanges = (enhancedChangedData: any) => {
  const jobs: any[] = []
  const jobIds = new Set<number>()

  enhancedChangedData.providerIdChanges.forEach((change: any) => {
    if (Array.isArray(change.data)) {
      change.data.forEach((assignment: any) => {
        if (!jobIds.has(assignment.jobid)) {
          jobs.push({
            jobid: assignment.jobid,
            abbrev: assignment.abbrev,
            job: assignment.job,
          })
          jobIds.add(assignment.jobid)
        }
      })
    } else {
      const assignment = change
      if (!jobIds.has(assignment.data.jobid)) {
        jobs.push({
          jobid: assignment.data.jobid,
          abbrev: assignment.abbrev,
          job: assignment.job,
        })
        jobIds.add(assignment.data.jobid)
      }
    }
  })

  enhancedChangedData.unassignedChanges.forEach((change: any) => {
    if (!jobIds.has(change.data.jobid)) {
      jobs.push({
        jobid: change.data.jobid,
        abbrev: change.abbrev,
        job: change.job,
      })
      jobIds.add(change.data.jobid)
    }
  })

  return jobs
}
