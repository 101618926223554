import React, { useEffect, useState } from "react"
import { Select } from "@app/components/Form"
import Button from "@app/components/Button"
import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card"
import Loader from "@app/components/Loader"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import css from "./SchedulerPermissions.module.scss"
import cx from "classnames"

type PermissionOptionType = {
  name: string
  menu_itemid: number
  parentid?: number
  access?: boolean
  indent?: number
  children?: PermissionOptionType[]
  disabled?: boolean
}

type MenuItemIdStorageType = {
  [key: string]: number
}

type SchedulerPermissionsPropsType = {
  schedulerid: number
  scheduler_name: string
}

export default (props: SchedulerPermissionsPropsType) => {
  const [showLoader, setShowLoader] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [permissionOptions, setPermissionOptions] = useState<
    PermissionOptionType[]
  >([])
  const [permissionDefaults, setPermissionDefaults] = useState<number[]>([])
  //const [selectedObj, setSelectedObj] = useState({} as any)
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([])
  const permissions: PermissionOptionType[] = []

  useEffect(() => {
    const perms = [
      {
        name: "Settings",
        menu_itemid: 14,
        children: [
          { name: "Setup Info", menu_itemid: 17 },
          { name: "Scheduler List", menu_itemid: 50 },
          { name: "Provider List", menu_itemid: 18 },
        ],
      },
      { name: "Scheduling", menu_itemid: 20 },
      { name: "Provider Requests", menu_itemid: 57 },
    ]
    recurseTree(perms, 0, 0)
    setPermissionOptions(permissions)

    const defaultObj: MenuItemIdStorageType = {}
    let defaultArr: number[] = []
    let selectedObj: MenuItemIdStorageType = {}
    api.getUserMenuItems(props.schedulerid, "Scheduler").then(
      function (response) {
        for (const item of response) {
          if (item.access == 0) {
            //access==false
            defaultObj["id_" + item.menu_itemid] = 1
          }
        }
        for (const perm of permissions) {
          if (defaultObj["id_" + perm.menu_itemid]) {
            //negative permissions so this one is unchecked
          } else {
            selectedObj["id_" + perm.menu_itemid] = 1
            defaultArr.push(perm.menu_itemid)
          }
        }
        //setSelectedObj(selectedObj)
        setPermissionDefaults(defaultArr)
        setSelectedPermissions(defaultArr)
        setShowLoader(false)
      },
      function (err) {
        handleApiError(err)
      }
    )
  }, [])

  useEffect(() => {
    const disableSchedulerList = () => {
      setPermissionOptions(
        permissionOptions.map((option: PermissionOptionType) => {
          if (option.menu_itemid === 50) {
            return { ...option, disabled: true }
          }
          return option
        })
      )
    }

    const enableSchedulerList = () => {
      setPermissionOptions(
        permissionOptions.map((option: PermissionOptionType) => {
          return { ...option, disabled: false }
        })
      )
    }

    if (
      selectedPermissions.length !== permissionOptions.length &&
      permissionOptions.length !== 0
    ) {
      if (
        selectedPermissions.length === permissionOptions.length - 1 &&
        !selectedPermissions.find((menuItemId: number) => menuItemId === 50)
      ) {
        enableSchedulerList()
      } else {
        disableSchedulerList()
      }
    } else if (
      selectedPermissions.length === permissionOptions.length &&
      permissionOptions.length !== 0
    ) {
      enableSchedulerList()
    }
  }, [selectedPermissions])

  const recurseTree = (
    perms: PermissionOptionType[],
    indent: number,
    parentid: number
  ) => {
    for (const item of perms) {
      item.indent = indent
      item.parentid = parentid
      permissions.push(item)
      if (item.children) {
        recurseTree(item.children, indent + 1, item.menu_itemid)
      }
    }
  }

  const handleChange = (selected: number[]) => {
    //basically a long-winded way to detect what was just clicked
    //and make sure to select/deselect affected child or parent items
    let recentlyDeselected = 0
    let recentlySelected = 0
    let currentChecked: MenuItemIdStorageType = {}
    const newSelected = []

    const filterSchedulerItem = (selected: number[]) => {
      if (selected.length !== permissionOptions.length) {
        const schedulerListItemIndex = selected.findIndex(
          (menuItemId: number) => menuItemId === 50
        )

        if (schedulerListItemIndex !== -1) {
          let selectedSpliced = [...selected]
          selectedSpliced.splice(schedulerListItemIndex, 1)
          return selectedSpliced
        }
        return selected
      }
      return selected
    }

    if (selected.length == permissionOptions.length || selected.length == 0) {
      //[select-all] / [clear] was clicked
      setPermissionDefaults(selected)
      setSelectedPermissions(selected)
      return
    }

    for (const item of selectedPermissions) {
      currentChecked["id_" + item] = 1
    }
    let newlyChecked: MenuItemIdStorageType = {}
    //figure out what option was just clicked
    for (const item of selected) {
      newlyChecked["id_" + item] = 1
    }

    for (const item of permissionOptions) {
      if (
        currentChecked["id_" + item.menu_itemid] &&
        !newlyChecked["id_" + item.menu_itemid]
      ) {
        //recently de-selected
        recentlyDeselected = item.menu_itemid
      }
      if (
        newlyChecked["id_" + item.menu_itemid] &&
        !currentChecked["id_" + item.menu_itemid]
      ) {
        recentlySelected = item.menu_itemid
      }
    }

    if (recentlyDeselected) {
      //loop through the options and deselect any children of the recently deselected item
      for (const item of permissionOptions) {
        if (item.parentid && item.parentid == recentlyDeselected) {
          continue
        } else {
          if (newlyChecked["id_" + item.menu_itemid]) {
            newSelected.push(item.menu_itemid)
          }
        }
      }
      setPermissionDefaults(filterSchedulerItem(newSelected))
      setSelectedPermissions(filterSchedulerItem(newSelected))
      return
    }
    if (recentlySelected) {
      //if a child item was selected we need to make sure the parent is selected as well
      for (const item of permissionOptions) {
        if (newlyChecked["id_" + item.menu_itemid]) {
          newSelected.push(item.menu_itemid)
        } else {
          if (item.children) {
            for (const child of item.children) {
              if (child.menu_itemid == recentlySelected) {
                newSelected.push(item.menu_itemid)
              }
            }
          }
        }
      }
      setPermissionDefaults(filterSchedulerItem(newSelected))
      setSelectedPermissions(filterSchedulerItem(newSelected))
      return
    }
    setPermissionDefaults(filterSchedulerItem(selected))
    setSelectedPermissions(filterSchedulerItem(selected))
  }

  const doSubmit = () => {
    for (const option of permissionOptions) {
      option.access = false
      for (const selected of selectedPermissions) {
        if (option.menu_itemid == selected) {
          option.access = true
        }
      }
    }

    api
      .updateUserMenuItems(props.schedulerid, "Scheduler", {
        permissions: permissionOptions,
      })
      .then(
        function (response) {
          setShowSuccess(true)
        },
        function (err) {
          handleApiError(err)
        }
      )
  }

  if (showLoader == true) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <div className="w-100 p-3 d-flex justify-content-center">
            <Loader animation="dots" />
          </div>
        </Card>
      </BaseLayout>
    )
  }

  if (showSuccess == true) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <div className="w-100 p-3 d-flex justify-content-center">
            <h3>Permissions have been saved.</h3>
          </div>
          <div className={css.centerAlign}>
            <Button className="btn btn-primary" id="react-cancel-button">
              Close
            </Button>
          </div>
        </Card>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout containerSize="fluid" className="mt-3">
      <Card>
        <div className="w-100 p-3 d-flex justify-content-center">
          <h2>
            Edit Permissions for <i>{props.scheduler_name}</i>
          </h2>
        </div>
        <Select
          multiple
          inline
          labelKey="name"
          valueKey="menu_itemid"
          name="schedulerPermissions"
          options={permissionOptions}
          value={permissionDefaults?.map((itemid: number) => itemid)}
          renderOption={(text: string, option: PermissionOptionType) => (
            <div>
              <span className={cx({ [css.selectOptionIndent]: option.indent })}>
                {text}
              </span>
            </div>
          )}
          clearable={false}
          onChange={handleChange}
        />
        <div className={css.centerAlign}>
          <Button className="btn btn-light" id="react-cancel-button">
            Cancel
          </Button>{" "}
          &nbsp;
          <Button
            onClick={doSubmit}
            className="btn btn-primary"
            id="react-update-btn"
          >
            Save
          </Button>
        </div>
      </Card>
    </BaseLayout>
  )
}
