import React, { useState } from "react"
import ApplyDailyAssignmentHeader from "../../components/ApplyDailyAssignmentHeader"
import { formatDateRange } from "@app/utils"
import SelectTemplate from "./components/SelectTemplate"
import ConfirmDailyAssignments from "./components/ConfirmDailyAssignments"
import AppliedTemplateSummary from "./components/AppliedTemplateSummary"
import { JobsAndDatesType } from "./components/ConfirmDailyAssignments"

type PropType = {
  selectedRotation: AnnualBlockScheduleRotationType
  selectRotation: (selectedRotation: AnnualBlockScheduleRotationType) => void
  setModalTitle: (title: string) => void
  setDailyTemplatesShow: (toggle: boolean) => void
  provider: ProviderBaseType
  startDate: Date
  endDate: Date
  blockId: string
  closeModal: () => void
  appliedTemplateSelections: TemplateResultsType
  setAppliedTemplateSelections: (selections: TemplateResultsType) => void
  appliedTemplateResults: TemplateResultsType
  setTemplateId: (templateId: string) => void
  page: string
  setPage: (page: string) => void
  staffLevel: StaffLevelType
}

export type TemplateResultsType = {
  jobid: number
  providerid: number
  start_date: Date
}[]

export default (props: PropType) => {
  const {
    selectedRotation,
    provider,
    startDate,
    endDate,
    blockId,
    setModalTitle,
    setDailyTemplatesShow,
    selectRotation,
    setTemplateId,
    appliedTemplateResults,
    setAppliedTemplateSelections,
    page,
    setPage,
    closeModal,
    staffLevel,
  } = props
  const [selectedTemplate, setSelectedTemplate] =
    useState<AnnualBlockScheduleTemplateType>()
  const [jobsList, setJobsList] = useState<JobsAndDatesType[]>([])

  const getPage = (page: string) => {
    if (page === "selectTemplate") {
      setModalTitle("Apply Daily Assignment Template")
      return (
        <SelectTemplate
          setPage={setPage}
          setSelectedTemplate={(
            template: AnnualBlockScheduleTemplateType | undefined
          ) => {
            setSelectedTemplate(template)
            setTemplateId(template?.id || "")
          }}
          setDailyTemplatesShow={setDailyTemplatesShow}
          selectRotation={() => selectRotation(selectedRotation)}
          selectedRotationId={selectedRotation.block_schedule_rotation_id}
          staffLevelId={staffLevel.id}
        />
      )
    } else if (page === "confirmDailyAssignments" && selectedTemplate?.id) {
      setModalTitle("Confirm / Override Daily Assignments")
      return (
        <ConfirmDailyAssignments
          blockId={blockId}
          providerId={provider.providerid}
          templateId={selectedTemplate.id}
          setPage={setPage}
          setRotation={() => selectRotation(selectedRotation)}
          setSelectedTemplate={(
            template: AnnualBlockScheduleTemplateType | undefined
          ) => {
            setSelectedTemplate(template)
            setTemplateId(template?.id || "")
          }}
          setJobsList={setJobsList}
          setAppliedTemplateSelections={setAppliedTemplateSelections}
        />
      )
    } else if (page === "appliedTemplateSummary") {
      setModalTitle("Update Successful")
      return (
        <AppliedTemplateSummary
          jobsList={jobsList}
          appliedTemplateResults={appliedTemplateResults}
          closeModal={closeModal}
        />
      )
    } else {
      setModalTitle("Apply Daily Assignment Template")
      return (
        <SelectTemplate
          setPage={setPage}
          setSelectedTemplate={(
            template: AnnualBlockScheduleTemplateType | undefined
          ) => {
            setSelectedTemplate(template)
            setTemplateId(template?.id || "")
          }}
          setDailyTemplatesShow={setDailyTemplatesShow}
          selectRotation={() => selectRotation(selectedRotation)}
          selectedRotationId={selectedRotation.block_schedule_rotation_id}
          staffLevelId={staffLevel.id}
        />
      )
    }
  }

  return (
    <>
      <ApplyDailyAssignmentHeader
        providerName={`${provider.firstname} ${provider.lastname}`}
        dateRange={formatDateRange(
          { start: startDate, end: endDate },
          (f) => f.shortSlash
        )}
        rotation={selectedRotation.block_schedule_rotation}
        templateName={selectedTemplate?.name || ""}
      />
      {getPage(page)}
    </>
  )
}
