import React from "react"
import { Link, useResolvedPath, useMatch } from "react-router-dom"
import cx from "classnames"

interface Props extends PlainObjectType {
  to: string
  isActive?: (match: any, resolvedLocation: any) => boolean
  className?: string
  activeClassName?: string
}

export default ((props) => {
  const {
    to,
    className,
    isActive,
    activeClassName = "active",
    children,
    ...rest
  } = props

  const resolvedLocation = useResolvedPath(to)
  const match = useMatch({ path: resolvedLocation.pathname, end: true })
  const isActived = isActive ? isActive(match, resolvedLocation) : !!match

  return (
    <Link
      className={cx(className, { [activeClassName]: isActived })}
      to={to}
      {...rest}
    >
      {children}
    </Link>
  )
}) as React.FC<Props>
