import React, { useState } from "react"
import Modal from "@app/components/Modal"
import Button from "@app/components/Button"
import api, { mutate } from "@app/services/api"
import { handleApiError } from "@app/utils"

type PropsType = {
  annualBlockScheduleId: string
  annualRotation: AnnualBlockScheduleRotationType
  show: boolean
  onHide: Function
}

export default (props: PropsType) => {
  const { annualBlockScheduleId, annualRotation, show, onHide } = props
  const [showConfirm, setShowConfirm] = useState(false)

  const onModalClose = (status: boolean) => {
    mutate([api.getAnnualBlockScheduleRotations, annualBlockScheduleId])
    onHide(status)
  }

  const deleteRotation = () => {
    api.deleteAnnualBlockScheduleRotation(annualRotation.id).then((res) => {
      setShowConfirm(true)
    }, handleApiError)
  }

  return (
    <Modal
      show={show}
      onHide={onModalClose}
      title="Delete Rotation"
      closeButton={false}
    >
      {!showConfirm ? (
        <div>
          <p className="text-center">
            Are you sure you want to delete the{" "}
            {annualRotation.block_schedule_rotation.name} rotation from the
            current academic year?
          </p>
          <div className="d-flex justify-content-center">
            <div>
              <Button
                className="mr-3"
                variant="outline-secondary"
                onClick={() => onModalClose(false)}
              >
                CANCEL
              </Button>
              <Button variant="danger" onClick={deleteRotation}>
                DELETE
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-center">
            The {annualRotation.block_schedule_rotation.name} rotation has been
            deleted from the current academic year.
          </p>
          <div className="d-flex justify-content-center">
            <Button onClick={() => onModalClose(false)}>OK</Button>
          </div>
        </div>
      )}
    </Modal>
  )
}
