import React, { useState, useRef } from "react"
import { HexColorPicker } from "react-colorful"
import Dropdown from "@app/components/Dropdown"
import Icon from "@app/components/Icon"
import { RotationColors } from "@app/utils/constants"
import { useControl, UseControlProps } from "./useForm"
import cx from "classnames"
import css from "./ColorPicker.module.scss"

type ColorFieldProps = {
  color: string
  className?: string
  clickable?: boolean
}

interface Props extends UseControlProps {}

const ColorField = (props: ColorFieldProps) => {
  const { color, className, clickable } = props

  return (
    <div
      style={{ backgroundColor: color }}
      className={cx(css.colorField, className, {
        border: !color,
        [css.colorFieldClickable]: clickable,
      })}
    />
  )
}

const ColorPicker: React.FC<Props> & { ColorField: typeof ColorField } = (
  props
) => {
  const { name, defaultValue, value, onChange } = useControl(props)
  const [innerValue, setInnerValue] = useState<string>(defaultValue)
  const [isCustom, setCustom] = useState(false)
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null)

  const currentColor = value ?? innerValue
  const customColor = RotationColors.includes(currentColor)
    ? RotationColors[RotationColors.length - 1]
    : currentColor

  const handleClick = (color: string) => {
    setInnerValue(color)
    onChange?.(color)
  }

  const handleCustomClick = (color: string) => {
    handleClick(color)

    const dropdownMenu = dropdownMenuRef.current

    // scroll to left/right to switch between normal picker and customization picker
    if (dropdownMenu) {
      const left = isCustom ? 0 : dropdownMenu.clientWidth
      dropdownMenu.scrollTo
        ? dropdownMenu.scrollTo({ left, behavior: "smooth" })
        : (dropdownMenu.scrollLeft = left)
    }

    setCustom((val) => !val)
  }

  return (
    <Dropdown
      variant="colorPicker"
      overlay={
        <Dropdown.Menu className={css.colorPicker}>
          <div ref={dropdownMenuRef} className={css.colorPickerContent}>
            <div className={css.gridColorPicker}>
              {RotationColors.map((color, index) =>
                index < RotationColors.length - 1 ? (
                  <Dropdown.Item
                    key={color}
                    style={{ backgroundColor: color }}
                    className={css.colorField}
                    onClick={() => handleClick(color)}
                  >
                    {color === currentColor && <Icon name="check" />}
                  </Dropdown.Item>
                ) : (
                  <div
                    key={customColor}
                    style={{ backgroundColor: customColor }}
                    className={cx(css.colorField, css.customColorField)}
                    onClick={() => handleCustomClick(customColor)}
                  >
                    <div className="text-center">
                      {customColor === currentColor && <Icon name="check" />}
                      <span className="d-inline-block w-100">
                        {isCustom ? "Back" : "Customize"}
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
            <HexColorPicker
              color={customColor}
              onChange={handleClick}
              className={css.customColorPicker}
            />
          </div>
        </Dropdown.Menu>
      }
    >
      <ColorField clickable color={currentColor} />
      <input type="hidden" name={name} value={currentColor} />
    </Dropdown>
  )
}

ColorPicker.ColorField = ColorField

export default ColorPicker
