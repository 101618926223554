import React from "react"
import { H1, H5 } from "@app/components/Typography"

export default () => {
  return (
    <div className="bootstrap4">
      <div className="p-3">
        <H1 bold="medium" variant="black" className="mb-3">
          Not Found
        </H1>
        <H5 variant="black">The requested URL was not found on this server.</H5>
      </div>
    </div>
  )
}
