import React from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { Row, Col } from "@app/components/Layout"
import Button from "@app/components/Button"

type Props = {
  nextSection?: string
  onSubmit: (redirect: any) => any
}

export default ((props) => {
  const { nextSection, onSubmit, children } = props
  const { jobId } = useParams()
  const navigate = useNavigate()

  const goBack = () => navigate("/")
  const gotoNext = (job?: JobType) => {
    const currentJobId = job?.jobid || jobId
    nextSection &&
      navigate({
        pathname: `/jobs/${currentJobId}/edit/${nextSection}`,
        search: !jobId ? "from=new" : undefined,
      })
  }

  return (
    <div>
      {children}
      <Row className="mt-5 justify-content-md-end">
        <Col md="auto">
          <Button
            variant="outline-primary"
            className="text-uppercase"
            onClick={goBack}
          >
            Cancel
          </Button>
          <Button
            className="text-uppercase ml-3"
            onClick={() => onSubmit(goBack)}
          >
            Save
          </Button>
          {nextSection && (
            <Button
              className="text-uppercase ml-3"
              onClick={() => onSubmit(gotoNext)}
            >
              Save and Next
            </Button>
          )}
        </Col>
      </Row>
    </div>
  )
}) as React.FC<Props>
