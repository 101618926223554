import { createModel } from "@rematch/core"
import { RootModel } from "."

type StateType = {
  quickAssignmentRotation?: string | undefined
  highlightRotation: string[]
  quickAssignmentOrHighlight?: "QuickAssignment" | "Highlight" | undefined
}

export default createModel<RootModel>()({
  state: {
    highlightRotation: [],
  } as StateType,
  reducers: {
    updateRotationOperations(state, payload: Partial<StateType>) {
      return {
        ...state,
        ...payload,
      }
    },
  },
})
