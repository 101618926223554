import { pluralize, summate, parseDate } from "@app/utils"
import { differenceInDays } from "date-fns"

// Enum: Tally Count Mode of a Block Set
export const TallyCountModes = [
  { id: 1, name: "Block", unit: "Block", calculation: () => 1 },
  { id: 2, name: "Block x 2", unit: "Block", calculation: () => 2 },
  {
    id: 3,
    name: "Weeks",
    unit: "Week",
    calculation: (block: BlockSetBlockType) => {
      const startDate = parseDate(block.start_date)
      const endDate = parseDate(block.end_date)
      const diffDays = differenceInDays(endDate, startDate) + 1

      return diffDays / 7
    },
  },
]

// Pluralize tally count mode for providers,
// Block/Block x 2/Weeks
// Example:
//   tallyCountModePluralize(1) => "1 Block"
//   tallyCountModePluralize(1, 2) => "1 Block"
//   tallyCountModePluralize(2, 3) => "2 Weeks"
export function tallyCountModePluralize(
  value: number,
  countBlocksId: number = 1
) {
  const countMode = TallyCountModes.find((x) => x.id === countBlocksId)

  if (!countMode) {
    return
  }

  return pluralize(value, countMode.unit)
}

type CalculateTotalTallyDataArgsType = {
  scheduleProvider?: AnnualBlockScheduleProviderWithAllFieldsType
  annualRotations: AnnualBlockScheduleRotationType[]
  blockSets: BlockSetType[]
  tallies: AnnualBlockScheduleTallyType[]
}

type TallySummaryType = {
  tally: AnnualBlockScheduleTallyType
  tallyTarget: number
  tallyCredit: number
  validTallyCredit: number
  tallyTargetText?: string
  tallyCreditText?: string
}

export type TallyDataType = {
  tallyBasedSummaries: Array<
    TallySummaryType & {
      annualRotations: AnnualBlockScheduleRotationType[]
    }
  >
  rotationBasedSummaries: Array<
    AnnualBlockScheduleRotationType & {
      tallies: TallySummaryType[]
    }
  >
}

export function calculateTotalTallyData({
  scheduleProvider,
  annualRotations,
  blockSets,
  tallies: allTallies,
}: CalculateTotalTallyDataArgsType): TallyDataType {
  if (!scheduleProvider) {
    return {
      tallyBasedSummaries: [],
      rotationBasedSummaries: [],
    }
  }

  const {
    block_set_id: blockSetId,
    block_events: blockEvents,
    staff_level_id: staffLevelId,
  } = scheduleProvider

  const tallyBasedSummaries: TallyDataType["tallyBasedSummaries"] = []
  const rotationBasedSummaries: TallyDataType["rotationBasedSummaries"] = []

  const blockSet = blockSets.find((x) => x.id === blockSetId)

  if (blockSet) {
    const { count_blocks: countBlocksId, block_set_blocks: blocks } = blockSet

    annualRotations.forEach((scheduleRotation) => {
      const blockSetIds = scheduleRotation.block_sets.map((x) => x.block_set_id)

      // Aavailable rotation's block sets should contains block_set_id of scheduleProvider
      if (!blockSetIds.includes(blockSetId)) {
        return
      }

      const countMode =
        TallyCountModes.find((x) => x.id === countBlocksId) ||
        TallyCountModes[0]

      const filteredTallies = allTallies.filter((tally) =>
        tally.annual_block_schedule_rotation_ids.includes(scheduleRotation.id)
      )

      const tallies = filteredTallies.reduce((results, tally) => {
        const tallyTarget = tally.tally_targets.find(
          (x) => x.staff_level_id === staffLevelId
        )?.count

        if (tallyTarget == null) {
          return results
        }

        const tallyCreditNums = blockEvents
          .filter(
            (blockEvent) =>
              blockEvent.annual_block_schedule_rotation_id ===
              scheduleRotation.id
          )
          .map((blockEvent) => {
            const block = blocks?.find(
              (x) => x.id === blockEvent.block_set_block_id
            )
            return block ? countMode.calculation(block) : 0
          })
        const tallyCredit = summate(tallyCreditNums, { precision: 2 })

        results.push({
          tally,
          tallyTarget,
          tallyCredit,
          validTallyCredit: Math.min(tallyTarget, tallyCredit),
          tallyTargetText: tallyCountModePluralize(tallyTarget, countBlocksId),
          tallyCreditText: tallyCountModePluralize(tallyCredit, countBlocksId),
        })

        return results
      }, [] as TallySummaryType[])

      rotationBasedSummaries.push({
        ...scheduleRotation,
        tallies,
      })
    })

    allTallies.forEach((tally) => {
      const summaries: TallySummaryType[] = []
      const annualRotations: AnnualBlockScheduleRotationType[] = []

      rotationBasedSummaries.forEach(({ tallies, ...annualRotation }) => {
        const filteredTallySummaries = tallies.filter(
          (x) => x.tally.id === tally.id
        )

        if (filteredTallySummaries.length) {
          summaries.push(...filteredTallySummaries)
          annualRotations.push(annualRotation)
        }
      })

      if (!summaries.length) {
        return
      }

      const tallyTarget = summaries[0].tallyTarget
      const tallyCredit = summate(summaries, {
        key: "tallyCredit",
        precision: 2,
      })

      tallyBasedSummaries.push({
        tally,
        tallyTarget,
        tallyCredit,
        validTallyCredit: Math.min(tallyTarget, tallyCredit),
        tallyTargetText: tallyCountModePluralize(tallyTarget, countBlocksId),
        tallyCreditText: tallyCountModePluralize(tallyCredit, countBlocksId),
        annualRotations,
      })
    })
  }

  return {
    tallyBasedSummaries,
    rotationBasedSummaries,
  }
}
