import React from "react"
import { ProviderDefaultColor } from "@app/utils/constants"
import css from "./AssignmentDot.module.scss"

type AssignmentDot = {
  isDraft: boolean
  customColor?: string | null
}

const AssignmentDot = ({ isDraft, customColor }: AssignmentDot) => {
  return (
    <div
      className={css.assignmentDot}
      style={{
        backgroundColor: isDraft ? "#FFF" : customColor ?? ProviderDefaultColor,
        borderColor: customColor ?? ProviderDefaultColor,
      }}
    />
  )
}

export default AssignmentDot
