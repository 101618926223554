import React, { useMemo } from "react"
import { H3 } from "@app/components/Typography"
import Button from "@app/components/Button"
import Card from "@app/components/Card"
import Table from "@app/components/Table"
import { templates, humanizeTemplate } from "@app/services/dutyHourRuleTemplate"
import api, { useRequest } from "@app/services/api"

export default () => {
  const { data: dutyHourRules } = useRequest([api.getDutyHourRules])

  const columns: any[] = useMemo(
    () => [
      {
        dataKey: "staff_levels",
        title: "Staff Levels",
        forceWrap: true,
        render: (val: StaffLevelType[]) => val.map((x) => x.name).join(", "),
      },
      ...templates.map((template) => ({
        dataKey: template.type,
        title: template.name,
        render: (_: any, record: DutyHourRuleType) =>
          humanizeTemplate(template.type, record)?.map((text, index) => (
            <div key={index}>{text}</div>
          )),
      })),
      {
        dataKey: "id",
        position: "right",
        width: 100,
        render: (id: string) => (
          <Button
            variant="outline-secondary"
            size="sm"
            to={`/duty_hour_rules/${id}/edit`}
          >
            Edit
          </Button>
        ),
      },
    ],
    [templates]
  )

  return (
    <Card
      title={<H3 variant="blue">Duty Hour Rules</H3>}
      action={
        <Button size="sm" variant="outline-secondary" to="/duty_hour_rules/new">
          Add
        </Button>
      }
    >
      <Table
        data={dutyHourRules}
        loading={!dutyHourRules}
        scroll={{ y: 400 }}
        columns={columns}
        emptyMessage="No duty hour rules have been created for this group. Please set up your duty hour rules."
      />
    </Card>
  )
}
