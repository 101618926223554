import React from "react"
import SwapVertIcon from "@material-ui/icons/SwapVert"
import css from "./GroupManagementHeader.module.scss"

interface GroupManagementHeaderProps {
  sortBy: "name" | "latest_assigned_date" | null
  sortOrder: "asc" | "desc" | null
  handleSortChange: (key: "name" | "latest_assigned_date") => void
}

const GroupManagementHeader: React.FC<GroupManagementHeaderProps> = ({
  sortBy,
  sortOrder,
  handleSortChange,
}) => {
  const headers = [
    { label: "Group ID", sortable: false },
    { label: "Group Name", sortable: true, key: "name" },
    { label: "Group Abbreviation", sortable: false },
    { label: "Jobs", sortable: false },
    { label: "Roles Sync Jobs", sortable: false },
    { label: "Providers", sortable: false },
    { label: "Active Providers", sortable: false },
    { label: "Schedulers", sortable: false },
    { label: "Active Schedulers", sortable: false },
    { label: "Assignment for next 30 days", sortable: false },
    {
      label: "Latest Assignment Date",
      sortable: true,
      key: "latest_assigned_date",
    },
    { label: "Provider Protocols", sortable: false },
    { label: "Group Protocols", sortable: false },
    { label: "Roles Sync Enabled", sortable: false },
    { label: "Roles Sync 2-way", sortable: false },
    { label: "Roles Sync Empty Assignment Notification", sortable: false },
  ]

  return (
    <thead className={css.groupManagementHeader}>
      <tr>
        {headers.map((header, index) => (
          <th
            key={index}
            className={header.key === "name" ? css.groupName : ""}
          >
            <div
              className={header.sortable ? css.clickableHeader : ""}
              onClick={() =>
                header.sortable && handleSortChange(header.key as any)
              }
            >
              {header.label}
              {header.sortable && (
                <SwapVertIcon
                  className={
                    sortBy === header.key ? css.activeSortIcon : css.sortIcon
                  }
                  style={{
                    transform:
                      sortBy === header.key && sortOrder === "desc"
                        ? "rotate(180deg)"
                        : "none",
                  }}
                />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default GroupManagementHeader
