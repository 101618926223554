import React, { useContext, useEffect, useState, useMemo } from "react"
import { useMatch } from "react-router-dom"
import { useSelector } from "@app/models"
import { H4, Text } from "@app/components/Typography"
import { Row, Col } from "@app/components/Layout"
import Modal from "@app/components/Modal"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import Avatar from "@app/components/Avatar"
import Popover from "@app/components/Popover"
import Loader from "@app/components/Loader"
import ProviderRequestSummary from "./ProviderRequestSummary"
import ApplyDailyAssignmentTemplate from "./ApplyDailyAssignmentTemplate"
import { QuickAssignmentContext } from "../../../../../context/QuickAssignmentContext"
import {
  overlapOfDateRanges,
  formatDateRange,
  handleApiError,
  mutateCollection,
} from "@app/utils"
import api, { mutate } from "@app/services/api"
import type { TallyDataType } from "@app/services/tallyCalculation"
import type { RequestType } from "./type"
import cx from "classnames"
import css from "./Block.module.scss"
import { TemplateResultsType } from "./ApplyDailyAssignmentTemplate/ApplyDailyAssignmentTemplate"
import UnapplyDailyAssignmentTemplate from "./UnapplyDailyAssignmentTemplate/UnapplyDailyAssignmentTemplate"

type Props = {
  id: string
  isBuffer: boolean
  blockSetId: string
  startDate: Date
  endDate: Date
  left: number
  width: number
  resizedLeft: number
  resizedWidth: number
  minWidth?: number
  maxWidth?: number
  minStartDate?: Date
  maxEndDate?: Date
  daysCount?: number
  blockHeight?: number
  provider: AnnualBlockScheduleProviderType["provider"]
  staffLevel: StaffLevelType
  blockEvent?: BlockEventType
  tallyData: TallyDataType
  requests: RequestType[]
}

export default (props: Props) => {
  const {
    id: blockId,
    isBuffer,
    startDate,
    endDate,
    resizedWidth,
    resizedLeft,
    daysCount,
    blockHeight,
    provider,
    staffLevel,
    blockEvent,
    tallyData,
    requests,
  } = props

  const [assignmentFormShow, setAssignmentFormShow] = useState(false)
  const [modalEditting, setModalEditting] = useState(false)
  const [dailyTemplatesShow, setDailyTemplatesShow] = useState(false)
  const [selectedRotation, setSelectedRotation] =
    useState<AnnualBlockScheduleRotationType>()
  const [modalTitle, setModalTitle] = useState("")
  const [appliedTemplateSelections, setAppliedTemplateSelections] =
    useState<TemplateResultsType>([])
  const [appliedTemplateResults, setAppliedTemplateResults] =
    useState<TemplateResultsType>([])
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false)
  const [templateEvents, setTemplateEvents] = useState<{
    additional_event_assignments: AdditionalEventAssignmentType[]
    events: EventType[]
  }>()
  const [appliedTemplateId, setAppliedTemplateId] = useState("")
  const [templateId, setTemplateId] = useState("")
  const [selectedTemplate, setSelectedTemplate] =
    useState<AnnualBlockScheduleTemplateType>()
  const [page, setPage] = useState("selectTemplate")
  const [templateAssignmentsToDelete, setTemplateAssignmentsToDelete] =
    useState<{
      additional_assignment_ids: string[]
      eventids: number[]
      appliedTemplateId: string
    }>()
  const [
    templateAssignmentsToDeleteLoading,
    setTemplateAssignmentsToDeleteLoading,
  ] = useState(false)
  const [quickAssignmentDisabled, setQuickAssignmentDisabled] = useState(false)

  const { quickAssignmentRotation, highlightRotation } = useSelector(
    (state) => state.rotationOperations
  )

  const { quickAssignmentTemplate, addTemplateRequestToQueue } = useContext(
    QuickAssignmentContext
  )

  useEffect(() => {
    setSelectedTemplate(undefined)
    if (assignmentFormShow && blockEvent?.id) {
      api.getEventsFromAppliedTemplate(blockEvent?.id).then((res) => {
        setSelectedTemplate(res.annual_block_schedule_template || {})
      })
    }
  }, [assignmentFormShow])

  useEffect(() => {
    const quickAssignDisabledBool =
      (!isBuffer &&
        quickAssignmentRotation &&
        (!quickAssignmentRotationObj ||
          (currentRotation &&
            (currentRotation !== quickAssignmentRotationObj ||
              blockEvent.applied_template)))) ||
      (quickAssignmentTemplate &&
        !quickAssignmentTemplate.staff_levels?.filter(
          (staffLevelTemplate) => staffLevel.id === staffLevelTemplate.id
        ).length)
    setQuickAssignmentDisabled(!!quickAssignDisabledBool)
  }, [quickAssignmentRotation, quickAssignmentTemplate])

  // Whiteboard page should launch different popup when click on the block
  const isWhiteboardPage = useMatch("/annual_schedules/:id/whiteboard")

  const { rotationBasedSummaries } = tallyData
  const currentRotation =
    blockEvent &&
    rotationBasedSummaries.find(
      (x) => x.id === blockEvent.annual_block_schedule_rotation_id
    )

  const quickAssignmentRotationObj =
    quickAssignmentRotation &&
    rotationBasedSummaries.find((x) => x.id === quickAssignmentRotation)

  const leaveRequestsInBlock = useMemo(() => {
    const results: {
      abbrev: string
      start: Date
      end: Date
      originalRequest: RequestType
    }[] = []

    const filterRequests = (request: RequestType) => {
      if (isWhiteboardPage) {
        return (
          request.status === "Approved" ||
          request.status === "Day Off" ||
          request.status === "Vacation"
        )
      } else {
        return request.status !== "Declined"
      }
    }

    requests.filter(filterRequests).forEach((request) => {
      const overlap = overlapOfDateRanges(
        { start: startDate, end: endDate },
        { start: request.start_date, end: request.end_date }
      )

      overlap &&
        results.push({
          originalRequest: request,
          abbrev: (request.type_abbrev || request.type_name)[0]?.toLowerCase(),
          start: overlap.start,
          end: overlap.end,
        })
    })

    return results
  }, [requests, startDate, endDate])

  const onModalClose = () => {
    setAssignmentFormShow(false)
    setModalEditting(false)
    setPage("")
    setDailyTemplatesShow(false)
    setShowDeleteTemplateModal(false)
    // setTemplateEvents(undefined)
    setModalTitle("")
  }

  const selectRotation = async (record: AnnualBlockScheduleRotationType) => {
    const annualBlockScheduleId = record.annual_block_schedule_id
    const providerId = provider.providerid

    return api
      .updateBlockEvent(annualBlockScheduleId, {
        providerid: providerId,
        block_set_block_id: blockId,
        annual_block_schedule_rotation_id: record.id,
      })
      .then((blockEvent) => {
        // Mutate annualBlockScheduleProvider
        if (appliedTemplateSelections.length && templateId) {
          api
            .applyAssignmentsFromTemplate(
              templateId,
              blockEvent.id,
              appliedTemplateSelections
            )
            .then(
              (results: {
                events_created: TemplateResultsType
                total_events: TemplateResultsType
              }) => {
                setAppliedTemplateResults(results.events_created)
                setPage("appliedTemplateSummary")
              }
            )
        }

        if (quickAssignmentTemplate?.id) {
          setQuickAssignmentDisabled(true)
          addTemplateRequestToQueue(() =>
            api.applyAssignmentsFromTemplate(
              quickAssignmentTemplate.id || "",
              blockEvent.id
            )
          )
        }

        if (templateAssignmentsToDelete) {
          api
            .deleteEventsFromAppliedTemplate(
              templateAssignmentsToDelete.appliedTemplateId,
              {
                additional_assignment_ids:
                  templateAssignmentsToDelete.additional_assignment_ids,
                eventids: templateAssignmentsToDelete.eventids,
              }
            )
            .then((res) => {})
        }
        mutate(
          [
            api.getAnnualBlockScheduleProvider,
            annualBlockScheduleId,
            providerId,
          ],
          (provider?: AnnualBlockScheduleProviderWithAllFieldsType) => {
            // console.log(provider.block_events, blockEvent)
            if (provider) {
              provider.block_events = mutateCollection(
                provider.block_events,
                blockEvent,
                {
                  key: "block_set_block_id",
                }
              )
              return { ...provider }
            }
            return undefined
          }
        )

        // Mutate annualBlockScheduleRotation Assignment Statistics
        mutate([
          api.getAnnualBlockScheduleRotationAssignmentStatistics,
          blockEvent.annual_block_schedule_rotation_id,
        ])

        if (!appliedTemplateSelections.length) {
          onModalClose()
        }
      }, handleApiError)
  }

  const deleteRotation = () => {
    if (!currentRotation || !blockEvent) {
      return
    }

    const annualBlockScheduleId = currentRotation.annual_block_schedule_id
    const providerId = provider.providerid

    return api
      .deleteBlockEvent(annualBlockScheduleId, blockEvent.id)
      .then(() => {
        // Mutate annualBlockScheduleProvider
        mutate(
          [
            api.getAnnualBlockScheduleProvider,
            annualBlockScheduleId,
            providerId,
          ],
          (provider?: AnnualBlockScheduleProviderWithAllFieldsType) => {
            if (provider) {
              provider.block_events = mutateCollection(
                provider.block_events,
                blockEvent,
                {
                  delete: true,
                  key: "block_set_block_id",
                }
              )

              return { ...provider }
            }
            return undefined
          }
        )

        // Mutate annualBlockScheduleRotation Assignment Statistics
        mutate([
          api.getAnnualBlockScheduleRotationAssignmentStatistics,
          blockEvent.annual_block_schedule_rotation_id,
        ])

        onModalClose()
      }, handleApiError)
  }

  const deleteTemplateDataFromRotation = () => {
    if (blockEvent?.id) {
      setTemplateAssignmentsToDeleteLoading(true)
      api.getEventsFromAppliedTemplate(blockEvent.id).then((response) => {
        if (response.additional_event_assignments || response.events) {
          setTemplateAssignmentsToDeleteLoading(false)
          // Do not delete the assigned rotation if there is applied template
          if (quickAssignmentRotation) return

          setShowDeleteTemplateModal(true)
          setModalTitle("Delete Daily Assignments")
          setTemplateEvents(response)
          setSelectedTemplate(response.annual_block_schedule_template)
          setAppliedTemplateId(response.id)
        } else {
          deleteRotation()
        }
      })
    }
  }

  const selectNewRotation = (rotation: AnnualBlockScheduleRotationType) => {
    if (blockEvent?.id) {
      api.getEventsFromAppliedTemplate(blockEvent?.id).then((response) => {
        if (
          response?.additional_event_assignments?.length ||
          response?.events?.length
        ) {
          setShowDeleteTemplateModal(true)
          setModalTitle("Delete Daily Assignments")
          setTemplateEvents(response)
          setSelectedRotation(rotation)
          setSelectedTemplate(response.annual_block_schedule_template)
          setAppliedTemplateId(response.id)
          setAppliedTemplateSelections([])
        } else {
          setDailyTemplatesShow(true)
          setSelectedRotation(rotation)
        }
      })
    } else {
      setDailyTemplatesShow(true)
      setSelectedRotation(rotation)
    }
  }

  const renderTallies = <T,>(
    tallies: T[],
    attr: keyof T,
    options: { ellipsis?: boolean } = {}
  ) => {
    if (!tallies?.length) {
      return <div className="text-center">-</div>
    }

    const { ellipsis } = options

    return tallies.map((item: any) => (
      <Text key={item.tally.id} ellipsis={ellipsis}>
        {item[attr] || item.tally[attr]}
      </Text>
    ))
  }

  const tableColumns: any[] = [
    {
      title: "Rotation",
      dataKey: "block_schedule_rotation",
      className: css.rotationName,
      render: (rotation: BlockScheduleRotationType) => (
        <Text bold ellipsis variant="black" prefixDotColor={rotation.color}>
          {rotation.name}
        </Text>
      ),
    },
    {
      title: "Tally Name",
      dataKey: "tallyName",
      className: css.tallyName,
      render: (_: any, item: any) =>
        renderTallies(item.tallies, "name", { ellipsis: true }),
    },
    {
      title: "Tally Target",
      dataKey: "tallyTargetText",
      className: css.tallyTarget,
      render: (_: any, item: any) =>
        renderTallies(item.tallies, "tallyTargetText"),
    },
    {
      title: "Tally Credit",
      dataKey: "tallyCreditText",
      className: css.tallyCredit,
      render: (_: any, item: any) =>
        renderTallies(item.tallies, "tallyCreditText"),
    },
  ]

  if (!modalEditting && currentRotation) {
    tableColumns.push({
      title: "Template",
      render: () =>
        selectedTemplate === undefined ? (
          <Loader />
        ) : selectedTemplate.name ? (
          <Text>{selectedTemplate.name}</Text>
        ) : (
          <a
            href="javascript:void(0)"
            onClick={() => {
              setDailyTemplatesShow(true)
              setSelectedRotation(currentRotation)
            }}
          >
            Apply Template
          </a>
        ),
    })
  }

  if (modalEditting || !currentRotation) {
    tableColumns.push({
      dataKey: "action",
      position: "right",
      className: css.rotationAction,
      render: (_: any, record: AnnualBlockScheduleRotationType) => (
        <Button
          size="sm"
          disabled={
            record.block_schedule_rotation_id ===
            currentRotation?.block_schedule_rotation_id
          }
          onClick={() => {
            selectNewRotation(record)
          }}
        >
          Select
        </Button>
      ),
    })
  }

  const rotationColor = currentRotation?.block_schedule_rotation.color
  const rotationName = currentRotation?.block_schedule_rotation.name
  const rotationAbbrev = currentRotation?.block_schedule_rotation.abbrev

  const { daySizePixels, minDays } = useSelector(
    (state) => state.blockSets.blockConfig
  )

  // Calculate font-size for texts in Block content
  const [rotationFontSize, requestFontSize] = useMemo(() => {
    const calcFontSize = (
      charNums: number | undefined,
      options: { max?: number; min?: number } = {}
    ) => {
      if (!charNums) {
        return 0
      }

      const padding = 12
      const totalPx = (daysCount ?? minDays - 1) * daySizePixels - padding
      const fontSizeIfShowAll = 1.3 * (totalPx / charNums)

      const maxFontSize = options.max ?? 16
      const minFontSize = options.min ?? 8

      if (fontSizeIfShowAll > maxFontSize) {
        return maxFontSize
      } else if (fontSizeIfShowAll < minFontSize) {
        return minFontSize
      } else {
        return fontSizeIfShowAll
      }
    }

    const rotationFontSize = calcFontSize(rotationAbbrev?.length)
    const requestFontSize = calcFontSize(12, { max: 12 }) // Fixed 12 here, assume [T MM/DD-MM/DD]

    return [rotationFontSize, requestFontSize]
  }, [daysCount, rotationAbbrev, daySizePixels, minDays])

  const highlightDisabled =
    !isBuffer &&
    currentRotation &&
    highlightRotation.length &&
    !highlightRotation.includes(currentRotation.id)

  const triggerAssign = () => {
    if (isWhiteboardPage || quickAssignmentDisabled) return

    quickAssignmentRotation ? quickAssign() : setAssignmentFormShow(true)
  }

  const quickAssign = () => {
    if (currentRotation) {
      deleteTemplateDataFromRotation()
    } else {
      quickAssignmentRotationObj && selectRotation(quickAssignmentRotationObj)
    }
  }

  const blockNode = (
    <div
      className={cx(
        css.block,
        quickAssignmentDisabled
          ? css.quickAssignmentDisabled
          : highlightDisabled
          ? css.highlightDisabled
          : "",
        isBuffer
          ? css.blockBuffer
          : currentRotation
          ? css.blockNormal
          : css.blockUnassigned
      )}
      style={{
        width: resizedWidth,
        height: blockHeight,
        position: "absolute",
        left: resizedLeft,
        borderLeftColor: rotationColor,
      }}
      onClick={triggerAssign}
    >
      {!isBuffer && (
        <>
          <Modal
            title={
              modalTitle
                ? modalTitle
                : modalEditting || !currentRotation
                ? "Select Rotation"
                : "Rotation Info"
            }
            size="lg"
            show={assignmentFormShow}
            onHide={onModalClose}
            onScroll={(e: Event) => e.stopPropagation()}
          >
            {dailyTemplatesShow && selectedRotation ? (
              <ApplyDailyAssignmentTemplate
                selectRotation={selectRotation}
                selectedRotation={selectedRotation}
                provider={provider}
                startDate={startDate}
                endDate={endDate}
                setModalTitle={setModalTitle}
                blockId={blockId}
                setDailyTemplatesShow={setDailyTemplatesShow}
                closeModal={() => {
                  setTimeout(() => onModalClose(), 1)
                }}
                setTemplateId={setTemplateId}
                appliedTemplateSelections={appliedTemplateSelections}
                setAppliedTemplateSelections={setAppliedTemplateSelections}
                appliedTemplateResults={appliedTemplateResults}
                page={page}
                setPage={setPage}
                staffLevel={staffLevel}
              />
            ) : showDeleteTemplateModal ? (
              <UnapplyDailyAssignmentTemplate
                additionalAssignmentEvents={
                  templateEvents?.additional_event_assignments || []
                }
                events={templateEvents?.events || []}
                appliedTemplateId={appliedTemplateId}
                closeModal={() => onModalClose()}
                deleteRotation={deleteRotation}
                providerName={`${provider.firstname} ${provider.lastname}`}
                dateRange={formatDateRange(
                  { start: startDate, end: endDate },
                  (f) => f.shortSlash
                )}
                rotation={currentRotation?.block_schedule_rotation}
                templateName={selectedTemplate?.name || ""}
                modalEditting={modalEditting}
                goToNextPage={() => {
                  setDailyTemplatesShow(true)
                  setShowDeleteTemplateModal(false)
                }}
                setTemplateAssignmentsToDelete={setTemplateAssignmentsToDelete}
              />
            ) : (
              <>
                <Row>
                  <Col>
                    <H4>{provider.fullname}</H4>
                  </Col>
                  <Col sm="auto">
                    <Text>
                      {formatDateRange(
                        { start: startDate, end: endDate },
                        (f) => f.shortSlash
                      )}
                    </Text>
                  </Col>
                </Row>
                <Table
                  className={cx("mt-4", css.rotationsTable)}
                  striped={false}
                  hover={false}
                  variant="noBorder"
                  columns={tableColumns}
                  data={
                    modalEditting || !currentRotation
                      ? rotationBasedSummaries
                      : [currentRotation]
                  }
                />
                {leaveRequestsInBlock.length > 0 && (
                  <ProviderRequestSummary
                    className="mt-4"
                    requests={leaveRequestsInBlock.map(
                      (x) => x.originalRequest
                    )}
                    tableProps={{ striped: false, hover: false }}
                  />
                )}
                {!modalEditting && currentRotation && (
                  <div className="mt-5 d-flex justify-content-between">
                    {templateAssignmentsToDeleteLoading ? (
                      <Loader />
                    ) : (
                      <Button
                        variant="outline-danger"
                        onClick={deleteTemplateDataFromRotation}
                        className="text-uppercase"
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      onClick={() => setModalEditting(true)}
                      className="text-uppercase"
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </>
            )}
          </Modal>
          <div
            className={css.blockContent}
            style={{ borderLeftColor: rotationColor }}
            title={`Click to ${
              currentRotation ? "edit rotation assignment" : "select a rotation"
            }`}
          >
            {currentRotation ? (
              <H4
                variant="black"
                style={{ fontSize: rotationFontSize }}
                className={css.rotationAbbr}
              >
                {rotationAbbrev}
              </H4>
            ) : (
              <div />
            )}
            {leaveRequestsInBlock.length > 0 && (
              <div>
                {leaveRequestsInBlock.map((request, index) => (
                  <Text
                    key={index}
                    style={{ fontSize: requestFontSize }}
                    className={css.requestItem}
                  >
                    {request.abbrev}{" "}
                    {formatDateRange(request, (f) => f.shortSlashMonthOnly)}
                  </Text>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )

  if (isWhiteboardPage && currentRotation) {
    return (
      <Popover
        title="Rotation Assignment"
        content={
          <>
            <div className={css.rotationInfoItem}>
              <Text className={css.rotationInfoItemName} bold="medium">
                Provider
              </Text>
              <div className="d-flex align-items-center">
                <Avatar src={provider.avatar} size={20} />
                <Text className="ml-2">{provider.fullname}</Text>
                <Text className="ml-2">({staffLevel.name})</Text>
              </div>
            </div>
            <div className={css.rotationInfoItem}>
              <Text className={css.rotationInfoItemName} bold="medium">
                Rotation
              </Text>
              <Text
                bold
                ellipsis
                variant="black"
                prefixDotColor={rotationColor}
              >
                {rotationName}
              </Text>
            </div>
            <div className={css.rotationInfoItem}>
              <Text className={css.rotationInfoItemName} bold="medium">
                Dates
              </Text>
              <Text>
                {formatDateRange(
                  { start: startDate, end: endDate },
                  (f) => f.shortSlash
                )}
              </Text>
            </div>
          </>
        }
      >
        {blockNode}
      </Popover>
    )
  }

  return blockNode
}
