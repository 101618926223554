import React, { useState } from "react"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import useRequest from "@app/utils/request"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import AddClinicPermission from "./AddClinicPermission"
import { Group } from "../UserLookupEdit"
import { Dialog } from "@app/components/Modal"

type PropsType = {
  userid: number
  groupAssociations: Group[]
}

export default ({ userid, groupAssociations }: PropsType) => {
  const [showModal, setShowModal] = useState(false)
  const {
    data: permissions,
    isLoading,
    mutate,
  } = useRequest([api.getClinicPermissions, userid])

  const handleDelete = (id: string) => {
    api.deleteClinicPermission(id).then(() => {
      mutate()
    }, handleApiError)
  }

  const onDeleteButtonClick = (
    id: string,
    permission: UserClinicPermission
  ) => {
    Dialog.confirm({
      title: "Warning",
      message: `Are you sure you want to delete the ${permission.clinic_permission.permission} permission in the ${permission.clinic.name} clinic?`,
      buttons: {
        ok: { text: "Yes", onClick: () => handleDelete(id) },
        cancel: { text: "No" },
      },
    })
  }

  const hideModal = () => {
    setShowModal(false)
    mutate()
  }

  return (
    <>
      <AddClinicPermission
        show={showModal}
        onHide={hideModal}
        groupAssociations={groupAssociations}
        userid={userid}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "40px",
        }}
      >
        <h1>Enterprise Permissions</h1>
        <Button onClick={() => setShowModal(true)}>Add</Button>
      </div>
      <Table
        loading={isLoading}
        columns={[
          {
            title: "Clinic",
            dataKey: "clinic_name",
            position: "left",
          },
          {
            title: "Permission",
            dataKey: "permission_name",
            position: "left",
          },
          {
            dataKey: "id",
            position: "right",
            render: (id: string, permission: UserClinicPermission) => (
              <Button
                onClick={() => onDeleteButtonClick(id, permission)}
                variant="danger"
              >
                Delete
              </Button>
            ),
          },
        ]}
        data={permissions?.map((permission: UserClinicPermission) => {
          return {
            ...permission,
            clinic_name: permission.clinic.name,
            permission_name: permission.clinic_permission.permission,
          }
        })}
      />
    </>
  )
}
