import React, { useMemo, useCallback, useState, useEffect } from "react"
import cx from "classnames"
import css from "./AssignmentMultipleJobElement.module.scss"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { useSelector, useDispatch } from "@app/models"
import { getAdditionalAssignmentHighlighted } from "@app/services/getAdditionalAssignmentHighlighted"
import CustomTooltip from "../Tooltip"
import { AssignmentDetails } from "../AssignmentDetails"
import { getIsMultipleAssignmentHighlighted } from "../utils/getIsMultipleAssignmentHighlighted"
import {
  getIsAssignmentFiltered,
  getProviderIds,
} from "../utils/getIsAssignmentFiltered"
import { getIsSingleAssignmentHighlighted } from "../utils/getIsSingleAssignmentHighlighted"
import api from "@app/services/api"
import { handleApiError, formatDateToStartEnd } from "@app/utils"
import { optimisticUpdateEvent } from "@app/containers/spa/WhiteboardCalendar/utils/calendarUpdates"
import { useJobTimes } from "../../../CalendarGridView/hooks/useJobTime"

interface Props {
  assignment: GroupedAssignment
  isJobDisabled: boolean
  isJobSelectedInQuickAssignMode: (assignment: GroupedAssignment) => boolean
  getAssignmentUnderstaffedText: () => string
  isAssignmentUnderstaffed: boolean | undefined
  quickMode?: boolean
  showModal?: () => void
  isHighlightedChangesAssigment?: boolean
}

export const AssignmentMultipleJobsElement = ({
  assignment,
  isJobDisabled,
  isJobSelectedInQuickAssignMode,
  getAssignmentUnderstaffedText,
  showModal,
  quickMode,
  isHighlightedChangesAssigment,
}: Props) => {
  const {
    highlightOptions: { highlightProviders },
    filterOptions: {
      providersFilters: { providerIds },
    },
    isUnderstaffedJobsHighlightActive,
    isHighlightedChangesActive,
    rulesConfig: { display_job_times: showJobTimesInCalendar },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const { jobs } = useSelector((state) => state.groupData)
  const { calendarConfig } = useSelector((state) => state.calendarEvents)
  const { selectedProvider } = calendarConfig
  const { startDate, endDate } = calendarConfig.filterOptions
  const { jobStartTime, jobEndTime } = useJobTimes(
    assignment.jobid,
    assignment.edate
  )
  const [canAddAssignment, setCanAddAssignment] = useState(true)
  const [jobSettings, setJobSettings] = useState<{ maximum: number } | null>(
    null
  )
  const dispatch = useDispatch()

  useEffect(() => {
    let isMounted = true

    const fetchJobSettingsAndValidate = async () => {
      if (selectedProvider && isMounted && !jobSettings) {
        try {
          const response = await api.getJobMultipleAssignmentSettings(
            assignment.jobid,
            assignment.edate
          )
          setJobSettings(response)
          const currentAssignedCount =
            assignment.additional_event_assignments?.length + 1 || 1
          setCanAddAssignment(response.maximum > currentAssignedCount)
        } catch (error) {
          handleApiError
        }
      }
    }

    fetchJobSettingsAndValidate()

    return () => {
      isMounted = false
    }
  }, [selectedProvider, assignment, jobSettings])

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const isProviderHighlighted = getIsSingleAssignmentHighlighted(
    assignment,
    highlightProviders
  )

  const isSplitAssignmentHighlighted = getIsMultipleAssignmentHighlighted(
    assignment.additional_split_event_assignments,
    highlightProviders
  )

  const isAdditionalAssignmentHighlighted = getIsMultipleAssignmentHighlighted(
    assignment.additional_event_assignments,
    highlightProviders
  )

  const multipleAssignmentSettings = useMemo(
    () =>
      jobs
        .find((job) => job.jobid === assignment.jobid)
        ?.multipleAssignmentsSettings?.find(
          (setting) => setting.date === assignment.edate
        ),
    [jobs, assignment]
  )

  const isAssignmentUnderstaffed = useMemo(() => {
    const currentAssignedCount = assignment.additional_event_assignments
      ? assignment.additional_event_assignments.length + 1
      : 1

    return (
      isUnderstaffedJobsHighlightActive &&
      multipleAssignmentSettings &&
      currentAssignedCount < multipleAssignmentSettings.minimum
    )
  }, [
    isUnderstaffedJobsHighlightActive,
    assignment.additional_event_assignments,
  ])

  const itemIsHighlighted =
    isProviderHighlighted ||
    isSplitAssignmentHighlighted ||
    isAdditionalAssignmentHighlighted ||
    isAssignmentUnderstaffed ||
    (isHighlightedChangesActive && isHighlightedChangesAssigment)

  const additionalEventAssignments = assignment.additional_event_assignments

  const isProviderFilterActive = useMemo(
    () => Boolean(providerIds),
    [providerIds]
  )

  const multiAssignmentClasses = cx(css.assignmentWrapper, {
    [css.disabledCellJob]:
      isHighlightedChangesActive &&
      !isHighlightedChangesAssigment &&
      selectedProvider &&
      (isJobDisabled ||
        isJobSelectedInQuickAssignMode(assignment) ||
        (!isJobSelectedInQuickAssignMode(assignment) &&
          !isJobDisabled &&
          !canAddAssignment)),
    [css.highlightedAssignment]:
      (isHighlightedChangesActive && isHighlightedChangesAssigment) ||
      itemIsHighlighted ||
      isHighlightedChangesAssigment,
    [css.highlightedAssignmentDraft]:
      isHighlightedChangesActive &&
      isHighlightedChangesAssigment &&
      itemIsHighlighted &&
      assignment?.draft_eventid,
    [css.fadedAssignmentWrapper]:
      isHighlightedChangesActive &&
      !isHighlightedChangesAssigment &&
      (providersAreHighlighted || isUnderstaffedJobsHighlightActive) &&
      !itemIsHighlighted,
    [css.fadedDraftAssignmentWrapper]:
      isHighlightedChangesActive &&
      !isHighlightedChangesAssigment &&
      (providersAreHighlighted || isUnderstaffedJobsHighlightActive) &&
      !itemIsHighlighted &&
      assignment.draft_eventid,
  })

  const handleClick = useCallback(
    async (selectedAssignment: any) => {
      const multiProviderIds = getProviderIds(assignment)
      const filteredProviderIds = multiProviderIds.filter(
        (id) => id !== selectedAssignment.provider.providerid
      )

      if (quickMode && selectedProvider) {
        const updateFunction = assignment.draft_eventid
          ? api.updateDraftMultipleAssignments
          : api.updateMultipleAssignments
        if (
          selectedProvider?.providerid ===
          selectedAssignment.provider.providerid
        ) {
          const response = await updateFunction(
            assignment.jobid,
            assignment.edate,
            filteredProviderIds
          )
          const assignmentToAdd = Array.isArray(response)
            ? response[0]
            : response
          optimisticUpdateEvent(
            assignmentToAdd,
            startDate,
            endDate,
            Boolean(assignment.draft_eventid)
          )
          dispatch.quickAssignments.removeQuickAssignment(
            response.draft_eventid || response.eventid
          )
        } else {
          if (
            selectedProvider &&
            jobSettings &&
            multiProviderIds.length < jobSettings.maximum &&
            canAddAssignment
          ) {
            if (
              assignment.additional_event_assignments.some(
                (additionalAssignment) =>
                  additionalAssignment.providerid ===
                  selectedProvider?.providerid
              )
            ) {
              return
            } else {
              const response = await updateFunction(
                assignment.jobid,
                assignment.edate,
                Array.from(
                  new Set([
                    ...multiProviderIds,
                    Number(selectedProvider?.providerid),
                  ])
                )
              )
              const assignmentToAdd = Array.isArray(response)
                ? response[0]
                : response

              optimisticUpdateEvent(
                assignmentToAdd,
                startDate,
                endDate,
                Boolean(assignment.draft_eventid)
              )
              dispatch.quickAssignments.addQuickAssignment(assignmentToAdd)
            }
          } else {
            setCanAddAssignment(false)
          }
        }
      } else {
        showModal && showModal()
      }
    },
    [
      quickMode,
      showModal,
      selectedProvider,
      assignment,
      canAddAssignment,
      jobSettings,
    ]
  )

  return (
    <ul className={multiAssignmentClasses}>
      <li>
        <button
          className={cx(css.assignmentElementWrapper, {
            [css.activeCellJob]: isJobSelectedInQuickAssignMode(assignment),
            [css.assignmentElementWrapperFaded]:
              isHighlightedChangesActive &&
              !isHighlightedChangesAssigment &&
              providersAreHighlighted &&
              !getAdditionalAssignmentHighlighted(
                assignment.provider?.providerid,
                highlightProviders
              ),
            [css.multipleDisabledCellJob]:
              selectedProvider &&
              !isJobSelectedInQuickAssignMode(assignment) &&
              (!isHighlightedChangesActive || !isHighlightedChangesAssigment),
            [css.disabledCellJob]:
              isHighlightedChangesActive && !isHighlightedChangesAssigment,
          })}
          style={{
            color: assignment.draft_eventid ? "#A5A5A5" : "",
          }}
          onClick={() => handleClick(assignment)}
          disabled={
            isHighlightedChangesActive &&
            !isHighlightedChangesAssigment &&
            !isJobSelectedInQuickAssignMode(assignment) &&
            !canAddAssignment
          }
        >
          <div className={css.assignmentWrapper}>
            {showJobTimesInCalendar &&
              Boolean(assignment.additional_event_assignments) &&
              !Boolean(assignment.split_shiftid) && (
                <span className={css.jobDateTime}>
                  {jobStartTime &&
                    jobEndTime &&
                    formatDateToStartEnd(jobStartTime, jobEndTime)}
                </span>
              )}
          </div>
          {isProviderFilterActive &&
          getIsAssignmentFiltered(
            assignment.provider?.providerid,
            providerIds
          ) ? (
            <CustomTooltip
              arrow
              title={
                <AssignmentDetails
                  isDraft={Boolean(assignment.draft_eventid)}
                  providerColor={assignment.provider?.provider_color}
                  providerName={assignment.provider?.display_name}
                  providerJob={
                    assignment.split_shift || {
                      starttime: jobStartTime,
                      endtime: jobEndTime,
                    }
                  }
                  isMultiProvider={Boolean(
                    assignment.additional_event_assignments
                  )}
                  isSplitShift={Boolean(
                    assignment.additional_split_event_assignments
                  )}
                />
              }
            >
              <span className={css.filteredText}>Filtered</span>
            </CustomTooltip>
          ) : (
            <AssignmentDetails
              isDraft={Boolean(assignment.draft_eventid)}
              providerColor={assignment.provider?.provider_color}
              providerName={assignment.provider?.display_name}
              providerJob={
                assignment.split_shift || {
                  starttime: jobStartTime,
                  endtime: jobEndTime,
                }
              }
              isMultiProvider={Boolean(assignment.additional_event_assignments)}
              isSplitShift={Boolean(
                assignment.additional_split_event_assignments
              )}
            />
          )}
        </button>
      </li>

      {Boolean(assignment.split_shiftid) === true && (
        <>
          {assignment.additional_split_event_assignments?.map(
            (splitAssignment) => {
              const isDisabledInQuickMode = quickMode
              const isHighlighted =
                !quickMode &&
                providersAreHighlighted &&
                getAdditionalAssignmentHighlighted(
                  splitAssignment.provider?.providerid,
                  highlightProviders
                )
              return (
                <li
                  key={`${splitAssignment.providerid}-${splitAssignment.jobid}-${splitAssignment.eventid}-splitAssignment`}
                >
                  <button
                    disabled={
                      isDisabledInQuickMode ||
                      !isJobSelectedInQuickAssignMode(splitAssignment)
                    }
                    className={cx(css.assignmentElementWrapper, {
                      [css.activeCellJob]:
                        isJobSelectedInQuickAssignMode(splitAssignment),
                      [css.assignmentElementWrapperFaded]: isHighlighted,
                      [css.multipleDisabledCellJob]:
                        selectedProvider &&
                        !isJobSelectedInQuickAssignMode(splitAssignment),
                      [css.disabledCellJob]: isDisabledInQuickMode,
                    })}
                    style={{
                      color: splitAssignment.draft_eventid ? "#A5A5A5" : "",
                    }}
                    onClick={() => handleClick(splitAssignment)}
                  >
                    {isProviderFilterActive &&
                    getIsAssignmentFiltered(
                      splitAssignment.provider?.providerid,
                      providerIds
                    ) ? (
                      <CustomTooltip
                        arrow
                        title={
                          <AssignmentDetails
                            isDraft={Boolean(splitAssignment.draft_eventid)}
                            providerColor={
                              splitAssignment.provider?.provider_color
                            }
                            providerName={
                              splitAssignment.provider?.display_name
                            }
                            providerJob={splitAssignment.split_shift}
                            isSplitShift={true}
                          />
                        }
                      >
                        <span className={css.filteredText}>Filtered</span>
                      </CustomTooltip>
                    ) : (
                      <AssignmentDetails
                        isDraft={Boolean(splitAssignment.draft_eventid)}
                        providerColor={splitAssignment.provider?.provider_color}
                        providerName={splitAssignment.provider?.display_name}
                        providerJob={splitAssignment.split_shift}
                        isSplitShift={true}
                      />
                    )}
                  </button>
                </li>
              )
            }
          )}
        </>
      )}
      {additionalEventAssignments && additionalEventAssignments.length > 0 && (
        <>
          {additionalEventAssignments.map((addAssignment, index) => (
            <li
              key={`${addAssignment.providerid}-${addAssignment.jobid}-${
                addAssignment.eventid ?? addAssignment?.draft_eventid
              }-${index}`}
            >
              <button
                disabled={
                  !isJobSelectedInQuickAssignMode(addAssignment) &&
                  !itemIsHighlighted &&
                  !canAddAssignment
                }
                className={cx(css.assignmentElementWrapper, {
                  [css.activeCellJob]:
                    canAddAssignment &&
                    !itemIsHighlighted &&
                    isJobSelectedInQuickAssignMode(addAssignment),
                  [css.assignmentElementWrapperFaded]:
                    providersAreHighlighted &&
                    !getAdditionalAssignmentHighlighted(
                      addAssignment.provider?.providerid,
                      highlightProviders
                    ),
                  [css.multipleDisabledCellJob]:
                    selectedProvider &&
                    !isJobSelectedInQuickAssignMode(addAssignment),
                })}
                style={{
                  color: addAssignment.draft_eventid ? "#A5A5A5" : "",
                }}
                onClick={() => handleClick(addAssignment)}
              >
                {isProviderFilterActive &&
                getIsAssignmentFiltered(
                  addAssignment.provider?.providerid,
                  providerIds
                ) ? (
                  <CustomTooltip
                    arrow
                    title={
                      <div className={css.textContainer}>
                        <AssignmentDetails
                          isDraft={Boolean(addAssignment.draft_eventid)}
                          providerColor={addAssignment.provider?.provider_color}
                          providerName={addAssignment.provider?.display_name}
                          providerJob={{
                            ...assignment.job,
                            starttime: jobStartTime,
                            endtime: jobEndTime,
                          }}
                          isMultiProvider={Boolean(
                            assignment.additional_event_assignments
                          )}
                        />
                      </div>
                    }
                  >
                    <span className={css.filteredText}>Filtered</span>
                  </CustomTooltip>
                ) : (
                  <AssignmentDetails
                    isDraft={Boolean(addAssignment.draft_eventid)}
                    providerColor={addAssignment.provider?.provider_color}
                    providerName={addAssignment.provider?.display_name}
                    providerJob={assignment.job}
                    isMultiProvider={Boolean(
                      assignment.additional_event_assignments
                    )}
                  />
                )}
              </button>
            </li>
          ))}
        </>
      )}

      {isUnderstaffedJobsHighlightActive && isAssignmentUnderstaffed && (
        <div className={css.understaffedCounter}>
          {getAssignmentUnderstaffedText()}
        </div>
      )}
    </ul>
  )
}
