import React from "react"
import { useSelector } from "@app/models"
import Card from "@app/components/Card"
import Table, { ColumnType } from "@app/components/Table"
import Button from "@app/components/Button"
import { H3 } from "@app/components/Typography"
import { Dialog } from "@app/components/Modal"
import JobRolesCell from "./JobRolesCell"
import api, { useRequest } from "@app/services/api"
import { parseDate, formatDate, handleApiError } from "@app/utils"

type Props = {
  jobTypeId: number
  jobTypeName: string
}

export default (props: Props) => {
  const { jobTypeId, jobTypeName } = props
  const { data, mutate } = useRequest<JobType[]>([api.getJobs, jobTypeId])
  const { user_typeid: currentUserTypeId } = useSelector(
    (state) => state.users.currentUser
  )

  const deleteJob = (id: number) => {
    Dialog.confirm({
      title: "Confirm",
      message: (
        <>
          <div>Deleting this job will remove ALL past assignments.</div>
          <div>Are you sure this is what you wish to do?</div>
        </>
      ),
      buttons: {
        ok: {
          text: "Delete",
          variant: "danger",
          onClick() {
            return api.deleteJob(id).then(() => {
              mutate(data?.filter((x) => x.jobid !== id))
            }, handleApiError)
          },
        },
      },
    })
  }

  const renderTime = (time: string) => {
    if (!time) {
      return
    }

    const date = parseDate(time, (f) => f.timeOnly)
    return formatDate(date, (f) => f.hourMinute12h)
  }

  const columns: ColumnType<JobType>[] = [
    { title: "Name", dataKey: "name" },
    { title: "Abbrev", dataKey: "abbrev" },
    { title: "Priority", dataKey: "priority" },
    { title: "Start", dataKey: "starttime", render: renderTime },
    { title: "End", dataKey: "endtime", render: renderTime },
    {
      dataKey: "action",
      width: 200,
      position: "right",
      render: (_: any, item: any) => (
        <div>
          <Button
            size="sm"
            variant="outline-secondary"
            to={`/jobs/${item.jobid}/edit`}
          >
            Edit
          </Button>
          {currentUserTypeId && currentUserTypeId <= 5 && (
            <Button
              size="sm"
              variant="outline-secondary"
              className="ml-2"
              onClick={() => deleteJob(item.jobid)}
            >
              Delete
            </Button>
          )}
        </div>
      ),
    },
  ]

  // Column "Tigerconnect Role" is conditional
  if (data && data.some((item) => "tigerconnect_roles" in item)) {
    const actionIndex = columns.findIndex((x) => x.dataKey === "action")
    columns.splice(actionIndex, 0, {
      title: "Tigerconnect Roles",
      dataKey: "tigerconnect_roles",
      width: 250,
      render: (roles, job) => <JobRolesCell job={job} />,
    })
  }

  return (
    <Card
      title={<H3 variant="blue">{jobTypeName} Jobs</H3>}
      action={
        <Button
          size="sm"
          variant="outline-secondary"
          to={{ pathname: "/jobs/new", search: `job_typeid=${jobTypeId}` }}
        >
          Add a Job
        </Button>
      }
    >
      <Table
        columns={columns}
        data={data}
        rowKey="jobid"
        loading={!data}
        emptyMessage={`No ${jobTypeName} have been created for this group.`}
      />
    </Card>
  )
}
