import React, { useEffect } from "react"
import { useSelector } from "@app/models"
import { Select } from "@app/components/Form"
import { H5 } from "@app/components/Typography"
import Avatar from "@app/components/Avatar"
import SharedFilter from "./SharedFilter"
import { useFilter } from "@app/utils/hooks"
import { uniqBy } from "lodash"

type Props = {
  annualBlockScheduleId: string
}

export default (props: Props) => {
  const { annualBlockScheduleId: id } = props
  const {
    filterState: { conditions, values },
    enabled,
    clear,
    update,
    filterByConditions,
  } = useFilter(["annualBlockSchedule", id, "providers"])

  const annualProviders = useSelector(({ blockSets }) => {
    const { annualBlockScheduleProviders, annualBlockSchedule } = blockSets
    return id === annualBlockSchedule.id ? annualBlockScheduleProviders : []
  })

  const annualProvidersFilteredByConditions =
    filterByConditions(annualProviders)

  // Update values when conditions exist and changed
  useEffect(() => {
    if (Object.keys(conditions).length) {
      update({
        values: annualProvidersFilteredByConditions.map((x) => x.providerid),
      })
    }
  }, [conditions])

  const staffLevelsForFilters = uniqBy(
    annualProviders.map((x) => x.staff_level),
    "id"
  ).sort((a, b) =>
    a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: "base",
    })
  )

  return (
    <SharedFilter
      filterName="Providers"
      filterEnabled={enabled}
      filterValuesCount={values.length}
      onFilterClean={clear}
    >
      <Select
        className="mb-3"
        placeholder="All Staff Levels"
        multiple
        options={staffLevelsForFilters}
        value={conditions.staff_level_id?.value}
        onChange={(value) =>
          update({ condition: { name: "staff_level_id", value } })
        }
      />
      <Select
        inline
        multiple
        options={annualProvidersFilteredByConditions}
        valueKey="providerid"
        labelKey="provider.display_name"
        value={values || []}
        renderOption={(text, item) => (
          <div className="d-flex align-items-center">
            <Avatar size={25} src={item.provider.avatar} className="mr-3" />
            <H5 ellipsis>{text}</H5>
          </div>
        )}
        onChange={(values) => update({ values })}
      />
    </SharedFilter>
  )
}
