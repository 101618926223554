import React from "react"
import BaseLayout from "@app/containers/layouts/Base"
import { H2 } from "@app/components/Typography"
import { Row, Col } from "@app/components/Layout"
import StaffLevels from "./components/StaffLevels"
import Rotations from "./components/Rotations"
import AnnualSchedules from "./components/AnnualSchedules"
import Providers from "./components/Providers"
import DutyHourRules from "./components/DutyHourRules"

export default () => {
  return (
    <BaseLayout>
      <Row>
        <Col>
          <H2 className="pl-5 mb-4">Block Scheduling</H2>
        </Col>
        <Col md="auto" />
      </Row>
      <StaffLevels />
      <Rotations />
      <AnnualSchedules />
      <DutyHourRules />
      <Providers />
    </BaseLayout>
  )
}
