import React from "react"
import { useSelector, useDispatch } from "@app/models"
import DatePicker from "./components/DatePicker"
import SearchBox from "./components/SearchBox"
import SortBox from "./components/SortBox"
import Departments from "./components/Departments"
import { Checkbox } from "@app/components/Form"
import { H4 } from "@app/components/Typography"
import Button from "@app/components/Button"
import { useSearchParams } from "react-router-dom"
import { formatDateInTimezone } from "@app/utils"

export default () => {
  const dispatch = useDispatch()

  const [_searchParams, setSearchParams] = useSearchParams()

  const setToToday = () => {
    setSearchParams({ date: formatDateInTimezone(new Date(), timezone) })
  }

  const {
    customCalendar: { timezone },
    customCalendarFilterConditions: { hidePastFuture },
  } = useSelector((state) => state.customCalendars)

  return (
    <div className="d-flex my-3">
      <div className="d-flex align-items-center">
        <Departments className="mr-3" />
        <SortBox className="mr-3" />
        <SearchBox />
      </div>
      <div className="flex-fill d-flex align-items-center justify-content-end">
        <div className="mr-5 d-flex align-items-center">
          <H4 className="mr-3" as="span">
            View Date
          </H4>
          <DatePicker timezone={timezone} />
          <Button
            className="ml-3"
            size="sm"
            variant="outline-shortcut"
            onClick={setToToday}
          >
            Today
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <H4 className="mr-3" as="span">
            Hide Past and Future Events
          </H4>
          <Checkbox
            type="switch"
            value={hidePastFuture}
            onChange={(e) => {
              dispatch.customCalendars.updateCustomCalendarConditions({
                hidePastFuture: e.currentTarget.checked,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}
