import { setLocale } from "yup"

// Set yup default error messages
setLocale({
  string: {
    max: "${label} has a maximum limit of ${max} characters",
    required: "${label} is a required field",
  },
  number: {
    required: "${label} is a required field",
  },
  array: {
    min: "At least ${min} ${label} must be selected",
  },
})
