import { IManualScheduleJobItem, ISplitShiftItem } from "./data";
import { formatDate, parseDate } from "@app/utils"

export const removeDuplicatedJobs = (jobs: IManualScheduleJobItem[]) => {
    let seen = new Set();
    const res = jobs.filter((j) => {
        if (seen.has(j.jobid)) {
            return false;
        }
        else {
            seen.add(j.jobid);
            return true;
        }
    });
    return res;
}

export const sortSplitShift = (jobs: IManualScheduleJobItem[]) => {
    const res = jobs.map((j) => {
        if(j.splitShifts && j.splitShifts.length > 0) {
            const orderedShifts = sortSplitShiftListByStartTime(j.splitShifts);
            j.splitShifts = orderedShifts;
        }
        return j;
    });
    return res;
}

export const sortSplitShiftListByStartTime = (splitShifts: ISplitShiftItem[]) => {
    let res = [...splitShifts];
    const parseToNumber = (date: string, time?: string) =>
        parseDate(`${date} ${time}`, 'yyyy-MM-dd hh:mm aaa').valueOf()

    res.sort((a,b) => {
        let aStartTime = parseToNumber(a.edate, a.starttime)
        let bStartTime = parseToNumber(b.edate, b.starttime)
        return (aStartTime - bStartTime);
    });

    return res;
}

export const formatTime = (timeStr: string) =>
  formatDate(
    parseDate(timeStr, (f) => f.timeOnly),
    (f) => f.hourMinute12h
  )

export const getTimeStamp = (timeStr: string) =>
  parseDate(timeStr, (f) => f.timeOnly).valueOf()

export const removeDelFlag = async (split_shiftid: string) => {
    const url = `${window.location.origin}/calendar/split_shift.cgi?op=remove_del_flag&split_shiftid=${split_shiftid}`;
    fetch(url);
    window.onunload = () => {
        if(window.opener) {
            window.opener.location.reload();
        }
    };
}
