import React from "react"
import { Select, Input } from "@app/components/Form"
import { flatten, get as lodashGet } from "lodash"
import { pluralize } from "@app/utils"

// Build a yup schema for hours field in templates
function buildPeriodSchema(options: PlainObjectType = {}) {
  const { min = 1, max = 120, label = "Hours" } = options
  const transform = (v: number, origin: any) =>
    origin && String(origin).trim() ? v : null

  return (yup: any) =>
    yup
      .number()
      .integer()
      .min(min)
      .max(max)
      .nullable()
      .transform(transform)
      .label(label)
}

// Build Select options from a range based on specificed numbers
function buildRangeOptions(max: number, min: number = 1) {
  const options = []

  for (let num = min; num <= max; num++) {
    options.push({ id: num, name: num })
  }

  return options
}

// "templates" are recorded how to render the DutyHourRule form
// and table of DutyHourRule section in blockScheduling page,
// also having the Form validation rules(schemas)
export const templates = [
  {
    name: "Work",
    type: "work",
    items: [
      {
        content:
          "Selected staff levels can be scheduled ${hours_per_week} duty hours per week (average over block).",
        render: {
          hours_per_week: {
            component: Input,
            defaultProps: { placeholder: "Hours..." },
            schema: buildPeriodSchema(),
            humanize: (val: number) => `${pluralize(val, "hour")} per week`,
          },
        },
      },
      {
        content: "The maxiumum duty period is ${max_hours_per_period} hours.",
        render: {
          max_hours_per_period: {
            component: Input,
            defaultProps: { placeholder: "Hours..." },
            schema: buildPeriodSchema(),
            humanize: (val: number) =>
              `${pluralize(val, "max hour")} per period`,
          },
        },
      },
    ],
  },
  {
    name: "Rest",
    type: "rest",
    items: [
      {
        content:
          "Selected staff levels requires ${hours_between_periods} hours of rest between duty periods.",
        render: {
          hours_between_periods: {
            component: Input,
            defaultProps: { placeholder: "Hours..." },
            schema: buildPeriodSchema(),
            humanize: (val: number) =>
              `${pluralize(val, "hour")} between periods`,
          },
        },
      },
      {
        content:
          "The number of days off per week is ${day_off_per_week} (average over block).",
        render: {
          day_off_per_week: {
            component: Select,
            defaultProps: { options: buildRangeOptions(7) },
            humanize: (val: number) => `${pluralize(val, "day")} off per week`,
          },
        },
      },
    ],
  },
  {
    name: "Frequency and Spacing",
    type: "frequency_and_spacing",
    items: [
      {
        content:
          "Selected staff levels can be scheduled at most 1 overnight call assignment every ${days_for_assignment} days (average over block).",
        render: {
          days_for_assignment: {
            component: Select,
            defaultProps: { options: buildRangeOptions(30) },
            unit: [""],
            humanize: (val: number) =>
              `1 overnight call assignment every ${pluralize(val, "day")}`,
          },
        },
      },
      {
        content:
          "The minimum time between overnight call assignments is ${min_days_between_assignment} days.",
        render: {
          min_days_between_assignment: {
            component: Input,
            defaultProps: { placeholder: "Days..." },
            schema: buildPeriodSchema({ label: "Days" }),
            humanize: (val: number) =>
              `${pluralize(val, "day")} between overnight call assignments`,
          },
        },
      },
      {
        content:
          "Night Floats can take ${consecutive_calls} consecutive calls.",
        render: {
          consecutive_calls: {
            component: Select,
            defaultProps: { options: buildRangeOptions(10) },
            humanize: (val: number) =>
              `${pluralize(val, "consecutive call")} for Night Floats`,
          },
        },
      },
    ],
  },
]

// Render parts of DutyHourRule Form including metioned fields from spectific type in templates
export function renderTemplate(
  type: string,
  options: {
    control: any
    dutyHourRule?: PlainObjectType
    renderItem?: (
      item: React.ReactNode | React.ReactNode[],
      index?: number
    ) => React.ReactNode
  }
) {
  const template = templates.find((x) => x.type === type)

  if (!template) {
    return null
  }

  const { control, dutyHourRule, renderItem } = options

  return template.items.map((t, tidx) => {
    let parts: any[] = [t.content]

    Object.entries(t.render).forEach(([key, config]) => {
      const keyword = "${" + key + "}"
      parts.forEach((item, index) => {
        parts[index] = item
          .split(keyword)
          .reduce((prev: React.ReactNode[], current: string, i: number) => {
            if (!i) {
              return [current]
            } else {
              const { component, defaultProps } = config
              const fieldName = `data.${type}.${key}`
              return prev.concat(
                React.createElement(component, {
                  ...defaultProps,
                  key,
                  control,
                  className: "ml-2 mr-2",
                  name: fieldName,
                  defaultValue: lodashGet(dutyHourRule, fieldName, null),
                }),
                current
              )
            }
          }, [])
      })
      parts = flatten(parts)
    })

    return renderItem?.(parts, tidx) || parts
  })
}

// Build DutyHourRule data's yup schedma for form validation
export function ruleDataSchemaBuilder(yup: any) {
  const data: PlainObjectType = {}

  templates.forEach(({ type, items }) => {
    const attrs: PlainObjectType = {}

    items.forEach(({ render }) => {
      Object.entries(render).forEach(([key, config]) => {
        if (config.schema) {
          attrs[key] = config.schema(yup)
        }
      })
    })

    data[type] = yup.object().shape(attrs)
  })

  return yup.object().shape(data)
}

// Render parts of Table of DutyHourRule section in BlockScheduling page
export function humanizeTemplate(type: string, dutyHourRule: PlainObjectType) {
  const template = templates.find((x) => x.type === type)

  if (!template) {
    return null
  }

  const texts: React.ReactNode[] = []

  template.items.forEach((item) => {
    Object.entries(item.render).forEach(([key, config]) => {
      const name = `data.${type}.${key}`
      const value = lodashGet(dutyHourRule, name)

      if (config.humanize && value != null) {
        texts.push(config.humanize(value))
      }
    })
  })

  return texts
}
