import { createModel } from "@rematch/core"
import { RootModel } from "."
import api from "@app/services/api"

type StateType = {
  providers: ProviderBaseType[]
  jobs: JobType[]
}

export default createModel<RootModel>()({
  state: {
    providers: [],
    schedulers: [],
    jobs: [],
  } as StateType,
  reducers: {
    setGroupData(state, payload: Partial<StateType>) {
      return {
        ...state,
        ...payload,
      }
    },
    setJobsMultipleAssignmentsSettings(
      state,
      payload: MultipleAssignmentSetting[]
    ) {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          const multipleAssignmentsSettings = payload.filter(
            (setting) => setting.jobid === job.jobid
          )
          return {
            ...job,
            multipleAssignmentsSettings: multipleAssignmentsSettings,
          }
        }),
      }
    },
  },
  effects: (dispatch) => ({
    async getGroupData() {
      const providers = await api.getProviders()
      const jobs = await api.getJobs()

      dispatch.groupData.setGroupData({
        providers,
        jobs,
      })
    },
  }),
})
