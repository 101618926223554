import React, { useState } from "react"
import Button from "@app/components/Button"
import { Text } from "@app/components/Typography"
import Table from "@app/components/Table"
import CreateOrUpdateTallyForm from "./components/CreateOrUpdateTallyForm"
import api, { useRequest } from "@app/services/api"

type Props = {
  id: string
}

export default (props: Props) => {
  const { id } = props
  const [tallyFormShow, setTallyFormShow] = useState(false)
  const [currentTally, setCurrentTally] = useState<
    AnnualBlockScheduleTallyType | undefined
  >()

  const { data: annualBlockScheduleTallies } = useRequest<
    AnnualBlockScheduleTallyType[]
  >([api.getAnnualBLockScheduleTallies, id])

  const onLaunchFormModal = (tally?: AnnualBlockScheduleTallyType) => {
    setCurrentTally(tally)
    setTallyFormShow(true)
  }

  return (
    <>
      <div className="text-right mb-4">
        <Button size="sm" onClick={() => onLaunchFormModal()}>
          Add Tally
        </Button>
        <CreateOrUpdateTallyForm
          show={tallyFormShow}
          onHide={setTallyFormShow}
          annualBlockScheduleId={id}
          annualBlockScheduleTally={currentTally}
        />
      </div>
      <Table
        variant="frame"
        data={annualBlockScheduleTallies}
        loading={!annualBlockScheduleTallies}
        columns={[
          { dataKey: "name", title: "Tally Name" },
          {
            dataKey: "annual_block_schedule_rotations",
            title: "Rotations",
            render: (rotations: AnnualBlockScheduleRotationType[]) =>
              rotations?.map(({ block_schedule_rotation: rotation }) => (
                <Text key={rotation.id} prefixDotColor={rotation.color}>
                  {rotation.name}
                </Text>
              )),
          },
          {
            dataKey: "tally_targets",
            title: "Tally Targets",
            render: (tally_targets) =>
              tally_targets.map((tallyTarget: any) => (
                <Text key={tallyTarget.staff_level_id}>
                  {tallyTarget.staff_level?.name} ({tallyTarget.count})
                </Text>
              )),
          },
          {
            dataKey: "action",
            position: "right",
            render: (_, tally: any) => (
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => onLaunchFormModal(tally)}
              >
                Edit
              </Button>
            ),
          },
        ]}
        emptyMessage="No tallies have been created. Please add a tally."
      />
    </>
  )
}
