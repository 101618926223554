import React from "react"
import CloseIcon from "@material-ui/icons/Close"
import css from "./SubMenuCalendar.module.scss"

interface SubmenuProps {
  title: string
  onClose: () => void
  children: React.ReactNode
}

const SubMenuCalendar = ({ title, onClose, children }: SubmenuProps) => {
  return (
    <div className={css.submenuContainer}>
      <div className={css.submenuHeader}>
        <CloseIcon className={css.closeIcon} onClick={onClose} />
      </div>
      <div className={css.submenuTitle}>
        <h3>{title}</h3>
      </div>
      <div className={css.submenuContent}>{children}</div>
    </div>
  )
}

export default SubMenuCalendar
