export class Clinic {
  public clinic_name: string
  public clinicid: number

  constructor(args: any) {
    this.clinicid = args.clinicid
    this.clinic_name = args.clinic_name
  }

  public get clinicName() {
    return this.clinic_name
  }
}
