import React, { useMemo } from "react"
import NotesForm from "../NotesForm"
import NotesList from "../NotesList"

import { useGetNotesList } from "./hooks/useGetNotesList"
import css from "./NotesManager.module.scss"
import { Dialog } from "@app/components/Modal"
import { format, parse } from "date-fns"
import { select, useDispatch, useSelector } from "@app/models"
import api from "@app/services/api"
import * as types from "../types"
import { handleApiError } from "@app/utils"
import { getDailyNotes } from "@app/services/getDailyNotes"

interface Props {
  onCloseModal: () => void
  date: string
}

export const NotesManager = ({ onCloseModal, date }: Props) => {
  const { loading, error } = useGetNotesList()
  const { currentUser } = useSelector((state) => state.users)

  const { notes } = useSelector(select.calendarEvents.filteredCalendarData)

  const dispatch = useDispatch()

  const notesList = useMemo(
    () => getDailyNotes(notes, new Date(date)),
    [notes, date]
  )

  const onSubmitForm = async (formData: types.NotesForm) => {
    try {
      const parsedDate = parse(date.split(", ")[1], "MM/dd/yy", new Date())
      const formattedDate = format(parsedDate, "yyyy-MM-dd")

      const parsedNote = {
        note: formData.content,
        showOnCalendar: formData.showOnCalendar,
        noteDate: formattedDate,
        userid: currentUser.userid,
        groupid: currentUser.groupid,
      }

      const newNote = await api.createNote(parsedNote)

      if (newNote.noteid) {
        dispatch.calendarEvents.getNotes()
      }
    } catch (error) {
      handleApiError
    }
  }

  const updateNote = async (noteid: number, updatedNote: types.NotesForm) => {
    try {
      await api.updateNote(noteid, {
        note: updatedNote.content,
        show_on_calendar: updatedNote.showOnCalendar,
      })
      dispatch.calendarEvents.getNotes()
    } catch (error) {
      handleApiError
    }
  }

  const deleteNote = async (noteId: number) => {
    try {
      await api.deleteNote(noteId)
      dispatch.calendarEvents.getNotes()
    } catch (error) {
      handleApiError
    }
  }

  const handleDeleteNoteConfirmation = async (noteId: number) => {
    Dialog.confirm({
      title: "Delete Note",
      message: (
        <p>
          Are you sure you want to delete the Note? Action can not be undone.
        </p>
      ),
      buttons: {
        ok: {
          text: "Delete",
          variant: "danger",
          onClick: async () => {
            await deleteNote(noteId)
          },
        },
      },
    })
  }

  if (error) {
    return <div>Error</div>
  }

  return (
    <div className={css.wrapper}>
      <NotesForm onCloseModal={onCloseModal} onSubmit={onSubmitForm} />
      {loading ? <div>Loading...</div> : null}
      {notesList && !loading ? (
        <NotesList
          list={notesList}
          onUpdateNote={updateNote}
          onDeleteNote={handleDeleteNoteConfirmation}
        />
      ) : null}
    </div>
  )
}
