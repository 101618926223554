import React, { useState } from "react"
import { H4, Text } from "@app/components/Typography"
import Table from "@app/components/Table"
import Icon from "@app/components/Icon"
import Button from "@app/components/Button"
import api, { useRequest } from "@app/services/api"
import CreateOrUpdateTigerconnectSetting from "./components/CreateOrUpdateTigerconnectSetting"
import cx from "classnames"
import css from "./TigerconnectSettings.module.scss"

export default () => {
  const [modalShow, setModalShow] = useState(false)
  const [editingSetting, setEditingSetting] = useState<
    TigerconnectSettingType | undefined
  >()

  const { data } = useRequest<TigerconnectSettingType[]>([
    api.getTigerconnectSettings,
  ])

  const handleEdit = (setting: TigerconnectSettingType) => {
    setEditingSetting(setting)
    setModalShow(true)
  }

  const handleCreate = () => {
    setEditingSetting(undefined)
    setModalShow(true)
  }

  const columns = [
    { dataKey: "label", title: "Label" },
    {
      dataKey: "organization_token",
      title: "Org Token",
      render: (token: string, item: PlainObjectType) =>
        token || item.clinic_organization_token,
    },
    {
      dataKey: "tigerconnect_key",
      title: "API Key",
    },
    { dataKey: "integrationid", title: "Integration ID" },
    {
      dataKey: "id",
      render: (id: string, item: any) => (
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => handleEdit(item)}
        >
          Edit
        </Button>
      ),
    },
  ]

  return (
    <div className="bootstrap4">
      <div className={css.settingContainer}>
        <H4 bold variant="black">
          TigerConnect Settings
        </H4>
        <Table
          columns={columns}
          data={data}
          loading={!data}
          striped={false}
          className={css.settingTable}
        />
        <Text
          className={cx("d-flex align-items-center", css.addingButton)}
          onClick={handleCreate}
        >
          <Icon name="plus" />
          <span className="ml-2">Add Organization</span>
        </Text>
        <CreateOrUpdateTigerconnectSetting
          show={modalShow}
          onHide={setModalShow}
          tigerconnectSetting={editingSetting}
        />
      </div>
    </div>
  )
}
