import React, { useState, useMemo } from "react"
import Icon from "@app/components/Icon"
import Button from "@app/components/Button"
import { Text, H4 } from "@app/components/Typography"
import Loader from "@app/components/Loader"
import VirtualList from "@app/components/VirtualList"
import CreateOrUpdateRotationForm from "./components/CreateOrUpdateRotationForm"
import { SharedCalendarViewRow } from "../../../SharedCalendarView"
import RotationRow from "./components/RotationRow"
import api, { useRequest } from "@app/services/api"
import { useFilter } from "@app/utils/hooks"
import css from "./Rotations.module.scss"

const AddRotationRow = (props: {
  annualBlockScheduleId: string
  message?: React.ReactNode
}) => {
  const { annualBlockScheduleId: id, message } = props
  const [rotationFormShow, setRotationFormShow] = useState(false)

  return (
    <div className="w-100 d-flex">
      <div className={css.addRotation}>
        <CreateOrUpdateRotationForm
          annualBlockScheduleId={id}
          show={rotationFormShow}
          onHide={setRotationFormShow}
        />
        <Button
          shape="circle"
          variant="outline-secondary"
          className={css.addRotationIcon}
          onClick={() => setRotationFormShow(true)}
        >
          <Icon name="plus" />
        </Button>
        <Text variant="grey" className="ml-2">
          Add Rotation
        </Text>
      </div>
      {message && <Text className="ml-3 mt-2">{message}</Text>}
    </div>
  )
}

type Props = {
  id: string
  syncScrollOptions?: PlainObjectType
}

export default ({ id, syncScrollOptions }: Props) => {
  const { data: allAnnualRotations } = useRequest<
    AnnualBlockScheduleRotationType[]
  >([api.getAnnualBlockScheduleRotations, id])

  const { filter, filterCacheKey } = useFilter([
    "annualBlockSchedule",
    id,
    "rotations",
  ])
  const annualRotations = useMemo(
    () => filter(allAnnualRotations),
    [allAnnualRotations, filterCacheKey]
  )

  const maxContentHeight = 400

  return (
    <div className="mt-4">
      <H4 className="mb-3">Rotations</H4>
      <div className={css.rotationsWrapper}>
        {!allAnnualRotations ? (
          <div className="w-100 p-5 d-flex justify-content-center">
            <Loader />
          </div>
        ) : (
          <VirtualList
            height={maxContentHeight}
            overscan={150}
            data={annualRotations}
            computeItemKey={(_, item) => item.id}
            components={{
              Footer: () => (
                <AddRotationRow
                  annualBlockScheduleId={id}
                  message={
                    !annualRotations.length &&
                    (allAnnualRotations?.length
                      ? "All rotations are currently hidden based on your filter."
                      : "No rotations have been created. Please add a rotation.")
                  }
                />
              ),
            }}
            itemContent={(index, item, { width }: PlainObjectType) => (
              <SharedCalendarViewRow.Provider
                value={{
                  lists: annualRotations,
                  isLastItem: false,
                  index,
                  width,
                }}
              >
                <RotationRow
                  annualBlockScheduleId={id}
                  annualBlockScheduleRotation={item}
                />
              </SharedCalendarViewRow.Provider>
            )}
          />
        )}
      </div>
    </div>
  )
}
