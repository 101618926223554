import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { useSelector } from "@app/models"
import store, { useDispatch } from "@app/models"
import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card"
import { H2 } from "@app/components/Typography"
import Button from "@app/components/Button"
import JobsList from "./components/JobsList"
import { formatDate } from "@app/utils"
import { DateFormatter } from "@app/utils/constants"
import { useEnabledJobTypes } from "@app/services/jobHelper"
import fetch from "@app/utils/axios"

const JobReport = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch.users.getCurrentUserInfo()
  }, [])

  const { group: currentGroup } = useSelector(
    (state) => state.users.currentUser
  )

  const enabledJobTypes = useEnabledJobTypes()

  // Wait for CurrentUser data loading over
  if (!currentGroup) {
    return null
  }

  const exportJobType = (jobTypeId: number) => {
    const body = {
      type: "jobs_by_type",
      entityid: jobTypeId,
    }
    return fetch("/api/v2/scheduler_reports", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(body),
    })
      .then(handleReportResponse)
      .catch((error) => {
        console.error("Error @ TrainerReports#getReportResults", error)
        return error
      })
  }

  const handleReportResponse = async (response: any) => {
    if (response) {
      var newBlob = new Blob([response])
      var navigator: any = window.navigator

      if (navigator?.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      var data = window.URL.createObjectURL(newBlob)
      var link = document.createElement("a")

      link.href = data
      link.target = "_blank"
      link.download = `${formatDate(
        new Date(),
        DateFormatter.humanizedMonthOnly
      )}_JobsReport.csv`
      document.body.appendChild(link)
      link.click()
      setTimeout(function () {
        document.body.removeChild(link)
        window.URL.revokeObjectURL(data)
      }, 0)
      return
    } else {
      return Promise.reject(response.message)
    }
  }

  return (
    <BaseLayout>
      <H2 className="pl-5 mb-4">
        {currentGroup && (
          <>
            <span className="font-weight-bold">{currentGroup.name}</span>
            <span> - </span>
          </>
        )}
        <span>Jobs</span>
      </H2>
      <Card>
        <div className="w-100 p-3 d-flex justify-content-center">
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => exportJobType(0)}
          >
            Export All Jobs CSV
          </Button>
        </div>
      </Card>
      {enabledJobTypes.map((jobType) => (
        <JobsList
          key={jobType.id}
          jobTypeId={jobType.id}
          jobTypeName={jobType.name}
          exportJobType={exportJobType}
        />
      ))}
    </BaseLayout>
  )
}

export default () => (
  <Provider store={store}>
    <JobReport />
  </Provider>
)
