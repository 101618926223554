import React from "react"
import { useFormState } from "react-hook-form"
import { get as lodashGet } from "lodash"
import cx from "classnames"
import css from "./ErrorMessage.module.scss"

interface Props extends PlainObjectType {
  type?: "error" | "warning"
  message?: React.ReactNode
  control?: any
  name?: string
  className?: string
  as?: React.ElementType
  render?: (message: any, errors: any) => React.ReactNode
}

export default ((props) => {
  const {
    type = "error",
    message,
    control,
    name,
    className,
    as: Comp = "div",
    render,
    children,
    ...rest
  } = props

  let errors: PlainObjectType = {}
  let errorMessage = message

  if (control && name) {
    errors = useFormState({ control }).errors
    errorMessage = message || lodashGet(errors, `${name}.message`)
  }

  const renderMessage = render?.(errorMessage, errors) || errorMessage

  return (
    <Comp className={cx(css.positionRelative, className)} {...rest}>
      {children}
      <div className={cx("invalid-tooltip", css[`messageType-${type}`])}>
        {renderMessage}
      </div>
    </Comp>
  )
}) as React.FC<Props>
