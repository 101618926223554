// A pollyfill for IE to support `Element.closest`.
// Read More: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest

const prototype: any = Element.prototype

if (!prototype.matches) {
  prototype.matches =
    prototype.msMatchesSelector || prototype.webkitMatchesSelector
}

if (!prototype.closest) {
  prototype.closest = function (s: any) {
    var el: any = this

    do {
      if (prototype.matches.call(el, s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)

    return null
  }
}
