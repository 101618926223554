import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card"
import Button from "@app/components/Button"
import Loader from "@app/components/Loader"
import { Dialog } from "@app/components/Modal"
import { useForm, FormItem, Select } from "@app/components/Form"
import { H3, Text } from "@app/components/Typography"
import api, { useRequest, mutate } from "@app/services/api"
import { handleApiError, mutateCollection } from "@app/utils"
import { isFormStateChanged } from "@app/services/formState"

type FormFieldsType = {
  groupid: number
  block_schedule_rotation_ids: string[]
  staff_level_ids: string[]
}

export default () => {
  const { jobId, id } = useParams()
  const navigate = useNavigate()

  const { control, watch, getValues, setValue, handleSubmit } =
    useForm<FormFieldsType>({
      schema: (yup) =>
        yup.object().shape({
          groupid: yup.string().required().label("Group"),
          block_schedule_rotation_ids: yup.array().ensure().of(yup.string()),
          staff_level_ids: yup.array().ensure().of(yup.string()),
        }),
    })

  const groupId = watch("groupid")
  let groupOptions

  const { data: job } = useRequest<JobWithAllFieldsType>(
    jobId ? [api.getJob, parseInt(jobId)] : null
  )
  const { data: linkedRotation } = useRequest<JobLinkedRotationType>(
    id ? [api.getJobLinkedRotation, jobId, id] : null
  )

  const { data: groups } = useRequest<GroupType[]>([api.getGroups])
  const { data: rotations } = useRequest<BlockScheduleRotationType[]>(
    groupId ? [api.getBlockScheduleRotations, groupId] : null
  )
  const { data: staffLevels } = useRequest<StaffLevelType[]>(
    groupId ? [api.getGroupStaffLevels, groupId] : null
  )

  if (id) {
    // Edit Form
    useEffect(() => {
      linkedRotation && setValue("groupid", linkedRotation.groupid)
    }, [linkedRotation])
  } else {
    // Add Form
    const { data: linkedRotations } = useRequest<JobLinkedRotationType[]>([
      api.getJobLinkedRotations,
      jobId,
    ])

    // Watch `groupid`, reset `block_schedule_rotation_ids` and `staff_level_ids`,
    // if `groupid` changed
    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        if (name === "groupid") {
          setValue("block_schedule_rotation_ids", [])
          setValue("staff_level_ids", [])
        }
      })

      return () => subscription.unsubscribe()
    }, [watch])

    groupOptions = groups?.map(({ groupid, name }) => {
      const usedGroupIds = linkedRotations?.map((x) => x.groupid) || []
      return {
        groupid,
        name,
        disabled: usedGroupIds.includes(groupid),
      }
    })
  }

  // Redirect to LinkedRotation section of Job form
  const redirectToBack = () => navigate(`/jobs/${jobId}/edit/linked_rotations`)

  // Check if there are any changes on form state,
  // launch a notice dialog if really changed
  const onLeavePage = () => {
    const currentValues = getValues()
    let isFormChanged = false

    if (id) {
      if (linkedRotation) {
        const fields = ["block_schedule_rotation_ids", "staff_level_ids"]
        isFormChanged = isFormStateChanged(currentValues, {
          baseState: linkedRotation,
          fields,
        })
      }
    } else {
      isFormChanged = isFormStateChanged(currentValues)
    }

    if (!isFormChanged) {
      redirectToBack()
      return
    }

    Dialog.confirm({
      title: "Notice",
      message:
        "You have modified the data for the selected group. \
          Are you sure that you want to lose your unsaved changes?",
      buttons: {
        ok: { text: "Yes", onClick: redirectToBack },
        cancel: { text: "No" },
      },
    })
  }

  const onSubmit = (fields: FormFieldsType) => {
    const parsedJobId = jobId && parseInt(jobId)

    if (!parsedJobId) {
      return
    }

    const handler = id
      ? api.updateJobLinkedRotation(parsedJobId, id, fields)
      : api.createJobLinkedRotation(parsedJobId, fields)

    return handler.then((res) => {
      mutate(
        [api.getJobLinkedRotations, parsedJobId],
        (data?: JobLinkedRotationType[]) => mutateCollection(data || [], res),
        false
      )
      redirectToBack()
    }, handleApiError)
  }

  const isDataLoaded = id
    ? [job, linkedRotation, groups].every(Boolean)
    : [job, groups].every(Boolean)

  return (
    <BaseLayout>
      <Card
        title={
          <div>
            <H3 bold>
              Manage Linked Rotations
              {isDataLoaded ? (
                <H3 bold="medium" as="span">
                  {` - ${job?.name}`}
                </H3>
              ) : (
                <Loader className="ml-3" />
              )}
            </H3>
            <Text variant="grey" className="font-weight-normal">
              Select the asociated rotations and staff levels from each group
              for this job
            </Text>
          </div>
        }
      >
        <div className="mt-3">
          <FormItem label="Group">
            {id ? (
              <Text variant="black">{linkedRotation?.group.name}</Text>
            ) : (
              <Select
                options={groupOptions || []}
                valueKey="groupid"
                name="groupid"
                control={control}
                defaultValue={linkedRotation?.groupid}
              />
            )}
          </FormItem>
          <FormItem
            label="Rotations"
            name="block_schedule_rotation_ids"
            control={control}
            defaultValue={linkedRotation?.block_schedule_rotation_ids}
          >
            <Select
              multiple
              inline
              options={rotations || []}
              loading={groupId ? !rotations : false}
            />
          </FormItem>
          <FormItem
            label="Staff Levels"
            name="staff_level_ids"
            control={control}
            defaultValue={linkedRotation?.staff_level_ids}
          >
            <Select
              multiple
              inline
              options={staffLevels || []}
              loading={groupId ? !staffLevels : false}
            />
          </FormItem>
          <div className="mt-3 mb-1 text-right">
            <Button
              variant="outline-primary"
              className="text-uppercase"
              onClick={onLeavePage}
            >
              Back
            </Button>
            <Button
              className="text-uppercase ml-3"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </div>
        </div>
      </Card>
    </BaseLayout>
  )
}
