import React from "react"
import ToggleSwitch from "@app/components/Toggle/Toggle"
import css from "./FilterMissingAssignments.module.scss"
import { useDispatch, useSelector } from "@app/models"

export const FilterMissingAssignments = () => {
  const { isMissingAssignmentsFilterActive } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const dispatch = useDispatch()

  const toggleSwitch = () => {
    dispatch.calendarEvents.setMissingAssignmentsFilter(
      !isMissingAssignmentsFilterActive
    )
  }

  return (
    <div className={css.container}>
      <div className={css.headerContainer}>
        <h2 className={css.header}>Filter Missing Assignments</h2>
        <div>
          <ToggleSwitch
            value={isMissingAssignmentsFilterActive}
            onToggle={toggleSwitch}
          />
        </div>
      </div>
      <div>
        <p className={css.description}>
          Enable this filter to only display jobs with the "Show Empty
          Assignments" setting turned on.
        </p>
        <p className={css.description}>
          The Calendar view will be limited to only display missing assignments.
        </p>
      </div>
    </div>
  )
}
