import React, { useState } from "react"
import BsButton from "react-bootstrap/Button"
import { useHref, useNavigate, Link } from "react-router-dom"
import { useFormState } from "@app/components/Form"
import cx from "classnames"
import css from "./Button.module.scss"

type Props = {
  variant?: any
  className?: string
  type?: any
  shape?: "round" | "pill" | "circle"
  onClick?: Function
  [index: string]: any
}

const Button: React.FC<Props> = (props) => {
  const {
    variant = "primary",
    type = "button",
    shape = "round",
    className,
    disabled,
    control,
    onClick,
    children,
    ...others
  } = props

  const [smartLoading, setSmartLoading] = onClick
    ? useState(false)
    : [false, () => {}]
  const { isSubmitting } = control
    ? useFormState({ control })
    : ({} as PlainObjectType)

  const onButtonClick = (...args: any[]) => {
    const result = onClick?.(...args)

    if (result instanceof Promise) {
      // Disable the button until promise resolved
      setSmartLoading(true)
      const quitLoadingStatus = () => setSmartLoading(false)
      result.finally(quitLoadingStatus)
    }

    return result
  }

  const buttonClassName = cx(
    "react-button",
    `btn-shape-${shape}`,
    css.button,
    className
  )

  return (
    <BsButton
      className={buttonClassName}
      variant={variant}
      type={type}
      disabled={disabled || isSubmitting || smartLoading}
      onClick={onButtonClick}
      {...others}
    >
      {children}
    </BsButton>
  )
}

export default ((props) => {
  const { to, onClick, ...rest } = props

  if (!to) {
    return <Button onClick={onClick} {...props} />
  }

  const navigate = useNavigate()
  const href = useHref(to)

  const handleClick = (event: any) => {
    event.preventDefault()
    navigate(to)
    onClick?.(event)
  }

  return <Button href={href} onClick={handleClick} {...rest} />
}) as React.FC<Props & Partial<React.ComponentProps<typeof Link>>>
