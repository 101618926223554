import React, { useState, useMemo } from "react"
import { useSelector, useDispatch } from "@app/models"
import Modal, { Dialog } from "@app/components/Modal"
import Dropdown from "@app/components/Dropdown"
import Icon from "@app/components/Icon"
import { H4, Text } from "@app/components/Typography"
import { Col, Row } from "@app/components/Layout"
import { FormItem, Select, Radio, useForm } from "@app/components/Form"
import { formatBlocksDate, handleApiError } from "@app/utils"
import { TallyCountModes } from "@app/services/tallyCalculation"
import { differenceInDays } from "date-fns"
import api from "@app/services/api"
import CreateOrUpdateBlockSetForm from "./CreateOrUpdateBlockSetForm"
import css from "./BlockSetInfo.module.scss"

type Props = BlockSetType & {
  blockHeight?: number
}

type FormFieldsType = {
  blockId: string
  direction: string
}

export default ((props) => {
  const {
    id: blockSetId,
    name: blockSetName,
    annual_block_schedule_id: annualBlockScheduleId,
    block_layout: blockLayout,
    count_blocks: countBlocks,
    staff_levels: staffLevels,
    blockHeight,
  } = props

  const [blockSetFormModalShow, setBlockSetFormModalShow] = useState(false)
  const [addBlockFormShow, setAddBlockFormShow] = useState(false)

  const dispatch = useDispatch()
  const {
    blocks,
    blockConfig: { minDays },
  } = useSelector(({ blockSets }) => ({
    blocks: blockSets.computedBlocksData[blockSetId] || [],
    blockConfig: blockSets.blockConfig,
  }))

  const {
    control,
    handleModalSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        blockId: yup
          .string()
          .required("You need to pick a block before adding"),
      }),
  })

  const onSubmit = (fields: FormFieldsType, closeModal: Function) => {
    return dispatch.blockSets.addBlock({ blockSetId, ...fields }).then(() => {
      closeModal()
    })
  }

  const onModalClose = (status: boolean) => {
    setAddBlockFormShow(status)
    resetForm()
  }

  const onBlockSetDelete = () => {
    Dialog.confirm({
      title: "Delete Block Set",
      message: (
        <p>
          Are you sure you want to delete the block set? Action cannot be
          undone.
        </p>
      ),
      buttons: {
        ok: {
          text: "Delete",
          variant: "danger",
          onClick() {
            return api.deleteBlockSet(blockSetId).then(() => {
              dispatch.blockSets.deleteBlockSet({ blockSetId })
            }, handleApiError)
          },
        },
      },
    })
  }

  const directionOptions = [
    { id: "left", name: "Before" },
    { id: "right", name: "After" },
  ]
  const blocksOptions = useMemo(() => {
    const options: { id: string; name: string }[] = []

    blocks
      .filter((block) => !block.isBuffer)
      .forEach((block, index) => {
        const blockDays = differenceInDays(block.endDate, block.startDate) + 1

        // Block will be splited as 2 blocks, so it should large than 2 weeks
        if (blockDays >= minDays * 2) {
          options.push({
            id: block.id,
            name: `Block ${index + 1} (${formatBlocksDate(
              block.startDate
            )} - ${formatBlocksDate(block.endDate)})`,
          })
        }
      })

    return options
  }, [addBlockFormShow, blocks?.length])

  return (
    <>
      <Modal
        disableBodyScroll
        title="Add Block"
        show={addBlockFormShow}
        onHide={onModalClose}
        buttons={[
          { text: "Cancel", variant: "outline-primary" },
          {
            control,
            text: "Add",
            onClick: handleModalSubmit(onSubmit),
          },
        ]}
      >
        <div className="p-5">
          <FormItem label="Add block" className="d-block">
            <Radio.Group
              name="direction"
              control={control}
              options={directionOptions}
              defaultValue="left"
            />
            <Select
              name="blockId"
              placeholder="Select a block..."
              control={control}
              options={blocksOptions}
            />
          </FormItem>
        </div>
      </Modal>
      <div className={css.blockSet} style={{ height: blockHeight }}>
        <Row>
          <Col>
            <H4 bold>{blockSetName}</H4>
          </Col>
          <Col md="auto">
            <CreateOrUpdateBlockSetForm
              id={annualBlockScheduleId}
              blockSet={props}
              show={blockSetFormModalShow}
              onHide={setBlockSetFormModalShow}
            />
            <Dropdown
              overlay={
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setBlockSetFormModalShow(true)}>
                    Edit Block Set
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setAddBlockFormShow(true)}>
                    Add Block
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onBlockSetDelete}>
                    Delete Block Set
                  </Dropdown.Item>
                </Dropdown.Menu>
              }
            >
              <Icon
                hoverable
                name="three-dots"
                className={css.moreDropdownIcon}
              />
            </Dropdown>
          </Col>
        </Row>
        <Text>
          {blockLayout?.name}
          {`(${blocks.filter((x) => !x.isBuffer).length})`}
        </Text>
        <Text>{TallyCountModes.find((x) => x.id === countBlocks)?.name}</Text>
        <Text variant="danger">
          {staffLevels.map((sl) => sl.name).join(", ")}
        </Text>
      </div>
    </>
  )
}) as React.FC<Props>
