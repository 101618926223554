import React, { Fragment, useEffect, useMemo, useState } from "react"
import { FormItem, Select } from "@app/components/Form"
import DateFnsUtils from "@date-io/date-fns"
import css from "../AssignmentContentModal.module.scss"
import { IconButton, makeStyles } from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers"
import { Form, Col } from "react-bootstrap"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import ClearIcon from "@material-ui/icons/Clear"
import { SplitShift } from "@app/containers/spa/WhiteboardCalendar/data"
import ProvidersCustomSelectComponent from "../../ProviderCustomSelect/ProvidersCustomSelect"
import { formatDate, parseDate } from "@app/utils"
import { useSelector } from "@app/models"
import { useJobTimes } from "../../CalendarGridView/hooks/useJobTime"

interface SplitShiftInfo extends SplitShift {
  disabled: boolean
}

type PropsType = {
  splitShifts: SplitShift[]
  assignment?: AssignmentBaseType
  setSelectedSplitShifts: (splitShifts: SplitShift[]) => void
  jobid: number
  edate: string
}

export default ({
  splitShifts,
  assignment,
  jobid,
  edate,
  setSelectedSplitShifts,
}: PropsType) => {
  const { jobs } = useSelector((state) => state.groupData)
  const [newSplitShifts, setNewSplitShifts] = useState<SplitShift[]>([])
  const { jobStartTime, jobEndTime } = useJobTimes(jobid, edate)

  const job = useMemo(() => {
    if (assignment) {
      return assignment.job
    } else {
      return jobs.find((job) => job.jobid === jobid)
    }
  }, [assignment, jobs, jobid])

  useEffect(() => {
    setSelectedSplitShifts(newSplitShifts)
  }, [newSplitShifts])

  if (!job) return null

  useEffect(() => {
    let splitShiftsCopy = [] as SplitShift[]

    if (assignment) {
      if (!splitShifts.length) {
        splitShiftsCopy.push({
          providerid: assignment.providerid,
          tally_credit: 1,
          starttime: jobStartTime,
          endtime: jobEndTime,
          edate: assignment.edate,
        })
      } else {
        splitShiftsCopy = [...splitShifts].sort((aSplit, bSplit) =>
          `${aSplit.edate}${aSplit.starttime}`.localeCompare(
            `${bSplit.edate}${bSplit.starttime}`
          )
        )
      }
      splitShiftsCopy = splitShiftsCopy.map((splitShift) => {
        return {
          ...splitShift,
          disabled: false,
        }
      })
    } else {
      splitShiftsCopy.push({
        starttime: jobStartTime,
        endtime: jobEndTime,
        disabled: false,
      } as SplitShiftInfo)
    }

    while (splitShiftsCopy.length < 4) {
      splitShiftsCopy.push({ disabled: true } as SplitShiftInfo)
    }
    setNewSplitShifts(splitShiftsCopy as SplitShiftInfo[])
  }, [splitShifts])

  const clearSplitShiftRow = (index: number) => {
    setNewSplitShifts([
      ...newSplitShifts.slice(0, index),
      { disabled: true } as SplitShiftInfo,
      ...newSplitShifts.slice(index + 1),
    ])
  }

  const changeSplitShiftTimes = (
    index: number,
    starttime: string,
    endtime: string
  ) => {
    const splitShiftsCopy = [...newSplitShifts]

    splitShiftsCopy[index].starttime = starttime
    splitShiftsCopy[index].endtime = endtime

    if (
      index < 4 &&
      splitShiftsCopy[index + 1] &&
      job &&
      endtime !== jobEndTime
    ) {
      splitShiftsCopy[index + 1].starttime = endtime
      splitShiftsCopy[index + 1].endtime ||= jobEndTime
      splitShiftsCopy[index + 1].disabled = false
    }

    if (index > 0 && splitShiftsCopy[index - 1]) {
      splitShiftsCopy[index - 1].endtime = starttime
    }

    setNewSplitShifts(splitShiftsCopy)
  }

  const changeSplitShiftProvider = (index: number, providerid: number) => {
    const splitShiftsCopy = [...newSplitShifts]

    splitShiftsCopy[index].providerid = providerid

    setNewSplitShifts(splitShiftsCopy)
  }

  const changeSplitShiftTallyCredit = (index: number, tallyCredit: number) => {
    const splitShiftsCopy = [...newSplitShifts]

    splitShiftsCopy[index].tally_credit = tallyCredit

    setNewSplitShifts(splitShiftsCopy)
  }

  const formatTime = (date: any) => {
    if (date) {
      const time = formatDate(date, (f) => f.timeOnly)
      return time.substring(0, 6) + "00"
    }
    return ""
  }

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        width: 100,
        height: 24,
        borderRadius: 4,
        position: "relative",
        backgroundColor: "#fff",
        border: "1px solid #ced4da",
        paddingRight: 0,
      },
      "&:focus": {
        borderColor: "#ced4da",
      },
      "& .MuiIconButton-root": {
        padding: "6px",
      },
      "& .MuiOutlinedInput-input": {
        height: "auto !important",
        border: 0,
        fontSize: 12,
        padding: "8px 0 8px 10px",
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        width: 100,
      },
      "& .MuiInputAdornment-positionEnd": {
        marginLeft: "0 !important",
      },
      "& .MuiIconButton-label": {
        justifyContent: "center",
      },
    },
  })

  const classes = useStyles()

  return (
    <Form style={{ padding: "20px" }}>
      {newSplitShifts.map((splitShift, index) => (
        <div className="row" key={index}>
          <Form.Group
            as={Col}
            controlId={`providerSelect-${index}`}
            className="col-md-4"
          >
            <Form.Label className={css.labelPopup}>Provider(s)</Form.Label>
            <FormItem
              className={css.formItem}
              label=""
              layout={[0, 12]}
              name={`view-${index}`}
            >
              <ProvidersCustomSelectComponent
                jobid={String(job?.jobid)}
                edate={edate || ""}
                onChange={(providerid) =>
                  changeSplitShiftProvider(index, Number(providerid))
                }
                defaultProviderId={String(splitShift.providerid)}
                disabled={splitShift.disabled}
              />
            </FormItem>
          </Form.Group>

          <Form.Group
            as={Col}
            controlId={`shiftTimes-${index}`}
            className="col-md-4"
          >
            <Fragment>
              <Form.Label className={css.labelPopup}>Shift Times</Form.Label>
              <div className={css.pickerTimeContainer}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    onChange={(date) =>
                      changeSplitShiftTimes(
                        index,
                        formatTime(date),
                        splitShift.endtime
                      )
                    }
                    value={
                      splitShift.starttime
                        ? formatDate(
                            parseDate(splitShift.starttime, (f) => f.timeOnly),
                            "yyyy-MM-dd HH:mm:ss"
                          )
                        : null
                    }
                    inputVariant="outlined"
                    className={classes.root}
                    variant="inline"
                    mask="__:__ _M"
                    keyboardIcon={<AccessTimeIcon />}
                    disabled={splitShift.disabled}
                  />
                  <span>to</span>
                  <KeyboardTimePicker
                    onChange={(date) =>
                      changeSplitShiftTimes(
                        index,
                        splitShift.starttime,
                        formatTime(date)
                      )
                    }
                    value={
                      splitShift.endtime
                        ? formatDate(
                            parseDate(splitShift.endtime, (f) => f.timeOnly),
                            "yyyy-MM-dd HH:mm:ss"
                          )
                        : null
                    }
                    inputVariant="outlined"
                    className={classes.root}
                    variant="inline"
                    mask="__:__ _M"
                    keyboardIcon={<AccessTimeIcon />}
                    disabled={splitShift.disabled}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Fragment>
          </Form.Group>
          <Form.Group
            as={Col}
            controlId={`tallys-${index}`}
            className="col-md-4"
            style={{ paddingLeft: "40px" }}
          >
            <Form.Label className={css.labelTally}>Tallys</Form.Label>
            <div
              style={{ display: "flex", height: "35px", alignItems: "center" }}
            >
              <FormItem
                className="mb-0"
                label=""
                layout={[0, 12]}
                name={`view-${index}`}
              >
                <Select
                  className={css.select}
                  onChange={(val) => changeSplitShiftTallyCredit(index, val)}
                  disabled={splitShift.disabled}
                  value={splitShift.tally_credit}
                  clearable={false}
                  options={[0, 0.25, 0.5, 0.75, 1].map((val) => {
                    return {
                      id: val,
                      name: val,
                    }
                  })}
                />
              </FormItem>
              <FormItem label="" className="mb-0">
                <IconButton
                  component="span"
                  onClick={() => clearSplitShiftRow(index)}
                  style={{
                    width: 22,
                    height: 22,
                    padding: 0,
                    marginLeft: 10,
                    backgroundColor: "#FFFFFF",
                    color: "#3080DF",
                    border: "1px solid #CDD9DE",
                    visibility: splitShift.disabled ? "hidden" : "visible",
                  }}
                >
                  {" "}
                  <ClearIcon fontSize="small" style={{ color: "#A2B0BC" }} />
                </IconButton>
              </FormItem>
            </div>
          </Form.Group>
        </div>
      ))}
    </Form>
  )
}
