import React from "react"
import BsRow from "react-bootstrap/Row"
import BsCol from "react-bootstrap/Col"
import cx from "classnames"
import css from "./Layout.module.scss"

type ContainerProps = {
  className?: string
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl" | "fluid"
}

export const Container: React.FC<ContainerProps> = (props) => {
  const { size, className, children } = props

  return (
    <div className={cx(`container${size ? `-${size}` : ""}`, className)}>
      {children}
    </div>
  )
}

export const ContainerFluid = (props: any) => {
  return (
    <div className={cx(css.containerFluid, props.className)}>
      {props.children}
    </div>
  )
}

export const Row = React.forwardRef<
  any,
  React.ComponentProps<typeof BsRow> & { noMargin?: boolean }
>((props, ref) => {
  const { className, noMargin, ...others } = props
  return (
    <BsRow
      ref={ref}
      className={cx(className, { [css.noMargin]: noMargin })}
      {...others}
    />
  )
})

export const Col = React.forwardRef<
  any,
  React.ComponentProps<typeof BsCol> & { noPadding?: boolean }
>((props, ref) => {
  const { className, noPadding, ...others } = props
  return (
    <BsCol
      ref={ref}
      className={cx(className, { [css.noPadding]: noPadding })}
      {...others}
    />
  )
})

export default {
  Container,
  ContainerFluid,
  Row,
  Col,
}
