import React, { useState, useContext } from "react"
import Icon from "@app/components/Icon"
import { useControl, UseControlProps } from "./useForm"
import cx from "classnames"
import css from "./Checkbox.module.scss"

interface CheckboxGroupContextProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: any
  name?: string
  className?: string
}

interface CheckboxGroupProps extends UseControlProps {
  options: PlainObjectType[]
  valueKey?: keyof CheckboxGroupProps["options"][number]
  labelKey?: keyof CheckboxGroupProps["options"][number]
  className?: string
  checkboxClassName?: string
}

interface CheckboxProps extends UseControlProps {
  label?: string
  className?: string
  value?: boolean
  checkboxValue?: any
  type?: "checkbox" | "switch"
}

const CheckboxGroupContext =
  React.createContext<CheckboxGroupContextProps | null>(null)

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const {
    options,
    valueKey = "id",
    labelKey = "name",
    error,
    name,
    defaultValue,
    value,
    onChange,
    className,
    checkboxClassName,
  } = useControl(props, { nativeValue: "" })

  const [innerValue, setInnerValue] = useState(defaultValue || [])

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    const newValue =
      currentValue.includes(val) || currentValue.includes(String(val))
        ? currentValue.filter((x: any) => x !== val)
        : [...currentValue, val]

    setInnerValue(newValue)
    onChange?.(newValue)
  }

  const currentValue = value ?? innerValue
  const errorClassNames = cx({ "bootstrap4 is-invalid": error })

  return (
    <CheckboxGroupContext.Provider
      value={{
        value: currentValue,
        onChange: onCheckboxChange,
        name: name,
        className: checkboxClassName,
      }}
    >
      <div className={cx(css.CheckboxGroupContainer, className)}>
        <div className={cx(css.CheckboxesList, errorClassNames)}>
          {options?.map((option) => (
            <Checkbox
              key={option[valueKey]}
              label={option[labelKey]}
              value={option[valueKey]}
            />
          ))}
        </div>
        <div className="invalid-tooltip">{error?.message}</div>
      </div>
    </CheckboxGroupContext.Provider>
  )
}

const Checkbox: React.FC<CheckboxProps> & { Group: typeof CheckboxGroup } = (
  props
) => {
  const checkboxProps = { ...props }
  const context = useContext(CheckboxGroupContext)

  if (context) {
    checkboxProps.name = context.name
    checkboxProps.onChange = context.onChange
    checkboxProps.className = context.className
    checkboxProps.checkboxValue = props.value
    checkboxProps.value =
      context.value?.includes(props.value) ||
      context.value?.includes(String(props.value))
  }

  const {
    label,
    value: checked,
    defaultValue: defaultChecked,
    checkboxValue,
    className,
    type = "checkbox",
    children,
    ...others
  } = useControl(checkboxProps, { nativeValue: false })

  const content = label || children

  return (
    <div className={cx(css.checkboxContainer, className)}>
      <input
        type="checkbox"
        className={css.checkbox}
        checked={checked}
        defaultChecked={defaultChecked}
        value={checkboxValue}
        {...others}
      />
      <div className={css.checkboxState}>
        {type === "switch" ? (
          <div className={css.switchToggle} />
        ) : (
          <div className={css.checkboxIcon}>
            <Icon name="check" />
          </div>
        )}
        {content && <label className={css.checkboxLabel}>{content}</label>}
      </div>
    </div>
  )
}

Checkbox.Group = CheckboxGroup

export default Checkbox
