import { Provider } from "@app/containers/spa/WhiteboardCalendar/data"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"

export const getIsSingleAssignmentHighlighted = (
  assignment: GroupedAssignment,
  highlightProviders: Provider[] | undefined
) => {
  return highlightProviders?.some(
    (highlightedProvider) =>
      assignment.provider?.providerid === highlightedProvider.providerid
  )
}
