import React from "react"
import { AssignmentDot } from "../../AssignmentDot"
import { useSelector } from "@app/models"
import css from "./AssignmentDetails.module.scss"
import { formatDateToStartEnd } from "@app/utils"

interface AssignmentDetailsProps {
  isDraft: boolean
  providerColor: string | null | undefined
  providerName: string
  isMultiProvider?: boolean
  isSplitShift?: boolean
  providerJob?: JobType
}

export const AssignmentDetails = ({
  isDraft,
  providerColor,
  providerName,
  isMultiProvider,
  isSplitShift,
  providerJob,
}: AssignmentDetailsProps) => {
  const {
    rulesConfig: { display_job_times: showJobTimesInCalendar },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  return (
    <div>
      <div className={css.textContainer}>
        <AssignmentDot isDraft={isDraft} customColor={providerColor} />
        <span>{providerName}</span>
      </div>
      <div className={css.jobTimeWrapper}>
        {(!isMultiProvider || isSplitShift) &&
          showJobTimesInCalendar &&
          providerJob &&
          providerJob?.starttime &&
          providerJob?.endtime && (
            <span className={css.jobDateTime}>
              {formatDateToStartEnd(providerJob.starttime, providerJob.endtime)}
            </span>
          )}
      </div>
    </div>
  )
}
