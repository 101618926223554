import React, { useState } from "react"
import Modal from "@app/components/Modal"
import { Select, FormItem } from "@app/components/Form"
import api, { useRequest } from "@app/services/api"
import { DefaultTigerconnectSettingId } from "@app/utils/constants"

type TCTagType = {
  name: string
  token: string
}

type TCRoleType = {
  department: string
  id: string
  role: string
  tagcolor: string
  tagname: string
  text: string
  orgid: string
}

type JobRoleType = JobType["tigerconnect_roles"][number]

type searchRoleProps = {
  show: boolean
  onHide: () => void
  editingRole?: JobRoleType | null
  tigerconnectSettings?: TigerconnectSettingType[]
  roles: JobRoleType[]
  setRoles: (roles: JobRoleType[]) => void
}

export default (props: searchRoleProps) => {
  const { show, onHide, editingRole, tigerconnectSettings, roles, setRoles } =
    props

  const [tigerconnectSettingId, setTigerconnectSettingId] = useState<string>(
    editingRole?.tigerconnect_setting_id || DefaultTigerconnectSettingId
  )

  // No need to pass tigerconnectSettingId to API when it's default tigerconnectSettingId
  const realTigerconnectSettingId =
    tigerconnectSettingId !== DefaultTigerconnectSettingId
      ? tigerconnectSettingId
      : undefined

  const [tagToken, setTagToken] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedTCRole, setSelectedTCRole] = useState<TCRoleType | undefined>()

  const isReadyToSearch = searchTerm.length >= 2
  const { data: tags } = useRequest<{ results?: TCTagType[] }>([
    api.getRoleTags,
    realTigerconnectSettingId,
  ])
  const { data: tcRoles } = useRequest<{ results?: TCRoleType[] }>(
    isReadyToSearch
      ? [api.getRoles, tagToken, searchTerm, realTigerconnectSettingId]
      : null
  )

  const handleModalHide = () => {
    // Reset form fields or states
    setTigerconnectSettingId(DefaultTigerconnectSettingId)
    setTagToken("")
    setSearchTerm("")
    setSelectedTCRole(undefined)

    onHide()
  }

  const handleRolePick = (closeModal: Function) => {
    if (selectedTCRole && tigerconnectSettings) {
      const { role, tagname, tagcolor, id, orgid } = selectedTCRole
      const color = tagcolor.replace("0x", "#")

      const tigerconnectSetting = tigerconnectSettings.find(
        (x) => x.id === tigerconnectSettingId
      ) as TigerconnectSettingType

      const newRole = {
        id: editingRole?.id,
        entity_name: role,
        entity_tag_name: tagname,
        entity_tag_color: color,
        entity_token: id,
        tigerconnect_setting_id: tigerconnectSettingId,
        tigerconnect_setting_label: tigerconnectSetting.label,
        tigerconnect_setting_organization_token: orgid,
      }

      // Append the newRole when no editingRole,
      // or replace role to be newRole when there is an editingRole
      const newRoles = !editingRole
        ? [...roles, newRole]
        : roles.map((role) =>
            role.entity_token === editingRole.entity_token ? newRole : role
          )

      setRoles(newRoles)
    }

    // Hide Modal
    closeModal()
  }

  const handleRoleInput = (text: string) => {
    setSearchTerm(text)
    setSelectedTCRole(undefined)
  }

  const isMultipleTigerconnectSettings =
    tigerconnectSettings && tigerconnectSettings.length > 1

  const orgsOptions =
    tigerconnectSettings?.map((item) => {
      const orgToken = item.organization_token || item.clinic_organization_token
      return { name: `${item.label} (${orgToken})`, id: item.id }
    }) || []

  const tagsOptions = [{ name: "All Roles", token: "" }].concat(
    tags?.results || []
  )

  // Prevent using same role tokens for a job
  const usedRoleTokens = roles
    .map((role) => role.entity_token)
    .filter((token) => token !== editingRole?.entity_token)

  const tcRolesOptions =
    tcRoles?.results?.map((role) => ({
      name: role.text,
      value: role,
      disabled: usedRoleTokens.includes(role.id),
    })) || []

  return (
    <Modal
      disableBodyScroll
      title="Search Roles"
      show={show}
      onHide={handleModalHide}
      buttons={[
        { text: "Cancel", variant: "outline-primary" },
        {
          text: "Set Role",
          variant: "primary",
          disabled: !selectedTCRole,
          onClick: handleRolePick,
        },
      ]}
    >
      {isMultipleTigerconnectSettings && (
        <FormItem layout="vertical" label="Select organization">
          <Select
            options={orgsOptions}
            loading={!tigerconnectSettings}
            value={tigerconnectSettingId}
            onChange={(id) => setTigerconnectSettingId(id)}
          />
        </FormItem>
      )}
      <FormItem layout="vertical" label="Select a tag to filter">
        <Select
          options={tagsOptions}
          loading={!tags}
          valueKey="token"
          value={tagToken}
          onChange={(token) => setTagToken(token)}
        />
      </FormItem>
      <FormItem layout="vertical" label="Search for roles">
        <Select
          searchable
          valueKey="value"
          value={selectedTCRole}
          options={tcRolesOptions}
          onInputChange={handleRoleInput}
          onChange={(role) => role && setSelectedTCRole(role)}
          placeholder="Please enter two or more characters to search for roles"
          loading={isReadyToSearch && !tcRoles}
        />
      </FormItem>
    </Modal>
  )
}
