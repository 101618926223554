import React, { useContext, useMemo } from "react"
import Avatar from "@app/components/Avatar"
import Loader from "@app/components/Loader"
import { H5, Text } from "@app/components/Typography"
import Icon from "@app/components/Icon"
import type { JobRowType } from "@app/services/mdvShiftCalculation"
import api, { useRequest } from "@app/services/api"
import ProtocolViewer from "./ProtocolViewer"
import { OnViewNoteUserLoginContext } from "../../../context/OnViewNoteUserLoginContext"

import cx from "classnames"
import css from "./JobRow.module.scss"

type Props = {
  jobRow?: JobRowType
  customCalendarKey: string
  setPickedProviderId: (providerId: number) => any
  setPickedSendSecureMessageSource: (source: {
    type: "provider" | "job"
    id: number
  }) => any
}

export default (props: Props) => {
  const {
    jobRow,
    customCalendarKey,
    setPickedProviderId,
    setPickedSendSecureMessageSource,
  } = props

  const { printView } = useContext(OnViewNoteUserLoginContext)

  const currentProviderId = jobRow && jobRow.shift.current.provider.providerid
  let { data: providerDetails } = useRequest<CustomCalendarProviderType>(
    currentProviderId
      ? [api.getCustomCalendarProvider, customCalendarKey, currentProviderId]
      : null
  )

  const jobInfoBgClass = useMemo(() => {
    if (!jobRow) {
      return ""
    }

    return {
      past: "isPast",
      current: "isCurrent",
      future: "isFuture",
    }[jobRow.pastFutureType]
  }, [jobRow?.pastFutureType])

  // Show loader when jobRow is undefined
  if (!jobRow) {
    return <Loader animation="glow" className={css.jobRowLodaer} />
  }

  return (
    <div key={jobRow.id} className={css.jobRow}>
      <div className={cx(css.eventInfo, css[jobInfoBgClass])}>
        <div className={css.currentEvent}>
          <div className="d-flex align-items-center">
            <Avatar size={35} src={jobRow.shift.current.provider.avatar} />
            <H5
              variant="inherit"
              bold="medium"
              className={cx("ml-3", css.hoverLink)}
              onClick={() =>
                setPickedProviderId(jobRow.shift.current.provider.providerid)
              }
            >
              {jobRow.shift.current.provider.fullname}
            </H5>
          </div>
          <div>
            {!jobRow.shift.current.is_start_date_current_date && (
              <div className={cx(css.dateShortName, "text-left")}>
                ( {jobRow.shift.current.start_date_text} )
              </div>
            )}
            <Text className={css.dateRange}>
              {jobRow.shift.current.starttime_text}
              <Icon resetLineHeight name="dot" />
              {jobRow.shift.current.endtime_text}
            </Text>
            {!jobRow.shift.current.is_end_date_current_date && (
              <div className={cx(css.dateShortName, "text-right")}>
                ( {jobRow.shift.current.end_date_text} )
              </div>
            )}
          </div>
        </div>
        <div className={css.protocolContainer}>
          <div className={css.protocolContent}>
            <Text bold variant="inherit">
              Protocol
            </Text>
            <div>
              {!providerDetails ? (
                <div>Loading...</div>
              ) : (
                <ProtocolViewer
                  readMore={!printView}
                  className={css.protocolText}
                  protocol={providerDetails?.provider_protocol?.protocol}
                  providerId={currentProviderId}
                  canSendMessage={providerDetails.can_send_secure_message}
                  onSendMessageLinkClick={() =>
                    currentProviderId &&
                    setPickedSendSecureMessageSource({
                      type: "provider",
                      id: currentProviderId,
                    })
                  }
                  onClick={() =>
                    providerDetails &&
                    setPickedProviderId(providerDetails.providerid)
                  }
                />
              )}
            </div>
          </div>
          {(jobRow.shift.previous || jobRow.shift.next) && (
            <div className={css.nextAndPassedEvents}>
              <Text bold variant="inherit">
                Update:
              </Text>
              {jobRow.shift.previous && (
                <div>
                  Previously:{" "}
                  <Text
                    bold
                    className={css.hoverUnderline}
                    variant="inherit"
                    as="span"
                    onClick={() =>
                      jobRow.shift.previous &&
                      setPickedProviderId(
                        jobRow.shift.previous.provider.providerid
                      )
                    }
                  >
                    {jobRow.shift.previous.provider.fullname}
                  </Text>{" "}
                  {jobRow.shift.previous.starttime_text}
                  <Icon resetLineHeight name="dot" />
                  {jobRow.shift.previous.endtime_text}
                </div>
              )}
              {jobRow.shift.next && (
                <div>
                  Upcoming:{" "}
                  <Text
                    bold
                    className={css.hoverUnderline}
                    variant="inherit"
                    as="span"
                    onClick={() =>
                      jobRow.shift.next &&
                      setPickedProviderId(jobRow.shift.next.provider.providerid)
                    }
                  >
                    {jobRow.shift.next.provider.fullname}
                  </Text>{" "}
                  {jobRow.shift.next.starttime_text}
                  <Icon resetLineHeight name="dot" />
                  {jobRow.shift.next.endtime_text}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
