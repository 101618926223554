import React from "react"
import { useDispatch } from "@app/models"
import Modal from "@app/components/Modal"
import { useForm, Input, FormItem } from "@app/components/Form"
import Button from "@app/components/Button"
import { handleApiError } from "@app/utils"
import css from "./MDVLogin.module.scss"

type Props = {
  calendarKey: string
  show: boolean
  onHide: (status: boolean) => any
}

type FormFieldsType = {
  username: string
  password: string
}

export default (props: Props) => {
  const { calendarKey, show, onHide } = props
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        username: yup.string().required().label("Username"),
        password: yup.string().required().label("Password"),
      }),
  })

  const onModalClose = (status: boolean) => {
    onHide(status)
    resetForm()
  }

  const onSubmit = (fields: FormFieldsType) =>
    dispatch.users
      .onViewNoteUserLogin({ ...fields, calendar_key: calendarKey })
      .then(() => onModalClose(false))
      .catch(handleApiError)

  return (
    <Modal
      disableBodyScroll
      show={show}
      onHide={onModalClose}
      title="Login into On-View Note Editor"
      titleAlign="left"
    >
      <div className={css.loginForm}>
        <FormItem required name="username" control={control} label="Username">
          <Input />
        </FormItem>
        <FormItem required name="password" control={control} label="Password">
          <Input type="password" />
        </FormItem>
        <div className={css.loginActions}>
          <Button type="primary" onClick={handleSubmit(onSubmit)}>
            Login
          </Button>
          <a
            target="_blank"
            className="ml-3"
            href="/forgot_password.cgi?type=2"
          >
            Forgot Password
          </a>
        </div>
      </div>
    </Modal>
  )
}
