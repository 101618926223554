import React, { useEffect, useState } from "react"
import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card"
import Tabs from "@app/components/Tabs"
import Button from "@app/components/Button"
import Loader from "@app/components/Loader"
import RequestPage from "./components/Request"
import OverlappingPage from "./components/OverlappingRequests"
import DetailsPage from "./components/RequestDetails"
import css from "./components/EditRequest.module.scss"
import api from "@app/services/api"
import OverlappingAssignment from "./components/OverlappingAssignment"

const EditRequestStatus: React.FC<any> = (props): JSX.Element => {
  const ReqResp = {
    request: [],
    overlapping_requests: [],
    display_name: "",
  }

  const [formValues, setFormValues] = useState({
    requestid: "",
    request_status: "",
    scheduler_comment: "",
    request_type: "",
    request_typeid: "",
    request_multi_dates: Array<String>(),
    override_request_count: false,
  })

  const [reqResponse, setReqResponse] = useState(ReqResp)
  const [rotationResp, setRotationResp] = useState()
  const [saveButton, setSaveButton] = useState(0)
  const [buttonsDisabled, disableButtons] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [isComplete, setIsComplete] = useState(false)
  const [requestTypes, setRequestTypes] = useState([])
  const [overlappingRequests, setOverlappingRequests] = useState<
    OverlappingRequest[]
  >([])
  const [overlappingAssignment, setOverlappingAssignment] = useState<
    OverlappingAssignment[]
  >([])

  useEffect(() => {
    Promise.all([
      api.getProviderRequestTypes(),
      api.getProviderRequest(props.requestid),
      api.getOverlaps(props.requestid),
    ]).then(([providerRequestTypesRes, providerRequestRes, overlapping]) => {
      setRequestTypes(providerRequestTypesRes)
      providerRequestRes.display_name =
        providerRequestRes.request.provider_display_name
      setReqResponse(providerRequestRes)
      setRotationResp(providerRequestRes.rotations)
      setOverlappingRequests(
        overlapping.overlapping_requests.sort(
          (a: any, b: any) => Number(a.requestid) < Number(b.requestid)
        )
      )
      setOverlappingAssignment(
        overlapping.overlapping_assignments.sort(
          (a: any, b: any) => Number(a.requestid) < Number(b.requestid)
        )
      )
      setShowLoader(false)
    })
  }, [])

  if (showLoader == true) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <div className="w-100 p-3 d-flex justify-content-center">
            <Loader animation="dots" />
          </div>
        </Card>
      </BaseLayout>
    )
  }

  if (isComplete == true) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <div className="w-100 p-3 d-flex justify-content-center">
            <div className={css.formLabel}>Updated Provider Request</div>
          </div>
        </Card>
        <div className={css.modalButtons}>
          <Button
            className="btn btn-primary"
            id="react-close-btn"
            onClick={props.onHide}
          >
            Close
          </Button>
        </div>
      </BaseLayout>
    )
  }

  const tabs = [
    {
      title: "Request",
      eventKey: "request",
      content: (
        <div className="mt-1">
          <RequestPage
            req={reqResponse.request}
            rotation={rotationResp}
            requestTypes={requestTypes}
            saveButton={saveButton}
            setSaveButton={setSaveButton}
            disableButtons={disableButtons}
            setIsComplete={setIsComplete}
            setShowLoader={setShowLoader}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </div>
      ),
    },
    {
      title: (
        <span>
          Conflicts{" "}
          <span
            style={
              overlappingRequests.length + overlappingAssignment.length
                ? { color: "red" }
                : {}
            }
          >
            ({overlappingRequests.length + overlappingAssignment.length})
          </span>
        </span>
      ),
      eventKey: "overlapping-requests",
      content: (
        <div className="mt-5">
          <OverlappingAssignment
            overlappingAssignment={overlappingAssignment}
          />
          <OverlappingPage
            overlappingRequests={overlappingRequests}
            overlappingAssignment={overlappingAssignment}
          />
        </div>
      ),
    },
    {
      title: "Details",
      eventKey: "details",
      content: (
        <div className="mt-5">
          <DetailsPage req={reqResponse.request} />
        </div>
      ),
    },
  ]

  return (
    <BaseLayout containerSize="fluid" className="mt-3">
      <div className={css.modalTitle}>
        Provider Request - {reqResponse.display_name}
      </div>
      <Card>
        <Tabs variant="underline" tabs={tabs} />
      </Card>
      <div className={css.modalButtons}>
        <Button
          className="btn btn-light"
          id="react-cancel-btn"
          disabled={buttonsDisabled}
          onClick={props.onHide}
        >
          Cancel
        </Button>{" "}
        <Button
          onClick={() => setSaveButton((prev) => prev + 1)}
          className="btn btn-primary"
          disabled={buttonsDisabled}
        >
          Save
        </Button>
      </div>
    </BaseLayout>
  )
}

export default EditRequestStatus
