import React, { useReducer, useEffect } from "react"
import { Form, Alert } from "react-bootstrap"
import Button from "@app/components/Button"
import Table from "@app/components/Table"
import Loader from "@app/components/Loader"
import { FormItem, useForm, Checkbox, Input } from "@app/components/Form"
import api, { useRequest, mutate } from "@app/services/api"
import { handleApiError } from "@app/utils"
import EditSchedulerProviderModal from "./EditSchedulerProviderModal.tsx"
import ClinicPermissionsTable from "./ClinicPermissionsTable"

export type Group = GroupType & {
  clinic_name: string
  group_name: string
  abbrev: string
  priority: number
  roleid: number
  role: string
}

type FormFieldsType = {
  userid: string | number
  ts_username: string
  tc_username: string
  tc_token: string
  sso_enabled: boolean
  email: string
}

type initialStateType = {
  userid: string | number
  firstname: string
  lastname: string
  username: string
  email: string
  groupAssociations: Group[]
  loading: boolean
  displayAlert: boolean
  alertMessage: string
  alertType: string
  showModal: boolean
}

type UserLookupEditProps = {
  selectedUserId: string | number
  displayEditUserPage(userid: Number | String): void
}

const UserLookupEdit: React.FC<UserLookupEditProps> = (props): JSX.Element => {
  const initialState: initialStateType = {
    userid: "",
    firstname: "",
    lastname: "",
    groupAssociations: [],
    loading: true,
    displayAlert: false,
    alertMessage: "",
    alertType: "",
    username: "",
    email: "",
    showModal: false,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "setUser":
        const { firstname, lastname, username, groupAssociations } =
          action.payload
        return {
          ...state,
          username,
          firstname,
          lastname,
          groupAssociations,
          loading: !state.loading,
        }
      case "setAlert":
        const { displayAlert, alertMessage, alertType } = action.payload
        return { ...state, displayAlert, alertMessage, alertType }
      case "toggleAlert":
        return { ...state, displayAlert: !state.displayAlert }
      case "checkSchedulerProvider":
        return {
          ...state,
          selectedSchedulerProvider: [
            ...(state.selectedSchedulerProvider || []),
            action.payload,
          ],
        }
      case "uncheckSchedulerProvider":
        const schedulerProviderListCopy = [...state.selectedSchedulerProvider]
        const removeIndex = schedulerProviderListCopy.findIndex(
          (entity) => entity.id === action.payload.id
        )
        schedulerProviderListCopy.splice(removeIndex, 1)
        return {
          ...state,
          selectedSchedulerProvider: schedulerProviderListCopy,
        }
      case "toggleModal":
        return { ...state, showModal: !state.showModal }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const { control, setValue, handleSubmit } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          ts_username: yup.string().required().label("Username"),
          tc_username: yup.string().label("TigerConnect Username"),
          tc_token: yup.string().label("TigerConnect Token"),
          sso_enabled: yup.boolean().label("SSO Enabled"),
        })
      ),
  })

  const { data: userInfo } = useRequest([
    api.AdminUserLookupGetUser,
    props.selectedUserId,
  ])

  useEffect(() => {
    if (userInfo) {
      const {
        userid,
        username,
        tigerconnect_token,
        tigerconnect,
        tigerschedule_sso,
        firstname,
        lastname,
        email,
      } = userInfo.user
      setValue("ts_username", username)
      setValue("userid", userid)
      setValue("tc_username", tigerconnect ? tigerconnect : "")
      setValue("tc_token", tigerconnect_token ? tigerconnect_token : "")
      setValue("email", email || "N/A")
      setValue("sso_enabled", Number(tigerschedule_sso) ? true : false)
      dispatch({
        type: "setUser",
        payload: {
          firstname,
          lastname,
          username,
          groupAssociations: userInfo.group_associations,
        },
      })
    }
  }, [userInfo])

  const displayAlertMessage = (message: string, alertType: string): void => {
    dispatch({
      type: "setAlert",
      payload: {
        displayAlert: true,
        alertMessage: message,
        alertType: alertType,
      },
    })
  }

  const onSubmit = (fields: FormFieldsType): void => {
    const requestBody = { ...fields, sso_enabled: fields.sso_enabled ? 1 : 0 }

    api
      .AdminUserLookupUpdateUser(props.selectedUserId, requestBody)
      .then((response) => {
        displayAlertMessage("User successfully updated", "success")
      }, handleApiError)
  }

  const tableColumns = [
    {
      title: "",
      dataKey: "entity",
    },
    {
      title: "Clinic",
      dataKey: "clinic_name",
    },
    {
      title: "Group",
      dataKey: "group_name",
    },
    {
      title: "Entity ID",
      dataKey: "entityid",
    },
    {
      title: "Role",
      dataKey: "role",
    },
    {
      title: "Display Name",
      dataKey: "display_name",
    },
    {
      title: "Email",
      dataKey: "email",
    },
  ].map((column: any) => {
    if (column.dataKey === "entity") {
      return {
        render: (entity: { id: string; type: string; checked: boolean }) => {
          return (
            <div className="text-center">
              <Checkbox
                onChange={(e) => {
                  dispatch({
                    type: e.currentTarget.checked
                      ? "checkSchedulerProvider"
                      : "uncheckSchedulerProvider",
                    payload: {
                      type: entity.type,
                      id: entity.id,
                    },
                  })
                }}
              />
            </div>
          )
        },
        ...column,
      }
    } else {
      return {
        render: (info: string) => {
          return <div className="text-center">{info}</div>
        },
        ...column,
      }
    }
  })

  if (!userInfo) {
    return (
      <div style={{ textAlign: "center", width: "100%" }}>
        <Loader animation="dots" />
      </div>
    )
  } else {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #EDF0F4",
            marginBottom: "20px",
          }}
        >
          <h1 style={{ border: "none" }}>
            {state.username} ({state.firstname} {state.lastname})
          </h1>
          <Button
            onClick={() => props.displayEditUserPage("")}
            variant="danger"
            style={{ height: "100%" }}
          >
            Back
          </Button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem
            label="User ID"
            name="userid"
            control={control}
            disabled={true}
          >
            <Input />
          </FormItem>
          <FormItem
            required
            label="TS Username"
            name="ts_username"
            control={control}
          >
            <Input />
          </FormItem>

          <FormItem label="TC Username" name="tc_username" control={control}>
            <Input />
          </FormItem>

          <FormItem label="Email" name="email" control={control}>
            <Input />
          </FormItem>

          <FormItem label="TC Token" name="tc_token" control={control}>
            <Input />
          </FormItem>

          <FormItem label="SSO Enabled" name="sso_enabled" control={control}>
            <Checkbox />
          </FormItem>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="submit">Save</Button>
          </div>
          {state.displayAlert && (
            <Alert
              variant={state.alertType}
              show={state.displayAlert}
              transition={false}
              style={{ marginTop: "15px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <p>{state.alertMessage}</p>
                <Button
                  variant={`outline-${state.alertType}`}
                  onClick={() => dispatch({ type: "toggleAlert" })}
                >
                  Close
                </Button>
              </div>
            </Alert>
          )}
        </Form>
        <EditSchedulerProviderModal
          show={state.showModal}
          closeModal={() => {
            mutate([api.AdminUserLookupGetUser, props.selectedUserId])
            dispatch({
              type: "toggleModal",
            })
          }}
          userEntities={state.selectedSchedulerProvider}
        />
        <br />
        <h1>Group associations</h1>
        <Button
          disabled={
            state.selectedSchedulerProvider &&
            state.selectedSchedulerProvider.length
              ? false
              : true
          }
          onClick={() => {
            dispatch({
              type: "toggleModal",
            })
          }}
        >
          Edit
        </Button>
        <Table
          columns={tableColumns}
          data={
            state.groupAssociations &&
            state.groupAssociations.map((group: any) => {
              return {
                clinic_name: group.clinic_name,
                group_name: `${group.group_name} (${group.groupid})`,
                role: group.role,
                display_name: group.user_info.display_name,
                email: group.user_info.email,
                entityid: group.user_info.entityid,
                entity: {
                  id: group.user_info.entityid,
                  type: group.role,
                },
              }
            })
          }
        />
        <br />
        <ClinicPermissionsTable
          userid={Number(props.selectedUserId)}
          groupAssociations={state.groupAssociations}
        />
      </>
    )
  }
}

export default UserLookupEdit
