import { isSameDay, parseISO } from "date-fns"

export const getDailyScheduleChanges = (
  scheduleChanges: ScheduleChange[],
  day: Date
) =>
  scheduleChanges.filter((scheduleChange) => {
    const eventDate = parseISO(scheduleChange.event_date)
    return isSameDay(eventDate, day)
  })
