import React, { useMemo } from "react"
import { useSelector } from "@app/models"
import { SharedCalendarViewRow } from "../../../../SharedCalendarView"
import RotationInfo from "./RotationInfo"
import RotationProgressBoard from "./RotationProgressBoard"
import {
  ApiDayProgressType,
  plimProgressData,
} from "@app/services/rotationProgress"
import api, { useRequest } from "@app/services/api"

type Props = {
  annualBlockScheduleId: string
  annualBlockScheduleRotation: AnnualBlockScheduleRotationType
}

export default (props: Props) => {
  const { annualBlockScheduleId, annualBlockScheduleRotation } = props

  const { data: apiProgressData } = useRequest<ApiDayProgressType[]>([
    api.getAnnualBlockScheduleRotationAssignmentStatistics,
    annualBlockScheduleRotation.id,
  ])

  const { annualBlockScheduleProviders } = useSelector(
    (state) => state.blockSets
  )

  // apiProgressData is just including providerids and staff_requirement_id,
  // therefore we need to plim it to add providers and staff_requirements
  const progressData = useMemo(
    () =>
      plimProgressData({
        apiProgressData,
        annualBlockScheduleRotation,
        annualBlockScheduleProviders,
      }),
    [apiProgressData, annualBlockScheduleRotation, annualBlockScheduleProviders]
  )

  const blockHeight = 68

  return (
    <SharedCalendarViewRow
      sideContent={
        <RotationInfo
          annualBlockScheduleId={annualBlockScheduleId}
          annualBlockScheduleRotation={annualBlockScheduleRotation}
          progressData={progressData}
          blockHeight={blockHeight}
        />
      }
      mainContent={
        progressData ? (
          <RotationProgressBoard
            annualBlockScheduleRotation={annualBlockScheduleRotation}
            progressData={progressData}
            blockHeight={blockHeight}
          />
        ) : (
          <SharedCalendarViewRow.Loader height={blockHeight} />
        )
      }
    />
  )
}
