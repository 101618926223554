import React, { useMemo, useCallback } from "react"
import css from "./AssignmentProviderElement.module.scss"
import { formatDateToStartEnd, formatUTCTime } from "@app/utils"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { JobDefaultColor } from "@app/utils/constants"
import cx from "classnames"
import { useSelector } from "@app/models"
import CustomTooltip from "../AssignmentJobElement/Tooltip"
import { useJobTimes } from "../../CalendarGridView/hooks/useJobTime"

interface AssignmentProviderElementProps {
  assignment: GroupedAssignment
  showModal: () => void
  isCardDisabled: boolean
  quickMode?: boolean
  handleDeleteQuickAssign?: (assignment: AssignmentBaseType) => void
  handleDeleteMultipleQuickAssign: () => void
  isAssignmentHighlightedProvider?: boolean
}

export const AssignmentProviderElement = ({
  assignment,
  showModal,
  isCardDisabled,
  quickMode,
  handleDeleteQuickAssign,
  handleDeleteMultipleQuickAssign,
  isAssignmentHighlightedProvider,
}: AssignmentProviderElementProps) => {
  const {
    rulesConfig: { display_job_times: showJobTimesInCalendar },
    filterOptions: {
      jobsFilters: { jobIds },
    },
    isHighlightedChangesActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const { jobStartTime, jobEndTime } = useJobTimes(
    assignment.job?.jobid,
    assignment.edate
  )

  const { selectedJob } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const isJobFilterActive = useMemo(() => Boolean(jobIds), [jobIds])

  const isAssignmentFiltered = useMemo(
    () => !jobIds?.includes(assignment.job?.jobid),
    [jobIds, assignment.job?.jobid]
  )

  const getFormattedJobTimes = () => {
    const timeSource = assignment.split_shift || {
      starttime: jobStartTime,
      endtime: jobEndTime,
    }
    if (!timeSource) {
      return null
    }

    const startTime = formatUTCTime(timeSource.starttime)
    const endTime = formatUTCTime(timeSource.endtime)

    return formatDateToStartEnd(startTime, endTime)
  }

  const handleClick = useCallback(() => {
    if (quickMode) {
      if (!assignment.id) {
        handleDeleteQuickAssign?.(assignment)
      } else {
        handleDeleteMultipleQuickAssign()
      }
    } else {
      showModal()
    }
  }, [quickMode, assignment])

  const getIsProviderCardDisabledQuickMode = (): boolean => {
    if (quickMode && selectedJob) {
      return Boolean(assignment.split_shift)
    }
    return false
  }

  return (
    <button
      className={cx(css.assignmentWrapper, {
        [css.disabledButton]:
          isCardDisabled ||
          getIsProviderCardDisabledQuickMode() ||
          (isHighlightedChangesActive && !isAssignmentHighlightedProvider),
        [css.disabledCellJob]:
          isCardDisabled ||
          getIsProviderCardDisabledQuickMode() ||
          (isHighlightedChangesActive && !isAssignmentHighlightedProvider),
      })}
      onClick={handleClick}
      disabled={
        isCardDisabled ||
        getIsProviderCardDisabledQuickMode() ||
        (isHighlightedChangesActive && !isAssignmentHighlightedProvider)
      }
    >
      <div
        className={cx(css.assignmentBorder)}
        style={{
          borderColor:
            isCardDisabled ||
            (isHighlightedChangesActive && !isAssignmentHighlightedProvider)
              ? "#e6e6e6"
              : assignment.job?.color ?? JobDefaultColor,
          backgroundColor:
            isCardDisabled ||
            (isHighlightedChangesActive && !isAssignmentHighlightedProvider)
              ? "#e6e6e6"
              : !assignment.draft_eventid
              ? assignment.job?.color ?? JobDefaultColor
              : "",
        }}
      />
      <div
        className={cx(css.assignmentDataWrapper, {
          [css.disabledCellJob]:
            isCardDisabled ||
            (isHighlightedChangesActive && !isAssignmentHighlightedProvider),
        })}
        style={{
          backgroundColor: assignment.draft_eventid ? "transparent" : "",
          borderColor: assignment.draft_eventid ? "#DADADA" : "",
        }}
      >
        {isJobFilterActive && isAssignmentFiltered ? (
          <CustomTooltip
            arrow
            title={
              <span
                className={css.assignmentTitleWrapper}
                style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
              >
                {assignment.job?.abbrev}
              </span>
            }
          >
            <span className={css.filteredText}>Filtered</span>
          </CustomTooltip>
        ) : (
          <>
            <span
              className={css.assignmentTitleWrapper}
              style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
            >
              {assignment.job?.abbrev}
            </span>
            <span
              className={css.assignmentDescriptionWrapper}
              style={{ color: assignment.draft_eventid ? "#A5A5A5" : "" }}
            >
              {showJobTimesInCalendar && getFormattedJobTimes()}
            </span>
          </>
        )}
      </div>
    </button>
  )
}
