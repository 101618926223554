import React, { useReducer } from "react"
import { orderBy, get as lodashGet } from "lodash"
import Icon from "@app/components/Icon"
import type { ColumnType } from "./Table"
import cx from "classnames"

export enum SortOrderType {
  ASC = "asc",
  DESC = "desc",
}

export interface SorterStateType<T> {
  column?: ColumnType<T>
  sortOrder?: SortOrderType
}

export default function useTableSorter<T>() {
  const [sorterState, triggerSorter] = useReducer(
    (state: SorterStateType<T>, column: ColumnType<T>) => {
      if (column.dataKey !== state.column?.dataKey) {
        return { column, sortOrder: SortOrderType.ASC }
      } else if (!state.sortOrder) {
        return { column, sortOrder: SortOrderType.ASC }
      } else if (state.sortOrder === SortOrderType.ASC) {
        return { column, sortOrder: SortOrderType.DESC }
      } else {
        return { column }
      }
    },
    { column: {} }
  )

  // Add sort icons and handle header click
  const transformColumns = (columns: ColumnType<T>[]) => {
    return columns.map((column) => {
      if (!column.sorter) {
        return column
      }

      const sortOrder =
        sorterState.column.dataKey === column.dataKey && sorterState.sortOrder
      const activeClass = (order: SortOrderType) =>
        cx({ active: sortOrder === order })

      return {
        ...column,
        title: (
          <div className="rc-column-sorters">
            <div>{column.title}</div>
            <div className="rc-column-sorter">
              <Icon
                name="caret-up-fill"
                className={activeClass(SortOrderType.ASC)}
              />
              <Icon
                name="caret-down-fill"
                className={activeClass(SortOrderType.DESC)}
              />
            </div>
          </div>
        ),
        onHeaderCell: (col: any) => {
          const cell: React.HTMLAttributes<HTMLElement> =
            column.onHeaderCell?.(col) || {}
          const originOnClick = cell.onClick
          cell.onClick = (event: React.MouseEvent<HTMLElement>) => {
            triggerSorter(column)
            originOnClick?.(event)
          }
          return cell
        },
      }
    })
  }

  return { sorterState, transformColumns }
}

// Process data sort logic
export function getSortData<T>(
  data: Readonly<T[]> | undefined,
  sorterState: SorterStateType<T>
) {
  const { column, sortOrder } = sorterState
  const { sorter, dataKey } = column || {}

  if (!data || !sortOrder || !sorter) {
    return data || []
  }

  if (typeof sorter === "function")
    return data.slice().sort((a, b) => {
      const result = sorter(a, b, sortOrder)
      return result !== 0 ? (SortOrderType.ASC ? result : -result) : 0
    })
  else {
    const sorterKey = typeof sorter === "string" ? sorter : `${dataKey}`
    return orderBy(
      data,
      (item) => lodashGet(item, sorterKey)?.toLowerCase(),
      sortOrder
    )
  }
}
