import React from "react"
import { HashRouter, Routes, Route } from "react-router-dom"
import TabsLayout, { TabType } from "./TabsLayout"

const TabsLayoutRouting = (props: { tabs: TabType[] }): JSX.Element => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<TabsLayout tabs={props.tabs} />} />
        {props.tabs.map((tab, index) => (
          <Route
            key={index}
            path={`/${tab.eventKey}`}
            element={
              <TabsLayout
                tabs={props.tabs}
                selected={tab.eventKey}
                key={index + Math.random()}
              />
            }
          />
        ))}
      </Routes>
    </HashRouter>
  )
}

export default TabsLayoutRouting
