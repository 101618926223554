import React, { useContext } from "react"
import { ErrorMessage } from "@app/components/Form"
import { ManualScheduleFormErrorsContext } from "../../context/ManualScheduleFormErrorsContext"
import type { ISplitShiftItem } from "../../data"
import { isArray } from "lodash"

type Props = {
  jobId?: number | null
  providerId?: number | null
  splitShift?: ISplitShiftItem | ISplitShiftItem[]
}

export default ((props) => {
  const { jobId, providerId, splitShift, children } = props
  const { errors, assignments } = useContext(ManualScheduleFormErrorsContext)

  const messages: string[] = []
  const splitShifts = splitShift
    ? isArray(splitShift)
      ? splitShift
      : [splitShift]
    : []

  // Handle splitShifts first, and ignore `providerId` if splitShifts exist
  if (splitShifts.length) {
    const assignment = assignments.find((x) => x.jobid == jobId)
    const assignmentErrors = errors.filter((x) => x.jobid == jobId)

    // If users did any changes on `attrs`,
    // don't collect the error messages for the corresponding `assignment`
    const handleShiftError = (
      shift: PlainObjectType,
      assignmentShift?: PlainObjectType
    ) => {
      const attrs = ["providerid", "starttime", "endtime", "tally_credit"]
      const error = assignmentErrors.find(
        (x) => x.providerid == shift.providerid
      )

      if (
        error &&
        assignmentShift &&
        attrs.every((attr) => shift[attr] == assignmentShift[attr])
      ) {
        messages.push(...error.messages)
      }
    }

    if (assignment && assignmentErrors.length) {
      // Map the single provider schedule error on item of splitShifts
      if (splitShifts.length === 1) {
        handleShiftError(splitShifts[0], { ...assignment, tally_credit: 1 })
      }

      splitShifts.forEach((shift) => {
        const assignmentShift = assignment.split_shifts.find(
          (x) => x.providerid == shift.providerid
        )

        handleShiftError(shift, assignmentShift)
      })
    }
  } else if (providerId) {
    // collect error message for single provider schedule(not splitShifts)
    errors.forEach((x) => {
      if (x.jobid == jobId && x.providerid == providerId) {
        messages.push(...x.messages)
      }
    })
  }

  const errorMessage = messages.join(", ")

  return (
    <ErrorMessage
      type="warning"
      className="bootstrap4"
      message={
        errorMessage && (
          <div className="text-truncate" title={errorMessage}>
            {errorMessage}
          </div>
        )
      }
      children={children}
    />
  )
}) as React.FC<Props>
