import React, { useMemo, useContext } from "react"
import Loader from "@app/components/Loader"
import cx from "classnames"
import css from "./SharedCalendarViewRow.module.scss"

type Props<T> = {
  sideContent: React.ReactNode
  mainContent?: React.ReactNode
  children?: React.ReactNode
}

type ContextType<T> = {
  lists?: T[]
  index?: number
  width?: number
  isFirstItem?: boolean
  isLastItem?: boolean
}

function MakeSharedCalendarViewRow<T extends PlainObjectType>() {
  const RowContext = React.createContext<ContextType<T>>({})

  const Row = (props: Props<T>) => {
    const { sideContent, mainContent, children } = props

    const { lists, index, width, isFirstItem, isLastItem } =
      useContext(RowContext)

    // Check if first row or last row,
    // and calculate its z-index (the former item's z-index should larger than latter one)
    const [isFirstRow, isLastRow, sideZIndex] = useMemo(() => {
      if (!lists?.length) {
        return []
      }

      const isFirstRow = isFirstItem ?? index === 0
      const isLastRow = isLastItem ?? index === lists.length - 1
      const sideZIndex = lists.length + 1 - (index || 0)

      return [isFirstRow, isLastRow, sideZIndex]
    }, [lists, index, isFirstItem, isLastItem])

    // Calculate side classname and main classname
    const [sideClassName, mainClassName] = useMemo(() => {
      const sideClassNames = [css.side]
      const mainClassNames = [css.main]

      if (isFirstRow) {
        sideClassNames.push(css.firstItem)
        mainClassNames.push(css.firstItem)
      }

      if (isLastRow) {
        sideClassNames.push(css.lastItem)
        mainClassNames.push(css.lastItem)
      }

      return [cx(sideClassNames), cx(mainClassNames)]
    }, [isFirstRow, isLastRow])

    return (
      <div className={css.calendarContentRow} style={{ width }}>
        <div className={sideClassName} style={{ zIndex: sideZIndex }}>
          {sideContent}
        </div>
        <div className={mainClassName}>
          <div className={css.blocksContainer}>{mainContent || children}</div>
        </div>
      </div>
    )
  }

  const RowLoader = (props: { height: number }) => {
    const { height } = props

    return <Loader animation="glow" style={{ height }} className={css.loader} />
  }

  Row.Provider = RowContext.Provider
  Row.Loader = RowLoader

  return Row
}

export default MakeSharedCalendarViewRow()
