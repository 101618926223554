import React, { useState, useRef, useCallback, useMemo } from "react"
import Overlay from "react-bootstrap/Overlay"
import BootstrapTooltip from "react-bootstrap/Tooltip"
import { debounce } from "lodash"
import { randomID } from "@app/utils"
import cx from "classnames"
import tooltipCss from "./Tooltip.module.scss"
import css from "./ListTooltip.module.scss"

type Props<T = {}> = Omit<
  React.ComponentProps<typeof Overlay>,
  "container" | "target" | "children"
> & {
  lists: T[]
  renderTooltipContent: (item: T) => React.ReactElement
  renderListItem: (item: T) => React.ReactElement
  variant?: "default" | "progress"
  containerClassName?: string
  className?: string
  bg?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => any
}

export default <T extends object>(props: Props<T>) => {
  const {
    placement = "top",
    bg = "white",
    rootClose = true,
    variant = "default",
    containerClassName,
    className,
    lists,
    renderTooltipContent,
    renderListItem,
    onClick,
    ...rest
  } = props

  const [target, setTarget] = useState<HTMLElement | null>(null)
  const [overItemIndex, setOverItemIndex] = useState<number | null>(null)
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null)
  const containerRef = useRef(null)

  const toolTipId = useRef(randomID({ prefix: "tooltip" }))
  const debounceSetCurrentItemIndex = useCallback(
    debounce(setOverItemIndex, 100),
    []
  )

  const handleMouseOver = (
    event: React.MouseEvent<HTMLElement>,
    options: { item: PlainObjectType; index: number }
  ) => {
    setTarget(event.currentTarget)
    setHoveredItemIndex(options.index)
    debounceSetCurrentItemIndex(options.index)
  }

  const handleMouseOut = (
    event: React.MouseEvent<HTMLElement>,
    options: { item: PlainObjectType; index: number }
  ) => {
    setHoveredItemIndex(null)
    debounceSetCurrentItemIndex(null)
  }

  const handleMouseMove = (
    event: React.MouseEvent<HTMLElement>,
    options: { item: PlainObjectType; index: number }
  ) => {
    if (options.index === overItemIndex) {
      const { offsetX } = event.nativeEvent
      const { offsetWidth } = event.currentTarget
      const bufferWidth = 2

      if (offsetX < -bufferWidth || offsetX > offsetWidth + bufferWidth) {
        setHoveredItemIndex(null)
      }
    }
  }

  const defaultContainerClass = {
    default: undefined,
    progress: "d-flex",
  }[variant]

  const defaultPopperConfig = useMemo(
    () =>
      ({
        default: {},
        progress: {
          modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
        },
      }[variant]),
    [variant]
  )

  return (
    <>
      <div
        className={cx(defaultContainerClass, containerClassName)}
        ref={containerRef}
        onClick={onClick}
      >
        {lists.map((item, index) => {
          const node = renderListItem(item)
          const { className } = node.props

          return React.cloneElement(node, {
            className: cx(className, {
              [css[`itemActive-${variant}`]]: hoveredItemIndex === index,
            }),
            onMouseOver: (event: React.MouseEvent<HTMLElement>) =>
              handleMouseOver(event, { item, index }),
            onMouseOut: (event: React.MouseEvent<HTMLElement>) =>
              handleMouseOut(event, { item, index }),
            onMouseMove: (event: React.MouseEvent<HTMLElement>) =>
              handleMouseMove(event, { item, index }),
          })
        })}
      </div>
      <Overlay
        {...rest}
        target={target}
        show={overItemIndex != null}
        placement={placement}
        container={containerRef}
        popperConfig={defaultPopperConfig}
      >
        {(overlayProps) => (
          <div className={cx("bootstrap4", tooltipCss.tooltipContainer)}>
            <BootstrapTooltip
              {...overlayProps}
              id={toolTipId.current}
              className={cx(
                tooltipCss.tooltip,
                bg && tooltipCss[`bg-${bg}`],
                className
              )}
            >
              {overItemIndex != null &&
                renderTooltipContent(lists[overItemIndex])}
            </BootstrapTooltip>
          </div>
        )}
      </Overlay>
    </>
  )
}
