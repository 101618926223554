import React from "react"
import Icon from "@app/components/Icon"
import { isIE } from "@app/utils"
import cx from "classnames"
import css from "./Avatar.module.scss"

type Props = {
  src?: string
  name?: string
  size?: number
  shape?: "circle" | "square"
  className?: string
}

type customStyleType = {
  width?: number
  height?: number
}

export default (props: Props) => {
  let { src, size, shape = "circle", className } = props
  let customStyle: customStyleType = {}

  if (size) {
    customStyle["height"] = size
    customStyle["width"] = size
  }

  return (
    <div
      className={cx(css.avatar, className, {
        [css.avatarShapeCircle]: shape === "circle",
      })}
      style={customStyle}
    >
      {src ? (
        isIE ? (
          <img style={{ background: `url("${src}") no-repeat 50%/cover` }} />
        ) : (
          <img src={src} />
        )
      ) : (
        <Icon
          name="person"
          className={css.defaultAvatar}
          style={{ fontSize: (size || 35) / 1.75 }}
        />
      )}
    </div>
  )
}
