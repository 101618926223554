import api from "@app/services/api"

class Queue {
  static store: Record<string, { isProcessing: boolean; jobs: Function[] }> = {}
  public name: string

  constructor(name: string) {
    this.name = name
    Queue.store[name] ||= { isProcessing: false, jobs: [] }
  }

  get queueStore() {
    return Queue.store[this.name]
  }

  push(job: any) {
    this.queueStore.jobs.push(job)
  }

  async process(checkProcessStatus: boolean = true) {
    if (checkProcessStatus) {
      if (this.queueStore.isProcessing) {
        return
      }

      this.queueStore.isProcessing = true
    }

    const job = this.queueStore.jobs.shift()

    if (job) {
      // Process job one by one
      await job()
    }

    if (this.queueStore.jobs.length > 0) {
      this.process(false)
    } else {
      this.queueStore.isProcessing = false
    }
  }
}

export const postBatchUpdateBlocksInQueue = (
  blockSetId: string,
  params: any
) => {
  return new Promise<BlockSetType>((resolve, reject) => {
    const queue = new Queue(blockSetId)
    const job = () =>
      api.postBatchUpdateBlocks(blockSetId, params).then(resolve).catch(reject)

    queue.push(job)
    queue.process()
  })
}
