import React, { useState, useEffect } from "react"
import { useSelector } from "@app/models"
import { Input, Checkbox } from "@app/components/Form"
import { H5 } from "@app/components/Typography"
import Icon from "@app/components/Icon"
import Dropdown from "@app/components/Dropdown"
import Button from "@app/components/Button"
import api, { useRequest } from "@app/services/api"
import { useFilter, useElementPosition } from "@app/utils/hooks"
import { pluralize } from "@app/utils"
import cx from "classnames"
import css from "./Departments.module.scss"

type Props = {
  className?: string
}

export default (props: Props) => {
  const { className } = props

  const [searchBoxVisible, setSearchBoxVisible] = useState(false)
  const [keyword, setKeyword] = useState("")

  const { key: customCalendarKey } = useSelector(
    (state) => state.customCalendars.customCalendar
  )

  const {
    elementRef: departmentListRef,
    heightToScreenBottom: departmentListMaxheight,
  } = useElementPosition({ topSpace: 50 })

  const {
    filterState: { values: selectedGroupIds },
    update,
  } = useFilter(["mdv", customCalendarKey, "departments"])

  const { data: groups = [] } = useRequest<GroupType[]>(
    customCalendarKey ? [api.getCustomCalendarGroups, customCalendarKey] : null
  )

  useEffect(() => {
    if (groups && !selectedGroupIds.length) {
      handleSelectAll()
    }
  }, [groups])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Hide search box when ESC key is pressed
    if (event.keyCode === 27) {
      setSearchBoxVisible(false)
      setKeyword("")
    }
  }

  const isAllGroupsChecked = selectedGroupIds.length === groups.length
  const handleSelectAll = () => {
    update({
      values: isAllGroupsChecked ? [] : groups.map((x) => x.groupid.toString()),
    })
  }

  const visibleGroups = !searchBoxVisible
    ? groups
    : groups.filter(({ name }) =>
        name.toLowerCase().includes(keyword.toLowerCase())
      )

  return (
    <div className={className}>
      <Dropdown
        overlay={
          <Dropdown.Menu>
            <div
              className={css.departmentsDropdown}
              style={{ maxHeight: departmentListMaxheight }}
            >
              <div className={css.searchContainer}>
                <div className="flex-fill">
                  {searchBoxVisible ? (
                    <Input
                      autoFocus
                      className={css.searchBox}
                      placeholder="Search Departments"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  ) : (
                    <H5 bold>Departments</H5>
                  )}
                </div>
                <Button
                  shape="circle"
                  variant="light"
                  className={cx("mx-3", css.searchButton)}
                  onClick={() => setSearchBoxVisible(!searchBoxVisible)}
                >
                  <Icon name={searchBoxVisible ? "arrow-left" : "search"} />
                </Button>
              </div>
              <div className={cx("flex-fill", css.departmentList)}>
                {!searchBoxVisible && (
                  <Checkbox
                    label="All Departments"
                    className={css.departmentItem}
                    value={isAllGroupsChecked}
                    onChange={handleSelectAll}
                  />
                )}
                <Checkbox.Group
                  options={visibleGroups}
                  valueKey="groupid"
                  checkboxClassName={css.departmentItem}
                  value={selectedGroupIds}
                  onChange={(val) => update({ values: val })}
                />
              </div>
            </div>
          </Dropdown.Menu>
        }
      >
        {(isShow) => (
          <div className={css.departments} ref={departmentListRef}>
            <H5 className="mr-2" variant="black">
              {isAllGroupsChecked
                ? "All Departments"
                : pluralize(selectedGroupIds.length, "Department")}
            </H5>
            <Icon name={isShow ? "chevron-up" : "chevron-down"} />
          </div>
        )}
      </Dropdown>
    </div>
  )
}
