import React, { useEffect, useMemo } from "react"
import Modal from "@app/components/Modal"
import {
  FormItem,
  Select,
  Input,
  Checkbox,
  ErrorMessage,
  useForm,
  useFieldArray,
} from "@app/components/Form"
import { Text } from "@app/components/Typography"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import api, { useRequest, mutate } from "@app/services/api"
import { checkStaffLevelsUnique } from "@app/services/staffLevelsValidation"
import { handleApiError, mapPick, mutateCollection } from "@app/utils"
import cx from "classnames"
import css from "./CreateOrUpdateRotationForm.module.scss"

type Props = {
  show: boolean
  onHide: Function
  annualBlockScheduleId: string
  annualBlockScheduleRotation?: AnnualBlockScheduleRotationType
}

type FormFieldsType = {
  block_schedule_rotation_id: string
  vacation_allowed: boolean
  staff_requirements: {
    staff_level_ids: string[]
    count: number
  }[]
  block_set_ids: string[]
}

const defaultStaffRequirement = {
  staff_level_ids: undefined,
  count: undefined,
}

export default (props: Props) => {
  const {
    show,
    onHide,
    annualBlockScheduleId,
    annualBlockScheduleRotation: annualRotation,
  } = props

  const { data: rotations } = useRequest<BlockScheduleRotationType[]>([
    api.getBlockScheduleRotations,
  ])
  const { data: annualBlockScheduleRotations } = useRequest<
    AnnualBlockScheduleRotationType[]
  >([api.getAnnualBlockScheduleRotations, annualBlockScheduleId])
  const { data: staffLevels } = useRequest([api.getGroupStaffLevels])
  const { data: blockSets } = useRequest([
    api.getBlockSets,
    annualBlockScheduleId,
  ])

  // Prepare rotation select options,
  // the rotations used(created) should be disabled
  const rotationOptions = useMemo(() => {
    if (!rotations) {
      return []
    }
    const usedRotationIds = annualBlockScheduleRotations?.length
      ? annualBlockScheduleRotations
          .map((ar) => ar.block_schedule_rotation_id)
          .filter((id) => id !== annualRotation?.block_schedule_rotation_id)
      : []

    return rotations.map((rotation) => ({
      id: rotation.id,
      name: rotation.name,
      disabled: usedRotationIds.includes(rotation.id),
    }))
  }, [rotations, annualBlockScheduleRotations, annualRotation])

  const {
    control,
    handleModalSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          block_schedule_rotation_id: yup
            .string()
            .required()
            .label("Rotation Name"),
          staff_requirements: yup
            .array()
            .ensure()
            .of(
              yup.object().shape({
                staff_level_ids: yup
                  .array()
                  .ensure()
                  .min(1)
                  .of(yup.string())
                  .test(
                    "unique",
                    "You cannot have the same Staff Levels for two different Staff Requirements",
                    checkStaffLevelsUnique
                  )
                  .label("Staff Level"),
                count: yup.number().integer().required().label("Count"),
              })
            ),
          block_set_ids: yup
            .array()
            .ensure()
            .min(1)
            .of(yup.string())
            .label("Block Set"),
        })
      ),
  })
  const {
    fields: staffRequirementFields,
    append: appendStaffRequirement,
    remove: removeStaffRequirement,
  } = useFieldArray({ control, name: "staff_requirements" })

  // Set default values for staff_requirements
  useEffect(() => {
    resetForm({
      staff_requirements: annualRotation
        ? mapPick(annualRotation.staff_requirements, [
            "staff_level_ids",
            "count",
          ])
        : [defaultStaffRequirement],
    })
  }, [annualRotation])

  const onModalClose = (status: boolean) => {
    onHide(status)
    resetForm()
  }

  const onSubmit = (fields: FormFieldsType, closeModal: Function) => {
    const requestHandler = annualRotation
      ? api.updateAnnualBlockScheduleRotation(annualRotation.id, fields)
      : api.createAnnualBlockScheduleRotation({
          ...fields,
          annual_block_schedule_id: annualBlockScheduleId,
        })

    return requestHandler.then(
      (newAnnualRotation: AnnualBlockScheduleRotationType) => {
        // Update AnnualBlockScheduleRotations
        mutate(
          [api.getAnnualBlockScheduleRotations, annualBlockScheduleId],
          (AnnualRotatios?: AnnualBlockScheduleRotationType[]) =>
            mutateCollection(AnnualRotatios || [], newAnnualRotation)
        )

        // Refresh staffRequirement Statistics
        mutate([
          api.getAnnualBlockScheduleRotationAssignmentStatistics,
          newAnnualRotation.id,
        ])

        closeModal()
      },
      handleApiError
    )
  }

  return (
    <Modal
      title={`${annualRotation ? "Edit" : "Add"} Rotation`}
      show={show}
      onHide={onModalClose}
      size="lg"
      buttons={[
        { text: "Cancel", variant: "outline-primary" },
        {
          control,
          text: "Save",
          onClick: handleModalSubmit(onSubmit),
        },
      ]}
    >
      <div className="mb-3">
        <FormItem
          required
          label="Rotation Name"
          control={control}
          name="block_schedule_rotation_id"
          defaultValue={annualRotation?.block_schedule_rotation_id}
        >
          <Select options={rotationOptions} />
        </FormItem>
        {/* <FormItem
          label="Vacation Allowed"
          control={control}
          name="vacation_allowed"
          defaultValue={annualRotation?.vacation_allowed}
        >
          <Checkbox />
        </FormItem> */}
        <FormItem label="Staff Requirements">
          <div className={css.requirement}>
            <div className={cx("text-center", css.requirementSelection)}>
              <Text variant="black" as="span">
                Residency Year
              </Text>
            </div>
            <div className={cx("text-center", css.requirementAmount)}>
              <Text variant="black" as="span">
                Requirement
              </Text>
            </div>
          </div>
          {staffRequirementFields.map((item: any, index) => (
            <div className={css.requirement} key={item.id}>
              <div className={css.requirementSelection}>
                <Select
                  multiple
                  separator="OR"
                  options={staffLevels}
                  control={control}
                  name={`staff_requirements.${index}.staff_level_ids`}
                />
              </div>
              <div className={css.requirementAmount}>
                <Input
                  control={control}
                  name={`staff_requirements.${index}.count`}
                />
              </div>
              <div className={css.requirementRemove}>
                <Button
                  shape="circle"
                  variant="outline-secondary"
                  className={css.removeIcon}
                  onClick={() => removeStaffRequirement(index)}
                >
                  <Icon name="close" />
                </Button>
              </div>
            </div>
          ))}
          <ErrorMessage
            className={css.requirementAdd}
            control={control}
            name="staff_requirements"
          >
            <Button
              shape="circle"
              variant="outline-secondary"
              className={css.addIcon}
              onClick={() => appendStaffRequirement(defaultStaffRequirement)}
            >
              <Icon name="plus" />
            </Button>
            <Text variant="grey" className="ml-3">
              Add staff requirement
            </Text>
          </ErrorMessage>
        </FormItem>
        <FormItem label="Block Sets">
          <div className="mt-2 mb-3">
            <Checkbox.Group
              name="block_set_ids"
              control={control}
              options={blockSets}
              defaultValue={annualRotation?.block_sets?.map(
                (x) => x.block_set_id
              )}
            />
          </div>
        </FormItem>
      </div>
    </Modal>
  )
}
