import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "@app/models"
import SharedCalendarView, {
  SharedCalendarViewRow,
} from "../../../SharedCalendarView"
import ProviderRow from "./components/ProviderRow"
import api, { useRequest } from "@app/services/api"
import { useFilter } from "@app/utils/hooks"

type Props = {
  id: string
  syncScrollOptions?: PlainObjectType
  className?: string
  calendarHeightExpandable?: boolean
}

export default (props: Props) => {
  const { id, syncScrollOptions, className, calendarHeightExpandable } = props
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { filter, filterCacheKey } = useFilter([
    "annualBlockSchedule",
    id,
    "providers",
  ])

  const { data: annualRotations = [] } = useRequest<
    AnnualBlockScheduleRotationType[]
  >([api.getAnnualBlockScheduleRotations, id])

  const { data: tallies = [] } = useRequest<AnnualBlockScheduleTallyType[]>([
    api.getAnnualBLockScheduleTallies,
    id,
  ])

  // Get all scheduleProviders
  const { annualBlockScheduleProviders: scheduleProviders, blockSets } =
    useSelector((state) => state.blockSets)

  useEffect(() => {
    Promise.all([
      dispatch.blockSets.getAnnualBlockScheduleProviders({ id }),
      dispatch.blockSets.getBlockSets({ id }),
    ]).then(() => {
      setLoading(false)
    })
  }, [id])

  const filteredProviders = useMemo(
    () => filter(scheduleProviders, "providerid"),
    [scheduleProviders, filterCacheKey]
  )

  let emptyMessage

  if (!blockSets.length) {
    emptyMessage =
      "No block sets have been created for this annual schedule. Please add a block set in Blocks tab."
  } else if (!filteredProviders.length) {
    emptyMessage = scheduleProviders.length
      ? "All providers are currently hidden based on your filter."
      : "No providers have been created for this annual schedule."
  }

  return (
    <SharedCalendarView
      id={id}
      lists={filteredProviders}
      listsTitle="Providers"
      loading={loading}
      renderListItem={(scheduleProvider, context) => (
        <SharedCalendarViewRow.Provider value={context}>
          <ProviderRow
            annualBlockScheduleId={id}
            scheduleProvider={scheduleProvider}
            annualRotations={annualRotations}
            tallies={tallies}
          />
        </SharedCalendarViewRow.Provider>
      )}
      emptyMessage={emptyMessage}
      syncScrollOptions={syncScrollOptions}
      className={className}
      heightExpandable={calendarHeightExpandable}
    />
  )
}
