import React, { useEffect } from "react"
import useRequest from "@app/utils/request"
import api from "@app/services/api"
import { Select } from "@app/components/Form"
import Button from "@app/components/Button"
import { TemplateResultsType } from "../ApplyDailyAssignmentTemplate"
import { formatDate } from "@app/utils"
import css from "./ConfirmDailyAssignments.module.scss"

type PropsType = {
  templateId: string
  blockId: string
  providerId: number
  setPage: (page: string) => void
  setRotation: () => void
  setSelectedTemplate: (template: undefined) => void
  setAppliedTemplateSelections: (selections: TemplateResultsType) => void
  setJobsList: (jobsAndDates: JobsAndDatesType[]) => void
}

export type JobsAndDatesType = {
  start_date: string
  validation: {
    message: string
    type: string
  }
} & JobType

export default (props: PropsType) => {
  const {
    templateId,
    blockId,
    providerId,
    setPage,
    setRotation,
    setSelectedTemplate,
    setAppliedTemplateSelections,
    setJobsList,
  } = props
  const { data: jobsAndDates } = useRequest<JobsAndDatesType[]>([
    api.getJobsAndDatesFromTemplate,
    templateId,
    blockId,
    providerId,
  ])

  useEffect(() => {
    if (jobsAndDates) {
      setJobsList(jobsAndDates)
    }
  }, [jobsAndDates])

  const setAssignmentsFromSelect = (indices: number[]) => {
    const selections = [] as any
    indices.forEach((index) => selections.push(jobsAndDates?.[index]))

    setAppliedTemplateSelections(
      selections.map((job: JobsAndDatesType) => {
        return {
          providerid: providerId,
          jobid: job.jobid,
          start_date: job.start_date,
        }
      })
    )
  }

  const selectRender = (_text: any, item: any) => {
    return (
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p>{item.label}</p>
        {item?.validation && (
          <p style={{ color: "red" }}>{item.validation.message}</p>
        )}
      </div>
    )
  }

  const options =
    jobsAndDates?.map((job, index) => {
      return {
        ...job,
        key: index,
        label: `${formatDate(
          job.start_date,
          (f) => f.shortSlashWithDayOfWeek
        )} ${job.name}`,
        disabled: !!job?.validation,
      }
    }) || []

  return (
    <>
      <div className="mt-5">
        <Select
          className={css.selectList}
          loading={!jobsAndDates}
          multiple
          inline
          options={options}
          valueKey="key"
          labelKey="label"
          renderOption={selectRender}
          onChange={(values) => {
            setAssignmentsFromSelect(values)
          }}
          value={options
            .filter((option) => !option.disabled)
            .map((option) => option.key)}
        />
      </div>
      <div className="d-flex flex-row justify-content-between">
        <Button
          variant="secondary"
          onClick={() => {
            setPage("selectTemplate")
            setSelectedTemplate(undefined)
          }}
        >
          Back
        </Button>
        <Button onClick={setRotation}>Submit</Button>
      </div>
    </>
  )
}
