import React, { useContext, useEffect, useState } from "react"
import Button from "@app/components/Button"
import Modal from "@app/components/Modal"
import AppliedTemplateSummary from "../../Schedules/components/Schedulers/components/ApplyDailyAssignmentTemplate/components/AppliedTemplateSummary"
import { JobsAndDatesType } from "../../Schedules/components/Schedulers/components/ApplyDailyAssignmentTemplate/components/ConfirmDailyAssignments"
import { TemplateResultsType } from "../../Schedules/components/Schedulers/components/ApplyDailyAssignmentTemplate/ApplyDailyAssignmentTemplate"
import { QuickAssignmentContext } from "../../../context/QuickAssignmentContext"

type TemplateResultType = {
  jobsList: JobsAndDatesType[]
  appliedTemplateResults: TemplateResultsType
}

export default () => {
  const { templateResults, templatesQueued } = useContext(
    QuickAssignmentContext
  )
  const [totalQuickAssignTemplateResults, setTotalQuickAssignTemplateResults] =
    useState<TemplateResultType>()
  const [templateResultsShow, setTemplateResultsShow] = useState(false)

  useEffect(() => {
    let totalResults = {
      jobsList: [] as JobsAndDatesType[],
      appliedTemplateResults: [] as TemplateResultsType,
    }
    templateResults.forEach((templateResult) => {
      totalResults = {
        jobsList: [...totalResults.jobsList, ...templateResult.total_events],
        appliedTemplateResults: [
          ...totalResults.appliedTemplateResults,
          ...templateResult.events_created,
        ],
      }
    })
    setTotalQuickAssignTemplateResults(totalResults)
  }, [templateResults])

  return (
    <>
      <Button
        shape="pill"
        onClick={() => setTemplateResultsShow(true)}
        variant="light"
        size="xs"
        disabled={!templateResults.length || templateResults.length === 0}
      >
        {templateResults.length
          ? `${templateResults.length} / ${templatesQueued}`
          : 0}
      </Button>
      <Modal
        title="Quick Assignment Template Results"
        size="lg"
        show={templateResultsShow}
        buttons={[
          {
            text: "Close",
            variant: "outline-primary",
            onClick: () => setTemplateResultsShow(false),
          },
        ]}
        onHide={() => setTemplateResultsShow(false)}
      >
        <AppliedTemplateSummary
          jobsList={totalQuickAssignTemplateResults?.jobsList || []}
          appliedTemplateResults={
            totalQuickAssignTemplateResults?.appliedTemplateResults || []
          }
        />
      </Modal>
    </>
  )
}
