import React from "react"
import css from "./Block.module.scss"
import cx from "classnames"

type BlockProps = {
  tag?: "div" | "pre" | "span" | "p"
  bg?: string
  className?: string
  outline?: boolean
}

export default ((props) => {
  const { tag = "div", bg = "grey", className, outline, children } = props

  return React.createElement(tag, {
    className: cx(css.block, css[`block-${bg}`], className, {
      [css.blockOutline]: outline,
    }),
    children: children,
  })
}) as React.FC<BlockProps>
