import React from "react"
import Modal from "@app/components/Modal"
import css from "./EditSchedulerProviderModal.module.scss"
import { FormItem, Input, useForm } from "@app/components/Form"
import Button from "@app/components/Button"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"

type PropTypes = {
  userEntities: {
    id: number
    type: string
  }[]
  show: boolean
  closeModal: () => void
}

type FormFieldsType = {
  email: string
}

export default (props: PropTypes) => {
  const { control, handleSubmit } = useForm<FormFieldsType>()

  const onSubmit = (fields: FormFieldsType) => {
    api
      .AdminUserLookupBatchUpdate(props.userEntities, {
        email: fields.email,
      })
      .then((res) => {
        props.closeModal()
      }, handleApiError)
  }

  return (
    <Modal
      title="Update Schedulers and Providers"
      onHide={props.closeModal}
      closeButton={true}
      show={props.show}
      className={css.modal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem required label="Email" name="email" control={control}>
          <Input />
        </FormItem>
        <Button type="submit">Submit</Button>
      </form>
    </Modal>
  )
}
