// Old Rotation Colors:
// ['#c37068', '#e44b33', '#e86248', '#ef7c48', '#f4b05a', '#4ea46b', '#8fce5d', '#bbda7a',
//  '#f8e991', '#f3d276', '#addfe6', '#a6c5e4', '#5686e0', '#999ef8', '#b39df8', '#c8bdbf',
//  '#c6a7ac', '#e896b1', '#c17ae0', '#9e7edc']
// prettier-ignore
export const RotationColors = [
  '#ffccbc', '#b39df8', '#addfe6', '#999ef8', '#a6c5e4', '#f8e991', '#f88da7', '#c8bdbf',
  '#e896b1', '#c6a7ac', '#aab8c3', '#f3d276', '#9e7edc', '#c17ae0', '#bbda7a', '#8FBC8F',
  '#525252', '#194c4d', '#e91e62', '#9900ef', '#9c27b0', '#6739b6', '#0000CD', '#01bcd4',
  '#2096f3', '#5686e0', '#4050b5', '#607c8a', '#009688', '#4ea46b', '#ADFF2F', '#6B8E23',
  '#8fce5d', '#3eb722', '#ffc106', '#41fe99', '#808000', '#ff5721', '#ff9800', '#ff4fa4',
  '#f4b05a', '#ef7c48', '#e86248', '#e44b33', '#c37068', '#795647', '#2bcdda']

export const RotationDefaultColor = "#c8bdbf"
export const JobDefaultColor = "#c8bdbf"
export const ProviderDefaultColor = "#c8bdbf"
export const DefaultCalendarColor = "#d9d9d9"

export const DefaultTigerconnectSettingId =
  "00000000-0000-0000-0000-000000000000"

// Format Rule: https://date-fns.org/v2.22.1/docs/format
export const DateFormatter = {
  localized: "P", // Example: 02/28/2022, 02/01/2022
  iso: "ISO", // Exmaple: 2022-05-05 17:06:13.843112-04, 2022-05-06T05:06:13+08:00
  normal: "yyyy-MM-dd", // Example: 2022-02-28
  humanizedMonthOnly: "MMM d", // Example: Feb 28
  shortSlash: "M/d/yyyy", // Example: 2/28/2022, 2/1/2022
  shortSlashMonthOnly: "M/d", // Example: 2/28, 2/1
  timeOnly: "HH:mm:ss", // Example: 22:08:30
  hourMinute12h: "hh:mm aaa", // Example: 10:08:30 pm
  shortHourMinute12h: "h:mm aaa", // Example: 8:08:30 pm
  hourMinute24h: "HH:mm", // Example: 22:08
  humanizedFullTimestamp: "PPPP pp", // Example: Monday, February 28th, 2022 10:08:30 AM
  shortMonth: "MMM", // Example: Feb
  longLocalized: "PPPP", // Example: Monday, February 28th, 2022
  longLocalizedWithoutYear: "EEEE, MMM do", // Example: Monday, February 28th
  longLocalizedWithoutWeek: "MMMM do, yyyy", // Example: February 28th, 2022
  shortSlashWithDayOfWeek: "iii, M/d/yyyy", // Example: Sun, 3/17/2023
  timeAmPm: "h:mm aaa",
}

export const TimezoneOptions = [
  {
    zone: "US/Eastern",
    dstAbbrev: "EDT",
    dstOffset: -60,
    offset: "-500",
    abbrev: "EST",
    dst: 1,
    displayName: "US Eastern",
  },
  {
    zone: "US/Central",
    dstOffset: -60,
    dstAbbrev: "CDT",
    abbrev: "CST",
    offset: "-600",
    dst: 1,
    displayName: "US Central",
  },
  {
    dstAbbrev: "MDT",
    dstOffset: -60,
    offset: "-700",
    abbrev: "MST",
    zone: "US/Mountain",
    displayName: "US Mountain",
    dst: 1,
  },
  {
    dst: 0,
    displayName: "US Arizona",
    zone: "US/Arizona",
    offset: "-700",
    abbrev: "MST",
    dstAbbrev: "MST",
    dstOffset: 0,
  },
  {
    zone: "US/Pacific",
    dstOffset: -60,
    dstAbbrev: "PDT",
    abbrev: "PST",
    offset: "-800",
    dst: 1,
    displayName: "US Pacific",
  },
  {
    displayName: "US Alaska",
    dst: 1,
    offset: "-900",
    abbrev: "AKST",
    dstAbbrev: "AKDT",
    dstOffset: -60,
    zone: "US/Alaska",
  },
  {
    dstAbbrev: "HDT",
    dstOffset: "0",
    offset: "-1000",
    abbrev: "HST",
    zone: "US/Hawaii",
    displayName: "US Hawaii",
    dst: 0,
  },
  {
    displayName: "CA Atlantic",
    dst: 1,
    offset: "-400",
    abbrev: "AST",
    dstAbbrev: "ADT",
    dstOffset: -60,
    zone: "Canada/Atlantic",
  },
  {
    dst: 1,
    displayName: "CA Central",
    zone: "Canada/Central",
    dstAbbrev: "CDT",
    dstOffset: -60,
    offset: "-600",
    abbrev: "CST",
  },
  {
    displayName: "CA Eastern",
    dst: 1,
    offset: "-500",
    abbrev: "EST",
    dstAbbrev: "EDT",
    dstOffset: -60,
    zone: "Canada/Eastern",
  },
  {
    dstAbbrev: "MDT",
    dstOffset: -60,
    offset: "-700",
    abbrev: "MST",
    zone: "Canada/Mountain",
    displayName: "CA Mountain",
    dst: 1,
  },
  {
    abbrev: "NST",
    offset: "-330",
    dstOffset: -60,
    dstAbbrev: "NDT",
    zone: "Canada/Newfoundland",
    displayName: "CA Newfoundland",
    dst: 1,
  },
  {
    dstAbbrev: "PDT",
    dstOffset: -60,
    offset: "-800",
    abbrev: "PST",
    zone: "Canada/Pacific",
    displayName: "CA Pacific",
    dst: 1,
  },
]

export const ProvidersRules = [
  "skills",
  "moreThanOneJob",
  "dayOff",
  "eligibleJobs",
  "workingPreviousNextDay",
  "restrictedProvider",
]

export const DaysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const
export type DaysOfWeekType = typeof DaysOfWeek[number]

export const MonthsAbbrev = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

export const JobsTableHeader = [
  "Job",
  "Provider",
  "Shift Time",
  "Splits",
  "Max",
  "Type",
  "Flag",
  "Status",
]

export const dashboardMenuItems = [
  { title: "Filters", isTitle: true },
  { title: "Providers", submenu: "Select Providers to Display" },
  { title: "Jobs", submenu: "Select Jobs to Display" },
  { title: "Days Off", submenu: "Select Request (Day Off) Types to Display" },
  { title: "Missing Assignments", submenu: "Missing Assignments" },
  { title: "Highlighting", isTitle: true },
  {
    title: "Highlight Providers",
    submenu: "Select Providers to Highlight",
  },
  { title: "Highlight Changes", submenu: "Highlight Changes" },
  {
    title: "Highlight Understaffed Jobs",
    submenu: "Assignment Requirements",
    viewMode: "list",
    listViewType: "job",
  },
  { title: "Scheduling Actions", isTitle: true },
  { title: "Range", submenu: "Range" },
  { title: "Unschedule" },
  { title: "Erase Flags" },
  { title: "New Template", submenu: "New Template" },
  { title: "Quick Assign", isTitle: false, viewMode: "list" },
  { title: "Settings", isTitle: true },
  { title: "Manage Preferences", submenu: "Manage Scheduling Preferences" },
]

export const filterJobTypesOptions = [
  { id: 1, name: "Call" },
  { id: 2, name: "Work" },
  { id: 3, name: "Resident" },
]

export const UnassignedText = "Unassigned"
