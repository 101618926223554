import React from "react"
import { Row, Col } from "@app/components/Layout"
import { H3, Text } from "@app/components/Typography"
import Button from "@app/components/Button"
import Card from "@app/components/Card"
import Block from "@app/components/Block"
import Breadcrumb from "@app/components/Breadcrumb"

export default () => {
  return (
    <Card title={<H3 variant="blue">Provider (Residents)</H3>}>
      <Text as="p" className="ml-4">
        You can manage residents in the Provider List section.
      </Text>
      <Block>
        <Row>
          <Col className="d-flex align-items-center">
            <Breadcrumb>
              <Breadcrumb.Item active>Settings</Breadcrumb.Item>
              <Breadcrumb.Item active>Provider List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col md="auto">
            <Button
              as="a"
              size="sm"
              variant="outline-secondary"
              href="/user/provider_list.cgi"
            >
              Go
            </Button>
          </Col>
        </Row>
      </Block>
    </Card>
  )
}
