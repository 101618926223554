import React, { useState, useEffect, useMemo, useRef } from "react"
import { useSelector } from "@app/models"
import { Row } from "@app/components/Layout"
import { H5, Text } from "@app/components/Typography"
import Loader from "@app/components/Loader"
import VirtualList from "@app/components/VirtualList"
import { monthOfInterval, weekOfInterval, formatDate } from "@app/utils"
import { useScrollSync } from "@app/utils/hooks"
import { debounce } from "lodash"
import css from "./SharedCalendarView.module.scss"
import cx from "classnames"

type Props<T> = {
  id: string
  lists: T[]
  listsTitle: string
  renderListItem: (
    item: T,
    options: { index: number; lists: T[]; width?: number }
  ) => React.ReactNode
  loading?: boolean
  emptyMessage?: string
  blockHeight?: number
  className?: string
  hasWeekGuidelines?: boolean
  heightExpandable?: boolean
  syncScrollOptions?: PlainObjectType
}

export const Sidebar: React.FC<PlainObjectType> = ({ className, ...props }) => (
  <div className={cx(css.calendarViewSidebar, className)} {...props} />
)
export const Content: React.FC<PlainObjectType> = ({ className, ...props }) => (
  <div className={cx(css.calendarViewContent, className)} {...props} />
)

export default <T extends PlainObjectType>(props: Props<T>) => {
  const {
    // id,
    lists,
    listsTitle,
    renderListItem,
    loading = false,
    emptyMessage = "No data.",
    className,
    hasWeekGuidelines = true,
    heightExpandable,
    syncScrollOptions = useScrollSync({
      vertical: false,
      proportional: false,
    }),
  } = props

  const containerRef = useRef<HTMLDivElement>(null)
  const [maxContentHeight, setMaxContentHeight] = useState<number>(400)

  // Auto adjust height to make calendar use free rest space of page
  useEffect(() => {
    if (!heightExpandable) {
      return
    }

    const calcMaxContentHeight = debounce((event?: UIEvent | boolean) => {
      if (
        !containerRef.current ||
        (event instanceof Event ? !event?.target : !event)
      ) {
        // Only do the calculation logic when Window event trigger,
        // or by force
        return
      }

      const clientHeight = document.documentElement.clientHeight
      const containerOffsetDocumentTop =
        window.pageYOffset + containerRef.current.getBoundingClientRect().top

      const headerHeight = 45
      const space = 10

      const maxHeight =
        clientHeight - containerOffsetDocumentTop - headerHeight - space
      setMaxContentHeight(maxHeight)
    }, 300)

    // Make calcMaxContentHeight just run once in initialization,
    // to aviod recalculating when lists changing
    if (!lists?.length) calcMaxContentHeight(true)

    // Bind resize event of window for recalculating maxHeight when window size was changed
    window.addEventListener("resize", calcMaxContentHeight)

    return () => window.removeEventListener("resize", calcMaxContentHeight)
  }, [lists])

  const {
    annualBlockSchedule,
    blockConfig: { daySizePixels, bufferDays },
  } = useSelector((state) => state.blockSets)

  const {
    start_date: startDate,
    end_date: endDate,
    calendarStartDate,
    calendarEndDate,
  } = annualBlockSchedule

  const monthesInfo = useMemo(() => {
    const results = []
    const intervals = monthOfInterval(startDate, endDate)

    // process first month
    const firstMonth = intervals.shift()

    if (firstMonth) {
      const extendDays = firstMonth.restDays + bufferDays
      const overflowDays = extendDays - firstMonth.days

      if (overflowDays > 0) {
        results.push({ days: overflowDays }, firstMonth)
      } else {
        results.push({ days: extendDays, name: firstMonth.name })
      }
    }

    return results.concat(intervals)
  }, [startDate, endDate])

  const weeksInfo = useMemo(() => {
    if (!hasWeekGuidelines) {
      return []
    }

    const intervals = weekOfInterval(calendarStartDate, calendarEndDate, {
      weekStartsOn: 1,
    })

    return intervals.map((item, index) => {
      const isVisible = index !== 0 || item.restDays === item.days
      return { ...item, isVisible }
    })
  }, [calendarStartDate, calendarEndDate, hasWeekGuidelines])

  if (loading || !lists.length) {
    return (
      <div ref={containerRef} className={css.calendarViewContainer}>
        <div className="d-flex p-5 w-100 justify-content-center">
          {loading ? <Loader /> : <Text>{emptyMessage}</Text>}
        </div>
      </div>
    )
  }

  return (
    <div
      ref={containerRef}
      className={cx(css.calendarViewContainer, className)}
    >
      <Row noMargin className={css.calendarHeader} {...syncScrollOptions}>
        <Sidebar className={css.columnBorder}>
          <div
            className={cx(css.calendarHeaderItem, css.filterTitle)}
            style={{ marginRight: daySizePixels }}
          >
            <H5 bold>{listsTitle}</H5>
          </div>
        </Sidebar>
        <Content className="d-flex flex-column">
          <div className={cx("flex-fill", css.calendarMonths)}>
            {monthesInfo.map((mi, index) => (
              <div
                key={index}
                className={css.calendarMonthItem}
                style={{
                  marginRight: daySizePixels,
                  width: (mi.days - 1) * daySizePixels,
                }}
              >
                <H5>{mi.name}</H5>
              </div>
            ))}
          </div>
          {hasWeekGuidelines && (
            <div className={css.calendarWeeks}>
              {weeksInfo.map((wi, index) => (
                <div
                  key={index}
                  className={cx(css.calendarWeekItem, {
                    [css.fullWeek]: wi.isVisible,
                  })}
                  style={{
                    marginRight: daySizePixels,
                    width: (wi.restDays - 1) * daySizePixels,
                  }}
                >
                  {wi.isVisible && (
                    <div className={css.weekDate}>
                      {formatDate(wi.week, "d")}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </Content>
      </Row>
      <Row noMargin className={css.calendarContainer} {...syncScrollOptions}>
        <div className="w-100">
          <VirtualList
            height={maxContentHeight}
            overscan={150}
            data={lists}
            computeItemKey={(_, item) => item.providerid || item.id}
            itemContent={(index, item, { width }: PlainObjectType) =>
              renderListItem(item, { index, lists, width })
            }
          />
        </div>
      </Row>
    </div>
  )
}
