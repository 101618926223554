import React, { useState, useEffect } from "react"
import Modal from "@app/components/Modal"
import Loader from "@app/components/Loader"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"

type InfoObjType = {
  groupid: number
  clinic_organization_token: string
  organization_token: string
}

type Props = {
  show: boolean
  onHide: Function
  tigerconnectSetting?: TigerconnectSettingType
  formValues: any
  setFormValue: any
}

export default (props: Props) => {
  const { show, onHide, tigerconnectSetting, formValues, setFormValue } = props
  const [showLoader, setShowLoader] = useState(false)
  const [infoObj, setInfoObject] = useState({} as InfoObjType)
  const [groupName, setGroupName] = useState("")
  const onIntegrationModalClose = (status: boolean) => {
    onHide(status)
  }

  let setting: any
  useEffect(() => {
    if (tigerconnectSetting) {
      setting = tigerconnectSetting
      setInfoObject({
        ...infoObj,
        groupid: setting.groupid,
        clinic_organization_token: setting.clinic_organization_token,
        organization_token: setting.organization_token,
      })
    } else {
      api.getTigerconnectSettings().then(function (response) {
        setting = response[0]
        setInfoObject({
          ...infoObj,
          groupid: setting.groupid,
          clinic_organization_token: setting.clinic_organization_token,
          organization_token: setting.organization_token,
        })
      })
    }

    //need to get the group name from the logged in user
    api.getCurrentUserInfo().then(function (response) {
      setGroupName(response.group.name)
    })
  }, [])

  let token = infoObj.organization_token
    ? infoObj.organization_token
    : infoObj.clinic_organization_token
  let label = formValues.label
  if (formValues.organization_token) {
    token = formValues.organization_token
  }

  const handleIntegrationModalSubmit = () => {
    setShowLoader(true)
    if (
      formValues.tigerconnect_key === null ||
      formValues.tigerconnect_key === undefined ||
      String(formValues.tigerconnect_key).length == 0 ||
      formValues.tigerconnect_secret === null ||
      formValues.tigerconnect_secret === undefined ||
      String(formValues.tigerconnect_secret).length == 0
    ) {
      setShowLoader(false)
      handleApiError({
        message:
          "API Key and Secret are required to generate a new Integration Id.",
      })
      return true
    } else {
      api
        .createNewTCIntegrationId(
          formValues.organization_token,
          formValues.tigerconnect_key,
          formValues.tigerconnect_secret
        )
        .then(function (response) {
          setFormValue("integrationid", response.integrationid, {
            shouldTouch: true,
            shouldDirty: true,
          })
          onHide(false)
          setShowLoader(false)
        })
        .catch((error) => {
          setShowLoader(false)
          let message = "There was an error creating the new Integration Id."
          if (error.message.code == 401) {
            message += " The API Key and Secret failed to authorize."
          }
          handleApiError({ message: message })
        })
    }

    return false
  }

  if (showLoader == true) {
    return (
      <Modal
        title="Create Integration Record"
        show={show}
        onHide={onIntegrationModalClose}
        size="lg"
      >
        <div className="w-100 p-3 d-flex justify-content-center">
          <Loader animation="dots" />
        </div>
      </Modal>
    )
  } else {
    return (
      <Modal
        title="Create Integration Record"
        show={show}
        onHide={onIntegrationModalClose}
        size="lg"
        buttons={[
          { text: "Cancel", variant: "outline-primary" },
          {
            text: "Create Integration Record",
            onClick: () => handleIntegrationModalSubmit(),
          },
        ]}
      >
        <div className="mx-3 pb-3">
          <h3>
            Are you sure you want to create a new TigerConnect integration
            record for
            <br />
            Group{" "}
            <em>
              {infoObj.groupid} ({groupName})
            </em>
            <br />
            in Organization{" "}
            <em>
              {token} ({label})
            </em>
          </h3>
        </div>
      </Modal>
    )
  }
}
