import React, { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { useSelector, useDispatch } from "@app/models"
import Loader from "@app/components/Loader"
import Icon from "@app/components/Icon"
import MDVLayout from "@app/containers/layouts/MDV"
import MDVLogin from "./components/MDVLogin"
import WhiteboardHeader from "./components/WhiteboardHeader"
import WhiteboardContent from "./components/WhiteboardContent"
import NotFound from "@app/containers/common/NotFound"
import { OnViewNoteUserLoginContext } from "./context/OnViewNoteUserLoginContext"

import css from "./MergedDailyWhiteboardPage.module.scss"

export default () => {
  const { key } = useParams()
  const [loginModalShow, setLoginModalShow] = useState(false)
  const [printView, setPrintView] = useState(false)
  const [printing, setPrinting] = useState(false)
  const { customCalendar } = useSelector((state) => state.customCalendars)

  const dispatch = useDispatch()
  const isLoading = !customCalendar.key

  // Get custom calendar
  useEffect(() => {
    dispatch.customCalendars.getCustomCalendar(key)
  }, [key])

  if (!key) {
    return <NotFound />
  }

  const printRef = useRef(null)

  const handleClick = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setPrintView(true)
        setPrinting(true)
        const i = setInterval(() => {
          if (document.querySelectorAll("div.shift-loading").length === 0) {
            clearInterval(i)
            setPrinting(false)
            resolve(1)
          }
        }, 300)
      })
    },
    onAfterPrint: () => setPrintView(false),
  })

  return (
    <MDVLayout
      title={customCalendar.name}
      loading={isLoading}
      timezone={customCalendar.timezone}
      onViewNotes={customCalendar.on_view_notes}
      showLoginModal={() => setLoginModalShow(true)}
      printRef={printRef}
    >
      <MDVLogin
        calendarKey={key}
        show={loginModalShow}
        onHide={setLoginModalShow}
      />
      {isLoading ? (
        <div className="d-flex p-5 justify-content-center">
          <Loader animation="dots" />
        </div>
      ) : (
        <>
          <div style={{ position: "relative", float: "right" }}>
            <button className={css.printButton} onClick={handleClick}>
              {printing ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Icon
                  hoverable
                  name="printer"
                  title="Print the Merged Daily View"
                />
              )}
            </button>
          </div>
          <WhiteboardHeader />
          <OnViewNoteUserLoginContext.Provider
            value={{ setLoginModalShow, printView }}
          >
            <React.Suspense fallback={null}>
              <WhiteboardContent />
            </React.Suspense>
          </OnViewNoteUserLoginContext.Provider>
        </>
      )}
    </MDVLayout>
  )
}
