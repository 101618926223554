import React from "react"
import { useParams } from "react-router-dom"
import BlockSchedulingForm from "@app/containers/layouts/BlockSchedulingForm"
import { useForm, Select, ErrorMessage } from "@app/components/Form"
import { Text } from "@app/components/Typography"
import Table from "@app/components/Table"
import api, { useRequest } from "@app/services/api"
import {
  templates,
  renderTemplate,
  ruleDataSchemaBuilder,
} from "@app/services/dutyHourRuleTemplate"
import { handleApiError } from "@app/utils"
import css from "./CreateOrUpdateDutyHourRule.module.scss"

type FormFieldsType = {
  staff_level_ids: string[]
  data: Record<string, PlainObjectType>
}

export default () => {
  const { id } = useParams()

  const { control, handleSubmit } = useForm<FormFieldsType>({
    schema: (yup) => {
      return yup.lazy(() =>
        yup.object().shape({
          staff_level_ids: yup
            .array()
            .ensure()
            .of(yup.string())
            .min(
              1,
              "You must select at least one staff level to assign to this set of Duty Hour rules"
            ),
          data: ruleDataSchemaBuilder(yup).test(
            "notAllFeildsEmpty",
            "You must set at least one Duty Hour rule",
            notAllFeildsEmpty
          ),
        })
      )
    },
  })

  const notAllFeildsEmpty = (dataValue: PlainObjectType) => {
    return Object.values(dataValue).some((obj) =>
      Object.values(obj).some((val) => val != null)
    )
  }

  const { data: dutyHourRule, mutate: mutateDutyHourRule } =
    useRequest<DutyHourRuleType>(id ? [api.getDutyHourRule, id] : null)
  const { data: staffLevels } = useRequest<StaffLevelType[]>([
    api.getGroupStaffLevels,
  ])
  const { data: dutyHourRules, mutate: mutateDutyHourRules } = useRequest<
    DutyHourRuleType[]
  >([api.getDutyHourRules])

  const onSubmit = (fields: FormFieldsType, redirectBack: any) => {
    if (id) {
      return api.updateDutyHourRule(id, fields).then((res) => {
        mutateDutyHourRule(res, false)

        if (dutyHourRules) {
          const dutyHourRuleIndex = dutyHourRules.findIndex((x) => x.id === id)
          dutyHourRules[dutyHourRuleIndex] = res
          mutateDutyHourRules(dutyHourRules, false)
        }

        redirectBack()
      }, handleApiError)
    } else {
      return api.createDutyHourRule(fields).then((res) => {
        dutyHourRules && mutateDutyHourRules([...dutyHourRules, res], false)
        redirectBack()
      }, handleApiError)
    }
  }

  const staffLevelIdsOptions = staffLevels?.map((sl) => {
    const usedStaffLevelIds =
      dutyHourRules
        ?.filter((x) => x.id !== dutyHourRule?.id)
        .reduce((acc: string[], x) => acc.concat(x.staff_level_ids), []) || []

    return {
      id: sl.id,
      name: sl.name,
      disabled: usedStaffLevelIds.includes(sl.id),
    }
  })

  const ruleSections = templates.map(({ name, type }) => {
    return {
      id: type,
      rule: name,
      rule_builder: renderTemplate(type, {
        control,
        dutyHourRule,
        renderItem: (children, key) => (
          <div className="d-flex align-items-center mt-2 mb-2" key={key}>
            {children}
          </div>
        ),
      }),
    }
  })

  return (
    <BlockSchedulingForm
      title="Duty Hour Rules"
      onSubmit={handleSubmit(onSubmit)}
      formControl={control}
    >
      <div>
        <div className="mb-3">
          <Text bold variant="black">
            Staff Level
          </Text>
          <Text variant="grey">
            Select the provider staff level for these duty hour rules.
          </Text>
        </div>
        <Select
          inline
          multiple
          name="staff_level_ids"
          control={control}
          options={staffLevelIdsOptions || []}
          loading={!staffLevels}
          defaultValue={dutyHourRule?.staff_level_ids}
        />
      </div>
      <ErrorMessage
        className={css.tableContainer}
        control={control}
        name="data"
      >
        <Table
          className={css.table}
          data={ruleSections}
          hover={false}
          striped={false}
          columns={[
            { dataKey: "rule", title: "Rule" },
            { dataKey: "rule_builder", title: "Rule Builder" },
          ]}
        />
      </ErrorMessage>
    </BlockSchedulingForm>
  )
}
