import React, { useState, useMemo } from "react"
import api, { useRequest } from "@app/services/api"
import Card from "@app/components/Card"
import Loader from "@app/components/Loader"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import { H3 } from "@app/components/Typography"
import { Dialog } from "@app/components/Modal"
import BaseLayout from "@app/containers/layouts/Base"
import {
  formatDate,
  parseDate,
  isDateRangeCovered,
  handleApiError,
} from "@app/utils"
import { addDays, startOfYear } from "date-fns"
import { uniq } from "lodash"

export default () => {
  const [showLoader, setShowLoader] = useState(false)
  const {
    data: publishedData,
    mutate: refreshPublishedData,
    isValidating,
  } = useRequest<{ draft_publishing: DraftPublishingType[] }>([
    api.getDraftPublishing,
  ])

  const formattedPublishedData = useMemo(
    () =>
      publishedData?.draft_publishing.map((draftPublish) => {
        const startdate = parseDate(draftPublish.start_date)
        const enddate = parseDate(draftPublish.end_date)
        const formattedDate = `${formatDate(
          startdate,
          (f) => f.localized
        )} - ${formatDate(enddate, (f) => f.localized)}`

        return { ...draftPublish, date_range: formattedDate }
      }),
    [publishedData]
  )

  const renderPublishedAt = (time: string) => {
    if (!time) {
      return
    }

    const date = parseDate(time, (f) => f.iso)
    return `${formatDate(date, (f) => f.shortSlash)}  ${formatDate(
      date,
      (f) => f.hourMinute12h
    )}`
  }

  const handlePublishedAtOnFilter = (filterValue: any[], time: string) => {
    if (!time) {
      return false
    }

    const date = parseDate(time, (f) => f.iso)
    const today = new Date()
    let startDate

    if (filterValue.includes("year_to_date")) {
      startDate = startOfYear(today)
    } else if (filterValue.includes("past_31_days")) {
      startDate = addDays(today, -31)
    } else if (filterValue.includes("past_7_days")) {
      startDate = addDays(today, -7)
    }

    if (startDate) {
      return isDateRangeCovered(date, { start: startDate })
    } else {
      return false
    }
  }

  const unpublishEvents = (
    id: number,
    date_range: string,
    event_count: number
  ) => {
    Dialog.confirm({
      title: "Confirm Undo",
      message: (
        <>
          <div>
            Are you sure that you want to unpublish all {event_count} published
            assignments for date range {date_range}?<br />
            This will revert the assignments to draft mode, and they will no
            longer be visible to providers in the group
          </div>
        </>
      ),
      buttons: {
        ok: {
          text: "Undo Publish",
          variant: "danger",
          onClick() {
            setShowLoader(true)
            api.unpublishDraftEvents(id).then(
              () => {
                refreshPublishedData()
                if (!isValidating) {
                  setShowLoader(false)
                }
              },
              function (err) {
                setShowLoader(false)
                handleApiError(err)
              }
            )
          },
        },
      },
    })
  }

  const columns: React.ComponentProps<typeof Table>["columns"] = [
    {
      title: "Time",
      dataKey: "published_at",
      sorter: true,
      render: renderPublishedAt,
      onFilter: handlePublishedAtOnFilter,
      filter: {
        options: [
          { id: "past_7_days", name: "Past 7 Days" },
          { id: "past_31_days", name: "Past 31 Days" },
          { id: "year_to_date", name: "Year to Date" },
        ],
      },
    },
    { title: "User", dataKey: "fullname", sorter: true },
    { title: "Published Dates", dataKey: "date_range", sorter: "start_date" },
    {
      title: "Job",
      dataKey: "job_name",
      sorter: true,
      filter: {
        multiple: true,
        options: uniq([
          "All Jobs",
          "Events Deleted",
          ...(formattedPublishedData?.map((x) => x.job_name) || []),
        ]).map((name) => ({ id: name, name })),
      },
    },
    {
      dataKey: "action",
      width: 200,
      position: "right",
      render: (_: any, item: any) => (
        <div>
          <Button
            disabled={item.event_count ? false : true}
            size="sm"
            variant="outline-secondary"
            onClick={() =>
              unpublishEvents(
                item.draft_publishingid,
                item.date_range,
                item.event_count
              )
            }
          >
            Undo Publish
          </Button>
        </div>
      ),
    },
  ]

  if (showLoader == true) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <div className="w-100 p-3 d-flex justify-content-center">
            <Loader animation="dots" />
          </div>
        </Card>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout>
      <Card disableBodyScroll title={<H3 variant="blue">Undo Publish</H3>}>
        <Table
          columns={columns}
          data={formattedPublishedData}
          rowKey="draft_publishingid"
          loading={!formattedPublishedData}
          emptyMessage={`There are no recently published assignments.`}
        />
      </Card>
    </BaseLayout>
  )
}
