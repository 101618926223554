import * as React from "react"
import ErrorMessage from "./components/ErrorMessage"
import LoginStep2 from "./components/LoginStep2"
import { LoginStepEnum } from "./utils/loginStepEnum"
import LoginButton from "./components/LoginButton"
import LoginUsernameInput from "./components/LoginUsernameInput"

declare var TigerConnect: any
interface LoginProps {
  message: string
  username?: string
  logintype?: string
  tcbaseurl?: string
  mobile_sso?: string
}

interface LoginState {
  message: string
  username: string
  loginStep: LoginStepEnum
  passwd: string
  apikey: string
  secret: string
  loading: boolean
}

interface IJsSdkClientConfig {
  partnerName: string
  version: string
  baseUrl?: string
}

export default class Login extends React.Component<LoginProps, LoginState> {
  client: any // js-sdk currently have no typings
  constructor(props: LoginProps) {
    super(props)
    this.setJsSDKClient(props)
    let loginStep = this.getInitLoginStep(props)
    this.state = {
      message: props.message ?? "",
      username: props.username ?? "",
      loginStep: loginStep,
      passwd: "",
      apikey: "",
      secret: "",
      loading: false,
    }
  }

  private getInitLoginStep = (props: LoginProps): LoginStepEnum => {
    if (props.username) {
      if (props.logintype === "sso") {
        return LoginStepEnum.SSO_LOGIN_BY_PSWD
      } else {
        return LoginStepEnum.SCHEDULE_LOGIN_BY_PSWD
      }
    } else {
      return LoginStepEnum.LOGIN_CHECK
    }
  }

  private setJsSDKClient = (props: LoginProps) => {
    let config: IJsSdkClientConfig = {
      partnerName: "tiger_schedule",
      version: "release 1.0",
    }
    if (props.tcbaseurl) {
      config = {
        ...config,
        baseUrl: props.tcbaseurl,
      }
    }
    this.client = new (window as any).TigerConnect.Client(config)
  }

  // Handle Input Value Change. Bind input value with state
  private onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = e.currentTarget
    if (id === "username" || id === "passwd") {
      this.setState({ ...this.state, [id]: value })
    }
  }

  private goBackToLoginCheck = () => {
    this.setState({
      ...this.state,
      passwd: "",
      loginStep: LoginStepEnum.LOGIN_CHECK,
    })
  }

  private checkLogin = async () => {
    const { username } = this.state
    if (!username) {
      this.setErrorMessage("Please supply a username.")
      return
    }
    this.showLoading()
    const body = { username: this.state.username }
    const res = await fetch(
      `${location.protocol}//${location.host}/api/auth/check_login.cgi`,
      {
        method: "post",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
    if (res.ok) {
      const json = await res.json()
      const ssoFlag = json.data.tigerschedule_sso
      if (ssoFlag) {
        this.ssoCheckLogin(username)
      } else {
        this.setState(
          {
            ...this.state,
            loginStep: LoginStepEnum.SCHEDULE_LOGIN_BY_PSWD,
          },
          this.hideLoading
        )
      }
    } else {
      this.setErrorMessage(
        "There is not an account that matches the username or email that you provided."
      )
    }
  }

  private ssoCheckLogin = async (username: string) => {
    try {
      username = username.trim()
      const data = await this.client.users.checkLogin(username, { udid: "" })
      const userExists = !!data.find((env: any) => env.users.length > 0)
      if (userExists) {
        const isExternalLogin = await this.handleExternalLogin(data, username)
        if (isExternalLogin) {
          return
        } else {
          this.setState(
            {
              ...this.state,
              message: "",
              passwd: "",
              loginStep: LoginStepEnum.SSO_LOGIN_BY_PSWD,
            },
            this.hideLoading
          )
        }
      } else {
        // TODO: Check requirment: What error msg need to be shown if Schedule has a username and positive sso_flag, but checklogin failed in TC
        this.setErrorMessage("We can not find an account that matched.")
      }
    } finally {
    }
  }

  private handleExternalLogin = async (envs: any, username: string) => {
    for (const { users } of envs) {
      const user = users[0]
      for (const { method, url } of user.auth) {
        if (method === "external") {
          window.location.href = `${url}&&tt_product_version=tiger_schedule`
          return true
        }
      }
    }
    return false
  }

  private handleFormSubmit = async (event: React.FormEvent) => {
    const { loginStep } = this.state
    switch (loginStep) {
      case LoginStepEnum.LOGIN_CHECK:
        event.preventDefault()
        this.checkLogin()
        break
      case LoginStepEnum.SSO_LOGIN_BY_PSWD:
        this.showLoading()
        event.preventDefault()
        this.handleSsoLoginByPassword()
      default:
        this.showLoading()
        break
    }
  }

  private handleSsoLoginByPassword = async () => {
    const { username, passwd } = this.state
    if (!!username && !!passwd) {
      try {
        const { auth } = await this.client.signIn(username, passwd)
        this.setState(
          {
            ...this.state,
            apikey: auth.key,
            secret: auth.secret,
          },
          this.submitForm
        )
      } catch (error) {
        this.setErrorMessage(
          "The username or password combination you entered is incorrect"
        )
      } finally {
      }
    }
    // else: username or passwd input is empty. Do and show nothing according to requirment
  }

  private submitForm = () => {
    const form = document.getElementById("login-form") as HTMLFormElement
    form.submit()
  }

  private setErrorMessage = (message: string) => {
    this.setState({
      ...this.state,
      message,
      loading: false,
    })
  }

  private showLoading = () =>
    this.setState({
      ...this.state,
      loading: true,
      message: "",
    })
  private hideLoading = () => this.setState({ ...this.state, loading: false })

  public render(): JSX.Element {
    return (
      <div>
        <div className="col-xs-12 col-xs-offset-0 col-sm-10 col-sm-offset-1">
          <div className="row">
            <div className="login-form-header col-xs-12">
              <div className="login-form-header-text headspan">Log in</div>
            </div>
          </div>

          <div className="row login-main-content">
            <form
              id="login-form"
              action="/login.cgi"
              method="post"
              onSubmit={this.handleFormSubmit}
            >
              <input type="hidden" name="single-signon" value="1" />
              <input
                type="hidden"
                name="mobile_sso"
                value={this.props.mobile_sso}
              />
              <ErrorMessage message={this.state.message} />
              <LoginUsernameInput
                onInputChange={this.onInputChange}
                username={this.state.username}
                loginStep={this.state.loginStep}
                goBackToLoginCheck={this.goBackToLoginCheck}
              />
              <LoginStep2
                loginStep={this.state.loginStep}
                onInputChange={this.onInputChange}
                passwd={this.state.passwd}
                apikey={this.state.apikey}
                secret={this.state.secret}
              />
              <LoginButton
                loginStep={this.state.loginStep}
                loading={this.state.loading}
              />
            </form>
          </div>
        </div>
      </div>
    )
  }
}
