import React from "react"
import css from "./NotesHeader.module.scss"
import Icon from "@app/components/Icon"
import { ArrowBack } from "@material-ui/icons"

interface Props {
  onModalClose: () => void
  closeButton: boolean
  date?: string
}

export const NotesHeader = ({ date, onModalClose, closeButton }: Props) => {
  return (
    <div className={css.container}>
      <div className={css.leftContainer}>
        <h3 className={css.title}>Notes</h3>
        <span className={css.divider}>|</span>
        <h3 className={css.dateSubtitle}>{date}</h3>
      </div>

      {closeButton ? (
        <Icon name="close" className={css.closeIcon} onClick={onModalClose} />
      ) : (
        <button className={css.backButton} onClick={onModalClose}>
          <ArrowBack className={css.icon} />
          <span>Back</span>
        </button>
      )}
    </div>
  )
}
