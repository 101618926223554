import React, { useState, useRef } from "react"
import { useDispatch } from "@app/models"
import { Input } from "@app/components/Form"
import Icon from "@app/components/Icon"
import { useOnClickOutside } from "@app/utils/hooks"
import css from "./SearchBox.module.scss"

export default () => {
  const [inputable, setInputable] = useState(false)
  const [inputText, setInputText] = useState("")

  const searchBoxRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()

  useOnClickOutside(searchBoxRef, () => handleSearch())

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) handleSearch()
  }

  const handleSearchIconClick = () => {
    inputable ? handleSearch() : setInputable(true)
  }

  const handleSearch = () => {
    const text = inputText?.trim()

    setInputText(text)
    setInputable(Boolean(text))

    dispatch.customCalendars.updateCustomCalendarConditions({
      searchKeyword: text,
    })
  }

  const handleClear = () => {
    setInputText("")
    setInputable(false)

    dispatch.customCalendars.updateCustomCalendarConditions({
      searchKeyword: "",
    })
  }

  if (!inputable) {
    return (
      <Icon
        name="search"
        className={css.searchIcon}
        onClick={handleSearchIconClick}
      />
    )
  }

  return (
    <div className={css.searchBox} ref={searchBoxRef}>
      <Input
        autoFocus
        placeholder="Search jobs or providers"
        className={css.inputBox}
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        onKeyDown={handleInputKeyDown}
      />
      <div className={css.inputIcons}>
        {!!inputText && (
          <Icon
            name="close"
            title="Clear search"
            className={css.clearIcon}
            onClick={handleClear}
          />
        )}
        <Icon
          name="search"
          title="Search"
          className={css.searchIcon}
          onClick={handleSearch}
        />
      </div>
    </div>
  )
}
