import React, { useEffect, useState } from "react"
import { Select } from "@app/components/Form"
import css from "./EditRequest.module.scss"
import api from "@app/services/api"
import { formatDate } from "@app/utils"
import { Dialog } from "@app/components/Modal"

type Props = {
  req: any
  rotation: Rotation[] | undefined
  saveButton: number
  setSaveButton: any
  disableButtons: any
  setIsComplete: any
  setShowLoader: any
  requestTypes: any
  formValues: any
  setFormValues: any
}

export default ({
  req,
  rotation,
  saveButton,
  setSaveButton,
  disableButtons,
  setIsComplete,
  setShowLoader,
  requestTypes,
  formValues,
  setFormValues,
}: Props) => {
  let allRequestDays: PlainObjectType[] = []
  const [statusOptions, setStatusOptions] = useState([] as any)

  const setRequestStatuses = () => {
    let statuses = []
    if (req.status != "Provisional") {
      //don't show Pending status if request is alread set to Provisional
      statuses.push({ status: "Pending", id: "Pending" })
    }
    if (req.request_typeid >= 3) {
      //Provisional is only for dayoffs
      statuses.push({ status: "Provisional", id: "Provisional" })
    }
    statuses.push({ status: "Approved", id: "Approved" })
    statuses.push({ status: "Declined", id: "Declined" })
    setStatusOptions(statuses)
  }
  // initialize the formValues if this is the first time rendering this tab
  useEffect(() => {
    if (!String(formValues.requestid).length) {
      let tmpArray: string[] = []

      if (req.request_multi_vars != null) {
        tmpArray = String(req.request_multi_vars).split(",")

        allRequestDays = []
        for (let x = 0; x < tmpArray.length; x++) {
          allRequestDays.push({
            date: formatDate(tmpArray[x], "eeee, MMMM do, yyyy"),
            id: tmpArray[x],
          })
        }
        setRequestDaysMulti(true)
      }

      setFormValues({
        ...formValues,
        requestid: req.requestid,
        request_status: req.status,
        scheduler_comment: req.scomment,
        request_type: req.request_type,
        request_typeid: req.request_typeid,
        request_multi_dates: tmpArray,
        all_request_days: allRequestDays,
      })

      setRequestStatuses()
    }
  }, [])

  useEffect(() => {
    setRequestStatuses()
  }, [req])

  const handleChange = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }
  /////////////////////////////////////////////////////////////////////////////////////
  const [requestDaysMulti, setRequestDaysMulti] = useState(false)

  let requestDates: string
  let requestTypeDropdown: boolean = true
  let requestJob: string | undefined

  if (req.request_typeid <= 2) {
    //don't show dropdown if it's swap or preference request
    requestTypeDropdown = false
  }
  if (req.request_typeid == 2) {
    //preference
    requestJob = req.job_name
  }

  const [prunedRequestTypes, setPrunedRequestTypes] = useState([] as any)
  useEffect(() => {
    let prunedRequestTypes = Array()
    let ctlArray = Array()
    for (let x = 0; x < requestTypes.length; x++) {
      let request_typeid = requestTypes[x].request_typeid
      if (request_typeid <= 2) {
        //don't include swap or preference types
        continue
      }
      if (typeof ctlArray[requestTypes[x].request_typeid] === "undefined") {
        prunedRequestTypes.push(requestTypes[x])
      }
      ctlArray[request_typeid] = 1
    }

    prunedRequestTypes.sort(function (a: any, b: any) {
      if (a.request_type > b.request_type) {
        return 1
      }
      if (a.request_type < b.request_type) {
        return -1
      }
      return 0
    })
    setPrunedRequestTypes(prunedRequestTypes)
  }, [requestTypes])

  if (req.request_multi_vars) {
    useEffect(() => {
      setRequestDaysMulti(true)
    }, [])
  }
  requestDates = formatDate(req.start_date, "eeee, MMMM do, yyyy")
  if (req.end_date != req.start_date) {
    requestDates += " - " + formatDate(req.end_date, "eeee, MMMM do, yyyy")
  }

  const handleDatesSelect = (e: any) => {
    setFormValues({ ...formValues, request_multi_dates: e })
  }

  const handleStatusSelect = (e: string) => {
    setFormValues({ ...formValues, request_status: e })
  }
  const handleTypeSelect = (e: string) => {
    setFormValues({ ...formValues, request_type: e })
  }

  //handle the Save button click from the parent component
  useEffect(() => {
    if (saveButton == 0) {
      return //initialized
    }

    if (formValues.hasSubmitted == true) {
      return //don't submit twice
    }

    setFormValues({ ...formValues, hasSubmitted: true })
    setShowLoader(true)

    api
      .updateProviderRequest(
        formValues.request_status,
        req.requestid,
        formValues.scheduler_comment,
        formValues.request_multi_dates,
        formValues.request_type,
        formValues.override_request_count
      )
      .then(
        function (response) {
          //success
          if (response.error) {
            //handle issue
            setShowLoader(false)
            setFormValues({ ...formValues, hasSubmitted: false })
            confirmOverride(response.error)
          } else {
            setIsComplete(true)
            setShowLoader(false)
          }
        },
        function (reason) {
          //fail
          console.log("Error: " + reason.message)
        }
      )
  }, [saveButton])

  const confirmOverride = (message: string) => {
    Dialog.confirm({
      title: "Confirm Override",
      message: (
        <>
          <div>{message}</div>
        </>
      ),
      buttons: {
        ok: {
          text: "Override",
          variant: "danger",
          onClick() {
            setFormValues({ ...formValues, override_request_count: true })
            setSaveButton(saveButton + 1)
          },
        },
      },
    })
  }

  return (
    <>
      <div className={css.formLabel}>Request Status</div>
      <div className="select-container">
        <Select
          labelKey="status"
          valueKey="id"
          name="requestStatus"
          options={statusOptions}
          defaultValue={formValues.request_status}
          value={formValues.request_status}
          renderOption={(text: string, option: String) => (
            <div>
              <span>{text}</span>
            </div>
          )}
          onChange={handleStatusSelect}
          clearable={false}
        />
      </div>

      <div className={css.formLabel}>Scheduler Comment</div>
      <div className={css.textArea}>
        <textarea
          name="scheduler_comment"
          onChange={handleChange}
          defaultValue={formValues.scheduler_comment}
        ></textarea>
      </div>

      <div className={css.formLabel}>Request Type</div>
      {requestTypeDropdown == true && (
        <div className="select-container">
          <Select
            labelKey="request_type"
            valueKey="request_type"
            name="requestType"
            options={prunedRequestTypes}
            defaultValue={formValues.request_type}
            value={formValues.request_type}
            renderOption={(text: string, option: String) => (
              <div>
                <span>{text}</span>
              </div>
            )}
            onChange={handleTypeSelect}
            clearable={false}
          />
        </div>
      )}
      {requestTypeDropdown == false && (
        <div className={css.formValue}>{formValues.request_type}</div>
      )}

      {requestJob && (
        <>
          <div className={css.formLabel}>Job</div>
          <div className={css.formValue}>{requestJob}</div>
        </>
      )}

      {rotation && rotation.length !== 0 && (
        <>
          <div className={css.formLabel}>Rotations</div>
          <div className={css.formValue}>
            {rotation.map((rot: Rotation, index: number) => (
              <div key={index} className={css.rotationItem}>
                <span
                  className={css.rotationColor}
                  style={{ backgroundColor: rot.rotation_color }}
                ></span>
                <span className={css.rotationName}>{rot.rotation_name}</span>
                <span>
                  ({formatDate(rot.rotation_start_date, "MM/dd/yyyy")} -{" "}
                  {formatDate(rot.rotation_end_date, "MM/dd/yyyy")})
                </span>
              </div>
            ))}
          </div>
        </>
      )}

      <div className={css.formLabel}>Requested Days</div>
      {requestDaysMulti === false && (
        <div className={css.formValue}>{requestDates}</div>
      )}

      {req.detail_text.length > 0 && (
        <>
          <div className={css.formLabel}>Swap Detail</div>
          <div className={css.formValue}>{req.detail_text}</div>
        </>
      )}

      {requestDaysMulti === true && (
        <div>
          <Select
            multiple
            inline
            labelKey="date"
            valueKey="id"
            name="requestDates"
            // control={control}
            options={formValues.all_request_days}
            defaultValue={formValues.request_multi_dates?.map(
              (rd: string) => rd
            )}
            value={formValues.request_multi_dates?.map((sl: string) => sl)}
            renderOption={(text: string, option: String) => (
              <div>
                <span>{text}</span>
              </div>
            )}
            onChange={handleDatesSelect}
          />
        </div>
      )}

      <div className={css.formLabel}>Provider Comment</div>
      <div className={css.formValue}>{req.comment}</div>
    </>
  )
}
