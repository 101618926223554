import React from "react"
import css from "./HighlightChanges.module.scss"
import ToggleSwitch from "@app/components/Toggle/Toggle"
import { useDispatch, useSelector } from "@app/models"

export const HighlightChanges = () => {
  const dispatch = useDispatch()

  const { isHighlightedChangesActive } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const toggleSwitch = () => {
    dispatch.calendarEvents.setHighlightedChangesActive(
      !isHighlightedChangesActive
    )
  }

  return (
    <div className={css.container}>
      <div className={css.headerContainer}>
        <h2 className={css.header}>Highlight tracked changes</h2>
        <ToggleSwitch
          value={isHighlightedChangesActive}
          onToggle={toggleSwitch}
        />
      </div>
      <div>
        <p className={css.description}>
          Enable this option to highlight any assigments that were made with the
          "Track Changes" setting applied.
        </p>
      </div>
    </div>
  )
}
