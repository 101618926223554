import React from "react"
import { H3 } from "@app/components/Typography"
import api, { useRequest } from "@app/services/api"
import Button from "@app/components/Button"
import Card from "@app/components/Card"
import Table from "@app/components/Table"

export default () => {
  const { data } = useRequest([api.getGroupStaffLevels])

  return (
    <Card
      title={<H3 variant="blue">Staff Levels</H3>}
      action={
        <Button size="sm" variant="outline-secondary" to="/staff_levels/edit">
          Edit
        </Button>
      }
    >
      <Table
        data={data}
        loading={!data}
        scroll={{ y: 300 }}
        columns={[{ dataKey: "name", title: "Available Staff Levels" }]}
        emptyMessage="No staff levels have been created for this group. Please add a staff level."
      />
    </Card>
  )
}
