import * as React from 'react';
import { LoginStepEnum } from '../../utils/loginStepEnum';



// const ArrowLeftSvg = require("../../../images/arrow-left.svg") as string;

export interface ILoginUsernameInputProps {
    onInputChange: (x: React.FormEvent<HTMLInputElement>) => void,
    goBackToLoginCheck: () => void,
    loginStep: LoginStepEnum
    username: string
}

export default class LoginUsernameInput extends React.Component<ILoginUsernameInputProps> {
    public render() {
        return (
            <div>
                <div className="row padding-top">
                    {
                        this.props.loginStep === LoginStepEnum.LOGIN_CHECK ? (
                            <div className="col-xs-12 col-sm-6 col-sm-offset-3 user-input">
                                <input type="text" id="username" name="username" placeholder="Enter your username" value={this.props.username} onChange={this.props.onInputChange} />
                            </div>
                        ) : (
                                <div className="go-back-login-row">
                                    <div onClick={this.props.goBackToLoginCheck} aria-label="back to username" className="go-back-login-check">
                                        <span>
                                            {this.props.username}
                                        </span>
                                        <span className="back-arrow">
                                            <img src="../../../images/arrow-left.svg" />
                                        </span>
                                    </div>
                                    <input type="hidden" id="username" name="username" placeholder="Enter your username" value={this.props.username} onChange={this.props.onInputChange} />
                                </div>
                            )
                    }
                </div>
            </div>
        );
    }
}
