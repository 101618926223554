import React, { useState } from "react"
import Modal from "@app/components/Modal"
import {
  useForm,
  useFieldArray,
  FormItem,
  Input,
  Select,
} from "@app/components/Form"
import Button from "@app/components/Button"
import api, { useRequest } from "@app/services/api"
import { handleApiError, parseDate } from "@app/utils"

import cx from "classnames"
import css from "./RolloverModal.module.scss"

type Props = {
  rolloverAnnualSchedule: AnnualBlockScheduleType | null
  mutateAnuualSchedules: () => any
  onHide: () => any
}

type RolloverAnnualScheduleFields = {
  name: string
  start_year: number
  start_date: string
  end_date: string
  staff_level_mappings: {
    previous_staff_level: string
    previous_staff_level_id: string
    target_start_level: string
    target_start_level_id: string
  }[]
}

type RolloverAnnualScheduleCheck = {
  present_annual_block_schedule: AnnualBlockScheduleType | null
  blocks_count?: number
  residents_count?: number
  rotations_count?: number
  assignments_count?: number
  tallies_count?: number
}

export default (props: Props) => {
  const { rolloverAnnualSchedule, mutateAnuualSchedules, onHide } = props

  const [residentsCount, setResidentsCount] = useState<number | null>(null)
  const [override, setOverride] = useState<boolean>(false)

  const title = `Rollover ${rolloverAnnualSchedule?.name}`

  const { data: blockYears = [] } = useRequest<BlockYearType[]>([
    api.getBlockYears,
  ])

  const { data: rolloverAnnualScheduleCheck } =
    useRequest<RolloverAnnualScheduleCheck>(
      rolloverAnnualSchedule
        ? [api.rolloverAnnualScheduleCheck, rolloverAnnualSchedule.id]
        : null
    )

  const targetBlockYear = blockYears.find(
    (item: BlockYearType) =>
      rolloverAnnualSchedule &&
      item.start_year == rolloverAnnualSchedule.start_year + 1
  )

  const initStartDate = targetBlockYear && `${targetBlockYear.start_year}-07-01`
  const initEndDate =
    targetBlockYear && `${targetBlockYear.start_year + 1}-06-30`

  const { data: staffLevels = [] } = useRequest<StaffLevelType[]>([
    api.getGroupStaffLevels,
  ])

  const {
    control,
    reset: resetForm,
    watch,
    handleSubmit,
  } = useForm<RolloverAnnualScheduleFields>({
    schema: (yup) =>
      yup.lazy(() => {
        const baseShape = {
          name: yup.string().required().label("Annual Schedule Name"),
          start_date: yup
            .string()
            .required("Start Date is an invalid date")
            .test(dateValidate("start_date")),
          end_date: yup
            .string()
            .required("End Date is an invalid date")
            .test(dateValidate("end_date")),
        }

        return yup.object().shape(baseShape)
      }),
  })

  const currentStartYear: number = watch("start_year")

  const startYearRange = {
    start_date: currentStartYear,
    end_date: currentStartYear + 1,
  }

  const dateValidate = (field: "start_date" | "end_date") => {
    return {
      name: "withinYear",
      exclusive: true,
      params: { year: startYearRange[field] },
      message: "Date must be in the year of ${year}",
      test: (value: any) => !checkIfDisabledDays(field)(parseDate(value)),
    }
  }

  const checkIfDisabledDays = (field: "start_date" | "end_date") =>
    currentStartYear
      ? (date: any) => date.getFullYear() != startYearRange[field]
      : () => false

  const { fields: staffLevelMappingFields } = useFieldArray({
    control,
    name: "staff_level_mappings",
  })

  const onModalShow = () => {
    resetForm({
      start_date: initStartDate,
      end_date: initEndDate,
      staff_level_mappings: staffLevels.map((item) => ({
        previous_staff_level: item.name,
        previous_staff_level_id: item.id,
      })),
    })
  }

  const handleRolloverSubmit = (fields: RolloverAnnualScheduleFields) => {
    return api
      .rolloverAnnualSchedule(rolloverAnnualSchedule!.id, {
        ...fields,
        override,
      })
      .then(handleResult)
      .then(mutateAnuualSchedules)
      .catch(handleApiError)
  }

  const handleResult = (data: PlainObjectType) => {
    const { residents_count } = data
    setResidentsCount(residents_count)
  }

  const handleModalHide = () => {
    setOverride(false)
    setResidentsCount(null)
    resetForm()
    onHide()
  }

  const rolloverResult = (residentsCount: number) => (
    <div style={{ fontSize: "16px" }}>
      Your <strong>{rolloverAnnualSchedule?.name}</strong> calendar has been
      rolled over to <strong>{targetBlockYear?.display_name}</strong>.{" "}
      {residentsCount} residents were added to your{" "}
      <strong>{rolloverAnnualSchedule?.name}</strong> calendar.
    </div>
  )

  const rolloverCheck = () => {
    const {
      present_annual_block_schedule,
      blocks_count,
      residents_count,
      rotations_count,
      assignments_count,
      tallies_count,
    } = rolloverAnnualScheduleCheck || {}

    const currentYearDesc = present_annual_block_schedule
      ? `${present_annual_block_schedule.start_year - 1}-${
          present_annual_block_schedule.start_year
        }`
      : ""
    const targetYearDesc = present_annual_block_schedule
      ? `${present_annual_block_schedule.start_year}-${
          present_annual_block_schedule.start_year + 1
        }`
      : ""

    return (
      present_annual_block_schedule && (
        <>
          <p>
            The existing <strong>{targetYearDesc}</strong> schedule (
            <strong>{present_annual_block_schedule.name}</strong>) contains
          </p>
          <ul>
            <li>{blocks_count} blocks,</li>
            <li>{residents_count} residents with a staff level designation,</li>
            <li>{rotations_count} rotations, </li>
            <li>{assignments_count} rotation assignments, </li>
            <li>and {tallies_count} tallies. </li>
          </ul>
          <p>
            Are you sure that you want to delete this{" "}
            <strong>{targetYearDesc}</strong> annual schedule so you can proceed
            to roll over your <strong>{currentYearDesc}</strong> annual
            schedule?
          </p>
          <div className="mt-3 text-right">
            <Button
              size="sm"
              variant="light"
              className="mr-3"
              onClick={handleModalHide}
            >
              Cancel
            </Button>
            <Button size="sm" onClick={() => setOverride(true)}>
              Continue
            </Button>
          </div>
        </>
      )
    )
  }

  const rolloverForm = () => (
    <>
      <FormItem
        required
        layout={[4, 8]}
        label="Name"
        control={control}
        name="name"
      >
        <Input />
      </FormItem>
      <FormItem
        required
        layout={[4, 8]}
        control={control}
        label="Academic Year"
        name="start_year"
      >
        <Select
          loading={!targetBlockYear}
          options={targetBlockYear ? [targetBlockYear] : []}
          clearable={false}
          labelKey="display_name"
          valueKey="start_year"
          value={targetBlockYear?.start_year}
        />
      </FormItem>
      <FormItem
        required
        layout={[4, 8]}
        label="Start Date"
        control={control}
        name="start_date"
        defaultValue={initStartDate}
      >
        <Input
          type="date"
          dayPickerProps={{
            disabledDays: checkIfDisabledDays("start_date"),
          }}
        />
      </FormItem>
      <FormItem
        required
        layout={[4, 8]}
        label="End Date"
        control={control}
        name="end_date"
        defaultValue={initEndDate}
      >
        <Input
          type="date"
          dayPickerProps={{
            disabledDays: checkIfDisabledDays("end_date"),
          }}
        />
      </FormItem>
      <FormItem
        required
        className={cx(css.staffLevelMappingContainer)}
        layout={[4, 8]}
        label="Advance Staff Level"
        control={control}
        name="staff_level_mappings"
      >
        {staffLevelMappingFields.map((item, index) => (
          <div key={item.id} className={cx(css.staffLevelMapping)}>
            <Input
              readOnly={true}
              control={control}
              className="col-md-6"
              name={`staff_level_mappings.${index}.previous_staff_level`}
              value={item.previous_staff_level}
            />
            <Select
              className="col-md-6"
              loading={!staffLevels}
              control={control}
              name={`staff_level_mappings.${index}.target_staff_level_id`}
              valueKey="id"
              options={[...staffLevels, { id: "Graduate", name: "Graduate" }]}
              renderOption={(_, item) => <span>{item.name}</span>}
            />
          </div>
        ))}
      </FormItem>
      <div className="mt-3 text-right">
        <Button size="sm" onClick={handleSubmit(handleRolloverSubmit)}>
          Submit
        </Button>
      </div>
    </>
  )

  return (
    <Modal
      title={title}
      titleAlign="left"
      dialogClassName={cx(css.rolloverModal)}
      show={Boolean(rolloverAnnualSchedule)}
      onShow={onModalShow}
      onHide={handleModalHide}
    >
      {!rolloverAnnualScheduleCheck ? (
        <div>Loading...</div>
      ) : rolloverAnnualScheduleCheck.present_annual_block_schedule &&
        !override ? (
        rolloverCheck()
      ) : residentsCount ? (
        rolloverResult(residentsCount)
      ) : (
        rolloverForm()
      )}
    </Modal>
  )
}
