import React from "react"
import Card from "@app/components/Card"
import Table, { ColumnType } from "@app/components/Table"
import Button from "@app/components/Button"
import { H3 } from "@app/components/Typography"
import JobRolesCell from "@app/containers/jobs/IndexPage/components/JobRolesCell"
import api, { useRequest } from "@app/services/api"
import { parseDate, formatDate } from "@app/utils"

type Props = {
  jobTypeId: number
  jobTypeName: string
  exportJobType: any
}

export default (props: Props) => {
  const { jobTypeId, jobTypeName, exportJobType } = props
  const { data } = useRequest<JobType[]>([api.getJobs, jobTypeId])

  const renderTime = (time: string) => {
    if (!time) {
      return
    }

    const date = parseDate(time, (f) => f.timeOnly)
    return formatDate(date, (f) => f.hourMinute12h)
  }

  const exportJobs = (jobTypeId: number) => {
    exportJobType(jobTypeId)
  }

  const columns: ColumnType<JobType>[] = [
    { title: "Name", dataKey: "name" },
    { title: "Abbrev", dataKey: "abbrev" },
    { title: "Priority", dataKey: "priority" },
    { title: "Start", dataKey: "starttime", render: renderTime },
    { title: "End", dataKey: "endtime", render: renderTime },
    {
      title: "Tigerconnect Roles",
      dataKey: "tigerconnect_roles",
      width: 250,
      render: (roles, job) => <JobRolesCell job={job} />,
    },
  ]

  return (
    <Card
      title={<H3 variant="blue">{jobTypeName} Jobs</H3>}
      action={
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => exportJobs(jobTypeId)}
        >
          Export CSV
        </Button>
      }
    >
      <Table
        columns={columns}
        data={data}
        rowKey="jobid"
        loading={!data}
        emptyMessage={`No ${jobTypeName} have been created for this group.`}
      />
    </Card>
  )
}
