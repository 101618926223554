import React, { useState, useImperativeHandle } from "react"
import ReactDOM from "react-dom"
import Icon from "@app/components/Icon"
import { H2, H4 } from "@app/components/Typography"
import Modal, { ButtonProps } from "./Modal"
import merge from "lodash/merge"
import cx from "classnames"
import css from "./Dialog.module.scss"

type ButtonType = "ok" | "cancel"

type Props = {
  title?: string
  iconName?: string
  message?: string | JSX.Element
  buttons?: Partial<Record<ButtonType, ButtonProps | object>>
  className?: string
  defaultShow?: boolean
  size?: React.ComponentProps<typeof Modal>["size"]
  onHide?: (status?: boolean) => void
  escKeyClose?: boolean
  style?: React.CSSProperties
}

const DialogModal = React.forwardRef<any, Props>((props, ref) => {
  const [dialogShow, updateDialogShow] = useState<boolean>(
    props.defaultShow || false
  )
  const [dialogConfig, updateDialogConfig] = useState<Props>({})
  const {
    title,
    iconName,
    message,
    buttons,
    className,
    defaultShow,
    onHide,
    ...rest
  } = {
    ...props,
    ...dialogConfig,
  }

  useImperativeHandle(ref, () => ({
    showDialog,
    hideDialog: () => handleDialogHide(false),
  }))

  const showDialog = (config: Props = {}) => {
    updateDialogShow(true)
    updateDialogConfig(config)
  }

  const handleDialogHide = (status: boolean) => {
    updateDialogShow(status)
    onHide?.()
  }

  const okButton = { text: "OK", ...buttons?.ok }
  const cancelButton = {
    text: "Cancel",
    variant: "light",
    ...buttons?.cancel,
  }
  const dialogButtons = buttons?.cancel
    ? [cancelButton, okButton]
    : buttons?.ok
    ? [okButton]
    : []

  return (
    <Modal
      show={dialogShow}
      onHide={handleDialogHide}
      className={cx(css.dialog, className)}
      buttons={dialogButtons}
      closeButton={false}
      title={
        <>
          {title && <H2 bold>{title}</H2>}
          {iconName && (
            <div className={css.dialogIcon}>
              <Icon name={iconName} />
            </div>
          )}
        </>
      }
      {...rest}
    >
      <H4 className="p-3">{message}</H4>
    </Modal>
  )
})

type WithDialogProps = Props & Partial<Record<ButtonType, Function>>
type DialogType = typeof DialogModal &
  Record<"confirm" | "warn" | "success", (props: WithDialogProps) => any>

const Dialog = DialogModal as DialogType
const withDialog =
  (defaultProps?: Props) =>
  (props: WithDialogProps = {}) => {
    const dialogProps = {}
    const { ok, cancel, ...rest } = props

    merge(dialogProps, defaultProps, rest, {
      buttons: {
        ok: ok && { onClick: ok },
        cancel: cancel && { onClick: cancel },
      },
    })

    const dialogContainer = document.createElement("div")
    document.body.appendChild(dialogContainer)

    const destroyDialogContainer = () => {
      ReactDOM.unmountComponentAtNode(dialogContainer)
      if (dialogContainer.parentNode) {
        dialogContainer.parentNode.removeChild(dialogContainer)
      }
    }

    ReactDOM.render(
      <Dialog defaultShow onHide={destroyDialogContainer} {...dialogProps} />,
      dialogContainer
    )
  }

Dialog.confirm = withDialog({
  buttons: { ok: {}, cancel: {} },
})
Dialog.warn = withDialog({
  buttons: { ok: {} },
})
Dialog.success = withDialog({
  buttons: { ok: {} },
})

export default Dialog
