import React, { useState } from "react"
import api, { useRequest, mutate } from "@app/services/api"
import BlockSchedulingForm from "@app/containers/layouts/BlockSchedulingForm"
import { Select, useForm } from "@app/components/Form"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import { Text } from "@app/components/Typography"
import CreateOrUpdateStaffLevel from "./components/CreateOrUpdateStaffLevel"
import { handleApiError } from "@app/utils"
import css from "./EditStaffLevels.module.scss"
import cx from "classnames"

type FormFieldsType = {
  staffLevelIds: string[]
}

export default () => {
  const [editingStaffLevel, setEditingStaffLevel] = useState<
    StaffLevelType | PlainObjectType | undefined
  >()

  const { control, handleSubmit } = useForm<FormFieldsType>()
  const { data: allStaffLevels } = useRequest<StaffLevelType[]>([
    api.getStaffLevels,
  ])
  const { data: groupStaffLevels } = useRequest<StaffLevelType[]>([
    api.getGroupStaffLevels,
  ])

  const onSubmit = (fields: FormFieldsType, cb: any) => {
    const postParams = fields.staffLevelIds.map((id) => ({
      staff_level_id: id,
    }))

    return api.postGroupStaffLevels(postParams).then(() => {
      mutate([api.getGroupStaffLevels])
      cb()
    }, handleApiError)
  }

  const loading = !allStaffLevels || !groupStaffLevels

  return (
    <BlockSchedulingForm
      title="Staff Levels"
      onSubmit={handleSubmit(onSubmit)}
      formControl={control}
    >
      <CreateOrUpdateStaffLevel
        show={!!editingStaffLevel}
        staffLevel={editingStaffLevel}
        onHide={() => setEditingStaffLevel(undefined)}
      />
      <div className={cx(css.addCustomStaffLevel, "mb-2")}>
        <Button
          shape="circle"
          variant="outline-secondary"
          className={css.addCustomStaffLevelIcon}
          onClick={() => setEditingStaffLevel({})}
        >
          <Icon name="plus" />
        </Button>
        <Text variant="grey" className="ml-2">
          Add Custom
        </Text>
      </div>
      <Select
        multiple
        inline
        name="staffLevelIds"
        control={control}
        loading={loading}
        options={allStaffLevels || []}
        defaultValue={groupStaffLevels?.map((sl) => sl.id)}
        renderOption={(text: string, option: StaffLevelType) => (
          <div>
            <span>{text}</span>
            {!option.is_system && (
              <span
                className={cx("ml-2", css.editCustomStaffLevel)}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setEditingStaffLevel(option)
                }}
              >
                <Icon hoverable name="pencil" />
              </span>
            )}
          </div>
        )}
      />
    </BlockSchedulingForm>
  )
}
