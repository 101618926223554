import React, { useContext, useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import {
  IManualScheduleJobItem,
  IProviderInfo,
  ISplitShiftItem,
  ITimePair,
} from "../../data"
import { v4 as uuidv4 } from "uuid"
import SplitShiftConfigBoxRow from "./SplitShiftConfigBoxRow"
import MGrid from "@material-ui/core/Grid"
import {
  getTimeStamp,
  removeDelFlag,
  sortSplitShiftListByStartTime,
} from "../../service"
import { addDays } from "date-fns"
import { parseDate, formatDate } from "@app/utils"

export interface ISplitShiftConfigBoxProps {
  providers: IProviderInfo[]
}

const SplitShiftConfigBox: React.FC<ISplitShiftConfigBoxProps> = (props) => {
  const {
    currentSplitShiftJobId,
    setCurrentSplitShiftJobId,
    jobs,
    setJobs,
    edate,
    setSplitShiftHasChanged,
  } = useContext(ManualScheduleTableContext)

  const [currentSplitShiftList, setCurrentSplitShiftList] = useState<
    ISplitShiftItem[]
  >([])
  const [rerender, setState] = useState(0)
  const forceUpdate = () => setState(rerender + 1)

  const getSplitShifts = () => {
    const job = jobs.find((j) => j.jobid == currentSplitShiftJobId)
    if (job && job.splitShifts) {
      return job.splitShifts.map((x) => {
        return { ...x, is_new: 0 }
      })
    } else {
      return []
    }
  }

  useEffect(() => {
    document
      .querySelectorAll(".schedule-button")
      .forEach((x) => ((x as HTMLButtonElement).disabled = true))
    return () => {
      document
        .querySelectorAll(".schedule-button")
        .forEach((x) => ((x as HTMLButtonElement).disabled = false))
    }
  }, [])

  useEffect(() => {
    let shifts = getSplitShifts()
    const job = jobs.find((j) => currentSplitShiftJobId == j.jobid)
    if (shifts.length == 0) {
      setCurrentSplitShiftList([
        {
          ...initSplitShift(),
          starttime: job?.starttime,
          endtime: job?.endtime,
          tally_credit: 1,
          providerid: job?.providerid ?? undefined,
        },
      ])
    } else {
      setCurrentSplitShiftList(shifts)
    }
  }, [currentSplitShiftJobId])

  const initSplitShift = () => {
    return {
      starttime: "",
      endtime: "",
      split_shiftid: uuidv4(),
      tally_credit: "",
      providerid: undefined,
      edate: edate,
      is_new: 1,
      has_changed: 0,
      job_edate: "",
      delete_flag: false,
    }
  }

  const handleOnChange = async (
    index: number,
    propname: "starttime" | "endtime" | "tally_credit" | "providerid",
    value: any
  ) => {
    let splitShift: ISplitShiftItem
    let newList = [...currentSplitShiftList]

    const job = jobs.find((j) => j.jobid == currentSplitShiftJobId)
    const change = { index, propname, value }

    splitShift =
      index < newList.length ? { ...newList[index] } : initSplitShift()
    splitShift[propname] = value
    if (currentSplitShiftList.length <= index) {
      newList = [...newList, splitShift]
    } else {
      if (currentSplitShiftList[index][propname] != value) {
        splitShift.has_changed = 1
      }
    }
    handleDelFlagsByPropAndIndex(propname, index, splitShift)
    newList[index] = splitShift
    newList = parseForTimeUpdates([...newList], job, change)
    splitShift[propname] = value
    newList[index] = splitShift
    setCurrentSplitShiftList([...newList])
    forceUpdate()
  }

  const parseForTimeUpdates = (
    shiftList: ISplitShiftItem[],
    job: any,
    change: any
  ): ISplitShiftItem[] => {
    if (
      change.index < 3 &&
      !shiftList[change.index + 1] &&
      change.propname === "endtime" &&
      shiftList[change.index]?.endtime !== job.endtime
    ) {
      shiftList.push(initSplitShift())
    }
    return shiftList.map((shift, index) => {
      if (
        index === 0 &&
        change.propname === "starttime" &&
        shift.starttime !== job.starttime
      ) {
        shift.starttime = job.starttime
        shift.has_changed = 1
        alert("The first split shift must start at the same time as the job")
      }
      if (
        change.propname === "endtime" &&
        index === change.index + 1 &&
        shift.starttime != change.value
      ) {
        shift.starttime = change.value
        shift.has_changed = 1
      }
      if (
        shift.endtime &&
        index > 0 &&
        index < shiftList.length &&
        shift.endtime != job.endtime
      ) {
        if (shift.starttime != shiftList[index - 1].endtime) {
          shift.starttime = shiftList[index - 1].endtime
          shift.has_changed = 1
        }
        if (
          index + 1 < shiftList.length &&
          index < 3 &&
          shiftList[index + 1].starttime &&
          shift.endtime != shiftList[index + 1].starttime
        ) {
          shift.endtime = shiftList[index + 1].starttime
          shift.has_changed = 1
        }
      }
      if (
        index + 1 === shiftList.length &&
        !shiftList.some((shift) => shift.endtime == job.endtime) &&
        shift.endtime != job.endtime
      ) {
        shift.endtime = job.endtime
        shift.has_changed = 1
      }

      return shift
    })
  }

  const handleDelFlagsByPropAndIndex = async (
    propname: string,
    index: number,
    splitShift: ISplitShiftItem
  ) => {
    if (propname == "providerid" && splitShift.delete_flag) {
      await removeDelFlag(splitShift.split_shiftid as string)
      splitShift.delete_flag = false
      removeDelFlagInJobs(index)
    }
  }

  const handleRemoveDelFlag = async (index: number) => {
    if (currentSplitShiftList.length > index) {
      let splitShift = currentSplitShiftList[index]
      if (splitShift.delete_flag && splitShift.split_shiftid) {
        await removeDelFlag(splitShift.split_shiftid as string)
        setCurrentSplitShiftList([
          ...currentSplitShiftList.slice(0, index),
          { ...splitShift, delete_flag: false },
          ...currentSplitShiftList.slice(index + 1),
        ])
        removeDelFlagInJobs(index)
      }
    }
  }

  const removeDelFlagInJobs = (index: number) => {
    const jobIndex = jobs.findIndex((m) => m.jobid == currentSplitShiftJobId)
    let updJob = { ...jobs[jobIndex] }
    updJob.splitShifts[index].delete_flag = false
    setJobs([...jobs.slice(0, jobIndex), updJob, ...jobs.slice(jobIndex + 1)])
  }

  const handleSave = (
    currSplitShiftList: ISplitShiftItem[],
    currEdate: string
  ) => {
    if (validate(currSplitShiftList)) {
      setSplitShiftHasChanged(true)
      const index = jobs.findIndex((m) => m.jobid == currentSplitShiftJobId)
      let edateUpdList = currentSplitShiftList.map((s) => {
        if (
          getTimeStamp(s.starttime as string) <
          getTimeStamp(jobs[index].starttime)
        ) {
          return { ...s, edate: formatDate(addDays(parseDate(currEdate), 1)) }
        } else {
          return { ...s, edate: currEdate }
        }
      })
      edateUpdList = sortSplitShiftListByStartTime(edateUpdList)
      let updJob = { ...jobs[index], ...{ splitShifts: edateUpdList } }
      if (edateUpdList.length == 0) {
        updJob = { ...updJob, removeSplitShiftListFlag: true }
      }
      setJobs([...jobs.slice(0, index), updJob, ...jobs.slice(index + 1)])
      setCurrentSplitShiftJobId(null)
    }
  }

  const handleClearRow = (index: number) => {
    setCurrentSplitShiftList([
      ...currentSplitShiftList.slice(0, index),
      ...currentSplitShiftList.slice(index + 1),
    ])
  }

  const handleCancel = () => {
    setCurrentSplitShiftJobId(null)
  }

  const validate = (splList: ISplitShiftItem[]) => {
    if (splList.length == 0) {
      return true
    }
    if (splList.length == 1) {
      alert(`You cannot save a split shift with only one entry.`)
      return false
    }
    let res = true
    for (let index = 0; index < splList.length; index++) {
      const s = splList[index]
      if (!validateRow(s)) {
        alert(
          `Please enter all required information for Split Shift #${index + 1}`
        )
        return false
      }
    }
    if (!validateTally(splList)) {
      alert("Tally total for all splits must equal 1.0")
      res = false
    }
    if (!validateTimeGapAndSameUser(splList)) {
      res = false
    }
    return res
  }

  const validateRow = (splitShift: ISplitShiftItem): boolean => {
    return (
      !!splitShift.starttime &&
      !!splitShift.endtime &&
      !!splitShift.providerid &&
      splitShift.tally_credit !== ""
    )
  }

  const validateTally = (splList: ISplitShiftItem[]) => {
    let sum = 0
    splList.forEach((s) =>
      s.tally_credit ? (sum += s.tally_credit as number) : null
    )
    return sum == 1
  }

  const validateTimeGapAndSameUser = (splList: ISplitShiftItem[]) => {
    const job = jobs.find(
      (j) => j.jobid == currentSplitShiftJobId
    ) as IManualScheduleJobItem
    let timePairs: ITimePair[] = []

    const getNextDayTimeStamp = (time?: string) =>
      time &&
      addDays(
        parseDate(time, (f) => f.timeOnly),
        1
      ).valueOf()
    const baseStart = getTimeStamp(job.starttime)
    const baseEnd =
      getTimeStamp(job.endtime) > baseStart
        ? getTimeStamp(job.endtime)
        : getNextDayTimeStamp(job.endtime)

    // Make SplitShift time pair list
    splList.forEach((s) => {
      let start =
        (s.starttime as string) >= job.starttime
          ? getTimeStamp(s.starttime as string)
          : getNextDayTimeStamp(s.starttime)
      let end =
        (s.endtime as string) > job.starttime
          ? getTimeStamp(s.endtime as string)
          : getNextDayTimeStamp(s.endtime)

      if (start && end)
        timePairs.push({ start, end, providerid: s.providerid as number })
    })

    // Sort TimePair List
    timePairs.sort((a, b) => a.start - b.start)

    // walk through
    let timePt = baseStart
    let providerPt = -1
    for (let i = 0; i < timePairs.length; i++) {
      if (
        timePt != timePairs[i].start ||
        timePairs[i].start >= timePairs[i].end
      ) {
        alert(
          "There cannot be any gaps or overlapping splits in the split start and end times"
        )
        return false
      } else {
        if (providerPt == timePairs[i].providerid) {
          alert("The same user cannot be assigned to two consecutive splits")
          return false
        } else {
          providerPt = timePairs[i].providerid
          timePt = timePairs[i].end
        }
      }
    }
    if (timePt != baseEnd) {
      alert(
        "There cannot be any gaps or overlapping splits in the split start and end times"
      )
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <Form>
        <Container
          className="container"
          style={{
            background: "#F0F2F2",
            width: "100%",
            height: "auto",
            borderRadius: "6px",
          }}
        >
          {[...new Array(4).keys()].map((i) => {
            return (
              <SplitShiftConfigBoxRow
                rerender={rerender}
                key={i}
                index={i}
                isActive={i <= currentSplitShiftList.length}
                handleRemoveDelFlag={async () => await handleRemoveDelFlag(i)}
                handleOnChange={async (
                  propname:
                    | "starttime"
                    | "endtime"
                    | "tally_credit"
                    | "providerid",
                  e: any
                ) => await handleOnChange(i, propname, e)}
                rowData={currentSplitShiftList[i]}
                handleClearRow={() => handleClearRow(i)}
                providers={props.providers}
              />
            )
          })}
          <MGrid
            container
            direction="row-reverse"
            wrap="nowrap"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            style={{ padding: "16px 66px 16px 32px" }}
          >
            <MGrid item>
              <Button
                type="button"
                onClick={() => handleSave(currentSplitShiftList, edate)}
                style={{ background: "#4A657B", color: "#FFFFFF", width: 104 }}
              >
                Save
              </Button>
            </MGrid>
            <MGrid item>
              <Button
                type="button"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#9EB2BD",
                  border: "0.9114px solid #9EB2BD",
                  width: 104,
                }}
              >
                Cancel
              </Button>
            </MGrid>
          </MGrid>
        </Container>
      </Form>
    </>
  )
}

export default SplitShiftConfigBox
