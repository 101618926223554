import React, { useState, useEffect } from "react"
import { Select } from "@app/components/Form"
import { H4 } from "@app/components/Typography"
import api from "@app/services/api"
import Button from "@app/components/Button"
import ApplyDailyAssignmentHeader from "../ApplyDailyAssignmentHeader"
import { formatDate } from "@app/utils"
import css from "./UnapplyDailyAssignments.module.scss"

type PropsType = {
  additionalAssignmentEvents: AdditionalEventAssignmentType[]
  events: EventType[]
  closeModal: () => void
  appliedTemplateId: string
  deleteRotation: () => void
  providerName: string
  dateRange: string
  rotation: BlockScheduleRotationType | undefined
  templateName: string
  modalEditting: boolean
  setTemplateAssignmentsToDelete: (data: SelectedAssignments) => void
  goToNextPage: () => void
}

type SelectedAssignments = {
  additional_assignment_ids: string[]
  eventids: number[]
  appliedTemplateId: string
}

type SelectOptionsType = {
  label: string
  value: string
}[]

export default (props: PropsType) => {
  const {
    additionalAssignmentEvents,
    events,
    appliedTemplateId,
    closeModal,
    deleteRotation,
    providerName,
    dateRange,
    rotation,
    templateName,
    setTemplateAssignmentsToDelete,
    modalEditting,
    goToNextPage,
  } = props
  const [optionsSelected, setOptionsSelected] = useState<string[]>([])

  useEffect(() => {
    const options = getOptionsFromAppliedTemplate()

    const values = options.map((option) => {
      return option.value
    })

    setOptionsSelected(values)
  }, [additionalAssignmentEvents, events])

  const getOptionsFromAppliedTemplate = () => {
    const additionalAssignmentOptions = additionalAssignmentEvents.reduce(
      (
        options: SelectOptionsType,
        additionalEventAssignment: AdditionalEventAssignmentType
      ) => {
        options = [
          ...options,
          {
            label: `${formatDate(
              additionalEventAssignment?.event?.edate as Date,
              (f) => f.shortSlashWithDayOfWeek
            )} ${additionalEventAssignment?.event?.job?.name}`,
            value: `additional_assignment_event ${additionalEventAssignment.id}`,
          },
        ]
        return options
      },
      []
    )

    const eventsOptions = events.reduce(
      (options: SelectOptionsType, event: EventType) => {
        options = [
          ...options,
          {
            label: `${formatDate(
              event.edate,
              (f) => f.shortSlashWithDayOfWeek
            )} ${event?.job?.name}`,
            value: `event ${event.eventid}`,
          },
        ]

        return options
      },
      []
    )

    return [...additionalAssignmentOptions, ...eventsOptions]
  }

  const formatAssignmentsForDeletion = () => {
    const eventids = optionsSelected
      .filter((option) => option.split(" ")[0] === "event")
      .map((option) => {
        return Number(option.split(" ")[1])
      })
    const additionalAssignmentIds = optionsSelected
      .filter(
        (option) => option.split(" ")[0] === "additional_assignment_event"
      )
      .map((option) => {
        return option.split(" ")[1]
      })

    return {
      additional_assignment_ids: additionalAssignmentIds,
      eventids,
    }
  }

  const submitAssignmentsForDeletion = () => {
    api
      .deleteEventsFromAppliedTemplate(
        appliedTemplateId,
        formatAssignmentsForDeletion()
      )
      .then((res) => {
        deleteRotation()
      })
      .catch((err) => console.log(err))
  }

  const deleteOrGoToNextPage = () => {
    if (modalEditting) {
      const assignmentsForDeletion = formatAssignmentsForDeletion()
      setTemplateAssignmentsToDelete({
        ...assignmentsForDeletion,
        appliedTemplateId,
      })
      goToNextPage()
    } else {
      submitAssignmentsForDeletion()
    }
  }

  return (
    <>
      <ApplyDailyAssignmentHeader
        providerName={providerName}
        dateRange={dateRange}
        rotation={rotation}
        templateName={templateName}
      />
      <H4 bold className="mt-5">
        Daily Assignments Selected: {optionsSelected.length}
      </H4>
      <Select
        className={css.selectList}
        multiple
        inline
        options={getOptionsFromAppliedTemplate()}
        value={optionsSelected}
        valueKey={"value"}
        labelKey={"label"}
        onChange={(values) => setOptionsSelected(values)}
      />
      <div className="d-flex flex-row justify-content-between">
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteOrGoToNextPage}>
          Delete
        </Button>
      </div>
    </>
  )
}
