import React, { useReducer } from "react"
import Button from "@app/components/Button"
import Loader from "@app/components/Loader"
import Table from "@app/components/Table"
import api from "@app/services/api"
import css from "./EditRequestStatusMulti.module.scss"

const EditRequestStatusMulti: React.FC<any> = (props): JSX.Element => {
  const initialState = {
    requestids: props.requestids,
    statusType: "undefined",
    showLoader: false,
    currentView: "form",
    updateBtnDisabled: true,
    errorCount: 0,
    successfulRequests: [],
    tableData: [],
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "statusTypeChanged":
        return { ...state, statusType: action.payload }
      case "toggleShowLoader":
        return { ...state, showLoader: !state.showLoader }
      case "setSuccessfulRequests":
        return { ...state, successfulRequests: action.payload }
      case "showResultView":
        return { ...state, currentView: "results", tableData: action.payload }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const submit = (): void => {
    dispatch({ type: "toggleShowLoader" })
    api.updateProviderRequestMultiple(state.statusType, state.requestids).then(
      function (response) {
        //success
        if (response.request_failures.length > 0) {
          for (var x = 0; x < response.request_failures.length; x++) {
            //add an id property to the array to keep the react-table from complaining
            response.request_failures[x].id =
              response.request_failures[x].requestid
          }
        }
        dispatch({
          type: "setSuccessfulRequests",
          payload: response.successful_requests,
        })
        dispatch({ type: "showResultView", payload: response.request_failures })
      },
      function (reason) {
        //fail
        console.log("Error: " + reason.message)
        dispatch({ type: "toggleShowLoader" })
      }
    )
  }

  const requestStatusChanged = (status: string): void => {
    if (status == "undefined") {
      state.updateBtnDisabled = true
    } else {
      state.updateBtnDisabled = false
    }
    dispatch({ type: "statusTypeChanged", payload: status })
  }

  if (state.currentView == "form") {
    return (
      <>
        <h1>Update Status ({state.requestids.length})</h1>
        <div className={css.center_align}>
          <h2>Request Status</h2>
          <div className="select-container">
            <select onChange={(e) => requestStatusChanged(e.target.value)}>
              <option value="undefined">Choose Request Status</option>
              <option value="Approved">Approved</option>
              <option value="Provisional">Provisional</option>
              <option value="Declined">Declined</option>
            </select>
          </div>
        </div>
        {state.showLoader && (
          <div style={{ textAlign: "center", width: "100%" }}>
            <Loader animation="dots" />
          </div>
        )}
        {!state.showLoader && (
          <div className={css.center_align}>
            <Button className="btn btn-light" id="react-cancel-btn">
              Cancel
            </Button>{" "}
            &nbsp;
            <Button
              onClick={submit}
              className="btn btn-primary"
              id="react-update-btn"
              disabled={state.updateBtnDisabled}
            >
              Update
            </Button>
          </div>
        )}
      </>
    )
  }
  if (state.currentView == "results") {
    return (
      <>
        <div className={css.center_align}>
          <h1>Request Status</h1>
        </div>
        <div className={css.center_align}>
          <h2>
            The status has been updated to {state.statusType} for (
            {state.successfulRequests.length}) provider requests.
          </h2>
        </div>

        {state.tableData.length > 0 && (
          <div className={css.center_align}>
            <h2>
              There was an error updating the status of (
              {state.tableData.length}) requests.
            </h2>
            <Table
              data={state.tableData}
              columns={[
                {
                  title: "Provider",
                  dataKey: "provider_display_name",
                  align: "left",
                },
                { title: "Type", dataKey: "request_type", align: "left" },
                { title: "Start Date", dataKey: "start_date", align: "left" },
                { title: "End Date", dataKey: "end_date", align: "left" },
              ]}
            />
          </div>
        )}
        <div className={css.center_align}>
          <Button className="btn btn-primary" id="react-close-btn">
            Close
          </Button>
        </div>
      </>
    )
  }
  return <div></div>
}

export default EditRequestStatusMulti
