import React from "react"
import { Container } from "@app/components/Layout"
import css from "./Base.module.scss"
import cx from "classnames"

type Props = {
  className?: string
  containerSize?: Required<React.ComponentProps<typeof Container>>["size"]
}

export default ((props) => {
  const { containerSize, className, children } = props

  return (
    <Container size={containerSize} className="bootstrap4">
      <div className={cx(css.wrapper, className)}>{children}</div>
    </Container>
  )
}) as React.FC<Props>
