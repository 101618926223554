import React from "react"
import Badge from "react-bootstrap/Badge"
import cx from "classnames"
import css from "./Tag.module.scss"

type Props = {
  variant?: string
  className?: string
}

export default ((props) => {
  const { variant, children, className } = props
  return (
    <Badge pill variant={variant} className={cx(css.tag, className)}>
      {children}
    </Badge>
  )
}) as React.FC<Props>
