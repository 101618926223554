import React, { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "@app/models"
import { Select } from "@app/components/Form"
import { H5 } from "@app/components/Typography"
import Button from "@app/components/Button"
import Modal, { ButtonProps } from "@app/components/Modal"
import { QuickAssignmentContext } from "../../../context/QuickAssignmentContext"
import api, { useRequest } from "@app/services/api"

type Props = {
  annualBlockScheduleId: string
}

export default (props: Props) => {
  const { annualBlockScheduleId: id } = props

  const dispatch = useDispatch()

  const { quickAssignmentRotation, quickAssignmentOrHighlight } = useSelector(
    (state) => state.rotationOperations
  )

  const {
    quickAssignmentPage,
    setQuickAssignmentPage,
    quickAssignmentTemplate,
    setQuickAssignmentTemplate,
    clearQuickAssignTemplateData,
  } = useContext(QuickAssignmentContext)

  const quickAssignmentShow = () =>
    quickAssignmentOrHighlight == "QuickAssignment"

  const onModalClose = () => {
    dispatch.rotationOperations.updateRotationOperations({
      quickAssignmentOrHighlight: undefined,
    })
  }

  const showModal = () => {
    dispatch.rotationOperations.updateRotationOperations({
      quickAssignmentOrHighlight: "QuickAssignment",
    })
  }

  const clearQuickAssignmentShow = () => {
    dispatch.rotationOperations.updateRotationOperations({
      quickAssignmentRotation: undefined,
      quickAssignmentOrHighlight: undefined,
    })

    setQuickAssignmentTemplate(undefined)
    clearQuickAssignTemplateData()
  }

  useEffect(() => {
    if (!quickAssignmentShow()) {
      setQuickAssignmentPage("rotation")
    }
  }, [quickAssignmentOrHighlight])

  useEffect(() => {
    if (quickAssignmentShow()) {
      dispatch.rotationOperations.updateRotationOperations({
        highlightRotation: [],
      })
    }
  }, [quickAssignmentRotation])

  const { data: annualRotations = [] } = useRequest<
    AnnualBlockScheduleRotationType[]
  >([api.getAnnualBlockScheduleRotations, id])

  const { data: annualTemplates = [] } = useRequest<
    AnnualBlockScheduleTemplateType[]
  >([api.getTemplates, id])

  const getApplicableTemplates = () => {
    return annualTemplates.filter(
      (template: AnnualBlockScheduleTemplateType) =>
        !!template.rotations?.filter(
          (rotation: AnnualBlockScheduleRotationType) =>
            rotation.id === quickAssignmentRotation
        ).length
    )
  }

  useEffect(() => {
    if (quickAssignmentPage === "template") {
      const applicableTemplates = getApplicableTemplates()

      if (!applicableTemplates.length) {
        dispatch.rotationOperations.updateRotationOperations({
          quickAssignmentOrHighlight: undefined,
        })
        setQuickAssignmentTemplate(undefined)
      }
    }
  }, [quickAssignmentPage])

  const rotationLabel = () => {
    const rotation = annualRotations.find(
      (item) => item.id == quickAssignmentRotation
    )

    return rotation ? (
      <H5 ellipsis prefixDotColor={rotation.block_schedule_rotation.color}>
        {rotation.block_schedule_rotation.name}
      </H5>
    ) : (
      "Rotations"
    )
  }

  const selectQuickAssignmentRotation = (val?: string) => {
    if (val !== quickAssignmentRotation) {
      setQuickAssignmentPage("template")
    }
    dispatch.rotationOperations.updateRotationOperations({
      quickAssignmentRotation: val,
    })
  }

  const selectQuickAssignmentTemplate = (val?: string) => {
    const selectedTemplate = annualTemplates.filter(
      (template) => template.id === val
    )[0]
    setQuickAssignmentTemplate(selectedTemplate)
    if (val !== quickAssignmentTemplate) {
      dispatch.rotationOperations.updateRotationOperations({
        quickAssignmentOrHighlight: undefined,
      })
    }
  }

  const getModalButtons = () => {
    const buttons: ButtonProps[] = []
    if (quickAssignmentPage === "rotation") {
      buttons.push({
        text: "Clear",
        variant: "outline-primary",
        onClick: clearQuickAssignmentShow,
      })
    } else if (quickAssignmentPage === "template") {
      buttons.push(
        {
          text: "Clear",
          variant: "outline-primary",
          onClick: clearQuickAssignmentShow,
        },
        {
          text: "Close",
          variant: "primary",
          onClick: () =>
            dispatch.rotationOperations.updateRotationOperations({
              quickAssignmentOrHighlight: undefined,
            }),
        }
      )
    }

    return buttons
  }

  return (
    <>
      <Button shape="pill" onClick={showModal} variant="light" size="xs">
        {rotationLabel()}
      </Button>
      <Modal
        title={`Select the ${quickAssignmentPage} to quickly assign`}
        size="lg"
        show={quickAssignmentShow()}
        onHide={onModalClose}
        buttons={getModalButtons()}
      >
        {quickAssignmentPage === "rotation" && (
          <Select
            inline
            options={annualRotations}
            labelKey="block_schedule_rotation.name"
            value={quickAssignmentRotation}
            renderOption={(text, item) => (
              <H5 ellipsis prefixDotColor={item.block_schedule_rotation.color}>
                {text}
              </H5>
            )}
            onChange={(val) => selectQuickAssignmentRotation(val)}
          />
        )}
        {quickAssignmentPage === "template" && (
          <Select
            inline
            options={getApplicableTemplates()}
            labelKey="name"
            value={quickAssignmentTemplate}
            onChange={(val) => selectQuickAssignmentTemplate(val)}
          />
        )}
      </Modal>
    </>
  )
}
