import React, { useState } from "react"
import Dropdown from "@app/components/Dropdown"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import { H5 } from "@app/components/Typography"
import { Select } from "@app/components/Form"
import type { FilterState } from "./useTableFilter"
import type { ColumnType } from "../Table"
import { isIE } from "@app/utils"
import cx from "classnames"

type Props<T> = {
  children: React.ReactNode
  column: ColumnType<T>
  triggerFilter: (state: FilterState<T>) => any
  filterState?: FilterState<T>
}

export default <T,>(props: Props<T>) => {
  const { children, column, filterState, triggerFilter } = props
  const defaultFilterValue = filterState?.filterValue || []

  const { filterTitle } = column
  const { options = [], multiple } = column.filter || {}

  const [show, setShow] = useState(false)
  const [filterValue, setFilterValue] = useState<any[]>(defaultFilterValue)
  const hasFilterValue = (value: any[]) => value?.length

  // Handle dropdown toggle
  const onToggle = (
    isOpen: boolean,
    _: any,
    { source }: { source: string }
  ) => {
    if (["rootClose", "click"].includes(source)) {
      if (!isOpen) {
        // Apply filter when dropdown collapsed
        onConfirm()
      } else {
        setShow(isOpen)
      }
    }
  }

  const onReset = () => {
    setFilterValue([])
  }

  const onConfirm = () => {
    setShow(false)
    triggerFilter({
      column,
      filterValue: filterValue,
    })
  }

  return (
    <div className="rc-filter-column">
      {children}
      <Dropdown
        show={show}
        onToggle={onToggle}
        overlay={
          <Dropdown.Menu className="rc-column-filter-menu">
            <H5 bold="medium" className="rc-column-filter-menu__title">
              Filter by {filterTitle}
            </H5>
            <div
              className={cx("rc-column-filter-menu__content", {
                "single-select-ie": isIE && !multiple,
              })}
            >
              <Select
                name="filterValue"
                options={options}
                multiple={multiple}
                inline={multiple}
                enableHelpers={false}
                clearable={false}
                value={multiple ? filterValue : filterValue[0]}
                onChange={(val) =>
                  multiple
                    ? setFilterValue(val)
                    : setFilterValue(val != null ? [val] : [])
                }
              />
            </div>
            <div className="rc-column-filter-menu__buttons">
              <Button
                variant="link"
                size="sm"
                disabled={!hasFilterValue(filterValue)}
                onClick={onReset}
              >
                Reset
              </Button>
              <Button size="sm" onClick={onConfirm}>
                OK
              </Button>
            </div>
          </Dropdown.Menu>
        }
        onClick={(e: any) => e.stopPropagation()}
        className="rc-column-filter"
      >
        <Icon
          name="funnel-fill"
          className={cx("rc-column-filter-trigger", {
            active: hasFilterValue(defaultFilterValue),
          })}
        />
      </Dropdown>
    </div>
  )
}
