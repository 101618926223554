import React from "react"
import Modal from "@app/components/Modal"
import { FormItem, useForm, Input, Select } from "@app/components/Form"
import api from "@app/services/api"
import { handleApiError, parseDate } from "@app/utils"

type Props = {
  mutate: (v: any) => void
  dailyOverride: AdditionalAssignmentDailyOverride | null
  setDailyOverride: (v: any) => void
  jobName: string
}

export default (props: Props) => {
  const { mutate, dailyOverride, setDailyOverride, jobName } = props

  const { minimum = 1, ideal = 1, maximum = 1 } = dailyOverride || {}

  type FormFieldsType = {
    date: string
    minimum: number
    ideal: number
    maximum: number
  }

  const {
    control,
    getValues,
    setValue,
    handleModalSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          date: yup
            .string()
            .required()
            .label("Date")
            .test(
              "Invalid date",
              "You cannot create a daily assignment override for a date in the past",
              validateDate
            ),
          minimum: yup.number().required().label("Minimum"),
          ideal: yup
            .number()
            .required()
            .label("Ideal")
            .test(
              "Invalid ideal value",
              "Ideal need to be greater or equal than minimum",
              validateIdeal
            ),
          maximum: yup
            .number()
            .required()
            .label("Maximum")
            .test(
              "Invalid maximum value",
              "Maximum need to be greater or equal than ideal",
              validateMaximum
            ),
        })
      ),
  })

  const validateDate = (_value: any): boolean => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    return getValues("date") ? parseDate(getValues("date")) >= today : false
  }

  const validateIdeal = (_value: any): boolean =>
    Boolean(getValues("ideal")) &&
    Boolean(getValues("minimum")) &&
    getValues("ideal") >= getValues("minimum")

  const validateMaximum = (_value: any): boolean =>
    Boolean(getValues("ideal")) &&
    Boolean(getValues("maximum")) &&
    getValues("maximum") >= getValues("ideal")

  const seatCountCheck = (seatType: string, count: string) => {
    let seatsCount = count ? parseInt(count) : 1

    seatType == "minimum"
      ? getValues("ideal") &&
        getValues("ideal") < seatsCount &&
        setValue("ideal", seatsCount)
      : seatType == "ideal"
      ? getValues("maximum") &&
        getValues("maximum") < seatsCount &&
        setValue("maximum", seatsCount)
      : null
  }

  const hideModal = () => {
    setDailyOverride(null)
    resetForm()
  }

  const onSubmit = (fields: FormFieldsType) => {
    dailyOverride &&
      (dailyOverride.id
        ? api
            .updateAdditionalAssignmentDailyOverride(dailyOverride.id, {
              ...fields,
              jobid: dailyOverride.jobid,
            })
            .then((_res) => {
              hideModal()
              mutate([
                api.getAdditionalAssignmentDailyOverrides,
                dailyOverride.jobid,
              ])
            }, handleApiError)
        : api
            .createAdditionalAssignmentDailyOverride({
              ...fields,
              jobid: dailyOverride.jobid,
            })
            .then((_res) => {
              hideModal()
              mutate([
                api.getAdditionalAssignmentDailyOverrides,
                dailyOverride.jobid,
              ])
            }, handleApiError))
  }

  const countOptions = [...Array(30).keys()].map((x) => ({
    id: x + 1,
    name: x + 1,
  }))

  return (
    <Modal
      title={`Add Assignment Requirement Daily Override for ${jobName}`}
      size="lg"
      show={Boolean(dailyOverride)}
      onHide={hideModal}
      buttons={[
        {
          text: "Cancel",
          variant: "outline-primary",
          onClick: hideModal,
        },
        {
          text: "Save",
          variant: "primary",
          onClick: handleModalSubmit(onSubmit),
        },
      ]}
    >
      <div className="my-5" style={{ minHeight: "200px" }}>
        <FormItem required label="Date" name="date" control={control}>
          <Input
            type="date"
            disabled={Boolean(dailyOverride?.id)}
            defaultValue={dailyOverride?.date}
            value={dailyOverride?.date}
          />
        </FormItem>
        <FormItem required label="Minimum" name="minimum" control={control}>
          <Select
            options={countOptions}
            defaultValue={minimum}
            onChange={(val) => seatCountCheck("minimum", val)}
          />
        </FormItem>
        <FormItem required label="Ideal" name="ideal" control={control}>
          <Select
            options={countOptions}
            defaultValue={ideal}
            onChange={(val) => seatCountCheck("ideal", val)}
          />
        </FormItem>
        <FormItem required label="Maximum" name="maximum" control={control}>
          <Select options={countOptions} defaultValue={maximum} />
        </FormItem>
      </div>
    </Modal>
  )
}
