import React from "react"
import BsCard from "react-bootstrap/Card"
import { isIE } from "@app/utils"
import cx from "classnames"
import css from "./Card.module.scss"

type Props = {
  title?: boolean | string | JSX.Element
  action?: boolean | string | JSX.Element
  className?: string
  maxHeight?: number
  disableBodyScroll?: boolean
}

export default ((props) => {
  const { title, action, className, maxHeight, disableBodyScroll, children } =
    props

  return (
    <BsCard className={cx(css.card, className)}>
      {title && (
        <BsCard.Header className={css.cardHeader}>
          <div className={css.cardTitle}>{title}</div>
          <div className={css.cardAction}>{action}</div>
        </BsCard.Header>
      )}
      {children && (
        <BsCard.Body
          className={css.cardBody}
          style={{
            maxHeight,
            overflow: disableBodyScroll
              ? isIE
                ? "visible"
                : "unset"
              : undefined,
          }}
        >
          {children}
        </BsCard.Body>
      )}
    </BsCard>
  )
}) as React.FC<Props>
