import React, { useState } from "react"
import cx from "classnames"
import remove from "lodash/remove"

import useRequest from "@app/utils/request"
import api from "@app/services/api"
import Modal from "@app/components/Modal"
import Icon from "@app/components/Icon"
import { Selected } from "./DailyTemplateCalendarView"

import css from "./AddOrRemoveJobModal.module.scss"

type Props = {
  show: boolean
  close: Function
  selectedDays: Selected
  weeks: JobType[][][]
  setWeeks: Function
  mode: "add" | "remove"
  jobsToRemove: Record<string, JobType>
}

export default (props: Props) => {
  const { mode, show, close, weeks, setWeeks, selectedDays, jobsToRemove } =
    props
  const [selectedJobs, setSelectedJobs] = useState<Record<string, JobType>>({})

  const { data: jobs } = useRequest<JobType[]>([api.getJobs])

  const availableJobs = mode === "remove" ? Object.values(jobsToRemove) : jobs

  const onModalClose = () => {
    close(false)
    setSelectedJobs({})
  }

  function iterateSelected(fn: Function) {
    Object.keys(selectedDays).map((key: string) => {
      if (!selectedDays[key]?.selected) return
      const week = Number(key.charAt(0))
      const day = Number(key.charAt(1))

      Object.values(selectedJobs).map((job) => {
        weeks[week][day] = fn(weeks[week][day], job)
      })
    })
    setWeeks([...weeks])
  }

  function addJobs() {
    iterateSelected((existingJobs: JobType[], jobToAdd: JobType) => {
      let existsInDay
      for (const job of existingJobs) {
        if (job.jobid === jobToAdd.jobid) {
          existsInDay = true
          break
        }
      }
      if (existsInDay) return existingJobs

      return [...existingJobs, jobToAdd].sort(sortJobsByStartTime)
    })
  }

  function removeJobs() {
    iterateSelected((existingJobs: JobType[], jobToRemove: JobType) => {
      remove(existingJobs, (job) => jobToRemove.jobid === job.jobid)
      return existingJobs.sort(sortJobsByStartTime)
    })
  }

  function sortJobsByStartTime(a: JobType, b: JobType) {
    if (a.starttime < b.starttime) return -1
    if (a.starttime > b.starttime) return 1
    else return 0
  }

  function selectJob(job: JobType) {
    if (selectedJobs[job.jobid]) {
      delete selectedJobs[job.jobid]
    } else {
      selectedJobs[job.jobid] = job
    }
    setSelectedJobs({ ...selectedJobs })
  }

  return (
    <Modal
      title={`${mode === "add" ? "Add" : "Remove"} Jobs`}
      show={show}
      onHide={onModalClose}
      size="xl"
      buttons={[
        { text: "Cancel", variant: "outline-secondary" },
        {
          text: `${mode === "add" ? "Add" : "Remove"} Jobs`,
          variant: `${mode === "remove" ? "danger" : "primary"}`,
          onClick: mode === "add" ? () => addJobs() : () => removeJobs(),
          disabled: Object.keys(selectedJobs).length === 0,
        },
      ]}
    >
      <div className={css.jobTableView}>
        <div className={cx(css.jobRow, css.headerRow)}>
          {[
            "Name",
            "Abbrev",
            "Priority",
            "Type",
            "Start",
            "End",
            "Selected",
          ].map((title, idx) => (
            <div key={idx}>{title}</div>
          ))}
        </div>
        {availableJobs?.map((job, idx) => (
          <div className={css.jobRow} key={idx} onClick={() => selectJob(job)}>
            <div>{job.name}</div>
            <div>{job.abbrev}</div>
            <div>{job.priority}</div>
            <div>{job.job_type.type}</div>
            <div>{job.starttime}</div>
            <div>{job.endtime}</div>
            <div>
              <div
                className={cx(
                  css.selectCheckIcon,
                  selectedJobs[job.jobid] ? css.checked : undefined
                )}
              >
                {selectedJobs[job.jobid] && <Icon name="check" />}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}
