import React, { useContext, useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import {
  IProviderInfo,
  IStaffTarget,
  IAdditionalAssignmentItem,
} from "../../data"
import MultipleAssignmentsConfigBoxRow from "./MultipleAssignmentsConfigBoxRow"
import Grid from "@material-ui/core/Grid"
import { H5 } from "@app/components/Typography"
import api, { useRequest } from "@app/services/api"

export interface IMultipleAssignmentsConfigBoxProps {
  providers: IProviderInfo[]
}

const MultipleAssignmentsConfigBox: React.FC<
  IMultipleAssignmentsConfigBoxProps
> = (props) => {
  const {
    currentMultipleAssignmentsJobId,
    setCurrentMultipleAssignmentsJobId,
    jobs,
    setJobs,
    edate,
    dayid,
    setMultipleAssignmentsHasChanged,
  } = useContext(ManualScheduleTableContext)

  const providerOptions = () => {
    const residentProviders = props.providers.find(
      (providersMap) => providersMap.jobid == currentMultipleAssignmentsJobId
    )

    return residentProviders?.providers || props.providers
  }

  const [rerender, setRerender] = useState(0)
  const forceUpdate = () => setRerender(rerender + 1)

  const { data: jobStaffingTargets } = useRequest([
    api.getJobDayAssignmentOverrides,
    currentMultipleAssignmentsJobId,
    edate,
  ])

  const targetJobStaffingTarget =
    jobStaffingTargets &&
    jobStaffingTargets.find((item: IStaffTarget) => item.dayid == dayid)

  const currentJob = jobs.find(
    (j) => currentMultipleAssignmentsJobId == j.jobid
  )

  const initAssignment = {
    jobid: currentJob!.jobid,
    providerid: currentJob!.providerid,
    eventid: undefined,
    edate: edate,
  }

  const getAdditionalAssignments = () =>
    currentJob && currentJob.providerid
      ? currentJob.additionalAssignments[0]?.providerid == currentJob.providerid
        ? currentJob!.additionalAssignments
        : [initAssignment, ...currentJob!.additionalAssignments]
      : []

  const [currentAssignmentsList, setCurrentAssignmentsList] = useState<
    IAdditionalAssignmentItem[]
  >(getAdditionalAssignments())

  useEffect(() => {
    document
      .querySelectorAll(".schedule-button")
      .forEach((x) => ((x as HTMLButtonElement).disabled = true))
    return () => {
      document
        .querySelectorAll(".schedule-button")
        .forEach((x) => ((x as HTMLButtonElement).disabled = false))
    }
  }, [])

  const handleOnChange = async (
    index: number,
    propname: "providerid",
    value: any
  ) => {
    let assignment: IAdditionalAssignmentItem
    let newList = [...currentAssignmentsList]

    assignment = index < newList.length ? { ...newList[index] } : initAssignment
    assignment[propname] = parseInt(value)
    if (currentAssignmentsList.length <= index) {
      newList = [...newList, assignment]
    }
    newList[index] = assignment
    setCurrentAssignmentsList([...newList])
    forceUpdate()
  }

  const handleSave = (currSplitShiftList: IAdditionalAssignmentItem[]) => {
    if (validate(currSplitShiftList)) {
      setMultipleAssignmentsHasChanged(true)
      const index = jobs.findIndex(
        (m) => m.jobid == currentMultipleAssignmentsJobId
      )
      let edateUpdList = currentAssignmentsList
      jobs[index].providerid = currSplitShiftList[0]?.providerid
      let updJob = {
        ...jobs[index],
        ...{ additionalAssignments: edateUpdList },
      }
      if (edateUpdList.length == 0) {
        updJob = { ...updJob, removeSplitShiftListFlag: true }
      }
      setJobs([...jobs.slice(0, index), updJob, ...jobs.slice(index + 1)])
      setCurrentMultipleAssignmentsJobId(null)
    }
  }

  const handleClearRow = (index: number) => {
    setCurrentAssignmentsList([
      ...currentAssignmentsList.slice(0, index),
      ...currentAssignmentsList.slice(index + 1),
    ])
  }

  const handleCancel = () => {
    setCurrentMultipleAssignmentsJobId(null)
  }

  const validate = (assignments: IAdditionalAssignmentItem[]) => {
    if (assignments.length == 0) {
      return true
    }

    const providerids = assignments.map((assignment) => assignment.providerid)
    const uniqProviderids = [...new Set(providerids)]

    let res = providerids.length == uniqProviderids.length

    if (!res) {
      alert(`Please do not select duplicated providers!`)
      return false
    }

    return res
  }

  return (
    <>
      <Form>
        <Container
          className="container"
          style={{
            background: "#F0F2F2",
            width: "100%",
            height: "auto",
            borderRadius: "6px",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ marginTop: 10, textAlign: "center" }}
          >
            <Grid item xs={3} md={3}>
              <H5>Assignment</H5>
            </Grid>
            <Grid item xs={3} md={3}>
              <H5>Staff Target</H5>
            </Grid>
            <Grid item xs={6} md={6}>
              <H5>Scheduled</H5>
            </Grid>
          </Grid>
          {targetJobStaffingTarget ? (
            [...new Array(targetJobStaffingTarget.maximum).keys()].map((i) => {
              return (
                <MultipleAssignmentsConfigBoxRow
                  rerender={rerender}
                  key={i}
                  index={i}
                  isActive={i <= currentAssignmentsList.length}
                  handleOnChange={async (propname: "providerid", e: any) =>
                    await handleOnChange(i, propname, e)
                  }
                  rowData={currentAssignmentsList[i]}
                  handleClearRow={() => handleClearRow(i)}
                  providers={providerOptions()}
                  targetLevel={
                    i < targetJobStaffingTarget.ideal - 1
                      ? "minimum"
                      : i == targetJobStaffingTarget.ideal - 1
                      ? "ideal"
                      : "maximum"
                  }
                />
              )
            })
          ) : (
            <div>Loading...</div>
          )}
          <Grid
            container
            direction="row-reverse"
            wrap="nowrap"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            style={{ padding: "16px 66px 16px 32px" }}
          >
            <Grid item>
              <Button
                type="button"
                onClick={() => handleSave(currentAssignmentsList)}
                style={{
                  background: "#4A657B",
                  color: "#FFFFFF",
                  width: 104,
                }}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#9EB2BD",
                  border: "0.9114px solid #9EB2BD",
                  width: 104,
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Form>
    </>
  )
}

export default MultipleAssignmentsConfigBox
