import React, { useState, useEffect, useRef, useContext, useMemo } from "react"
import { Dialog } from "@app/components/Modal"
import Loader from "@app/components/Loader"
import Alert from "@app/components/Alert"
import { H5, Text } from "@app/components/Typography"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import {
  FormErrorType,
  ManualScheduleFormErrorsContext,
} from "../../context/ManualScheduleFormErrorsContext"
import type { IManualScheduleJobItem } from "../../data"
import api from "@app/services/api"
import { isMatch } from "lodash"
import cx from "classnames"
import css from "./FormValidator.module.scss"
import { formatDate } from "@app/utils"

const buildAssignments = (jobs: IManualScheduleJobItem[]) => {
  const mainAttrsOf = (obj: PlainObjectType) => {
    const { providerid, starttime, endtime, splitShifts } = obj
    return {
      starttime,
      endtime,
      providerid: splitShifts?.length ? undefined : providerid,
    }
  }

  return jobs
    .filter((job) => job.link_to_rotations)
    .filter((job) => job.providerid || job.splitShifts?.length)
    .map((job) => ({
      ...mainAttrsOf(job),
      jobid: job.jobid,
      split_shifts: job.splitShifts.map((splitShift) => ({
        ...mainAttrsOf(splitShift),
        date: splitShift.edate,
        tally_credit: splitShift.tally_credit,
      })),
    }))
}

const getOverlappingAssignmentsError = (
  jobs: IManualScheduleJobItem[],
  edate: string
) => {
  const getJobTimeNumber = (edate: string, time: string) =>
    new Date(`${edate}:${time}`).getTime()

  const getJobTimeFormatted = (time: string) => {
    const date = new Date(`${edate}:${time}`)
    return formatDate(date, (f) => f.hourMinute12h)
  }

  const getJobsRoleTokenMatch = (
    job1: IManualScheduleJobItem,
    job2: IManualScheduleJobItem
  ) => {
    return !!job1.tigerconnect_role_tokens?.filter((role_token: string) =>
      job2.tigerconnect_role_tokens?.includes(role_token)
    ).length
  }

  const sortedJobs = jobs
    .filter((job) => job.tigerconnect_role_tokens?.length && job.providerid)
    .sort(
      (job1, job2) =>
        getJobTimeNumber(edate, job1.starttime) -
        getJobTimeNumber(edate, job2.starttime)
    )

  const getJobsOverlapping = (
    startIndex: number,
    job: IManualScheduleJobItem
  ) => {
    const jobs = []
    for (let i = startIndex; i < sortedJobs.length; i++) {
      const job2startTime = getJobTimeNumber(edate, sortedJobs[i].starttime)
      const job1startTime = getJobTimeNumber(edate, job.starttime)
      let job1endTime = getJobTimeNumber(edate, job.endtime)

      if (job1startTime > job1endTime) {
        const newDate = new Date(edate)
        newDate.setDate(newDate.getDate() + 1)
        job1endTime = getJobTimeNumber(
          newDate.toISOString().split("T")[0],
          job.endtime
        )
      }

      if (
        job2startTime >= job1startTime &&
        job2startTime < job1endTime &&
        getJobsRoleTokenMatch(job, sortedJobs[i]) &&
        job.providerid
      ) {
        jobs.push(sortedJobs[i])
      }
    }

    return jobs
  }

  const getOverlappingShiftErrorMessage = (
    job1: IManualScheduleJobItem,
    job2: IManualScheduleJobItem
  ) => {
    const job1Times = `(${getJobTimeFormatted(
      job1.starttime
    )} - ${getJobTimeFormatted(job1.endtime)})`
    const job2Times = `(${getJobTimeFormatted(
      job2.starttime
    )} - ${getJobTimeFormatted(job2.endtime)})`
    let error =
      "You are attempting to schedule two jobs that are both mapped to the same Role in TigerConnect, which will result in an overlapping shift error: \n"
    error += `* ${job1.scheduled_name} for ${job1.name} ${job1Times}\n`
    error += `* ${job2.scheduled_name} for ${job2.name} ${job2Times}\n\n`
    error += "Please take one of the following actions to resolve this error:\n"
    error +=
      '* Delete one of these assignments by choosing "Select a Provider" for the desired job\n'
    error +=
      "* Verify that the start and end times for both jobs are correct in Jobs setup\n"
    error +=
      "* Work with your scheduling team to map the jobs to unique roles in TigerConnect, or modify start and end times to prevent the overlap"

    return error
  }

  const error = sortedJobs.reduce((message, job, index) => {
    if (!message && index !== sortedJobs.length - 1) {
      const overlappingAssignments = getJobsOverlapping(index + 1, job)

      if (overlappingAssignments.length) {
        message = getOverlappingShiftErrorMessage(
          job,
          overlappingAssignments[0]
        )
      }
    }
    return message
  }, "")

  return error
}

const disableSubmitButtons = () => {
  const buttons = Array.from(
    document.getElementsByClassName("schedule-button")
  ) as HTMLButtonElement[]

  buttons.forEach((button: HTMLButtonElement) => {
    button.disabled = true
  })
}

const enableSubmitButtons = () => {
  const buttons = Array.from(
    document.getElementsByClassName("schedule-button")
  ) as HTMLButtonElement[]

  buttons.forEach((button: HTMLButtonElement) => {
    button.disabled = false
  })
}

export default (({ children }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<FormErrorType[]>([])
  const [assignments, setAssignments] = useState<
    ManualScheduleJobAssignmentType[]
  >([])

  const containerRef = useRef<HTMLDivElement>(null)
  const dialogRef = useRef<PlainObjectType>(null)
  const allowSubmitRef = useRef(false)

  const { isResidentJob, edate, jobs, residentJobsProviders } = useContext(
    ManualScheduleTableContext
  )
  const initialAssignments = useMemo(
    () => buildAssignments(jobs),
    [isResidentJob]
  )

  // Control form submitting condition
  const isAllowSubmit = allowSubmitRef.current
  const allowSubmit = (allow = true) => (allowSubmitRef.current = allow)

  useEffect(() => {
    const form = containerRef.current?.closest("form")
    const dialog = dialogRef.current

    const scheduleNotifyButton = document.getElementById(
      "schedule-notify-button"
    ) as HTMLButtonElement

    if (scheduleNotifyButton) {
      scheduleNotifyButton.onclick = () => {
        const input = document.createElement("input")
        input.setAttribute("type", "hidden")
        input.setAttribute("name", "notify")
        input.setAttribute("value", "1")
        form?.appendChild(input)
      }
    }

    if (!form || !dialog) {
      return
    }

    const overlappingAssignmentsError = getOverlappingAssignmentsError(
      jobs,
      edate
    )
    const submitHandler = (event: Event) => {
      const assignments = buildAssignments(jobs)
      // Continue to normal submit,
      // when nothing changed on resident jobs
      if (
        (isAllowSubmit || isMatch(initialAssignments, assignments)) &&
        !overlappingAssignmentsError
      ) {
        disableSubmitButtons()
        return true
      }

      // Prevent submitting and show loading popup,
      // then request remote validation
      if (overlappingAssignmentsError) {
        enableSubmitButtons()
        event.preventDefault()
        alert(overlappingAssignmentsError)
      } else if (isResidentJob) {
        event.preventDefault()
        setLoading(true)
        dialog.showDialog()
        api
          .validateJobsAssignments({
            date: edate,
            assignments,
          })
          .then(() => {
            setErrors([])
            setLoading(false)
            allowSubmit()
            form.submit()
          })
          .catch((res) => {
            enableSubmitButtons()
            setErrors(res.data.errors)
            setLoading(false)
            setAssignments(assignments)
          })
      } else {
        disableSubmitButtons()
        return true
      }

      return false
    }

    form.addEventListener("submit", submitHandler)
    return () => form.removeEventListener("submit", submitHandler)
  }, [isResidentJob, jobs])

  const onSubmit = () => {
    const form = containerRef.current?.closest("form")

    if (form) {
      setErrors([])
      allowSubmit()
      form.submit()
    }

    // Keep dialog open and show the submitting loader
    return false
  }

  const errorsInfo = errors.map((err) => {
    const jobName = jobs.find((x) => x.jobid === err.jobid)?.name
    const providerName = residentJobsProviders
      ?.find((x) => x.jobid === err.jobid)
      ?.providers?.find((x) => x.providerid === err.providerid)?.name

    return { ...err, jobName, providerName }
  })

  let dialogProps: React.ComponentProps<typeof Dialog> = {
    message: (
      <div className="pl-5 pr-5">
        <Loader />
        <p className="mt-3">{loading ? "Validating..." : "Submiting..."}</p>
      </div>
    ),
  }

  if (!loading && errors.length) {
    dialogProps = {
      title: "Duty Hour Validation Error",
      buttons: {
        ok: { text: "Submit anyway", onClick: onSubmit },
        cancel: { text: "Cancel" },
      },
      message: (
        <>
          <H5 className="text-left">
            Are you sure that you want to proceed with these assignments?
          </H5>
          <Alert
            variant="warning"
            className={cx("mt-3 mb-0", css.alertContainer)}
          >
            {errorsInfo.map((err, idx) => {
              return (
                <div key={idx}>
                  <Text as="ul" className="pl-0 mb-0 text-left">
                    <div>
                      Assigning {err.providerName} to {err.jobName} will violate
                      the following duty hours:
                    </div>
                    {err.messages.map((message, messageIdx) => (
                      <li key={messageIdx} className="ml-5">
                        {message}
                      </li>
                    ))}
                  </Text>
                </div>
              )
            })}
          </Alert>
        </>
      ),
    }
  }

  return (
    <div ref={containerRef}>
      <Dialog ref={dialogRef} size="lg" {...dialogProps} />
      <ManualScheduleFormErrorsContext.Provider
        value={{ errors, assignments }}
        children={children}
      />
    </div>
  )
}) as React.FC
