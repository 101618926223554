import { Dialog } from "@app/components/Modal"
import {
  eachMonthOfInterval,
  eachWeekOfInterval,
  getDaysInMonth,
  differenceInDays,
  areIntervalsOverlapping,
  isWithinInterval,
  isSameDay,
  format,
  formatISO,
  startOfMonth,
  lastDayOfMonth,
  lastDayOfWeek,
  parse,
  parseISO,
  max as maxDate,
  min as minDate,
  addDays,
  subDays,
} from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { pick as lodashPick } from "lodash"
import { DateFormatter, DaysOfWeek, DaysOfWeekType } from "./constants"

type RandomIDArgsType = {
  prefix?: string
  suffix?: string
}

export const randomID = ({ prefix, suffix }: RandomIDArgsType) =>
  [prefix, Math.floor(Math.random() * 0xffff), suffix]
    .filter((x) => x != null)
    .join("-")

// Handle API failed or error
export const handleApiError = (res: PlainObjectType) => {
  const { fail, data, message } = res
  const defaultMessage = "Sorry, an error occured, please try agian."

  Dialog.warn({
    title: fail ? "Failed" : "Error",
    message: message || data.message || defaultMessage,
  })
}

export const monthOfInterval = (
  startDate: string | Date,
  endDate: string | Date
) => {
  if (!startDate || !endDate) {
    return []
  }

  const start = parseDate(startDate)
  const end = parseDate(endDate)
  const months = eachMonthOfInterval({ start, end })

  return months.map((month, idx) => {
    const daysInMonth = getDaysInMonth(month)
    let restDays, startDateOfMonth, endDateOfMonth

    if (idx === 0) {
      restDays = daysInMonth - (start.getDate() - 1)
      startDateOfMonth = start
      endDateOfMonth = lastDayOfMonth(month)
    } else if (idx === months.length - 1) {
      restDays = parseDate(endDate).getDate()
      startDateOfMonth = startOfMonth(month)
      endDateOfMonth = end
    } else {
      restDays = daysInMonth
      startDateOfMonth = startOfMonth(month)
      endDateOfMonth = lastDayOfMonth(month)
    }

    return {
      month: month,
      days: daysInMonth,
      restDays: restDays,
      name: format(month, "MMMM"),
      date: format(month, "yyyy-MM"),
      startDate: startDateOfMonth,
      endDate: endDateOfMonth,
    }
  })
}

export const weekOfInterval = (
  startDate: string | Date,
  endDate: string | Date,
  options?: Parameters<typeof eachWeekOfInterval>[1]
) => {
  if (!startDate || !endDate) {
    return []
  }

  const start = parseDate(startDate)
  const end = parseDate(endDate)
  const weeks = eachWeekOfInterval({ start, end }, options)
  const commonWeekDays = 7

  return weeks.map((week, idx) => {
    let restDays = commonWeekDays

    if (idx === 0) {
      restDays = differenceInDays(lastDayOfWeek(week, options), start) + 1
    } else if (idx === weeks.length - 1) {
      restDays = differenceInDays(end, week) + 1
    }

    return {
      week: week,
      days: commonWeekDays,
      restDays: restDays,
      name: format(week, "w"),
    }
  })
}

type DateRangeType = {
  start: Date | string
  end: Date | string
}

export const overlapOfDateRanges = (
  range1: DateRangeType,
  range2: DateRangeType
) => {
  const leftRange = {
    start: parseDate(range1.start),
    end: parseDate(range1.end),
  }
  const rightRange = {
    start: parseDate(range2.start),
    end: parseDate(range2.end),
  }

  if (areIntervalsOverlapping(leftRange, rightRange, { inclusive: true })) {
    return {
      start: maxDate([leftRange.start, rightRange.start]),
      end: minDate([leftRange.end, rightRange.end]),
    }
  } else {
    return undefined
  }
}

export const isDateRangeCovered = (
  date: string | Date,
  dateRange: Partial<DateRangeType>
) => {
  const startDate = parseDate(dateRange.start || new Date())
  const endDate = parseDate(dateRange.end || new Date())

  return isWithinInterval(parseDate(date), { start: startDate, end: endDate })
}

export const formatDate = (
  date: string | Date,
  formatter:
    | string
    | ((formatter: typeof DateFormatter) => string) = DateFormatter.normal
) => {
  typeof formatter === "function" && (formatter = formatter(DateFormatter))
  return formatter === DateFormatter.iso
    ? formatISO(parseDate(date))
    : format(parseDate(date), formatter)
}

export const formatDateInTimezone = (
  date: string | Date,
  timezone: string,
  formatter:
    | string
    | ((formatter: typeof DateFormatter) => string) = DateFormatter.normal
) => {
  typeof formatter === "function" && (formatter = formatter(DateFormatter))
  return formatInTimeZone(parseDate(date), timezone, formatter)
}

// Format annual schedule blocks start date or end date,
// 2021-12-02 => Dec 2
export const formatBlocksDate = (date: string | Date) => {
  return formatDate(date, DateFormatter.humanizedMonthOnly)
}

export const parseDate = (
  date: string | Date,
  formatter:
    | string
    | ((formatter: typeof DateFormatter) => string) = DateFormatter.normal
) => {
  typeof formatter === "function" && (formatter = formatter(DateFormatter))
  return date instanceof Date
    ? date
    : formatter === DateFormatter.iso
    ? parseISO(date)
    : parse(date, formatter, new Date())
}

// Format start and end time of assignment
// 12:00 am - 12:00 pm
export const formatDateToStartEnd = (startTime: string, endTime: string) => {
  return `${formatDate(
    new Date(`2023-01-01T${startTime}`),
    DateFormatter.hourMinute12h
  )} - ${formatDate(
    new Date(`2023-01-01T${endTime}`),
    DateFormatter.hourMinute12h
  )}`
}

//Format ISO UTC time to normal time for assigment
// 1970-01-01T11:09:00.000Z to 00:00 pm
export const formatUTCTime = (time: string) => {
  if (time.includes("Z")) {
    const date = parseISO(time)
    return format(date, "HH:mm:ss")
  } else if (time.length === 8 && time.includes(":")) {
    return time
  }
  return time
}

export const renderBlockSchedulingMenus = (
  allItems: AnnualBlockScheduleType[]
) => {
  const items = allItems
    .filter((item) => item.view_on_list)
    .sort((a, b) => a.start_year - b.start_year)
  const selector = "#nav-main .scheduling a#block_schedules_menu"
  const selectorElement = document.querySelector(selector)
  const blockSchedulingElement = selectorElement?.parentElement

  if (blockSchedulingElement) {
    let ulElement = blockSchedulingElement.querySelector("ul")

    if (!items.length) {
      ulElement && blockSchedulingElement.removeChild(ulElement)
      selectorElement?.setAttribute("data-placement", "right")
      selectorElement?.setAttribute("title", "No active calendars")
      jQuery(selectorElement).tooltip()
      return
    }

    if (!ulElement) {
      ulElement = document.createElement("ul")
      blockSchedulingElement.appendChild(ulElement)
      selectorElement?.removeAttribute("title")
      jQuery(selectorElement).tooltip("destroy")
    }

    const renderItem = (item: AnnualBlockScheduleType) => `
      <li>
        <a href="/editor/block_scheduling.cgi#/annual_schedules/${item.id}">
          ${item.name}<br>(${item.display_name})
        </a>
      </li>
    `

    ulElement.innerHTML = items.map(renderItem).join("")
  }
}

export const hideNavigationMenus = () => {
  const selector = "#nav-main .settings > ul"
  const settingsMenusElement =
    document.querySelector<HTMLUListElement>(selector)

  if (settingsMenusElement) {
    settingsMenusElement.style.display = "none"
    setTimeout(() => {
      settingsMenusElement.style.display = ""
    })
  }
}

// Pick parts of object in an Array
// Example:
//   - mapPick([{ id: 1, name: "Foo", age: 10 }], "id") => [{ id: 1 }]
//   - mapPick([{ id: 1, name: "Foo", age: 10 }], ["id", "name"]) => [{ id: 1, name: "Foo" }]
export const mapPick = <T = {}>(arr: T[], keys: keyof T | Array<keyof T>) => {
  return arr.map((obj) => lodashPick(obj, keys))
}

export const formatDateRange = (
  dateRange: DateRangeType,
  formatter:
    | string
    | ((formatter: typeof DateFormatter) => string) = DateFormatter.normal,
  separator: string = "-"
) => {
  const startDate = parseDate(dateRange.start)
  const endDate = parseDate(dateRange.end)
  typeof formatter === "function" && (formatter = formatter(DateFormatter))

  if (isSameDay(startDate, endDate)) {
    return format(startDate, formatter)
  }

  return [format(startDate, formatter), format(endDate, formatter)].join(
    separator
  )
}

export const getTimezoneAbbr = (timezone: string) => {
  return formatInTimeZone(new Date(), timezone, "zzz")
}

// Convert number with comma for easy reading
// Example:
//   1234 => "1,234"
//   12345.88 => "12,345.88"
export function humanizeNumber(value: number): string {
  value = value || 0
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

// To output single/plural format for text with numbers
// Example:
//   pluralize(1234, "Company", "Companies") => "1,234 Companies"
//   pluralize(1, "Company", "Companies") => "1 Company"
//   pluralize(2019, "List") => "2,019 Lists"
//   pluralize(2019, "City", "Cities", { format: false }) => "2019 Cities"
export function pluralize(
  value: number = 0,
  single: string,
  plural?: string,
  options?: { format?: boolean }
): string {
  const defaultOptions = { format: true }
  options = { ...options, ...defaultOptions }
  let numberText = options.format ? humanizeNumber(value) : value
  let pluralText = value === 1 ? single : plural || `${single}s`

  return `${numberText} ${pluralText}`
}

export function summate(
  nums: Array<number | null | undefined | PlainObjectType>,
  options: { precision?: number; key?: string } = {}
) {
  const { precision, key } = options
  const values = key ? nums.map((x: any) => x[key]) : nums
  const amount = values.reduce(
    (acc: number, num) => (num == null ? acc : acc + num),
    0
  )

  return precision ? +amount.toFixed(precision) : amount
}

// To mutate collection for API response,
// it's useful for `mutate` of api and `useRequest`
// Example:
//   const items = [{ id: 1, name: "Foo" }, { id: 2, name: "Bar" }]
//   mutateCollection(items, { id: 2 }, { delete: true })
//     => [{ id: 1, name: "Foo" }]
//   mutateCollection(items, { id: 2, name: "Not Bar" })
//     => [{ id: 1, name: "Foo" }, { id: 2, name: "Not Bar" }]
//   mutateCollection(items, { id: 3, name: "Foo-and-Bar" })
//     => [{ id: 1, name: "Foo" }, { id: 2, name: "Bar" }, { id: 3, name: "Foo-and-Bar" }]
export const mutateCollection = <T extends PlainObjectType>(
  items: T[],
  item: T,
  options: { key?: keyof T; delete?: boolean } = { key: "id", delete: false }
) => {
  const { key, delete: isDelete } = options

  if (key == null) {
    return items
  }

  if (isDelete) {
    return items.filter((x) => x[key] != item[key])
  }

  const foundIndex = items.findIndex((x) => x[key] == item[key])
  foundIndex >= 0 ? (items[foundIndex] = item) : items.push(item)

  return [...items]
}

// Format US Phone Number
// Example:
//   formatUSNumber('3334445555') => 333 444 5555
//   formatUSNumber('13334445555') => 333 444 5555
//   formatUSNumber('+13334445555') => 333 444 5555
export const formatUSNumber = (entry: string | number) => {
  const matches = entry
    .toString()
    .replace(/\D+/g, "")
    .replace(/^1/, "")
    .match(/([^\d]*\d[^\d]*){1,10}$/)

  const match = matches ? matches[0] : ""
  const part1 = match.length > 2 ? `${match.substring(0, 3)}` : match
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ""
  const part3 = match.length > 6 ? ` ${match.substring(6, 10)}` : ""

  return `${part1}${part2}${part3}`
}

// Get text content from HTML
export const parseHTMLTextContent = (html: string = "") =>
  new DOMParser().parseFromString(html, "text/html").documentElement.textContent

// Platform detection
export const isIE = !!(window.document as any)["documentMode"]
export const isWindows = /Win/.test(navigator.platform)

// Get date of earliest week day before day
export const getEarliestWeekDayBefore = (
  date: string,
  calendarViewStartDay: DaysOfWeekType
) => {
  const dayIndex = DaysOfWeek.indexOf(calendarViewStartDay)
  const selectedDate = parseISO(date)
  const daysToGoBack = (selectedDate.getDay() - dayIndex + 7) % 7
  const newStartDate = subDays(selectedDate, daysToGoBack)

  return newStartDate
}

export const getLatestWeekDayAfter = (
  date: string,
  calendarViewEndDay: DaysOfWeekType
) => {
  const dayIndex = DaysOfWeek.indexOf(calendarViewEndDay)
  const endDayIndex = dayIndex === 0 ? 6 : dayIndex - 1
  const selectedDate = parseISO(date)
  const daysToWeekEnd = Math.abs(selectedDate.getDay() - endDayIndex)
  const newEndDate = addDays(selectedDate, daysToWeekEnd)

  return newEndDate
}
