import React from "react"
import { ColorPicker, FormItem } from "@app/components/Form"
import { ProviderDefaultColor } from "@app/utils/constants"
import css from "./ProviderColor.module.scss"

type Props = {
  provider_color: string
}

const ProviderColor = (props: Props) => {
  const { provider_color } = props
  return (
    <FormItem
      label="Color:"
      name="provider_color"
      layout={[4, 3]}
      className={css.colorLabel}
    >
      <ColorPicker
        defaultValue={provider_color || ProviderDefaultColor}
        name="provider_color"
      />
    </FormItem>
  )
}

export default ProviderColor
