import React, {useEffect, useReducer} from "react";
import {Form, Button, Card} from "react-bootstrap";
import api from "@app/services/api"; 
import useRequest from "@app/utils/request";

interface Comment {
    name: string;
    message: string;
    created_at: string;
    updated_at: string
}

const SyncFailureComments:React.FC<any> = (props: {errorId: string}):JSX.Element => {
    const initialState = {
        message: "",
        comments: [],
        buttonDisabled: false
    }

    const reducer = (state: any, action: any) => {
        switch(action.type) {
            case "updateMessage":
                return {...state, message: action.payload}
            case "updateComments":
                return {...state, comments: action.payload, buttonDisabled: false, name: "", message: ""}
            case "togglebuttonDisabled":
                return {...state, buttonDisabled: !state.buttonDisabled}
            default:
                throw new Error("Incorrect action type")
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const {data: comments} = useRequest([api.AdminGetSyncErrorComments, props.errorId])

    const createMessage = () => {
        dispatch({type: "togglebuttonDisabled"});
        api.AdminCreateSyncErrorComment(props.errorId, state.message).then((res: any) => {
            dispatch({type: "updateComments", payload: [{name: res.name, message: res.message}, ...state.comments]})
        }).catch(err => console.error(err))
    }

    useEffect(() => {
        if(comments) {
            dispatch({type: "updateComments", payload: comments})
        }
    }, [comments])

    return (
        <>
            <Form.Group>
                <Form.Label>
                    Message
                </Form.Label>
                <Form.Control 
                    as="textarea" 
                    rows={3} 
                    onChange={e => dispatch({type: "updateMessage", payload: e.target.value})}
                    value={state.message}
                />
            </Form.Group>
            <Button onClick={createMessage} disabled={state.buttonDisabled}>
                Submit
            </Button>
            <div>
                {state.comments.map((comment: Comment, index: number) => (
                    <Card key={index} border="light" bg="info" style={{marginTop: "10px"}}>
                        <Card.Body style={{padding: "2px", margin: "2px"}}>
                            <Card.Title>
                                {comment.name}
                            </Card.Title>
                            <Card.Text>
                                {comment.message}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </>
    )
}

export default SyncFailureComments