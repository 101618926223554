import React from "react"
import { useNavigate } from "react-router-dom"
import BaseLayout from "./Base"
import Card from "@app/components/Card"
import Breadcrumb from "@app/components/Breadcrumb"
import { Row, Col } from "@app/components/Layout"
import Button from "@app/components/Button"
import css from "./BlockSchedulingForm.module.scss"

type Props = {
  title: string
  onSubmit: (redirectToBack: any) => any
  formControl?: any
}

export default ((props) => {
  const { title, onSubmit, formControl, children } = props
  const navigate = useNavigate()
  const redirectToBack = () => navigate("/")

  return (
    <BaseLayout>
      <Card
        disableBodyScroll
        title={
          <Breadcrumb className={css.breadcrumb}>
            <Breadcrumb.Item active>Block Scheduling</Breadcrumb.Item>
            <Breadcrumb.Item active className={css.title}>
              {title}
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        {children}
        <Row className="mt-4 justify-content-md-end">
          <Col md="auto">
            <Button
              variant="outline-primary"
              className="text-uppercase"
              onClick={redirectToBack}
            >
              Cancel
            </Button>
            <Button
              className="text-uppercase ml-3"
              onClick={() => onSubmit(redirectToBack)}
              control={formControl}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Card>
    </BaseLayout>
  )
}) as React.FC<Props>
