import { addDays, format, parseISO } from "date-fns"
import { Assignment } from "../../data"

export const calculateDatesToShow = (
  selectedDate: string,
  view: string,
  periodCount: number
) => {
  let dates = []
  const start = parseISO(selectedDate)
  if (view === "Day") {
    for (let i = 0; i < periodCount; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  } else if (view === "Week") {
    for (let i = 0; i < periodCount * 7; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  } else if (view === "Month") {
    for (let i = 0; i < 30 * periodCount; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  }
  return dates
}

export const processAssignmentsByDate = (
  unProcessedAssignments: AssignmentBaseType[]
) => {
  const preprocessedAssignments: { [date: string]: Assignment[] } = {}
  unProcessedAssignments?.forEach((assignment) => {
    const dateKey = format(parseISO(assignment.edate), "yyyy-MM-dd")
    if (!preprocessedAssignments[dateKey]) {
      preprocessedAssignments[dateKey] = []
    }
    preprocessedAssignments[dateKey].push(assignment)
  })
  return preprocessedAssignments
}

export const formatDate = (date: string): string =>
  format(parseISO(date), "yyyy-MM-dd")
