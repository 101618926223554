import React, { useReducer, useEffect } from "react"
import { Modal, Button } from "react-bootstrap"
import { FormControlLabel, Switch } from "@material-ui/core"
import Table from "@app/components/Table"
import Loader from "@app/components/Loader"
import css from "./SyncFailuresEditModal.module.scss"
import api from "@app/services/api"
import useRequest from "@app/utils/request"
import SyncFailureComments from "./SyncFailureComments"
import { formatDateToHumanReadable, errorTypeMap } from "./SyncFailures"
import FilterDropdown from "../components/FilterDropdown"

interface propTypes {
  show: boolean
  error: any
  caseid: number
  onHide: () => void
  changeOwner: (owner: string, errorId: string) => void
}

const SyncFailuresEditModal: React.FC<any> = (
  props: propTypes
): JSX.Element => {
  const initialState = {
    tableData: [],
    ownerTrainerName: "",
    owner: "",
    trainers: [],
    ownerButtonDisabled: false,
    resolveAttempt: false,
    syncErrorCase: {},
    loader: true,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "setTableData":
        return { ...state, tableData: action.payload }
      case "changeOwnerTrainerName":
        return { ...state, ownerTrainerName: action.payload }
      case "changeOwner":
        return { ...state, owner: action.payload }
      case "toggleOwnerButtonDisabled":
        return { ...state, ownerButtonDisabled: !state.ownerButtonDisabled }
      case "setTrainerData":
        return { ...state, trainers: action.payload }
      case "setSyncError":
        return { ...state, syncErrorCase: action.payload }
      case "toggleResolveAttempt":
        return {
          ...state,
          resolveAttempt: !!action.payload,
        }
      case "toggleLoader":
        return { ...state, loader: action.payload }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const { data: trainers } = useRequest([api.GetListOfTrainers])
  const { data: syncErrorCase } = useRequest([
    api.getSingleCaseError,
    props.caseid,
  ])

  useEffect(() => {
    if (trainers) {
      const mappedTrainerNames = trainers.map((trainer: any) => {
        return `${trainer.firstname} ${trainer.lastname} (${trainer.username})`
      })
      dispatch({ type: "setTrainerData", payload: mappedTrainerNames })
    }
  }, [trainers])

  useEffect(() => {
    if (syncErrorCase) {
      const mutateSyncErrorCaseErrors = (syncErrorCase: any) => {
        return {
          ...syncErrorCase,
          error_type: errorTypeMap[syncErrorCase.error_type],
          error: JSON.parse(syncErrorCase.error).map((syncError: any) => {
            return {
              ...syncError,
              error_type: errorTypeMap[syncErrorCase.error_type],
              stime: formatDateToHumanReadable(syncError.stime),
              etime: formatDateToHumanReadable(syncError.etime),
            }
          }),
        }
      }

      const formattedSyncErrorCase = mutateSyncErrorCaseErrors(syncErrorCase)

      dispatch({ type: "setTableData", payload: formattedSyncErrorCase.error })
      dispatch({
        type: "changeOwner",
        payload: formattedSyncErrorCase.owner
          ? `Assigned to: ${formattedSyncErrorCase.owner}`
          : "Not assigned",
      })
      dispatch({
        type: "changeOwnerTrainerName",
        payload: formattedSyncErrorCase.owner,
      })
      dispatch({
        type: "toggleResolveAttempt",
        payload: formattedSyncErrorCase.resolve_attempt,
      })

      dispatch({ type: "setSyncError", payload: formattedSyncErrorCase })
      dispatch({ type: "toggleLoader", payload: false })
    }
  }, [syncErrorCase])

  const columns = [
    {
      title: "Error",
      dataKey: "error_type",
    },
    {
      title: "Role token",
      dataKey: "role_token",
    },
    {
      title: "Job name",
      dataKey: "job_name",
    },
    {
      title: "User token",
      dataKey: "user_token",
    },
    {
      title: "Username",
      dataKey: "username",
    },
    {
      title: "Start time",
      dataKey: "stime",
    },
    {
      title: "End time",
      dataKey: "etime",
    },
  ]

  const updateSyncError = (resolveAttempt: boolean) => {
    dispatch({ type: "toggleOwnerButtonDisabled" })
    const newResolveAttempt = resolveAttempt
      ? !state.resolveAttempt
      : state.resolveAttempt
    api
      .UpdateSyncError(
        state.syncErrorCase.id,
        state.ownerTrainerName,
        newResolveAttempt
      )
      .then((res) => {
        if (resolveAttempt) {
          dispatch({
            type: "toggleResolveAttempt",
            payload: res.resolve_attempt,
          })
        } else {
          dispatch({
            type: "changeOwner",
            payload: `Assigned to: ${state.ownerTrainerName}`,
          })
        }
        dispatch({ type: "toggleOwnerButtonDisabled" })
        props.changeOwner(state.ownerTrainerName, state.syncErrorCase.id)
      })
  }

  return (
    <div className={css.modalContainer}>
      <Modal
        className={css.syncFailureModal}
        contentClassName={css.syncFailureModalContent}
        dialogClassName={css.syncFailureModalDialog}
        backdropClassName={css.syncFailureModalBackdrop}
        style={{ opacity: 1 }}
        show={props.show}
        onHide={props.onHide}
        animation={false}
        centered
      >
        <>
          <Modal.Header>
            <Modal.Title>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {state.loader ? (
                  <Loader animation="dots" />
                ) : (
                  <h2>
                    {`Case: ${state.syncErrorCase.caseid} ${state.syncErrorCase.error_type} in Group ${state.syncErrorCase.group_name} (${state.syncErrorCase.groupid})`}
                  </h2>
                )}
              </div>
              <h4>{state.owner}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ width: "100%" }}>
              <FilterDropdown
                selected={state.ownerTrainerName || "Select owner"}
                items={state.trainers}
                getSelected={(val: string) =>
                  dispatch({ type: "changeOwnerTrainerName", payload: val })
                }
              />
              <Button
                disabled={state.ownerButtonDisabled}
                onClick={() => updateSyncError(false)}
              >
                Assign
              </Button>
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={state.resolveAttempt}
                  onChange={() => updateSyncError(true)}
                />
              }
              label="Resolve Attempt"
            />
            {state.loader ? (
              <Loader animation="dots" />
            ) : (
              <Table data={state.tableData} columns={columns} />
            )}
            <SyncFailureComments errorId={state.syncErrorCase.id} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </>
      </Modal>
    </div>
  )
}

export default SyncFailuresEditModal
