import { debounce } from "lodash"
import { handleApiError } from "@app/utils"
import { ProvidersRules } from "@app/utils/constants"
import {
  Provider as CalendarProvider,
  ViolationQuickAssignment,
} from "@app/containers/spa/WhiteboardCalendar/data"
import api from "./api"
import { format, parseISO } from "date-fns"
import { getProviderIds } from "@app/containers/spa/WhiteboardCalendar/component/CalendarListDayElements/AssignmentJobElement/utils/getIsAssignmentFiltered"
import { useDispatch } from "@app/models"

type RuleApplied = {
  rule: string
  message: string
  data: any[]
}

const calculateQuickAssigmentViolationsDebounced = debounce(
  async (
    filteredQuickAssignments: AssignmentBaseType[],
    selectedQuickProvider: CalendarProvider | undefined,
    updateQuickAssignments: (
      assignmentCount: number,
      violations: ViolationQuickAssignment[]
    ) => void
  ) => {
    if (!selectedQuickProvider) {
      updateQuickAssignments(0, [])
      return
    }

    const jobDatePairs = filteredQuickAssignments.map((assignment) => ({
      jobid: Number(assignment.jobid),
      date: format(parseISO(assignment.edate), "yyyy-MM-dd"),
    }))

    try {
      const response = await Promise.all(
        jobDatePairs.map((pair) =>
          api.getProvidersV3({
            jobid: pair.jobid,
            date: pair.date,
            rules: ProvidersRules,
          })
        )
      )

      const jobDetailsPromises = filteredQuickAssignments.map((assignment) =>
        api.getJob(Number(assignment.jobid))
      )
      const jobDetails = await Promise.all(jobDetailsPromises)

      const violations = filteredQuickAssignments.reduce(
        (acc, assignment, index) => {
          const providerRules = response[index] ?? []
          const matchingProvider = providerRules.find(
            (provider: any) =>
              provider.providerid === selectedQuickProvider.providerid
          )

          if (
            matchingProvider &&
            Array.isArray(matchingProvider.rulesApplied) &&
            matchingProvider.rulesApplied.length > 0 &&
            matchingProvider.rulesApplied.some(
              (rule: RuleApplied) => Object.keys(rule).length > 0
            )
          ) {
            const jobDetail = jobDetails[index]
            acc.push({
              ...assignment,
              rulesApplied: matchingProvider.rulesApplied,
              job: jobDetail,
            })
          }

          return acc
        },
        [] as ViolationQuickAssignment[]
      )

      updateQuickAssignments(filteredQuickAssignments.length, violations)
    } catch (error) {
      handleApiError
      updateQuickAssignments(0, [])
    }
  },
  300
)

export const calculateQuickAssigmentViolations =
  calculateQuickAssigmentViolationsDebounced

const calculateQuickAssigmentJobViolationsDebounced = debounce(
  async (
    filteredQuickAssignments: AssignmentBaseType[],
    providersData: CalendarProvider[],
    updateQuickAssignments: (
      assignmentCount: number,
      violations: ViolationQuickAssignment[]
    ) => void
  ) => {
    if (!filteredQuickAssignments) {
      updateQuickAssignments(0, [])
      return
    }

    const jobDatePairs = filteredQuickAssignments.map((assignment) => ({
      jobid: Number(assignment.jobid),
      date: format(parseISO(assignment.edate), "yyyy-MM-dd"),
    }))

    try {
      const response = await Promise.all(
        jobDatePairs.map((pair) =>
          api.getProvidersV3({
            jobid: pair.jobid,
            date: pair.date,
            rules: ProvidersRules,
          })
        )
      )

      const violations = filteredQuickAssignments.reduce(
        (acc, assignment, index) => {
          const providerRules = response[index] ?? []
          const matchingProvider = providerRules.find(
            (provider: any) => provider.providerid === assignment.providerid
          )

          if (
            matchingProvider &&
            Array.isArray(matchingProvider.rulesApplied) &&
            matchingProvider.rulesApplied.length > 0 &&
            matchingProvider.rulesApplied.some(
              (rule: RuleApplied) => Object.keys(rule).length > 0
            )
          ) {
            const providerData = providersData.find(
              (provider) => provider.providerid === assignment.providerid
            )

            acc.push({
              ...assignment,
              rulesApplied: matchingProvider.rulesApplied,
              provider: {
                ...providerData!,
                avatar: providerData!.avatar || "",
                providerid: Number(providerData!.providerid),
              },
            })
          }

          return acc
        },
        [] as ViolationQuickAssignment[]
      )

      updateQuickAssignments(filteredQuickAssignments.length, violations)
    } catch (error) {
      handleApiError
      updateQuickAssignments(0, [])
    }
  },
  300
)

export const calculateQuickAssigmentJobViolations =
  calculateQuickAssigmentJobViolationsDebounced

export const handleMultipleAssignment = async (
  jobId: number,
  providerId: number,
  assignmentDate: string,
  providersFilteredAssignmentsByDate: Record<string, AssignmentBaseType[]>,
  draft_mode_scheduling: boolean,
  dispatch: ReturnType<typeof useDispatch>
): Promise<void> => {
  const assignmentsForDate =
    providersFilteredAssignmentsByDate[assignmentDate] || []
  const filteredAssignments = assignmentsForDate.filter(
    (assignment) => assignment.jobid === jobId
  )

  if (filteredAssignments.length > 0) {
    const jobConfig = await api.getJobMultipleAssignmentSettings(
      jobId,
      assignmentDate
    )
    const totalAssignmentsCount = filteredAssignments.reduce(
      (count, assignment) =>
        count + 1 + (assignment.additional_event_assignments?.length || 0),
      0
    )

    if (totalAssignmentsCount < jobConfig.maximum) {
      const multiProviderIds = getProviderIds(filteredAssignments)
      const updateFunction = draft_mode_scheduling
        ? api.updateDraftMultipleAssignments
        : api.updateMultipleAssignments
      const updateMultipleAssigment = await updateFunction(
        jobId,
        assignmentDate,
        Array.from(new Set([...multiProviderIds, providerId]))
      )
      dispatch.quickAssignments.addQuickAssignment(updateMultipleAssigment)
    }
  } else {
    await createSingleQuickAssignment(
      draft_mode_scheduling,
      assignmentDate,
      jobId,
      providerId,
      dispatch
    )
  }
}

export const createSingleQuickAssignment = async (
  draftModeScheduling: boolean,
  assignmentDate: string,
  jobId: number,
  providerId: number,
  dispatch: ReturnType<typeof useDispatch>
) => {
  const assignmentPayload = {
    edate: assignmentDate,
    jobid: jobId,
    providerid: providerId,
  }

  const singleQuickAssignment = draftModeScheduling
    ? await api.createDraftEvent(assignmentPayload)
    : await api.createSingleAssignment(assignmentPayload)

  const assignmentResponse = Array.isArray(singleQuickAssignment)
    ? singleQuickAssignment[0]
    : singleQuickAssignment
  dispatch.quickAssignments.addQuickAssignment(assignmentResponse)
}

export const handleDeleteMultipleQuickAssign = async (
  additionalAssignment: any,
  providersFilteredAssignmentsByDate: Record<string, AssignmentBaseType[]>,
  selectedProvider: any,
  dispatch: ReturnType<typeof useDispatch>
) => {
  const assignmentsForDate =
    providersFilteredAssignmentsByDate[additionalAssignment.edate] || []
  const filteredAssignments = assignmentsForDate.filter(
    (assignment) => assignment.jobid === additionalAssignment.job.jobid
  )
  const multiProviderIds = getProviderIds(filteredAssignments)
  const filteredProviderIds = multiProviderIds.filter(
    (id) => id !== additionalAssignment.providerid
  )

  const updateFunction = additionalAssignment.draft_eventid
    ? api.updateDraftMultipleAssignments
    : api.updateMultipleAssignments

  const response = await updateFunction(
    additionalAssignment.job.jobid,
    additionalAssignment.edate,
    filteredProviderIds
  )

  dispatch.quickAssignments.removeQuickAssignment(
    response.draft_eventid || response.eventid
  )

  dispatch.calendarEvents.getCalendarData()
}

export const handleDeleteQuickAssign = async (
  assignment: AssignmentBaseType,
  isProviderView: boolean,
  selectedJob: any,
  selectedProvider: any,
  dispatch: ReturnType<typeof useDispatch>
) => {
  const id = assignment?.draft_eventid || assignment?.eventid
  const deleteAssigment = assignment?.draft_eventid
    ? api.deleteDraftEvent
    : api.deleteEvent

  const totalAssigments = handleDeleteQuickMultipleAssignments(assignment)
  const isMultipleAssignmentJob =
    isProviderView &&
    selectedJob?.multiple_assignments &&
    totalAssigments.length > 1

  if (id) {
    if (isMultipleAssignmentJob) {
      const multiProviderIds = getProviderIds(assignment)
      const filteredProviderIds = multiProviderIds.filter(
        (id) => id !== assignment.providerid
      )
      const updateFunction = assignment.draft_eventid
        ? api.updateDraftMultipleAssignments
        : api.updateMultipleAssignments
      const response = await updateFunction(
        assignment.jobid,
        assignment.edate,
        filteredProviderIds
      )
      dispatch.quickAssignments.removeQuickAssignment(
        response.draft_eventid || response.eventid
      )
    } else {
      try {
        deleteAssigment(id)
        dispatch.quickAssignments.removeQuickAssignmentAndViolation(id)
      } catch (error) {
        handleApiError
      }
    }
    dispatch.calendarEvents.getCalendarData()
  }
}

export const handleDeleteQuickMultipleAssignments = (
  assignment: AssignmentBaseType
) => {
  const multiProviderIds = getProviderIds(assignment)
  return multiProviderIds
}
