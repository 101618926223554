import React, { useEffect, useState } from "react"
import Modal from "@app/components/Modal"
import css from "./ScheduleChangesModal.module.scss"

interface Props {
  isModalOpen: boolean
  onCloseModal: () => void
  date: string
}

export const ScheduleChangesModal = ({
  isModalOpen,
  onCloseModal,
  date,
}: Props) => {
  if (!date) return null

  const [content, setContent] = useState("")

  useEffect(() => {
    fetch(
      `/calendar/schedule_change_popup.cgi?get_schedule_changes=1&change_date=${date}&ts=${new Date().getTime()}`
    ).then(async (resp) => {
      const text = await resp.text()
      setContent(text?.replace(/<br\s*\/>/g, " "))
    })
  }, [])

  return (
    <Modal
      className={css.modal}
      centered
      show={isModalOpen}
      title="Schedule Change"
      onHide={onCloseModal}
    >
      <div
        className={css.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Modal>
  )
}
