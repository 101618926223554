import React from "react"
import { DefaultCalendarColor } from "@app/utils/constants"
import css from "../CalendarListView.module.scss"
import cx from "classnames"
import CalendarListViewCell from "./CalendarListViewCell"

type PropsType = {
  index: number
  compactListView: boolean
  isProviderView: boolean
  item: any
  isUnderstaffedJobsHighlightActive: boolean
  datesToShow: any[]
  renderNumberOfUnderstaffedJobs: (
    dates: string[],
    jobId: number,
    jobDaysType: JobDayType[] | undefined
  ) => JSX.Element | null
  renderAssignmentForDate: (date: string, item: any) => void
}

const CalendarListViewRow = ({
  index,
  compactListView,
  isProviderView,
  item,
  isUnderstaffedJobsHighlightActive,
  renderNumberOfUnderstaffedJobs,
  renderAssignmentForDate,
  datesToShow,
}: PropsType) => {
  return (
    <tr key={index}>
      <td
        className={cx(css.listViewCell, {
          [css.miniListViewCell]: compactListView,
        })}
      >
        {isProviderView ? (
          <div className={css.headListProvider}>
            <div className={css.providerWrapper}>
              <div
                className={css.providerCellCircle}
                style={{
                  background: item.color || DefaultCalendarColor,
                }}
              />
              <div className={css.providerLabelName}>{item.name}</div>
            </div>
          </div>
        ) : (
          <div className={css.headListJob}>
            <div
              className={css.jobCellRectangle}
              style={{
                background: item.color || DefaultCalendarColor,
              }}
            />
            <div className={css.providerLabelName}>{item.name}</div>
            {isUnderstaffedJobsHighlightActive &&
              renderNumberOfUnderstaffedJobs(
                datesToShow,
                Number(item.id),
                item.job_days_type
              )}
          </div>
        )}
      </td>

      {datesToShow.map((date, dateIndex) => (
        <CalendarListViewCell
          key={`${index}-${dateIndex}`}
          compactListView={compactListView}
          isProviderView={isProviderView}
          renderAssignmentForDate={renderAssignmentForDate}
          date={date}
          item={item}
        />
      ))}
    </tr>
  )
}

export default CalendarListViewRow
