import React, { useEffect } from "react"
import BlockSchedulingForm from "@app/containers/layouts/BlockSchedulingForm"
import {
  Select,
  Checkbox,
  Input,
  FormItem,
  useForm,
} from "@app/components/Form"
import { useParams } from "react-router-dom"
import { useSelector } from "@app/models"
import api, { useRequest, mutate } from "@app/services/api"
import {
  handleApiError,
  renderBlockSchedulingMenus,
  parseDate,
} from "@app/utils"

type FormFieldsType = {
  name: string
  start_year: number
  start_date: string
  end_date: string
  view_on_list: boolean
  block_layout_ids: string[]
}

export default () => {
  const { id } = useParams() // id of annual schedule if have
  const { data: record } = useRequest(
    id ? [api.getAnnualBlockSchedule, id] : null
  )

  const { control, setValue, watch, handleSubmit } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          name: yup.string().max(80).required().label("Name"),
          start_year: yup.number().required().label("Academic Year"),
          start_date: yup
            .string()
            .required("Start Date is an invalid date")
            .test(dateValidate("start_date")),
          end_date: yup
            .string()
            .required("End Date is an invalid date")
            .test(dateValidate("end_date")),
          block_layout_ids: yup
            .array()
            .ensure()
            .of(yup.string())
            .min(1)
            .label("Block Layout"),
        })
      ),
  })

  const { data: allBlockLayouts } = useRequest([api.getBlockLayouts])
  const { data: allBlockYears } = useRequest([api.getBlockYears])
  const { groupid } = useSelector((state) => state.users.currentUser)
  const currentStartYear: number = watch("start_year")

  const startYearRange = {
    start_date: currentStartYear,
    end_date: currentStartYear + 1,
  }

  // Update start_date and end_date when year changed
  useEffect(() => {
    if (id || !currentStartYear) {
      return
    }

    setValue("start_date", `${startYearRange["start_date"]}-07-01`)
    setValue("end_date", `${startYearRange["end_date"]}-06-30`)
  }, [currentStartYear])

  const dateValidate = (field: "start_date" | "end_date") => {
    return {
      name: "withinYear",
      exclusive: true,
      params: { year: startYearRange[field] },
      message: "Date must be in the year of ${year}",
      test: (value: any) => !checkIfDisabledDays(field)(parseDate(value)),
    }
  }

  const checkIfDisabledDays = (field: "start_date" | "end_date") =>
    currentStartYear
      ? (date: any) => date.getFullYear() != startYearRange[field]
      : () => false

  const onSubmit = (fields: FormFieldsType, cb: any) => {
    const requestParams = { ...fields, groupid }
    const requestHandler = id
      ? api.updateAnnualBlockSchedule(id, requestParams)
      : api.createAnnualBlockSchedule(requestParams)

    return requestHandler.then(() => {
      api.getAnnualBlockSchedules().then((annualBlockSchedules: any) => {
        mutate([api.getAnnualBlockSchedules], annualBlockSchedules, false)
        if (id) mutate([api.getAnnualBlockSchedule, id])

        // Redirect back
        cb()

        // Update BlockScheduling menu without refreshing page
        renderBlockSchedulingMenus(annualBlockSchedules)
      })
    }, handleApiError)
  }

  return (
    <BlockSchedulingForm
      title="Annual Schedules"
      onSubmit={handleSubmit(onSubmit)}
      formControl={control}
    >
      <div className="p-4">
        <FormItem
          required
          label="Name"
          name="name"
          control={control}
          defaultValue={record?.name}
        >
          <Input />
        </FormItem>
        <FormItem
          required
          label="Academic Year"
          name="start_year"
          control={control}
          defaultValue={record?.start_year}
        >
          <Select
            options={allBlockYears}
            loading={!allBlockYears}
            valueKey="start_year"
            labelKey="display_name"
          />
        </FormItem>
        <FormItem
          required
          label="Start Date"
          name="start_date"
          control={control}
          defaultValue={record?.start_date}
        >
          <Input
            type="date"
            dayPickerProps={{
              disabledDays: checkIfDisabledDays("start_date"),
            }}
          />
        </FormItem>
        <FormItem
          required
          label="End Date"
          name="end_date"
          control={control}
          defaultValue={record?.end_date}
        >
          <Input
            type="date"
            dayPickerProps={{
              disabledDays: checkIfDisabledDays("end_date"),
            }}
          />
        </FormItem>
        <FormItem
          label="View on Menu"
          name="view_on_list"
          control={control}
          defaultValue={record?.view_on_list}
        >
          <Checkbox />
        </FormItem>
        <FormItem
          required
          label="Block Layout"
          name="block_layout_ids"
          control={control}
          defaultValue={record?.block_layouts?.map((x: any) => x.id)}
        >
          <Select
            inline
            multiple
            options={allBlockLayouts}
            loading={!allBlockLayouts}
          />
        </FormItem>
      </div>
    </BlockSchedulingForm>
  )
}
