import React, { useContext } from "react";
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext";
import { formatTime } from "../../service";

const SplitShiftJobRow: React.FC = () => {
    const { currentSplitShiftJobId, jobs } = useContext(ManualScheduleTableContext);
    return (
        <>
            <tr>
                <td></td>
                <td>
                    {jobs.find(j => j.jobid == currentSplitShiftJobId)?.name}
                </td>
                <td>
                    {formatTime(jobs.find(j => j.jobid == currentSplitShiftJobId)?.starttime as string)} - {formatTime(jobs.find(j => j.jobid == currentSplitShiftJobId)?.endtime as string)}
                </td>
                <td></td>
                <td></td>
            </tr>
        </>
    );
}

export default SplitShiftJobRow;