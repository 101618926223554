import { useFieldArray, useForm } from "@app/components/Form"
import {
  MultipleAssignmentJob,
  SplitShift,
} from "@app/containers/spa/WhiteboardCalendar/data"

type EventBaseTypeForm = {
  jobid: number
  providerid: number
  edate: string
  eventid?: number
  draft_eventid?: number
  original_providerid?: number
}

export type EventForm = {
  type: "event" | "draft_event"
  data: EventBaseTypeForm
  changed?: boolean
}

export type SplitShiftForm = {
  type: "split_shift"
  data: (EventBaseTypeForm & {
    starttime: string
    endtime: string
    tally_credit: number
  })[]
  changed?: boolean
}

export type UnassignedForm = {
  type: "unassigned"
  data: {
    jobid: number
    edate: string
    providerid?: number
    original_providerid?: number
  }
  changed?: boolean
}

export type MultipleAssignmentForm = {
  type: "multiple_assignments" | "draft_multiple_assignments"
  data: EventBaseTypeForm[]
  changed?: boolean
}

export type FormElement = (
  | EventForm
  | MultipleAssignmentForm
  | SplitShiftForm
  | UnassignedForm
) & { id?: string }

export type FormData = {
  formElements: FormElement[]
}

export default () => {
  const getElementSchema = (yup: any, value: FormElement) => {
    if (value.type === "event" || value.type === "draft_event") {
      return yup.object().shape({
        type: yup.string().required(),
        data: yup.object().shape({
          jobid: yup.number().required(),
          providerid: yup.number().required(),
          edate: yup.string().required(),
        }),
      })
    } else if (
      value.type === "multiple_assignments" ||
      value.type === "draft_multiple_assignments"
    ) {
      return yup.object().shape({
        type: yup.string().required(),
        data: yup.array().of(
          yup.object().shape({
            jobid: yup.number().required(),
            providerid: yup.number().required(),
            edate: yup.string().required(),
          })
        ),
      })
    } else if (value.type === "split_shift") {
      return yup.object().shape({
        type: yup.string().required(),
        data: yup.array().of(
          yup.object().shape({
            jobid: yup.number().required(),
            providerid: yup.number().required(),
            edate: yup.string().required(),
            starttime: yup.string().required(),
            endtime: yup.string().required(),
            tally_credit: yup.number().required(),
          })
        ),
      })
    } else if (value.type == "unassigned") {
      return yup.object().shape({
        type: yup.string().required(),
        data: yup.object().shape({
          jobid: yup.number().required(),
          edate: yup.string().required(),
        }),
      })
    }
  }
  const useFormFuncs = useForm<FormData>({
    defaultValues: { formElements: [] },
    mode: "onChange",
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          formElements: yup
            .array()
            .of(yup.lazy((value) => getElementSchema(yup, value)) as any),
        })
      ),
  })

  const formFieldArrayFuncs = useFieldArray({
    control: useFormFuncs.control,
    name: "formElements",
  })

  return { ...useFormFuncs, ...formFieldArrayFuncs }
}

export const transformJobsTableData = (
  jobsTableData: MultipleAssignmentJob[]
): FormData["formElements"] => {
  return jobsTableData.map((jobTableData: MultipleAssignmentJob) => {
    if (
      jobTableData.assignments[0] &&
      !jobTableData.split_shift.length &&
      !jobTableData.additional_event_assignments.length &&
      !jobTableData.multiple_assignments
    ) {
      const assignment = jobTableData.assignments[0]
      return {
        type: jobTableData.status === "Published" ? "event" : "draft_event",
        data: {
          providerid: assignment.providerid,
          jobid: Number(assignment.jobid),
          edate: assignment.edate,
        },
      }
    }
    if (
      jobTableData.multiple_assignments &&
      jobTableData.assignments.length &&
      !jobTableData.split_shift.length
    ) {
      const additionalAssignments =
        jobTableData.additional_event_assignments || []
      const allAssignments = [
        jobTableData.assignments[0],
        ...additionalAssignments,
      ]
      return {
        type:
          jobTableData.status === "Published"
            ? "multiple_assignments"
            : "draft_multiple_assignments",
        data: allAssignments.map((assignment: AssignmentBaseType) => ({
          providerid: assignment.providerid,
          jobid: Number(assignment.jobid) || Number(assignment.job.jobid),
          edate: jobTableData.edate,
          eventid: assignment.eventid,
          draft_eventid: assignment.draft_eventid,
          original_providerid: assignment.providerid,
        })),
      }
    }
    if (
      jobTableData.assignments.length > 1 &&
      jobTableData.split_shift.length
    ) {
      return {
        type: "split_shift",
        data: jobTableData.split_shift.map((splitShift: SplitShift) => ({
          providerid: splitShift.providerid,
          jobid: Number(jobTableData.jobid),
          edate: splitShift.edate,
          starttime: splitShift.starttime,
          endtime: splitShift.endtime,
          tally_credit: splitShift.tally_credit,
        })),
      }
    }
    return {
      type: "unassigned",
      data: {
        jobid: Number(jobTableData.jobid),
        edate: jobTableData.edate,
      },
    }
  })
}
