import React, { useContext, useEffect, useRef, useState } from "react"
import { IProviderInfo, IAdditionalAssignmentItem } from "../../data"
import Form from "react-bootstrap/Form"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import ClearIcon from "@material-ui/icons/Clear"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import { Text } from "@app/components/Typography"
// import cx from "classnames"
// import css from "./Avatar.module.scss"

export interface IMultipleAssignmentsConfigBoxRowProps {
  rerender: number
  rowData: IAdditionalAssignmentItem | null
  handleOnChange: (propname: "providerid", v: any) => Promise<void>
  handleClearRow: () => void
  providers: IProviderInfo[]
  index: number
  isActive: Boolean
  targetLevel: "ideal" | "minimum" | "maximum"
}

const MultipleAssignmentsConfigBoxRow: React.FC<
  IMultipleAssignmentsConfigBoxRowProps
> = (props) => {
  const { edate, currentMultipleAssignmentsJobId } = useContext(
    ManualScheduleTableContext
  )

  const rowInitValue = {
    jobid: currentMultipleAssignmentsJobId!,
    eventid: undefined,
    providerid: null,
    edate: edate,
  }

  const [rowData, setRowData] =
    useState<IAdditionalAssignmentItem>(rowInitValue)
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    handleResetRow()
    if (props.rowData) {
      setRowData({
        ...rowInitValue,
        ...props.rowData,
      })
    } else {
      setRowData(rowInitValue)
    }
  }, [props.rowData])

  useEffect(() => {
    if (props.rowData) {
      setRowData({
        ...rowInitValue,
        ...props.rowData,
      })
    } else {
      setRowData(rowInitValue)
    }
  }, [props.rerender])

  const handleResetRow = () => {
    if (formRef && formRef.current) {
      formRef.current.reset()
    }
  }

  const targetLevelColor = (targetLevel: string) => {
    switch (targetLevel) {
      case "minimum":
        return "gray"
      case "ideal":
        return "blue"
      case "maximum":
        return "red"
      default:
        return "red"
    }
  }

  return (
    <>
      <form ref={formRef}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          style={{ padding: "16px 16px 0 16px" }}
        >
          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            wrap="nowrap"
            style={{ maxWidth: 120 }}
          >
            <Text>Assignment {props.index + 1}</Text>
          </Grid>

          <Grid item style={{ maxWidth: 200 }}>
            <Text
              prefixDotColor={targetLevelColor(props.targetLevel)}
              style={{ marginLeft: "80px", width: "100px" }}
            ></Text>
          </Grid>
          <Grid item>
            <Form.Control
              as="select"
              placeholder="Provider"
              disabled={!props.isActive}
              onChange={(e) =>
                props.handleOnChange(
                  "providerid",
                  (e.target as HTMLInputElement).value
                )
              }
              value={rowData.providerid || ""}
              style={{
                width: 240,
                backgroundColor: "#F0FBFF",
              }}
            >
              <option value=""> Please select provider</option>
              {props.providers.map((p) => {
                return (
                  <option
                    key={p.providerid}
                    value={p.providerid}
                  >{`${p.display_name} - ${p.name}`}</option>
                )
              })}
            </Form.Control>
          </Grid>

          <Grid item>
            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={() => props.handleClearRow()}
              style={{
                width: 22,
                height: 22,
                padding: 0,
                backgroundColor: "#FFFFFF",
                color: "#3080DF",
                border: "1px solid #CDD9DE",
                visibility: props.isActive ? "visible" : "hidden",
              }}
            >
              <ClearIcon fontSize="small" style={{ color: "#A2B0BC" }} />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default MultipleAssignmentsConfigBoxRow
