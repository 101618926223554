import React, { useEffect, useState, MutableRefObject, useRef } from "react"

import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card"
import Loader from "@app/components/Loader"
import DayPickerInput from "react-day-picker/DayPickerInput"
import dateFnsFormat from "date-fns/format"
import dateFnsParse from "date-fns/parse"
import Button from "@app/components/Button"
import { Dialog } from "@app/components/Modal"
import { Radio } from "@app/components/Form"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import { DateUtils } from "react-day-picker"
import css from "./DraftModePublish.module.scss"

type PublishForm = {
  jobid: string
  selectedJob: string
  startDate: string
  endDate: string
  notifyProviders: string
  notifySchedulers: boolean
}

const DraftModePublish: React.FC<any> = (props): JSX.Element => {
  const [showLoader, setShowLoader] = useState(true)
  const [callJobVars, setCallJobVars] = useState([] as any)
  const [workJobVars, setWorkJobVars] = useState([] as any)
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [formValues, setFormValues] = useState({} as PublishForm)
  const [showNotifyProviderOptions, setShowNotifyProviderOptions] =
    useState(false)
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const pickerFormat = "M/d/y"

  useEffect(() => {
    api.getJobs(1).then(function (response) {
      setCallJobVars(response)
    })
    api.getJobs(2).then(function (response) {
      setWorkJobVars(response)
      setShowLoader(false)
    })

    setFormValues({
      ...formValues,
      jobid: "0",
      selectedJob: "",
      startDate: "",
      endDate: "",
      notifySchedulers: false,
    })
  }, [])

  const modifiers = { start: startDate, end: endDate }
  const formatDate = (date: Date, format?: any, locale?: any): string => {
    return dateFnsFormat(date, format, { locale })
  }
  const parseDate = (datestring: string, format: string, locale: any): any => {
    const parsed = dateFnsParse(datestring, format, new Date(), { locale })
    if (DateUtils.isDate(parsed)) {
      return parsed
    }
    return undefined
  }
  const endDateRef: MutableRefObject<DayPickerInput | null> = useRef(null)

  const onStartDayChange = (date: Date) => {
    var tmpDateString = ""
    if (date !== undefined) {
      tmpDateString = dateFnsFormat(date, "yyyy-MM-dd")
      setStartDate(date)
    }

    setFormValues({ ...formValues, startDate: tmpDateString })
  }
  const onEndDayChange = (date: Date) => {
    var tmpDateString = ""
    if (date !== undefined) {
      tmpDateString = dateFnsFormat(date, "yyyy-MM-dd")
      setEndDate(date)
    }

    setFormValues({ ...formValues, endDate: tmpDateString })
  }

  const handleFormChange = (e: any) => {
    var prop = e.target.name
    var val = e.target.value

    if (e.target.name == "jobid") {
      var selectedJobName = e.target.options[e.target.selectedIndex].text
      setFormValues({ ...formValues, selectedJob: selectedJobName, jobid: val })
      return
    }

    if (e.target.type == "checkbox") {
      val = e.target.checked
    }

    setFormValues({ ...formValues, [prop]: val })
  }

  useEffect(() => {
    //check if we have enough data to submit the form
    var isReady = true
    if (formValues.jobid == "0") {
      isReady = false
    }
    if (String(formValues.startDate).length == 0) {
      isReady = false
    }
    if (String(formValues.endDate).length == 0) {
      isReady = false
    }
    setReadyToSubmit(isReady)
  }, [formValues])

  const confirmSubmit = (e: any) => {
    e.preventDefault()

    Dialog.confirm({
      title: "Publish Draft Events",
      message: (
        <p>
          Are you sure you want to publish the draft events
          <br key="br0" />
          for{" "}
          <em key="em0">
            {dateFnsFormat(startDate, "MM/dd/yyyy")} -{" "}
            {dateFnsFormat(endDate, "MM/dd/yyyy")}
          </em>{" "}
          for <em key="em1">{formValues.selectedJob}</em>?<br key="br1" />
          This will allow your providers to view the published events.
        </p>
      ),
      buttons: {
        ok: {
          text: "Publish",
          onClick() {
            return handleSubmit()
          },
        },
      },
    })
  }

  const handleSubmit = () => {
    setShowLoader(true)
    const jobIDs = []
    if (formValues.jobid == "*") {
      for (let job of callJobVars) {
        jobIDs.push(job.jobid)
      }
      for (let job of workJobVars) {
        jobIDs.push(job.jobid)
      }
    } else {
      jobIDs.push(formValues.jobid)
    }

    api
      .publishDraftEvents({
        jobids: jobIDs,
        start_date: formValues.startDate,
        end_date: formValues.endDate,
        notify_scheduled_providers:
          formValues.notifyProviders === "notifyScheduledProviders",
        notify_all_providers:
          formValues.notifyProviders === "notifyAllProviders",
        notify_schedulers: formValues.notifySchedulers,
      })
      .then(
        function (response) {
          window.location.href = `/calendar/manual_schedule.cgi?view=month&wt=comp&year=${
            startDate.getYear() + 1900
          }&month=${startDate.getMonth() + 1}`
        },
        function (err) {
          setShowLoader(false)
          handleApiError(err)
        }
      )
  }

  if (showLoader == true) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <div className="w-100 p-3 d-flex justify-content-center">
            <Loader animation="dots" />
          </div>
        </Card>
      </BaseLayout>
    )
  }

  return (
    <>
      <div className="row">
        <div className="col-xs-8 col-xs-offset-2 main-content">
          <div className="row">
            <div className="col-xs-12">
              <h1>Publish Assignments</h1>
            </div>
          </div>
          <form onSubmit={(e) => confirmSubmit(e)}>
            <div className="row">
              <div className="col-xs-10 select-container form-group">
                <label className="form-label" htmlFor="jobid">
                  Job
                </label>
                <select
                  className="form-control"
                  id="jobid"
                  name="jobid"
                  onChange={handleFormChange}
                  defaultValue={formValues.jobid}
                >
                  <option key="0" value="0">
                    Select a job...
                  </option>
                  <option key="*" value="*">
                    All Jobs
                  </option>
                  <optgroup label="Call Jobs">
                    {callJobVars.map((job: any) => (
                      <option key={job.jobid} value={job.jobid}>
                        {job.name}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Work Jobs">
                    {workJobVars.map((job: any) => (
                      <option key={job.jobid} value={job.jobid}>
                        {job.name}
                      </option>
                    ))}
                  </optgroup>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-10 form-group">
                <p>
                  <label className="form-label">Assignment Dates</label>
                </p>
                <div className={css.calendar}>
                  <DayPickerInput
                    placeholder="Choose Start Date"
                    format={pickerFormat}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    value={startDate}
                    inputProps={{
                      required: true,
                      style: {
                        height: "30px",
                        border: "1px solid #D8DCE3",
                        borderRadius: "7px",
                      },
                    }}
                    dayPickerProps={{
                      selectedDays: [startDate, { startDate, endDate }],
                      disabledDays: { after: endDate },
                      toMonth: endDate,
                      modifiers,
                      numberOfMonths: 1,
                      onDayClick: () => {
                        if (endDateRef && endDateRef.current !== null) {
                          endDateRef.current.getInput().focus()
                        }
                      },
                    }}
                    onDayChange={(date) => onStartDayChange(date)}
                  />{" "}
                  {" - "}
                  <DayPickerInput
                    placeholder="Choose End Date"
                    format={pickerFormat}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    value={endDate}
                    inputProps={{
                      required: true,
                      style: {
                        height: "30px",
                        border: "1px solid #D8DCE3",
                        borderRadius: "7px",
                      },
                    }}
                    dayPickerProps={{
                      selectedDays: [startDate, { startDate, endDate }],
                      disabledDays: { before: startDate },
                      month: startDate,
                      fromMonth: startDate,
                      modifiers,
                      numberOfMonths: 1,
                      onDayClick: () => {
                        if (endDateRef && endDateRef.current !== null) {
                          endDateRef.current.getInput().focus()
                        }
                      },
                    }}
                    onDayChange={(date) => onEndDayChange(date)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-10 form-group">
                <input
                  type="checkbox"
                  id="notify_providers"
                  name="notifyProviders"
                  checked={showNotifyProviderOptions}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFormValues({
                        ...formValues,
                        notifyProviders: "notifyScheduledProviders",
                      })
                    } else {
                      setFormValues({ ...formValues, notifyProviders: "" })
                    }
                    setShowNotifyProviderOptions(e.target.checked)
                  }}
                />{" "}
                &nbsp; {"  "}
                <label htmlFor="notify_providers">Notify providers</label>
              </div>
            </div>
            {showNotifyProviderOptions && (
              <div className="text-nowrap" style={{ marginLeft: "20px" }}>
                <Radio.Group
                  name="notify_providers"
                  options={[
                    {
                      id: "notifyScheduledProviders",
                      name: "Notify any provider assigned to a published draft event",
                    },
                    {
                      id: "notifyAllProviders",
                      name: "Notify all providers in the group",
                    },
                  ]}
                  onChange={(val: string) =>
                    setFormValues({ ...formValues, notifyProviders: val })
                  }
                  defaultValue="notifyScheduledProviders"
                />
              </div>
            )}
            <div className="row">
              <div className="col-xs-10 form-group">
                <input
                  type="checkbox"
                  id="notify_schedulers"
                  name="notifySchedulers"
                  onChange={handleFormChange}
                  checked={formValues.notifySchedulers}
                />{" "}
                &nbsp; {"  "}
                <label htmlFor="notify_schedulers">Notify all schedulers</label>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-10 form-group">
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={readyToSubmit ? false : true}
                >
                  Publish
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default DraftModePublish
