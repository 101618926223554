import React, { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "@app/models"
import CloseIcon from "@material-ui/icons/Close"
import { Provider as CalendarProvider } from "@app/containers/spa/WhiteboardCalendar/data"
import ProvidersMenu from "./ProvidersMenu"
import { JobsMenu } from "./JobsMenu/JobsMenu"
import css from "./QuickAssignmentMenu.module.scss"

interface QuickAssignmentMenuProps {
  quickAssigneePanelOpen: boolean
  onShowQuickAssigneePanel: () => void
  jobsData: JobAssignment[]
  providersData: CalendarProvider[]
  onClose: () => void
}

export const QuickAssignmentMenu = ({
  quickAssigneePanelOpen,
  onShowQuickAssigneePanel,
  jobsData,
  providersData,
  onClose,
}: QuickAssignmentMenuProps) => {
  const { listViewType } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const dispatch = useDispatch()

  const resetQuickMode = useCallback(() => {
    dispatch.calendarEvents.setSelectedJob(undefined)
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.quickAssignments.resetQuickAssignments()
  }, [dispatch])

  useEffect(() => {
    resetQuickMode()
  }, [listViewType])

  return (
    <div
      className={`${css.quickModal} ${
        quickAssigneePanelOpen ? css.quickModalOpen : ""
      }`}
    >
      <div className={css.quickModalHeaderContainer}>
        <span className={css.quickModalTitle}>Quick Assign</span>
        <CloseIcon
          className={css.closeRightModalIcon}
          onClick={() => {
            onShowQuickAssigneePanel()
            onClose()
          }}
        />
      </div>

      {listViewType && listViewType === "job" ? (
        <ProvidersMenu providersData={providersData} />
      ) : (
        <JobsMenu jobsData={jobsData} providersData={providersData} />
      )}
    </div>
  )
}
