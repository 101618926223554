import React, { useContext } from "react"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import { ISplitShiftItem, IAdditionalAssignmentItem } from "../../data"
import { formatTime } from "../../service"
import ManualScheduleJobRowProviderSelect from "./ManualScheduleJobRowProviderSelect"
import FieldErrorMessage from "../ManualScheduleFormValidation/FieldErrorMessage"
import SplitShiftOperationButton from "./SplitShiftOperationButton"
import SplitShiftScheduleCol from "./SplitShiftScheduleCol"
import MultipleAssignmentsCol from "./MultipleAssignmentsCol"

export interface IManualScheduleJobRowProps {
  flagHref: string | undefined
  flag: string
  jobHref: string
  jobid: number
  starttime: string
  endtime: string
  scheduled: string
  name: string
  splitShifts: ISplitShiftItem[]
  multiple_assignments: boolean
  additionalAssignments: IAdditionalAssignmentItem[]
}
const ManualScheduleJobRow: React.FC<IManualScheduleJobRowProps> = (props) => {
  const { splitShiftFlag, multipleAssignmentsFlag, jobs, tab } = useContext(
    ManualScheduleTableContext
  )

  return (
    <>
      <tr>
        <td align="center" className="small_blk indicator_glyph_table_cell">
          {props.flagHref ? (
            <a href={props.flagHref} className="small_blk">
              {props.flag && props.flag != "null" ? (
                <div dangerouslySetInnerHTML={{ __html: props.flag }}></div>
              ) : (
                <></>
              )}
            </a>
          ) : null}
        </td>
        <td className="small_blk job_description_table_cell">
          <a href={props.jobHref} className="small_blk">
            {props.name}
          </a>
        </td>
        <td
          className="small_blk job_description_table_cell"
          style={{ whiteSpace: "nowrap" }}
        >
          {formatTime(props.starttime)}-{formatTime(props.endtime)}
        </td>
        <td className="small_blk scheduled_drop_down_table_cell">
          <div className="d-flex">
            {splitShiftFlag &&
            props.splitShifts &&
            props.splitShifts.length > 0 ? (
              <SplitShiftScheduleCol
                jobId={props.jobid}
                splitShifts={props.splitShifts}
              />
            ) : multipleAssignmentsFlag && props.multiple_assignments ? (
              <MultipleAssignmentsCol
                jobId={props.jobid}
                additionalAssignments={props.additionalAssignments}
                multiple_assignments={props.multiple_assignments}
              />
            ) : (
              <ManualScheduleJobRowProviderSelect jobId={props.jobid} />
            )}
            {tab !== "draft" &&
            (!props.multiple_assignments ||
              (props.splitShifts && props.splitShifts.length > 0)) &&
            splitShiftFlag ? (
              <SplitShiftOperationButton
                jobId={props.jobid}
                splitShifts={props.splitShifts}
              />
            ) : null}
          </div>
          <FieldErrorMessage
            jobId={props.jobid}
            providerId={
              jobs.find((job) => job.jobid === props.jobid)?.providerid
            }
            splitShift={props.splitShifts}
          />
        </td>
      </tr>
    </>
  )
}

export default ManualScheduleJobRow
