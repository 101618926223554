import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "@app/models"
import { useSearchParams } from "react-router-dom"
import { H4, H6, Text } from "@app/components/Typography"
import DailyArchiveModal from "./DailyArchiveModal"
import ProviderModal from "./ProviderModal"
import SendSecureMessageModal from "./SendSecureMessageModal"
import ProtocolViewer from "./ProtocolViewer"
import JobBlock from "./JobBlock"
import { utcToZonedTime } from "date-fns-tz"
import {
  processJobRows,
  filterJobRows,
  mergeJobRows,
  JobBlockType,
} from "@app/services/mdvShiftCalculation"
import api, { useRequest } from "@app/services/api"

import cx from "classnames"
import css from "./Shift.module.scss"

type Props = {
  customCalendar: CustomCalendarType
  group: CustomCalendarGroupType
  setPickedJobForScheduleChange: (job: JobBlockType | null) => any
  forceReload: number | null
}

export default (props: Props) => {
  const { customCalendar, group, setPickedJobForScheduleChange, forceReload } =
    props
  const { key: customCalendarKey, timezone } = customCalendar

  const dispatch = useDispatch()

  const [dailyArchiveShow, setDailyArchiveShow] = useState<boolean>(false)
  const [pickedProviderId, setPickedProviderId] = useState<null | number>(null)
  const [pickedSendSecureMessageSource, setPickedSendSecureMessageSource] =
    useState<{ type: "provider" | "job"; id: number } | null>(null)

  const getTimezoneNow = () =>
    utcToZonedTime(new Date().toISOString(), timezone)

  const [now, setNow] = useState(getTimezoneNow())

  const { customCalendarFilterConditions } = useSelector(
    (state) => state.customCalendars
  )

  useEffect(() => {
    const interval = setInterval(() => setNow(getTimezoneNow()), 60 * 1000)
    return () => clearInterval(interval)
  }, [timezone])

  // Get `date` from URL query
  const [searchParams] = useSearchParams()
  const date = searchParams.get("date")
  const params = useMemo(() => (date ? { date } : undefined), [date])
  const { data: groupDetails, mutate } =
    useRequest<CustomCalendarGroupDetailsType>([
      api.getCustomCalendarGroupDetails,
      customCalendarKey,
      group.groupid,
      params,
    ])

  useEffect(() => {
    if (forceReload === group.groupid) mutate()
  }, [forceReload])

  const {
    name,
    daily_archived,
    shift_assigned,
    jobs,
    protocols,
    calendar_notes,
  } = groupDetails
    ? groupDetails
    : {
        name: group.name,
        daily_archived: false,
        shift_assigned: false,
        jobs: undefined,
        protocols: [],
        calendar_notes: [],
      }

  // Processed jobs based on conditions
  const jobRows = useMemo(
    () => processJobRows(jobs, now, date),
    [now, jobs, date]
  )

  const jobRowsByConditions = useMemo(() => {
    return filterJobRows(jobRows, customCalendarFilterConditions)
  }, [jobRows, customCalendarFilterConditions])

  const mergedJobRows = useMemo(() => {
    return mergeJobRows(jobRowsByConditions)
  }, [jobRowsByConditions])

  useEffect(() => {
    dispatch.groupShifts.updateGroupShiftsMap({
      [group.groupid]:
        groupDetails && Object.assign({}, groupDetails, { jobRows }),
    })
  }, [groupDetails])

  return (
    <>
      {jobs ? (
        (
          customCalendarFilterConditions.searchKeyword
            ? Boolean(jobRowsByConditions?.length)
            : shift_assigned || Boolean(protocols.length)
        ) ? (
          <div>
            <div className={css.shiftHeader}>
              <div className={css.groupTitle}>
                <H4 bold ellipsis variant="inherit">
                  {customCalendar.enable_group_calendar_links ? (
                    <a
                      className={css.groupCalendarLink}
                      href={`/calendar/whiteboard_gen.cgi?group_key=${
                        group.group_key
                      }&calendar_group_key=${group.calendar_group_key || ""}`}
                      target="_blank"
                    >
                      {name}
                    </a>
                  ) : (
                    name
                  )}
                </H4>
                {daily_archived && (
                  <>
                    <Text
                      variant="inherit"
                      bold="medium"
                      className={cx("flex-shrink-0", css.dailyArchive)}
                      onClick={() => setDailyArchiveShow(true)}
                    >
                      Daily Archive
                    </Text>
                    <DailyArchiveModal
                      dailyArchiveShow={dailyArchiveShow}
                      group={group}
                      customCalendar={customCalendar}
                      onHide={() => setDailyArchiveShow(false)}
                    />
                  </>
                )}
              </div>
              {Boolean(protocols.length) && (
                <div className={css.groupProtocols}>
                  {protocols.map((item) => (
                    <ProtocolViewer
                      key={item.group_protocolid}
                      className="my-3"
                      protocol={item.protocol}
                      protocolName={item.protocol_name}
                      display={item.display}
                    />
                  ))}
                </div>
              )}
              {Boolean(calendar_notes.length) && (
                <div className={css.groupProtocols}>
                  <H6 bold className="mb-3">
                    Calendar Notes:
                  </H6>
                  {calendar_notes.map((item) => (
                    <p key={item.noteid}>{item.note}</p>
                  ))}
                </div>
              )}
            </div>
            <ProviderModal
              providerId={pickedProviderId}
              customCalendar={customCalendar}
              onHide={() => setPickedProviderId(null)}
              setSendSecureMessageProviderId={(providerId: number) =>
                setPickedSendSecureMessageSource({
                  type: "provider",
                  id: providerId,
                })
              }
            />
            <SendSecureMessageModal
              source={pickedSendSecureMessageSource}
              customCalendar={customCalendar}
              onHide={() => setPickedSendSecureMessageSource(null)}
            />
            <div className={css.jobs}>
              {mergedJobRows &&
                mergedJobRows.map((jobBlock) => (
                  <JobBlock
                    group={group}
                    key={jobBlock.id}
                    jobBlock={jobBlock}
                    customCalendarKey={customCalendarKey}
                    setPickedProviderId={setPickedProviderId}
                    setPickedSendSecureMessageSource={
                      setPickedSendSecureMessageSource
                    }
                    setPickedJobForScheduleChange={
                      setPickedJobForScheduleChange
                    }
                  />
                ))}
            </div>
          </div>
        ) : (
          <div style={{ height: "1px" }}></div>
        )
      ) : (
        <div className="shift-loading">Loading...</div>
      )}
    </>
  )
}
