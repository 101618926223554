import React, { useEffect, useState } from "react"
import api, { useRequest } from "@app/services/api"
import { H1 } from "@app/components/Typography"
import { AdminUserLookupUser, UserRoleType } from "../../UserLookup"
import { useForm, FormItem, Input, Checkbox } from "@app/components/Form"
import Loader from "@app/components/Loader"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import { handleApiError } from "@app/utils"
import _ from "lodash"

type PropTypes = {
  primaryUser: AdminUserLookupUser
  otherUsers: AdminUserLookupUser[]
  closeModal: () => void
}

type FormFieldsType = {
  tc_username: string
  tc_token: string
  sso_enabled: boolean
}

export default (props: PropTypes) => {
  const userIds = props.otherUsers.map((user) => user.userid)
  userIds.push(props.primaryUser.userid)
  const { data: users } = useRequest([api.AdminUserLookupGetUsers, userIds])
  const [userRoles, setUserRoles] = useState<UserRoleType[]>([])
  const [mergeSuccess, setMergeSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { control, handleSubmit } = useForm<FormFieldsType>()

  useEffect(() => {
    if (users) {
      const userRolesOtherUsers = users.reduce(
        (userRolesCombined: UserRoleType[], user: AdminUserLookupUser) => {
          userRolesCombined = [...userRolesCombined, ...user.user_roles]
          return userRolesCombined
        },
        []
      )

      setUserRoles(
        _.uniqWith(
          userRolesOtherUsers,
          (a, b) => a.roleid === b.roleid && a.groupid === b.groupid
        )
      )
    }
  }, [users])

  const mergeAccount = (fields: FormFieldsType) => {
    setLoading(true)
    api
      .AdminAccountMerge(
        props.otherUsers.map((user) => user.userid),
        props.primaryUser.userid,
        fields.tc_username,
        fields.tc_token,
        fields.sso_enabled
      )
      .then((response) => {
        setMergeSuccess(true)
        setLoading(false)
      }, handleApiError)
  }

  const tableColumns = [
    {
      title: "Clinic ID",
      dataKey: "clinicid",
    },
    {
      title: "Clinic Name",
      dataKey: "clinic_name",
    },
    {
      title: "Group ID",
      dataKey: "groupid",
    },
    {
      title: "Group Name",
      dataKey: "group_name",
    },
    {
      title: "Role",
      dataKey: "role_name",
    },
  ]

  if (mergeSuccess) {
    return (
      <div className="text-align-center">
        <H1>Account merge completed successfully</H1>
      </div>
    )
  } else if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Loader animation="dots" />
      </div>
    )
  } else {
    return (
      <form onSubmit={handleSubmit(mergeAccount)}>
        <FormItem
          defaultValue={props.primaryUser.tigerconnect}
          label="TC Username"
          control={control}
          name="tc_username"
        >
          <Input placeholder="TC Username" />
        </FormItem>
        <FormItem
          defaultValue={props.primaryUser.tigerconnect_token}
          control={control}
          name="tc_token"
          label="TC Token"
        >
          <Input placeholder="TC Token" />
        </FormItem>
        <FormItem
          defaultValue={!!props.primaryUser.tigerschedule_sso}
          control={control}
          name="sso_enabled"
          label="TC SSO"
        >
          <Checkbox />
        </FormItem>
        <Table columns={tableColumns} data={userRoles} />
        <Button className="mr-5 mt-5" onClick={props.closeModal}>
          Cancel
        </Button>
        <Button className="mt-5" type="submit">
          Merge Account
        </Button>
      </form>
    )
  }
}
