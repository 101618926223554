import React, { useEffect, useState } from "react"
import { useForm, FormItem, Input, Select } from "@app/components/Form"
import api, { mutate, useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"
import { H3, H5 } from "@app/components/Typography"
import DailyTemplateCalendarView from "./DailyTemplateCalendarView"
import Button from "@app/components/Button"
import cloneDeep from "lodash/cloneDeep"

type Props = {
  annualBlockScheduleId: string
  annualBlockScheduleTemplate?: {
    id: string
    name: string
    staff_levels: StaffLevelType[]
    rotations: AnnualBlockScheduleRotationType[]
  }
  back: () => void
}

type FormFieldsType = {
  name: string
  annual_block_schedule_id: string
  annual_block_schedule_rotation_ids: string[]
  staff_level_ids: string[]
  content?: AnnualBlockScheduleTemplateContentType
  num_weeks?: number
}

export default (props: Props) => {
  const { annualBlockScheduleId, annualBlockScheduleTemplate, back } = props
  const [templateStep, setTemplateStep] = useState<"assign" | "calendar">(
    "assign"
  )
  const [numberOfWeeks, setNumberOfWeeks] = useState<number>()
  const [weeks, setWeeks] = useState<any>()

  const { data: allAnnualRotations } = useRequest<
    AnnualBlockScheduleRotationType[]
  >([api.getAnnualBlockScheduleRotations, annualBlockScheduleId])

  const { data: staffLevels } = useRequest<StaffLevelType[]>([
    api.getGroupStaffLevels,
  ])

  const { data: template } = useRequest(
    annualBlockScheduleTemplate
      ? [api.getTemplate, annualBlockScheduleTemplate?.id]
      : null
  )

  useEffect(
    function setupWeeks() {
      if (weeks) {
        modifyWeeksBuffer()
      } else if (annualBlockScheduleTemplate) {
        if (template) {
          setWeeks(cloneDeep(template.content.weeks))
        }
      } else {
        setWeeks(createBlankWeeks())
      }
    },
    [numberOfWeeks, template]
  )

  function createBlankWeeks() {
    return new Array(numberOfWeeks)
      .fill([])
      .map((_week) => new Array(7).fill([]))
  }

  function modifyWeeksBuffer() {
    if (!numberOfWeeks) return

    const difference = numberOfWeeks - weeks.length
    if (difference === 0) return
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        weeks.push(new Array(7).fill([]))
      }
    } else {
      for (let i = 0; i < Math.abs(difference); i++) {
        weeks.pop()
      }
    }

    setWeeks([...weeks])
  }

  useEffect(
    function fillForm() {
      resetForm({
        name: annualBlockScheduleTemplate?.name,
        annual_block_schedule_rotation_ids:
          annualBlockScheduleTemplate?.rotations.map((rotation) => rotation.id),
        staff_level_ids: annualBlockScheduleTemplate?.staff_levels.map(
          (staff_level) => staff_level.id
        ),
        num_weeks: template?.content?.weeks.length,
      })
    },
    [template]
  )

  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          name: yup.string().max(80).required().label("Name"),
          annual_block_schedule_rotation_ids: yup
            .array()
            .ensure()
            .min(1, "You must add at least one Rotation")
            .of(yup.string()),
          staff_level_ids: yup
            .array()
            .ensure()
            .min(1, "You must add at least one staff level")
            .of(yup.string()),
          num_weeks: yup.number().required("You must use at least one week"),
        })
      ),
  })

  function formatContent() {
    if (!weeks) return
    let finalWeeks = cloneDeep(weeks)

    for (let w = 0; w < finalWeeks.length; w++) {
      for (let d = 0; d < finalWeeks[w].length; d++) {
        for (let j = 0; j < finalWeeks[w][d].length; j++) {
          finalWeeks[w][d][j] = finalWeeks[w][d][j].jobid
        }
      }
    }

    return { weeks: finalWeeks }
  }

  const onSubmit = (fields: FormFieldsType) => {
    if (templateStep === "assign") {
      if (fields.num_weeks !== numberOfWeeks) {
        setNumberOfWeeks(fields.num_weeks)
      }
      setTemplateStep("calendar")
      return
    }

    delete fields.num_weeks
    fields.content = formatContent()
    if (annualBlockScheduleTemplate) {
      return api
        .updateTemplate(annualBlockScheduleTemplate.id, fields)
        .then((_res) => {
          mutate([api.getTemplates, annualBlockScheduleId])
          mutate([api.getTemplate, annualBlockScheduleTemplate?.id])
          back()
        }, handleApiError)
    } else {
      fields.annual_block_schedule_id = annualBlockScheduleId
      return api.createTemplate(annualBlockScheduleId, fields).then((_res) => {
        mutate([api.getTemplates, annualBlockScheduleId])
        back()
      }, handleApiError)
    }
  }

  return (
    <>
      <div className={"text-center mb-4"}>
        <H3>{`${
          annualBlockScheduleTemplate ? "Edit" : "Add"
        } Daily Assignment Template`}</H3>
      </div>
      <>
        {templateStep === "assign" && (
          <>
            <FormItem required label="Name" name="name" control={control}>
              <Input />
            </FormItem>
            <FormItem
              required
              label="Rotations"
              name="annual_block_schedule_rotation_ids"
              control={control}
            >
              <Select
                multiple
                inline
                loading={!allAnnualRotations}
                options={allAnnualRotations || []}
                labelKey="block_schedule_rotation"
                renderOption={(rotation) => (
                  <H5
                    ellipsis
                    prefixDotColor={rotation.color}
                    title={rotation.name}
                  >
                    {rotation.name}
                  </H5>
                )}
              />
            </FormItem>
            <FormItem
              required
              label="Staff Levels"
              name="staff_level_ids"
              control={control}
            >
              <Select
                multiple
                inline
                loading={!staffLevels}
                options={staffLevels || []}
                labelKey="staff_levels"
                renderOption={(_, staffLevel) => (
                  <H5 ellipsis title={staffLevel.name}>
                    {staffLevel.name}
                  </H5>
                )}
              />
            </FormItem>
            <FormItem required label="Number of Weeks">
              <Select
                options={[
                  { id: 1, name: "1 week" },
                  { id: 2, name: "2 weeks" },
                  { id: 3, name: "3 weeks" },
                  { id: 4, name: "4 weeks" },
                  { id: 5, name: "5 weeks" },
                  { id: 6, name: "6 weeks" },
                ]}
                name="num_weeks"
                control={control}
              />
            </FormItem>
          </>
        )}

        {templateStep === "calendar" && (
          <DailyTemplateCalendarView setWeeks={setWeeks} weeks={weeks} />
        )}
      </>
      <div className="text-right mb-4">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={
            templateStep === "calendar"
              ? () => {
                  setTemplateStep("assign")
                  return false
                }
              : back
          }
        >
          {templateStep === "assign" ? "Cancel" : "Back"}
        </Button>{" "}
        <Button size="sm" onClick={handleSubmit(onSubmit)}>
          {templateStep === "assign" ? "Next" : "Save"}
        </Button>
      </div>
    </>
  )
}
