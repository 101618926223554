import React from "react"
import css from "./NoteElement.module.scss"

interface Props {
  content: string
}

export const NoteElement = ({ content }: Props) => {
  return (
    <button className={css.container}>
      <div className={css.header}>
        <span>Note</span>
      </div>
      <div className={css.contentContainer}>
        <p>{content}</p>
      </div>
    </button>
  )
}
