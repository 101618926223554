import React, { useState, useContext } from "react"
import { useControl, UseControlProps } from "./useForm"
import cx from "classnames"
import css from "./Radio.module.scss"

interface RadioGroupContextProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: any
  name?: string
  className?: string
}

interface RadioGroupProps extends UseControlProps {
  options: PlainObjectType[]
  valueKey?: keyof RadioGroupProps["options"][number]
  labelKey?: keyof RadioGroupProps["options"][number]
  className?: string
  radioClassName?: string
}

interface RadioProps extends UseControlProps {
  label?: string
  className?: string
  checked?: boolean
}

const RadioGroupContext = React.createContext<RadioGroupContextProps | null>(
  null
)

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const {
    options,
    valueKey = "id",
    labelKey = "name",
    error,
    name,
    defaultValue,
    value,
    onChange,
    className,
    radioClassName,
  } = useControl(props, { nativeValue: "" })

  const [innerValue, setInnerValue] = useState<string>(defaultValue)

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value

    setInnerValue(val)
    onChange?.(val)
  }

  const currentValue = value ?? innerValue
  const errorClassNames = cx({ "bootstrap4 is-invalid": error })

  return (
    <RadioGroupContext.Provider
      value={{
        value: currentValue,
        onChange: onRadioChange,
        name: name,
        className: radioClassName,
      }}
    >
      <div className={cx(css.radioGroupContainer, className)}>
        <div className={cx(css.radiosList, errorClassNames)}>
          {options.map((option) => (
            <Radio
              key={option[valueKey]}
              label={option[labelKey]}
              value={option[valueKey]}
            />
          ))}
        </div>
        <div className="invalid-tooltip">{error?.message}</div>
      </div>
    </RadioGroupContext.Provider>
  )
}

const Radio: React.FC<RadioProps> & { Group: typeof RadioGroup } = (props) => {
  const radioProps = { ...props }
  const context = useContext(RadioGroupContext)

  if (context) {
    radioProps.name = context.name
    radioProps.onChange = context.onChange
    radioProps.checked = props.value === context.value
  }

  const { label, className, children, ...rest } = radioProps

  const content = label || children

  return (
    <label className={cx(css.radioContainer, className)}>
      <input type="radio" className={css.radio} {...rest} />
      <span className={css.radioState}>
        {content && <span className={css.radioLabel}>{content}</span>}
      </span>
    </label>
  )
}

Radio.Group = RadioGroup

export default Radio
