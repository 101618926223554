import React, { useContext, useMemo } from "react"
import { Button } from "react-bootstrap"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import { IAdditionalAssignmentItem } from "../../data"

export interface MultipleAssignmentsColProps {
  jobId: number
  additionalAssignments: IAdditionalAssignmentItem[]
  multiple_assignments: boolean
}

const MultipleAssignmentsCol: React.FC<MultipleAssignmentsColProps> = (
  props
) => {
  const { jobId, additionalAssignments } = props
  const {
    jobs,
    providers,
    residentJobsProviders,
    setCurrentMultipleAssignmentsJobId,
    edate,
  } = useContext(ManualScheduleTableContext)

  const combinedProviders = useMemo(() => {
    const residentProviders =
      residentJobsProviders?.find((x) => x.jobid === jobId)?.providers || []
    return residentProviders.concat(providers)
  }, [jobId, providers, residentJobsProviders])

  const job = jobs.find((j) => jobId == j.jobid)
  const initAssignment = {
    jobid: job?.jobid,
    providerid: job?.providerid,
    edate: edate,
  }

  const totalAssignments =
    job?.providerid &&
    !additionalAssignments.find((a) => a.providerid == job?.providerid)
      ? [initAssignment, ...additionalAssignments]
      : additionalAssignments

  const getMultipleAssignmentsValue = () => {
    const value = {
      jobid: jobId,
      edate: edate,
      additionalAssignments: totalAssignments,
    }

    return JSON.stringify(value)
  }

  const getMultipleAssignmentsDisplayValue = () => {
    const len = totalAssignments.length
    const nameList = totalAssignments
      .map(
        (s) => combinedProviders.find((p) => p.providerid == s.providerid)?.name
      )
      .join(", ")

    return len == 0 ? "Select a provider..." : `${len} Seats - ${nameList}`
  }

  return (
    <>
      <div className="flex-1">
        <select
          defaultValue={getMultipleAssignmentsValue()}
          name={`majob${jobId}`}
          onClick={() => setCurrentMultipleAssignmentsJobId(jobId)}
          style={{ width: "100%", minWidth: "240px" }}
        >
          <option value={getMultipleAssignmentsValue()}>
            {getMultipleAssignmentsDisplayValue()}
          </option>
        </select>
      </div>
      <Button
        className="ml-40"
        style={{ visibility: totalAssignments.length ? "visible" : "hidden" }}
        onClick={() => setCurrentMultipleAssignmentsJobId(props.jobId)}
      >
        Edit
      </Button>
    </>
  )
}

export default MultipleAssignmentsCol
