import React from "react"
import Spinner from "react-bootstrap/Spinner"
import cx from "classnames"
import css from "./Loader.module.scss"

type Props = Omit<
  Partial<React.ComponentProps<typeof Spinner>>,
  "animation"
> & {
  animation?: "border" | "grow" | "dots" | "wave" | "glow"
  className?: string
  style?: React.CSSProperties
}

export default ((props) => {
  const { animation = "border", style, className, ...rest } = props

  if (animation === "dots") {
    return (
      <div className={cx(css.dotsLoader, className)}>
        <div />
      </div>
    )
  }

  if (animation === "wave" || animation === "glow") {
    return (
      <div style={style} className={cx(css[`${animation}Loader`], className)}>
        <div />
      </div>
    )
  }

  return <Spinner animation={animation} {...rest} />
}) as React.FC<Props>
