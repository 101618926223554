import React from "react"
import DatePickerInput from "react-day-picker/DayPickerInput"
import TimePickerInput from "./TimePickerInput"
import { useControl, UseControlProps } from "./useForm"
import { DateFormatter } from "@app/utils/constants"
import { formatDate, parseDate } from "@app/utils"
import cx from "classnames"
import css from "./Input.module.scss"
import TodayIcon from "@material-ui/icons/Today"

type DatePickerInputProps = React.ComponentProps<typeof DatePickerInput>
type TimePickerInputProps = React.ComponentProps<typeof TimePickerInput>

interface InputProps extends UseControlProps {
  className?: string
  type?: string
  placeholder?: string
  autoFocus?: boolean
  dateFormat?: string
  dayPickerProps?: DatePickerInputProps["dayPickerProps"]
  timePickerProps?: TimePickerInputProps["timePickerProps"]
  inputProps?: DatePickerInputProps["inputProps"]
  component?: DatePickerInputProps["component"]
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  rows?: number
  showTodayButton?: boolean // Prop to control the visibility of the Today button
}

export default React.forwardRef<any, InputProps>((props, ref) => {
  const {
    className,
    type = "text",
    placeholder,
    error,
    dayPickerProps = {},
    timePickerProps = {},
    inputProps = {},
    onChange,
    children,
    value,
    defaultValue,
    disabled,
    dateFormat,
    onBlur,
    rows,
    showTodayButton,
    ...others
  } = useControl(props, { nativeValue: "" })

  const errorClassNames = cx({ "bootstrap4 is-invalid": error })
  const defaultFormat = dateFormat || DateFormatter.localized

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.preventDefault()
  }

  const customDayPickerProps = {
    ...dayPickerProps,
    todayButton: showTodayButton ? "Today" : undefined,
  }
  return (
    <div className={cx(css.inputContainer, className)}>
      {type === "date" ? (
        <div className={errorClassNames}>
          <DatePickerInput
            ref={ref}
            format={defaultFormat}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={placeholder || "Pick a date"}
            dayPickerProps={{ ...customDayPickerProps }}
            onDayChange={(pickedDate: any, _, dayPickerInput: any) => {
              if (pickedDate) {
                onChange?.(formatDate(pickedDate, DateFormatter.normal))
              } else {
                !dayPickerInput.getInput().value && onChange?.("")
              }
            }}
            inputProps={{
              className: "form-control",
              type: "text",
              disabled,
              onBlur,
              onKeyDown: handleKeyDown, //handle invalid time value
              ...inputProps,
            }}
            value={value && formatDate(value, defaultFormat)}
            {...others}
          />
        </div>
      ) : type === "datetime" ? (
        <div className={errorClassNames}>
          <div className={css.customInputCalendar}>
            <DatePickerInput
              ref={ref}
              format={defaultFormat}
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder={placeholder || "Pick a date"}
              dayPickerProps={{ ...customDayPickerProps }}
              onDayChange={(pickedDate: any, _, dayPickerInput: any) => {
                if (pickedDate && dayPickerInput && dayPickerInput.getInput()) {
                  onChange?.(formatDate(pickedDate, DateFormatter.normal))
                } else if (dayPickerInput && !dayPickerInput.getInput().value) {
                  onChange?.("")
                }
              }}
              inputProps={{
                className: "datetimeCalendar",
                type: "text",
                disabled,
                onBlur,
                onKeyDown: handleKeyDown, //handle invalid time value
                ...inputProps,
              }}
              value={value && formatDate(value, defaultFormat)}
              {...others}
            />
            <TodayIcon className={css.iconCalendar} />
          </div>
        </div>
      ) : type == "time" ? (
        <div className={errorClassNames}>
          <TimePickerInput
            ref={ref}
            placeholder={placeholder || "Pick a time"}
            timePickerProps={{ ...timePickerProps }}
            onTimeChange={(time24h) => onChange?.(time24h)}
            inputProps={{ disabled, onBlur, ...inputProps }}
            value={value}
            disabled={disabled}
            {...others}
          />
        </div>
      ) : type == "textarea" ? (
        <textarea
          rows={rows}
          ref={ref}
          placeholder={placeholder}
          className={cx("form-control", errorClassNames)}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          onBlur={onBlur}
          {...others}
        />
      ) : (
        <input
          type={type}
          ref={ref}
          placeholder={placeholder}
          className={cx("form-control", errorClassNames)}
          autoComplete="off"
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          onBlur={onBlur}
          {...others}
        />
      )}
      <div className="invalid-tooltip">{error?.message}</div>
    </div>
  )
})
