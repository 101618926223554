import React from "react"
import { useParams } from "react-router-dom"
import useRequest from "@app/utils/request"
import api from "@app/services/api"
import { H5 } from "@app/components/Typography"
import Button from "@app/components/Button"
import Loader from "@app/components/Loader/Loader"

type PropsType = {
  setPage: (page: string) => void
  setSelectedTemplate: (template: AnnualBlockScheduleTemplateType) => void
  setDailyTemplatesShow: (toggle: boolean) => void
  selectRotation: () => void
  selectedRotationId: string
  staffLevelId: string
}

export default (props: PropsType) => {
  const {
    setPage,
    setSelectedTemplate,
    setDailyTemplatesShow,
    selectRotation,
    selectedRotationId,
    staffLevelId,
  } = props
  const { id } = useParams()

  const { data: templates } = useRequest<AnnualBlockScheduleTemplateType[]>([
    api.getTemplates,
    id,
  ])

  const selectTemplate = (template: AnnualBlockScheduleTemplateType) => {
    setSelectedTemplate(template)
    setPage("confirmDailyAssignments")
  }

  return (
    <div className="mt-5">
      <H5 bold>Template Name</H5>
      {templates
        ?.filter(
          (template) =>
            template.rotations &&
            template.rotations
              .map(
                (rotation: AnnualBlockScheduleRotationType) =>
                  rotation.block_schedule_rotation.id
              )
              .includes(selectedRotationId) &&
            template.staff_levels &&
            template.staff_levels
              .map((staffLevel) => staffLevel.id)
              .includes(staffLevelId)
        )
        .map((template) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <p>{template.name}</p>
              <Button onClick={() => selectTemplate(template)} size="sm">
                Select
              </Button>
            </div>
          )
        })}
      {!templates && (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      )}
      <div className="mt-5 d-flex flex-row justify-content-between">
        <Button
          variant="secondary"
          onClick={() => setDailyTemplatesShow(false)}
        >
          Back
        </Button>
        <Button onClick={selectRotation}>Only Select Rotation</Button>
      </div>
    </div>
  )
}
