import React from "react"
import { H3 } from "@app/components/Typography"
import Button from "@app/components/Button"
import Card from "@app/components/Card"
import Table from "@app/components/Table"
import { ColorPicker } from "@app/components/Form"
import api, { useRequest } from "@app/services/api"

export default () => {
  const { data: rotations } = useRequest([api.getBlockScheduleRotations])

  return (
    <Card
      title={<H3 variant="blue">Rotations</H3>}
      action={
        <Button size="sm" variant="outline-secondary" to="/rotations/edit">
          Edit
        </Button>
      }
    >
      <Table
        data={rotations}
        loading={!rotations}
        scroll={{ y: 400 }}
        columns={[
          { dataKey: "name", title: "Name" },
          { dataKey: "abbrev", title: "Abbreviation" },
          {
            dataKey: "color",
            title: "Color",
            render: (val) => <ColorPicker.ColorField color={val} />,
          },
        ]}
        emptyMessage="No rotations have been created for this group. Please add a rotation."
      />
    </Card>
  )
}
