import {
  format,
  parseISO,
  addDays,
  subDays,
  isAfter,
  isBefore,
  isEqual,
} from "date-fns"

const partition = (array: Holiday[], isValid: (elem: Holiday) => boolean) => {
  return array.reduce<Holiday[][]>(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]
    },
    [[], []]
  )
}

const getDaysOfWeekInMonth = (
  year: number,
  month: number,
  weekIndex: number,
  dayOfWeek: number
) => {
  const days: Date[] = []

  let currentDate = new Date(year, month - 1, 1)
  while (currentDate.getMonth() === month - 1) {
    if (currentDate.getDay() === dayOfWeek - 1) {
      days.push(new Date(currentDate))
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }

  if (weekIndex === 5) {
    return days.pop()
  } else {
    return days[weekIndex - 1]
  }
}

export type ProcessedHoliday = {
  abbrev: string
  startDate: string
  endDate: string
  name: string
  holidayTypeId: number
  holidayId: number
  holidayByYear: string
  year: number
}

export const getOrderedHolidays = (
  holidays: Holiday[],
  selectedDate: string
) => {
  const processedHolidays: ProcessedHoliday[] = []
  const equationProcessedHolidays: ProcessedHoliday[] = []

  const [equationHolidays, simpleAndWeekIncrementHoliday] = partition(
    holidays,
    (holiday) => Boolean(holiday.equation)
  )
  const [weekIncrementHolidays, simpleHolidays] = partition(
    simpleAndWeekIncrementHoliday,
    (holiday) => Boolean(holiday.wecrementid)
  )
  const selectedDateYear = new Date(selectedDate).getFullYear()
  const yearsToProcess = [
    selectedDateYear - 1,
    selectedDateYear,
    selectedDateYear + 1,
  ]

  simpleHolidays.forEach((simpleHoliday) => {
    if (
      simpleHoliday.year &&
      simpleHoliday.monthid &&
      simpleHoliday.day_of_month
    ) {
      const holidayDate = new Date()
      holidayDate.setFullYear(
        simpleHoliday.year,
        simpleHoliday.monthid - 1,
        simpleHoliday.day_of_month
      )
      processedHolidays.push({
        abbrev: simpleHoliday.abbrev ?? "Secondary Holiday",
        startDate: format(holidayDate, "yyyy-MM-dd"),
        endDate: format(holidayDate, "yyyy-MM-dd"),
        name: simpleHoliday.name ?? "Secondary Holiday",
        holidayTypeId: simpleHoliday.holiday_typeid,
        holidayId: simpleHoliday.holidayid,
        holidayByYear: `${simpleHoliday.holidayid}-${simpleHoliday.year}`,
        year: simpleHoliday.year,
      })
      return
    } else {
      yearsToProcess.forEach((year) => {
        if (simpleHoliday.monthid && simpleHoliday.day_of_month) {
          const holidayDate = new Date()
          holidayDate.setFullYear(
            year,
            simpleHoliday.monthid - 1,
            simpleHoliday.day_of_month
          )
          processedHolidays.push({
            abbrev: simpleHoliday.abbrev ?? "Secondary Holiday",
            startDate: format(holidayDate, "yyyy-MM-dd"),
            endDate: format(
              addDays(holidayDate, simpleHoliday.duration - 1),
              "yyyy-MM-dd"
            ),
            name: simpleHoliday.name ?? "Secondary Holiday",
            holidayTypeId: simpleHoliday.holiday_typeid,
            holidayId: simpleHoliday.holidayid,
            holidayByYear: `${simpleHoliday.holidayid}-${year}`,
            year: year,
          })
        }
      })
    }
  })

  weekIncrementHolidays.forEach((weekIncrementHoliday) => {
    yearsToProcess.forEach((year) => {
      if (
        weekIncrementHoliday.monthid &&
        weekIncrementHoliday.dayid &&
        weekIncrementHoliday.wecrementid
      ) {
        const startDate = getDaysOfWeekInMonth(
          year,
          weekIncrementHoliday.monthid,
          weekIncrementHoliday.wecrementid,
          weekIncrementHoliday.dayid
        )
        if (startDate) {
          processedHolidays.push({
            abbrev: weekIncrementHoliday.abbrev ?? "Secondary Holiday",
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(
              addDays(startDate, weekIncrementHoliday.duration - 1),
              "yyyy-MM-dd"
            ),
            name: weekIncrementHoliday.name ?? "Secondary Holiday",
            holidayTypeId: weekIncrementHoliday.holiday_typeid,
            holidayId: weekIncrementHoliday.holidayid,
            holidayByYear: `${weekIncrementHoliday.holidayid}-${year}`,
            year: year,
          })
        }
      }
    })
  })

  equationHolidays.forEach((equationHoliday) => {
    yearsToProcess.forEach((year) => {
      const isAdd = equationHoliday.equation.includes("+")
      const parsedEquation = isAdd
        ? equationHoliday.equation.split("+")
        : equationHoliday.equation.split("-")
      let parentProcessedHoliday: ProcessedHoliday = {
        startDate: "",
        endDate: "",
        abbrev: "",
        holidayByYear: "",
        holidayId: 0,
        holidayTypeId: 0,
        name: "",
        year: 0,
      }

      if (parsedEquation.length === 2) {
        processedHolidays.forEach((processedHoliday) => {
          if (
            processedHoliday.year === year &&
            processedHoliday.abbrev === parsedEquation[0]
          ) {
            parentProcessedHoliday = processedHoliday
          }
        })

        if (Boolean(parentProcessedHoliday.startDate)) {
          const newStartDate = isAdd
            ? format(
                addDays(
                  parseISO(parentProcessedHoliday.startDate),
                  Number(parsedEquation[1])
                ),
                "yyyy-MM-dd"
              )
            : format(
                subDays(
                  parseISO(parentProcessedHoliday.startDate),
                  Number(parsedEquation[1])
                ),
                "yyyy-MM-dd"
              )

          equationProcessedHolidays.push({
            abbrev: equationHoliday.abbrev ?? "Secondary Holiday",
            startDate: newStartDate,
            endDate: format(
              addDays(parseISO(newStartDate), equationHoliday.duration - 1),
              "yyyy-MM-dd"
            ),
            name: equationHoliday.name ?? "Secondary Holiday",
            holidayTypeId: equationHoliday.holiday_typeid,
            holidayId: equationHoliday.holidayid,
            holidayByYear: `${equationHoliday.holidayid}-${year}`,
            year: year,
          })
        }
      }
    })
  })

  const combinedHolidays = [...processedHolidays, ...equationProcessedHolidays]
  combinedHolidays.sort(
    (prevHoliday, nextHoliday) =>
      prevHoliday.holidayTypeId - nextHoliday.holidayTypeId
  )

  return combinedHolidays
}

const isDateBetween = (
  dateToCheck: string,
  startDate: string,
  endDate: string
) => {
  return (
    (isAfter(parseISO(dateToCheck), parseISO(startDate)) ||
      isEqual(parseISO(dateToCheck), parseISO(startDate))) &&
    (isBefore(parseISO(dateToCheck), parseISO(endDate)) ||
      isEqual(parseISO(dateToCheck), parseISO(endDate)))
  )
}

export const getHolidaysOffDate = (
  date: string,
  orderedHolidays: ProcessedHoliday[]
) => {
  const holidaysByDate: { name: string; holidayId: string; abbrev: string }[] =
    []

  orderedHolidays.forEach((orderedHoliday) => {
    if (isDateBetween(date, orderedHoliday.startDate, orderedHoliday.endDate)) {
      holidaysByDate.push({
        name: orderedHoliday.name,
        holidayId: orderedHoliday.holidayByYear,
        abbrev: orderedHoliday.abbrev,
      })
    }
  })

  return holidaysByDate
}
