import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext";
import { IManualScheduleJobItem, ISplitShiftItem } from "../../data";

export interface SplitShiftOperationButtonProps {
    jobId: number,
    splitShifts: ISplitShiftItem[],
}

const SplitShiftOperationButton: React.FC<SplitShiftOperationButtonProps> = (props) => {
    const { currentSplitShiftJobId, setCurrentSplitShiftJobId, jobs, splitShiftFlag } = useContext(ManualScheduleTableContext);
    const [showSplitBtn, setShowSplitBtn] = useState<boolean>(!!(jobs.find(j => j.jobid == props.jobId)?.providerid));
    useEffect(() => {
        const currJob = jobs.find(j => j.jobid == props.jobId) as IManualScheduleJobItem;
        setShowSplitBtn(!!splitShiftFlag && (!!currJob.providerid || currJob.splitShifts.length > 0))
    }, [jobs]);

    return (
        <>
            {
                currentSplitShiftJobId ? null : <Button className="ml-40" style={{ visibility: (showSplitBtn ? 'visible' : 'hidden') }} onClick={() => setCurrentSplitShiftJobId(props.jobId)}>
                    {props.splitShifts && props.splitShifts.length > 0 ? "Edit" : "Split"}
                </Button>
            }
        </>
    );
}

export default SplitShiftOperationButton;
