// Staff requirement should be uniq with others requirements,
// For example,
//   [PGY1, PGY2] vs [PG2, PGY1] // Bad
//   [PGY1, PGY2] vs [PG2, PGY1, PGY3] // Good
export const checkStaffLevelsUnique = (value: any, context: any) => {
  const {
    path,
    index,
    from: [_, formObj],
  } = context.options

  const key = /staff_requirements/.test(path)
    ? "staff_requirements"
    : "tally_targets"

  const testCollection = formObj.value[key].slice(0, index + 1)
  const values = testCollection.map(
    (item: any) =>
      item.staff_level_id || item.staff_level_ids?.concat().sort().join()
  )
  const lastValue = values.pop()

  return values.every((val: string) => lastValue !== val)
}
