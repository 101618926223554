import { createModel } from "@rematch/core"
import { RootModel } from "."

type ConditionType = { name: string; value: any; path?: string }

type StateType = {
  commonFilters: Record<
    string,
    {
      conditions: { [index: string]: ConditionType }
      values: any[]
    }
  >
}

export default createModel<RootModel>()({
  state: {
    commonFilters: {},
  } as StateType,
  reducers: {
    updateFilter(
      state,
      {
        key,
        values,
        condition,
      }: { key: string; values?: any[]; condition?: ConditionType }
    ) {
      // Prevent changing state in this case
      if (
        !(key in state.commonFilters) &&
        !values?.length &&
        condition?.value == null
      ) {
        return state
      }

      state.commonFilters[key] ||= { values: [], conditions: {} }

      if (values != null) {
        state.commonFilters[key].values = values
      }

      if (condition) {
        state.commonFilters[key].conditions[condition.name] = condition
      }

      return state
    },
    clearFilter(state, { key }: { key: string }) {
      delete state.commonFilters[key]
      return state
    },
  },
})
