import React, { useEffect, useState } from "react"
import { formatDate } from "@app/utils"
import css from "./EditRequest.module.scss"
import BaseLayout from "@app/containers/layouts/Base"
import Loader from "@app/components/Loader"
import api from "@app/services/api"

type Props = {
  overlappingAssignment: OverlappingAssignment[]
}

const EditRequest = ({ overlappingAssignment }: Props) => {
  const [showLoader, setShowLoader] = useState(true)
  const [jobsData, setJobsData] = useState<any[]>([])

  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        const allPromises = overlappingAssignment.map((assignment) =>
          api.getJob(assignment.jobid)
        )
        const results = await Promise.all(allPromises)
        setJobsData(results)
      } catch (error) {
        console.error("Error fetching job data:", error)
      }
      setShowLoader(false)
    }

    fetchJobsData()
  }, [overlappingAssignment])

  const convertTimeFormat = (timeString: string) => {
    const [hours] = timeString.split(":").map(Number)
    const amOrPm = hours >= 12 ? "PM" : "AM"
    const adjustedHour = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours
    return `${adjustedHour}${amOrPm}`
  }

  if (showLoader) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <div className="w-100 p-3 d-flex justify-content-center">
          <Loader animation="dots" />
        </div>
      </BaseLayout>
    )
  } else if (overlappingAssignment.length === 0) {
    return (
      <>
        <div className={css.overlappingTitle}>Overlapping Assignments</div>
        <h3>There are no overlapping assignments.</h3>
      </>
    )
  }

  const formattedOverlappingAssignment = overlappingAssignment.map(
    (request) => ({
      ...request,
      edate: formatDate(request.edate, "MMMM do, yyyy"),
    })
  )

  return (
    <div className="container-fluid">
      <div className={css.overlappingTitle}>Overlapping Assignments</div>
      {formattedOverlappingAssignment.map(
        (request: OverlappingAssignment, index: number) => {
          const jobInfo = jobsData[index]
          return (
            <div
              className={
                index % 2 !== 0 ? css.darkBackground : css.whiteBackground
              }
              key={request.jobid}
            >
              <div className="row">
                <div className="col-xs-3">
                  <div className={css.overlappingProviderName}>
                    {request.provider.display_name}
                  </div>
                </div>
                <div className="col-xs-9">
                  <div className={css.overlappingItemBold}>
                    {jobInfo.abbrev}
                  </div>
                </div>
              </div>

              <div className="row padding-bottom">
                <div className="col-xs-12">
                  <div className={css.overlappingItem}>
                    {request.edate} <span> </span>
                    {convertTimeFormat(jobInfo.starttime)} -{" "}
                    {convertTimeFormat(jobInfo.endtime)}
                  </div>
                </div>
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}

export default EditRequest
