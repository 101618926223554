import React, { useEffect, useReducer } from "react"
import Table from "@app/components/Table"
import Button from "@app/components/Button"
import { DateFormatter } from "@app/utils/constants"
import { formatDate } from "@app/utils"
import api from "@app/services/api"
import useRequest from "@app/utils/request"
import SyncFailuresEditModal from "./SyncFailuresEditModal"
import SyncFailureEmailDeliveryModal from "./SyncFailureEmailDeliveryModal"
import css from "./SyncFailuresEditModal.module.scss"

export interface syncErrorCases {
  caseid: string
  clinic_name: string
  group_name: string
  error_type: string
  occurrences: number
  owner: string
  edit: React.ComponentType
}

type EmailData = {
  caseid: number
  amountOfEmails: number
}

export const formatDateToHumanReadable = (
  date: string,
  local?: boolean
): string => {
  if (!date) return "Date not available"
  if (local) {
    return formatDate(
      new Date(date.replace(" ", "T") + "Z"),
      DateFormatter.humanizedFullTimestamp
    )
  }
  const dateObj = new Date(date)
  const dateWithOffset = new Date(
    dateObj.valueOf() + dateObj.getTimezoneOffset() * 60 * 1000
  )

  return formatDate(dateWithOffset, DateFormatter.humanizedFullTimestamp)
}

export const errorTypeMap: any = {
  overlapping_shift: "Overlapping Shift",
  user_not_matched: "User not Matched",
  role_not_matched: "Role not Matched",
  user_role_not_matched: "User Role not Matched",
}

const SyncFailures: React.FC<any> = (props): JSX.Element => {
  const initialState = {
    syncErrorCases: [],
    show: false,
    selectedErrorCaseId: null,
    showSchedulerNotificationEmailModal: false,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "setErrors":
        return { ...state, syncErrorCases: action.payload }
      case "toggleModal":
        return {
          ...state,
          show: !state.show,
          selectedErrorCaseId: action.payload,
        }
      case "changeErrorField":
        const errorCaseIndex = state.syncErrorCases.findIndex(
          (error: any) => error.id === action.payload.errorId
        )
        const field = Object.keys(action.payload.field)[0]
        let syncErrorCasesCopy = [...state.syncErrorCases]
        syncErrorCasesCopy[errorCaseIndex][field] = action.payload.field[field]
        return {
          ...state,
          syncErrorCases: syncErrorCasesCopy,
        }
      case "toggleSchedulerEmailModal":
        return {
          ...state,
          showSchedulerNotificationEmailModal:
            !state.showSchedulerNotificationEmailModal,
          selectedErrorCaseId: action.payload,
        }
      default:
        throw new Error()
    }
  }

  const { data: syncErrorCases } = useRequest([api.AdminGetSyncFailures])
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (syncErrorCases) {
      const mutatedSyncErrorCases = syncErrorCases.map((syncErrorCase: any) => {
        return {
          ...syncErrorCase,
          sync_enabled:
            Number(syncErrorCase.sync_enabled) === 1 ? "True" : "False",
          created_at: formatDateToHumanReadable(syncErrorCase.created_at, true),
          updated_at: formatDateToHumanReadable(syncErrorCase.updated_at, true),
          email_delivery_timestamp: formatDateToHumanReadable(
            syncErrorCase.email_delivery_timestamp,
            true
          ),
          email_data: {
            caseid: syncErrorCase.caseid,
            amountOfEmails: syncErrorCase.total_emails_delivered,
          },
          error: JSON.parse(syncErrorCase.error),
          error_type: errorTypeMap[syncErrorCase.error_type],
          occurrences: JSON.parse(syncErrorCase.error).length,
          clinic_name: `${syncErrorCase.clinicid} ${syncErrorCase.clinic_name}`,
          group_name: `${syncErrorCase.groupid} ${syncErrorCase.group_name}`,
          owner: syncErrorCase.owner || "Not assigned",
          edit: (
            <Button
              onClick={() =>
                dispatch({
                  type: "toggleModal",
                  payload: syncErrorCase.caseid,
                })
              }
            >
              Edit
            </Button>
          ),
        }
      })
      dispatch({ type: "setErrors", payload: mutatedSyncErrorCases })
    }
  }, [syncErrorCases])

  const columns = [
    {
      title: "Case ID",
      dataKey: "caseid",
    },
    {
      title: "Clinic Name",
      dataKey: "clinic_name",
    },
    {
      title: "Group Name",
      dataKey: "group_name",
    },
    {
      title: "TigerConnect Setting Label",
      dataKey: "tigerconnect_setting_label",
    },
    {
      title: "Failure Type",
      dataKey: "error_type",
    },
    {
      title: "Amount of Failures",
      dataKey: "occurrences",
    },
    {
      title: "Sync Enabled",
      dataKey: "sync_enabled",
    },
    {
      title: "Emails Delivered",
      dataKey: "email_data",
      render: (email_data: EmailData) => (
        <p
          className={css.emailsDeliveredText}
          onClick={() => {
            dispatch({
              type: "toggleSchedulerEmailModal",
              payload: email_data.caseid,
            })
          }}
        >
          {email_data.amountOfEmails}
        </p>
      ),
    },
    {
      title: "Email Delivery Time",
      dataKey: "email_delivery_timestamp",
    },
    {
      title: "Created at",
      dataKey: "created_at",
    },
    {
      title: "Updated at",
      dataKey: "updated_at",
    },
    {
      title: "Owner",
      dataKey: "owner",
    },
    {
      title: "",
      dataKey: "edit",
    },
  ]

  return (
    <>
      <Table data={state.syncErrorCases} columns={columns} />
      {state.show && (
        <SyncFailuresEditModal
          show={state.show}
          key={state.syncErrorCases.resolve_attempt}
          onHide={() =>
            dispatch({ type: "toggleModal", payload: { error: [] } })
          }
          caseid={state.selectedErrorCaseId}
          changeOwner={(owner: string, errorId: string) =>
            dispatch({
              type: "changeErrorField",
              payload: { field: { owner }, errorId },
            })
          }
        />
      )}
      {state.showSchedulerNotificationEmailModal && (
        <SyncFailureEmailDeliveryModal
          show={state.showSchedulerNotificationEmailModal}
          onHide={() => dispatch({ type: "toggleSchedulerEmailModal" })}
          syncErrorId={state.selectedErrorCaseId}
        />
      )}
    </>
  )
}

export default SyncFailures
