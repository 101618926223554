import React from "react"
import { Select, useForm, FormItem } from "@app/components/Form"
import JobFormContainer from "../JobFormContainer"
import api, { useRequest, mutate } from "@app/services/api"
import { handleApiError } from "@app/utils"

type FormFieldsType = {
  skill_ids: number[]
}

type Props = {
  job: JobWithAllFieldsType
  nextSection?: string
}

export default (props: Props) => {
  const { job, nextSection } = props
  const { data: skills } = useRequest([api.getSkills])

  const { control, handleSubmit } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.object().shape({
        skill_ids: yup.array().ensure().of(yup.number()),
      }),
  })

  const submitForm = (fields: FormFieldsType, handleNextStep: any) => {
    return api.updateJob(job.jobid, fields).then((newJob) => {
      mutate([api.getJob, newJob.jobid], newJob, false)
      handleNextStep()
    }, handleApiError)
  }

  return (
    <JobFormContainer
      nextSection={nextSection}
      onSubmit={handleSubmit(submitForm)}
    >
      <FormItem label="Required Skills" name="skill_ids" control={control}>
        <Select
          multiple
          inline
          options={skills}
          loading={!skills}
          valueKey="skillid"
          defaultValue={job.skills.map((x) => x.skillid)}
        />
      </FormItem>
    </JobFormContainer>
  )
}
