interface IJob {
  jobid: number | string
  priority: number
  abbrev: string
  starttime: string
}

interface IProvider {
  providerid: number
  priority: number
  display_name: string
}

export function orderJobsFunc(
  a: IJob | { job: IJob },
  b: IJob | { job: IJob }
): number {
  const getJobValue = (obj: IJob | { job: IJob }): IJob =>
    "job" in obj && typeof obj.job === "object" ? obj.job : (obj as IJob)
  const jobA = getJobValue(a)
  const jobB = getJobValue(b)

  if (jobA.priority !== jobB.priority) return jobA.priority - jobB.priority
  if (jobA.abbrev !== jobB.abbrev)
    return jobA.abbrev.toLocaleLowerCase() > jobB.abbrev.toLocaleLowerCase()
      ? 1
      : -1
  if (jobA.starttime !== jobB.starttime)
    return jobA.starttime > jobB.starttime ? 1 : -1

  return 1
}

export function orderProvidersFunc(
  a: IProvider | { provider: IProvider },
  b: IProvider | { provider: IProvider }
): number {
  const getProviderValue = (
    obj: IProvider | { provider: IProvider }
  ): IProvider =>
    "provider" in obj && typeof obj.provider === "object"
      ? obj.provider
      : (obj as IProvider)
  const providerA = getProviderValue(a)
  const providerB = getProviderValue(b)

  if (providerA.priority !== providerB.priority)
    return providerA.priority - providerB.priority
  if (providerA.display_name !== providerB.display_name)
    return providerA.display_name.toLocaleLowerCase() >
      providerB.display_name.toLocaleLowerCase()
      ? 1
      : -1

  return 1
}
