import React from "react"
import Schedulers from "./components/Schedulers"
import Rotations from "./components/Rotations"
import { useScrollSync } from "@app/utils/hooks"

type Props = {
  id: string
}

export default ({ id }: Props) => {
  const syncScrollOptions = useScrollSync({
    vertical: false,
    proportional: false,
  })

  return (
    <>
      <Schedulers syncScrollOptions={syncScrollOptions} id={id} />
      <Rotations syncScrollOptions={syncScrollOptions} id={id} />
    </>
  )
}
