import React, { useMemo, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { H4, Text } from "@app/components/Typography"
import Modal from "@app/components/Modal"
import Card from "@app/components/Card"
import Icon from "@app/components/Icon"
import api, { useRequest } from "@app/services/api"
import css from "./DailyArchiveModal.module.scss"
import { useSearchParams } from "react-router-dom"
import { formatInTimeZone } from "date-fns-tz"
import { formatDate, formatDateInTimezone, getTimezoneAbbr } from "@app/utils"

type Props = {
  dailyArchiveShow: boolean
  group: CustomCalendarGroupType
  customCalendar: CustomCalendarType
  onHide: (status: boolean) => any
}

export default (props: Props) => {
  const { dailyArchiveShow, group, customCalendar, onHide } = props

  const [searchParams] = useSearchParams()
  const date = searchParams.get("date")

  const params = useMemo(() => (date ? { date } : undefined), [date])
  const { data: dailyArchive } = useRequest<DailyArchiveNoteType>(
    dailyArchiveShow
      ? [api.getDailyArchive, customCalendar.key, group.groupid, params]
      : null
  )

  const timezoneShortName = useMemo(
    () => customCalendar.timezone && getTimezoneAbbr(customCalendar.timezone),
    [customCalendar.timezone]
  )

  const handleModalHide = () => {
    onHide(false)
  }

  const now = date
    ? formatInTimeZone(new Date(date), "UTC", "PPPP")
    : formatInTimeZone(new Date(), customCalendar.timezone, "PPPP")

  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const modalTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <H4>{`${group?.name} Daily Archive`}</H4>
        <Text>{now}</Text>
      </div>
      <Icon
        hoverable
        name="printer"
        title="Print the Daily Archive"
        onClick={handlePrint}
      />
    </div>
  )

  return (
    <Modal
      title={modalTitle}
      titleAlign="left"
      className={css.dailyArchiveModal}
      show={dailyArchiveShow}
      onHide={handleModalHide}
    >
      {!dailyArchive ? (
        <div>Loading...</div>
      ) : (
        <div ref={printRef}>
          <div className={css.onlyForPrint}>
            <H4
              style={{ fontSize: "20px", lineHeight: "20px" }}
            >{`${group?.name} Daily Archive`}</H4>
            <Text style={{ fontSize: "16px" }}>{now}</Text>
          </div>
          {dailyArchive.notes.map((item) => {
            const startAt = formatDate(
              new Date(`${item.start_date}T${item.starttime}`),
              (f) => f.humanizedFullTimestamp
            )
            const endAt = formatDate(
              new Date(`${item.end_date}T${item.endtime}`),
              (f) => f.humanizedFullTimestamp
            )
            return (
              <Card
                title={item.job_name}
                key={item.noteid}
                className={css.thinCard}
              >
                <Text bold className={css.notice}>
                  {startAt} - {endAt}
                </Text>
                <div className="text-break align-items-center mt-3">
                  <span className="font-weight-bold">
                    {item.provider.display_name}
                  </span>
                  <span className="px-1">is covering</span>
                  <span className="font-weight-bold">{item.job_name}</span>
                  <span className="px-1">for</span>
                  <span className="font-weight-bold">
                    {item.previous_provider?.display_name}
                  </span>
                  <span className="px-1">from</span>
                  <span className="font-weight-bold">{startAt}</span>
                  <span className="px-1">to</span>
                  <span className="font-weight-bold">{endAt}</span>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Text bold className={css.label}>
                    Contact:
                  </Text>
                  <Text bold>{item.provider.display_name}</Text>
                </div>
                {Boolean(item.provider_contact_1?.length) && (
                  <div className="d-flex align-items-center">
                    <Text bold className={css.label}>
                      Pager:
                    </Text>
                    <Text>{item.provider_contact_1}</Text>
                  </div>
                )}
                {Boolean(item.provider_contact_pager?.length) && (
                  <div className="d-flex align-items-center">
                    <Text bold className={css.label}>
                      In-house Pager:
                    </Text>
                    <Text>{item.provider_contact_pager}</Text>
                  </div>
                )}
                {Boolean(item.provider_contact_2?.length) && (
                  <div className="d-flex align-items-center">
                    <Text bold className={css.label}>
                      Home:
                    </Text>
                    <Text>{item.provider_contact_2}</Text>
                  </div>
                )}
                {Boolean(item.provider_contact_3?.length) && (
                  <div className="d-flex align-items-center">
                    <Text bold className={css.label}>
                      Cell:
                    </Text>
                    <Text>{item.provider_contact_3}</Text>
                  </div>
                )}
                {Boolean(item.provider_contact_special?.length) && (
                  <div className="d-flex align-items-center">
                    <Text bold className={css.label}>
                      Other:
                    </Text>
                    <Text>{item.provider_contact_special}</Text>
                  </div>
                )}
                {Boolean(item.notes?.length) && (
                  <div className="align-items-center border-top mt-3 pt-3">
                    <Text bold className={css.label}>
                      Special Instructions:
                    </Text>
                    <Text>{item.notes}</Text>
                  </div>
                )}
                <div className="border-top mt-3 pt-3">
                  <div className="d-flex align-items-center">
                    <Text bold className={css.label}>
                      Contact Person:
                    </Text>
                    <Text>{item.contact}</Text>
                  </div>
                  <div className="d-flex align-items-center">
                    <Text bold className={css.label}>
                      Created on:
                    </Text>
                    <Text>
                      {formatDateInTimezone(
                        new Date(item.note_time),
                        customCalendar.timezone,
                        "MMMM do, yyyy hh:mm aa"
                      ) +
                        " " +
                        timezoneShortName}
                    </Text>
                  </div>
                  <div className="d-flex align-items-center">
                    <Text bold className={css.label}>
                      Created By:
                    </Text>
                    <Text>{item.user_name}</Text>
                  </div>
                </div>
              </Card>
            )
          })}
        </div>
      )}
    </Modal>
  )
}
