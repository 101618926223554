import { useEffect } from "react"
import * as yup from "yup"
import { useForm as useFormHook, Control, useController } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
export {
  Controller,
  useController,
  useFormState,
  useFieldArray,
  useWatch,
} from "react-hook-form"

export type UseControlProps = {
  name?: string
  defaultValue?: any
  control?: Control<any>

  // not in UseControllerProps
  value?: any
  onChange?: (...args: any[]) => any
  error?: { message: string; type: string }
  disabled?: boolean
  readOnly?: boolean
}

type useControlConfig = {
  nativeValue?: any
}

// useControl is using for Make a component which can control by useForm
export function useControl<T extends UseControlProps>(
  props: T,
  config?: useControlConfig
) {
  const { control, name, ...others } = props
  let controlProps = {}

  if (control && name) {
    const { defaultValue, value } = others
    const { field, fieldState } = useController({ control, name, ...others })

    const onChange = (...arg: any) =>
      [field?.onChange, props.onChange].forEach((fn) => fn?.(...arg))

    // Allow override defaultValue when no current value
    useEffect(() => {
      if (defaultValue != null && field.value == null) onChange(defaultValue)
    }, [[defaultValue].join()])

    // Make component to be controlled component
    controlProps = {
      name,
      control,
      onChange,
      ref: field.ref,
      value: value ?? field.value ?? config?.nativeValue,
      defaultValue: undefined,
      error: fieldState.error,
    }
  }

  return { name, control, ...others, ...controlProps }
}

type UseFormParamsType = Parameters<typeof useFormHook>[0] & {
  schema?: (y: typeof yup) => Parameters<typeof yupResolver>[0]
}

export function useForm<T>(config?: UseFormParamsType) {
  if (config && config.schema) {
    config.resolver = yupResolver(config.schema(yup))
    delete config.schema
  }

  const { handleSubmit, ...restHooks } = useFormHook<T>(
    config as PlainObjectType
  )

  return {
    ...restHooks,
    handleSubmit,
    handleModalSubmit: (onSubmit: (fields: T, closeModal: Function) => any) => {
      return (closeModal: Function) => {
        handleSubmit(onSubmit as any)(closeModal as any)
        return false
      }
    },
  }
}
