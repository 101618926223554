import React, { useMemo } from "react"
import { useSelector } from "@app/models"
import { H5, Text } from "@app/components/Typography"
import Icon from "@app/components/Icon"
import type { JobBlockType } from "@app/services/mdvShiftCalculation"
import { pluralize } from "@app/utils"
import JobRow from "./JobRow"
import ScheduleChange from "./ScheduleChange"

import cx from "classnames"
import css from "./JobBlock.module.scss"

type Props = {
  jobBlock: JobBlockType
  customCalendarKey: string
  setPickedProviderId: (providerId: number) => any
  setPickedSendSecureMessageSource: (source: {
    type: "provider" | "job"
    id: number
  }) => any
  setPickedJobForScheduleChange: (job: JobBlockType | null) => any
  group: CustomCalendarGroupType
}

export default (props: Props) => {
  const {
    jobBlock,
    customCalendarKey,
    setPickedProviderId,
    setPickedSendSecureMessageSource,
    setPickedJobForScheduleChange,
    group,
  } = props

  const { id, job, shifts: jobRows } = jobBlock
  const jobInfoBgClass = useMemo(() => {
    if (!jobBlock) {
      return ""
    }

    return {
      past: "isPast",
      current: "isCurrent",
      future: "isFuture",
    }[jobBlock.pastFutureType]
  }, [jobBlock?.pastFutureType])

  const { currentOnViewNoteUser } = useSelector((state) => state.users)
  const { customCalendar } = useSelector((state) => state.customCalendars)

  return (
    <div key={id} className={css.jobBlock}>
      <div className={cx(css.jobInfo, css[jobInfoBgClass])}>
        <H5
          ellipsis
          bold
          variant="inherit"
          className={
            customCalendar.allow_messaging_to_roles &&
            job.tigerconnect_roles_count > 0
              ? css.hoverLink
              : ""
          }
          title={
            job.groupid === group.groupid
              ? job.name
              : `${job.name} (${job.group?.name})`
          }
          onClick={() => {
            if (
              !customCalendar.allow_messaging_to_roles ||
              job.tigerconnect_roles_count === 0
            )
              return
            setPickedSendSecureMessageSource({
              type: "job",
              id: job.jobid,
            })
          }}
        >
          {job.name}
        </H5>
        {job.groupid !== group.groupid && (
          <p>
            Imported from{" "}
            {customCalendar.enable_group_calendar_links ? (
              <a
                className={css.groupCalendarLink}
                href={`/calendar/whiteboard_gen.cgi?group_key=${
                  job.group?.group_key
                }&calendar_group_key=${job.group?.calendar_group_key || ""}`}
                target="_blank"
              >
                {job.group?.name}
              </a>
            ) : (
              <u>{job.group?.name}</u>
            )}
          </p>
        )}
        <div
          className="d-flex justify-content-between"
          style={{ paddingRight: "3em" }}
        >
          <Text variant="inherit" className="flex-shrink-0">
            {job.starttime_text}
            <Icon resetLineHeight name="dot" />
            {job.endtime_text}
          </Text>
        </div>
        {(job.tigerconnect_roles_count > 0 ||
          currentOnViewNoteUser?.allow_edit) &&
          !group.is_nested_calendar && (
            <>
              {customCalendar.allow_messaging_to_roles && (
                <div className={css.roleLine}>
                  {pluralize(job.tigerconnect_roles_count, "Role")}
                </div>
              )}
              <div className={css.chatIcon}>
                <ScheduleChange
                  className="chatIcon"
                  jobBlock={jobBlock}
                  onViewNotes={customCalendar.on_view_notes}
                  setPickedSendSecureMessageSource={
                    setPickedSendSecureMessageSource
                  }
                  setPickedJobForScheduleChange={setPickedJobForScheduleChange}
                />
              </div>
            </>
          )}
      </div>
      <div className={css.shiftBlock}>
        {jobRows.map((jobRow) => (
          <JobRow
            key={jobRow.id}
            jobRow={jobRow}
            customCalendarKey={customCalendarKey}
            setPickedProviderId={setPickedProviderId}
            setPickedSendSecureMessageSource={setPickedSendSecureMessageSource}
          />
        ))}
      </div>
    </div>
  )
}
