import React from "react"
import { Select, Input, useForm, FormItem } from "@app/components/Form"
import { Row, Col } from "@app/components/Layout"
import { Text } from "@app/components/Typography"
import JobFormContainer from "../JobFormContainer"
import api, { mutate } from "@app/services/api"
import { handleApiError } from "@app/utils"
import { JobDays } from "@app/services/jobHelper"

type FormFieldsType = {
  starttime: string
  endtime: string
  day_ids: number[]
}

type Props = {
  job: JobWithAllFieldsType
}

export default (props: Props) => {
  const { job } = props

  const { control, handleSubmit } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          starttime: yup.string().required().label("Start Time"),
          endtime: yup.string().required().label("End Time"),
          day_ids: yup
            .array()
            .ensure()
            .min(1, "You must select one or more days of the week"),
        })
      ),
  })

  const submitForm = (fields: FormFieldsType, handleNextStep: any) => {
    return api.updateJob(job.jobid, fields).then((newJob) => {
      mutate([api.getJob, newJob.jobid], newJob, false)
    }, handleApiError)
  }

  return (
    <JobFormContainer onSubmit={handleSubmit(submitForm)}>
      <FormItem label="Pick Work Time">
        <Row>
          <Col>
            <Text bold className="mb-2">
              Start Time
            </Text>
            <Input
              type="time"
              name="starttime"
              control={control}
              defaultValue={job.starttime || "00:00:00"}
            />
          </Col>
          <Col>
            <Text bold className="mb-2">
              End Time
            </Text>
            <Input
              type="time"
              name="endtime"
              control={control}
              defaultValue={job.endtime || "00:00:00"}
            />
          </Col>
        </Row>
      </FormItem>
      <FormItem label="Days to Assign" control={control} name="day_ids">
        <Select
          multiple
          inline
          defaultValue={job.day_types.map((x) => x.dayid)}
          options={JobDays}
        />
      </FormItem>
    </JobFormContainer>
  )
}
