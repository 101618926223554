import React, { useReducer } from "react"
import type { ColumnType } from "../Table"
import FilterDropdown from "./FilterDropdown"
import { get as lodashGet } from "lodash"

export interface FilterState<T> {
  column: ColumnType<T>
  filterValue?: any[]
}

export default function useTableFilter<T>() {
  const [filtersState, triggerFilter] = useReducer(
    (state: FilterState<T>[], payload: FilterState<T>) => {
      const foundIndex = state.findIndex(
        (x) => x.column.dataKey === payload.column.dataKey
      )
      foundIndex >= 0 ? (state[foundIndex] = payload) : state.push(payload)
      return [...state]
    },
    []
  )

  // Add sort icons and handle header click
  const transformColumns = (columns: ColumnType<T>[]) => {
    return columns.map((column) => {
      if (!column.filter) {
        return column
      }

      const filterState = filtersState.find(
        (x) => x.column.dataKey === column.dataKey
      )

      return {
        ...column,
        title: (
          <FilterDropdown
            column={column}
            filterState={filterState}
            triggerFilter={triggerFilter}
          >
            {column.title}
          </FilterDropdown>
        ),
      }
    })
  }

  return { filtersState, transformColumns }
}

// Process data filter logic
export function getFilterData<T>(
  data: Readonly<T[]> | undefined,
  filtersState: FilterState<T>[]
) {
  if (!data || !filtersState.length) {
    return data || []
  }

  return data.filter((item) => {
    return filtersState.every(({ column, filterValue }) => {
      if (!column || !filterValue?.length || !column.filter) {
        return true
      }

      const { dataKey, onFilter } = column
      const itemValue = lodashGet(item, `${dataKey}`)

      return onFilter
        ? onFilter(filterValue, itemValue, item)
        : filterValue.includes(itemValue)
    })
  })
}
