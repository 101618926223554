import React, { useContext } from "react";
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext";
import { formatTime } from "../../service";

const MultipleAssignmentsJobRow: React.FC = () => {
    const { currentMultipleAssignmentsJobId, jobs } = useContext(ManualScheduleTableContext);
    return (
        <>
            <tr>
                <td></td>
                <td>
                    {jobs.find(j => j.jobid == currentMultipleAssignmentsJobId)?.name}
                </td>
                <td>
                    {formatTime(jobs.find(j => j.jobid == currentMultipleAssignmentsJobId)?.starttime as string)} - {formatTime(jobs.find(j => j.jobid == currentMultipleAssignmentsJobId)?.endtime as string)}
                </td>
                <td></td>
                <td></td>
            </tr>
        </>
    );
}

export default MultipleAssignmentsJobRow;