import React, { useState } from "react"
import { H3 } from "@app/components/Typography"
import { FormItem, Select } from "@app/components/Form"
import api from "@app/services/api"
import Button from "@app/components/Button"

type PropsType = {
  groups: GroupType[]
  calendarid: number
  groupid: number
  externalJobids: number[]
}

export default (props: PropsType) => {
  const { groups, calendarid, groupid, externalJobids } = props
  const [jobOptions, setJobOptions] = useState<JobType[]>([])
  const [selectedGroupId, setSelectedGroupId] = useState<number>()
  const [selectedJobId, setSelectedJobId] = useState("")
  const [jobsLoading, setJobsLoading] = useState(false)

  const getJobOptions = (groupid: number) => {
    setSelectedGroupId(groupid)
    setJobsLoading(true)
    setSelectedJobId("")
    api.getJobs(undefined, groupid).then((jobs) => {
      const jobOptionsMapped = jobs
        .filter((job: JobType) => !externalJobids.includes(job.jobid))
        .map((job: JobType) => {
          return {
            id: `jobid_${job.jobid}`,
            name: job.name,
          }
        })
      setJobOptions(jobOptionsMapped)
      setJobsLoading(false)
    })
  }

  const addJob = () => {
    const input = document.createElement("input")
    input.name = selectedJobId
    input.value = "1"
    input.type = "hidden"
    ;(document.getElementById("job_form") as any).appendChild(input)
    const submitButton = document.getElementById("submitJobs") as any

    ;[
      {
        key: "calendarid",
        value: calendarid.toString(),
      },
      {
        key: "step",
        value: "3",
      },
      {
        key: "step_groupid",
        value: groupid.toString(),
      },
      {
        key: "location",
        value: window.location.href,
      },
    ].forEach(({ key, value }) => {
      const navInput = document.createElement("input")
      navInput.name = key
      navInput.value = value
      navInput.type = "hidden"
      ;(document.getElementById("job_form") as any).appendChild(navInput)
    })
    submitButton.click()
  }

  return (
    <div className="bootstrap4">
      <div className="d-flex justify-content-center">
        <H3 bold>Add job</H3>
      </div>
      <hr />
      <div>
        <FormItem label="Group:">
          <Select
            options={groups.map((group: GroupType) => {
              return {
                id: group.groupid,
                name: `${group.name} (${group.groupid})`,
              }
            })}
            value={selectedGroupId}
            onChange={(groupid) => getJobOptions(groupid)}
          />
        </FormItem>
        <FormItem label="Job:">
          <Select
            options={jobOptions}
            value={selectedJobId || undefined}
            onChange={(jobid) => setSelectedJobId(jobid)}
            disabled={!jobOptions}
            loading={jobsLoading}
          />
        </FormItem>
      </div>
      <Button size="sm" onClick={addJob}>
        Add
      </Button>
    </div>
  )
}
