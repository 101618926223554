import { useEffect, useState } from "react"

interface Note {
  id: number
  authorName: string
  content: string
}

const notes: Note[] = [
  {
    id: 1,
    authorName: "Alice",
    content:
      "This is the first note. It contains a brief introduction to the project and outlines the goals and objectives.",
  },
  {
    id: 2,
    authorName: "Bob",
    content:
      "This is the second note. It includes a list of tasks to be completed this week, along with deadlines and assigned team members.",
  },
  {
    id: 3,
    authorName: "Charlie",
    content:
      "This is the third note. It covers the recent meeting minutes and summarizes the key decisions and action items.",
  },
  {
    id: 4,
    authorName: "Diana",
    content:
      "This is the fourth note. It describes the technical challenges faced during the last sprint and potential solutions being considered.",
  },
  {
    id: 5,
    authorName: "Eve",
    content:
      "This is the fifth note. It contains feedback from the client on the recent project demo and areas for improvement.",
  },
  {
    id: 6,
    authorName: "Frank",
    content:
      "This is the sixth note. It lists the new features proposed for the next release and the estimated time required for development.",
  },
  {
    id: 7,
    authorName: "Grace",
    content:
      "This is the seventh note. It provides a detailed analysis of the latest market trends and how they impact our project strategy.",
  },
  {
    id: 8,
    authorName: "Hank",
    content:
      "This is the eighth note. It includes a comprehensive risk assessment and mitigation plan for the upcoming project phase.",
  },
  {
    id: 9,
    authorName: "Ivy",
    content:
      "This is the ninth note. It highlights the key achievements and milestones reached in the last quarter.",
  },
  {
    id: 10,
    authorName: "Jack",
    content:
      "This is the tenth note. It contains a proposal for a new feature set, including detailed specifications and user stories.",
  },
]

const fetchNotes = (): Promise<Note[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(notes)
    })
  })
}

const updateNoteAPI = (id: number, updatedNote: Note): Promise<Note> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(updatedNote), 1000)
  })
}

const deleteNoteAPI = (id: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 1000)
  })
}

export const useGetNotesList = () => {
  const [notesList, setNotesList] = useState<Note[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const notes = await fetchNotes()
        setNotesList(notes)
        setLoading(false)
      } catch (err) {
        setError("Failed to fetch notes")
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const addNote = (newNote: Note) => {
    setNotesList((prevNotes) => [...prevNotes, newNote])
  }

  const updateNote = async (id: number, content: string) => {
    try {
      const updatedNote = {
        id,
        authorName: notesList.find((note) => note.id === id)!.authorName,
        content,
      }
      await updateNoteAPI(id, updatedNote)
      setNotesList(
        notesList.map((note) => (note.id === id ? updatedNote : note))
      )
    } catch (err) {
      setError("Failed to update note")
    }
  }

  const deleteNote = async (id: number) => {
    try {
      await deleteNoteAPI(id)
      setNotesList(notesList.filter((note) => note.id !== id))
    } catch (err) {
      setError("Failed to delete note")
    }
  }

  return { notesList, loading, error, addNote, updateNote, deleteNote }
}
