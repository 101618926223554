import React from "react"
import BsAlert from "react-bootstrap/Alert"

type Props = React.ComponentProps<typeof BsAlert>

const Alert: React.FC<Props> & {
  Link: typeof BsAlert.Link
  Heading: typeof BsAlert.Heading
} = (props) => <BsAlert {...props} transition={false} />

Alert.Link = BsAlert.Link
Alert.Heading = BsAlert.Heading

export default Alert
