import React from "react"

export default class GroupLookup extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
  }

  public render(): JSX.Element {
    return <h1>Group Lookup Page</h1>
  }
}
