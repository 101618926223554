import React, { useState } from "react"
import { useSelector, useDispatch } from "@app/models"
import { Text } from "@app/components/Typography"
import Icon from "@app/components/Icon"
import Modal, { Dialog } from "@app/components/Modal"
import { useForm, Input } from "@app/components/Form"
import { formatDate, formatBlocksDate } from "@app/utils"
import { Resizable } from "re-resizable"
import cx from "classnames"
import css from "./Block.module.scss"

type FormFieldsType = {
  start_date?: string
  end_date?: string
}

type Props = {
  id: string
  isBuffer: boolean
  blockSetId: string
  startDate: Date
  endDate: Date
  left: number
  width: number
  resizedLeft: number
  resizedWidth: number
  minWidth?: number
  maxWidth?: number
  minStartDate?: Date
  maxEndDate?: Date
  daysCount?: number
  blockHeight?: number
}

export default (props: Props) => {
  const {
    id,
    isBuffer,
    blockSetId,
    startDate,
    endDate,
    resizedLeft,
    resizedWidth,
    minWidth,
    maxWidth,
    minStartDate,
    maxEndDate,
    daysCount,
    blockHeight,
  } = props

  const dispatch = useDispatch()
  const { height } = useSelector((state) => state.blockSets.blockConfig)

  const [dateFormShow, setDateFormShow] = useState(false)
  const [dateUpdateField, setDateUpdateField] = useState<string>("start_date")
  const { handleModalSubmit, control, reset } = useForm<FormFieldsType>()

  // Generate version for making blocks updates orderly
  const generateVersion = () => Date.now()

  const handleBlockResizeStart = (
    event: React.SyntheticEvent,
    direction: string,
    ref: any
  ) => {
    dispatch.blockSets.updateBlockSetVersion({ blockSetId, version: Infinity })
    dispatch.blockSets.setBlockWidthLimitation({
      blockSetId,
      blockId: id,
      direction,
    })
  }

  const handleBlockResize = (
    event: TouchEvent | MouseEvent,
    direction: string,
    ref: any,
    { width: distance }: { width: number; height: number }
  ) => {
    dispatch.blockSets.adjustBlockPosition({
      blockSetId,
      blockId: id,
      direction,
      distance,
    })
  }

  const handleBlockResizeStop = (
    event: TouchEvent | MouseEvent,
    direction: string,
    ref: any,
    { width: distance }: { width: number; height: number }
  ) => {
    const version = generateVersion()

    // Use `version` to dismiss outdated request
    dispatch.blockSets.updateBlockSetVersion({ blockSetId, version })
    dispatch.blockSets.comfirmBlockPosition({
      blockSetId,
      blockId: id,
      direction,
      version,
    })
  }

  const handleBlockDelete = () => {
    Dialog.confirm({
      title: "Delete Block",
      message: (
        <p>
          Are you sure you want to delete the block? Action can not be undone.
        </p>
      ),
      buttons: {
        ok: {
          text: "Delete",
          variant: "danger",
          onClick() {
            return dispatch.blockSets.deleteBlock({ blockSetId, blockId: id })
          },
        },
      },
    })
  }

  const handleDateClick = (dateType: "start_date" | "end_date") => {
    const version = generateVersion()
    dispatch.blockSets.updateBlockSetVersion({ blockSetId, version })

    dispatch.blockSets.setBlockDateLimitation({
      blockSetId,
      blockId: id,
      direction: { start_date: "left", end_date: "right" }[dateType],
    })

    setDateFormShow(true)
    setDateUpdateField(dateType)
  }

  const onDateFormSubmit = (fields: FormFieldsType, closeModal: Function) =>
    dispatch.blockSets
      .updateBlockDate({ blockSetId, blockId: id, fields })
      .then(() => {
        closeModal()
        reset({}) // reset DateUpdateForm
      })

  return (
    <Resizable
      className={cx(css.block, isBuffer ? css.blockBuffer : css.blockNormal)}
      size={{ height: blockHeight || height, width: resizedWidth }}
      enable={{ left: id !== "bufferLeft", right: id !== "bufferRight" }}
      onResize={handleBlockResize}
      onResizeStop={handleBlockResizeStop}
      onResizeStart={handleBlockResizeStart}
      minWidth={minWidth}
      maxWidth={maxWidth}
      style={{
        position: "absolute",
        left: resizedLeft,
      }}
    >
      {!isBuffer && (
        <>
          <Modal
            disableBodyScroll
            title={`Update Block Date`}
            show={dateFormShow}
            onHide={setDateFormShow}
            buttons={[
              { text: "Cancel", variant: "outline-primary" },
              {
                control,
                text: "Save",
                variant: "primary",
                onClick: handleModalSubmit(onDateFormSubmit),
              },
            ]}
          >
            <div className={css.dateUpdateForm}>
              <Text bold className="mb-2">
                Select{" "}
                {dateUpdateField === "start_date" ? "start date" : "end date"}{" "}
                to resize the block:
              </Text>
              <Input
                type="date"
                name={dateUpdateField}
                control={control}
                value={formatDate(
                  dateUpdateField === "start_date" ? startDate : endDate
                )}
                dayPickerProps={{
                  disabledDays: {
                    before: minStartDate || startDate,
                    after: maxEndDate || endDate,
                  },
                }}
              />
            </div>
          </Modal>
          <div className={css.blockHeader}>
            <Text
              className={css.startDate}
              onClick={() => handleDateClick("start_date")}
            >
              {formatBlocksDate(startDate)}
            </Text>
            <Icon hoverable name="close" onClick={handleBlockDelete} />
          </div>
          <div className={css.blockBody}></div>
          <div className={css.blockFooter}>
            <Text
              className={css.endDate}
              onClick={() => handleDateClick("end_date")}
            >
              {formatBlocksDate(endDate)}
            </Text>
          </div>
          <div className={css.blockDaysCount}>{daysCount}d</div>
        </>
      )}
    </Resizable>
  )
}
