import { init, Models, RematchDispatch, RematchRootState } from "@rematch/core"
import immerPlugin from "@rematch/immer"
import selectPlugin from "@rematch/select"
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux"
import users from "./users"
import blockSets from "./blockSets"
import filterCollections from "./filterCollections"
import customCalendars from "./customCalendars"
import groupShifts from "./groupShifts"
import rotationOperations from "./rotationOperations"
import calendarEvents from "./calendarEvents"
import groupData from "./groupData"
import quickAssignments from "./quickAssignments"

export interface RootModel extends Models<RootModel> {
  users: typeof users
  blockSets: typeof blockSets
  filterCollections: typeof filterCollections
  customCalendars: typeof customCalendars
  groupShifts: typeof groupShifts
  rotationOperations: typeof rotationOperations
  calendarEvents: typeof calendarEvents
  groupData: typeof groupData
  quickAssignments: typeof quickAssignments
}

const store = init<RootModel>({
  models: {
    users,
    blockSets,
    filterCollections,
    customCalendars,
    groupShifts,
    rotationOperations,
    calendarEvents,
    groupData,
    quickAssignments,
  },
  plugins: [immerPlugin(), selectPlugin()],
})

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>

// Export useDispatch and useSelector with Rematch TS Type
export const useDispatch: <T extends Dispatch>() => T = useReduxDispatch
export const useSelector: <T extends RootState, U extends any>(
  selector: (state: T) => U,
  fn?: (left: U, right: U) => boolean
) => U = useReduxSelector
export const { select } = store

export default store
