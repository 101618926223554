export class Role {
  userid: number
  groupid: number
  group_name: string
  roleid: number
  clinicid: number
  clinic_name: string
  providerid?: number
  name: string
  schedulerid?: number

  constructor(args: any) {
    this.userid = args.userid
    this.groupid = args.groupid
    this.roleid = args.roleid
    this.clinicid = args.clinicid
    this.clinic_name = args.clinic_name
    this.name = args.provider_name ?? args.display_name
    this.providerid = args.providerid
    this.schedulerid = args.schedulerid
    this.group_name = args.group_name
  }

  public get entityid() {
    return this.providerid ? this.providerid : this.schedulerid
  }

  public get roleName(): string {
    const types: { [index: string]: string } = {
      "5": "Trainer",
      "6": "Scheduler",
      "7": "Provider",
    }
    return types[String(this.roleid)]
  }

  public get groupName() {
    return this.group_name
  }

  public get clinicName() {
    return this.clinic_name
  }
}
