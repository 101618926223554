import { useEffect, useState } from "react"
import { useSelector } from "@app/models"
import api from "@app/services/api"

// Hook for Can-I-Use a certain feature
export const useClinicPermission = (permissionName: string) => {
  const [permissionStatus, setPermissionStatus] = useState<boolean | null>(null)
  const { clinic, current_role } = useSelector(
    (state) => state.users.currentUser
  )

  const queryPermissions = async (clinicid: number) => {
    const resPermision = await api.getMyClinicPermissions(
      clinicid,
      permissionName
    )
    setPermissionStatus(Boolean(resPermision))
  }

  useEffect(() => {
    if (current_role) {
      if (current_role.roleid === 5) {
        setPermissionStatus(true)
      } else if (clinic) {
        queryPermissions(Number(clinic.clinicid))
      }
    }
  }, [clinic, current_role])

  return permissionStatus
}
