import React from "react"
import { Text } from "@app/components/Typography"
import Avatar from "@app/components/Avatar"
import Modal from "@app/components/Modal"
import { useForm, FormItem, Input, Select } from "@app/components/Form"
import Button from "@app/components/Button"
import api, { useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"

type Props = {
  source: { type: "provider" | "job"; id: number } | null
  customCalendar: CustomCalendarType
  onHide: (status: boolean) => any
}

type SendMessageFormFields = {
  job_role_token_id?: string
  tigertext_from?: string
  tigertext_callback?: string
  tigertext_message: string
}

export default (props: Props) => {
  const { source, customCalendar, onHide } = props
  const {
    control,
    reset: resetForm,
    handleSubmit,
  } = useForm({
    schema: (yup) =>
      yup.lazy(() => {
        const baseShape = {
          tigertext_message: yup.string().required().label("Message"),
        }

        if (source?.type === "job") {
          Object.assign(baseShape, {
            job_role_token_id: yup.string().required().label("To Job Role"),
          })
        }

        return yup.object().shape(baseShape)
      }),
  })

  const { data: provider } = useRequest<CustomCalendarProviderType>(
    source?.type === "provider"
      ? [api.getCustomCalendarProvider, customCalendar.key, source.id]
      : null
  )

  const { data: job } = useRequest<JobType>(
    source?.type === "job" ? [api.getJob, source.id] : null
  )

  const handleModalHide = () => {
    resetForm()
    onHide(false)
  }

  const handleMessageSubmit = (fields: SendMessageFormFields) => {
    if (!source) return

    const providerParams =
      source?.type === "provider" ? { providerid: source.id } : {}

    return api
      .postCustomCalendarProviderTCMessage(customCalendar.key, {
        ...providerParams,
        ...fields,
      })
      .then(handleModalHide)
      .catch(handleApiError)
  }

  return (
    <Modal
      title="Send Secure Message"
      titleAlign="left"
      show={Boolean(source)}
      onHide={handleModalHide}
    >
      {source?.type === "provider" && (
        <FormItem layout={[4, 8]} label="To Provider">
          <div className="d-flex align-items-center">
            <Avatar size={25} src={provider?.avatar} className="mr-3" />
            <Text>{provider?.fullname}</Text>
          </div>
        </FormItem>
      )}
      {source?.type === "job" && (
        <FormItem
          required
          layout={[4, 8]}
          label="To Job Role"
          control={control}
          name="job_role_token_id"
        >
          <Select
            loading={!job}
            options={job?.tigerconnect_roles || []}
            labelKey="entity_name"
            defaultValue={job?.tigerconnect_roles[0]?.id}
            renderOption={(_, role) => (
              <>
                <span>{role.entity_name}</span>
                {role.entity_tag_name && (
                  <span
                    className="ml-1"
                    style={{ color: role.entity_tag_color }}
                  >
                    ({role.entity_tag_name})
                  </span>
                )}
              </>
            )}
          />
        </FormItem>
      )}
      <FormItem
        layout={[4, 8]}
        label="From"
        control={control}
        name="tigertext_from"
      >
        <Input />
      </FormItem>
      <FormItem
        layout={[4, 8]}
        label="Callback Number"
        control={control}
        name="tigertext_callback"
      >
        <Input />
      </FormItem>
      <FormItem
        required
        layout={[4, 8]}
        label="Message"
        control={control}
        name="tigertext_message"
      >
        <Input type="textarea" rows={3} />
      </FormItem>
      <div className="mt-3 text-right">
        <Button size="sm" onClick={handleSubmit(handleMessageSubmit)}>
          Send Message
        </Button>
      </div>
    </Modal>
  )
}
