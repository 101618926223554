import React, { useMemo } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import BaseLayout from "@app/containers/layouts/Base"
import Breadcrumb from "@app/components/Breadcrumb"
import Card from "@app/components/Card"
import Loader from "@app/components/Loader"
import Tabs from "@app/components/Tabs"
import { H2 } from "@app/components/Typography"
import PropertiesForm from "./components/Properties"
import DayTypesForm from "./components/DayTypes"
import RequiredSkillsForm from "./components/RequiredSkills"
import ProviderTypesForm from "./components/ProviderTypes"
import LinkedRotationsForm from "./components/LinkedRotations"
import StaffingTargets from "./components/StaffingTargets"
import { detectJobType, checkIfResidentJob } from "@app/services/jobHelper"
import api, { useRequest } from "@app/services/api"
import css from "./CreateOrUpdateJob.module.scss"
import { useCaniuseFeature } from "@app/utils/hooks"

const sectionMappings = [
  {
    title: "Properties",
    eventKey: "properties",
    component: PropertiesForm,
  },
  {
    title: "Day Types",
    eventKey: "day_types",
    component: DayTypesForm,
    nextIndex(index: number, isCreatingForm: boolean) {
      return isCreatingForm ? index + 2 : index + 1
    },
  },
  {
    title: "Required Skills",
    eventKey: "required_skills",
    component: RequiredSkillsForm,
  },
  {
    title: "Enabled Provider Types",
    eventKey: "provider_types",
    component: ProviderTypesForm,
  },
  {
    title: "Linked Rotations",
    eventKey: "linked_rotations",
    component: LinkedRotationsForm,
  },
  {
    title: "Assignment Requirements",
    eventKey: "staffing_targets",
    component: StaffingTargets,
  },
]

export default () => {
  const { jobId } = useParams()
  const [searchParams] = useSearchParams()
  const jobTypeId = parseInt(searchParams.get("job_typeid") || "1")
  const jobType = detectJobType(jobTypeId)

  const multipleAssignmentsEnabled = useCaniuseFeature("multiple_assignments", {
    scope: "group",
  })

  const { data: job } = useRequest<JobWithAllFieldsType>(
    jobId ? [api.getJob, parseInt(jobId)] : null
  )

  const isCreatingForm = useMemo(() => {
    return !jobId || searchParams.get("from") === "new"
  }, [jobId])

  const tabs = useMemo(() => {
    let sections = [...sectionMappings]

    if (!checkIfResidentJob(job)) {
      if (!multipleAssignmentsEnabled || !job?.multiple_assignments) {
        sections = sections.filter(
          (x) =>
            x.eventKey !== "linked_rotations" &&
            x.eventKey !== "staffing_targets"
        )
      } else {
        sections = sections.filter((x) => x.eventKey !== "linked_rotations")
      }
    } else {
      if (!multipleAssignmentsEnabled || !job?.multiple_assignments) {
        sections = sections.filter((x) => x.eventKey !== "staffing_targets")
      }
      if (!job?.link_to_rotations) {
        sections = sections.filter((x) => x.eventKey !== "linked_rotations")
      }
    }

    return sections.map((item, index) => {
      const { component: SectionComponent, nextIndex, ...rest } = item
      const nextSectionIndex = nextIndex?.(index, isCreatingForm) || index + 1
      const nextEventKey = sections[nextSectionIndex]?.eventKey

      return {
        ...rest,
        content: (
          <SectionComponent
            job={job as JobWithAllFieldsType}
            jobTypeId={jobTypeId}
            nextSection={nextEventKey}
          />
        ),
      }
    })
  }, [job, jobTypeId])

  return (
    <BaseLayout>
      {job && <H2 className="pl-5 mb-4">{job.name}</H2>}
      <Card
        disableBodyScroll
        className={css.jobCard}
        title={
          !jobId && (
            <Breadcrumb className={css.breadcrumb}>
              <Breadcrumb.Item active>Add {jobType?.name} Job</Breadcrumb.Item>
              <Breadcrumb.Item active className={css.sectionTitle}>
                {tabs[0].title}
              </Breadcrumb.Item>
            </Breadcrumb>
          )
        }
      >
        {!jobId ? (
          <div className="p-4">{tabs[0].content}</div>
        ) : job ? (
          <Tabs
            asRoute
            tabs={tabs}
            variant="navigation"
            className={css.jobFormNavigation}
          />
        ) : (
          <div className="d-flex p-3 justify-content-center">
            <Loader />
          </div>
        )}
      </Card>
    </BaseLayout>
  )
}
