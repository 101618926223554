import React, { useMemo } from "react"
import RcTable from "rc-table"
import { TableProps as RcTableProps, INTERNAL_HOOKS } from "rc-table/lib/Table"
import { ColumnType as RcColumnType } from "rc-table/lib/interface"
import Loader from "@app/components/Loader"
import useTableSorter, { SortOrderType, getSortData } from "./useTableSorter"
import useTableFilter, { getFilterData } from "./useTableFilter"
import cx from "classnames"
import css from "./Table.module.scss"

export interface ColumnType<T> extends RcColumnType<T> {
  title?: string | React.ReactNode

  // Be compatible with the old Bootstrap table
  dataKey?: RcColumnType<T>["dataIndex"]
  position?: RcColumnType<T>["align"]

  // Feature props
  forceWrap?: boolean
  sorter?:
    | boolean
    | string
    | ((a: T, b: T, sortOrder?: SortOrderType) => number)
  filterTitle?: string | React.ReactNode
  onFilter?: (filterValue: any[], val: any, item: T) => boolean
  filter?: {
    options: { id: string | number; name: string | number }[]
    multiple?: boolean
  }
}

// Learn more about table props:
// https://github.com/react-component/table
interface TableProps<T> extends Omit<RcTableProps<T>, "columns"> {
  columns?: ColumnType<T>[]
  loading?: boolean
  emptyMessage?: string | React.ReactNode
  variant?: "default" | "frame" | "noBorder"
  striped?: boolean
  hover?: boolean
}

function Table<T>(
  props: TableProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { className, style, ...tableProps } = props
  const {
    data,
    columns,
    rowKey = "id",
    rowClassName,
    loading,
    emptyMessage = "No Data.",
    variant = "default",
    striped = true,
    hover = true,
  } = tableProps

  const { transformColumns: transformSorterColumns, sorterState } =
    useTableSorter<T>()

  const { transformColumns: transformFilterColumns, filtersState } =
    useTableFilter<T>()

  const transformedColumns = useMemo(() => {
    const innerColumns = !columns
      ? columns || []
      : columns.map((column) => ({
          ...column,
          filterTitle: column.filterTitle || column.title,
          dataIndex: column["dataKey"],
          align: column["position"],
          className: cx(column["className"], {
            "rc-column--force-wrap": column.forceWrap,
          }),
        }))
    return transformFilterColumns(transformSorterColumns(innerColumns))
  }, [columns, transformSorterColumns, transformFilterColumns])

  const getRowKey = useMemo(() => {
    if (typeof rowKey === "function") {
      return rowKey
    }

    return (record: T) => (record as any)?.[rowKey as string]
  }, [rowKey])

  const internalRowClassName = (record: T, index: number, indent: number) => {
    let mergedRowClassName

    if (typeof rowClassName === "function") {
      mergedRowClassName = cx(rowClassName(record, index, indent))
    } else {
      mergedRowClassName = cx(rowClassName)
    }

    return mergedRowClassName
  }

  const sortedData = useMemo(
    () => getSortData(data, sorterState),
    [data, sorterState]
  )
  const mergedData = getFilterData(sortedData, filtersState)

  return (
    <div ref={ref} className={cx(css.tableWrapper, className)} style={style}>
      <RcTable<T>
        {...tableProps}
        columns={transformedColumns}
        className={cx("react-table", css[`table-${variant}`], {
          [css.noData]: mergedData.length === 0,
          "table-striped": striped,
          "table-hover": hover,
        })}
        data={mergedData}
        rowKey={getRowKey}
        rowClassName={internalRowClassName}
        emptyText={
          <div className="d-flex justify-content-center">
            {loading ? <Loader animation="dots" /> : emptyMessage}
          </div>
        }
        internalHooks={INTERNAL_HOOKS}
      />
    </div>
  )
}

// How to pass generic to forwardRefs:
// https://fettblog.eu/typescript-react-generic-forward-refs/
export default React.forwardRef(Table) as <T extends PlainObjectType>(
  props: TableProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof Table>
