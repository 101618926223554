import React, { useState } from "react"
// import JobFormContainer from "../JobFormContainer"
import Table, { ColumnType } from "@app/components/Table"
import Button from "@app/components/Button"
import { Dialog } from "@app/components/Modal"
// import Select from "@app/components/Form/Select"
import cx from "classnames"
import css from "./AdditionalAssignmentDailyOverrides.module.scss"
import api, { useRequest } from "@app/services/api"
import { handleApiError, parseDate } from "@app/utils"

import DailyOverrideForm from "./AdditionalAssignmentDailyOverrideForm"

type Props = {
  jobid: number
  jobName: string
}

export default (props: Props) => {
  const { jobid, jobName } = props
  const [dailyOverride, setDailyOverride] =
    useState<AdditionalAssignmentDailyOverride | null>(null)

  const { data: dailyOverrides, mutate } = useRequest([
    api.getAdditionalAssignmentDailyOverrides,
    jobid,
  ])

  const handleDailyOverrideDelete = (
    deleteOverride: AdditionalAssignmentDailyOverride
  ) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (parseDate(deleteOverride.date) < today) {
      Dialog.warn({
        title: "Notice",
        message: (
          <>
            <p>
              You cannot delete an assignment requirement override for a past
              date.
            </p>
          </>
        ),
      })
    } else {
      Dialog.confirm({
        title: "Delete Daily Override",
        message: (
          <p>
            Are you sure that you want to delete this assignment requirement
            override for {deleteOverride.date}
          </p>
        ),
        buttons: {
          ok: {
            text: "Delete",
            variant: "danger",
            onClick() {
              api
                .deleteAdditionalAssignmentDailyOverride(deleteOverride.id!)
                .then((res) => {
                  mutate([api.getAdditionalAssignmentDailyOverrides, res.jobid])
                }, handleApiError)
            },
          },
        },
      })
    }
  }

  const columns: ColumnType<AdditionalAssignmentDailyOverride>[] = [
    {
      title: "Date",
      dataKey: "date",
    },
    {
      title: "Minimum",
      dataKey: "minimum",
    },
    {
      title: "Ideal",
      dataKey: "ideal",
    },
    {
      title: "Maximum",
      dataKey: "maximum",
    },
    {
      dataKey: "actions",
      width: 200,
      position: "right",
      render: (_: any, item: any) => (
        <>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => {
              const today = new Date()
              today.setHours(0, 0, 0, 0)

              if (parseDate(item.date) < today) {
                Dialog.warn({
                  title: "Notice",
                  message: (
                    <>
                      <p>
                        You cannot edit an assignment requirement override for a
                        past date.
                      </p>
                    </>
                  ),
                })
              } else {
                setDailyOverride(item)
              }
            }}
          >
            Edit
          </Button>{" "}
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleDailyOverrideDelete(item)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ]

  const initDailyOverride = () => ({
    jobid,
    date: "",
    minimum: 1,
    ideal: 1,
    maximum: 1,
  })

  return (
    <div className={css.dailyOverrides}>
      <div className={cx(css.dailyOverridesHeader, "d-flex mb-5")}>
        <h4>Daily Overrides</h4>
        <Button
          variant="outline-primary"
          onClick={() => setDailyOverride(initDailyOverride())}
        >
          Add a daily override
        </Button>
      </div>
      <DailyOverrideForm
        mutate={mutate}
        dailyOverride={dailyOverride}
        setDailyOverride={setDailyOverride}
        jobName={jobName}
      />
      <Table
        variant="frame"
        data={dailyOverrides}
        loading={!dailyOverrides}
        columns={columns}
      ></Table>
    </div>
  )
}
