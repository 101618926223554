import React from "react"
import Popover from "@app/components/Popover"

import css from "./AuditLog.module.scss"

type LogItem = {
  log_typeid: number
  log_timestamp: string
  username: string
  action: string
  details: string
  ext_details: string
}

type Props = {
  log_list: LogItem[]
}

export default (props: Props) => {
  let { log_list } = props

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Date Time</th>
          <th>User</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {log_list.map((logItem, index) => {
          let details
          let tips

          if (logItem.username === "API") {
            const detailsJson = JSON.parse(logItem.details)

            if (logItem.log_typeid === 14) {
              tips = (
                <>
                  <div>{logItem.action}</div>
                  <Popover
                    key={`pop-${index}`}
                    trigger="click"
                    title={logItem.action}
                    content={
                      <div className={css.details}>
                        <pre>
                          {JSON.stringify(detailsJson["data"], null, 2)}
                        </pre>
                      </div>
                    }
                  >
                    <span className={css.blue}>
                      {detailsJson["total_count"]}{" "}
                    </span>
                  </Popover>
                  total events | {detailsJson["success_count"]} events
                  successfully imported
                </>
              )
            } else {
              tips = detailsJson["data"]
            }

            details = (
              <>
                <div className={css.strong}>API Request</div>
                {tips}
              </>
            )
          } else {
            details = (
              <>
                <div
                  className={css.strong}
                  dangerouslySetInnerHTML={{ __html: logItem.action }}
                />
                <div dangerouslySetInnerHTML={{ __html: logItem.details }} />
                <div
                  dangerouslySetInnerHTML={{ __html: logItem.ext_details }}
                />
              </>
            )
          }

          return (
            <tr key={`tr-${index}`}>
              <td style={{ whiteSpace: "nowrap" }}>
                <div
                  dangerouslySetInnerHTML={{ __html: logItem.log_timestamp }}
                />
              </td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: logItem.username }} />
              </td>
              <td style={{ textAlign: "left" }} align={"left"}>
                {details}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
