import { createModel } from "@rematch/core"
import { RootModel } from "."
import { differenceInDays, addDays } from "date-fns"
import { parseDate } from "@app/utils"
import api from "@app/services/api"
import { postBatchUpdateBlocksInQueue } from "@app/services/blockSetQueue"

type BlockStateType = {
  id: string
  isBuffer: boolean
  blockSetId: string
  startDate: Date
  endDate: Date
  left: number
  width: number
  resizedLeft: number
  resizedWidth: number
  minWidth?: number
  maxWidth?: number
  minStartDate?: Date
  maxEndDate?: Date
  daysCount?: number
}

type StateType = {
  annualBlockSchedule:
    | (AnnualBlockScheduleType & {
        calendarStartDate: Date
        calendarEndDate: Date
      })
    | PlainObjectType
  blockSets: Array<BlockSetType & { version?: number }>
  computedBlocksData: {
    [blockSetId: string]: BlockStateType[]
  }
  blockConfig: {
    daySizePixels: number
    bufferDays: number
    height: number
    minDays: number
    bufferMinDays: number
  }
  annualBlockScheduleProviders: AnnualBlockScheduleProviderType[]
}

// Block helpers
const helpers = (state: StateType) => {
  const self = {
    findBlockWithContext(id: string, blockSetId: string) {
      const { computedBlocksData } = state
      const blocks = computedBlocksData[blockSetId]
      const blockIndex = blocks.findIndex((block) => block.id === id)
      const block = blocks[blockIndex]

      return {
        block,
        blocks,
        blockIndex,
        leftBlock: blocks[blockIndex - 1],
        rightBlock: blocks[blockIndex + 1],
      }
    },
    resizeSingleBlock(
      block: BlockStateType,
      direction: string,
      distance: number
    ) {
      if (!block) {
        return
      }

      const {
        annualBlockSchedule: { calendarStartDate },
        blockConfig: { daySizePixels },
      } = state

      if (direction === "left") {
        block.resizedLeft = block.left - distance
        block.resizedWidth = block.width + distance
        block.startDate = addDays(
          calendarStartDate,
          Math.round(block.resizedLeft / daySizePixels)
        )
      }

      if (direction === "right") {
        block.resizedWidth = block.width + distance
        block.endDate = addDays(
          calendarStartDate,
          Math.round((block.resizedLeft + block.resizedWidth) / daySizePixels)
        )
      }

      block.daysCount = differenceInDays(block.endDate, block.startDate) + 1
    },
    calculateBlockPosition(
      block: Optional<
        BlockStateType,
        "left" | "width" | "resizedWidth" | "resizedLeft"
      >
    ) {
      const { startDate, endDate } = block
      const {
        annualBlockSchedule: { calendarStartDate },
        blockConfig: { daySizePixels },
      } = state

      const diffDays = differenceInDays(endDate, startDate)
      const diffDaysWithCalendar = differenceInDays(
        startDate,
        calendarStartDate
      )
      const left = diffDaysWithCalendar * daySizePixels
      const width = diffDays * daySizePixels

      block.left = left
      block.width = width
      block.resizedLeft = left
      block.resizedWidth = width
      block.daysCount = diffDays + 1

      return block
    },
    getDaysFromSplittingBlock(block: BlockStateType) {
      const totalDays = differenceInDays(block.endDate, block.startDate) + 1
      const halfPartDays = Math.floor((totalDays + 1) / 2)
      const restPartDays = totalDays - halfPartDays

      return [halfPartDays, restPartDays]
    },
    computeBlockSetBlocks(blockSet: BlockSetType) {
      const {
        annualBlockSchedule: { calendarStartDate, calendarEndDate },
      } = state

      const blocks = blockSet.block_set_blocks
      const firstBlock = blocks[0]
      const lastBlock = blocks[blocks.length - 1]
      const blocksData: BlockStateType[] = []
      const pushBlockData = (block: any) => {
        self.calculateBlockPosition(block)
        blocksData.push(block)
      }

      // Left buffer block
      pushBlockData({
        id: "bufferLeft",
        isBuffer: true,
        blockSetId: blockSet.id,
        startDate: calendarStartDate,
        endDate: addDays(parseDate(firstBlock.start_date), -1),
      })

      // Normal block
      blocks.forEach((block) => {
        pushBlockData({
          id: block.id,
          isBuffer: false,
          blockSetId: blockSet.id,
          startDate: parseDate(block.start_date),
          endDate: parseDate(block.end_date),
        })
      })

      // Right buffer block
      pushBlockData({
        id: "bufferRight",
        isBuffer: true,
        blockSetId: blockSet.id,
        startDate: addDays(parseDate(lastBlock.end_date), 1),
        endDate: calendarEndDate,
      })

      return blocksData
    },
  }
  return self
}

export default createModel<RootModel>()({
  state: {
    annualBlockSchedule: {},
    blockSets: [],
    computedBlocksData: {},
    blockConfig: {
      daySizePixels: 6,
      bufferDays: 8, // actually 7 days in display
      height: 92,
      minDays: 7,
      bufferMinDays: 1,
    },
    annualBlockScheduleProviders: [],
  } as StateType,
  reducers: {
    update(state, payload: Partial<StateType>) {
      return { ...state, ...payload }
    },
    updateBlockSet(
      state,
      { blockSet, version }: { blockSet: BlockSetType; version?: number }
    ) {
      const { computeBlockSetBlocks } = helpers(state)
      const { blockSets, computedBlocksData } = state
      const { id: blockSetId, block_set_blocks: blocks } = blockSet
      const blockSetIndex = blockSets.findIndex(({ id }) => id === blockSetId)

      if (blockSetIndex >= 0) {
        const blockSet = blockSets[blockSetIndex]

        if (version && blockSet.version && blockSet.version > version) {
          return state
        }

        blockSet.block_set_blocks = blocks
        computedBlocksData[blockSetId] = computeBlockSetBlocks(blockSet)
      }

      return state
    },
    reviseBlockPosition(
      state,
      { blockSetId, blockIds }: { blockSetId: string; blockIds: string[] }
    ) {
      const { calculateBlockPosition } = helpers(state)
      const { computedBlocksData } = state
      const blockSet = computedBlocksData[blockSetId]

      if (blockSet) {
        blockIds.forEach((blockId) => {
          const block = blockSet.find(({ id }) => id === blockId)
          block && calculateBlockPosition(block)
        })
      }

      return state
    },
    setBlockWidthLimitation(
      state,
      {
        blockSetId,
        blockId,
        direction,
      }: {
        blockSetId: string
        blockId: string
        direction: string
      }
    ) {
      const { daySizePixels, minDays, bufferMinDays } = state.blockConfig
      const { findBlockWithContext } = helpers(state)
      const { leftBlock, block, rightBlock } = findBlockWithContext(
        blockId,
        blockSetId
      )

      block.minWidth =
        (block.isBuffer ? bufferMinDays - 0.75 : minDays - 1) * daySizePixels

      if (direction === "left") {
        const leftBlockMinEndDate = addDays(
          leftBlock.startDate,
          leftBlock.isBuffer ? bufferMinDays : minDays
        )
        const diffDays = differenceInDays(block.endDate, leftBlockMinEndDate)
        block.maxWidth = diffDays * daySizePixels
      }

      if (direction === "right") {
        const rightBlockMinEndDate = addDays(
          rightBlock.endDate,
          -(rightBlock.isBuffer ? bufferMinDays : minDays)
        )
        const diffDays = differenceInDays(rightBlockMinEndDate, block.startDate)
        block.maxWidth = diffDays * daySizePixels
      }

      return state
    },
    setBlockDateLimitation(
      state,
      {
        blockSetId,
        blockId,
        direction,
      }: {
        blockSetId: string
        blockId: string
        direction: string
      }
    ) {
      const { minDays, bufferMinDays } = state.blockConfig
      const { findBlockWithContext } = helpers(state)
      const { leftBlock, block, rightBlock } = findBlockWithContext(
        blockId,
        blockSetId
      )

      const getMinDays = (block: BlockStateType) =>
        block.isBuffer ? bufferMinDays : minDays

      if (direction === "left") {
        block.minStartDate = addDays(leftBlock.startDate, getMinDays(leftBlock))
        block.maxEndDate = addDays(block.endDate, -(minDays - 1))
      }

      if (direction === "right") {
        block.minStartDate = addDays(block.startDate, minDays - 1)
        block.maxEndDate = addDays(rightBlock.endDate, -getMinDays(rightBlock))
      }

      return state
    },
    adjustBlockPosition(
      state,
      {
        blockSetId,
        blockId,
        direction,
        distance,
      }: {
        blockSetId: string
        blockId: string
        direction: string
        distance: number
      }
    ) {
      const { findBlockWithContext, resizeSingleBlock } = helpers(state)
      const { leftBlock, block, rightBlock } = findBlockWithContext(
        blockId,
        blockSetId
      )

      if (!block) {
        return state
      }

      resizeSingleBlock(block, direction, distance)

      if (direction === "left") {
        resizeSingleBlock(leftBlock, "right", -distance)
      }

      if (direction === "right") {
        resizeSingleBlock(rightBlock, "left", -distance)
      }

      return state
    },
    computeBlocksData(state, payload: BlockSetType[]) {
      const { computeBlockSetBlocks } = helpers(state)
      const computedBlocksData: StateType["computedBlocksData"] = {}

      payload.forEach((blockSet) => {
        computedBlocksData[blockSet.id] = computeBlockSetBlocks(blockSet)
      })

      return { ...state, computedBlocksData }
    },
    deleteBlockSet(state, { blockSetId }: { blockSetId: string }) {
      const blockSetIndex = state.blockSets.findIndex(
        (blockSet) => blockSet.id === blockSetId
      )

      if (blockSetIndex >= 0) {
        // remove blockSet
        state.blockSets.splice(blockSetIndex, 1)
        delete state.computedBlocksData[blockSetId]
      }

      return state
    },
    updateBlockSetVersion(
      state,
      { blockSetId, version }: { blockSetId: string; version: number }
    ) {
      const blockSet = state.blockSets.find(
        (blockSet) => blockSet.id === blockSetId
      )

      if (blockSet) {
        // `Infinity` means blockSet is under draging
        blockSet.version = version
      }

      return state
    },
  },
  effects: (dispatch) => ({
    async getAnnualBlockSchedule(payload: { id: string }, state) {
      const annualBlockSchedule: any = await api.getAnnualBlockSchedule(
        payload.id
      )

      const { bufferDays } = state.blockSets.blockConfig
      const calendarStartDate = addDays(
        parseDate(annualBlockSchedule.start_date),
        -bufferDays
      )
      const calendarEndDate = addDays(
        parseDate(annualBlockSchedule.end_date),
        +bufferDays
      )

      dispatch.blockSets.update({
        annualBlockSchedule: {
          ...annualBlockSchedule,
          calendarStartDate,
          calendarEndDate,
        },
      })
    },
    async getBlockSets(payload: { id: string }, state) {
      const { blockSets: oldBlockSets } = state.blockSets
      if (oldBlockSets?.length) {
        const annualBlockScheduleId = oldBlockSets[0].annual_block_schedule_id
        // clear expired blockSets
        if (annualBlockScheduleId !== payload.id) {
          dispatch.blockSets.update({ blockSets: [] })
        }
      }

      // get new blockSets
      const blockSets: any = await api.getBlockSets(payload.id)

      dispatch.blockSets.update({ blockSets })
      dispatch.blockSets.computeBlocksData(blockSets)
    },
    async addBlock(
      {
        blockSetId,
        blockId,
        direction,
      }: { blockSetId: string; blockId: string; direction: string },
      state
    ) {
      const { findBlockWithContext, getDaysFromSplittingBlock } = helpers(
        state.blockSets
      )
      const { block } = findBlockWithContext(blockId, blockSetId)

      if (!block) {
        return
      }

      const [halfPartDays, restPartDays] = getDaysFromSplittingBlock(block)
      let params:
        | Array<BlocksBatchUpdateAPICreateType | BlocksBatchUpdateAPIUpdateType>
        | undefined

      if (direction === "left") {
        const newBlockStartDate = block.startDate
        const newBlockEndDate = addDays(block.startDate, halfPartDays - 1)
        const blockStartDate = addDays(newBlockEndDate, 1)

        params = [
          {
            action: "update",
            data: { id: block.id, start_date: blockStartDate },
          },
          {
            action: "create",
            data: {
              position: { id: block.id, direction },
              start_date: newBlockStartDate,
              end_date: newBlockEndDate,
            },
          },
        ]
      }

      if (direction === "right") {
        const newBlockEndDate = block.endDate
        const newBlockStartDate = addDays(block.endDate, -restPartDays + 1)
        const blockEndDate = addDays(newBlockStartDate, -1)

        params = [
          {
            action: "update",
            data: { id: block.id, end_date: blockEndDate },
          },
          {
            action: "create",
            data: {
              position: { id: block.id, direction },
              start_date: newBlockStartDate,
              end_date: newBlockEndDate,
            },
          },
        ]
      }

      if (params) {
        const blockSet = await api.postBatchUpdateBlocks(blockSetId, params)
        dispatch.blockSets.updateBlockSet({ blockSet })
      }
    },
    async deleteBlock(
      { blockSetId, blockId }: { blockSetId: string; blockId: string },
      state
    ) {
      const { findBlockWithContext, getDaysFromSplittingBlock } = helpers(
        state.blockSets
      )
      const { leftBlock, block, rightBlock } = findBlockWithContext(
        blockId,
        blockSetId
      )

      // Return if only 1 normal block
      if (!block || (leftBlock.isBuffer && rightBlock.isBuffer)) {
        return
      }

      let params: Array<
        BlocksBatchUpdateAPIUpdateType | BlocksBatchUpdateAPIDeleteType
      > = [{ action: "delete", data: { id: block.id } }]

      if (leftBlock.isBuffer) {
        params.push({
          action: "update",
          data: { id: rightBlock.id, start_date: block.startDate },
        })
      } else if (rightBlock.isBuffer) {
        params.push({
          action: "update",
          data: { id: leftBlock.id, end_date: block.endDate },
        })
      } else {
        const [halfPartDays, restPartDays] = getDaysFromSplittingBlock(block)
        const leftBlockEndDate = addDays(leftBlock.endDate, halfPartDays)
        const rightBlockStartDate = addDays(rightBlock.startDate, -restPartDays)

        params.push(
          {
            action: "update",
            data: { id: leftBlock.id, end_date: leftBlockEndDate },
          },
          {
            action: "update",
            data: { id: rightBlock.id, start_date: rightBlockStartDate },
          }
        )
      }

      const blockSet = await api.postBatchUpdateBlocks(blockSetId, params)
      dispatch.blockSets.updateBlockSet({ blockSet })
    },
    async updateBlockDate(
      {
        blockSetId,
        blockId,
        fields,
      }: {
        blockSetId: string
        blockId: string
        fields: { start_date?: string; end_date?: string }
      },
      state
    ) {
      const { findBlockWithContext } = helpers(state.blockSets)
      const { leftBlock, block, rightBlock } = findBlockWithContext(
        blockId,
        blockSetId
      )

      let params: BlocksBatchUpdateAPIUpdateType[] | undefined

      if (fields.start_date) {
        const startDate = parseDate(fields.start_date)

        params = [
          {
            action: "update",
            data: { id: block.id, start_date: startDate },
          },
        ]

        if (!leftBlock.isBuffer) {
          const leftBlockEndDate = addDays(startDate, -1)
          params.push({
            action: "update",
            data: { id: leftBlock.id, end_date: leftBlockEndDate },
          })
        }
      }

      if (fields.end_date) {
        const endDate = parseDate(fields.end_date)

        params = [
          {
            action: "update",
            data: { id: block.id, end_date: endDate },
          },
        ]

        if (!rightBlock.isBuffer) {
          const rightBlockStartDate = addDays(endDate, 1)
          params.push({
            action: "update",
            data: { id: rightBlock.id, start_date: rightBlockStartDate },
          })
        }
      }

      if (params) {
        const blockSet = await api.postBatchUpdateBlocks(blockSetId, params)
        dispatch.blockSets.updateBlockSet({ blockSet })
      }
    },
    async comfirmBlockPosition(
      {
        blockSetId,
        blockId,
        direction,
        version,
      }: {
        blockSetId: string
        blockId: string
        direction: string
        version: number
      },
      state
    ) {
      const { findBlockWithContext } = helpers(state.blockSets)
      const { leftBlock, block, rightBlock } = findBlockWithContext(
        blockId,
        blockSetId
      )

      if (!block) {
        return
      }

      const blockIds: string[] = [block.id]
      let params: BlocksBatchUpdateAPIUpdateType[] = []

      if (direction === "left") {
        blockIds.push(leftBlock.id)

        if (!block.isBuffer) {
          params.push({
            action: "update",
            data: { id: block.id, start_date: block.startDate },
          })
        }

        if (!leftBlock.isBuffer) {
          params.push({
            action: "update",
            data: { id: leftBlock.id, end_date: leftBlock.endDate },
          })
        }
      }

      if (direction === "right" && rightBlock) {
        blockIds.push(rightBlock.id)

        if (!block.isBuffer) {
          params.push({
            action: "update",
            data: { id: block.id, end_date: block.endDate },
          })
        }

        if (!rightBlock.isBuffer) {
          params.push({
            action: "update",
            data: { id: rightBlock.id, start_date: rightBlock.startDate },
          })
        }
      }

      dispatch.blockSets.reviseBlockPosition({ blockSetId, blockIds })

      if (params.length) {
        const blockSet = await postBatchUpdateBlocksInQueue(blockSetId, params)
        dispatch.blockSets.updateBlockSet({ blockSet, version })
      }
    },
    async getAnnualBlockScheduleProviders(payload: { id: string }, state) {
      const providers = await api.getAnnualBlockScheduleProviders(payload.id)
      dispatch.blockSets.update({ annualBlockScheduleProviders: providers })
    },
  }),
})
