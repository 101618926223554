import React, { Fragment, useEffect, useState } from "react"
import { useDispatch } from "@app/models"
import Menu from "@app/containers/layouts/Menu"
import { Container } from "@app/components/Layout"
import { useAccessPermission, useIsAuthenticated } from "@app/utils/hooks"
import css from "./GroupManagement.module.scss"
import GroupManagementTable from "./GroupManagementTable/GroupManagementTable"
import Loader from "@app/components/Loader"

const GroupManagement = () => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const isAuthenticated = useIsAuthenticated()
  const { canAccess } = useAccessPermission({ scope: "groupManagement" })

  useEffect(() => {
    ;(async () => {
      await dispatch.users.getCurrentUserInfo()
    })()
  }, [])

  useEffect(() => {
    if (!isAuthenticated) return

    if (isAuthenticated) {
      if (!canAccess("/enterprise_groups")) {
        window.location.href = "/menu.cgi"
      } else {
        setIsLoading(false)
      }
    }
  }, [isAuthenticated, canAccess])

  return (
    <Fragment>
      <Menu />
      <Container size="fluid" className={css.groupManagementContainer}>
        {isLoading ? (
          <div className={css.loadingContainer}>
            <Loader animation="dots" />
          </div>
        ) : (
          <div>
            <GroupManagementTable />
          </div>
        )}
      </Container>
    </Fragment>
  )
}

export default GroupManagement
