import * as React from 'react';
import { LoginStepEnum } from '../../utils/loginStepEnum';

export interface ILoginStep2Props {
    onInputChange: (x: React.FormEvent<HTMLInputElement>) => void,
    loginStep: LoginStepEnum,
    passwd: string,
    apikey: string,
    secret: string
}

export default class LoginStep2 extends React.Component<ILoginStep2Props> {
    private onInputChange = this.props.onInputChange;
    private _input?: HTMLInputElement | null;


    public componentDidUpdate() {
        if (this._input) {
            this._input?.focus()
        }
    }

    public render() {
        return (
            <div>
                {
                    this.props.loginStep === LoginStepEnum.SSO_LOGIN_BY_PSWD ? (
                        <div className="row padding-top">
                            <div className="col-sm-6 col-sm-offset-3 col-xs-12 user-input">
                                <input ref={input => (this._input = input)} type="password" id="passwd" name="passwd" placeholder="Enter your password" value={this.props.passwd} onChange={(e) => this.onInputChange(e)} />
                                <input id="apikey" name="apikey" type="hidden" value={this.props.apikey} />
                                <input id="secret" name="secret" type="hidden" value={this.props.secret} />
                            </div>
                        </div>
                    ) : null
                }

                {
                    this.props.loginStep === LoginStepEnum.SCHEDULE_LOGIN_BY_PSWD ? (
                        <div className="row padding-top">
                            <div className="col-sm-6 col-sm-offset-3 col-xs-12 user-input">
                                <input ref={input => (this._input = input)} type="password" id="passwd" name="passwd" placeholder="Enter your password" value={this.props.passwd} onChange={(e) => this.onInputChange(e)} />
                            </div>
                        </div>
                    ) : null
                }
            </div>
        );
    }
}
