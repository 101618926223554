import axios from "axios"
import { isIE } from "@app/utils"

const ax = axios.create({
  withCredentials: true,
  headers: {
    "X-Client": "tiger-schedule.web",
    "Access-Control-Allow-Origin": "*",
    ...(isIE && { Pragma: "no-cache" }),
  },
})

ax.interceptors.response.use(
  (response) => {
    const res = response.data

    if (typeof res !== "object" || !("status" in res)) return res
    if (res.status === "success") return res.data
    if (res.status === "fail")
      return Promise.reject({ fail: true, data: res.data })
    if (res.status === "error")
      return Promise.reject({ error: true, message: res.message })
  },
  (error) => {
    // if 401, redirect to login
    if (error?.response?.status === 401) {
      window.location.href = "/login.cgi"
    }
    return Promise.reject({ error: true, data: error })
  }
)

const checkApiV3Local = (url: string) => {
  if (url.match(/\/api\/v3/) && window.location.origin.match(/local/)) {
    return true
  }
  return false
}

const fetch = <T = any>(url: string, data?: any, options?: any) => {
  if (checkApiV3Local(url)) {
    url = `http://localhost:3000${url}`
  }

  const defaultOptions = { url, data, method: "get" }
  options = Object.assign({}, defaultOptions, options)

  if (checkApiV3Local(url)) {
    options.headers = {
      Authorization: document.cookie.match(/(?<=DEVELOPMENT_JWT=)[^;]+/),
    }
    options.withCredentials = true
  }

  if (options.method.toLowerCase() === "get" && options.data) {
    options.params = options.data
  }

  return ax.request<T, T>(options)
}

export { fetch }
export default ax
