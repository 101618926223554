import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "@app/models"
import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card"
import Dropdown from "@app/components/Dropdown"
import { H2, H4, Text } from "@app/components/Typography"
import Icon from "@app/components/Icon"
import Button from "@app/components/Button"
import Loader from "@app/components/Loader"
import Schedulers from "../AnnualSchedulesPage/AnnualSchedule/components/Schedules/components/Schedulers/Schedulers"
import ProvidersFilter from "../AnnualSchedulesPage/AnnualSchedule/components/AnnualScheduleHeader/components/ProvidersFilter"
import HighlightFilter from "../AnnualSchedulesPage/AnnualSchedule/components/AnnualScheduleHeader/components/HighlightFilter"
import api, { useRequest } from "@app/services/api"
import cx from "classnames"
import css from "./Whiteboard.module.scss"

export default () => {
  const { id } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const annualBlockSchedule = useSelector((state) => {
    const obj = state.blockSets.annualBlockSchedule
    return obj.id === id ? obj : undefined
  })

  const { data: annualBlockSchedules = [] } = useRequest<
    AnnualBlockScheduleType[]
  >([api.getAnnualBlockSchedulesOnMenu, id])

  useEffect(() => {
    id && dispatch.blockSets.getAnnualBlockSchedule({ id })
  }, [id])

  const handleDropdownItemClick = (id: string) => {
    navigate(`/annual_schedules/${id}/whiteboard`)
  }

  const dropdownOptions = annualBlockSchedules.map((x) => ({
    id: x.id,
    name: `${x.display_name} ${x.name}`,
  }))

  return (
    <BaseLayout containerSize="fluid" className="mt-3">
      <H2 className="pl-5 mb-4 d-flex align-items-center">
        Rotations Whiteboard
        {!annualBlockSchedule && (
          <Text className="ml-3">
            <Loader />
          </Text>
        )}
      </H2>
      {annualBlockSchedule && (
        <Card
          title={
            <Dropdown
              menuProps={{
                options: dropdownOptions,
                onItemClick: handleDropdownItemClick,
              }}
            >
              <div
                className={cx("d-flex align-items-center", css.dropdownTrigger)}
              >
                <Button className="mr-3" shape="circle" variant="info">
                  <Icon name="arrow-left-right" />
                </Button>
                <H4 bold>
                  {`${annualBlockSchedule.display_name} ${annualBlockSchedule.name}`}
                </H4>
              </div>
            </Dropdown>
          }
          action={
            <>
              <div className="d-flex align-items-center">
                <Text className="mr-3">Filter by</Text>
                <ProvidersFilter
                  annualBlockScheduleId={annualBlockSchedule.id}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <Text>Highlight</Text>
                <div className={cx("d-flex ml-3", css.filtersContainer)}>
                  <HighlightFilter
                    annualBlockScheduleId={annualBlockSchedule.id}
                  />
                </div>
              </div>
            </>
          }
        >
          <Schedulers
            calendarHeightExpandable
            id={annualBlockSchedule.id}
            className={css.disableMouseEvent}
          />
        </Card>
      )}
    </BaseLayout>
  )
}
