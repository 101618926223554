import React, { useState } from "react"
import { H4, Text } from "@app/components/Typography"
import Modal from "@app/components/Modal"
import Table from "@app/components/Table"
import Avatar from "@app/components/Avatar"
import Tag from "@app/components/Tag"
import ProviderRequestSummary from "./ProviderRequestSummary"
import type { TallyDataType } from "@app/services/tallyCalculation"
import type { RequestType } from "./type"
import { summate } from "@app/utils"
import css from "./ProviderInfo.module.scss"
import cx from "classnames"

type Props = {
  staffLevel: StaffLevelType
  provider: AnnualBlockScheduleProviderType["provider"]
  tallyData: TallyDataType
  requests: RequestType[]
  blockHeight?: number
}

export default (props: Props) => {
  const { provider, staffLevel, tallyData, requests, blockHeight } = props
  const [tallyPopupShow, setTallyPopupShow] = useState(false)

  const { tallyBasedSummaries } = tallyData
  const totalyTallyTarget = summate(tallyBasedSummaries, {
    key: "tallyTarget",
    precision: 2,
  })
  const totalyTallyCredit = summate(tallyBasedSummaries, {
    key: "validTallyCredit",
    precision: 2,
  })

  return (
    <>
      <Modal
        title={`${provider.fullname} ${staffLevel.name}`}
        size="lg"
        buttons={[{ text: "OK", variant: "primary" }]}
        show={tallyPopupShow}
        onHide={setTallyPopupShow}
      >
        <div>
          <Text bold variant="black" className="mb-4">
            Tally Target Summary
          </Text>
          <Table
            className={cx("mb-4", css.providerInfoTable)}
            variant="frame"
            striped={false}
            rowKey={(item) => item.tally.id}
            columns={[
              {
                title: "Tally Name",
                dataKey: "tally",
                className: css.tallyName,
                render: (tally) => (
                  <Text ellipsis bold variant="black" title={tally.name}>
                    {tally.name}
                  </Text>
                ),
              },
              {
                title: "Tally Target",
                dataKey: "tallyTargetText",
                className: css.tallyTarget,
              },
              {
                title: "Tally Credit",
                dataKey: "tallyCreditText",
                className: css.tallyCredit,
              },
              {
                title: "Rotations",
                dataKey: "annualRotations",
                className: css.rotationName,
                render: (annualRotations: AnnualBlockScheduleRotationType[]) =>
                  annualRotations.map(
                    ({ block_schedule_rotation: rotation }) => (
                      <Text
                        ellipsis
                        title={rotation.name}
                        prefixDotColor={rotation.color}
                        key={rotation.id}
                      >
                        {rotation.name}
                      </Text>
                    )
                  ),
              },
            ]}
            data={tallyBasedSummaries}
          />
        </div>
        <ProviderRequestSummary requests={requests} className="mb-5" />
      </Modal>
      <div
        className={cx("d-flex align-items-start", css.blockSet)}
        style={{ height: blockHeight }}
      >
        <Avatar src={provider.avatar} />
        <div className={cx("flex-fill ml-3", css.providerNameAndstaffLevel)}>
          <H4
            bold
            ellipsis
            variant="black"
            className={css.providerName}
            onClick={() => setTallyPopupShow(true)}
          >
            {provider.display_name}
          </H4>
          <Text>{staffLevel.name}</Text>
        </div>
        <Tag
          className={css.totalyTallyTarget}
          variant={
            totalyTallyTarget !== 0 && totalyTallyCredit == totalyTallyTarget
              ? "fg-success"
              : "fg-danger"
          }
        >
          {totalyTallyCredit}/{totalyTallyTarget}
        </Tag>
      </div>
    </>
  )
}
