import React, { useState } from "react"
import Modal from "@app/components/Modal"
import { FormItem, Input, Checkbox, useForm } from "@app/components/Form"
// import { Text } from "@app/components/Typography"
import Icon from "@app/components/Icon"
import Tooltip from "@app/components/Tooltip"
import api, { mutate } from "@app/services/api"
import { handleApiError, mutateCollection } from "@app/utils"
import { DefaultTigerconnectSettingId } from "@app/utils/constants"
import css from "./CreateOrUpdateTigerconnectSetting.module.scss"
import CreateTigerconnectIntegrationID from "./CreateTigerconnectIntegrationID"

type Props = {
  show: boolean
  onHide: Function
  tigerconnectSetting?: TigerconnectSettingType
}

type FormFieldsType = {
  label: string
  two_way_sync: boolean
  integrationid: string
  tigerconnect_key: string
  tigerconnect_secret: string
  organization_token: string
  created_at: string
  updated_at: string
}

export default (props: Props) => {
  const { show, onHide, tigerconnectSetting } = props
  const [integrationShow, setIntegrationShow] = useState(false)
  const isDefaultSetting =
    tigerconnectSetting?.id === DefaultTigerconnectSettingId
  //const [generateIntegration, setGenerateIntegration] = useState(false)

  const {
    control,
    getValues,
    setValue,
    handleModalSubmit,
    reset: resetForm,
  } = useForm<FormFieldsType>({
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          label: yup.string().max(80).required().label("Label"),
          tigerconnect_key: yup.string().required().label("API Key"),
          tigerconnect_secret: yup.string().required().label("API Secret"),
          ...(!isDefaultSetting && {
            integrationid: yup.string().required().label("Integration ID"),
            organization_token: yup.string().required().label("Org Token"),
          }),
        })
      ),
  })

  const onModalClose = (status: boolean) => {
    onHide(status)
    resetForm()
  }

  const onSubmit = (fields: FormFieldsType, closeModal: Function) => {
    fields = {
      ...fields,
      tigerconnect_key: fields.tigerconnect_key.trim(),
      tigerconnect_secret: fields.tigerconnect_secret.trim(),
    }

    const requestHandler = tigerconnectSetting
      ? api.updateTigerconnectSetting(tigerconnectSetting.id, fields)
      : api.createTigerconnectSetting(fields)

    return requestHandler.then((newSetting: TigerconnectSettingType) => {
      // Update tigerconnect settings
      mutate(
        [api.getTigerconnectSettings],
        (settings?: TigerconnectSettingType[]) =>
          mutateCollection(settings || [], newSetting)
      )

      closeModal()
    }, handleApiError)
  }

  return (
    <Modal
      title={`${tigerconnectSetting ? "Edit" : "Add"} Organization`}
      show={show}
      onHide={onModalClose}
      size="lg"
      buttons={[
        { text: "Cancel", variant: "outline-primary" },
        {
          control,
          text: "Save",
          onClick: handleModalSubmit(onSubmit),
        },
      ]}
    >
      <div className="mx-3 pb-3">
        <FormItem
          required
          label="Label"
          control={control}
          name="label"
          defaultValue={tigerconnectSetting?.label}
        >
          <Input />
        </FormItem>
        <FormItem
          required
          label="API Key"
          control={control}
          name="tigerconnect_key"
          defaultValue={tigerconnectSetting?.tigerconnect_key}
        >
          <Input />
        </FormItem>
        <FormItem
          required
          label="API Secret"
          control={control}
          name="tigerconnect_secret"
          defaultValue={tigerconnectSetting?.tigerconnect_secret}
        >
          <Input />
        </FormItem>

        <FormItem
          required={!isDefaultSetting}
          label="Roles Sync Integration ID"
          control={control}
          name="integrationid"
          defaultValue={tigerconnectSetting?.integrationid}
        >
          <Input />
        </FormItem>

        <div
          className={
            (tigerconnectSetting?.integrationid ? css.linkHidden + " " : "") +
            "row padding-bottom"
          }
        >
          <div className="col-md-3"></div>
          <div className="col-md-9">
            <a
              href="#"
              onClick={() => setIntegrationShow(true)}
              className={
                tigerconnectSetting?.integrationid ? css.linkHidden : undefined
              }
            >
              Create Integration Record
            </a>
          </div>
        </div>

        <FormItem
          label="2-Way Sync Enabled"
          control={control}
          name="two_way_sync"
          defaultValue={tigerconnectSetting?.two_way_sync}
        >
          <Checkbox />
        </FormItem>
        <FormItem
          required={!isDefaultSetting}
          label={
            isDefaultSetting ? (
              <div className="d-inline-flex align-items-center">
                <span>Org Token Override</span>
                <Tooltip title="Enter a TC org token ONLY if you need to override the default OrgId that is set in the Clinic (System) record.">
                  <Icon name="question-circle-fill" className="ml-2" />
                </Tooltip>
              </div>
            ) : (
              <span>Org Token</span>
            )
          }
          control={control}
          name="organization_token"
          defaultValue={tigerconnectSetting?.organization_token}
        >
          <Input />
        </FormItem>
      </div>
      <>
        <CreateTigerconnectIntegrationID
          show={integrationShow}
          onHide={setIntegrationShow}
          tigerconnectSetting={tigerconnectSetting}
          formValues={getValues()}
          setFormValue={setValue}
        />
      </>
    </Modal>
  )
}
