import React, { useState } from "react"
import { KeyboardTimePicker } from "@material-ui/pickers"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import { makeStyles } from "@material-ui/core"
import { parseDate, formatDate } from "@app/utils"
import { ISplitShiftItem } from "../../data"
import { ErrorMessage } from "@app/components/Form"

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      width: 110,
      height: 34,
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#fff",
      border: "1px solid #ced4da",
      paddingRight: 0,
    },
    "&:focus": {
      borderColor: "#ced4da",
    },
    "& .MuiIconButton-root": {
      padding: "6px",
    },
    "& .MuiOutlinedInput-input": {
      height: "auto !important",
      border: 0,
      fontSize: 14,
      padding: "10px 0 10px 12px",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      width: 82,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: "0 !important",
    },
    "& .MuiIconButton-label": {
      justifyContent: "center",
    },
  },
})

type Props = {
  name: "starttime" | "endtime"
  rowData: ISplitShiftItem | null
  handleOnChange: (propname: any, event: any) => any
  disabled?: boolean
}

export default (props: Props) => {
  const { name, rowData, handleOnChange, disabled } = props
  const inputValue = rowData?.[name]
  const placeholder = name === "starttime" ? "start" : "end"
  const classes = useStyles()

  const [isDateValid, setDateValid] = useState(true)

  const onChange = (val: any) => {
    if (val instanceof Date && isNaN(+val)) {
      setDateValid(false)
      handleOnChange(name, undefined)
    } else {
      setDateValid(true)
      handleOnChange(name, val && formatDate(val, (f) => f.timeOnly))
    }
  }

  return (
    <ErrorMessage
      className="bootstrap4"
      message={isDateValid ? undefined : "Invalid Date"}
    >
      <KeyboardTimePicker
        id={`${name}-picker`}
        onChange={onChange}
        value={
          inputValue
            ? formatDate(
                parseDate(inputValue, (f) => f.timeOnly),
                "yyyy-MM-dd HH:mm:ss"
              )
            : null
        }
        inputVariant="outlined"
        variant="inline"
        mask="__:__ _M"
        className={classes.root}
        placeholder={placeholder}
        disabled={disabled}
        keyboardIcon={<AccessTimeIcon />}
      />
    </ErrorMessage>
  )
}
