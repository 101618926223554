import React, { useReducer } from "react"
import { Form, FormGroup, Col, Button, Alert } from "react-bootstrap"
import { Checkbox } from "@app/components/Form"
import UserLookupEdit from "./UserLookupEdit"
import AccountMergeModal from "./AccountMergeModal"
import Loader from "@app/components/Loader"
import api from "@app/services/api"
import css from "./UserLookup.module.scss"

export type UserRoleType = {
  groupid: number
  roleid: number
  role_name: string
  userid: number
  group_name: string
  clinic_name: string
  clinicid: number
}

export interface AdminUserLookupUser {
  userid: number | string
  username: string
  tigerconnect: string
  firstname: string
  lastname: string
  email: string
  tigerconnect_token: string
  tigerschedule_sso: number
  user_roles: UserRoleType[]
}

const UserLookup: React.FC<any> = (props): JSX.Element => {
  const initialState = {
    searchInput: "",
    userData: null,
    selectedUserId: null,
    showMinCharactersAlert: false,
    showLoader: false,
    showAccountMergeModal: false,
    accountMerges: [],
  }

  const reducer = (state: any, action: any) => {
    const accountMergeUsers = [...state.accountMerges]
    switch (action.type) {
      case "setSearchInput":
        return { ...state, searchInput: action.payload }
      case "setUserData":
        return { ...state, userData: action.payload }
      case "setSelectedUserId":
        return { ...state, selectedUserId: action.payload }
      case "toggleShowMinCharactersAlert":
        return {
          ...state,
          showMinCharactersAlert:
            action.payload || !state.showMinCharactersAlert,
        }
      case "toggleAccountMergeModal":
        return { ...state, showAccountMergeModal: !state.showAccountMergeModal }
      case "toggleShowLoader":
        return { ...state, showLoader: !state.showLoader }
      case "addAccountMergeUsers":
        accountMergeUsers.splice(
          accountMergeUsers.findIndex(
            (user) => user.userid === action.payload.userid
          ),
          0,
          action.payload
        )
        return { ...state, accountMerges: accountMergeUsers }
      case "removeAccountMergeUsers":
        accountMergeUsers.splice(
          accountMergeUsers.findIndex(
            (user) => user.userid === action.payload.userid
          ),
          1
        )
        return { ...state, accountMerges: accountMergeUsers }
      case "clearAccountMergeUsers":
        return { ...state, accountMerges: [] }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const searchUser = (): void => {
    if (state.searchInput.length <= 3) {
      dispatch({ type: "toggleShowMinCharactersAlert", payload: true })
    } else {
      dispatch({ type: "toggleShowLoader" })
      api
        .AdminUserLookupSearchUsers(state.searchInput)
        .then((response: any) => {
          dispatch({ type: "setUserData", payload: response })
          dispatch({ type: "toggleShowLoader" })
        })
    }
  }

  const displayEditUserPage = (userid: number | string): void => {
    dispatch({ type: "setSelectedUserId", payload: userid })
  }

  if (!state.selectedUserId) {
    return (
      <>
        <FormGroup>
          <Form>
            <Col>Search User</Col>
            <Col id={css.searchWrapper}>
              <input
                id={css.searchBar}
                type="text"
                placeholder="Search by username, email, first name, last name, or TC token"
                value={state.searchInput}
                onChange={(e) =>
                  dispatch({ type: "setSearchInput", payload: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                    searchUser()
                  }
                }}
              />
              <Alert
                variant="warning"
                show={state.showMinCharactersAlert}
                transition={false}
                id={css.warning}
              >
                <div id={css.characterWarning}>
                  <p>Must enter more than three characters</p>
                  <Button
                    variant="warning"
                    onClick={() =>
                      dispatch({ type: "toggleShowMinCharactersAlert" })
                    }
                  >
                    Close
                  </Button>
                </div>
              </Alert>
            </Col>
            <Button onClick={searchUser}>Search</Button>
            {state.accountMerges.length > 1 && (
              <Button
                id={css.mergeAccountsButton}
                onClick={() => dispatch({ type: "toggleAccountMergeModal" })}
              >
                Merge Accounts
              </Button>
            )}
          </Form>
        </FormGroup>
        <AccountMergeModal
          show={state.showAccountMergeModal}
          toggleModal={() => dispatch({ type: "toggleAccountMergeModal" })}
          users={state.accountMerges}
          clearUsers={() => dispatch({ type: "clearAccountMergeUsers" })}
        />
        {state.showLoader && (
          <div id={css.loader}>
            <Loader animation="dots" />
          </div>
        )}
        {state.userData !== null ? (
          <table className="table table-striped" id={css.resultsTable}>
            <thead>
              <tr>
                <th>Merge Account</th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>TigerConnect Token</th>
                <th>SSO Enabled</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.userData.map(
                (user: AdminUserLookupUser, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className={css.checkboxes}>
                        <Checkbox
                          onChange={(e) =>
                            dispatch({
                              type: e.currentTarget.checked
                                ? "addAccountMergeUsers"
                                : "removeAccountMergeUsers",
                              payload: user,
                            })
                          }
                          checkboxValue={user.userid}
                          value={
                            state.accountMerges.findIndex(
                              (accountMergeUser: AdminUserLookupUser) =>
                                accountMergeUser.userid === user.userid
                            ) !== -1
                          }
                        />
                      </div>
                    </td>
                    <td>{user.username}</td>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td className={css.emails}>{user.email}</td>
                    <td>
                      {user.tigerconnect_token
                        ? user.tigerconnect_token
                        : "N/A"}
                    </td>
                    <td>{user.tigerschedule_sso ? "Yes" : "No"}</td>
                    <td>
                      <Button onClick={() => displayEditUserPage(user.userid)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </>
    )
  } else {
    return (
      <UserLookupEdit
        selectedUserId={state.selectedUserId}
        displayEditUserPage={displayEditUserPage}
      />
    )
  }
}

export default UserLookup
