import React, { useEffect, useState } from "react"
import { FormItem, Input } from "@app/components/Form"

import Button from "@app/components/Button"
import { Dialog } from "@app/components/Modal"
import Loader from "@app/components/Loader"
import api, { useRequest } from "@app/services/api"
import cx from "classnames"
import css from "./TigerscheduleApikey.module.scss"

export default () => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)

  const [apiKey, setApiKey] = useState<TigerscheduleApikeyType>()

  const { data } = useRequest<TigerscheduleApikeyType>([
    api.getTigerscheduleApikey,
  ])

  useEffect(() => {
    const checkbox = document.getElementById("tigerschedule_api_switch")
    setShow((checkbox as HTMLInputElement).checked)

    const listener = (event: Event) => {
      const isChecked = (event.target as HTMLInputElement).checked

      setShow(isChecked)
    }

    checkbox?.addEventListener("change", listener, { capture: true })

    return () => {
      checkbox?.removeEventListener("change", listener, { capture: true })
    }
  }, [])

  useEffect(() => {
    setApiKey(data)
    if (data !== undefined) {
      setLoading(false)
    }
  }, [data])

  const refreshKeys = () => {
    setLoading(true)
    api.updateTigerscheduleApikey().then((data: TigerscheduleApikeyType) => {
      setApiKey(data)
      setLoading(false)
    })
  }

  const onRefreshBtnClick = () => {
    if (apiKey) {
      Dialog.confirm({
        title: "Notice",
        message:
          "You will discard the exising API-Key & Secret and generate a new one for the current group. \
            Are you sure that you want to make this change?",
        buttons: {
          ok: { text: "Yes", onClick: refreshKeys },
          cancel: { text: "No" },
        },
      })
    } else {
      refreshKeys()
    }
  }

  return (
    show && (
      <div className="bootstrap4">
        <div className={css.settingContainer}>
          <FormItem required label="Api Key" name="api_key">
            {loading ? (
              <Loader variant="secondary" />
            ) : (
              <Input disabled value={apiKey?.api_key || ""} />
            )}
          </FormItem>
          <FormItem required label="Api Secret" name="api_secret">
            {loading ? (
              <Loader variant="secondary" />
            ) : (
              <Input disabled value={apiKey?.api_secret || ""} />
            )}
          </FormItem>
          <div className={cx(css.refreshButton)}>
            <Button size="sm" onClick={onRefreshBtnClick} disabled={loading}>
              {apiKey ? "Refresh" : "Generate"}
            </Button>
          </div>
        </div>
      </div>
    )
  )
}
