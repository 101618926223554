import { Provider } from "@app/containers/spa/WhiteboardCalendar/data"

export const getIsMultipleAssignmentHighlighted = (
  multipleAssignments: AssignmentBaseType[] | undefined,
  highlightProviders: Provider[] | undefined
) => {
  return multipleAssignments?.some((additionalElement) =>
    highlightProviders?.some(
      (highlightedProvider) =>
        additionalElement.provider?.providerid ===
        highlightedProvider.providerid
    )
  )
}
