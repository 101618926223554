import React, { useEffect } from "react"
import { useSelector } from "@app/models"
import { Select } from "@app/components/Form"
import SharedFilter from "./SharedFilter"
import { useFilter } from "@app/utils/hooks"
import { uniqBy, flatten } from "lodash"

type Props = {
  annualBlockScheduleId: string
}

export default (props: Props) => {
  const { annualBlockScheduleId: id } = props
  const {
    filterState: { conditions, values },
    enabled,
    clear,
    update,
    filterByConditions,
  } = useFilter(["annualBlockSchedule", id, "blockSets"])

  const blockSets = useSelector(({ blockSets: blockSetsState }) => {
    const { blockSets, annualBlockSchedule } = blockSetsState
    return id === annualBlockSchedule.id ? blockSets : []
  })

  const blockSetsFilteredByConditions = filterByConditions(blockSets)

  // Update values when conditions exist and changed
  useEffect(() => {
    if (Object.keys(conditions).length) {
      update({
        values: blockSetsFilteredByConditions.map((x) => x.id),
      })
    }
  }, [conditions])

  const staffLevelsForFilters = uniqBy(
    flatten(blockSets.map((x) => x.staff_levels)),
    "id"
  )

  return (
    <SharedFilter
      filterName="Block Sets"
      filterEnabled={enabled}
      filterValuesCount={values.length}
      onFilterClean={clear}
    >
      <Select
        className="mb-3"
        placeholder="All Staff Levels"
        options={staffLevelsForFilters}
        value={conditions.staff_level_id?.value}
        onChange={(value) =>
          update({
            condition: {
              name: "staff_level_id",
              path: "staff_levels[].id",
              value,
            },
          })
        }
      />
      <Select
        inline
        multiple
        options={blockSetsFilteredByConditions}
        value={values || []}
        onChange={(values) => update({ values })}
      />
    </SharedFilter>
  )
}
