import { addDays, getDate, isBefore, startOfWeek, endOfWeek } from "date-fns"

export const isAnyDayInFirstWeek = (startDate: Date) => {
  for (let i = 0; i <= 7; i++) {
    const day = addDays(startDate, i)
    const dayOfMonth = getDate(day)
    if (dayOfMonth <= 7) {
      return true
    }
  }
  return false
}

export const getWeeksToDisplay = (startDate: Date, targetMonth: number) => {
  const endOfMonth = new Date(startDate.getFullYear(), targetMonth + 1, 0)
  let firstVisibleDay = startOfWeek(startDate, { weekStartsOn: 0 })
  let lastVisibleDay = endOfWeek(endOfMonth, { weekStartsOn: 0 })

  let weeksToShow = 0
  while (
    isBefore(firstVisibleDay, lastVisibleDay) ||
    firstVisibleDay.getTime() === lastVisibleDay.getTime()
  ) {
    weeksToShow++
    firstVisibleDay = addDays(firstVisibleDay, 7)
  }
  return weeksToShow
}
