import React from "react"
import TabsLayout, { TabType } from "./components/TabsLayout"
import SyncFailures from "./sync-failures/SyncFailures"
import TrainerReports from "./trainer-reports/TrainerReports"
import UserLookup from "./user-lookup/UserLookup"

const tabs: Array<TabType> = [
  {
    title: "User Lookup",
    eventKey: "user_lookup",
    Component: UserLookup,
  },
  {
    title: "Trainer Reports",
    eventKey: "trainer_reports",
    Component: TrainerReports,
  },
  {
    title: "Sync Failures",
    eventKey: "sync_failures",
    Component: SyncFailures
  },
]

const AdminDashboard: React.FC<any> = (): JSX.Element => {
  return <TabsLayout tabs={tabs} />
}

export default AdminDashboard
