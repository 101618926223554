import React, { useState } from "react"
import { IManualScheduleJobItem, IManualScheduleJobTableInfo } from "./data"
import ManualScheduleJobRows from "./components/ManualScheduleJobRows"
import { ManualScheduleTableContext } from "./context/ManualScheduleJobTableContext"
import SplitShiftConfigBox from "./components/SplitShiftConfigBox/SplitShiftConfigBox"
import SplitShiftJobRow from "./components/ManualScheduleJobRow/SplitShiftJobRow"
import MultipleAssignmentsConfigBox from "./components/MultipleAssignmentsConfigBox"
import MultipleAssignmentsJobRow from "./components/ManualScheduleJobRow/MultipleAssignmentsJobRow"
import FormValidator from "./components/ManualScheduleFormValidation/FormValidator"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { removeDuplicatedJobs, sortSplitShift } from "./service"
import api, { useRequest } from "@app/services/api"
import { checkIfResidentJob } from "@app/services/jobHelper"

export interface IManualScheduleJobTableProps
  extends IManualScheduleJobTableInfo {}

const ManualScheduleJobTable: React.FC<IManualScheduleJobTableProps> = (
  props
) => {
  const [jobs, setJobs] = useState<IManualScheduleJobItem[]>(
    sortSplitShift(removeDuplicatedJobs(props.jobs))
  )
  const [currentSplitShiftJobId, setCurrentSplitShiftJobId] = useState<
    number | null
  >(null)
  const [currentMultipleAssignmentsJobId, setCurrentMultipleAssignmentsJobId] =
    useState<number | null>(null)
  const [splitShiftHasChanged, setSplitShiftHasChanged] =
    useState<boolean>(false)
  const [multipleAssignmentsHasChanged, setMultipleAssignmentsHasChanged] =
    useState<boolean>(false)
  const splitShiftHasNew = (currJobs: IManualScheduleJobItem[]) =>
    currJobs.some((j) => j.splitShifts.some((s) => s.is_new))

  const jobIds = jobs.map((x) => x.jobid).join(",")
  const isResidentJob = checkIfResidentJob(props.job_typeid)
  const { data: residentJobsProviders } = useRequest(
    isResidentJob ? [api.getJobsProviders, jobIds, props.edate] : null
  )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ManualScheduleTableContext.Provider
        value={{
          jobs,
          setJobs,
          currentSplitShiftJobId,
          setCurrentSplitShiftJobId,
          currentMultipleAssignmentsJobId,
          setCurrentMultipleAssignmentsJobId,
          providers: props.providers,
          isResidentJob,
          residentJobsProviders,
          edate: props.edate,
          dayid: props.dayid,
          splitShiftFlag: parseInt(props.splitShiftFlag),
          multipleAssignmentsFlag: parseInt(props.multipleAssignmentsFlag),
          splitShiftHasChanged,
          setSplitShiftHasChanged,
          multipleAssignmentsHasChanged,
          setMultipleAssignmentsHasChanged,
          tab: props.tab,
        }}
      >
        <FormValidator>
          <table>
            <thead>
              <tr>
                <td
                  align="center"
                  className="small_blk-bold indicator_glyph_table_cell"
                >
                  Flag
                </td>
                <td className="small_blk-bold job_description_table_cell">
                  Job
                </td>
                <td className="small_blk-bold job_description_table_cell">
                  Job Time
                </td>
                <td className="small_blk-bold scheduled_drop_down_table_cell">
                  Scheduled
                </td>
                {currentSplitShiftJobId ? (
                  <td className="small_blk-bold job_description_table_cell">
                    Tally
                  </td>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {currentSplitShiftJobId ? (
                <SplitShiftJobRow />
              ) : currentMultipleAssignmentsJobId ? (
                <MultipleAssignmentsJobRow />
              ) : (
                <ManualScheduleJobRows {...props} />
              )}
            </tbody>
          </table>
          {currentSplitShiftJobId ? (
            <SplitShiftConfigBox providers={props.providers} />
          ) : null}
          {currentMultipleAssignmentsJobId ? (
            <MultipleAssignmentsConfigBox
              providers={
                isResidentJob ? residentJobsProviders : props.providers
              }
            />
          ) : null}
          {splitShiftHasChanged ? (
            <input type="hidden" name="split_shift_has_change" value="1" />
          ) : null}
          {splitShiftHasNew(jobs) ? (
            <input type="hidden" name="split_shift_has_change" value="1" />
          ) : null}
          {multipleAssignmentsHasChanged ? (
            <input
              type="hidden"
              name="multiple_assignments_has_change"
              value="1"
            />
          ) : null}
        </FormValidator>
      </ManualScheduleTableContext.Provider>
    </MuiPickersUtilsProvider>
  )
}

export default ManualScheduleJobTable
