import React, { useEffect, useState } from "react"
import { formatDate } from "@app/utils"
import css from "./EditRequest.module.scss"
import BaseLayout from "@app/containers/layouts/Base"
import Loader from "@app/components/Loader"

type Props = {
  overlappingRequests: OverlappingRequest[]
  overlappingAssignment: OverlappingAssignment[]
}

export default ({ overlappingRequests, overlappingAssignment }: Props) => {
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    setShowLoader(false)
  }, [overlappingRequests, overlappingAssignment])

  if (showLoader) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <div className="w-100 p-3 d-flex justify-content-center">
          <Loader animation="dots" />
        </div>
      </BaseLayout>
    )
  } else if (overlappingRequests.length == 0) {
    return (
      <>
        <div className={css.overlappingTitle}>Overlapping Requests</div>
        <h3>There are no overlapping requests.</h3>
      </>
    )
  }

  for (var x = 0; x < overlappingRequests.length; x++) {
    // format start/end dates
    overlappingRequests[x].start_date_fmt = formatDate(
      overlappingRequests[x].start_date,
      "MMMM do, yyyy"
    )
    overlappingRequests[x].end_date_fmt = formatDate(
      overlappingRequests[x].end_date,
      "MMMM do, yyyy"
    )
  }

  return (
    <div className="container-fluid">
      <div className={css.overlappingTitle}>Overlapping Requests</div>
      {overlappingRequests.map((request: OverlappingRequest, index: number) => (
        <div
          className={index % 2 !== 0 ? css.darkBackground : css.whiteBackground}
          key={request.requestid}
        >
          <div className="row">
            <div className="col-xs-3">
              <div className={css.overlappingProviderName}>
                {request.provider_display_name}
              </div>
            </div>
            <div className="col-xs-9">
              <div className={css.overlappingItemBold}>
                {request.request_type} - {request.status}
              </div>
            </div>
          </div>

          <div className="row padding-bottom">
            <div className="col-xs-12">
              <div className={css.overlappingItem}>
                {request.start_date_fmt} - {request.end_date_fmt}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
