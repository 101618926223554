import React, { useState } from "react"
import { H3 } from "@app/components/Typography"
import Button from "@app/components/Button"
import Card from "@app/components/Card"
import Table from "@app/components/Table"
import { Link } from "react-router-dom"
import api, { useRequest, mutate } from "@app/services/api"
import { renderBlockSchedulingMenus } from "@app/utils"
import RolloverModal from "./RolloverModal"

export default () => {
  const { data: anuualSchedules } = useRequest([api.getAnnualBlockSchedules])
  const [rolloverAnnualSchedule, setRolloverAnnualSchedule] =
    useState<typeof anuualSchedules[0]>(null)

  const mutateAnuualSchedules = () => {
    api.getAnnualBlockSchedules().then((annualBlockSchedules: any) => {
      mutate([api.getAnnualBlockSchedules], annualBlockSchedules, false)

      // Update BlockScheduling menu without refreshing page
      renderBlockSchedulingMenus(annualBlockSchedules)
    })
  }

  return (
    <Card
      title={<H3 variant="blue">Annual Schedules</H3>}
      action={
        <Button
          size="sm"
          variant="outline-secondary"
          to="/annual_schedules/new"
        >
          Add
        </Button>
      }
    >
      <RolloverModal
        rolloverAnnualSchedule={rolloverAnnualSchedule}
        mutateAnuualSchedules={mutateAnuualSchedules}
        onHide={() => setRolloverAnnualSchedule(null)}
      />
      <Table
        data={anuualSchedules}
        loading={!anuualSchedules}
        scroll={{ y: 400 }}
        columns={[
          {
            dataKey: "name",
            title: "Name",
            render: (name, item) => (
              <Link to={`/annual_schedules/${item?.id}`}>{name}</Link>
            ),
          },
          { dataKey: "display_name", title: "Year" },
          {
            dataKey: "action",
            position: "right",
            render: (_, item) => (
              <>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  to={`/annual_schedules/${item?.id}/edit`}
                >
                  Edit
                </Button>
                <Button
                  size="sm"
                  className="ml-2"
                  variant="outline-secondary"
                  onClick={() => setRolloverAnnualSchedule(item)}
                >
                  Rollover
                </Button>
              </>
            ),
          },
        ]}
        emptyMessage="No annual schedules have been created for this group. Please add an annual schedule."
      />
    </Card>
  )
}
