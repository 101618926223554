import React from "react"
import { DefaultTigerconnectSettingId } from "@app/utils/constants"

type Props = {
  job: JobType
}

export default (({ job }: Props) => {
  const { tigerconnect_integration, tigerconnect_roles: roles } = job

  if (!tigerconnect_integration || !roles?.length) return null

  const isAllDefaultLabel = roles.every(
    (role) => role.tigerconnect_setting_id === DefaultTigerconnectSettingId
  )

  return roles.map((role) => (
    <div title={role.entity_token} key={role.id}>
      {!isAllDefaultLabel && (
        <span>{`${role.tigerconnect_setting_label} - `}</span>
      )}
      <span>{role.entity_name}</span>
      {role.entity_tag_name && (
        <span className="ml-1" style={{ color: role.entity_tag_color }}>
          ({role.entity_tag_name})
        </span>
      )}
    </div>
  ))
}) as React.FC<Props>
