import React, { useEffect, useState } from "react"
import css from "./NotesForm.module.scss"
import { Button } from "react-bootstrap"
import CheckIcon from "@material-ui/icons/Check"
import * as types from "../types"
import { NotesFormValues, useNotesForm } from "./useNotesForm"
import { Controller } from "react-hook-form"
import cx from "classnames"

interface Props {
  onSubmit: (data: types.NotesForm) => void
  onCloseModal: () => void
}

export const NotesForm = ({ onSubmit, onCloseModal }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const form = useNotesForm()

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = form

  const onSubmitForm = (formData: NotesFormValues) => {
    try {
      setIsLoading(true)
      onSubmit(formData)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ content: "", showOnCalendar: false })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form
      id="notes-form"
      className={css.form}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <Controller
        name="content"
        control={control}
        render={({ field }) => {
          return (
            <>
              <textarea
                {...field}
                className={cx(css.textArea, {
                  [css.errorBorder]: errors?.content?.message,
                })}
              />
              {errors?.content?.message ? (
                <p className={css.errorMessage}>{errors.content.message}</p>
              ) : null}
            </>
          )
        }}
      />

      <Controller
        name="showOnCalendar"
        control={control}
        render={({ field }) => {
          return (
            <button
              type="button"
              className={css.checkboxContainer}
              onClick={() => field.onChange(!field.value)}
            >
              <div className={css.checkIconContainer}>
                {field.value && <CheckIcon className={css.checkIcon} />}
              </div>
              <div>Show in calendar</div>
            </button>
          )
        }}
      />

      <div className={css.buttonsContainer}>
        <Button
          variant="outline-primary"
          onClick={onCloseModal}
          className={`${css.customButton} ${css.cancelButton}`}
        >
          Cancel
        </Button>

        <Button
          className={`${css.customButton} ${css.saveButton}`}
          form="notes-form"
          type="submit"
          disabled={isLoading}
        >
          Save Note
        </Button>
      </div>
    </form>
  )
}
