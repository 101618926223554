import React, { useState, useRef, useEffect, FC } from "react"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import css from "./CustomTableSelect.module.scss"

interface CustomSelectProps {
  options: string[]
  value: string
  onChange: (newValue: string) => void
  placeholder: string
}

const CustomTableSelect: FC<CustomSelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleOutsideClick)
    return () => document.removeEventListener("mousedown", handleOutsideClick)
  }, [])

  const getAllPlaceholder = () => {
    if (placeholder === "Type") {
      return "All Types"
    }
    return `All ${placeholder}`
  }

  return (
    <div ref={containerRef} className={css.customSelect}>
      <div className={css.selectValue} onClick={() => setIsOpen(!isOpen)}>
        {value || placeholder}
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      {isOpen && (
        <div className={css.optionsContainer}>
          <div
            className={`${css.option} ${!value && css.selectedOption}`}
            onClick={() => {
              onChange("")
              setIsOpen(false)
            }}
          >
            {getAllPlaceholder()}
          </div>
          {options.map((option) => (
            <div
              key={option}
              className={`${css.option} ${
                value === option && css.selectedOption
              }`}
              onClick={() => {
                onChange(option)
                setIsOpen(false)
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomTableSelect
