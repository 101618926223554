import React, { useRef } from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import BootstrapTooltip from "react-bootstrap/Tooltip"
import { randomID } from "@app/utils"
import cx from "classnames"
import css from "./Tooltip.module.scss"

type Props = Omit<React.ComponentProps<typeof OverlayTrigger>, "overlay"> & {
  title: string | JSX.Element
  className?: string
  bg?: string
}

export default (props: Props) => {
  const {
    title,
    trigger = ["hover", "focus"],
    placement = "top",
    bg = "white",
    rootClose = true,
    className,
    children,
    ...rest
  } = props

  const toolTipId = useRef(randomID({ prefix: "tooltip" }))

  return (
    <OverlayTrigger
      {...rest}
      rootClose={rootClose}
      placement={placement}
      trigger={trigger}
      overlay={(overlayProps) => (
        <div className={cx("bootstrap4", css.tooltipContainer)}>
          <BootstrapTooltip
            {...overlayProps}
            id={toolTipId.current}
            className={cx(css.tooltip, bg && css[`bg-${bg}`], className)}
          >
            {title}
          </BootstrapTooltip>
        </div>
      )}
    >
      {children}
    </OverlayTrigger>
  )
}
