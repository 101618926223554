import React from "react"
import { FieldArrayWithId } from "react-hook-form"
import { FormFieldsType } from "./AdditionalAssignments"
import IconButton from "@material-ui/core/IconButton"
import ClearIcon from "@material-ui/icons/Clear"
import Button from "@app/components/Button"

import css from "./AdditionalAssignments.module.scss"
import cx from "classnames"
interface PropsType {
  jobTimesRange: string
  remove: (index: number) => void
  fields: FieldArrayWithId<FormFieldsType, "providerids", "id">[]
  onSubmit: () => void
  onCancel: () => void
}

export default ({
  jobTimesRange,
  remove,
  fields,
  onSubmit,
  onCancel,
}: PropsType) => {
  return (
    <td colSpan={7}>
      <div className={css.headers}>Shift Times</div>
      {fields.map((row, index) => {
        if (fields.length === 1) {
          return (
            <div key={row.id} className={css.shiftTimes}>
              {jobTimesRange}
            </div>
          )
        } else {
          return (
            <div key={row.id} className="d-flex">
              <div className={css.shiftTimes}>{jobTimesRange}</div>
              <IconButton
                className={css.iconButton}
                aria-label="upload picture"
                component="span"
                onClick={() => remove(index)}
              >
                {" "}
                <ClearIcon fontSize="small" style={{ color: "#A2B0BC" }} />
              </IconButton>
            </div>
          )
        }
      })}
      <div className="d-flex justify-content-end mb-3">
        <Button
          className={cx("mr-3", css.customButton, css.cancelButton)}
          variant="outline-secondary"
          size="sm"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={cx(css.customButton, css.saveButton)}
          variant="primary"
          size="sm"
          onClick={onSubmit}
        >
          Apply
        </Button>
      </div>
    </td>
  )
}
