import React, { useState } from "react"
import { Radio, FormItem } from "@app/components/Form"
import Button from "@app/components/Button"
import { AdminUserLookupUser } from "../../UserLookup"

type PropsType = {
  users: AdminUserLookupUser[]
  setPrimaryUser: (user: AdminUserLookupUser) => void
  setOtherUsers: (users: AdminUserLookupUser[]) => void
  closeModal: () => void
}

export default (props: PropsType) => {
  const [selectedUser, setSelectedUser] = useState<number | string>(
    props.users[0].userid.toString()
  )

  const selectPrimaryUser = (userid: number | string) => {
    const usersCopy = [...props.users]
    const selectedUserIndex = props.users.findIndex(
      (user) => Number(user.userid) === Number(userid)
    )

    const selectedUser = usersCopy.splice(selectedUserIndex, 1)

    props.setPrimaryUser(selectedUser[0])

    props.setOtherUsers(usersCopy)
  }

  return (
    <div>
      <FormItem label="Primary Account Select">
        <div className="text-nowrap">
          <Radio.Group
            name="primaryAccount"
            options={props.users.map((user) => {
              return {
                id: user.userid.toString(),
                name: user.username,
              }
            })}
            defaultValue={selectedUser}
            value={selectedUser}
            onChange={(value) => setSelectedUser(value)}
          />
        </div>
      </FormItem>
      <div className="d-flex justify-content-center w-100">
        <Button onClick={() => selectPrimaryUser(selectedUser)}>Next</Button>
      </div>
    </div>
  )
}
