import React from "react"
import Modal from "@app/components/Modal"
import EditRequestStatus from "@app/containers/request-editor/EditRequestStatus"

type Props = {
  requestid: number
  onHide: () => void
}

export default (props: Props) => {
  const { requestid, onHide } = props

  const hideModal = () => {
    onHide()
  }

  return (
    <Modal
      noHeader
      noFooter
      size="lg"
      show={Boolean(requestid)}
      onHide={hideModal}
    >
      <EditRequestStatus requestid={requestid} onHide={hideModal} />
    </Modal>
  )
}
