import React, { useState } from "react"
import { Button } from "react-bootstrap"
import CheckIcon from "@material-ui/icons/Check"
import { Controller } from "react-hook-form"
import css from "./NoteItem.module.scss"
import cx from "classnames"

import { NotesFormValues, useNotesForm } from "../NotesForm/useNotesForm"
import * as types from "../types"

interface Props {
  data: Note
  onUpdateNote: (id: number, updatedNote: types.NotesForm) => Promise<void>
  onDeleteNote: (id: number) => Promise<void>
}

export const NoteItem = ({ data, onUpdateNote, onDeleteNote }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedNote, setSelectedNote] = useState<Note>()

  const form = useNotesForm(data)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form

  const onSubmitForm = (formData: NotesFormValues) => {
    try {
      setIsEditing(true)
      onUpdateNote(selectedNote!.noteid, formData)
    } catch (error) {
      console.error(error)
    } finally {
      setIsEditing(false)
    }
  }

  return (
    <li className={css.container}>
      <h6 className={css.authorNameContainer}>
        <span className={css.nameTitle}>Name:</span>
        <span>
          {data.user?.firstname} {data.user?.lastname}
        </span>
      </h6>
      <div className={css.contentContainer}>
        {isEditing ? (
          <form onSubmit={handleSubmit(onSubmitForm)} className={css.form}>
            <Controller
              name="content"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <textarea
                      {...field}
                      className={cx(css.textArea, {
                        [css.errorBorder]: errors?.content?.message,
                      })}
                    />
                    {errors?.content?.message ? (
                      <p className={css.errorMessage}>
                        {errors.content.message}
                      </p>
                    ) : null}
                  </>
                )
              }}
            />

            <Controller
              name="showOnCalendar"
              control={control}
              render={({ field }) => {
                return (
                  <button
                    type="button"
                    className={css.checkboxContainer}
                    onClick={() => field.onChange(!field.value)}
                  >
                    <div className={css.checkIconContainer}>
                      {field.value && <CheckIcon className={css.checkIcon} />}
                    </div>
                    <div>Show in calendar</div>
                  </button>
                )
              }}
            />
            <div className={css.buttonsContainer}>
              <Button className={css.textButton} type="submit">
                Save
              </Button>
              <Button
                className={css.textButton}
                onClick={() => {
                  setIsEditing(false)
                  setSelectedNote(undefined)
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        ) : (
          <>
            <div className={css.contentWrapper}>
              <p className={css.content}>{data.note}</p>
            </div>
            <div className={css.buttonsContainer}>
              <Button
                className={css.textButton}
                onClick={() => {
                  setIsEditing(true)
                  setSelectedNote(data)
                }}
              >
                Edit
              </Button>
              <Button
                className={css.textButton}
                onClick={() => onDeleteNote(data.noteid)}
              >
                Delete
              </Button>
            </div>
          </>
        )}
      </div>
    </li>
  )
}
