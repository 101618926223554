import React, { useEffect, useState, useMemo, useRef } from "react"
import { TableVirtuoso } from "react-virtuoso"
import api, { useRequest } from "@app/services/api"
import { useSelector } from "@app/models"
import GroupManagementHeader from "./GroupManagementHeader"
import css from "./GroupManagementTable.module.scss"

interface GroupData {
  groupid: number
  name: string
  abbreviation: string
  jobs_count: number
  tc_integrated_jobs_count: number
  providers_count: number
  active_providers: number
  schedulers: number
  active_schedulers: number
  assignment_for_next_30_days: number
  latest_assigned_date: string
  provider_protocols_count: number
  protocols_count: number
  tigerconnect_roles_sync: boolean
  tigerconnect_roles_sync_2way: boolean
  tigerconnect_roles_sync_empty_assignment_notification: boolean
}

const PAGE_SIZE = 50

const GroupManagementTable: React.FC = () => {
  const { clinicid } = useSelector((state) => state.users.currentUser)
  const { data: clinicData } = useRequest<{
    groups: GroupData[]
    total: number
    page: number
    per: number
  }>(clinicid ? [api.getGroupsByClinic, clinicid] : null)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<"name" | "latest_assigned_date" | null>(
    null
  )
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null)

  const virtuosoRef = useRef<any>(null)
  const filteredData = useMemo(() => {
    if (!clinicData) return []
    return clinicData.groups.filter(
      (group) =>
        group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        group.groupid.toString().includes(searchTerm)
    )
  }, [clinicData, searchTerm])

  const sortedData = useMemo(() => {
    if (!sortBy) {
      return filteredData
    }

    return [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      } else {
        return sortOrder === "asc"
          ? new Date(a.latest_assigned_date).getTime() -
              new Date(b.latest_assigned_date).getTime()
          : new Date(b.latest_assigned_date).getTime() -
              new Date(a.latest_assigned_date).getTime()
      }
    })
  }, [filteredData, sortBy, sortOrder])

  const pagedData = useMemo(() => {
    const start = (currentPage - 1) * PAGE_SIZE
    const end = start + PAGE_SIZE
    return sortedData.slice(start, end)
  }, [sortedData, currentPage])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerm])

  useEffect(() => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({ index: 0, align: "start" })
    }
  }, [currentPage])

  const handleSortChange = (key: "name" | "latest_assigned_date") => {
    if (sortBy === key) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
    } else {
      setSortBy(key)
      setSortOrder("desc")
    }
  }

  const startIndex = (currentPage - 1) * PAGE_SIZE + 1
  const endIndex = Math.min(currentPage * PAGE_SIZE, filteredData.length)
  const totalItems = filteredData.length

  return (
    <div className={css.tableContainer}>
      <div className={css.controls}>
        <input
          type="text"
          placeholder="Search by group name or ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={css.searchInput}
        />
      </div>
      <TableVirtuoso
        ref={virtuosoRef}
        style={{ height: 600, width: "100%" }}
        data={pagedData}
        fixedHeaderContent={() => (
          <GroupManagementHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleSortChange={handleSortChange}
          />
        )}
        itemContent={(index, group) => (
          <tr key={index} className={css.groupManagementTable}>
            <td>{group.groupid}</td>
            <td className={css.groupName}>{group.name}</td>
            <td>{group.abbreviation}</td>
            <td>{group.jobs_count}</td>
            <td>{group.tc_integrated_jobs_count}</td>
            <td>{group.providers_count}</td>
            <td>{group.active_providers}</td>
            <td>{group.schedulers}</td>
            <td>{group.active_schedulers}</td>
            <td>{group.assignment_for_next_30_days}</td>
            <td>{group.latest_assigned_date}</td>
            <td>{group.provider_protocols_count}</td>
            <td>{group.protocols_count}</td>
            <td>{group.tigerconnect_roles_sync ? "Yes" : "No"}</td>
            <td>{group.tigerconnect_roles_sync_2way ? "Yes" : "No"}</td>
            <td>
              {group.tigerconnect_roles_sync_empty_assignment_notification
                ? "Enabled"
                : "Disabled"}
            </td>
          </tr>
        )}
      />
      <div className={css.paginationControls}>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <div className={css.paginationInfo}>
          <div>
            {startIndex}–{endIndex} of {totalItems}
          </div>
          <div>Page {currentPage}</div>
        </div>
        <button
          disabled={currentPage * PAGE_SIZE >= filteredData.length}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default GroupManagementTable
