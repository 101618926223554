import React, { Fragment, useMemo, useEffect, useCallback } from "react"
import { FormItem, Input } from "@app/components/Form"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import HighlightOffOutlined from "@material-ui/icons/HighlightOffOutlined"
import css from "./CalendarControls.module.scss"
import { dashboardMenuItems } from "@app/utils/constants"
import SubMenuCalendar from "./SubMenuCalendar/SubMenuCalendar"
import { useDispatch, useSelector } from "@app/models"
import { IRequestStatus, Job, IRequestType } from "../data"
import { Control, UseFormHandleSubmit } from "react-hook-form"
import cx from "classnames"

type FormFields = {
  date: string
  number: number
  view: "Day" | "Week" | "Month"
}

type CalendarControlsProps = {
  actualDate: string
  control: Control<FormFields, object>
  number: number
  period: string
  viewMode: string
  daysOptions: number[]
  handleNumberChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handlePeriodChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleSubmit: UseFormHandleSubmit<FormFields>
  onSubmit: (fields: FormFields) => void
  handleViewModeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  toggleDropdown: () => void
  menuButtonRef: React.RefObject<HTMLButtonElement>
  groupName: string
  dropdownShow: boolean
  activeSubmenu: string
  openSubmenu: (submenuName: string) => void
  closeSubmenu: () => void
  renderSubmenuContent: () => JSX.Element | null
  onShowQuickAssigneePanel: () => void
  setQuickAssigneePanelOpen: React.Dispatch<React.SetStateAction<boolean>>
  quickAssigneePanelOpen: boolean
  onShowUnschedule: () => void
  onShowEraseFlags: () => void
  onShowScheduleRange: () => void
  menuRef: React.RefObject<HTMLDivElement>
  providersData: Provider[]
  jobsData: Job[]
  requestStatus: IRequestStatus[]
  requestTypes: IRequestType[]
}

const CalendarControls = ({
  actualDate,
  control,
  number,
  period,
  viewMode,
  daysOptions,
  handleNumberChange,
  handlePeriodChange,
  handleSubmit,
  onSubmit,
  handleViewModeChange,
  toggleDropdown,
  menuButtonRef,
  groupName,
  dropdownShow,
  activeSubmenu,
  openSubmenu,
  closeSubmenu,
  renderSubmenuContent,
  onShowQuickAssigneePanel,
  setQuickAssigneePanelOpen,
  quickAssigneePanelOpen,
  onShowUnschedule,
  onShowEraseFlags,
  onShowScheduleRange,
  menuRef,
  providersData,
  jobsData,
  requestStatus,
  requestTypes,
}: CalendarControlsProps) => {
  const { calendarConfig } = useSelector((state) => state.calendarEvents)

  const {
    filterOptions: { jobsFilters, providersFilters },
    isMissingAssignmentsFilterActive,
    listViewType,
  } = calendarConfig

  const isJobFilterActive = useMemo(
    () => Boolean(jobsFilters.jobIds),
    [jobsFilters]
  )

  const isProviderFilterActive = useMemo(
    () => Boolean(providersFilters.providerIds),
    [providersFilters]
  )

  const isDayOffFilterActive = useMemo(
    () =>
      calendarConfig.filterOptions.dayoffFilters.requestTypeIds &&
      requestTypes &&
      requestTypes.length !==
        calendarConfig.filterOptions.dayoffFilters.requestTypeIds.length &&
      calendarConfig.filterOptions.dayoffFilters.requestTypeIds.length > 0,
    [calendarConfig.filterOptions.dayoffFilters.requestTypeIds, requestTypes]
  )

  const isHighlightProvidersActive =
    calendarConfig.highlightOptions.highlightProviders &&
    calendarConfig.highlightOptions.highlightProviders?.length > 0

  const activeFiltersNumber = useMemo(
    () =>
      [
        isJobFilterActive,
        isProviderFilterActive,
        isDayOffFilterActive,
        isMissingAssignmentsFilterActive,
      ].filter(Boolean)?.length,
    [
      isJobFilterActive,
      isProviderFilterActive,
      isDayOffFilterActive,
      isMissingAssignmentsFilterActive,
    ]
  )

  const dispatch = useDispatch()

  const resetQuickMode = useCallback(() => {
    dispatch.calendarEvents.setSelectedJob(undefined)
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.quickAssignments.resetQuickAssignments()
  }, [dispatch])

  useEffect(() => {
    if (listViewType === "provider") {
      dispatch.calendarEvents.setUnderstaffedJobsHighlight(false)
      closeSubmenu()
    }
    resetQuickMode()
  }, [listViewType])

  const leftPanelFilteredItems = useMemo(
    () =>
      dashboardMenuItems.filter(
        (menuItem) =>
          (menuItem.viewMode ? menuItem.viewMode === viewMode : true) &&
          (menuItem.listViewType
            ? menuItem.listViewType === listViewType
            : true)
      ),
    [viewMode, listViewType]
  )

  const renderTitle = (item: { title: string }, index: number) => {
    return (
      <li
        key={index}
        className={cx(css.menuTitle, {
          [css.menuTitleWithLabel]:
            item.title === "Highlighting" &&
            (isHighlightProvidersActive ||
              calendarConfig.isHighlightedChangesActive ||
              calendarConfig.isUnderstaffedJobsHighlightActive),
        })}
      >
        {item.title === "Highlighting" &&
          (isHighlightProvidersActive ||
            calendarConfig.isHighlightedChangesActive ||
            calendarConfig.isUnderstaffedJobsHighlightActive) && (
            <span className={css.titleLabel}>
              Only one highlight can be active at a time
            </span>
          )}
        {item.title}
      </li>
    )
  }

  const renderLeftPanelFilteredItems = (
    listItem: {
      title: string
      submenu?: string
    },
    index: number
  ) => {
    const activeActionCase = (
      onClose: () => void,
      onClick: () => void,
      name: string,
      activeFilters: number | undefined
    ) => (
      <li key={`${name}-${index}`} className={css.filterActiveButtonMenuItem}>
        <div className={css.filterActiveButtonWrapper}>
          <button className={css.closeButton} onClick={onClose}>
            <HighlightOffOutlined />
          </button>
          <button className={css.openFiltersButton} onClick={onClick}>
            {name}{" "}
            {typeof activeFilters === "number" ? `(${activeFilters})` : ""}
          </button>
        </div>
      </li>
    )

    const defaultCase = (
      <li
        key={index}
        className={css.menuSubtitle}
        onClick={() => {
          if (listItem.title.toLowerCase().includes("quick")) {
            onShowQuickAssigneePanel()
            toggleDropdown()
          } else {
            listItem.submenu && openSubmenu(listItem.submenu)
          }
        }}
      >
        {listItem.title}
      </li>
    )

    switch (listItem.title) {
      case "Providers":
        return isProviderFilterActive
          ? activeActionCase(
              () => {
                dispatch.calendarEvents.saveFilterOptions({
                  providersFilters: {
                    activeSubFilter: [],
                    providerIds: undefined,
                  },
                })
              },
              () => {
                if (listItem.title.toLowerCase().includes("quick")) {
                  onShowQuickAssigneePanel()
                  toggleDropdown()
                } else {
                  listItem.submenu && openSubmenu(listItem.submenu)
                }
              },
              "Providers",
              calendarConfig.filterOptions.providersFilters.providerIds!.length
            )
          : defaultCase
      case "Jobs":
        return isJobFilterActive
          ? activeActionCase(
              () => {
                dispatch.calendarEvents.saveFilterOptions({
                  jobsFilters: {
                    activeSubFilter: [],
                    jobIds: undefined,
                  },
                })
              },
              () => {
                if (listItem.title.toLowerCase().includes("quick")) {
                  onShowQuickAssigneePanel()
                  toggleDropdown()
                } else {
                  listItem.submenu && openSubmenu(listItem.submenu)
                }
              },
              "Jobs",
              calendarConfig.filterOptions.jobsFilters.jobIds!.length
            )
          : defaultCase
      case "Days Off":
        const isDaysOffFilterActive =
          calendarConfig.filterOptions.dayoffFilters.requestTypeIds ===
            undefined ||
          (requestTypes &&
            requestTypes.length >
              calendarConfig.filterOptions.dayoffFilters.requestTypeIds.length)

        return isDaysOffFilterActive
          ? activeActionCase(
              () => {
                dispatch.calendarEvents.saveFilterOptions({
                  dayoffFilters: {
                    activeSubFilter: [],
                    requestStatusIds: requestStatus.map(
                      (status) => status.request_statusid
                    ),
                    requestTypeIds: requestTypes.map(
                      (requestType) => requestType.request_typeid
                    ),
                    showVacations: true,
                  },
                })
              },
              () => {
                if (listItem.title.toLowerCase().includes("quick")) {
                  onShowQuickAssigneePanel()
                  toggleDropdown()
                } else {
                  listItem.submenu && openSubmenu(listItem.submenu)
                }
              },
              "Days Off",
              calendarConfig.filterOptions.dayoffFilters.requestTypeIds ===
                undefined
                ? 0
                : calendarConfig.filterOptions.dayoffFilters.requestTypeIds
                    .length
            )
          : defaultCase
      case "Highlight Providers":
        return isHighlightProvidersActive ? (
          activeActionCase(
            () => {
              dispatch.calendarEvents.saveFilterOptions({
                highlights: {
                  activeSubFilter: [],
                  highlightProviders: [],
                },
              })
            },
            () => {
              if (listItem.title.toLowerCase().includes("quick")) {
                onShowQuickAssigneePanel()
                toggleDropdown()
              } else {
                listItem.submenu && openSubmenu(listItem.submenu)
              }
            },
            "Highlight Providers",
            calendarConfig.highlightOptions.highlightProviders?.length || 0
          )
        ) : (
          <button
            key={`${listItem.title}-${index}`}
            className={cx(css.menuHighlight, {
              [css.menuSubmenuDisabled]:
                calendarConfig.isUnderstaffedJobsHighlightActive ||
                calendarConfig.isHighlightedChangesActive,
            })}
            onClick={() => {
              if (!calendarConfig.isUnderstaffedJobsHighlightActive) {
                listItem.submenu && openSubmenu(listItem.submenu)
              }
            }}
            disabled={
              calendarConfig.isUnderstaffedJobsHighlightActive ||
              calendarConfig.isHighlightedChangesActive
            }
          >
            {listItem.title}
          </button>
        )
      case "Missing Assignments":
        return calendarConfig.isMissingAssignmentsFilterActive
          ? activeActionCase(
              () => {
                dispatch.calendarEvents.setMissingAssignmentsFilter(false)
              },
              () => {
                if (listItem.title.toLowerCase().includes("quick")) {
                  onShowQuickAssigneePanel()
                  toggleDropdown()
                } else {
                  listItem.submenu && openSubmenu(listItem.submenu)
                }
              },
              "Missing Assignments",
              undefined
            )
          : defaultCase

      case "Highlight Understaffed Jobs":
        return calendarConfig.isUnderstaffedJobsHighlightActive ? (
          activeActionCase(
            () => {
              dispatch.calendarEvents.setUnderstaffedJobsHighlight(false)
            },
            () => {
              if (listItem.title.toLowerCase().includes("quick")) {
                onShowQuickAssigneePanel()
                toggleDropdown()
              } else {
                listItem.submenu && openSubmenu(listItem.submenu)
              }
            },
            "Highlight Understaffed Jobs",
            undefined
          )
        ) : (
          <button
            key={`${listItem.title}-${index}`}
            className={cx(css.menuHighlight, {
              [css.menuSubmenuDisabled]:
                isHighlightProvidersActive ||
                calendarConfig.isHighlightedChangesActive,
            })}
            onClick={() => {
              if (!isHighlightProvidersActive) {
                listItem.submenu && openSubmenu(listItem.submenu)
              }
            }}
            disabled={
              isHighlightProvidersActive ||
              calendarConfig.isHighlightedChangesActive
            }
          >
            {listItem.title}
          </button>
        )

      case "Highlight Changes":
        return calendarConfig.isHighlightedChangesActive ? (
          activeActionCase(
            () => {
              dispatch.calendarEvents.setHighlightedChangesActive(false)
            },
            () => {
              if (
                !isHighlightProvidersActive &&
                !calendarConfig.isUnderstaffedJobsHighlightActive
              ) {
                listItem.submenu && openSubmenu(listItem.submenu)
              }
            },
            "Highlight Changes",
            undefined
          )
        ) : (
          <button
            key={`${listItem.title}-${index}`}
            className={cx(css.menuHighlight, {
              [css.menuSubmenuDisabled]:
                isHighlightProvidersActive ||
                calendarConfig.isUnderstaffedJobsHighlightActive,
            })}
            onClick={() => {
              if (
                !isHighlightProvidersActive &&
                !calendarConfig.isUnderstaffedJobsHighlightActive
              ) {
                listItem.submenu && openSubmenu(listItem.submenu)
              }
            }}
            disabled={
              isHighlightProvidersActive ||
              calendarConfig.isUnderstaffedJobsHighlightActive
            }
          >
            {listItem.title}
          </button>
        )
      case "Quick Assign":
        return quickAssigneePanelOpen
          ? activeActionCase(
              () => {
                setQuickAssigneePanelOpen(false)
                dispatch.calendarEvents.setSelectedProvider(undefined)
                dispatch.calendarEvents.setSelectedJob(undefined)
              },
              () => {
                if (listItem.title.toLowerCase().includes("quick")) {
                  onShowQuickAssigneePanel()
                  toggleDropdown()
                } else {
                  listItem.submenu && openSubmenu(listItem.submenu)
                }
              },
              "Quick Assign",
              undefined
            )
          : defaultCase
      case "Range":
        return (
          <li
            key={index}
            className={css.menuSubtitle}
            onClick={() => {
              onShowScheduleRange()
              toggleDropdown()
            }}
          >
            {listItem.title}
          </li>
        )
      case "Unschedule":
        return (
          <li
            key={index}
            className={css.menuSubtitle}
            onClick={() => {
              onShowUnschedule()
              toggleDropdown()
            }}
          >
            {listItem.title}
          </li>
        )
      case "Erase Flags":
        return (
          <li
            key={index}
            className={css.menuSubtitle}
            onClick={() => {
              onShowEraseFlags()
              toggleDropdown()
            }}
          >
            {listItem.title}
          </li>
        )
      default:
        return defaultCase
    }
  }

  return (
    <div className={css.calendarControls}>
      <div className={css.topRow}>
        <div className={css.groupName}>
          CURRENT SCHEDULE FOR <strong>{groupName.toUpperCase()}</strong>
        </div>
        <div className={css.legacyView}>
          Switch to the{" "}
          <a
            id="switch-to-legacy-view"
            href="/calendar/manual_schedule.cgi?view=month&wt=comp"
          >
            legacy view
          </a>
        </div>
      </div>
      <div className={css.secondRow}>
        <div className={css.secondRowLeft}>
          <button
            className={css.menuCTA}
            onClick={toggleDropdown}
            ref={menuButtonRef}
          >
            <MenuIcon />
          </button>

          {activeFiltersNumber > 0 ? (
            <button
              className={css.filtersIndicatorContainer}
              onClick={toggleDropdown}
            >
              Filters: {activeFiltersNumber}
            </button>
          ) : null}
        </div>

        <div className={css.dateControls}>
          <div className={css.dateControlsForm}>
            <FormItem
              defaultValue={actualDate}
              className={css.formItem}
              label=""
              layout={[0, 12]}
              name="date"
              control={control}
            >
              <Input type="datetime" showTodayButton={true} />
            </FormItem>
            <FormItem
              className={css.formItem}
              label=""
              layout={[0, 12]}
              name="date"
              control={control}
            >
              <div className={css.separatorLine}></div>
            </FormItem>
            <FormItem
              className={css.formItem}
              label=""
              layout={[0, 12]}
              name="number"
              control={control}
            >
              <select
                className={css.viewSelect}
                value={number}
                onChange={handleNumberChange}
              >
                {daysOptions.map((n) => (
                  <option key={n} value={n}>
                    {n}
                  </option>
                ))}
              </select>
            </FormItem>
            <FormItem
              className={css.formItem}
              label=""
              layout={[0, 12]}
              name="view"
              control={control}
            >
              <select
                className={css.viewSelect}
                value={period}
                onChange={handlePeriodChange}
              >
                <option value="Day">Days</option>
                <option value="Week">Weeks</option>
                <option value="Month">Months</option>
              </select>
            </FormItem>
            <FormItem
              label=""
              className={css.formItem}
              layout={[0, 12]}
              name="apply"
            >
              <button
                className={css.applyButton}
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </FormItem>
          </div>
        </div>
        <select
          className={css.viewSelect}
          value={viewMode}
          onChange={(e) => {
            dispatch.calendarEvents.setUnderstaffedJobsHighlight(false)
            dispatch.calendarEvents.setHighlightedChangesActive(false)
            dispatch.calendarEvents.setSelectedProvider(undefined)
            dispatch.calendarEvents.setSelectedJob(undefined)
            setQuickAssigneePanelOpen(false)
            closeSubmenu()
            handleViewModeChange(e)
          }}
        >
          <option value="calendar">Calendar View</option>
          <option value="list">List View</option>
        </select>
      </div>

      <div
        className={`${css.leftMenu} ${dropdownShow ? css.leftMenuOpen : ""}`}
        ref={menuRef}
      >
        {activeSubmenu ? (
          <SubMenuCalendar title={activeSubmenu} onClose={closeSubmenu}>
            {renderSubmenuContent()}
          </SubMenuCalendar>
        ) : (
          <Fragment>
            <CloseIcon className={css.closeIcon} onClick={toggleDropdown} />
            <ul className={css.menuContainer}>
              {leftPanelFilteredItems.map((item, index) =>
                item.isTitle
                  ? renderTitle(item, index)
                  : renderLeftPanelFilteredItems(item, index)
              )}
            </ul>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default CalendarControls
