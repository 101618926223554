const seatTypes = ["Minimum", "Ideal", "Maximum"]

export const transformStaffingTargetsToTableData = (
  staffingTargetData: any
) => {
  if (!staffingTargetData.length) {
    return seatTypes.map((seatType) => {
      return {
        name: seatType,
        "1": 1,
        "2": 1,
        "3": 1,
        "4": 1,
        "5": 1,
        "6": 1,
        "7": 1,
      }
    })
  }
  return staffingTargetData.reduce(
    (staffingTargetsTableData: any, staffingTarget: any) => {
      if (staffingTargetsTableData.length < 3) {
        seatTypes.forEach((seatType) => {
          staffingTargetsTableData.push({ name: seatType })
        })
      }

      seatTypes.forEach((seatType) => {
        const tableDataIndex = staffingTargetsTableData.findIndex(
          (staffingTarget: any) => staffingTarget.name === seatType
        )
        staffingTargetsTableData[tableDataIndex][
          staffingTarget.dayid.toString()
        ] = staffingTarget[seatType.toLowerCase()]
      })

      return staffingTargetsTableData
    },
    []
  )
}

export const transformStaffingTargetTableDataToRequestData = (
  staffingTargetTableData: any
) => {
  return staffingTargetTableData.reduce(
    (formattedJobAssignments: any, tableRow: any) => {
      for (let i = 1; i <= 7; i++) {
        const row =
          formattedJobAssignments.length === 7
            ? formattedJobAssignments.find(
                (assignment: any) => assignment.dayid === i.toString()
              )
            : ({} as any)
        row["dayid"] = i.toString()
        row[tableRow.name.toLowerCase()] = tableRow[i]
        if (formattedJobAssignments.length < 7) {
          formattedJobAssignments.push(row)
        }
      }
      return formattedJobAssignments
    },
    []
  )
}

export const mutateStaffingTargetDataToCorrectValues = (
  staffingTargetTableData: any,
  dayId: DayId,
  seatType: SeatType,
  value: number
) => {
  const indexOfSeatType = seatTypes.indexOf(seatType)

  for (let i = 0; i < indexOfSeatType; i++) {
    if (staffingTargetTableData[i][dayId] > value) {
      staffingTargetTableData[i][dayId] = value
    }
  }

  for (let i = indexOfSeatType + 1; i < seatTypes.length; i++) {
    if (staffingTargetTableData[i][dayId] < value) {
      staffingTargetTableData[i][dayId] = value
    }
  }
  staffingTargetTableData[indexOfSeatType][dayId] = value
  return staffingTargetTableData
}
