import React from "react"
import { useSelector } from "@app/models"
import css from "./Footer.module.scss"

export default (() => {
  const userInfo = useSelector((state) => state.users.currentUser)
  const groupId = userInfo.groupid ? userInfo.groupid : "N/A"

  return (
    <div className="">
      <div className="">
        <div className="">
          <div className={css.innerFooter}>
            <p style={{ margin: 0 }}>
              Your Group ID: <span className={css.groupId}>{groupId}</span>.
            </p>
            <p>Copyright &copy; 2024 TigerConnect. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  )
}) as React.FC
