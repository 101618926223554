import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "@app/models"
import Button from "@app/components/Button"
import SharedCalendarView, {
  SharedCalendarViewRow,
} from "../SharedCalendarView"
import CreateOrUpdateBlockSetForm from "./components/CreateOrUpdateBlockSetForm"
import BlockSetRow from "./components/BlockSetRow"
import { useFilter } from "@app/utils/hooks"

type Props = {
  id: string
}

export default ({ id }: Props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [blockSetFormModalShow, setBlockSetFormModalShow] = useState(false)

  const { blockSets } = useSelector((state) => state.blockSets)
  const { filter, filterCacheKey } = useFilter([
    "annualBlockSchedule",
    id,
    "blockSets",
  ])

  useEffect(() => {
    dispatch.blockSets.getBlockSets({ id }).then(() => {
      setLoading(false)
    })
  }, [id])

  const filteredBlockSets = useMemo(
    () => filter(blockSets),
    [blockSets, filterCacheKey]
  )

  let emptyMessage =
    "No block sets have been created for this annual schedule. Please add a block set."

  if (!filteredBlockSets.length && blockSets.length) {
    emptyMessage = "All block sets are currently hidden based on your filter."
  }

  return (
    <>
      <div className="text-right mb-4">
        <Button size="sm" onClick={() => setBlockSetFormModalShow(true)}>
          Add Block Set
        </Button>
        <CreateOrUpdateBlockSetForm
          id={id}
          show={blockSetFormModalShow}
          onHide={setBlockSetFormModalShow}
        />
      </div>

      <SharedCalendarView
        heightExpandable
        id={id}
        loading={loading}
        lists={filteredBlockSets}
        listsTitle="Block Sets"
        renderListItem={(blockSet, context) => (
          <SharedCalendarViewRow.Provider value={context}>
            <BlockSetRow blockSet={blockSet} />
          </SharedCalendarViewRow.Provider>
        )}
        emptyMessage={emptyMessage}
      />
    </>
  )
}
