import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "@app/components/Button"
import Icon from "@app/components/Icon"
import { handleApiError, isIE } from "@app/utils"
import cx from "classnames"
import css from "./JobsTableModal.module.scss"
import { format, parseISO } from "date-fns"
import AddIcon from "@material-ui/icons/Add"
import CheckIcon from "@material-ui/icons/Check"
import AvailableProviders from "../AvailableProviders/AvailableProviders"
import JobsTable from "../JobsTableModal/JobsTable"
import { MultipleAssignmentJob } from "@app/containers/spa/WhiteboardCalendar/data"
import useJobTableForm, {
  FormData,
} from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/jobsTableUseForm"

import NotesModal from "../NotesModal"
import api from "@app/services/api"
import { useDispatch, useSelector, select } from "@app/models"
import useFilterJobs from "../../hooks/useFilterJobs"
import useJobFilterAssignments from "../../hooks/useJobFilterAssignments"
import { transformsDataMultipleAssignmentJobs } from "@app/services/transformDataMultipleAssignment"
import {
  checkProviderIdChanges,
  detectChangeToUnassigned,
  enhanceChangedDataWithJobInfo,
  extractProvidersFromChanges,
  extractJobsFromChanges,
} from "@app/containers/spa/WhiteboardCalendar/component/JobsTableModal/hooks/getProviderChangeNotifications"
import NotifyChangesView from "./components/NotifyChangesViewModal/NotifyChangesView"
import OverlappingShiftError from "../OverlappingShiftError"

import { useForm } from "react-hook-form"

export type ButtonProps = Omit<
  React.ComponentProps<typeof Button>,
  "onClick"
> & {
  text: string
  position?: "left" | "right"
  onClick?: (closeModal: Function) => any
}

// Must pass onHide() to control the show state of parent component
type Props = Omit<React.ComponentProps<typeof Modal>, "onHide"> & {
  onHide: (status?: any) => void
  closeButton?: boolean
  buttons?: ButtonProps[]
  disableBodyScroll?: boolean
  titleAlign?: "left" | "center" | "right"
  escKeyClose?: boolean
  date: string
  onCancel?: () => void
  onSave?: () => void
  isModalDateOpen: boolean
}

export type ModalControlProps = Pick<Props, "show" | "onHide">

// Create Modal Container
let modalJobsContainer = document.querySelector(`div.${css.modalJobsContainer}`)
if (!modalJobsContainer) {
  modalJobsContainer = document.createElement("div")
  modalJobsContainer.classList.add(css.modalJobsContainer, "bootstrap4")
  document.body.appendChild(modalJobsContainer)
}

export default (props: Props) => {
  const {
    title,
    onHide,
    className,
    closeButton = true,
    show = false,
    children,
    buttons = [],
    disableBodyScroll,
    backdrop = "static",
    titleAlign = "left",
    escKeyClose = true,
    date,
    onSave,
    isModalDateOpen,
    ...rest
  } = props

  const [loadings, setLoadings] = useState<Record<number, boolean>>({})
  const [isNotifyActive, setIsNotifyActive] = useState(false)
  const [isTrackActive, setIsTrackActive] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState<number | undefined>()
  const [showAvailableProviders, setShowAvailableProviders] = useState(false)
  const [jobAvailableName, setJobAvailableName] = useState("")
  const [focusedJobId, setFocusedJobId] = useState<number | null>(null)
  const [onSubmitting, setOnSubmitting] = useState<boolean>(false)
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false)
  const [expandedCount, setExpandedCount] = useState(0)
  const [showOverlappingShiftModal, setShowOverlappingShiftModal] =
    useState(false)
  const [overlappingShiftData, setOverlappingShiftData] = useState({})

  const { watch, setValue } = useForm({
    defaultValues: {
      isNotifyActive: false,
      isTrackActive: false,
      notificationConfig: {
        isVisible: false,
        notifyProviders: [],
        notifyJobs: [],
        showProviderOptions: true,
        providersToNotify: {
          assignmentUpdates: false,
          newAssignments: false,
          all: false,
        },
        selectedProviders: [],
        notifyScheduler: false,
        showNotifyEmail: false,
        manualEmails: [],
      },
    },
  })

  const dispatch = useDispatch()
  const notificationConfig = watch("notificationConfig")
  const isMountedRef = useRef(true)

  const {
    control,
    reset,
    fields,
    replace,
    handleSubmit,
    formState: { errors },
  } = useJobTableForm()

  const updateNotificationConfig = (
    key: keyof typeof notificationConfig,
    value: any
  ) => {
    setValue(`notificationConfig.${key}`, value)
  }

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (!isModalDateOpen) {
      reset()
      setIsTrackActive(false)
      setIsNotifyActive(false)
    }
  }, [isModalDateOpen])

  // TODO: Show better validation errors in modal popup
  useEffect(() => {
    console.log("Errors: ", errors)
  }, [errors])

  useEffect(() => {
    if (notificationConfig.isVisible) {
      const filteredProviders = notificationConfig.notifyProviders.filter(
        (provider: { provider_type: string }) =>
          provider.provider_type !== "new-assignment"
      )
      updateNotificationConfig("selectedProviders", [...filteredProviders])
    }
  }, [notificationConfig.notifyProviders])

  const formattedDate = date ? format(parseISO(date), "EEEE, MM/dd/yy") : ""
  const hideModal = useCallback((event?: any) => {
    event?.stopPropagation()
    setExpandedCount(0)
    setOnSubmitting(false)
    onHide()
  }, [])

  const handleShowAvailableProviders = useCallback(
    (jobId: number | undefined, jobName: string) => {
      setSelectedJobId(jobId)
      setShowAvailableProviders(true)
      setJobAvailableName(jobName)
      setFocusedJobId(jobId || null)
    },
    []
  )

  const handleBack = useCallback(() => {
    if (isMountedRef.current) {
      setShowAvailableProviders(false)
      if (focusedJobId !== null) {
        setTimeout(() => {
          const element = document.getElementById(`job-${focusedJobId}`)
          if (element) {
            element.focus()
          }
        }, 0)
      }
    }
  }, [isMountedRef, focusedJobId])

  const onFormSubmit = (data: FormData) => {
    setOnSubmitting(true)
    const changedFields = data.formElements.filter((el) => el.changed)
    api
      .createEventsBatch({
        data: changedFields,
        track_changes: isTrackActive,
        schedule_and_notify: {
          all_providers:
            notificationConfig.showProviderOptions &&
            notificationConfig.providersToNotify.all,
          all_schedulers: notificationConfig.notifyScheduler,
          provider_ids: notificationConfig.showProviderOptions
            ? notificationConfig.selectedProviders.map(
                (provider: { providerid: number }) => provider.providerid
              )
            : [],
          emails: notificationConfig.showNotifyEmail
            ? notificationConfig.manualEmails
            : [],
        },
      })
      .then(({ events, draftEvents }) => {
        hideModal()
        dispatch.calendarEvents.getCalendarData()
      })
      .catch((err) => {
        if (err.data.title === "Overlapping shift error") {
          setOverlappingShiftData(err.data.data)
          setShowOverlappingShiftModal(true)
        } else {
          handleApiError(err)
        }
      })
  }

  const notifyChangesAndUpdateConfig = async (data: FormData) => {
    const { notifyProviders, notifyJobs } = await notifyChangesProcess(data)
    if (notifyProviders.length === 0 || notifyJobs.length === 0) {
      handleSubmit(onFormSubmit)()
      updateNotificationConfig("isVisible", false)
      return
    }
    updateNotificationConfig("isVisible", true)
    updateNotificationConfig("notifyProviders", notifyProviders)
    updateNotificationConfig("notifyJobs", notifyJobs)
  }

  const notifyChangesProcess = async (data: FormData) => {
    const providerIdChanges = checkProviderIdChanges(data.formElements)
    const unassignedChanges = detectChangeToUnassigned(data.formElements)
    const changedData = { providerIdChanges, unassignedChanges }
    const enhancedChangedData = await enhanceChangedDataWithJobInfo(
      changedData,
      jobs
    )
    const notifyProviders = extractProvidersFromChanges(enhancedChangedData)
    const notifyJobs = extractJobsFromChanges(enhancedChangedData).map(
      (job) => ({
        ...job,
        jobid: Number(job.jobid),
      })
    )
    updateNotificationConfig("isVisible", true)
    updateNotificationConfig("notifyProviders", notifyProviders)
    updateNotificationConfig("notifyJobs", notifyJobs)

    return { notifyProviders, notifyJobs }
  }

  const handleButtonClick = (
    onClick: ((cb: Function) => any) | undefined,
    buttonIndex: number
  ) => {
    const result = onClick?.(hideModal)

    if (result instanceof Promise) {
      // Disable the button until promise resolved
      setLoadings({ ...loadings, [buttonIndex]: true })
      result.finally(() => {
        if (isMountedRef.current) {
          setLoadings({ ...loadings, [buttonIndex]: false })
          hideModal()
        }
      })
    } else if (result !== false) {
      hideModal()
    }
  }

  const handleClick = async () => {
    if (isMountedRef.current) {
      if (isNotifyActive) {
        await handleSubmit(notifyChangesAndUpdateConfig)()
      } else {
        handleSubmit(onFormSubmit)()
      }
    }
  }

  const handleCancel = () => {
    props.onCancel?.()
    if (isMountedRef.current) {
      setExpandedCount(0)
    }
  }

  const onOpenNoteModal = () => {
    setIsAddNoteModalOpen(true)
  }

  const onCloseNoteModal = () => {
    setIsAddNoteModalOpen(false)
  }

  const jobsFilteredAssignments = useJobFilterAssignments(date)
  const filteredJobs = useFilterJobs()
  const { flags } = useSelector(select.calendarEvents.filteredCalendarData)

  const jobs = useMemo(
    () =>
      (date
        ? transformsDataMultipleAssignmentJobs(
            jobsFilteredAssignments,
            flags,
            filteredJobs,
            date
          )
        : []) as unknown as MultipleAssignmentJob[],
    [jobsFilteredAssignments, flags, date, filteredJobs]
  )

  if (isAddNoteModalOpen) {
    return (
      <NotesModal
        date={formattedDate}
        isModalOpen={isAddNoteModalOpen}
        onCloseModal={onCloseNoteModal}
      />
    )
  }

  return (
    <Modal
      centered
      animation={false}
      show={isModalDateOpen || showAvailableProviders}
      container={modalJobsContainer as any}
      className={
        !notificationConfig.isVisible
          ? cx(css.modal, className)
          : cx(css.modalNotify, className)
      }
      backdropClassName={css.backdrop}
      backdrop={backdrop}
      onEscapeKeyDown={() => escKeyClose && hideModal()}
      size={notificationConfig.isVisible ? "sm" : "xl"}
      style={{ zIndex: 1045 }}
      {...rest}
    >
      <Modal.Header className={css.header}>
        <div
          className={cx(css.headerLeft, {
            [css.headerLeftProvidersView]: showAvailableProviders,
          })}
        >
          <Modal.Title className={`text-${titleAlign} ${css.modalTitle}`}>
            <div>
              {!showAvailableProviders && !notificationConfig.isVisible
                ? "Manual Scheduling"
                : jobAvailableName}
              {notificationConfig.isVisible && "Notify of Changes"}
            </div>
            {!notificationConfig.isVisible && (
              <>
                <div className={css.modalSubtitleDivider}>|</div>
                <h3 className={css.modalSubtitle}>{formattedDate}</h3>
              </>
            )}
          </Modal.Title>

          {!showAvailableProviders && !notificationConfig.isVisible && (
            <button
              className={css.headerAddNoteButton}
              onClick={onOpenNoteModal}
            >
              <AddIcon className={css.headerAddNoteButtonIcon} />
              <span>Add Note</span>
            </button>
          )}
        </div>

        <div className={css.headerRight}>
          {closeButton && (
            <Icon
              name="close"
              className={css.closeIcon}
              onClick={showAvailableProviders ? handleBack : hideModal}
            />
          )}
          {!notificationConfig.isVisible && (
            <div className={css.buttonContainer}>
              {showAvailableProviders ? (
                <Button
                  variant="primary"
                  className={cx(css.customButton, css.saveButton)}
                  onClick={handleBack}
                >
                  Back
                </Button>
              ) : (
                <>
                  <button
                    className={css.checkboxContainer}
                    onClick={() => setIsNotifyActive(!isNotifyActive)}
                  >
                    <div className={css.checkIconContainer}>
                      {isNotifyActive && (
                        <CheckIcon className={css.checkIcon} />
                      )}
                    </div>
                    <div>Notify of Changes</div>
                  </button>
                  <button
                    className={css.checkboxContainer}
                    onClick={() => setIsTrackActive(!isTrackActive)}
                  >
                    <div className={css.checkIconContainer}>
                      {isTrackActive && <CheckIcon className={css.checkIcon} />}
                    </div>
                    <div>Track Changes</div>
                  </button>

                  <Button
                    variant="outline-primary"
                    className={`${css.customButton} ${css.cancelButton}`}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className={`${css.customButton} ${css.saveButton}`}
                    onClick={handleClick}
                    disabled={expandedCount > 0 || onSubmitting}
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body
        style={{
          overflow: disableBodyScroll
            ? isIE
              ? "visible"
              : "unset"
            : undefined,
        }}
      >
        {notificationConfig.isVisible ? (
          <NotifyChangesView
            notificationConfig={notificationConfig}
            updateNotificationConfig={(key, value) =>
              setValue(`notificationConfig.${key}`, value)
            }
            date={date}
          />
        ) : showAvailableProviders && selectedJobId ? (
          <AvailableProviders jobId={selectedJobId} edate={date} />
        ) : (
          <JobsTable
            data={jobs as unknown as MultipleAssignmentJob[]}
            date={date}
            onShowAvailableProviders={handleShowAvailableProviders}
            focusedJobId={focusedJobId}
            control={control}
            replace={replace}
            fields={fields}
            setExpandedCount={setExpandedCount}
          />
        )}
        <OverlappingShiftError
          onHide={() => {
            setShowOverlappingShiftModal(false)
            setOnSubmitting(false)
          }}
          show={showOverlappingShiftModal}
          overlappingShiftData={overlappingShiftData}
        />
      </Modal.Body>
      <Modal.Footer>
        {buttons.map((buttonProps, buttonIndex) => {
          const {
            text,
            onClick,
            disabled,
            position,
            className: btnClassName,
            ...restButtonProps
          } = buttonProps

          return (
            <Button
              key={text}
              disabled={disabled || loadings[buttonIndex]}
              onClick={() => handleButtonClick(onClick, buttonIndex)}
              className={cx(btnClassName, { "pull-left": position === "left" })}
              {...restButtonProps}
            >
              {text}
            </Button>
          )
        })}
        {notificationConfig.isVisible && (
          <>
            <Button
              variant="outline-primary"
              className={`${css.customButton} ${css.cancelButton}`}
              onClick={() => {
                updateNotificationConfig("isVisible", false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className={`${css.customButton} ${css.saveButton}`}
              onClick={() => {
                handleSubmit(onFormSubmit)()
              }}
              disabled={expandedCount > 0 || onSubmitting}
            >
              Save
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}
