import React from "react"
import { Select } from "@app/components/Form"
import { H5 } from "@app/components/Typography"
import api, { useRequest } from "@app/services/api"
import SharedFilter from "./SharedFilter"
import { useFilter } from "@app/utils/hooks"

type Props = {
  annualBlockScheduleId: string
}

export default (props: Props) => {
  const { annualBlockScheduleId: id } = props
  const {
    filterState: { values },
    enabled,
    clear,
    update,
  } = useFilter(["annualBlockSchedule", id, "rotations"])

  const { data: annualRotations = [] } = useRequest<
    AnnualBlockScheduleRotationType[]
  >([api.getAnnualBlockScheduleRotations, id])

  return (
    <SharedFilter
      filterName="Rotations"
      filterEnabled={enabled}
      filterValuesCount={values.length}
      onFilterClean={clear}
    >
      <Select
        inline
        multiple
        options={annualRotations}
        labelKey="block_schedule_rotation.name"
        value={values}
        renderOption={(text, item) => (
          <H5 ellipsis prefixDotColor={item.block_schedule_rotation.color}>
            {text}
          </H5>
        )}
        onChange={(val) => update({ values: val })}
      />
    </SharedFilter>
  )
}
