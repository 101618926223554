import React, { useRef } from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import BsPopover from "react-bootstrap/Popover"
import { H5 } from "@app/components/Typography"
import { randomID } from "@app/utils"
import cx from "classnames"
import css from "./Popover.module.scss"

type Props = Omit<React.ComponentProps<typeof OverlayTrigger>, "overlay"> & {
  title?: string | React.ReactNode
  content: string | React.ReactNode
  className?: string
}

export default (props: Props) => {
  const {
    title,
    content,
    trigger = ["hover", "focus"],
    placement = "top",
    rootClose = true,
    className,
    children,
    ...rest
  } = props

  const popoverId = useRef(randomID({ prefix: "popover" }))

  return (
    <OverlayTrigger
      {...rest}
      rootClose={rootClose}
      placement={placement}
      trigger={trigger}
      overlay={(overlayProps) => (
        <div className={cx("bootstrap4", css.popoverContainer)}>
          <BsPopover
            {...overlayProps}
            id={popoverId.current}
            className={cx(css.popover, className)}
          >
            {title && (
              <BsPopover.Title>
                <H5 ellipsis bold="medium" variant="blue">
                  {title}
                </H5>
              </BsPopover.Title>
            )}
            <BsPopover.Content>{content}</BsPopover.Content>
          </BsPopover>
        </div>
      )}
    >
      {children}
    </OverlayTrigger>
  )
}
