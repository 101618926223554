import React, { useCallback, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import css from "./AssignmentSingleJobElement.module.scss"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { useSelector, useDispatch } from "@app/models"
import { AssignmentDetails } from "../AssignmentDetails"
import { getIsSingleAssignmentHighlighted } from "../utils/getIsSingleAssignmentHighlighted"
import {
  getIsAssignmentFiltered,
  getProviderIds,
} from "../utils/getIsAssignmentFiltered"
import CustomTooltip from "../Tooltip"
import api from "@app/services/api"
import { ViolationQuickAssignment } from "@app/containers/spa/WhiteboardCalendar/data"
import { handleApiError } from "@app/utils"
import { useJobTimes } from "../../../CalendarGridView/hooks/useJobTime"

interface Props {
  assignment: GroupedAssignment
  showModal: () => void
  isJobSelectedInQuickAssignMode: (assignment: GroupedAssignment) => boolean
  isAssignmentUnderstaffed: boolean | undefined
  getAssignmentUnderstaffedText: () => string
  quickMode?: boolean
  handleDeleteQuickAssign?: (assignment: GroupedAssignment) => void
  isHighlightedChangesAssigment?: boolean
}

export const AssignmentSingleJobElement = ({
  assignment,
  showModal,
  isJobSelectedInQuickAssignMode,
  quickMode,
  handleDeleteQuickAssign,
  isAssignmentUnderstaffed,
  getAssignmentUnderstaffedText,
  isHighlightedChangesAssigment,
}: Props) => {
  const {
    highlightOptions: { highlightProviders },
    filterOptions: {
      providersFilters: { providerIds },
    },
    isUnderstaffedJobsHighlightActive,
    isHighlightedChangesActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)
  const dispatch = useDispatch()

  const { calendarConfig } = useSelector((state) => state.calendarEvents)
  const { selectedProvider } = calendarConfig
  const { jobStartTime, jobEndTime } = useJobTimes(
    assignment.jobid,
    assignment.edate
  )

  const [canAddAssignment, setCanAddAssignment] = useState(false)
  const [jobSettings, setJobSettings] = useState<{ maximum: number } | null>(
    null
  )
  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const isAssignmentHighlighted = getIsSingleAssignmentHighlighted(
    assignment,
    highlightProviders
  )

  const isProviderFilterActive = useMemo(
    () => Boolean(providerIds),
    [providerIds]
  )

  const isAssignmentFiltered = getIsAssignmentFiltered(
    assignment.provider?.providerid,
    providerIds
  )

  useEffect(() => {
    let isMounted = true
    const fetchJobSettingsAndValidate = async () => {
      if (selectedProvider && isMounted && !jobSettings) {
        try {
          const response = await api.getJobMultipleAssignmentSettings(
            assignment.jobid,
            assignment.edate
          )
          setJobSettings(response)
          const currentAssignedCount =
            assignment.additional_event_assignments?.length + 1 || 1
          setCanAddAssignment(response.maximum > currentAssignedCount)
        } catch (error) {
          handleApiError
        }
      }
    }

    fetchJobSettingsAndValidate()

    return () => {
      isMounted = false
    }
  }, [selectedProvider, assignment, jobSettings])

  const assignmentClasses = cx(css.assignmentSingleElementWrapper, {
    [css.disabledCellJob]:
      (isHighlightedChangesActive && !isHighlightedChangesAssigment) ||
      (!isJobSelectedInQuickAssignMode(assignment) &&
        !(
          quickMode &&
          assignment.job?.multiple_assignments &&
          canAddAssignment
        )),
    [css.highlightedAssignment]:
      (isHighlightedChangesActive && isHighlightedChangesAssigment) ||
      isAssignmentHighlighted ||
      isHighlightedChangesAssigment,
    [css.highlightedAssignmentDraft]:
      (isHighlightedChangesActive && isHighlightedChangesAssigment) ||
      isAssignmentHighlighted ||
      isHighlightedChangesAssigment ||
      assignment?.draft_eventid,
    [css.fadedAssignmentWrapper]:
      (isHighlightedChangesActive && !isHighlightedChangesAssigment) ||
      ((providersAreHighlighted || isUnderstaffedJobsHighlightActive) &&
        !isAssignmentHighlighted),
    [css.fadedDraftAssignmentWrapper]:
      (isHighlightedChangesActive && !isHighlightedChangesAssigment) ||
      ((providersAreHighlighted || isUnderstaffedJobsHighlightActive) &&
        !isAssignmentHighlighted &&
        assignment.draft_eventid),
  })

  const handleClick = useCallback(async () => {
    if (!quickMode) {
      showModal()
      return
    }

    const { job, draft_eventid } = assignment
    if (!job || !job.multiple_assignments) {
      if (selectedProvider?.providerid === assignment?.providerid) {
        handleDeleteQuickAssign?.(assignment)
      }
      return
    }

    const providerIds = getProviderIds(assignment)
    const updateFunction = draft_eventid
      ? api.updateDraftMultipleAssignments
      : api.updateMultipleAssignments
    const assignments = Array.from(
      new Set([...providerIds, Number(selectedProvider?.providerid)])
    ).filter((id) => id !== null)

    try {
      if (assignments.length === 1) {
        handleDeleteQuickAssign?.(assignment)
      } else {
        if (selectedProvider && jobSettings && canAddAssignment) {
          const response = await updateFunction(
            assignment.jobid,
            assignment.edate,
            assignments
          )
          const assignmentResponse = Array.isArray(response)
            ? response[0]
            : response

          dispatch.quickAssignments.addQuickAssignment(
            assignmentResponse as ViolationQuickAssignment
          )
        }
      }
    } catch (error) {
      handleApiError
    }
  }, [quickMode, assignment])

  return (
    <button
      className={assignmentClasses}
      onClick={handleClick}
      disabled={
        isHighlightedChangesActive &&
        !isHighlightedChangesAssigment &&
        !isJobSelectedInQuickAssignMode(assignment) &&
        !(quickMode && assignment.job?.multiple_assignments)
      }
    >
      {isProviderFilterActive && isAssignmentFiltered ? (
        <CustomTooltip
          arrow
          title={
            <div>
              <AssignmentDetails
                isDraft={Boolean(assignment.draft_eventid)}
                providerColor={assignment.provider?.provider_color}
                providerName={assignment.provider?.display_name}
                providerJob={{
                  ...assignment.job,
                  starttime: jobStartTime,
                  endtime: jobEndTime,
                }}
              />
            </div>
          }
        >
          <span className={css.filteredText}>Filtered</span>
        </CustomTooltip>
      ) : (
        <div>
          <AssignmentDetails
            isDraft={Boolean(assignment.draft_eventid)}
            providerColor={assignment.provider?.provider_color}
            providerName={assignment.provider?.display_name}
            providerJob={{
              ...assignment.job,
              starttime: jobStartTime,
              endtime: jobEndTime,
            }}
          />
        </div>
      )}

      {isUnderstaffedJobsHighlightActive && isAssignmentUnderstaffed && (
        <div className={css.understaffedCounter}>
          {getAssignmentUnderstaffedText()}
        </div>
      )}
    </button>
  )
}
