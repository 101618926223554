import React, { useCallback, useMemo, useState } from "react"
import api from "@app/services/api"
import Button from "@app/components/Button"
import Avatar from "@app/components/Avatar"
import useRequest from "@app/utils/request"
import { Select } from "@app/components/Form"
import { H5 } from "@app/components/Typography"
import CheckIcon from "@material-ui/icons/Check"
import { useDispatch, useSelector } from "@app/models"
import { DefaultCalendarColor } from "@app/utils/constants"
import css from "./ProvidersMenu.module.scss"
import {
  Provider as CalendarProvider,
  ProviderType,
} from "@app/containers/spa/WhiteboardCalendar/data"
import SelectedProviderMenu from "./SelectedProviderMenu"

interface ProvidersMenuProps {
  providersData: CalendarProvider[]
}

export const ProvidersMenu = ({ providersData }: ProvidersMenuProps) => {
  const [providerSubFilters, setProviderSubFilters] = useState<number[]>([])

  const providerTypesRequest = useMemo(() => [api.getProviderTypes], [])
  const { data: providerTypes } = useRequest(providerTypesRequest)

  const dispatch = useDispatch()

  const { selectedProvider } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const filteredData = useMemo(
    () =>
      providerSubFilters.length > 0
        ? providersData.filter((item) =>
            providerSubFilters?.includes(item.provider_typeid)
          )
        : providersData,
    [providersData, providerSubFilters]
  )

  const providerTypeOptions = useMemo(
    () =>
      providerTypes?.map((providerType: ProviderType) => ({
        id: providerType.provider_typeid,
        name: providerType.type,
      })),
    [providerTypes]
  )

  const handleProviderSubFilterList = useCallback(
    (selectedTypeIds: number[]) => {
      setProviderSubFilters(selectedTypeIds)
    },
    []
  )

  const handleCancel = () => {
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.calendarEvents.setSelectedJob(undefined)
    dispatch.quickAssignments.resetQuickAssignments()
  }

  const onSelectProvider = (provider: CalendarProvider) => {
    const isSelected = selectedProvider?.providerid === provider.providerid
    if (isSelected) {
      dispatch.calendarEvents.setSelectedProvider(undefined)
    } else {
      dispatch.calendarEvents.setSelectedProvider(provider)
    }
  }

  if (selectedProvider && selectedProvider?.providerid) {
    return <SelectedProviderMenu />
  }

  return (
    <div className={css.quickModalBodyContent}>
      <p className={css.quickModalDescription}>
        Select a Provider for Quick Assign Mode
      </p>
      <Select
        multiple
        value={providerSubFilters}
        options={providerTypeOptions}
        onChange={handleProviderSubFilterList}
        placeholder="All Providers Types"
        className={css.selectMultiProvider}
      />
      <div className={css.quickModalContainer}>
        <ul className={css.unorderedListContainer}>
          {filteredData.length === 0 && <div>No providers found</div>}
          {filteredData.map((provider) => (
            <li
              key={provider.providerid}
              className={css.customOption}
              onClick={() => onSelectProvider(provider)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  size={25}
                  src={provider.avatar ? provider.avatar : "/images/user.svg"}
                  className="mr-3"
                />
                <div
                  className={css.providerCellCircle}
                  style={{
                    background: provider.provider_color || DefaultCalendarColor,
                  }}
                ></div>
                <H5 ellipsis>{provider.display_name}</H5>
              </div>
              <div
                className={`${css.checkboxContainer} ${
                  selectedProvider?.providerid === provider.providerid
                    ? css.checkedContainer
                    : ""
                }`}
              >
                {selectedProvider?.providerid === provider.providerid ? (
                  <CheckIcon className={css.checkIcon} />
                ) : (
                  <div className={css.uncheckedIcon}></div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={css.buttonContainer}>
        <Button shape="pill" variant="light" size="sm" onClick={handleCancel}>
          Clear
        </Button>
      </div>
    </div>
  )
}
