import React, { useEffect, useState } from "react"
import { Dialog } from "@app/components/Modal"
import BaseLayout from "@app/containers/layouts/Base"
import Card from "@app/components/Card"
import Loader from "@app/components/Loader"
import Button from "@app/components/Button"
import api from "@app/services/api"
import { formatDate } from "@app/utils"
import css from "./DraftModeCtl.module.scss"
import { parseISO } from "date-fns"

type DraftModeForm = {
  draft_mode_enabled: boolean
  provider_message: string
  scheduler_message: string
}

const DraftModeCtl: React.FC<any> = (props): JSX.Element => {
  const [showLoader, setShowLoader] = useState(true)
  const [formMessage, setFormMessage] = useState("")
  const [formErrors, setFormErrors] = useState([] as any)
  const [formChanged, setFormChanged] = useState(false)
  const [settings, setDraftModeSettings] = useState({} as DraftModeForm)
  const [formValues, setFormValues] = useState({} as DraftModeForm)

  useEffect(() => {
    api.getDraftModeSettings().then(function (response) {
      let dm = response.draft_mode_settings
      setDraftModeSettings({
        ...settings,
        draft_mode_enabled: dm.draft_mode_enabled == 1 ? true : false,
        provider_message: dm.provider_message,
        scheduler_message: dm.scheduler_message,
      })
      setFormValues({
        ...formValues,
        draft_mode_enabled: dm.draft_mode_enabled == 1 ? true : false,
        provider_message: dm.provider_message,
        scheduler_message: dm.scheduler_message,
      })

      setShowLoader(false)
    })
  }, [])

  const handleSubmit = (e: any) => {
    setShowLoader(true)
    e.preventDefault()
    e.stopPropagation()
    let target = e.target

    let providerMessage = settings.provider_message
    let schedulerMessage = settings.scheduler_message
    if (formValues.draft_mode_enabled) {
      providerMessage = target.provider_message.value
      schedulerMessage = target.scheduler_message.value

      let validateProviderErrors = validateTemplate(
        "Provider Notification",
        target.provider_message.value
      )
      let validateSchedulerErrors = validateTemplate(
        "Scheduler Notification",
        target.scheduler_message.value
      )

      if (
        validateProviderErrors.length > 0 ||
        validateSchedulerErrors.length > 0
      ) {
        var allErrors = [] as any
        if (validateProviderErrors.length > 0) {
          allErrors = validateProviderErrors
        }
        if (validateSchedulerErrors.length > 0) {
          allErrors = allErrors.concat(validateSchedulerErrors)
        }

        setFormErrors(allErrors)
        setShowLoader(false)
        return
      } else {
        setFormErrors([])
      }
    }

    api
      .updateDraftModeSettings(
        formValues.draft_mode_enabled,
        providerMessage,
        schedulerMessage
      )
      .then(function (response) {
        if (response.show_warning) {
          formValues.draft_mode_enabled = true
          let startDate = parseISO(response.start_date)
          let endDate = parseISO(response.end_date)
          Dialog.warn({
            title: "Notice",
            message: (
              <>
                <p>
                  There are {response.count} total Draft Mode events in this
                  group beginning on
                  {formatDate(startDate, (f) => " MMMM do, Y")} and ending on{" "}
                  {formatDate(endDate, (f) => " MMMM do, Y")}.
                </p>
                <p>
                  You must publish or delete all pending Draft Mode events in
                  this group before disabling this setting.
                </p>
              </>
            ),
          })
        } else {
          //if we're enabling/disabling draft-mode we need to reflect it by showing/hiding menu items
          //for now the only way to do this is to reload the window.
          if (settings.draft_mode_enabled != formValues.draft_mode_enabled) {
            window.location.reload()
          }
          setFormMessage("Draft Mode settings saved.")
        }
        setDraftModeSettings({
          ...settings,
          draft_mode_enabled: formValues.draft_mode_enabled,
          provider_message: providerMessage,
          scheduler_message: schedulerMessage,
        })
        setShowLoader(false)
        setFormChanged(false)
      })
  }

  const handleFormChange = (e: any) => {
    if (e.target.name == "draft_mode") {
      setFormValues({
        ...formValues,
        draft_mode_enabled: e.target.value == "on" ? true : false,
      })
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }
    setFormChanged(true)
    setFormMessage("")
  }

  const resetForm = (e: any) => {
    setFormValues(settings)
  }

  const validateTemplate = (parameter: string, templateMessage: string) => {
    var validTags = [
      "[provider_first_name]",
      "[provider_last_name]",
      "[scheduler_first_name]",
      "[scheduler_last_name]",
      "[start_date]",
      "[end_date]",
      "[group_name]",
    ]
    var errors = [] as any
    var message_lines = []
    var brackets = /(\[.*?\])/g
    var provider_message = templateMessage
    message_lines = String(provider_message).split("\n")
    for (let x = 0; x < message_lines.length; x++) {
      let match = message_lines[x].match(brackets)
      if (Array.isArray(match)) {
        for (let y = 0; y < match.length; y++) {
          if (match[y].match(/\s/)) {
            //check for spaces inside bracket pair
            errors.push(
              parameter +
                " Line " +
                (x + 1) +
                ': No spaces are allowed within tags - "' +
                match[y] +
                '"'
            )
            continue
          }
          if (validTags.indexOf(match[y]) == -1) {
            errors.push(
              parameter +
                " Line " +
                (x + 1) +
                ': Invalid tag found "' +
                match[y] +
                '"'
            )
          }
        }
      }
    }

    return errors
  }

  if (showLoader == true) {
    return (
      <BaseLayout containerSize="fluid" className="mt-3">
        <Card>
          <div className="w-100 p-3 d-flex justify-content-center">
            <Loader animation="dots" />
          </div>
        </Card>
      </BaseLayout>
    )
  }

  return (
    <div className="row">
      <div className="col-xs-8 col-xs-offset-2 main-content">
        <div className="row">
          <div className="col-xs-12">
            <h1>Draft Mode</h1>
            {String(formMessage).length > 0 && (
              <div className={css.formMessage}>{formMessage}</div>
            )}
            {formErrors.length > 0 && (
              <div className={css.errorMessage}>
                <h3>Form Errors:</h3>
                <ul>
                  {formErrors.map((err: String, index: number) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="row">
            <div className="col-xs-4">
              <h3>
                <strong>Draft Mode</strong>
              </h3>
            </div>
            <div className="col-xs-4">
              <h3>
                <label>
                  <input
                    type="radio"
                    name="draft_mode"
                    value="on"
                    onChange={handleFormChange}
                    checked={formValues.draft_mode_enabled}
                  />{" "}
                  On{" "}
                </label>{" "}
                &nbsp;
                <label>
                  <input
                    type="radio"
                    name="draft_mode"
                    value="off"
                    onChange={handleFormChange}
                    checked={!formValues.draft_mode_enabled}
                  />{" "}
                  Off
                </label>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <p>
                When enabled, all future assignments are hidden by default from
                the website, mobile app, and microsite until they are manually
                published.
              </p>
            </div>
          </div>

          {formValues.draft_mode_enabled === true && (
            <>
              <div className="row">
                <div className="col-xs-4">
                  <h3>
                    <strong>Provider Notification Message</strong>
                  </h3>
                  <p>
                    Send a message to providers when assignments are published.
                    (Leave blank for no message)
                  </p>
                </div>
                <div className="col-xs-5">
                  <div className={css.textArea}>
                    <textarea
                      className={css.textArea}
                      name="provider_message"
                      onChange={handleFormChange}
                      value={formValues.provider_message}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-4">
                  <h3>
                    <strong>Scheduler Notification Message</strong>
                  </h3>
                  <p>
                    Send a message to schedulers when assignments are published.
                    (Leave blank for no message)
                  </p>
                </div>
                <div className="col-xs-5">
                  <div className={css.textArea}>
                    <textarea
                      className={css.textArea}
                      name="scheduler_message"
                      onChange={handleFormChange}
                      value={formValues.scheduler_message}
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row">
            <div className="col-xs-4">
              <Button
                className="btn btn-light"
                onClick={resetForm}
                disabled={formChanged ? false : true}
              >
                Reset
              </Button>{" "}
              <Button
                className="btn btn-primary"
                type="submit"
                disabled={formChanged ? false : true}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DraftModeCtl
