import React, { useEffect, useState } from "react"
import { useSelector } from "@app/models"
import MenuItem from "./components/MenuItem"
import css from "./Menu.module.scss"
import ChangeRole, { CurrentRole } from "../change-role/ChangeRole"
import Modal from "@app/components/Modal"
import api from "@app/services/api"
import { Role } from "@app/services/Role"
import { handleApiError } from "@app/utils"

interface ISubMenuItem {
  name: string
  link: string
  subMenu?: ISubMenuItem[]
}

interface IMenuItem {
  name: string
  link: string
  subMenu?: ISubMenuItem[]
}

interface IApiMenuItem {
  name: string
  link: string | null
  children?: IApiMenuItem[]
}

export default (() => {
  const userInfo = useSelector((state) => state.users.currentUser)
  const [userImage, setUserImage] = useState<string | null>(null)
  const [logoImage, setLogoImage] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [menuData, setMenuData] = useState<IMenuItem[]>([])
  const [selectedEntityRoleId, setSelectedEntityRoleId] = useState<string>("")
  const [selectedClinicId, setSelectedClinicId] = useState<number>(0)

  const [changeRolesAdjusted, setChangeRolesAdjusted] = useState({
    currentRole: {} as CurrentRole,
    roles: [{}].map((role) => new Role(role)),
  })

  const showModal = () => {
    setIsModalOpen(true)
  }

  const hideModal = () => {
    setIsModalOpen(false)
  }

  const changeGroup = () => {
    hideModal()
    api
      .postChangeRoles(selectedEntityRoleId, selectedClinicId)
      .then((response) => {
        window.location.href = "/menu.cgi"
      }, handleApiError)
  }

  const adaptMenuItems = (apiData: IApiMenuItem[]): IMenuItem[] => {
    return apiData.map((item) => ({
      name: item.name,
      link: item.link || "",
      subMenu: item.children ? adaptMenuItems(item.children) : [],
    }))
  }

  useEffect(() => {
    api.getMenu().then((response) => {
      const adaptedMenuItems: IMenuItem[] = adaptMenuItems(response)
      setMenuData(adaptedMenuItems)
    })
  }, [])

  useEffect(() => {
    if (userInfo) {
      const { block_scheduling, classic_algorithm, classic_rules } = userInfo
      // Determine the logo image based on the conditions
      let logoImage = ""
      if (classic_algorithm || classic_rules) {
        if (block_scheduling) {
          logoImage = "/images/tc_resident_scheduling-logo.png"
        } else {
          logoImage = "/images/tc_physician_scheduling-logo.svg"
        }
      } else {
        logoImage = "/images/tc_physician_scheduling-logo.svg"
      }

      setLogoImage(logoImage)
      setUserImage(userInfo.tigerconnect_avatar || null)

      setChangeRolesAdjusted((prev) => ({
        ...prev,
        currentRole: {
          roleid: userInfo.current_role?.roleid,
          entityid: userInfo.current_role?.entityid,
          groupid: userInfo.groupid,
          clinicid: userInfo.clinicid,
        },
      }))
    }
  }, [userInfo])

  useEffect(() => {
    api.getChangeRoles().then((response: any) => {
      const roles: { [index: string]: Role[] } = JSON.parse(
        JSON.stringify(response)
      )
      const clinics: string[] = Object.keys(roles)
      const allRoles: Role[] = []
      clinics.forEach((clinic) => {
        const availableRoles: Role[] = roles[clinic].map((role) =>
          role instanceof Role ? role : new Role(role)
        )
        availableRoles.forEach((role) => {
          role.clinicid = parseInt(clinic, 10)
        })
        allRoles.push(...availableRoles)
      })
      setChangeRolesAdjusted((prev) => ({
        ...prev,
        roles: allRoles,
      }))
    })
  }, [])

  const logout = () => {
    window.location.href = "/user/logout.cgi"
  }

  const clinicName = userInfo.clinic ? userInfo.clinic.name : "N/A"
  const groupName = userInfo.group ? userInfo.group.name : "N/A"
  const customerID = userInfo.clinicid ? userInfo.clinicid + 1000 : "N/A"

  return (
    <div className="">
      <div id="header" className="clearfix">
        <div className="inner">
          <a
            className="site-name classic col-xs-12 tc_resident_scheduling-logo"
            href="#"
          >
            {logoImage && <img src={logoImage} alt="Logo" />}
          </a>
          <div id="utility">
            <div className="text-wrapper">
              <div className="welcome">
                Welcome,{" "}
                <strong>{userInfo.username && userInfo.username}</strong>
              </div>
              <div className="links clearfix">
                {userInfo && userInfo.has_multiple_roles > 0 && (
                  <button
                    id="change-role"
                    onClick={showModal}
                    className={css.changeRole}
                  >
                    [ Change Group ]{" "}
                  </button>
                )}

                <a className="logout" onClick={logout}>
                  <span>
                    <span className={css.logout}>Logout</span>
                    <img
                      className="logout-image"
                      src="/images/logout.svg"
                      alt="Logout"
                    />
                  </span>
                </a>
              </div>
              <div className="user">
                Clinic: {clinicName} • Group: {groupName} • Customer ID:{" "}
                {customerID}
              </div>
            </div>
            <div className="utility-image">
              <img
                id="tigerconnect_avatar"
                src={userImage || "/images/user.svg"}
                alt="User icon"
                className="hidden-xs"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className={`row nav-color ${css.navColor}`}>
          <div className="col-lg-8 col-lg-push-1 col-xs-10 col-sm-10">
            <div className={`nav-main ${css.navMain}`}>
              <ul id="nav-main" className={css.navMainList}>
                {menuData.map((item, index) => (
                  <MenuItem key={index} item={item} />
                ))}
              </ul>
            </div>
          </div>
          <div className={`help-wrapper ${css.helpWrapper}`}>
            <div className="help-icon">
              <img src="/images/questionmark.svg" alt="Question Mark" />
            </div>
            <a className="help" id="help-link">
              Help
            </a>
          </div>
        </div>
      </div>
      <Modal
        centered={false}
        show={isModalOpen}
        onHide={hideModal}
        title="Select Organization and Group "
        closeButton={true}
        style={{ color: "#42A7C6", marginTop: "10%" }}
        titleAlign="left"
      >
        <ChangeRole
          showButtons={false}
          showTitle={false}
          currentRole={changeRolesAdjusted.currentRole}
          roles={changeRolesAdjusted.roles}
          selectEntityRoleId={setSelectedEntityRoleId}
          selectClinicId={setSelectedClinicId}
        />
        <div className="row padding-top">
          <div className="col-xs-11 offset-xs-1">
            <button className="btn btn-secondary btn-lg" onClick={hideModal}>
              Close
            </button>
            <button className="btn btn-primary btn-lg" onClick={changeGroup}>
              Change Group
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}) as React.FC
