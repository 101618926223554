import React from "react"
import { useSelector } from "@app/models"
import BaseLayout from "@app/containers/layouts/Base"
import { H2 } from "@app/components/Typography"
import JobsList from "./components/JobsList"
import { useEnabledJobTypes } from "@app/services/jobHelper"

export default () => {
  const { group: currentGroup } = useSelector(
    (state) => state.users.currentUser
  )

  const enabledJobTypes = useEnabledJobTypes()

  // Wait for CurrentUser data loading over
  if (!currentGroup) {
    return null
  }

  return (
    <BaseLayout>
      <H2 className="pl-5 mb-4">
        {currentGroup && (
          <>
            <span className="font-weight-bold">{currentGroup.name}</span>
            <span> - </span>
          </>
        )}
        <span>Jobs</span>
      </H2>
      {enabledJobTypes.map((jobType) => (
        <JobsList
          key={jobType.id}
          jobTypeId={jobType.id}
          jobTypeName={jobType.name}
        />
      ))}
    </BaseLayout>
  )
}
