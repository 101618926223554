import React from "react"
import SwapVertIcon from "@material-ui/icons/SwapVert"
import CustomTableSelect from "./CustomTableSelect"
import css from "./FilterHeader.module.scss"
import { MultipleAssignmentJob } from "@app/containers/spa/WhiteboardCalendar/data"
import cx from "classnames"

interface FilterHeaderProps {
  requestSort: (key: keyof MultipleAssignmentJob) => void
  headerKeyMap: { [key: string]: keyof MultipleAssignmentJob }
  sortConfig: {
    key: keyof MultipleAssignmentJob | null
    direction: "ascending" | "descending"
  }
  uniqueTypes: string[]
  uniqueStatuses: string[]
  typeFilter: string
  setTypeFilter: React.Dispatch<React.SetStateAction<string>>
  statusFilter: string
  setStatusFilter: React.Dispatch<React.SetStateAction<string>>
}

const FilterHeader: React.FC<FilterHeaderProps> = ({
  requestSort,
  headerKeyMap,
  sortConfig,
  uniqueTypes,
  uniqueStatuses,
  typeFilter,
  setTypeFilter,
  statusFilter,
  setStatusFilter,
}) => {
  return (
    <>
      <th
        className={cx(css.header, css.jobHeader)}
        onClick={() => requestSort("job")}
      >
        <div className={css.jobWrapper}>
          <div>Job</div>
          <SwapVertIcon />
        </div>
      </th>
      <th className={cx(css.header, css.providersHeader)}>
        <div>{`Provider(s)`}</div>
      </th>
      <th
        className={cx(css.header, css.shiftHeader)}
        onClick={() => requestSort("unParsedShiftTime")}
      >
        <div className={css.shiftWrapper}>
          <div>Shift Time</div>
          <SwapVertIcon />
        </div>
      </th>
      <th className={cx(css.header, css.splitsHeader)}>
        <div>Splits</div>
      </th>
      <th className={cx(css.header, css.maxHeader)}>
        <div>Max</div>
      </th>
      <th className={cx(css.header, css.typeHeader)}>
        <CustomTableSelect
          options={uniqueTypes}
          value={typeFilter}
          onChange={(newValue) => setTypeFilter(newValue)}
          placeholder={"Type"}
        />
      </th>
      <th className={cx(css.header, css.flagHeader)}>
        <div>Flag</div>
      </th>
      <th className={cx(css.header, css.statusHeader)}>
        <CustomTableSelect
          options={uniqueStatuses}
          value={statusFilter}
          onChange={(newValue) => setStatusFilter(newValue)}
          placeholder={"Status"}
        />
      </th>
      <th className={css.buttonHeader} />
    </>
  )
}

export default FilterHeader
