import React from "react"
import BsBreadcrumb from "react-bootstrap/Breadcrumb"
import Icon from "../Icon"
import cx from "classnames"
import css from "./Breadcrumb.module.scss"

type Props = React.ComponentProps<typeof BsBreadcrumb> & {
  className?: string
}

type ItemProps = React.ComponentProps<typeof BsBreadcrumb.Item> & {}

const Breadcrumb: React.FC<Props> & { Item: typeof BreadcrumbItem } = (
  props
) => {
  const { className, ...rest } = props

  return (
    <BsBreadcrumb
      className={cx(css.breadcrumbContainer, className)}
      {...rest}
    />
  )
}

const BreadcrumbItem: React.FC<ItemProps> = (props) => {
  return (
    <>
      <BsBreadcrumb.Item className={css.breadcrumbItem} {...props} />
      <Icon name="chevron-right" className={css.breadcrumbSeparator} />
    </>
  )
}

Breadcrumb.Item = BreadcrumbItem
export default Breadcrumb
