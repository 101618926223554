import React from "react"
import { useSelector, useDispatch } from "@app/models"
import Icon from "@app/components/Icon"
import { H5 } from "@app/components/Typography"
import Dropdown from "@app/components/Dropdown"
import { sortMappings } from "@app/services/mdvShiftCalculation"
import css from "./SortBox.module.scss"

type Props = {
  className?: string
}

export default (props: Props) => {
  const { className } = props

  const dispatch = useDispatch()
  const { sorting } = useSelector(
    (state) => state.customCalendars.customCalendarFilterConditions
  )

  return (
    <div className={className}>
      <Dropdown
        overlay={
          <Dropdown.Menu>
            <div className={css.sortDropdown}>
              <H5 bold="medium" className="mb-2">
                Sort by
              </H5>
              {Object.entries(sortMappings).map(([column, { name }]: any[]) => (
                <div
                  key={column}
                  className={css.dropdownItem}
                  onClick={() =>
                    dispatch.customCalendars.updateCustomCalendarConditions({
                      sorting: { ...sorting, column },
                    })
                  }
                >
                  {sorting.column === column && (
                    <Icon resetLineHeight name="check" />
                  )}
                  <span>{name}</span>
                </div>
              ))}
              <H5 bold="medium" className="mb-2 mt-2">
                Sort order
              </H5>
              {Object.entries(sortMappings[sorting.column].order).map(
                ([order, name]: any[]) => (
                  <div
                    key={order}
                    className={css.dropdownItem}
                    onClick={() =>
                      dispatch.customCalendars.updateCustomCalendarConditions({
                        sorting: { ...sorting, order },
                      })
                    }
                  >
                    {sorting.order === order && (
                      <Icon resetLineHeight name="check" />
                    )}
                    <span>{name}</span>
                  </div>
                )
              )}
            </div>
          </Dropdown.Menu>
        }
      >
        <div className={css.sortBox}>
          <Icon name={sorting.order === "asc" ? "sort-up" : "sort-down"} />
          <H5 className="ml-2" variant="black">
            {sortMappings[sorting.column].name}
          </H5>
        </div>
      </Dropdown>
    </div>
  )
}
