import React from "react"
import { JobsAndDatesType } from "../components/Schedules/components/Schedulers/components/ApplyDailyAssignmentTemplate/components/ConfirmDailyAssignments"
import { TemplateResultsType } from "../components/Schedules/components/Schedulers/components/ApplyDailyAssignmentTemplate/ApplyDailyAssignmentTemplate"

type QuickAssignmentContextProps = {
  quickAssignmentTemplate?: AnnualBlockScheduleTemplateType
  setQuickAssignmentTemplate: (val?: AnnualBlockScheduleTemplateType) => void
  quickAssignmentPage: string
  setQuickAssignmentPage: (val: string) => void
  addTemplateRequestToQueue: (asyncFunc: () => Promise<any>) => void
  templateResults: {
    total_events: JobsAndDatesType[]
    events_created: TemplateResultsType
  }[]
  templatesQueued: number
  clearQuickAssignTemplateData: () => void
}

export const QuickAssignmentContext = React.createContext(
  {} as QuickAssignmentContextProps
)
