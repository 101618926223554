import React, { useEffect, useReducer } from "react"
import JobFormContainer from "../JobFormContainer"
import Table from "@app/components/Table"
import Select from "@app/components/Form/Select"
import css from "./StaffingTargets.module.scss"
import api, { useRequest } from "@app/services/api"
import { handleApiError } from "@app/utils"
import {
  mutateStaffingTargetDataToCorrectValues,
  transformStaffingTargetsToTableData,
  transformStaffingTargetTableDataToRequestData,
} from "@app/services/staffingTargets"
import DailyOverrides from "./AdditionalAssignmentDailyOverrides"
import { useCaniuseFeature } from "@app/utils/hooks"

type Props = {
  job: JobWithAllFieldsType
}

export default (props: Props) => {
  const { job } = props
  const { data: jobStaffingTargets } = useRequest([
    api.getJobDayAssignments,
    job.jobid,
  ])

  const calendarV2Enabled = useCaniuseFeature("calendar_v2", {
    scope: "group",
  })

  useEffect(() => {
    if (jobStaffingTargets) {
      dispatch({
        initialState: transformStaffingTargetsToTableData(jobStaffingTargets),
        seatType: "Minimum",
        dayId: "1",
        value: 0,
      })
    }
  }, [jobStaffingTargets])

  const reducer = (
    state: any,
    action: {
      initialState?: any
      seatType: SeatType
      dayId: DayId
      value: number
    }
  ) => {
    if (action.initialState) {
      return [...action.initialState]
    }
    const { seatType, dayId, value } = action
    const mutatedData = mutateStaffingTargetDataToCorrectValues(
      state,
      dayId,
      seatType,
      value
    )
    return [...mutatedData]
  }

  const [state, dispatch] = useReducer(reducer, [])

  const renderSelectList = (dayId: DayId) => (val: number, row: any) =>
    getSeatInput(row.name, dayId, val)

  const getSeatInput = (type: SeatType, dayid: DayId, val: number) => {
    const options = [...Array(30).keys()].map((value) => {
      return {
        id: value + 1,
        name: (value + 1).toString(),
      }
    })
    return (
      <div>
        <Select
          key={`${type}-${dayid}`}
          options={options}
          clearable={false}
          value={val}
          onChange={(e) => {
            dispatch({ seatType: type, dayId: dayid, value: Number(e) })
          }}
        />
      </div>
    )
  }

  const columnTypes = [
    {
      dataKey: "name",
      title: "",
    },
    {
      dataKey: "1",
      title: "Sunday",
      render: renderSelectList("1"),
    },
    {
      dataKey: "2",
      title: "Monday",
      render: renderSelectList("2"),
    },
    {
      dataKey: "3",
      title: "Tuesday",
      render: renderSelectList("3"),
    },
    {
      dataKey: "4",
      title: "Wednesday",
      render: renderSelectList("4"),
    },
    {
      dataKey: "5",
      title: "Thursday",
      render: renderSelectList("5"),
    },
    {
      dataKey: "6",
      title: "Friday",
      render: renderSelectList("6"),
    },
    {
      dataKey: "7",
      title: "Saturday",
      render: renderSelectList("7"),
    },
  ]

  const handleSubmit = (job: JobWithAllFieldsType, state: any, goBack: any) => {
    const formattedData = transformStaffingTargetTableDataToRequestData(state)

    api.updateJobDayAssignments(job.jobid, formattedData).then((res) => {
      goBack()
    }, handleApiError)
  }

  return (
    <>
      <JobFormContainer onSubmit={(goBack) => handleSubmit(job, state, goBack)}>
        <div className={css.staffingTargetsTable}>
          <Table
            variant="frame"
            data={state}
            loading={!jobStaffingTargets}
            columns={columnTypes}
          />
        </div>
      </JobFormContainer>
      {calendarV2Enabled && (
        <DailyOverrides jobid={job.jobid} jobName={job.name} />
      )}
    </>
  )
}
