import React, { useContext, useMemo } from "react"
import { ManualScheduleTableContext } from "../../context/ManualScheduleJobTableContext"
import { ISplitShiftItem } from "../../data"

export interface SplitShiftScheduleColProps {
  jobId: number
  splitShifts: ISplitShiftItem[]
}

const SplitShiftScheduleCol: React.FC<SplitShiftScheduleColProps> = (props) => {
  const { jobId, splitShifts } = props
  const { providers, residentJobsProviders, setCurrentSplitShiftJobId, edate } =
    useContext(ManualScheduleTableContext)

  const combinedProviders = useMemo(() => {
    const residentProviders =
      residentJobsProviders?.find((x) => x.jobid === jobId)?.providers || []
    return residentProviders.concat(providers)
  }, [jobId, providers, residentJobsProviders])

  const getSplitShiftValue = () => {
    const value = {
      jobid: jobId,
      edate: edate,
      split_shifts: splitShifts,
    }

    return JSON.stringify(value)
  }

  const getSplitShiftDisplayValue = () => {
    const len = splitShifts.length
    const nameList = splitShifts
      .map(
        (s) => combinedProviders.find((p) => p.providerid == s.providerid)?.name
      )
      .join(", ")

    return `${len} Splits - ${nameList}`
  }

  return (
    <div className="flex-1">
      <select
        value={getSplitShiftValue()}
        name={`spljob${jobId}`}
        onClick={() => setCurrentSplitShiftJobId(jobId)}
        style={{ width: "100%", minWidth: "240px" }}
      >
        <option value={getSplitShiftValue()} selected>
          {getSplitShiftDisplayValue()}
        </option>
      </select>
    </div>
  )
}

export default SplitShiftScheduleCol
