import { useSelector } from "@app/models"
import { getJobTimes } from "../utils/getTimeByDate"

export const useJobTimes = (jobid: number, date: string) => {
  const { jobs } = useSelector((state) => state.groupData)
  const currentJob = jobs.find((job) => job.jobid === jobid)

  if (!currentJob) {
    return { jobStartTime: null, jobEndTime: null }
  }

  return getJobTimes(currentJob, date)
}
