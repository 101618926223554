import React from "react"
import Table from "@app/components/Table"
import { Text } from "@app/components/Typography"
import css from "./ApplyDailyAssignmentsHeader.module.scss"

type PropType = {
  providerName: string
  dateRange: string
  rotation: BlockScheduleRotationType | undefined
  templateName: string
}

export default (props: PropType) => {
  const { providerName, dateRange, rotation, templateName } = props
  const tableColumns = [
    {
      title: "Name",
      dataKey: "providerName",
    },
    {
      title: "Date range",
      dataKey: "dateRange",
    },
    {
      title: "Rotation",
      dataKey: "rotation",
      render: (rotation: BlockScheduleRotationType) =>
        rotation && (
          <Text bold ellipsis variant="black" prefixDotColor={rotation.color}>
            {rotation.name}
          </Text>
        ),
    },
    {
      title: "Template",
      dataKey: "templateName",
      render: (templateName: string) => (!templateName ? "-" : templateName),
    },
  ]

  return (
    <Table
      className={css.templateHeader}
      columns={tableColumns}
      data={[
        {
          providerName,
          dateRange,
          rotation,
          templateName,
        },
      ]}
      striped={false}
      hover={false}
    />
  )
}
