import React, { useState, useEffect } from "react"
import { useDispatch } from "@app/models"
import { useSearchParams } from "react-router-dom"
import { Input } from "@app/components/Form"
import Icon from "@app/components/Icon"
import { formatDateInTimezone } from "@app/utils"
import { DateFormatter } from "@app/utils/constants"
import css from "./DatePicker.module.scss"

const InputComponent = React.forwardRef((props: any, ref) => {
  return (
    <label className={css.dateInputLabel}>
      <input readOnly ref={ref} {...props} />
      <span className={css.dateInputValue}>{props.value}</span>
      <Icon name="calendar4" />
    </label>
  )
})

type Props = {
  timezone: string
}

export default ({ timezone }: Props) => {
  const getToday = () => formatDateInTimezone(new Date(), timezone)

  const [searchParams, setSearchParams] = useSearchParams()
  const [date, setDate] = useState(searchParams.get("date") || getToday())

  const dispatch = useDispatch()

  const updateDate = (value: string) => {
    setDate(value)
    setSearchParams({ date: value })
  }

  useEffect(() => {
    if (date !== searchParams.get("date")) {
      setDate(searchParams.get("date") || getToday())
    }
  }, [searchParams])

  useEffect(() => {
    const today = getToday()

    // Auto update switch to be true for days not today,
    // use `setTimeout` is for avoiding below logic override by default setting from
    // triggered this effect by enter the page
    if (date !== today) {
      setTimeout(() => {
        dispatch.customCalendars.updateCustomCalendarConditions({
          hidePastFuture: false,
        })
      })
    }
  }, [date])

  return (
    <Input
      type="date"
      value={date}
      onChange={updateDate}
      dateFormat={DateFormatter.longLocalizedWithoutWeek}
      className={css.datePicker}
      component={InputComponent}
    />
  )
}
