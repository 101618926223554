import React, { useEffect, useState } from "react"

import Button from "@app/components/Button"
import AddOrRemoveJobModal from "./AddOrRemoveJobModal"

import css from "./DailyTemplateCalendarView.module.scss"

type Props = {
  setWeeks: Function
  weeks: JobType[][][]
}

export type Selected = Record<string, { selected: boolean; jobs: JobType[] }>

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export default (props: Props) => {
  const { setWeeks, weeks } = props
  const [selected, setSelected] = useState<Selected>({})
  const [selectAllToggle, setSelectAllToggle] = useState<boolean>(true)
  const [showJobModal, setShowJobModal] = useState<boolean>(false)
  const [jobModalMode, setJobModalMode] = useState<"add" | "remove">("add")
  const [jobsToRemove, setJobsToRemove] = useState<Record<string, JobType>>({})

  useEffect(
    function updateJobsToRemove() {
      calcJobsToRemove()
    },
    [selected]
  )

  function handleDaySelect(key: string, setting?: boolean) {
    const week = Number(key.charAt(0))
    const day = Number(key.charAt(1))
    if (setting !== undefined) {
      if (setting) {
        selected[key] = {
          selected: true,
          jobs: weeks[week][day],
        }
      } else {
        delete selected[key]
      }
    } else if (selected[key]?.selected) {
      delete selected[key]
    } else {
      selected[key] = {
        selected: true,
        jobs: weeks[week][day],
      }
    }
    setSelected({ ...selected })
  }

  function calcJobsToRemove() {
    let removeJobs = {} as Record<string, JobType>
    Object.values(selected).map(({ selected, jobs }) => {
      if (!selected) return
      jobs.map((job) => {
        removeJobs[job.jobid] = job
      })
    })
    setJobsToRemove({ ...removeJobs })
  }

  function selectAll(setting: boolean) {
    for (let col = 0; col < weeks[0].length; col++) {
      for (let row = 0; row < weeks.length; row++) {
        handleDaySelect(`${row}${col}`, setting)
      }
    }
    setSelectAllToggle(!selectAllToggle)
  }

  function selectRow(row: number, setting: boolean) {
    for (let i = 0; i < weeks[0].length; i++) {
      handleDaySelect(`${row}${i}`, setting)
    }
  }

  function selectCol(col: number, setting: boolean) {
    for (let i = 0; i < weeks.length; i++) {
      handleDaySelect(`${i}${col}`, setting)
    }
  }

  const selectedRows = () => {
    const selectedWeekIndices: number[] = []

    weeks.forEach((_v, weekIdx) => {
      if (
        weekdays.every((_week, idx) => selected[`${weekIdx}${idx}`]?.selected)
      ) {
        selectedWeekIndices.push(weekIdx)
      }
    })

    return selectedWeekIndices
  }

  const selectedCols = () => {
    const selectedDayIndices: number[] = []

    weekdays.forEach((_v, idx) => {
      if (
        weeks.every((_week, weekIdx) => selected[`${weekIdx}${idx}`]?.selected)
      ) {
        selectedDayIndices.push(idx)
      }
    })

    return selectedDayIndices
  }

  return (
    <>
      <div className={css.calendarActions}>
        <Button variant="link" onClick={() => selectAll(selectAllToggle)}>
          {selectAllToggle ? "Select All" : "Deselect All"}
        </Button>{" "}
        <Button
          variant="link"
          disabled={Object.keys(jobsToRemove).length === 0}
          onClick={() => {
            setJobModalMode("remove")
            setShowJobModal(true)
          }}
        >
          Remove Job(s)
        </Button>{" "}
        <Button
          disabled={Object.keys(selected).length === 0}
          onClick={() => {
            setJobModalMode("add")
            setShowJobModal(true)
          }}
        >
          Add Job(s)
        </Button>
      </div>
      <div className={css.calendarView}>
        <table className={css.calendar}>
          <thead className={css.weekHeader}>
            <tr>
              <th className={css.emptyDay}></th>
              {weekdays.map((day, idx) => (
                <th className={css.headerDay} key={idx}>
                  <div className={css.headerWrapper}>
                    <span className={css.headerText}>{day}</span>{" "}
                    <input
                      type="checkbox"
                      checked={selectedCols().includes(idx)}
                      onChange={(e) => selectCol(idx, e.target.checked)}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, weekIdx) => (
              <tr className={css.week} key={weekIdx}>
                <td className={css.weekLabelCell}>
                  <div className={css.weekLabelWrapper}>
                    <div className={css.weekLabelText}>Week {weekIdx + 1}</div>{" "}
                    <input
                      type="checkbox"
                      checked={selectedRows().includes(weekIdx)}
                      onChange={(e) => selectRow(weekIdx, e.target.checked)}
                    />
                  </div>
                </td>
                {week.map((days, dayIdx) => {
                  const cellKey = `${weekIdx}${dayIdx}`
                  return (
                    <td
                      className={`${css.day} ${
                        selected[cellKey]?.selected ? css.selectedDay : null
                      }`}
                      key={dayIdx}
                      onClick={() => handleDaySelect(cellKey)}
                    >
                      {days.map((job, jobIdx) => (
                        <div key={`${cellKey}-${jobIdx}`}>
                          {job?.name && (
                            <div className={css.job}>
                              <div className={css.jobName}>{job.abbrev}</div>
                              <div
                                className={css.jobTime}
                              >{`${job?.starttime}-${job?.endtime}`}</div>
                            </div>
                          )}
                        </div>
                      ))}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddOrRemoveJobModal
        mode={jobModalMode}
        weeks={weeks}
        jobsToRemove={jobsToRemove}
        setWeeks={setWeeks}
        selectedDays={selected}
        show={showJobModal}
        close={setShowJobModal}
      />
    </>
  )
}
