import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { HashRouter, Routes, Route } from "react-router-dom"
import store, { useDispatch } from "@app/models"
import BlockScheduling from "./BlockScheduling"

const AppRoutes = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch.users.getCurrentUserInfo()
  }, [])

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/reports/daily_assignments"
          element={<BlockScheduling reportType="daily_assignments" />}
        />
        <Route
          path="/reports/rotation_assignments"
          element={<BlockScheduling reportType="rotations" />}
        />
        <Route
          path="/reports/rotations_summary"
          element={<BlockScheduling reportType="rotations_summary" />}
        />
      </Routes>
    </HashRouter>
  )
}

export default () => <Provider store={store} children={<AppRoutes />} />
