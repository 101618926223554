import { Tooltip, withStyles } from "@material-ui/core"

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#e4f6fe",
    color: "#565559",
    maxWidth: 220,
    marginLeft: "0.8rem",
    fontSize: "1.2rem",
    fontWeight: 600,
    fontFamily: "'Avenir Next', 'Montserrat', 'museo-sans', 'sans-serif'",
    border: "1px solid #e4f6fe",
    borderRadius: "4px",
  },

  arrow: {
    color: "#e4f6fe",
  },
}))(Tooltip)
