import React, { useState } from "react"
import HTMLEllipsis from "react-lines-ellipsis/lib/html"
import Modal from "@app/components/Modal"
import cx from "classnames"
import css from "./ProtocolViewer.module.scss"

type Props = {
  providerId?: number
  protocol?: string
  protocolName?: string
  noStyle?: boolean
  readMore?: boolean
  display?: "open" | "closed"
  className?: string
  canSendMessage?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
  onSendMessageLinkClick?: (providerId: number) => any
}

export default (props: Props) => {
  const {
    providerId,
    protocol = "No Protocol",
    protocolName = "View Protocol",
    display = "open",
    noStyle,
    readMore,
    className,
    canSendMessage = true,
    onClick,
    onSendMessageLinkClick,
  } = props

  const [protocolContentModalVisible, setProtocolContentModalVisible] =
    useState(false)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // bind message link click only works provider protocol
    if (providerId && canSendMessage) {
      const sendMessageLinkClass = "tigerconnect-link"
      const linkClasses = (event.target as HTMLAnchorElement).classList

      if (linkClasses.contains(sendMessageLinkClass)) {
        onSendMessageLinkClick?.(providerId)
        event.stopPropagation()
        return
      }
    }

    // trigger onClick
    onClick?.(event)
  }

  const sanitizedProtocol = noStyle
    ? protocol.replace(/(style=".+?")/gm, "")
    : protocol

  const viewerContent = readMore ? (
    <HTMLEllipsis
      maxLine={2}
      unsafeHTML={sanitizedProtocol}
      ellipsisHTML={`... <a href="javascript:void(0)">Read More</a>`}
      className={noStyle ? css.protocolNoStyle : ""}
    />
  ) : (
    <div dangerouslySetInnerHTML={{ __html: sanitizedProtocol }} />
  )

  // render protocol name and a preview modal when dispaly is `close`
  return (
    <div className={cx(className, css.protocolText)} onClick={handleClick}>
      {display === "open" ? (
        viewerContent
      ) : (
        <>
          <Modal
            title="Protocol Content"
            titleAlign="left"
            show={protocolContentModalVisible}
            onHide={() => setProtocolContentModalVisible(false)}
          >
            <div className={css.viewerContentInModal}>{viewerContent}</div>
          </Modal>
          <div
            className={css.protocolName}
            onClick={() => setProtocolContentModalVisible(true)}
          >
            {protocolName}
          </div>
        </>
      )}
    </div>
  )
}
