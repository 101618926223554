import React, { useMemo } from "react"
import { useSelector } from "@app/models"
import { SharedCalendarViewRow } from "../../../../SharedCalendarView"
import ProviderInfo from "./ProviderInfo"
import Block from "./Block"
import { calculateTotalTallyData } from "@app/services/tallyCalculation"
import type { RequestType } from "./type"
import api, { useRequest } from "@app/services/api"
import { parseDate } from "@app/utils"

type Props = {
  annualBlockScheduleId: string
  scheduleProvider: AnnualBlockScheduleProviderType
  annualRotations: AnnualBlockScheduleRotationType[]
  tallies: AnnualBlockScheduleTallyType[]
}

export default (props: Props) => {
  const { annualBlockScheduleId, scheduleProvider, annualRotations, tallies } =
    props

  const { blockSets, computedBlocksData } = useSelector(
    (state) => state.blockSets
  )

  const { data: providerWithAllFields } =
    useRequest<AnnualBlockScheduleProviderWithAllFieldsType>([
      api.getAnnualBlockScheduleProvider,
      annualBlockScheduleId,
      scheduleProvider.providerid,
    ])

  // Tally Data
  const tallyData = useMemo(
    () =>
      calculateTotalTallyData({
        scheduleProvider: providerWithAllFields,
        annualRotations,
        blockSets,
        tallies,
      }),
    [providerWithAllFields, annualRotations, blockSets, tallies]
  )

  // Combine Requests and Dayoffs
  const requests = useMemo(() => {
    if (!providerWithAllFields) {
      return []
    }

    const requestsData: RequestType[] = providerWithAllFields.requests.map(
      (item) => ({ ...item, id: `request-${item.requestid}` })
    )

    const dayoffsData: RequestType[] = providerWithAllFields.dayoffs.map(
      (item) => ({
        ...item,
        id: `dayoff-${item.dayoffid}`,
        start_date: item.date,
        end_date: item.date,
        status: "Day Off",
      })
    )

    const vacationsData: RequestType[] = providerWithAllFields.vacations.map(
      (item) => ({
        ...item,
        id: `vacation-${item.vacationid}`,
        start_date: item.date,
        end_date: item.date,
        status: "Vacation",
      })
    )

    const allRequests = requestsData.concat(dayoffsData).concat(vacationsData)

    // Sort requests by start date
    allRequests.sort((a, b) => {
      const startDateA = parseDate(a.start_date).getTime()
      const startDateB = parseDate(b.start_date).getTime()
      return startDateA - startDateB
    })

    return allRequests
  }, [providerWithAllFields])

  const blockHeight = 58
  const { block_events: blockEvents } = providerWithAllFields || {}
  const {
    provider,
    staff_level: staffLevel,
    block_set_id: blockSetId,
  } = providerWithAllFields || scheduleProvider

  return (
    <SharedCalendarViewRow
      sideContent={
        <ProviderInfo
          provider={provider}
          staffLevel={staffLevel}
          tallyData={tallyData}
          requests={requests}
          blockHeight={blockHeight}
        />
      }
      mainContent={
        blockEvents ? (
          (computedBlocksData[blockSetId] || []).map((block) => (
            <Block
              key={block.id}
              {...block}
              blockHeight={blockHeight}
              provider={provider}
              staffLevel={staffLevel}
              blockEvent={blockEvents.find(
                (x) => x.block_set_block_id === block.id
              )}
              tallyData={tallyData}
              requests={requests}
            />
          ))
        ) : (
          <SharedCalendarViewRow.Loader height={blockHeight} />
        )
      }
    />
  )
}
