import React, { useState, useEffect, useMemo } from "react"
import { Virtuoso, VirtuosoProps } from "react-virtuoso"
import cx from "classnames"
import css from "./VirtualList.module.scss"

type Props<T, C> = VirtuosoProps<T, C> & {
  height?: number
  virtualThreshold?: number
}

const List = <T, C>(props: VirtuosoProps<T, C>) => {
  const {
    data = [],
    style = {},
    className,
    itemContent,
    computeItemKey,
    context,
    scrollerRef,
    components: { Header, Footer } = {},
  } = props

  return (
    <div
      style={style}
      className={cx("virtual-item-list", css.list, className)}
      ref={scrollerRef}
    >
      {Header && <Header />}
      {data.map((item, index) => (
        <div
          data-item-index={index}
          key={computeItemKey?.(index, item, context as C) || index}
        >
          {itemContent?.(index, item, context as C)}
        </div>
      ))}
      {Footer && <Footer />}
    </div>
  )
}

export default <T extends PlainObjectType, C>(props: Props<T, C>) => {
  const {
    virtualThreshold = 10,
    style = {},
    className,
    height,
    scrollerRef,
    context = {},
    ...rest
  } = props

  // Get scrollable container real width
  const [scroller, setScroller] = useState<HTMLDivElement | undefined>()
  const [scrollerWidth, setScrollerWidth] = useState<number | undefined>()

  useEffect(() => {
    if (!scroller) {
      return
    }

    const fn = () => {
      const scrolled = scroller.scrollLeft
      if (!scrollerWidth && scrolled > 0) {
        const scrollWidth = scroller.scrollWidth
        setScrollerWidth(scrollWidth)
      }
    }

    scroller.addEventListener("scroll", fn)
    return () => scroller.removeEventListener("scroll", fn)
  }, [scroller])

  const getScrollerRef: typeof scrollerRef = (div) => {
    setScroller(div as HTMLDivElement)
    scrollerRef?.(div)
  }

  const dataLength = rest.data?.length || 0
  const Comp = useMemo(
    () =>
      virtualThreshold < 0
        ? List
        : dataLength > virtualThreshold
        ? Virtuoso
        : List,
    [virtualThreshold, dataLength]
  )

  const newStyle = useMemo(() => {
    const heightKey =
      virtualThreshold < 0
        ? "maxHeight"
        : dataLength > virtualThreshold
        ? "height"
        : "maxHeight"
    return { [heightKey]: height, ...style }
  }, [virtualThreshold, height, style])

  return (
    <Comp
      className={className}
      style={newStyle}
      scrollerRef={getScrollerRef}
      context={{ ...context, width: scrollerWidth } as any}
      {...rest}
    />
  )
}
