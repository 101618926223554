import { useEffect, useRef, useMemo, RefObject, useState } from "react"
import { useSelector, useDispatch } from "@app/models"
import { authPermission } from "@app/services/accessPermission"
import { throttle, get as lodashGet, isArray } from "lodash"

export const useMountedEffect = (func: Function, deps: any[]) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}

export const useRunningOnce = (func: Function) => {
  const didMount = useRef(false)

  if (!didMount.current) {
    func()
    didMount.current = true
  }
}

// useScrollSync implement
type UseScrollSyncOptions = {
  horizontal?: boolean
  vertical?: boolean
  proportional?: boolean
  throttleWaitTime?: number // ms
}

const updateScrollsPosition = <T extends HTMLElement>(
  target: HTMLElement,
  refs: HTMLElement[],
  options: UseScrollSyncOptions
) => {
  const scrollLeftOffset =
    target.scrollLeft / (target.scrollWidth - target.clientWidth)

  const scrollTopOffset =
    target.scrollTop / (target.scrollHeight - target.clientHeight)

  refs.forEach((current) => {
    if (!current) return

    if (options.vertical) {
      const position = options.proportional
        ? scrollTopOffset * (current.scrollHeight - current.clientHeight)
        : target.scrollTop

      current.scrollTop = Math.round(position)
    }

    if (options.horizontal) {
      const position = options.proportional
        ? scrollLeftOffset * (current.scrollWidth - current.clientWidth)
        : target.scrollLeft

      current.scrollLeft = Math.round(position)
    }
  })
}

export const useScrollSync = (options: UseScrollSyncOptions = {}) => {
  const refCollection = useRef<HTMLElement[]>([])
  const addRef = (ref: HTMLElement) => {
    if (!refCollection.current.includes(ref)) {
      refCollection.current.push(ref)
    }
  }
  const scrollSyncOptions = {
    horizontal: true,
    vertical: true,
    proportional: true,
    throttleWaitTime: 100,
    ...options,
  }

  const handleScroll = ({ target }: React.SyntheticEvent<WheelEvent>) => {
    if (!target) {
      throw Error("Event target shouldn't be null")
    }

    const refsWithoutTarget = refCollection.current.filter(
      (current) => current !== target
    )

    window.requestAnimationFrame(() => {
      updateScrollsPosition(
        target as HTMLElement,
        refsWithoutTarget,
        scrollSyncOptions
      )
    })
  }

  const onScroll = (event: React.SyntheticEvent<WheelEvent>) => {
    event.persist()
    throttle(handleScroll, scrollSyncOptions.throttleWaitTime)(event)
  }

  return { ref: addRef, onScroll: onScroll }
}

// Hook for Can-I-Use a certain feature
export const useCaniuseFeature = (
  featureName: string,
  options: { scope?: "group" | "clinic" } = {}
) => {
  const { scope } = options
  const { group } = useSelector((state) => state.users.currentUser)

  if (!group) {
    return false
  }

  const enabledFeatures =
    scope === "clinic" ? group.clinic_enabled_features : group.enabled_features

  return enabledFeatures.includes(featureName)
}

// Hook for Can I visit specific page/path
export const useAccessPermission = (permissionConfig: PlainObjectType = {}) => {
  const { currentUser } = useSelector((state) => state.users)

  const canAccess = (path: string) =>
    authPermission(currentUser, path, permissionConfig)

  return { canAccess }
}

// Hook for common filter
export const useFilter = (
  filterKey: string | Array<string | number | boolean | undefined>
) => {
  const dispatch = useDispatch()
  const key = typeof filterKey === "string" ? filterKey : filterKey.join("/")
  const [filterState, enabled] = useSelector((state) => {
    const filterState = state.filterCollections.commonFilters[key]
    return [filterState || { values: [], conditions: {} }, !!filterState]
  })

  // Update values of filter
  const update = (
    payload:
      | { values: any }
      | { condition: { name: string; value: any; path?: string } }
  ) => {
    if ("condition" in payload) {
      const condition = payload.condition
      const conditionValue = condition.value
      const conditionOldValue = filterState.conditions[condition.name]?.value

      // Prevent update when pressed clear button
      if (
        conditionOldValue == null &&
        (conditionValue == null ||
          (isArray(conditionValue) && !conditionValue.length))
      ) {
        return
      }
    }

    return dispatch.filterCollections.updateFilter({ key, ...payload })
  }

  // Remove filter state from store
  const clear = () => dispatch.filterCollections.clearFilter({ key })

  // Support to filter data based on values
  const filterCacheKey = filterState.values.join()
  const filter = <T>(
    data?: T[],
    keyOrFn: string | ((item: any) => any) = "id"
  ) => {
    if (!enabled || data == null) {
      return data || []
    }

    const fetcher =
      typeof keyOrFn === "string"
        ? (item: any) => lodashGet(item, keyOrFn)
        : keyOrFn

    return data.filter((item) => filterState.values.includes(fetcher(item)))
  }

  // Support to filter data based on conditions or specific conditions
  const filterByConditions = <T>(data?: T[]) => {
    if (!enabled || data == null) {
      return data || []
    }

    // Explanation of `keyPath` Sytax:
    //   - a.b.c -> { a: { b: { c: 1 } } }
    //   - a.b[].c.d[].e -> { a: b: [{ c: { d: [{ e: 1 }] } }] }
    const pickValues = (item: PlainObjectType, keyPath: string) => {
      if (!keyPath.includes("[]")) {
        return [lodashGet(item, keyPath)]
      }

      let results = [item]

      keyPath.split(".").forEach((key) => {
        let elements: any[] = []
        if (key.includes("[]")) {
          key = key.replace("[]", "")
          results.forEach((x) => elements.push(...x[key]))
        } else {
          results.forEach((x) => elements.push(x[key]))
        }
        results = elements.filter((x) => x != null)
      })

      return results
    }

    // Filter the data by conditions,
    // will dismiss conditions which value is null or empty,
    // also handle value if it's a array
    return Object.values(filterState.conditions)
      .filter(({ value }) => (isArray(value) ? value.length : value != null))
      .reduce((acc: T[], { name, path, value }) => {
        const values = isArray(value) ? value : [value]
        return acc.filter((item) => {
          const itemValues = pickValues(item, path || name)
          return values.some((val) => itemValues.includes(val))
        })
      }, data)
  }

  return {
    enabled,
    filterState,
    update,
    clear,
    filter,
    filterCacheKey,
    filterByConditions,
  }
}

// Calculate position for a element:
//   - heightToScreenBottom means max height for a element to screen bottom without <body> scrollbar
export const useElementPosition = (options: { topSpace?: number } = {}) => {
  const { topSpace = 0 } = options
  const elementRef = useRef<HTMLDivElement | null>(null)
  const heightToScreenBottom = useMemo(() => {
    if (!elementRef.current) {
      return undefined
    }

    const clientHeight = document.documentElement.clientHeight
    const containerOffsetDocumentTop =
      window.pageYOffset + elementRef.current.getBoundingClientRect().top

    return clientHeight - containerOffsetDocumentTop - topSpace
  }, [elementRef.current])

  return { elementRef, heightToScreenBottom }
}

// ClickOutside hook implement
export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => any,
  mouseEvent: "mousedown" | "mouseup" = "mousedown"
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    document.addEventListener(mouseEvent, listener)
    return () => document.removeEventListener(mouseEvent, listener)
  }, [ref, handler])
}

// Hacky front-end queue
export const useAsyncQueue = () => {
  type QueueItem = {
    asyncFunc: () => Promise<any>
    resolve: (value: any | PromiseLike<any>) => void
    reject: (reason?: any) => void
  }

  const queue = useRef<QueueItem[]>([])
  const executingRef = useRef(false)
  const totalEnqueued = useRef(0)
  const [results, setResults] = useState<any[]>([])
  const [queueLength, setQueueLength] = useState(0)

  const clearResults = (): void => {
    totalEnqueued.current = 0
    setResults([])
  }

  const addAsyncFuncToQueue = (asyncFunc: () => Promise<any>) => {
    const promise = new Promise<any>((resolve, reject) => {
      queue.current.push({ asyncFunc, resolve, reject })
      totalEnqueued.current++
      setQueueLength(queue.current.length)
    })
    return promise
  }

  useEffect(() => {
    if (executingRef.current || queue.current.length === 0) return
    const executeFunc = async () => {
      executingRef.current = true
      if (queue.current.length === 0) return
      const firstFunc = queue.current[0]
      try {
        const result = await firstFunc.asyncFunc()
        firstFunc.resolve(result)
        setResults((oldResults) => [...oldResults, result])
        queue.current.shift()
      } catch (e) {
        firstFunc.reject(e)
        queue.current.shift()
      }

      if (queue.current.length !== 0) {
        executeFunc()
      } else {
        executingRef.current = false
        return
      }
    }

    executeFunc()
  }, [queueLength])

  return {
    addAsyncFuncToQueue,
    totalEnqueued: totalEnqueued.current,
    results,
    clearResults,
  }
}

export const useIsAuthenticated = () => {
  const { currentUser } = useSelector((state) => state.users)
  const isAuthenticated =
    currentUser != null && Object.keys(currentUser).length > 0
  return isAuthenticated
}
